import { SET_TABLECOLUMN } from '../actions/tableColumnActions';
import { informSlackDBCodeErrs } from '../../util/informSlack';
import {
    buildingsTableColumnHeader,
    associatesTableColumnHeader,
    membersTableColumnHeader,
    businessesTableColumnHeader,
    voMembersTableColumnHeader,
    voBuildingsTableColumnHeader
} from '../../util/application.utils';

let initialState = {
    members: membersTableColumnHeader,
    bussiness: businessesTableColumnHeader,
    associates: associatesTableColumnHeader,
    buildings: buildingsTableColumnHeader,
    voMembers: voMembersTableColumnHeader,
    voBuilding: voBuildingsTableColumnHeader
};

try {
    if (localStorage.px_tableColumn) {
        const localData = JSON.parse(localStorage.px_tableColumn); // for checking any new column
        if (localData.members.length !== membersTableColumnHeader.length) {
            membersTableColumnHeader.forEach(item => {
                const index = localData.members.findIndex(x => x.name === item.name);
                if (index === -1) {
                    localData.members.push(item);
                }
            });
        }
        if (localData.associates.length !== associatesTableColumnHeader.length) {
            associatesTableColumnHeader.forEach(item => {
                const index = localData.associates.findIndex(x => x.name === item.name);
                if (index === -1) {
                    localData.associates.push(item);
                }
            });
        }

        if (localData.buildings.length !== buildingsTableColumnHeader.length) {
            buildingsTableColumnHeader.forEach(item => {
                const index = localData.buildings.findIndex(x => x.name === item.name);
                if (index === -1) {
                    localData.buildings.push(item);
                }
            });
        }
        if (localData.bussiness.length !== businessesTableColumnHeader.length) {
            businessesTableColumnHeader.forEach(item => {
                const index = localData.bussiness.findIndex(x => x.name === item.name);
                if (index === -1) {
                    localData.bussiness.push(item);
                }
            });
        }
        if (localData.voMembers && localData.voMembers.length !== voMembersTableColumnHeader.length) {
            voMembersTableColumnHeader.forEach(item => {
                const index = localData.voMembers.findIndex(x => x.name === item.name);
                if (index === -1) {
                    localData.voMembers.push(item);
                }
            });
        }
        if (localData.voBuilding && localData.voBuilding.length !== voBuildingsTableColumnHeader.length) {
            voMembersTableColumnHeader.forEach(item => {
                const index = localData.voBuilding.findIndex(x => x.name === item.name);
                if (index === -1) {
                    localData.voBuilding.push(item);
                }
            });
        }
    }
} catch (err) {
    informSlackDBCodeErrs(err, 'source > src/redux/reducers/tableColumnReducer', 'line # 55');
}


export default function (state = initialState, action) {
    switch (action.type) {
        case SET_TABLECOLUMN: {
            const newState = {
                members: action.tableFilter.members,
                bussiness: action.tableFilter.bussiness,
                associates: action.tableFilter.associates,
                buildings: action.tableFilter.buildings,
                voMembers: action.tableFilter.voMembers && action.tableFilter.voMembers,
                voBuilding: action.tableFilter.voBuilding && action.tableFilter.voBuilding,

            };
            localStorage.px_tableColumn = JSON.stringify(newState);
            return newState;
        }
        default:
            return state;
    }
}
