import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
// import CloseIcon from '@material-ui/icons/Close';

// const useStyles = makeStyles({
//   dialog: {
//     position: 'absolute',
//     right: 10,
//     bottom: 50
//   },
// });

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function NewUserModal({ open, close, history }) {
  const getLink = () => <u role="button" tabIndex={0} onClick={() => { close(); history.push('/upload_csv'); }} onKeyDown={() => { close(); history.push('/upload_csv'); }} >Upload CSV</u>;
  // const classes = useStyles();
  const onBoardDownloadAppHandle = () => {
    window.open('https://onelink.to/mailroom', '_blank'); 
    close();
  };
  return (
    <div>
      <Dialog

        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => close()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            position: 'absolute',
            right: '20',
            left: '20'
          }
         }}
        className="onboard-modal"
      >
        {/* <DialogTitle id="alert-dialog-slide-title">Title</DialogTitle> */}
        {/* <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: 'rgb(255, 30, 89) !important',
                }}
            >
                <DialogTitle id="alert-dialog-title" className="onboard-red">Welcome onboard !</DialogTitle>
                <IconButton
                    onClick={() => close()}
                    className="onboard-red-close-icon"
                    style={{ height: 'max-content', marginRight: 10, marginTop: 5 }}
                >
                    <CloseIcon />
                </IconButton>
            </div> */}
        <DialogContent>
          <Button className="onboard-download-app-btn" onClick={() => onBoardDownloadAppHandle()}>Download App to Start Scanning</Button>
            <p style={{ fontSize: 12, textAlign: 'center' }}>Already have recipients information? {getLink()}</p>
        </DialogContent>
      </Dialog>
    </div>
  );
}

NewUserModal.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    history: PropTypes.node.isRequired
};

export default withRouter(NewUserModal);
