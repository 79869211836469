/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  //   FormHelperText,
  Button,
  CircularProgress,
} from '@material-ui/core';
// import { getEncryptedEmail } from '../../../../util/application.utils';
import { CallTrigger } from '../../../../../util/Triggers/function';
import { CLA } from '../../../../../util/Triggers/triggerNames';
import Reciever from '../../../../../assets/Onboarding/Label.new.svg';
// import Reciever from '../../../../../assets/Onboarding/ScanItems/Label.svg';
import camera from '../../../../../assets/Onboarding/camera-scan.svg';
import detectText from '../../../../../assets/Onboarding/DetectText.svg';
import qr from '../../../../../assets/Onboarding/qr-icon.svg';
import scan from '../../../../../assets/Onboarding/scan.svg';

class AdditionalDetail extends React.Component {
  state = {
    disable: false,
  };
  componentDidMount() {}
  render() {
    const { next, getUserCurrentStep, t, waiting } = this.props;
    const { disable } = this.state;
    const moveToNext = () => {
      getUserCurrentStep(() => next());
    };
    const { userDetail } = this.props;
    const UserName =
      (userDetail &&
        userDetail.UserAttributes &&
        userDetail.UserAttributes[1] &&
        userDetail.UserAttributes[1].Value &&
        userDetail.UserAttributes[1].Value) ||
      localStorage.getItem('userName');

    return (
      <div className="onboard-new-outer-container">
        <Grid container className="container" style={{ minWidth: '100%' }}>
          {/* <form
              onSubmit={() => next()}
          > */}
          <Grid item xs={12} sm={12}>
            <h3 className="onboarding-welcome-title">
              Scan items into Mailroom
            </h3>
            <p className="onboarding-welcome-description">
              Your first step will be to scan items into the Mailroom app. We'll allow you to scan 
              up to 50 items at once, and our Al algorithm will do all the manual work of extracting the tracking number and
              matching it to the intended recipient.
            </p>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item xs={12} sm={6} className="onboard-new-pl-15">
                <ol className="onboard-new-instructions-list">
                  <li>
                    Select the{' '}
                    <img
                      src={camera}
                      alt="camera"
                      className="onboard-new-icon onboard-new-width-35px"
                    />
                    icon in the bottom right corner. This will bring up
                    Mailroom’s camera module.
                  </li>
                  <li>
                    Before we begin scanning, let's understand what we see on our
                    screen:
                    <ol
                      type="a"
                      className="onboard-new-instructions-list-inner"
                    >
                      <li>
                        The{' '}
                        <img
                          src={detectText}
                          alt="camera"
                          className="onboard-new-icon onboard-new-width-35px"
                        />{' '}
                        icon at the top left represents text. This icon will
                        highlight green when the text is being extracted from
                        the item’s label.
                      </li>
                      <li>
                        The{' '}
                        <img
                          src={qr}
                          alt="camera"
                          className="onboard-new-icon onboard-new-width-35px"
                        />
                        icon at the top right represents barcodes. This icon
                        will highlight green when the tracking number is
                        extracted from the item’s label.
                      </li>
                      <li>
                        At the bottom of the screen, you have two scan modes –
                        “Scan Inbound” & “Scan Outbound”. Scan inbound mode
                        should be used when carriers deliver items to your
                        facility to be picked up by the recipient. Scan outbound mode should be used when recipients give items to your staff to be shipped out by a courier.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Hover your device over the package label displayed on the
                    screen. When the{' '}
                    <img
                      src={detectText}
                      alt="camera"
                      className="onboard-new-icon onboard-new-width-35px"
                    />
                    icon is highlighted Green, click the white circle at the
                    bottom of the screen to capture the scan.
                  </li>
                  <li>
                    Select the{' '}
                    <img src={scan} alt="scan" className="onboard-new-icon" />{' '}
                    icon in the bottom right corner to move onto the “pending”
                    tab.
                  </li>
                </ol>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className="onboarding-scan-label-image-container"
              >
                <img
                  src={Reciever}
                  alt=""
                  className="onboard-new-scan-item-image"
                />
                <div className="onboard-new-scan-item-image-user-name">
                  {UserName}
                </div>
              </Grid>
            </Grid>
            <div className="onboard-new-end-para-center">
              <p>
                Pro Tip: You can select the item thumbnail in the bottom left
                corner to jump directly into the item details. Learn more about
                details on the next page!
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              disabled={waiting || disable}
              className={`onBoard-page-btn ${
                waiting || disable ? 'onBoard-page-btn-disabled' : ''
              }`}
              onClick={() => {
                CallTrigger(CLA.WELCOME.Welcome_get_started_bclick, {
                  Previous_event: '',
                });
                this.setState(
                  {
                    disable: true,
                  },
                  () => moveToNext()
                );
                //   this.setState({ waiting: true });
              }}
            >
              CONTINUE
              {(waiting || disable) && (
                <div className="ml-2">
                  <CircularProgress size={15} className="white" />
                </div>
              )}
            </Button>
          </Grid>
          {/* </form> */}
        </Grid>
      </div>
    );
  }
}

AdditionalDetail.propTypes = {
  t: PropTypes.func.isRequired,
  userDetail: PropTypes.object.isRequired,
  waiting: PropTypes.bool.isRequired,
  handleDisablePrev: PropTypes.func.isRequired,
  handleDisableNext: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userDetail: state.userDetails,
  user: state.user,
});

export default connect(mapStateToProps, null)(AdditionalDetail);
