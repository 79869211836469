/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Button } from '@material-ui/core';
import { Margin } from '../ResponsiveRelated';

const Index = ({
  children,
  loading,
  height,
  width,
  onClick,
  top,
  bottom,
  right,
  left,
  green,
  capitalize,
  style,
  disabled,
  marginStyle,
  color,
  ...others
}) => (
  <Margin
    top={top}
    bottom={bottom}
    right={right}
    left={left}
    style={marginStyle}
  >
    <Button
      variant="contained"
      style={{
        height,
        width,
        padding: '6px 10px',
        marginBottom: 0,
        borderRadius: 3,
        backgroundColor: green ? '#2ECC71' : disabled ? 'grey' : color ? color : '#027AFF',
        color: '#ffffff',
        textTransform: capitalize ? 'capitalize' : 'uppercase',
        fontSize: 13,
        fontWeight: 'bolder',
        boxShadow:
          !disabled &&
          '0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26)',
        ...style,
      }}
      onClick={onClick}
      disabled={disabled}
      {...others}
    >
      {children}
      {loading && (
        <CircularProgress
          style={{
            height: 10,
            width: 10,
            color: '#fff',
            marginLeft: 10,
          }}
        />
      )}
    </Button>
  </Margin>
);

Index.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  loading: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
  top: PropTypes.number,
  disabled: PropTypes.bool,
  bottom: PropTypes.number,
  right: PropTypes.number,
  left: PropTypes.number,
  green: PropTypes.bool,
  capitalize: PropTypes.bool,
  style: PropTypes.node,
  marginStyle: PropTypes.node,
};

Index.defaultProps = {
  loading: false,
  height: 'auto',
  width: 'auto',
  onClick: () => {},
  top: 0,
  bottom: 0,
  disabled: false,
  right: 0,
  left: 0,
  green: false,
  capitalize: false,
  style: {},
  marginStyle: {},
};

export default Index;
