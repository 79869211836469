import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import crypto from 'crypto';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  AvTimer,
  Code,
  Email,
  Lock,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { CustomInput, CustomInputLabel } from '../../UI/Input';
import { AlignCenter, VerticallyCenter } from '../../UI/ResponsiveRelated';
// import logo from '../../../shared/img/logo/logo_light.svg';
import mailroomlogo from '../../../shared/img/logo/mailroom_logo.svg';
import Slider from './components/Slider';
import Alert from '../../UI/Poper';
import UserDetail from './components/userDetail';
import { Event } from '../../../util/ga';
import { setBuildings } from '../../../redux/actions/allBuildingsActions';
import { setBuilding } from '../../../redux/actions/locationsFilterActions';
import { signin, confirmUserForceReset, setUserTenant, sendEmail, resetPassword, forceResetPassword, confirmUser, getProfile, signout } from '../../../server';
// import countriesData from './../../../util/country.json';
import citiesData from './../../../util/cities.json';
import statesData from './../../../util/states.json';
import { encryptEmailWithAES } from './../../../util/application.utils';
import { setTriggerUserID } from '../../../util/Triggers/function';

// import { changeTopBarMode } from './../../../redux/actions/sidebarActions';

const env = process.env.REACT_APP_ENV;
const regex = /^(?=.{8,32}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).*/;

function validateSearchCharacter(input) {
  const nameRegex = /[`$%^*+\=\[\]{};:"\\<>\?~|]/;
  return !nameRegex.test(String(input).toLowerCase());
}
class Index extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.node.isRequired,
    validateEmail: PropTypes.func.isRequired,
    match: PropTypes.node.isRequired,
    history: PropTypes.node.isRequired,
    i18n: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { match, location } = props;
    const url_string = `http://www.example.com/login${location.search}`;
    const url = new URL(url_string);
    let email = url.searchParams.get('detail') || '';
    let passwordKey = url.searchParams.get('key');
    let password = '';
    if (email !== null && passwordKey !== null && passwordKey.length !== 0) {
      password = this.decrypt2({
        encryptedData: passwordKey,
      });
    }
    this.state = {
      email: email || '',
      password,
      showPassword: false,
      loggedin: false,
      waiting: false,
      error: '',
      emailError: null,
      forceReset: false,
      resetCode: '',
      confirmPassword: '',
      passwordError: null,
      confirmPassError: false,
      showConfirmPass: false,
      resetSuccess: false,
      wrongCreds: false,
      temporaryPass: password,
      showTempPass: false,
      active: match.path === '/' || match.path === '/login/:detail?/:key?' ? 'login' :
        match.path === '/reset_password/:email' ? 'resetPass' : match.path === '/user_initial_details/:email' ? 'getUserDetails' :
          match.path === '/force_reset_password/:email' ? 'forceReset' : 'forgotPass',
      state: '',
      errorState: false,
      city: '',
      errorCity: false,
      country: '',
      errorCountry: false,
      statesToShow: [],
      citiesToShow: [],
      companyName: '',
      errorCompanyName: null,
      buildingName: '',
      errorBuildingName: null,
      errorMailRoom: null,
      mailroomName: '',
      zipCode: ''
    };
  }

  componentDidMount() {
    if (env && env.includes('cn')) {
      const pxAuth = localStorage.px_auth;
      this.clearFilterOnLogin();
      if (pxAuth) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ loggedin: true });
      }
    } else {
      let redirectState = localStorage.getItem('initialLoginState') ? localStorage.getItem('initialLoginState') : null;
      if (redirectState === 'FCP') {
        localStorage.removeItem('initialLoginState');
        this.redirectToPage('login', '/');
      } else if (redirectState === 'CND') {
        this.redirectToPage('getUserDetails', `/user_initial_details/${localStorage.getItem('px_user')}`);
      } else {
        Auth.currentSession()
          .then(() => this.setState({ loggedin: true }))
          .catch(() => this.setState({ loggedin: false }));
      }
    }
    this.props.history.listen(() => {
      const url = window.location.href;
      this.setState({
        active: url.includes('/reset_password') ?
          'resetPass' : url.includes('/user_initial_details') ?
            'getUserDetails' : url.includes('/force_reset_password') ?
              'forceReset' : url.includes('forgot') ? 'forgotPass' : 'login',
        disabled: url.includes('/user_initial_details') ? false : this.state.disabled
      });
    });
  }

  onClose = () => this.setState({ error: '' });

  setCountry = countrySelected => {
    this.setState({
      country: countrySelected,
      errorCountry: false,
      state: '',
      city: '',
      statesToShow: statesData.filter(obj => obj.country_id === countrySelected.country_id),
      citiesToShow: [],
      errorState: false,
      errorCity: false
    });
  }

  setCountryState = stateSelected => {
    this.setState({
      state: stateSelected,
      city: '',
      citiesToShow: citiesData.filter(obj => obj.state_id === stateSelected.state_id),
      errorState: false,
      errorCity: false
    });
  }

  getEncryptedEmail = (email, callback) => {
    let encryptedEmail = null;
    encryptEmailWithAES(email).then(res => {
      callback(res);
    });
    return encryptedEmail;
  }

  redirectToPage = (activePage, url) => {
    this.setState({ active: activePage }, () => {
      this.props.history.replace(url);
    });
  }

  handleDetailParams = (state, errorParam) => event => {
    this.setState({ [state]: event.target.value, [errorParam]: false });
  }

  showPassword = state => () => {
    this.setState({ [state]: !this.state[state] });
  };

  handleChange = state => e => {
    this.onClose();
    if (e.target) {
      this.setState(
        {
          [state]: e.target.value,
          error: '',
          wrongCreds: false,
          passwordError: state === 'confirmPassword' ? this.state.passwordError : null,
        },
        () => {
          const {
            email, password, confirmPassword,
          } = this.state;
          const { validateEmail } = this.props;
          if (email.length > 0 && password.length > 0) {
            this.setState({ emailError: email.length > 0 ? !validateEmail(email) : false });
          } else this.setState({ emailError: false });
          if (
            password.length > 0 &&
            confirmPassword.length > 0 &&
            password !== confirmPassword
          ) {
            this.setState({ confirmPassError: true });
          } else this.setState({ confirmPassError: false });
        },
      );
    }
  };

  resetStates = () => {
    this.setState({
      email: localStorage.email || '',
      password: '',
      showPassword: false,
      forceReset: false,
      resetCode: '',
      confirmPassword: '',
      passwordError: null,
      confirmPassError: false,
      showConfirmPass: false,
      temporaryPass: '',
      showTempPass: false,
    });
  };

  clearFilterOnLogin = () => {
    localStorage.removeItem('px_pagination');
    localStorage.removeItem('alternate_name');
    localStorage.removeItem('alternate_email');
    localStorage.removeItem('alternate_phone');
    localStorage.removeItem('px_uid');
    localStorage.removeItem('px_t');
    localStorage.removeItem('px_refreshing');
    localStorage.removeItem('px_stop');
    localStorage.removeItem('pxBuilding');
    localStorage.removeItem('language');
    sessionStorage.removeItem('px_tenant');
    sessionStorage.removeItem('px_scanFilter');
    sessionStorage.removeItem('px_dynamic_pagination');
  }

  selectItem = (name, errorItem, item) => {
    if (name === 'country') {
      this.setState({ [name]: item, [errorItem]: false, statesToShow: statesData.filter(obj => obj.country_id === item.country_id) });
    } else if (name === 'state') {
      this.setState({ [name]: item, [errorItem]: false, citiesToShow: citiesData.filter(obj => obj.state_id === item.state_id) });
    } else if (name === 'city') {
      this.setState({ [name]: item, [errorItem]: false });
    }
  };

  loginFlow = (response, email, t) => {
    if (localStorage.currentUser) {
      if (response.username !== localStorage.currentUser) {
        localStorage.currentUser = response.username;
        if (localStorage.px_search_suggestions) {
          localStorage.removeItem('px_search_suggestions');
        }
      }
    } else {
      localStorage.currentUser = response.username;
      localStorage.removeItem('px_search_suggestions');
    }
    Event('User', 'Login', 'Successful login');
    const langs = ['zh', 'ne', 'en', 'de', 'id', 'ja', 'ko', 'po', 'pt', 'ru', 'sp'];
    let lang = navigator.language;
    lang = lang && lang.split('-')[0];
    if (lang === 'es') lang = 'sp';
    else if (lang === 'pl') lang = 'po';
    else if (lang === 'nl') lang = 'ne';
    else if (langs.indexOf(lang) < 0) lang = 'en';
    localStorage.setItem('language', lang);
    this.props.i18n.changeLanguage(lang);
    if (env && env.includes('cn')) {
      localStorage.px_auth = btoa(JSON.stringify(response.data.login_details));
      if (
        response.data.challengeName === 'NEW_PASSWORD_REQUIRED' ||
        response.data.challengeName === 'FORCE_CHANGE_PASSWORD'
      ) {
        this.setState({ forceReset: true, active: 'forceReset', waiting: false });
        this.props.history.push(`/force_reset_password/${email}`);
        this.resetStates();
      } else {
        getProfile(response.username)
          .then(() => {
            this.setState({ loggedin: true, waiting: false });
            this.resetStates();
          }).catch(err => {
            if (err.err_code === 'F01') {
              this.setState({ loggedin: true, waiting: false });
              this.resetStates();
            } else {
              this.setState({
                waiting: false,
                wrongCreds: true,
                error:
                  err.err_code === 'F01'
                    ? t('common.error.expireAccount')
                    : err.message,
              });
              localStorage.removeItem('px_auth');
            }
          });
      }
    } else if (
      response.challengeName === 'NEW_PASSWORD_REQUIRED' ||
      response.challengeName === 'FORCE_CHANGE_PASSWORD'
    ) {
      this.setState({ forceReset: true, active: 'forceReset', waiting: false });
      this.props.history.push(`/force_reset_password/${email}`);
      this.setState({
        email: localStorage.email || '',
        showPassword: false,
        forceReset: false,
        resetCode: '',
        confirmPassword: '',
        passwordError: null,
        confirmPassError: false,
        showConfirmPass: false,
        password: '',
        showTempPass: false,
      });
    } else {
      getProfile(response.username)
        .then(() => {
          this.setState({ loggedin: true, waiting: false });
          this.resetStates();
        }).catch(err => {
          if (err.err_code === 'F01') {
            this.setState({ loggedin: true, waiting: false });
            this.resetStates();
          } else {
            this.setState({
              waiting: false,
              wrongCreds: true,
              error:
                err.err_code === 'F01'
                  ? t('common.error.expireAccount')
                  : err.message,
            });
            Auth.signOut();
          }
        });
    }
  }

  validateUserDetails = (country, state, city, companyName, buildingName, mailroomName) => {
    let errorCity = false;
    let errorState = false;
    let errorCountry = false;
    let errorCompanyName = false;
    let errorBuildingName = false;
    let errorMailRoom = false;
    if (city === '' || city === null || city === undefined) {
      errorCity = !errorCity;
      this.setState({ errorCity });
    }
    if (country === '' || country === null || country === undefined) {
      errorCountry = !errorCountry;
      this.setState({ errorCountry });
    }
    if (state === '' || state === null || state === undefined) {
      errorState = !errorState;
      this.setState({ errorState });
    }
    if (companyName === '' || companyName === null || companyName === undefined) {
      errorCompanyName = 'Please enter Company Name';
      this.setState({ errorCompanyName });
    }
    if (buildingName === '' || buildingName === null || buildingName === undefined) {
      errorBuildingName = 'Please enter building Name';
      this.setState({ errorBuildingName });
    }
    if (buildingName !== '') {
      if (!validateSearchCharacter(buildingName)) {
        errorBuildingName = 'Special Characters ( $ % ` ^ * + = | [ ] { } ; : < > ? ~ \\ \) are not allowed!';
        this.setState({ errorBuildingName });
      } else if (buildingName.length > 40) {
        errorBuildingName = 'length should not be greater than 40 characters';
        this.setState({ errorBuildingName });
      }
    }
    if (companyName !== '') {
      if (!validateSearchCharacter(companyName)) {
        errorCompanyName = 'Special Characters ( $ % ` ^ * + = | [ ] { } ; : < > ? ~ \\ \) are not allowed!';
        this.setState({ errorCompanyName });
      } else if (companyName.length > 40) {
        errorCompanyName = 'length should not be greater than 40 characters';
        this.setState({ errorCompanyName });
      }
    }
    if (mailroomName !== '') {
      if (!validateSearchCharacter(mailroomName)) {
        errorMailRoom = 'Special Characters ( $ % ` ^ * + = | [ ] { } ; : < > ? ~ \\ \) are not allowed!';
        this.setState({ errorMailRoom });
      } else if (mailroomName.length > 40) {
        errorMailRoom = 'length should not be greater than 40 characters';
        this.setState({ errorMailRoom });
      }
    }
    if (errorCity || errorState || errorCountry || errorCompanyName !== false || errorBuildingName !== false || errorMailRoom !== false) {
      return false;
    } else {
      return true;
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { t, match, dispatch } = this.props;
    const {
      email, password, resetCode, active, temporaryPass,
    } = this.state;
    localStorage.removeItem('onboarding');
    this.setState({ waiting: true, error: '' });
    if (active === 'login') {
      dispatch(setBuildings([]));
      dispatch(setBuilding([]));
      signin(email, password)
        .then(response => {
          localStorage.setItem('px_user', email);
          setTriggerUserID(response.username);
          if (response.challengeParam && response.challengeParam.userAttributes['custom:signup_state'] && response.challengeParam.userAttributes['custom:signup_state'] === 'FCP') {
            // localStorage.setItem('initialLoginState', 'FCP');
            localStorage.setItem('initialLoginTrigger', true);
            localStorage.setItem('px_user', this.state.email);
            this.setState({ forceReset: true, active: 'forceReset', waiting: false });
            this.props.history.replace(`/force_reset_password/${email}`);
            this.setState({
              email: localStorage.email || this.state.email || '',
              showPassword: false,
              forceReset: false,
              resetCode: '',
              confirmPassword: '',
              passwordError: null,
              confirmPassError: false,
              showConfirmPass: false,
              password: '',
              showTempPass: false,
              waiting: false,
              disabled: false
            });
          } else if (response.challengeParam && response.challengeParam.userAttributes['custom:signup_state'] && response.challengeParam.userAttributes['custom:signup_state'] === 'FCPO') {
            const recipientName = new Array(response.challengeParam.userAttributes.name);
            const recipientEmail = new Array(response.challengeParam.userAttributes.email);
            localStorage.setItem('userName', response.challengeParam.userAttributes.name);
            localStorage.setItem('onBoarding_email', response.challengeParam.userAttributes.email);
            localStorage.setItem('recipientsNames', JSON.stringify(recipientName));
            localStorage.setItem('recipientsEmails', JSON.stringify(recipientEmail));
            this.getEncryptedEmail(email, encryptedEmail => {
              this.props.history.replace(`/onboarding?email=${encryptedEmail}`);
            });
          } else if (response.attributes && response.attributes['custom:signup_state'] === 'CND') {
            this.setState({
              disabled: false,
              email: localStorage.email || this.state.email || '',
              showPassword: false,
              forceReset: false,
              resetCode: '',
              confirmPassword: '',
              passwordError: null,
              confirmPassError: false,
              showConfirmPass: false,
              password: '',
              showTempPass: false,
              waiting: false,
              active: 'getUserDetails',
            }, () => {
              localStorage.setItem('initialLoginState', 'CND');
              localStorage.setItem('px_user', this.state.email);
              this.props.history.replace(`/user_initial_details/${email}`);
            });
          } else if (response.attributes && response.attributes['custom:signup_state'] === 'CNOD') {
            localStorage.setItem('userName', response.attributes.name);
            localStorage.setItem('onBoarding_email', response.attributes.email);

            // dispatch(changeTopBarMode());
            this.getEncryptedEmail(email, encryptedEmail => {
              this.props.history.replace(`/onboarding?email=${encryptedEmail}`);
            });
          } else {
            localStorage.setItem('UserFromDashboard', true);
            this.loginFlow(response, email, t);
          }
        }).catch(err => {
          console.log('err >', err);
          this.setState({
            waiting: false,
            wrongCreds: true,
            error: err.code === 'NetworkError' ? t('common.error.networkError')
              : err.message.includes('null failed with error Generate callenges lambda cannot be called..')
                ? t('passwordValidation3') : err.message.includes('does not exist')
                  ? t('common.error.incorrect') : err.message.includes('Incorrect')
                    ? t('common.error.incorrect') : err.message.includes('execute') ? t('common.sessionExpired')
                      : err.response ? err.response.data.message : err.message.includes('Network') ? 'Something went wrong!' : err.message,
          });
        });
    } else if (active === 'forgotPass') {
      sendEmail(email)
        .then(() => {
          this.props.history.push(`/reset_password/${email}`);
          this.setState({ waiting: false, active: 'resetPass' });
          this.resetStates();
        })
        .catch(err => {
          this.setState({
            waiting: false,
            wrongCreds: true,
            error:
              err.code === 'UserNotFoundException'
                ? t('invalidUser')
                : err.response ? err.response.data.message : err.message,
          });
        });
    } else if (active === 'resetPass') {
      resetPassword(match.params.email, resetCode, password)
        .then(() => {
          this.props.history.push('/');
          this.setState({ resetSuccess: true, waiting: false });
          this.resetStates();
        })
        .catch(err => {
          this.setState({
            waiting: false,
            wrongCreds: true,
            error:
              err.code === 'ExpiredCodeException'
                ? t('setPassword.incorrectCode')
                : err.message.includes("confirmationCode' failed to satisfy constraint: Member must satisfy regular expression pattern")
                  ? t('setPassword.resetCodeNotSpace')
                  : err.response ? err.response.data.message : err.message,
          });
        });
    } else if (active === 'forceReset') {
      if (localStorage.getItem('UserFromDashboard')) {
        forceResetPassword(match.params.email, temporaryPass, password)
          .then(res => {
            let uuid;
            if (env && env.includes('cn')) {
              uuid = localStorage.px_uid;
            } else {
              uuid = res.username;
            }
            confirmUser(uuid)
              .then(() => {
                localStorage.removeItem('UserFromDashboard');
                this.resetStates();
                this.setState({ loggedin: true, waiting: false });
              }).catch(err =>
                this.setState({
                  waiting: false,
                  wrongCreds: true,
                  error:
                    err.code === 'NotAuthorizedException'
                      ? t('setPassword.wrongTempPassword')
                      : err.message,
                }));
          })
          .catch(err => {
            this.setState({
              waiting: false,
              wrongCreds: true,
              error:
                err.code === 'NotAuthorizedException'
                  ? t('setPassword.wrongTempPassword')
                  : err.response ? err.response.data.message : err.message,
            });
          });
      } else {
        confirmUserForceReset(match.params.email, temporaryPass, password, 'DASHBOARD')
          .then(res => {
            if (res.UserAttributes && res.UserAttributes[0].Value === 'CND') {
              localStorage.setItem('initialLoginState', 'CND');
              localStorage.setItem('px_user', match.params.email ? match.params.email : this.state.email);
              localStorage.setItem('companyDetailTrigger', true);
              this.setState({ waiting: false });
              this.props.history.replace(`/user_initial_details/${this.state.email}`);
              // this.setState({ active: 'getUserDetails' });
            }
          })
          .catch(err => {
            this.setState({
              waiting: false,
              wrongCreds: true,
              error:
                err.code === 'NotAuthorizedException'
                  ? t('setPassword.wrongTempPassword')
                  : err.response ? err.response.data.message : err.message,
            });
          });
      }
    } else if (active === 'getUserDetails') {
      const {
        country,
        state,
        city,
        companyName,
        buildingName,
        mailroomName,
        zipCode,
      } = this.state;
      this.setState({ waiting: false }, () => {
        if (this.validateUserDetails(country, state, city, companyName, buildingName, mailroomName)) {
          this.setState({ waiting: true }, () => {
            setUserTenant(companyName, buildingName, mailroomName, city.city_id, zipCode)
              .then(() => {
                localStorage.removeItem('initialLoginState');
                localStorage.removeItem('companyDetailTrigger');
                localStorage.setItem('newUserSignUp', true);
                this.props.history.replace('/dashboard');
              })
              .catch(err => {
                this.setState({
                  waiting: false,
                  wrongCreds: true,
                  error:
                    err.code === 'NotAuthorizedException'
                      ? t('setPassword.wrongTempPassword')
                      : err.response ? err.response.data.message : err.message,
                });
              });
          });
        }
      });
    }
  };

  toggle = () => this.setState({ resetSuccess: false, active: 'login' });

  decrypt2 = text => {
    const k = '69a23d3c4e3c990d00a5bf559b4f987c3d226e18421cd7bcc67d662e0cb032a3';
    const v = 'cc670e3c7f07c85240948611bc7453c2';
    let iv = new Buffer.from(v, 'hex');
    let encryptedText = new Buffer.from(text.encryptedData, 'hex');
    const algorithm = 'aes-256-cbc';
    let decipher = crypto.createDecipheriv(
      algorithm,
      new Buffer.from(k, 'hex'),
      iv,
    );
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  };

  checkUrl = () => {
    const { active } = this.state;
    const { pathname } = this.props.location;
    const redirectState = localStorage.getItem('initialLoginState');
    const initialLoginTrigger = localStorage.getItem('initialLoginTrigger');
    const companyDetailTrigger = localStorage.getItem('companyDetailTrigger');
    const paramsEmail = this.props.match.params.email;

    if (initialLoginTrigger) {
      localStorage.removeItem('initialLoginTrigger');
    } else if (paramsEmail === 'null' || paramsEmail === 'undefined') {
      this.setState({ loggedin: false, active: 'login' }, () => {
        localStorage.removeItem('initialLoginState');
        localStorage.removeItem('px_user');
        localStorage.removeItem('newUserSignUp');
        this.props.history.replace('/');
      });
    } else if (active !== 'getUserDetails' && !companyDetailTrigger && pathname.includes('/user_initial_details')) {
      Auth.currentSession()
        .then(() => this.setState({ loggedin: true, active: 'getUserDetails' }))
        .catch(() => this.setState({ loggedin: false }, () => {
          localStorage.removeItem('initialLoginState');
          localStorage.removeItem('px_user');
          localStorage.removeItem('newUserSignUp');
          this.props.history.replace('/');
        }));
      // } else if (redirectState && redirectState === 'FCP' && !pathname.includes('/force_reset_password')) {
      //   const email = localStorage.getItem('px_user');
      //   if (email) {
      //     this.setState({ loggedin: false, active: 'forceReset' }, () => {
      //       this.props.history.replace(`/force_reset_password/${email}`);
      //     });
      //   } else {
      //     localStorage.removeItem('initialLoginState');
      //     localStorage.removeItem('px_user');
      //     localStorage.removeItem('newUserSignUp');
      //     this.props.history.replace('/');
      //   }
    } else if (redirectState && redirectState === 'CND' && !companyDetailTrigger && !pathname.includes('/user_initial_details')) {
      Auth.currentSession()
        .then(() => this.setState({ loggedin: true, active: 'getUserDetails' }))
        .catch(() => this.setState({ loggedin: false }, () => {
          localStorage.removeItem('initialLoginState');
          localStorage.removeItem('px_user');
          localStorage.removeItem('newUserSignUp');
          this.props.history.replace('/');
        }));
    } else if (!companyDetailTrigger && pathname.includes('/user_initial_details')) {
      Auth.currentSession()
        .then(() => console.log('Session Established.'))
        .catch(() => this.setState({ loggedin: false }, () => {
          localStorage.removeItem('initialLoginState');
          localStorage.removeItem('px_user');
          localStorage.removeItem('newUserSignUp');
          this.props.history.replace('/');
        }));
    }
  }

  render() {
    const {
      email,
      password,
      loggedin,
      waiting,
      error,
      emailError,
      passwordError,
      showPassword,
      wrongCreds,
      active,
      resetCode,
      confirmPassword,
      resetSuccess,
      showConfirmPass,
      confirmPassError,
      temporaryPass,
      showTempPass,
      statesToShow,
      citiesToShow,
      country,
      state,
      city,
      companyName,
      buildingName,
      mailroomName,
      errorCountry,
      errorState,
      errorCity,
      errorBuildingName,
      errorCompanyName,
      errorMailRoom,
      zipCode
    } = this.state;
    // let disabled = waiting || error || emailError || passwordError ||
    //   email.length === 0 || password.length === 0 || wrongCreds;
    let disabled = waiting || emailError || passwordError ||
      email.length === 0 || password.length === 0;
    if (active === 'forgotPass') {
      // disabled = emailError || wrongCreds || waiting || email.length === 0;
      disabled = emailError || waiting || email.length === 0;
    }
    if (active === 'resetPass') {
      // disabled = waiting || error || passwordError || password.length === 0
      //   || confirmPassword.length === 0 || confirmPassError || wrongCreds;
      disabled = waiting || error || passwordError || password.length === 0
        || confirmPassword.length === 0 || confirmPassError;
    }
    if (active === 'forceReset') {
      // disabled = waiting || error || passwordError || password.length === 0 || wrongCreds ||
      //   confirmPassword.length === 0 || confirmPassError || temporaryPass.length === 0;
      disabled = waiting || error || passwordError || password.length === 0 ||
        confirmPassword.length === 0 || confirmPassError || temporaryPass.length === 0;
    }
    if (active === 'getUserDetails') {
      disabled = false;
    }

    const { t } = this.props;
    // window.onhashchange = () => {
    //     const url = window.location.href;
    //       this.setState({
    //         active: url.includes('/reset_password') ?
    //           'resetPass' : url.includes('/force_reset_password') ? 'forceReset' : url.includes('forgot') ? 'forgotPass' : 'login',
    //       });
    // };
    if (loggedin) {
      const redirectPath = localStorage.getItem('redirectPath');
      if (redirectPath) {
        localStorage.removeItem('redirectPath');
        return <Redirect to={redirectPath} />;
      } else {
        return <Redirect to="/dashboard" />;
      }
    }
    // if (pathname.includes('/') || pathname.includes('/login')) {
    //   localStorage.removeItem('initialLoginState');
    //   localStorage.removeItem('px_user');
    //   localStorage.removeItem('newUserSignUp');
    // }
    this.checkUrl();
    return (
      <Grid container className="height-100vh overflow-auto login-page">
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          {(error || resetSuccess) && (
            <Alert
              variant={error ? 'error' : 'success'}
              message={error || t('signin.changeSuccessMessage1')}
              onClose={error ? () => this.setState({ error: '' }) : () => this.toggle()}
              open
            />
          )}
          <Paper elevation={0} className="padding-3246 height-width-p height-100v">
            <a target="__blank" href="https://packagex.app/" >
              <img className="logo" src={mailroomlogo} alt="logo" />
            </a>
            <div className="height-95p">
              <VerticallyCenter>
                <div className="margin-070">
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      this.handleSubmit();
                    }}
                  >
                    <div className={`align-center-100 ${active === 'getUserDetails' && 'margin-top-70px'}`} >
                      <h3 className="heading">{active !== 'getUserDetails' ? 'Welcome to Mailroom' : 'Tell us about your company'}</h3>
                    </div>
                    <div className="align-center-100">
                      <p className="black padding-top-10p font-size-16">{active !== 'getUserDetails' ? 'The package management solution from the future' : 'Let us know about your company'}</p>
                    </div>
                    {active === 'getUserDetails' && (<div className="align-center-100">
                      <p className="black padding-bottom-30p font-size-16">and where it is located.</p>
                    </div>)}
                    {(active === 'getUserDetails') &&
                      <UserDetail
                        t={t}
                        selectItem={this.selectItem}
                        statesToShow={statesToShow}
                        citiesToShow={citiesToShow}
                        country={country}
                        state={state}
                        city={city}
                        companyName={companyName}
                        buildingName={buildingName}
                        mailroomName={mailroomName}
                        zipCode={zipCode}
                        errorCountry={errorCountry}
                        errorState={errorState}
                        errorCity={errorCity}
                        errorCompanyName={errorCompanyName}
                        errorBuildingName={errorBuildingName}
                        errorMailRoom={errorMailRoom}
                        setCountry={this.setCountry}
                        setCountryState={this.setCountryState}
                        handleChange={this.handleDetailParams}
                      />
                    }
                    {(active === 'login' || active === 'forgotPass') &&
                      <div className="form__form-group">
                        <FormControl
                          required
                          fullWidth
                          className="height-75"
                          onBlur={() => {
                            if (email.length > 0) {
                              this.setState({ emailError: email.length > 0 ? !this.props.validateEmail(email) : false });
                            }
                          }}
                        >
                          <CustomInputLabel htmlFor="adornment-password" shrink className="font-size-14">
                            Email
                        </CustomInputLabel>
                          <CustomInput
                            value={email}
                            danger={emailError || error || wrongCreds}
                            onChange={this.handleChange('email')}
                            placeholder={t('common.email')}
                            classes="black"
                            startAdornment={
                              <InputAdornment position="start">
                                <Email className="pink" />
                              </InputAdornment>
                            }
                          />
                          {emailError && (
                            <FormHelperText className="font-size-10 red">
                              {t('emailValidation')}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>}
                    {(active === 'resetPass') &&
                      <div className="form__form-group">
                        <FormControl required fullWidth className="height-75">
                          <CustomInputLabel htmlFor="adornment-text" shrink className="font-size-14">
                            {t('setPassword.resetCode')}
                          </CustomInputLabel>
                          <CustomInput
                            type="text"
                            value={resetCode}
                            danger={error || wrongCreds}
                            onChange={this.handleChange('resetCode')}
                            placeholder={t('setPassword.resetCode')}
                            startAdornment={
                              <InputAdornment position="start">
                                <Code className="pink" />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>}
                    {active === 'forceReset' &&
                      <FormControl required fullWidth className="height-75">
                        <CustomInputLabel shrink className="font-size-14">
                          {t('setPasswordForce.temporary')}
                        </CustomInputLabel>
                        <CustomInput
                          autocomplete="foo"
                          type={showTempPass ? 'text' : 'password'}
                          value={temporaryPass}
                          danger={wrongCreds}
                          inputProps={{ autocomplete: 'password' }}
                          onChange={this.handleChange('temporaryPass')}
                          placeholder={t('setPasswordForce.temporary')}
                          startAdornment={
                            <InputAdornment position="start">
                              <AvTimer className="pink" />
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton onClick={this.showPassword('showTempPass')}>
                                {showTempPass ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    }
                    {(active === 'login' || active === 'resetPass' || active === 'forceReset') &&
                      <div className="form__form-group">
                        <FormControl required fullWidth className="height-75">
                          <CustomInputLabel htmlFor="adornment-password" shrink className="font-size-14">
                            {active === 'login' ? t('common.password') : t('setPassword.newPassword')}
                          </CustomInputLabel>
                          <CustomInput
                            autocomplete="password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={password}
                            inputProps={{ autocomplete: 'password' }}
                            danger={passwordError || error || wrongCreds}
                            onChange={this.handleChange('password')}
                            onBlur={() => {
                              if (password.length > 0) {
                                this.setState({ passwordError: password.length < 6 });
                              } if (
                                active !== 'login' &&
                                password.length > 0 &&
                                // password.length < 6 &&
                                !regex.test(password)
                              ) {
                                this.setState({ passwordError: true });
                              } else if (
                                password.length > 0 &&
                                password.length < 6 &&
                                !regex.test(password)
                              ) {
                                this.setState({ passwordError: true });
                              } else this.setState({ passwordError: false });
                            }}
                            placeholder={active === 'login' ? t('common.password') : t('setPassword.newPassword')}
                            startAdornment={
                              <InputAdornment position="start">
                                <Lock className="pink" />
                              </InputAdornment>
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton onClick={this.showPassword('showPassword')}>
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {passwordError && (
                            <FormHelperText className="font-size-10 red width-375px">
                              {active === 'resetPass' || active === 'forceReset' ? t('passwordValidation') : t('passwordValidation3')}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>}
                    {(active === 'resetPass' || active === 'forceReset') &&
                      <div className="form__form-group">
                        <FormControl required fullWidth className="height-75">
                          <CustomInputLabel htmlFor="adornment-password" shrink className="font-size-14">
                            {t('setPassword.confirmNewPassword')}
                          </CustomInputLabel>
                          <CustomInput
                            type={this.state.showConfirmPass ? 'text' : 'password'}
                            value={confirmPassword}
                            danger={confirmPassError || wrongCreds}
                            onChange={this.handleChange('confirmPassword')}
                            placeholder={t('setPassword.confirmNewPassword')}
                            startAdornment={
                              <InputAdornment position="start">
                                <Lock className="pink" />
                              </InputAdornment>
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton onClick={this.showPassword('showConfirmPass')}>
                                  {showConfirmPass ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {confirmPassError && (
                            <FormHelperText className="font-size-10 red">
                              {t('passwordValidation2')}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>}
                    <Button
                      type="submit"
                      className={disabled ? 'login-btn-disabled' : 'login-btn'}
                      disabled={disabled}
                      onClick={this.handleSubmit}
                    >
                      {active === 'login' ? 'Sign in' : active === 'getUserDetails' ? 'Next' : (active === 'resetPass' || active === 'forceReset') ? 'Reset Password' : 'Send Email'}
                      &nbsp;
                      {waiting && <CircularProgress size={15} className={active === 'getUserDetails' ? 'white' : 'black'} />}
                    </Button>
                  </form>
                  <AlignCenter>
                    {active === 'login' && active !== 'getUserDetails' ? 'Forgot password?' : <span className="padding-right-5px">Back to</span>}
                    <Button
                      to={active === 'login' ? '/forgot_password' : '/'}
                      component={Link}
                      onClick={() => {
                        this.setState({
                          active: active === 'login' ? 'forgotPass' : 'login',
                          wrongCreds: false,
                          error: false,
                          showPassword: false,
                          showConfirmPass: false
                        });
                        if (active === 'getUserDetails' || active === 'forceReset') {
                          localStorage.removeItem('initialLoginState');
                          localStorage.removeItem('px_user');
                          localStorage.removeItem('newUserSignUp');
                          signout();
                        }
                      }
                      }
                      className="dark-link"
                    >
                      {' '}<div className="margin-left-m25">{active === 'login' ? 'Reset' : 'Sign In'}</div>
                    </Button>
                  </AlignCenter>
                </div>
              </VerticallyCenter>
            </div>
            <footer className="login-footer">
              <ul className="small-screen-center">
                <div className="flex">
                  <li className="margin-right-26 font-size-12 list-style-none">
                    <a href="https://packagex.io/privacy-policy/" target="__blank" className="black margin-m10">
                      Privacy Policy
                    </a>
                  </li>
                  <li className="margin-right-26 font-size-12 list-style-none">
                    <a href="https://packagex.io/terms/" target="__blank" className="black margin-m10">
                      Terms of Service
                    </a>
                  </li>
                  <li className="font-size-12 list-style-none">
                    <a href=" https://packagex.io/mailroom/support/" target="__blank" className="black margin-m10">
                      Contact Us
                    </a>
                  </li>
                </div>
              </ul>
              <span className="font-size-12 small-screen-center black">{(() => {
                switch (env && env.includes('cn')) {
                  case true: {
                    return (
                      <div>
                        © {new Date().getFullYear()} PackageX Inc, (ICP No. 18032187-2).
                      </div>
                    );
                  }
                  default: {
                    return (
                      <div>© {new Date().getFullYear()} PackageX, Inc.</div>
                    );
                  }
                }
              })()}
              </span>
            </footer>
          </Paper>
        </Grid>
        <Slider />
      </Grid>
    );
  }
}

export default withRouter(connect()(Index));
