/* eslint-disable quotes */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  FormControl,
  Grid,
  FormHelperText,
  TextField, withWidth, MuiThemeProvider, createMuiTheme,
} from '@material-ui/core';
import { blue } from "@material-ui/core/colors";
import {
  AddCircleOutline,
  CancelOutlined,
  BusinessCenter,
} from '@material-ui/icons';
import BlueButton from '../../../UI/BlueButton';
// import Switch from '../../../UI/Switch';
import './business.css';
import {
  CustomInputLabel,
  CustomInput,
  CustomTextField,
  CustomPhoneNumInput,
} from '../../../UI/Input';
import SearchDropdown from '../../../UI/SearchDropdown/SearchDropdown';
import { validateBusinessName } from '../../../../util';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
  typography: { useNextVariants: true, fontSize: 13 },
});

class AddBusiness extends Component {
  state = {};
  render() {
    const {
      t,
      name,
      email,
      phone,
      note,
      address,
      phoneError,
      office,
      alternativeName,
      handleChange,
      selectedBuilding,
      building,
      handlePhone,
      handleNotes,
      emailError,
      nameError,
      addressError,
      officeError,
      // validateBusinessName,
      addInputField,
      setAlternativeName,
      removeInputField,
      // addingAltName,
      businessCreating,
      saveBusiness,
      selectItem,
    } = this.props;
    const inputStyle = {
      fontSize: 13,
      color: '#000',
    };

    let { buildings, selectedTenant } = this.props;

    if (selectedTenant) {
      let newBuildings = [];
      buildings.map(item => {
        if (item.tenant_id === selectedTenant.tenant_id) {
          newBuildings = [...item.buildings];
        }
        return item;
      });
      buildings = [...newBuildings];
    }

    const labelStyle = { fontSize: 11 };
    const FormHelperStyle = { color: '#E74C3C' };
    const addIconStyle = {
      height: 14,
      width: 14,
      color: '#16A085',
      cursor: 'pointer',
    };
    const cancelIconStyle = {
      height: 14,
      width: 14,
      color: '#E74C3C',
      cursor: 'pointer',
    };

    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper className="just-paper card-body">
          <div className="flex card__title">
            <BusinessCenter style={{ width: 20, height: 20 }} />
            <h5 className="bold-text">{t('tables.businessInfo')}</h5>
          </div>
          <Grid container direction="row" justify="space-between">
            <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-50px">
              <FormControl required fullWidth>
                <CustomInputLabel style={labelStyle}>
                  {t('tables.name')}
                </CustomInputLabel>
                <CustomInput
                  value={name}
                  onChange={handleChange("name")}
                  style={inputStyle}
                />
                {name.length > 1 &&
                  <FormHelperText style={FormHelperStyle}>
                    {nameError ? t('common.error.nameValidation') : ''}
                  </FormHelperText>}
                {name.length < 2 && name.length > 0 &&
                  <FormHelperText style={FormHelperStyle}>{t('common.error.nameLength')}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-50px">
              <FormControl fullWidth>
                <CustomInputLabel style={labelStyle}>
                  {t('tables.email')}
                </CustomInputLabel>
                <CustomInput
                  value={email}
                  onChange={handleChange('email')}
                  style={inputStyle}
                />
                <FormHelperText style={FormHelperStyle}>
                  {emailError ? t('emailValidation') : ''}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-50px">
              <CustomPhoneNumInput
                t={t}
                placeholder={t('tables.phone')}
                value={phone}
                formClass="external-container-phone-input width-100"
                onChange={value => {
                  if (value) handlePhone('phone', value);
                  else handlePhone('phone', '');
                }}
                error={phoneError ? t('phoneValidation') : ''}
              />
            </Grid>
            <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-50px">
              <FormControl fullWidth>
                <CustomInputLabel style={labelStyle}>
                  {t('tables.office')}
                </CustomInputLabel>
                <CustomInput
                  value={office}
                  onChange={handleChange('office')}
                  style={inputStyle}
                />
                <FormHelperText style={FormHelperStyle}>
                  {officeError ? t('common.error.officeValidation') : ''}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-50px">
              <FormControl required fullWidth>
                <CustomInputLabel
                  style={labelStyle}
                  shrink={building.length > 0 || selectedBuilding}
                >
                  {t('tables.buildings')}
                </CustomInputLabel>
                {building.length > 0 ? (
                  <CustomTextField
                    value={building[0].building_name}
                    style={{ marginTop: 18 }}
                    disabled
                    InputProps={{
                      inputProps: {
                        style: {
                          inputStyle,
                          textOverflow: 'ellipsis',
                          color: 'black',
                        },
                      },
                    }}
                    InputLabelProps={{ style: labelStyle }}
                  />
                ) : (
                    <SearchDropdown
                      list={buildings.map(item => {
                        item.name = item.building_name;
                        item.id = item.building_id;
                        return item;
                      })}
                      selection={selectedBuilding}
                      selectItem={item => selectItem(item)}
                    />
                  )}
              </FormControl>
            </Grid>
            {alternativeName.map((value, index) => (
              <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-50px">
                <FormControl fullWidth>
                  <CustomInputLabel style={labelStyle}>
                    {t('members.businessAlias')}
                  </CustomInputLabel>
                  <CustomInput
                    value={value}
                    fullWidth
                    onChange={setAlternativeName(index)}
                    style={inputStyle}
                    endAdornment={
                      index === 0 ? (
                        <AddCircleOutline
                          style={addIconStyle}
                          onClick={() => addInputField()}
                        />
                      ) : (
                          <CancelOutlined
                            style={cancelIconStyle}
                            onClick={() => removeInputField(index)}
                          />
                        )
                    }
                  />
                  {value.length > 0 && !validateBusinessName(value) && (
                    <FormHelperText className="red">
                      {t('common.error.businessAliasValidation')}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ))}
            <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-50px">
              <FormControl fullWidth>
                <CustomInputLabel style={labelStyle}>
                  {t('buildings.address')}
                </CustomInputLabel>
                <CustomInput
                  value={address}
                  onChange={handleChange('address')}
                  style={inputStyle}
                />
                <FormHelperText style={FormHelperStyle}>
                  {addressError ? t('common.error.addressValidation') : ''}
                </FormHelperText>
              </FormControl>
            </Grid>
            <FormControl fullWidth>
              <Grid item xs={12} md={12} style={{ marginTop: 19 }}>
                <MuiThemeProvider theme={theme}>
                  <TextField
                    notchedOutline="false"
                    multiline
                    fullWidth
                    rows="5"
                    value={note}
                    // className={classes.margin}
                    label={t("tables.notes")}
                    variant="outlined"
                    id="mui-theme-provider-outlined-input"
                    placeholder={t("tables.noNotes")}
                    onChange={e => handleNotes(e.target.value)}
                    margin="normal"
                  />
                </MuiThemeProvider>
              </Grid>
            </FormControl>
          </Grid>
          <div
            style={{
              display: 'flex',
              justifyContent:
                window.screen.width < 500 ? 'space-around' : 'flex-end',
              marginTop: 19,
            }}
          >
            <BlueButton
              width={180}
              disabled={
                !selectedBuilding ||
                businessCreating ||
                name.length < 2 ||
                emailError ||
                nameError ||
                officeError ||
                addressError ||
                phoneError ||
                alternativeName.map(value => value.length !== 0 && !validateBusinessName(value)).indexOf(true) > -1}
              loading={businessCreating}
              onClick={saveBusiness}
            >
              {t('common.saveBusiness')}
            </BlueButton>
          </div>
        </Paper>
      </Grid>
    );
  }
}

AddBusiness.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  phoneError: PropTypes.bool.isRequired,
  office: PropTypes.string.isRequired,
  alternativeName: PropTypes.node.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedBuilding: PropTypes.node.isRequired,
  buildings: PropTypes.node.isRequired,
  building: PropTypes.node.isRequired,
  handlePhone: PropTypes.func.isRequired,
  handleNotes: PropTypes.func.isRequired,
  emailError: PropTypes.string.isRequired,
  nameError: PropTypes.string.isRequired,
  addressError: PropTypes.string.isRequired,
  officeError: PropTypes.string.isRequired,
  addInputField: PropTypes.func.isRequired,
  setAlternativeName: PropTypes.func.isRequired,
  // validateBusinessName: PropTypes.func.isRequired,
  removeInputField: PropTypes.func.isRequired,
  businessCreating: PropTypes.bool.isRequired,
  saveBusiness: PropTypes.func.isRequired,
  selectItem: PropTypes.func.isRequired,
  selectedTenant: PropTypes.node.isRequired,
};

export default withWidth()(AddBusiness);
