/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Menu,
  InputBase,
  Divider,
  InputAdornment,
  IconButton,
  Tabs,
  Tab,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  ClickAwayListener,
  withWidth,
  Badge,
} from "@material-ui/core";
import { Person, Search, BusinessCenter, SubdirectoryArrowLeft as SubdirectoryArrowLeftIcon } from "@material-ui/icons";
import { Link, withRouter } from "react-router-dom";
import Highlighter from "react-highlight-words";
import {
  Margin,
  AlignCenter,
  VerticallyCenter,
} from '../../UI/ResponsiveRelated';
import BuildingsIcon from '../../Layout/sidebar/BuildingsIcon';
import AssociatesIcon from '../../Layout/sidebar/AssociatesIcon';
import { saveSearch } from '../../../redux/actions/searchAction';
import {
  searchMember,
  searchBusiness,
  searchBuilding,
  searchAssociate,
} from '../../../server';
import { validateSearchCharacter } from '../../../util';
import { createRows } from '../../../util/createRows';

class TopbarSearch extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      inputOpen: true,
      key: '',
      tab: 'members',
      list: [],
      loading: false,
      error: null,
      anchorEl: null,
      notFound: false,
      openAlertError: false,
      errorMessage: ''
    };
    this.handleClickAway = this.handleClickAway.bind(this);
  }

  handleChange = e => {
    this.setState({
      key: e.target.value.trimLeft(),
      error: null,
      notFound: false,
      openAlertError: false
    });
  };

  handleClickAway() {
    this.setState({
      open: false,
      anchorEl: null,
      error: null,
      notFound: false,
      openAlertError: false
    });
  }

  open = e => {
    this.setState({
      open: !this.state.open,
      anchorEl: this.state.open ? null : e.currentTarget,
      error: null,
      notFound: false,
    });
  };

  switchTab = tab => {
    if (tab !== this.state.tab) {
      this.setState({ tab, list: [] }, () => {
        if (this.state.key.length > 0) {
          this.startSearching();
        }
      });
    }
  };

  startSearching = () => {
    this.setState({ error: null, notFound: false });
    const { tab, key } = this.state;
    if (!validateSearchCharacter(key)) {
      this.setState({
        openAlertError: true,
        errorMessage: this.props.t('common.error.notAllowSpecialCharacters'),
      });
    } else {
      const { t, building } = this.props;
      let buildingId = null;
      if (building.length > 0) buildingId = building[0].building_id;
      if (tab === 'members' && key.length > 0) {
        this.setState({ loading: true, list: [] });
        searchMember(key, buildingId, undefined, undefined, undefined, undefined, undefined, undefined, true)
          .then(members => {
            if (this.state.loading !== false) {
              this.setState({
                list: members,
                loading: false,
                notFound: members.length === 0,
              });
            }
          })
          .catch(error =>
            this.setState({
              loading: false,
              error: error.response && error.response.data.message,
            }),
          );
      } else if (tab === 'businesses' && key.length > 0) {
        this.setState({ loading: true, list: [] });
        searchBusiness(key, buildingId)
          .then(businesses => {
            if (this.state.loading !== false) {
              this.setState({
                list: businesses,
                loading: false,
                notFound: businesses.length === 0,
              });
            }
          })
          .catch(error =>
            this.setState({
              loading: false,
              error: error.response && error.response.data.message,
            }),
          );
      } else if (tab === 'buildings' && key.length > 0) {
        this.setState({ loading: true, list: [] });
        searchBuilding(key)
          .then(buildings => {
            if (this.state.loading !== false) {
              this.setState({
                list: buildings,
                loading: false,
                notFound: buildings.length === 0,
              });
            }
          })
          .catch(error =>
            this.setState({
              loading: false,
              error: error.response && error.response.data.message,
            }),
          );
      } else if (tab === 'associates' && key.length > 0) {
        this.setState({ loading: true, list: [] });
        searchAssociate(key, buildingId)
          .then(associates => {
            if (this.state.loading !== false) {
              this.setState({
                list: createRows(associates.Users, this.props),
                loading: false,
                notFound: associates.Users.length === 0,
              });
            }
          })
          .catch(error =>
            this.setState({
              loading: false,
              error: error.response && error.response.data.message,
            }),
          );
      } else this.setState({ error: t('search.typeTwoChars'), list: [] });
    }
  };

  render() {
    const searchClass = classNames({
      "topbar__search-field fs-14 black width-100 cursor-pointer": true,
      "topbar__search-field--open fs-14 black width-100 cursor-pointer": this.state.inputOpen,
    });
    const tabClasses = 'font-size-14 capitalize height-28px min-height-30px margin-top-bottom-10p min-width-auto';
    const { style, t, sidebar, dispatch, width, userRole } = this.props;
    const { tab, key, list, loading, error, anchorEl, notFound, openAlertError, errorMessage } = this.state;
    const placeholder = ((userRole === 'admin' || userRole === 'super_admin') || userRole === 'building_manager') ? t("search.TopBarSearch") : t("search.memberSearch");
    return (
      <div className="topbar__search width-100">
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClickAway}
            PaperProps={{
              style: {
                width: window.screen.width < 500 ? "100%" : "47%",
                overflowY: "hidden",
                borderRadius: 4,
                boxShadow: 'rgba(0, 0, 0, 0) 0px 6px 7px -4px, rgba(0, 0, 0, 0) 0px 11px 15px 1px, rgba(0, 0, 0, 0.12) 0px 4px 20px 3px',
              },
            }}
            style={{ top: -7 }}
          >
            <Margin bottom={5}>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  if (!loading) {
                    this.startSearching();
                  }
                }}
                className="align-center-100"
              >
                <InputBase
                  autoFocus
                  value={key}
                  className="width-60p fs-14px"
                  placeholder={placeholder}
                  onChange={this.handleChange}
                  inputProps={{ maxlength: 100 }}
                  startAdornment={
                    <InputAdornment position="start" style={{ margin: 0 }}>
                      <Search className="font-size-18 margin-right-5" />
                      <IconButton type="submit" className="display-none padding-0px" />
                    </InputAdornment>
                  }
                />
                <div className="top-searchBar-message">{t('common.press')} <SubdirectoryArrowLeftIcon className="font-size-14" /> {t('common.toSearch')}</div>
              </form>
            </Margin>
            <Divider />
            <Tabs className="align-center width-100">
              <Tab
                disabled={loading}
                value={tab}
                label={t('members.members')}
                className={`${tab === "members" && 'bg-blue white border-radius-25px'} ${tabClasses}`}
                onClick={() => this.switchTab('members')}
              />
              <Tab
                disabled={loading}
                value={tab}
                label={t('tables.businesses')}
                className={`${tab === "businesses" && 'bg-blue white border-radius-25px'} ${tabClasses}`}
                onClick={() => this.switchTab("businesses")}
              />
              {userRole && userRole !== 'mailroom_supervisor' &&
                <Tab
                  disabled={loading}
                  value={tab}
                  className={`${tab === "buildings" && 'bg-blue white border-radius-25px'} ${tabClasses}`}
                  label={t('tables.buildings')}
                  onClick={() => this.switchTab("buildings")}
                />}
              {userRole && userRole !== 'mailroom_supervisor' &&
                <Tab
                  disabled={loading}
                  value={tab}
                  className={`${tab === "associates" && 'bg-blue white border-radius-25px'} ${tabClasses}`}
                  label={t('tables.associates')}
                  onClick={() => this.switchTab('associates')}
                />}
            </Tabs>
            <div
              className="overflowy-scroll overflowx-hidden"
              style={{ height: list.length > 0 ? 340 : 42 }}
            >
              <Divider />
              {(loading || error) && (
                <AlignCenter>
                  <VerticallyCenter>
                    {loading && (
                      <div>
                        <CircularProgress size={15} className="blue" />{" "}
                        {` ${t("search.searching")}`}
                      </div>
                    )}
                    {error && error}
                  </VerticallyCenter>
                </AlignCenter>
              )}
              {notFound && (
                <AlignCenter>
                  <VerticallyCenter>
                    {tab === 'members'
                      ? t('members.noMembersFound')
                      : tab === 'businesses'
                        ? t('tables.noBusiness')
                        : tab === 'associates'
                          ? t('associates.noAssociates')
                          : t('tables.noBuildingsToShow')}
                  </VerticallyCenter>
                </AlignCenter>
              )}
              {
                openAlertError &&
                <AlignCenter>
                  <VerticallyCenter>
                    {errorMessage}
                  </VerticallyCenter>
                </AlignCenter>
              }
              {list.map(item => (
                <ListItem
                  button
                  component={Link}
                  style={{ height: 42, width: '100%' }}
                  onClick={() => {
                    this.setState({ anchorEl: null, open: !this.state.open });
                    dispatch(saveSearch(item));
                  }}
                  to={
                    tab === 'members'
                      ? `/member_detail/${item.member_id}`
                      : tab === 'buildings'
                        ? `/building_detail/${item.building_id}`
                        : tab === 'associates'
                          ? `/associate_detail/${item.id}`
                          : `/business_detail/${item.business_id}`
                  }
                >
                  <ListItemIcon>
                    {item.profile_picture ? (
                      <img
                        className="height-width-25px"
                        style={{ ...style.common.br_4, ...style.common.bs_df }}
                        src={`data:image/jpg;base64,${item.profile_picture}`}
                        alt="profile"
                      />
                    ) : tab === 'members' ? <Person className="grey-clr height-width-25px" />
                      : tab === 'businesses' ? <BusinessCenter className="grey-clr height-width-25px" />
                        : tab === 'associates' ? <AssociatesIcon className="grey-clr height-width-25px" />
                          : <BuildingsIcon className="grey-clr height-width-25px" active={false} />}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Highlighter
                        highlightClassName="bg-blue blue"
                        searchWords={[""]}
                        autoEscape
                        textToHighlight={item.name}
                        className="global-search-truncation" />
                    } />
                  <div style={{ ...style.colors.grey, ...style.common.fs_12 }}>
                    {
                      tab === 'members' && item && item.membership && (item.membership === 'VO_MEMBER' || item.membership === 'BOTH') ? <Badge className="badge white bg-black" style={{ fontSize: "12px" }}>
                        {'M&P Member'}
                      </Badge> :
                        tab === 'members'
                          ? t('members.member')
                          : tab === 'businesses'
                            ? t('tables.business')
                            : tab === 'associates'
                              ? t('tables.associates')
                              : t('tables.buildings')}
                  </div>
                </ListItem>
              ))}


            </div>
          </Menu>
        </ClickAwayListener>
        <InputBase
          disabled
          aria-haspopup="true"
          className={searchClass}
          style={{
            paddingRight: window.screen.width < 500 ? 0 : 30,
            marginLeft: sidebar.collapse ? 0 : width === 'xs' || width === 'sm' ? 0 : 68,
          }}
          inputProps={{ style: { cursor: 'pointer' } }}
          onClick={e => this.open(e)}
          value={this.state.key}
          placeholder={
            window.screen.width < 500 ? t('search.search') : placeholder
          }
          startAdornment={
            <Search
              onClick={this.open}
              style={{
                marginRight: window.screen.width < 500 ? 0 : 10,
                fontSize: 18,
              }}
            />
          }
        />
        {/* {
          openAlertError &&
          <Alert
            variant="error"
            open={openAlertError}
            message={errorMessage}
            onClose={() => this.setState({ openAlertError: false })}
          />
        } */}
      </div>
    );
  }
}

TopbarSearch.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  building: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => ({
  building: state.locationFilter,
  userRole: state.userRole,
}))(withRouter(withWidth()(TopbarSearch)));
