/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CachedIcon from '@material-ui/icons/Cached';
import { AlignCenter, VerticallyCenter } from '../../UI/ResponsiveRelated';
import { getPlanFeaturesArrayNew } from '../../../util/application.utils';
import { getCommaSeperatedNumber } from '../../../util/utility.functions';
import { CircularProgress, Button } from '@material-ui/core';
import TickImg from '../../../assets/Pricing/check.svg';
import CrossImg from '../../../assets/Pricing/remove.svg';
import { changeUserPlan, contactSalesTeam } from '../../../server/payment';
import CustomPlan from './CustomPlan';
import ContactSalesResponse from '../PaymentModals/ContactSalesResponse';
import PlanChangeRequested from '../PaymentModals/PlanChangeRequested';
import CustomPlanSBP from './CustomPlanSBP';
class ComparePlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlan: '',
      type: 1,
      internalLoader: true,
      plans: [],
      loadingBtns: [],
      starterFeatures: [],
      basicFeatures: [],
      premiumFeatures: [],
      loadCustomContactSales: false,
      openContactSalesResponseModal: false,
      disableBtns: false,
      starterLoader: false,
      basicLoader: false,
      premiumLoader: false,
      enterpriseLoader: false,
      openPlanChangeRequestModal: false,
      newSelectedPlan: '',
    };
  }
  componentDidMount() {
    if (this.props.buildings && this.props.buildings.length > 0) {
      const { tier } = this.props.buildings[0];
      const planType = this.props.buildings[0].billing === 'monthly' ? 1 : 0;
      let selectedTier = '';
      if (tier && tier.toLowerCase() === 'premium') {
        selectedTier = 'PREMIUM';
      } else if (tier && tier.toLowerCase() === 'basic') {
        selectedTier = 'BASIC';
      } else if (tier && tier.toLowerCase() === 'starter') {
        selectedTier = 'STARTER';
      } else if (tier && tier.toLowerCase() === 'enterprise') {
        selectedTier = 'Enterprise';
      } else if (tier && tier.toLowerCase() === 'custom') {
        selectedTier = 'Custom';
      } else if (tier && tier.toLowerCase() === 'starter_custom') {
        selectedTier = 'STARTER';
      } else if (tier && tier.toLowerCase() === 'basic_custom') {
        selectedTier = 'BASIC';
      } else if (tier && tier.toLowerCase() === 'premium_custom') {
        selectedTier = 'PREMIUM';
      }
      this.setState(
        {
          selectedPlan: selectedTier,
          type: planType,
        },
        () => {
          this.composePlans();
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.buildings &&
      prevProps.buildings.length !== this.props.buildings.length &&
      this.props.buildings.length > 0
    ) {
      // this.props.buildings[0].tier;
      const planType = this.props.buildings[0].billing === 'monthly' ? 1 : 0;
      const { tier } = this.props.buildings[0];
      let selectedTier = '';
      if (tier && tier.toLowerCase() === 'premium') {
        selectedTier = 'PREMIUM';
      } else if (tier && tier.toLowerCase() === 'basic') {
        selectedTier = 'BASIC';
      } else if (tier && tier.toLowerCase() === 'starter') {
        selectedTier = 'STARTER';
      } else if (tier && tier.toLowerCase() === 'enterprise') {
        selectedTier = 'Enterprise';
      } else if (tier && tier.toLowerCase() === 'custom') {
        selectedTier = 'Custom';
      }
      this.setState({
        selectedPlan: selectedTier,
        type: planType,
      });
    }
    if (
      this.props.paymentsDetails &&
      this.props.paymentsDetails.details.length > 0 &&
      prevProps.paymentsDetails.details.length !==
        this.props.paymentsDetails.details.length
    ) {
      this.composePlans();
    }
    if (
      this.props.buildingLoader !== prevProps.buildingLoader &&
      this.props.buildingLoader === false &&
      this.props.paymentsDetails.loading !==
        prevProps.paymentsDetails.loading &&
      this.props.paymentsDetails.loading === false
    ) {
      this.setState({
        internalLoader: false,
      });
    }
  }

  toggleTypeYearly = () => this.setState({ type: 0 });
  toggleTypeMonthly = () => this.setState({ type: 1 });

  closePlanChangeRequested = () => {
    this.setState(
      {
        openPlanChangeRequestModal: false,
      },
      () => this.props.history.push('/dashboard')
    );
  };

  closeContactSalesResponseModal = () => {
    this.setState(
      {
        openContactSalesResponseModal: false,
      },
      () => this.props.history.push('/dashboard')
    );
  };

  composePlans = () => {
    const { details } = this.props.paymentsDetails;
    let plansComposed = new Array(3);
    let starterFeature = [];
    let premiumFeature = [];
    let basicFeature = [];
    if (details.length > 0) {
      details.forEach((planDetail) => {
        let type = '';
        if (planDetail.key === 'starter') {
          plansComposed[0] = {
            type: 'STARTER',
            priceMonthly: planDetail.features.MONTHLY_PRICE,
            priceYearly: planDetail.features.ANNUALLY_PRICE,
            scanMonthly: planDetail.features.MONTHLY_SCAN_LIMIT,
            scanYearly: planDetail.features.ANNUALLY_SCAN_LIMIT,
          };
          starterFeature = getPlanFeaturesArrayNew(planDetail.features);
        } else if (planDetail.key === 'basic') {
          plansComposed[1] = {
            type: 'BASIC',
            priceMonthly: planDetail.features.MONTHLY_PRICE,
            priceYearly: planDetail.features.ANNUALLY_PRICE,
            scanMonthly: planDetail.features.MONTHLY_SCAN_LIMIT,
            scanYearly: planDetail.features.ANNUALLY_SCAN_LIMIT,
          };
          basicFeature = getPlanFeaturesArrayNew(planDetail.features);
        } else if (planDetail.key === 'premium') {
          plansComposed[2] = {
            type: 'PREMIUM',
            priceMonthly: planDetail.features.MONTHLY_PRICE,
            priceYearly: planDetail.features.ANNUALLY_PRICE,
            scanMonthly: planDetail.features.MONTHLY_SCAN_LIMIT,
            scanYearly: planDetail.features.ANNUALLY_SCAN_LIMIT,
          };
          premiumFeature = getPlanFeaturesArrayNew(planDetail.features);
        }
      });
      let loadingArray = [false, false, false];
      this.setState({
        plans: plansComposed,
        loadingBtns: loadingArray,
        internalLoader: false,
        starterFeatures: starterFeature,
        basicFeatures: basicFeature,
        premiumFeatures: premiumFeature,
      });
    }
  };

  contactSales = () => {
    const { loadingBtns } = this.state;
    const { buildings } = this.props;
    if (
      !loadingBtns ||
      loadingBtns.length === 0 ||
      loadingBtns[0] ||
      loadingBtns[1] ||
      loadingBtns[2]
    ) {
      this.setState({
        loadCustomContactSales: false,
      });
      return;
    }
    if (
      buildings &&
      buildings.length > 0 &&
      buildings[0].tier &&
      buildings[0].tenant_name
    ) {
      const { tenant_name, tier } = buildings[0];
      if (this.state.loadCustomContactSales) return;
      this.setState(
        {
          loadCustomContactSales: true,
        },
        () => {
          contactSalesTeam(tenant_name)
            .then(() => {
              this.setState(
                {
                  loadCustomContactSales: false,
                  openContactSalesResponseModal: true,
                },
                () => {}
              );
            })
            .catch((err) => {
              this.setState({ loadCustomContactSales: false });
              console.log(err);
            });
        }
      );
    } else return;
  };

  changePlan = (planType) => {
    const { loadCustomContactSales } = this.state;
    if (loadCustomContactSales) return;
    const { tenant_name, tier } = this.props.buildings[0];
    this.setBtnLoading(planType, true, () =>
      this.setState(
        {
          disableBtns: true,
        },
        () => {
          const planSelected =
            planType.charAt(0).toUpperCase() + planType.slice(1);
          changeUserPlan(tenant_name, tier, planSelected)
            .then((res) => {
              this.setBtnLoading(planType, false, () =>
                this.setState({
                  openPlanChangeRequestModal: true,
                  disableBtns: false,
                })
              );
            })
            .catch((err) => {
              this.setBtnLoading(planType, false, () =>
                this.setState({ disableBtns: false })
              );
              this.setState({ error: 'Failed to change plan!' });
              console.log(err);
            });
        }
      )
    );
  };

  setBtnLoading = (type, status, callback) => {
    if (type === 'starter') {
      this.setState({ starterLoader: status, newSelectedPlan: type });
    } else if (type === 'basic') {
      this.setState({ basicLoader: status, newSelectedPlan: type });
    } else if (type === 'premium') {
      this.setState({ premiumLoader: status, newSelectedPlan: type });
    } else if (type === 'enterprise') {
      this.setState({ enterpriseLoader: status, newSelectedPlan: type });
    }
    callback();
  };

  getButton = (plan, classBtn, classDisableBtn, disableBtn, text) => {
    const {
      starterLoader,
      basicLoader,
      premiumLoader,
      enterpriseLoader,
    } = this.state;
    const { newSelectedPlan } = this.state;
    if (disableBtn) console.log(disableBtn);
    if (starterLoader && newSelectedPlan === plan)
      return (
        <Button
          disabled={disableBtn}
          className={disableBtn ? classDisableBtn : classBtn}
          onClick={() => this.changePlan(plan, text)}
        >
          <span className="pm-cp-btn-spacer">{text}</span>{' '}
          {starterLoader ? (
            <CircularProgress size={15} className="white" />
          ) : (
            ''
          )}
        </Button>
      );
    else if (basicLoader && newSelectedPlan === plan)
      return (
        <Button
          disabled={disableBtn}
          className={disableBtn ? classDisableBtn : classBtn}
          onClick={() => this.changePlan(plan, text)}
        >
          <span className="pm-cp-btn-spacer">{text}</span>{' '}
          {basicLoader ? <CircularProgress size={15} className="white" /> : ''}
        </Button>
      );
    else if (premiumLoader && newSelectedPlan === plan)
      return (
        <Button
          disabled={disableBtn}
          className={disableBtn ? classDisableBtn : classBtn}
          onClick={() => this.changePlan(plan, text)}
        >
          <span className="pm-cp-btn-spacer">{text}</span>{' '}
          {premiumLoader ? (
            <CircularProgress size={15} className="white" />
          ) : (
            ''
          )}
        </Button>
      );
    else if (enterpriseLoader && newSelectedPlan === plan)
      return (
        <Button
          disabled={disableBtn}
          className={disableBtn ? classDisableBtn : classBtn}
          onClick={() => this.changePlan(plan, text)}
        >
          <span className="pm-cp-btn-spacer">{text}</span>{' '}
          {enterpriseLoader ? (
            <CircularProgress size={15} className="white" />
          ) : (
            ''
          )}
        </Button>
      );
    else {
      return (
        <Button
          disabled={disableBtn}
          className={classBtn}
          onClick={() => this.changePlan(plan, text)}
        >
          {text}
        </Button>
      );
    }
  };

  composeButton = (currentPlan, selectedPlan, disableBtns) => {
    if (selectedPlan.toLowerCase() === currentPlan) {
      return (
        <Button disabled className="pm-cp-current-btn">
          {this.props.t('paymentModals.comparePlans.currentPlanBtn')}
        </Button>
      );
    } else if (selectedPlan.toLowerCase() === 'starter') {
      return this.getButton(
        currentPlan,
        'pm-cp-upgrade-btn',
        'pm-cp-btn-disabled',
        disableBtns,
        'Upgrade Plan '
      );
    } else if (selectedPlan.toLowerCase() === 'basic') {
      if (currentPlan === 'premium' || currentPlan === 'enterprise') {
        return this.getButton(
          currentPlan,
          'pm-cp-upgrade-btn',
          'pm-cp-btn-disabled',
          disableBtns,
          'Upgrade Plan '
        );
      } else {
        return this.getButton(
          currentPlan,
          'pm-cp-downgrade-btn',
          'pm-cp-btn-disabled',
          disableBtns,
          'Downgrade Plan '
        );
      }
    } else if (selectedPlan.toLowerCase() === 'premium') {
      if (currentPlan === 'enterprise') {
        return this.getButton(
          currentPlan,
          'pm-cp-upgrade-btn',
          'pm-cp-btn-disabled',
          disableBtns,
          'Upgrade Plan '
        );
      } else {
        return this.getButton(
          currentPlan,
          'pm-cp-downgrade-btn',
          'pm-cp-btn-disabled',
          disableBtns,
          'Downgrade Plan '
        );
      }
    } else if (selectedPlan.toLowerCase() === 'enterprise') {
      return this.getButton(
        currentPlan,
        'pm-cp-downgrade-btn',
        'pm-cp-btn-disabled',
        disableBtns,
        'Downgrade Plan '
      );
    }
  };

  getFeaturesTable = () => {
    const {
      type,
      plans,
      selectedPlan,
      starterFeatures,
      basicFeatures,
      premiumFeatures,
      disableBtns,
    } = this.state;
    const { starterLoader, basicLoader, premiumLoader } = this.state;
    const { buildings } = this.props;
    const tier = buildings && buildings.length > 0 && buildings[0].tier;

    const monthlyFeatureHeading = ['Monthly price', 'Monthly deliveries'];
    const yearlyFeatureHeading = [
      'Price per month (paid annually)',
      'Annual deliveries',
    ];
    const generalFeatures = [
      'Delivery sites at buildings',
      'Mailroom users',
      'Text notifications (SMS)',
      'Email notifications',
      'Co-branded email notifications',
      'Custom package labels',
      'Mailroom connect',
      'APIs & webhooks',
      'Custom integrations',
      'Support',
    ];

    const getTickCrossIcon = (feature) => {
      if (feature === null) {
        return <img src={CrossImg} className="tick-cross-img" alt="No" />;
      } else if (feature === true) {
        return <img src={TickImg} className="tick-cross-img" alt="Yes" />;
      } else {
        return <img src={CrossImg} className="tick-cross-img" alt="No" />;
      }
    };
    const getYearlyPlanPrice = (price) => {
      let monthlyPrice = '';
      if (price) {
        monthlyPrice = Math.round(parseFloat(price) / 12);
        monthlyPrice = getCommaSeperatedNumber(monthlyPrice);
      }
      return monthlyPrice;
    };

    const getYearlyScans = (scans) => {
      let totalScans = '';
      if (scans) {
        totalScans = getCommaSeperatedNumber(scans);
      }
      return totalScans;
    };
    return (
      <div className="manage-plan-container-inner">
        <div className="manage-plan-features-column manage-plan-features-start-column">
          <ul className="manage-plan-features-list">
            <li className="manage-plan-general-features-headings manager-feature-heading">
              Features
            </li>
            {type === 1 ? (
              <>
                <li className="manage-plan-general-features-headings manager-feature-heading">
                  {monthlyFeatureHeading[0]}
                </li>
                <li className="manage-plan-general-features-headings manager-feature-heading">
                  {monthlyFeatureHeading[1]}
                </li>
              </>
            ) : (
              <>
                <li className="manage-plan-general-features-headings manager-feature-heading">
                  {yearlyFeatureHeading[0]}
                </li>
                <li className="manage-plan-general-features-headings manager-feature-heading">
                  {yearlyFeatureHeading[1]}
                </li>
              </>
            )}
            {generalFeatures.map((gFeatures) => (
              <li className="manage-plan-general-features-headings manager-feature-heading">
                {gFeatures}
              </li>
            ))}
            <li className="manage-plan-general-features-headings manager-feature-heading"></li>
          </ul>
        </div>
        <div className="manage-plan-splitter">
          <div className="manage-plan-features-column">
            {' '}
            {/*** Starter */}
            <ul
              className={`manage-plan-features-list ${tier &&
                (tier.toLowerCase() === 'starter' ||
                  tier.toLowerCase() === 'starter_custom') &&
                'manage-plan-selected'}`}
            >
              <li className="manage-plan-general-features manager-feature-heading-top manage-plan-starter">
                {plans[0].type}
              </li>
              {type === 1 ? (
                <>
                  <li className="manage-plan-general-features manager-feature-heading-top">
                    ${plans[0].priceMonthly}
                  </li>
                  <li className="manage-plan-general-features">
                    Up to {getYearlyScans(plans[0].scanMonthly)}
                  </li>
                </>
              ) : (
                <>
                  <li className="manage-plan-general-features manager-feature-heading-top">
                    ${getYearlyPlanPrice(plans[0].priceYearly)}
                  </li>
                  <li className="manage-plan-general-features">
                    Up to {getYearlyScans(plans[0].scanYearly)}
                  </li>
                </>
              )}
              {starterFeatures && starterFeatures.length > 0 && (
                <>
                  <li className="manage-plan-general-features">
                    {starterFeatures[0] !== null
                      ? starterFeatures[0]
                      : getTickCrossIcon(starterFeatures[0])}
                  </li>
                  <li className="manage-plan-general-features">
                    {starterFeatures[1] !== null
                      ? starterFeatures[1]
                      : getTickCrossIcon(starterFeatures[1])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(starterFeatures[2])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(starterFeatures[3])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(starterFeatures[4])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(starterFeatures[5])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(starterFeatures[6])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(starterFeatures[7])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(starterFeatures[8])}
                  </li>
                  <li className="manage-plan-general-features">
                    {starterFeatures[9] !== null
                      ? starterFeatures[9]
                      : getTickCrossIcon(starterFeatures[9])}
                  </li>
                  <li className="manage-plan-general-features">
                    {this.composeButton('starter', selectedPlan, disableBtns)}
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="manage-plan-features-column">
            {' '}
            {/*** Basic */}
            <ul
              className={`manage-plan-features-list ${tier &&
                (tier.toLowerCase() === 'basic' ||
                  tier.toLowerCase() === 'basic_custom') &&
                'manage-plan-selected'}`}
            >
              <li className="manage-plan-general-features manager-feature-heading-top manage-plan-basic">
                {plans[1].type}
              </li>
              {type === 1 ? (
                <>
                  <li className="manage-plan-general-features manager-feature-heading-top">
                    ${plans[1].priceMonthly}
                  </li>
                  <li className="manage-plan-general-features">
                    Up to {getYearlyScans(plans[1].scanMonthly)}
                  </li>
                </>
              ) : (
                <>
                  <li className="manage-plan-general-features manager-feature-heading-top">
                    ${getYearlyPlanPrice(plans[1].priceYearly)}
                  </li>
                  <li className="manage-plan-general-features">
                    Up to {getYearlyScans(plans[1].scanYearly)}
                  </li>
                </>
              )}
              {basicFeatures && basicFeatures.length > 0 && (
                <>
                  <li className="manage-plan-general-features">
                    {basicFeatures[0] !== null
                      ? basicFeatures[0]
                      : getTickCrossIcon(basicFeatures[0])}
                  </li>
                  <li className="manage-plan-general-features">
                    {basicFeatures[1] !== null
                      ? basicFeatures[1]
                      : getTickCrossIcon(basicFeatures[1])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(basicFeatures[2])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(basicFeatures[3])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(basicFeatures[4])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(basicFeatures[5])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(basicFeatures[6])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(basicFeatures[7])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(basicFeatures[8])}
                  </li>
                  <li className="manage-plan-general-features">
                    {basicFeatures[9] !== null
                      ? typeof answer !== 'boolean'
                        ? basicFeatures[9]
                        : getTickCrossIcon(basicFeatures[9])
                      : getTickCrossIcon(basicFeatures[9])}
                  </li>
                  <li className="manage-plan-general-features">
                    {this.composeButton('basic', selectedPlan, disableBtns)}
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="manage-plan-features-column">
            {' '}
            {/*** Premium */}
            <ul
              className={`manage-plan-features-list ${tier &&
                tier.toLowerCase() === 'premium' &&
                'manage-plan-selected'}`}
            >
              <li className="manage-plan-general-features manager-feature-heading-top manage-plan-premium">
                {plans[2].type}
              </li>
              {type === 1 ? (
                <>
                  <li className="manage-plan-general-features manager-feature-heading-top">
                    ${plans[2].priceMonthly}
                  </li>
                  <li className="manage-plan-general-features">
                    Up to {getYearlyScans(plans[2].scanMonthly)}
                  </li>
                </>
              ) : (
                <>
                  <li className="manage-plan-general-features manager-feature-heading-top">
                    ${getYearlyPlanPrice(plans[2].priceYearly)}
                  </li>
                  <li className="manage-plan-general-features">
                    Up to {getYearlyScans(plans[2].scanYearly)}
                  </li>
                </>
              )}
              {premiumFeatures && premiumFeatures.length > 0 && (
                <>
                  <li className="manage-plan-general-features">
                    {premiumFeatures[0] !== null
                      ? premiumFeatures[0]
                      : getTickCrossIcon(premiumFeatures[0])}
                  </li>
                  <li className="manage-plan-general-features">
                    {premiumFeatures[1] !== null
                      ? premiumFeatures[1]
                      : getTickCrossIcon(premiumFeatures[1])}
                  </li>
                  <li className="manage-plan-general-features">
                    {premiumFeatures[2] !== null
                      ? 'Yes (US & Canada)'
                      : getTickCrossIcon(premiumFeatures[2])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(premiumFeatures[3])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(premiumFeatures[4])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(premiumFeatures[5])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(premiumFeatures[6])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(premiumFeatures[7])}
                  </li>
                  <li className="manage-plan-general-features">
                    {getTickCrossIcon(premiumFeatures[8])}
                  </li>
                  <li className="manage-plan-general-features">
                    {premiumFeatures[9] !== null
                      ? premiumFeatures[9]
                      : getTickCrossIcon(premiumFeatures[9])}
                  </li>
                  <li className="manage-plan-general-features">
                    {this.composeButton('premium', selectedPlan, disableBtns)}
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="manage-plan-features-column">
            {' '}
            {/*** Enterprise */}
            <ul
              className={`manage-plan-features-list ${tier &&
                tier.toLowerCase() === 'enterprise' &&
                'manage-plan-selected'}`}
            >
              <li className="manage-plan-general-features manager-feature-heading-top manage-plan-enterprise">
                Enterprise
              </li>

              <li className="manage-plan-general-features">Contact Sales</li>
              <li className="manage-plan-general-features">Unlimited</li>

              <li className="manage-plan-general-features">Unlimited</li>
              <li className="manage-plan-general-features">Unlimited</li>
              <li className="manage-plan-general-features">
                Yes (US & Canada)
              </li>
              <li className="manage-plan-general-features">
                {getTickCrossIcon(true)}
              </li>
              <li className="manage-plan-general-features">
                {getTickCrossIcon(true)}
              </li>
              <li className="manage-plan-general-features">
                {getTickCrossIcon(true)}
              </li>
              <li className="manage-plan-general-features">
                {getTickCrossIcon(true)}
              </li>
              <li className="manage-plan-general-features">
                {getTickCrossIcon(true)}
              </li>
              <li className="manage-plan-general-features">
                {getTickCrossIcon(true)}
              </li>
              <li className="manage-plan-general-features">
                Email, 2 hr response time, Customer success manager
              </li>
              <li className="manage-plan-general-features">
                {this.composeButton('enterprise', selectedPlan, disableBtns)}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  getHeader = (hasCustomPlan) => {
    const { t } = this.props;
    const { type } = this.state;
    return (
      <>
        <div className="pm-main-heading">
          {hasCustomPlan ? 'Current Plan' : 'Compare Plans'}
        </div>
        {!hasCustomPlan && (
          <div className="pm-main-plan-container">
            {type === 1 ? (
              <span
                className="pm-main-plan-type-selected"
                onClick={() => type !== 1 && this.toggleTypeMonthly()}
              >
                {t('paymentModals.comparePlans.monthlyPlan')}
              </span>
            ) : (
              <span
                className="pm-main-plan-type"
                onClick={() => type !== 1 && this.toggleTypeMonthly()}
              >
                {t('paymentModals.comparePlans.monthlyPlan')}
              </span>
            )}
            {type === 0 ? (
              <span
                className="pm-main-plan-type-selected"
                onClick={() => type !== 0 && this.toggleTypeYearly()}
              >
                {t('paymentModals.comparePlans.yearlyPlan')}
              </span>
            ) : (
              <span
                className="pm-main-plan-type"
                onClick={() => type !== 0 && this.toggleTypeYearly()}
              >
                {t('paymentModals.comparePlans.yearlyPlan')}
              </span>
            )}
          </div>
        )}
      </>
    );
  };

  /**
   * Redirect to dashboard if legacy account
   */
  redirectIfLegacy = () => {
    this.props.buildings &&
      this.props.buildings[0] &&
      this.props.buildings[0].legacy &&
      this.props.history.replace('/dashboard');
  };

  render() {
    const {
      internalLoader,
      loadCustomContactSales,
      openContactSalesResponseModal,
      openPlanChangeRequestModal,
      type,
    } = this.state;
    const { next, t, buildings } = this.props;

    this.redirectIfLegacy();

    const hasCustomPlan =
      buildings &&
      buildings.length > 0 &&
      buildings[0].tier &&
      buildings[0].tier.toLowerCase() === 'custom';

    const hasCustomPlanSBP =
      buildings &&
      buildings.length > 0 &&
      buildings[0].tier &&
      (buildings[0].tier.toLowerCase() === 'starter_custom' ||
        buildings[0].tier.toLowerCase() === 'basic_custom' ||
        buildings[0].tier.toLowerCase() === 'premium_custom');

    const getCustomModals = () => {
      if (hasCustomPlan) {
        return <CustomPlan buildings={buildings} type={type} open={true} />;
      } else if (hasCustomPlanSBP) {
        return <CustomPlanSBP buildings={buildings} type={type} open={true} />;
      } else {
        return '';
      }
    };

    const tier = buildings && buildings.length > 0 && buildings[0].tier;

    const getComparePlansScreen = () => (
      <div className="manage-plan-container">
        {/****COMPARE PLANS SCREEN* */}
        {!internalLoader && this.getHeader(hasCustomPlan)}
        {!internalLoader && this.getFeaturesTable()}
        {!internalLoader && (
          <div className="pricing-footer">
            {t('paymentModals.comparePlans.footer.text1')}{' '}
            <span
              className="pm-footer-highlight"
              onClick={() => !loadCustomContactSales && this.contactSales()}
            >
              {t('paymentModals.comparePlans.footer.highlight')}{' '}
              {loadCustomContactSales && (
                <span className="refresh-notifications-circular">
                  <CachedIcon fontSize="small" />
                </span>
              )}
            </span>{' '}
            {t('paymentModals.comparePlans.footer.text2')}{' '}
            <span className="pm-footer-highlight">
              <a href="https://packagex.io/mailroom/pricing/" target="_blank">
                {t('paymentModals.comparePlans.footer.click')}
              </a>
            </span>
          </div>
        )}
        <PlanChangeRequested open={openPlanChangeRequestModal} t={t} />
        <ContactSalesResponse
          open={openContactSalesResponseModal}
          onClose={this.closeContactSalesResponseModal}
        />
        {/****COMPARE PLANS SCREEN* */}
      </div>
    );
    return (
      <>
        {internalLoader && (
          <div className="manage-plan-spinner-section">
            <div className="pm-main-heading-loading">Loading Plans ...</div>
            <VerticallyCenter>
              <AlignCenter>
                <CircularProgress />
              </AlignCenter>
            </VerticallyCenter>
          </div>
        )}
        {!internalLoader
          ? !hasCustomPlan && !hasCustomPlanSBP
            ? getComparePlansScreen()
            : getCustomModals()
          : ''}
      </>
    );
  }
}

ComparePlans.propTypes = {
  t: PropTypes.func.isRequired,
};

export default connect((state) => ({
  paymentsDetails: state.paymentsDetails,
  buildings: state.allBuildings,
  buildingLoader: state.loader.buildings,
}))(withRouter(ComparePlans));
