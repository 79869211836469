/* eslint- disable */

import { Amplify, Auth } from 'aws-amplify';
// import { init as initApm } from '@elastic/apm-rum';
import axios from 'axios';
import { chineseAuth, server } from './server';
import { informSlackDBCodeErrs } from '../util/informSlack';
import { CallTrigger } from '../util/Triggers/function';
import { CLA } from '../util/Triggers/triggerNames';

const env = process.env.REACT_APP_ENV;

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USERPOOLID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USERPOOLCLIENTID,
  },
});

// const apm = initApm({
//
//   // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
//   serviceName: '',
//
//   // Set custom APM Server URL (default: http://localhost:8200)
//   serverUrl: 'http://localhost:8200',
//
//   // Set service version (required for sourcemap feature)
//   serviceVersion: ''
// });

export async function signout() {
  /** Remove LocalStorage */
  localStorage.clear();
  // localStorage.removeItem('px_auth');
  // localStorage.removeItem('px_pagination');
  // localStorage.removeItem('alternate_name');
  // localStorage.removeItem('alternate_email');
  // localStorage.removeItem('alternate_phone');
  // localStorage.removeItem('px_uid');
  // localStorage.removeItem('px_t');
  // localStorage.removeItem('px_refreshing');
  // localStorage.removeItem('px_stop');
  // localStorage.removeItem('pxBuilding');
  // localStorage.removeItem('language');
  // localStorage.removeItem('px_scanFilter');
  // localStorage.removeItem('categoryFromDate_analytics');
  // localStorage.removeItem('categoryToDate_analytics');
  // localStorage.removeItem('px_Countries');
  // localStorage.removeItem('currentUser');
  // localStorage.removeItem('px_dynamic_pagination');
  // localStorage.removeItem('newUserSignUp');
  // localStorage.removeItem('UserFromDashboard');
  // localStorage.removeItem('Previous_event');
  // localStorage.removeItem('px_user');
  // localStorage.removeItem('categoryToDate_analytics');
  // localStorage.removeItem('px_scanFilter');
  // localStorage.removeItem('categoryFromDate_analytics');
  // localStorage.removeItem('onBoardingId');
  // localStorage.removeItem('px_stop');
  // localStorage.removeItem('trialUser');
  // localStorage.removeItem('trialUserUploadCSV');
  // localStorage.removeItem('userName');
  // localStorage.removeItem('selectedTanent');
  /** Remove SessionStorage */
  sessionStorage.removeItem('px_tenant');
  sessionStorage.removeItem('px_scanFilter');
  sessionStorage.removeItem('px_dynamic_pagination');
  localStorage.px_stop = true;
  if (env && env.includes('cn')) {
    if (window.location.pathname === '/' || window.location.pathname.includes('/login')) {
      // console.log('');
    } else {
      window.location.href = '/';
    }
  } else {
    try {
      // return new promise
      await Auth.signOut();
      // window.location.href = '/login';
    } catch (err) {
      console.log(err);
    }
  }
}

function iterate() {
  let time = localStorage.px_t;
  if (time) {
    time = parseInt(time, 10);
    time = (time - 60) * 1000;
  }
  setTimeout(function () {
    let stop;
    if (localStorage.px_stop) {
      try {
        stop = JSON.parse(localStorage.px_stop);
      } catch (e) {
        informSlackDBCodeErrs(e, 'src/server/auth.js', 'line # 80');
      }
    }
    if (stop) window.location.href = '/';
    else refreshToken();
  }, time);
}

export function refreshToken() {
  return new Promise((resolve, reject) => {
    if (env && env.includes('cn') && window.location.pathname !== '/') {
      localStorage.px_refreshing = true;
      if (localStorage.px_auth) {
        let refreshTkn = null;
        try {
          refreshTkn = JSON.parse(atob(localStorage.px_auth)).refresh_token;
        } catch (e) {
          informSlackDBCodeErrs(e, 'src/server/auth.js', 'line # 97');
        }
        axios
          .post(
            `${chineseAuth}/api/auth/refresh`,
            {
              refresh_token: refreshTkn,
            },
            { refresh_token: refreshTkn },
          )
          .then(response => {
            if (response && response.data) {
              localStorage.px_t = response.data.login_details.expires_in;
              localStorage.px_uid = response.data.user_details.uuid;
              try {
                localStorage.px_auth = btoa(JSON.stringify(response.data.login_details));
              } catch (e) {
                informSlackDBCodeErrs(e, 'src/server/auth.js', 'line # 114');
              }
              localStorage.px_refreshing = false;
              iterate();
              resolve(response.data);
            } else signout();
          })
          .catch(error =>
            signout()
              .then(() => reject(error))
              .catch(() => reject(error)));
      } else {
        signout()
          .then(() => reject())
          .catch(() => reject());
      }
    } else reject();
  });
}

export function signin(username, password) {
  const nonsensitiveUsername = username.toLowerCase();
  const nonsensitivepassword = password.trim();
  return new Promise((resolve, reject) => {
    if (env && env.includes('cn')) {
      axios
        .post(`${chineseAuth}/api/auth/login`, {
          username: nonsensitiveUsername,
          password: nonsensitivepassword,
        })
        .then(user => {
          if (user && user.data) {
            localStorage.px_t = user.data.login_details.expires_in;
            localStorage.px_stop = false;
            localStorage.px_refreshing = false;
            localStorage.px_uid = user.data.user_details.uuid;
            iterate(localStorage.px_t);
            resolve(user);
          } else signout();
        })
        .catch(err => reject(err));
    } else {
      Auth.signIn(nonsensitiveUsername, nonsensitivepassword)
        .then(user => resolve(user))
        .catch(err => reject(err));
    }
  });
}

export function changePassword(oldPass, newPass) {
  return new Promise((resolve, reject) => {
    if (env && env.includes('cn')) {
      let token = JSON.parse(atob(localStorage.px_auth)).access_token;
      axios
        .post(
          `${chineseAuth}/api/user/update_password`,
          {
            old_password: oldPass,
            new_password: newPass,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then(user => resolve(user))
        .catch(err => reject(err));
    } else {
      Auth.currentAuthenticatedUser()
        .then(user => Auth.changePassword(user, oldPass, newPass))
        .then(data => resolve(data))
        .catch(err => reject(err));
    }
  });
}

export function sendEmail(email) {
  const nonsensitiveemail = email.toLowerCase();
  return new Promise((resolve, reject) => {
    if (env && env.includes('cn')) {
      axios
        .post(`${chineseAuth}/api/user/generate_code`, {
          email: nonsensitiveemail,
        })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    } else {
      Auth.forgotPassword(nonsensitiveemail)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    }
  });
}

export function resetPassword(email, resetCode, newPassword) {
  return new Promise((resolve, reject) => {
    if (env && env.includes('cn')) {
      axios
        .post(`${chineseAuth}/api/user/forgot_password`, {
          email,
          reset_code: resetCode,
          new_password: newPassword,
        })
        .then(response => resolve(response))
        .catch(err => reject(err));
    } else {
      Auth.forgotPasswordSubmit(email, resetCode, newPassword)
        .then(response => resolve(response))
        .catch(error => reject(error));
    }
  });
}

export function forceResetPassword(email, temporaryPassword, newPassword) {
  return new Promise((resolve, reject) => {
    if (env && env.includes('cn')) {
      axios({
        method: 'post',
        url: `${chineseAuth}/api/user/first_login`,
        data: {
          temp_password: temporaryPassword,
          new_password: newPassword,
        },
      })
        .then(response => resolve(response))
        .catch(err => reject(err));
    } else {
      Auth.signIn(email, temporaryPassword)
        .then(user => {
          Auth.completeNewPassword(user, newPassword, {})
            .then(response => resolve(response))
            .catch(err => reject(err));
        })
        .catch(err => reject(err));
    }
  });
}

export function getProfile(username) {
  return new Promise((resolve, reject) => {
    axios
      // .get(`${server}/entity/user/${username}?include_mailrooms=true`)
      .get(`${server}/entity/user/${username}?include_mailrooms=true&include_tenant=true`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}

export function getRoles() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/roles`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}

export function confirmUser(uuid) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/entity/user/${uuid}`, { confirm_user: true })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export async function getCurrentAuthenticatedUser() {
  return new Promise((resolve, reject) => {
    Auth.currentUserInfo()
      .then(response => resolve(response))
      .catch(err => reject(err));
  });
}

export function confirmUserForceReset(email, temporaryPassword, newPassword, userPlatform, isCLA = false) {
  const bodyObj = JSON.stringify({
    user_email: email,
    old_password: temporaryPassword,
    new_password: newPassword,
    platform: userPlatform,
  });
  return new Promise((resolve, reject) => {
    fetch(`${server}/user/confirmuser`, {
      method: 'POST', // or 'PUT'
      headers: new Headers({
        'Content-Type': 'text/json',
        'x-api-key': process.env.REACT_APP_SIGNUP_KEY,
      }),
      body: bodyObj,
    })
      .then(async (response) => {
        if (response.ok) {
          let a = await response.json();
          Auth.signIn(email, newPassword)
            .then(reess => {
              localStorage.setItem('userName', reess.attributes.name);
              localStorage.setItem('onBoarding_email', reess.attributes.email);
              return resolve(a);
            })
            .catch(err => {
              console.log('err >', err);
              reject(err);
            });
        } else {
          const a = await response.json();
          if (isCLA) {
            CallTrigger(CLA.SET_PASS.Set_Password_verification_fail, { Previous_event: '', Error_desc: a.message.message });
          }
          // reject(new Error('Conflict: User Already Confirmed. Click on Sign In to proceed.'));
          if (a.message.err_code === 'C15' && a.message.message.includes('User Already')) {
            if (isCLA) {
              reject(new Error('/dashboard'));
            } else {
              window.location.href = '/dashboard';
            }
          } else {
            reject(new Error(a.type ? a.message : a.message.message));
          }
        }
      })
      .catch(error => {
        console.log('error >', error);
        reject(error);
      });
  });
}

export function stepUpdateOnboarding(step) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${server}/entity/user/status`, { last_step: step })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export function getStepOnboarding() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/user/status`)
      .then(response => {
        resolve(response);
      }).catch(error => reject(error));
  });
}

export function setUserTenantOnboarding(clientName, buildingName, buildingAddress, buildingCity, zipCode, timeZone) {
  const params = {
    onboarding: true,
    client_name: clientName,
    building_details: {
      building_name: buildingName,
      address: buildingAddress,
      city_id: buildingCity,
      zip_code: zipCode,
      time_zone: timeZone.substr(5, 6),
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/user/setusertenant`, params)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export function setMemberOnboarding(building_id, members) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/entity/user/addmembers`, {
        building_id,
        members,
      })
      .then(response => {
        resolve(response);
      }).catch(error => reject(error));
  });
}

export function setUserTenant(companyName, buildingName, mailroomName, cityId, zipCode) {
  const params = {
    client_name: companyName,
    building_details: {
      building_name: buildingName,
      // mailroom_name: mailroomName,
      city_id: cityId,
      // zip_code: zipCode
    },
  };
  if (mailroomName) {
    params.building_details.mailroom_name = mailroomName;
  }
  if (zipCode) {
    params.building_details.zip_code = zipCode;
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/user/setusertenant`, params)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}
