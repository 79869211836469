/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CardBody, Row, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { FormControl, FormHelperText, Grid, Paper, TextField } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import {
  AddCircleOutline,
  CancelOutlined,
  BusinessCenter,
} from "@material-ui/icons";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import {
  AlignCenter,
  VerticallyCenter,
} from "../../../UI/ResponsiveRelated";
import {
  CustomTextField,
  CustomInput,
  CustomInputLabel,
  CustomPhoneNumInput,
} from "../../../UI/Input";
import BlueButton from "../../../UI/BlueButton";
import { BusinessDetailStyles as styles } from '../../../../util/StyleConsts';
import { validateBusinessName } from '../../../../util';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
  typography: { useNextVariants: true, fontSize: 13 },
});

function BusinessDetail(props) {
  const {
    t,
    name,
    email,
    phone,
    office,
    emailError,
    nameError,
    addressError,
    officeError,
    // validateName,
    handlePhone,
    altName,
    note,
    address,
    phoneError,
    // style,
    loading,
    business,
    handleChange,
    buildings,
    setBuildings,
    addInputField,
    setAlternativeName,
    removeInputField,
    error,
    classes,
    saveBusiness,
    updating,
    width,
  } = props;
  setBuildings(buildings);
  const inputStyle = { fontSize: 14, color: "#000" };
  const labelStyle = { fontSize: 14, textOverflow: "ellipsis" };
  const FormHelperStyle = { color: "#FF1E59" };
  const addIconStyle = {
    height: 14,
    width: 14,
    color: "#16A085",
    cursor: "pointer",
  };
  const cancelIconStyle = {
    height: 14,
    width: 14,
    color: "#E74C3C",
    cursor: "pointer",
  };

  return (
    <Row>
      <Col md={12} lg={12} xl={12}>
        <Paper className="just-paper">
          <CardBody>
            <div className="flex card__title">
              <BusinessCenter
                style={{ height: 20, width: 20, fill: "grey" }}
              />
              <h5 className="bold-text">{t("tables.businessInfo")}</h5>
            </div>
            {error ? (
              <VerticallyCenter>
                <AlignCenter>
                  <p>
                    {" "}
                    <b> {error} </b>{" "}
                  </p>
                </AlignCenter>
              </VerticallyCenter>
            ) : (
                <Grid container direction="row" justify="space-between">
                  <Grid item xs={12} md={5} style={{ marginTop: 19 }} className="height-50px">
                    {loading ? (
                      <Skeleton width={100} />
                    ) : (
                        <FormControl fullWidth>
                          <CustomTextField
                            fullWidth
                            label={t("tables.name")}
                            value={name}
                            InputProps={{ style: inputStyle }}
                            InputLabelProps={{ style: labelStyle }}
                            onChange={handleChange("name")}
                          />
                          {name.length > 1 &&
                            <FormHelperText style={FormHelperStyle}>{nameError ? t('common.error.nameValidation') : ""}</FormHelperText>}
                          {name.length < 2 && name.length > 0 &&
                            <FormHelperText style={FormHelperStyle}>{t('common.error.nameLength')}</FormHelperText>}
                        </FormControl>
                      )}
                  </Grid>
                  <Grid item xs={12} md={5} style={{ marginTop: 19 }} className="height-50px">
                    {loading ? (
                      <Skeleton width={100} />
                    ) : (
                        <CustomTextField
                          fullWidth
                          label={t("tables.email")}
                          value={email}
                          InputProps={{ style: inputStyle }}
                          InputLabelProps={{ style: labelStyle }}
                          onChange={handleChange("email")}
                          helperText={emailError ? t("emailValidation") : ""}
                          FormHelperTextProps={{ style: FormHelperStyle }}
                        />
                      )}
                  </Grid>
                  <Grid item xs={12} md={5} style={{ marginTop: 19 }} className="height-50px">
                    <FormControl fullWidth>
                      {loading ? (
                        <Skeleton width={100} />
                      ) : (<CustomPhoneNumInput
                        placeholder={t("tables.phone")}
                        value={phone}
                        onChange={value => handlePhone("phone", value)}
                        formClass="external-container-phone-input"
                        error={phoneError ? t("phoneValidation") : ""}
                      />
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={5} style={{ marginTop: 32 }} className="height-50px">
                    {loading ? (
                      <Skeleton width={100} />
                    ) : (
                        <FormControl required fullWidth>
                          <CustomInputLabel style={labelStyle} shrink>
                            {t("tables.location")}
                          </CustomInputLabel>
                          <CustomTextField
                            fullWidth
                            disabled
                            style={{ paddingTop: 10 }}
                            value={business.building_name}
                            InputProps={{
                              inputProps: {
                                style: {
                                  inputStyle,
                                  textOverflow: "ellipsis",
                                  fontSize: "13px"
                                },
                              },
                            }}
                            InputLabelProps={{
                              style: labelStyle,
                            }}
                          />
                        </FormControl>
                      )}
                  </Grid>
                  <Grid item xs={12} md={5} style={{ marginTop: 19 }} className="height-50px">
                    {loading ? (
                      <Skeleton width={100} />
                    ) : (
                        <CustomTextField
                          fullWidth
                          label={t("tables.office")}
                          value={office}
                          InputProps={{ style: inputStyle }}
                          InputLabelProps={{ style: labelStyle }}
                          onChange={handleChange("office")}
                          helperText={officeError ? t('common.error.officeValidation') : ""}
                          FormHelperTextProps={{ style: FormHelperStyle }}
                        />
                      )}
                  </Grid>
                  {loading ? (
                    <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-50px">
                      <Skeleton width={100} />
                    </Grid>
                  ) : (
                      (!altName || (altName && altName.length === 0)
                        ? [""]
                        : altName
                      ).map((value, index) => (
                        <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-50px">
                          <FormControl fullWidth>
                            <CustomInputLabel style={labelStyle}>
                              {t("members.businessAlias")}
                            </CustomInputLabel>
                            <CustomInput
                              value={value}
                              onChange={setAlternativeName(index)}
                              style={inputStyle}
                              endAdornment={
                                (index === 0 ? (
                                  <AddCircleOutline
                                    style={addIconStyle}
                                    onClick={() => addInputField()}
                                  />
                                ) : (
                                    <CancelOutlined
                                      style={cancelIconStyle}
                                      onClick={() => removeInputField(index)}
                                    />
                                  ))
                              }
                            />
                            {value.length > 0 && !validateBusinessName(value) && (
                    <FormHelperText className="red">
                      {t('common.error.businessAliasValidation')}
                    </FormHelperText>
                  )}
                          </FormControl>
                        </Grid>
                      ))
                    )}
                </Grid>
              )}
            <Grid item xs={12} md={5} style={{ marginTop: 19 }} className="height-50px">
              {loading ? (
                <Skeleton width={100} />
              ) : (
                  <CustomTextField
                    fullWidth
                    label={t("buildings.address")}
                    value={address}
                    InputProps={{ style: inputStyle }}
                    InputLabelProps={{ style: labelStyle }}
                    onChange={handleChange("address")}
                    helperText={addressError ? t('common.error.addressValidation') : ""}
                    FormHelperTextProps={{ style: FormHelperStyle }}
                  />
                )}
            </Grid>
            {!error &&
              (loading ? (
                <Grid item xs={12} md={12} style={{ marginTop: 19 }} className="height-50px">
                  <Skeleton width={100} />
                </Grid>
              ) : (
                  <Grid item xs={12} md={12} style={{ marginTop: 19 }}>
                    <MuiThemeProvider theme={theme}>
                      <TextField
                        notchedOutline="false"
                        multiline
                        fullWidth
                        rows="5"
                        value={note}
                        className={classes.margin}
                        label={t("tables.notes")}
                        variant="outlined"
                        id="mui-theme-provider-outlined-input"
                        placeholder={t("tables.noNotes")}
                        onChange={handleChange("note")}
                        margin="normal"
                      />
                    </MuiThemeProvider>
                  </Grid>
                ))}
            {!loading && !error && (
              <div
                style={{
                  display: "flex",
                  justifyContent:
                    window.screen.width < 426 ? "center" : "flex-end",
                  marginTop: 19,
                  flexDirection: width === "xs" && "column-reverse",
                }}
              >
                <BlueButton
                  right={width !== "xs" && 10}
                  top={10}
                  bottom={10}
                  component={Link}
                  width={width !== "xs" ? 160 : 250}
                  marginStyle={
                    width === "xs" && {
                      display: "flex",
                      justifyContent: "center",
                    }
                  }
                  to="/members/businesses"
                >
                  {t("common.cancel")}
                </BlueButton>
                <BlueButton
                  top={10}
                  bottom={10}
                  width={width !== "xs" ? 160 : 250}
                  loading={updating}
                  disabled={
                    updating || 
                    name.length < 2 || 
                    emailError || 
                    nameError || 
                    phoneError || 
                    officeError || 
                    addressError ||
                    altName.map(value => value.length !== 0 && !validateBusinessName(value)).indexOf(true) > -1
                  }
                  onClick={saveBusiness}
                  marginStyle={
                    width === "xs" && {
                      display: "flex",
                      justifyContent: "center",
                    }
                  }
                >
                  {t("common.saveBusiness")}
                </BlueButton>
              </div>
            )}
          </CardBody>
        </Paper>
      </Col>
    </Row>
  );
}

BusinessDetail.propTypes = {
  t: PropTypes.func.isRequired,
  handlePhone: PropTypes.func.isRequired,
  setBuildings: PropTypes.func.isRequired,
  loading: PropTypes.string.isRequired,
  business: PropTypes.node.isRequired,
  buildings: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  office: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  altName: PropTypes.node.isRequired,
  emailError: PropTypes.bool.isRequired,
  nameError: PropTypes.bool.isRequired,
  officeError: PropTypes.bool.isRequired,
  addressError: PropTypes.bool.isRequired,
  // validateName: PropTypes.func.isRequired,
  phoneError: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  addInputField: PropTypes.func.isRequired,
  setAlternativeName: PropTypes.func.isRequired,
  removeInputField: PropTypes.func.isRequired,
  error: PropTypes.node.isRequired,
  classes: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
  saveBusiness: PropTypes.bool.isRequired,
  updating: PropTypes.func.isRequired,
};

export default connect(state => ({ buildings: state.allBuildings }))(
  withStyles(styles)(BusinessDetail),
);
