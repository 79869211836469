/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import * as React from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from "prop-types";
import {
  AlignCenter,
  VerticallyCenter,
} from "../../../../containers/UI/ResponsiveRelated";
import Switch from "../../../../containers/UI/Switch";
import BlueButton from "../../../../containers/UI/BlueButton";
import "../styles/material-ui-table.style.css";
import { getStatusEn } from "../../../../util/utility.functions";

const BlueCheckbox = withStyles({
  root: {
    // color: '#027aff',
    '&$checked': {
      color: '#027aff'
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function MaterialUITable(props) {
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.packageData.map((n) => {
        const isHolding =
          Math.floor(
            Math.abs(Date.now() - new Date(`${n.created_at || n.check_in}`)) /
            (1000 * 3600 * 24),
          ) < 10;
        const { packageStatus } = getStatusEn(n.status, n.action_status, isHolding, n.activity_type);
        return { pkgId: n.package_id, packageStatus, locId: n.building_id };
      });
      if (props.isVoTable) {
        if (newSelecteds.every(x => x.locId === packageData[0].building_id)) {
          setSelected(newSelecteds);
        }
      } else {
        setSelected(newSelecteds);
      }
      return;
    }
    setSelected([]);
  };

  const {
    t,
    loading,
    view,
    tableHeads,
    tableData,
    handleSwitchChange,
    handleSortByColumn,
    tableRows,
    sortType,
    sortBy,
    message,
    error,
    changeView,
    withoutSorting,
    setSelected,
    selected,
    packageData,
    buildingFilterFlag,
    isVoTable
  } = props;
  const sortedBy =
    sortBy && sortBy.charAt(0).toUpperCase() + sortBy.substr(1).toLowerCase();
  const overflow = !loading && (!message || !error) ? "auto" : "hidden";

  const handleClick = (event, pkgId, index, locId) => {
    const isHolding =
      Math.floor(
        Math.abs(Date.now() - new Date(`${packageData[index].created_at}` || `${packageData[index].check_in}`)) /
        (1000 * 3600 * 24),
      ) < 10;
    const { packageStatus } = getStatusEn(packageData[index].status, packageData[index].action_status, isHolding, packageData[index].activity_type);
    const selectedIndex = selected.findIndex(x => x.pkgId === pkgId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { pkgId, packageStatus, locId });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  // const isSelected = (name) => selected.indexOf(name) !== -1;
  const isSelected = (pkgId) => selected.findIndex(x => x.pkgId === pkgId) !== -1;
  const dontAllowSortIcons = (filter, name) => filter && name === 'Location';

  const disabledAll = isVoTable && !packageData.every(x => x.building_id === packageData[0].building_id);


  return (
    <div>
      {view === "filter" ? (
        <div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <BlueButton top={20} width={100} onClick={() => changeView("view", true)}>
              {t("email.save")}
            </BlueButton>
          </div>
          <Table>
            <TableBody>
              {tableHeads.map((item, index) => (
                <TableRow key={"table-header-".concat(index)}>
                  <Switch
                    checked={item.expanded}
                    value={item.name}
                    onChange={() => handleSwitchChange(index)}
                  />
                  {item.name}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <React.Fragment>
          <div style={{ overflowX: overflow, whiteSpace: "nowrap" }}>
            <Table>
              <TableHead>
                <TableRow
                  style={{ pointerEvents: (loading || tableData && tableData.length < 2) && 'none', height: 45 }}
                >
                  <TableCell padding="checkbox" className="width-60px">
                    <BlueCheckbox
                      //  indeterminate={selected.length > 0 && selected.length < tableData.length}
                      checked={tableData.length > 0 && selected.length === tableData.length}
                      className="checkboxTable"
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      disabled={disabledAll}
                    />
                  </TableCell>
                  {tableHeads.map((item, index) =>
                    item.expanded ? (
                      (item.name.indexOf(" ") && item.value.indexOf("_")) ===
                        -1 ? (
                        <TableCell
                          key={"table-header-".concat(index)}
                          classes={{ root: "table-header" }}
                          className={`padding-right-left cursor-none ${item.name.includes("Thumbnail")
                            ? "width-60px"
                            : item.name.includes("Status")
                              ? "width-100px"
                              : "width-200px"
                            }`}
                        >
                          <div
                            role="presentation"
                            style={
                              tableHeads[0].expanded
                                ? index === 1
                                  ? {
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: props.buildingDetail
                                      ? "default"
                                      : "pointer",
                                    width: "fit-content",
                                  }
                                  : {
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: props.buildingDetail
                                      ? "default"
                                      : "pointer",
                                    width: "fit-content",
                                  }
                                : {}
                            }
                            onClick={
                              !loading &&
                              (!error ||
                                message && message.toLowerCase().includes("Associates")) &&
                              (() => handleSortByColumn(item.value))
                            }
                            onKeyDown={
                              !loading &&
                              (!error ||
                                message && message.toLowerCase().includes("Associates")) &&
                              (() => handleSortByColumn(item.value))
                            }
                          >
                            {item.name !== "Thumbnail" && item.name}
                            {withoutSorting ? (
                              <div />
                            ) : !dontAllowSortIcons(buildingFilterFlag, item.name) && tableData && tableData.length > 1 ?
                              sortedBy === item.name ||
                                sortBy === item.secondaryValue ? (
                                sortType ? (
                                  <ArrowDropUp
                                    active
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <ArrowDropDown
                                    active
                                    style={{ cursor: "pointer" }}
                                  />
                                )
                              ) : (
                                <ArrowDropDown
                                  style={{ color: "#dddcdc", cursor: "pointer" }}
                                />
                              ) : null}
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell
                          key={"table-header-".concat(index)}
                          style={{ fontWeight: "bold", paddingLeft: "10px" }}
                          className={`padding-right-left ${item.name.includes("Thumbnail")
                            ? "width-60px"
                            : item.name.includes("Status") ||
                              item.name.includes("Checked")
                              ? "width-200px"
                              : "width-200px"
                            }`}
                        >
                          <span>
                            {item.name !== "Thumbnail" && item.name}
                            {(sortedBy === item.name ||
                              sortBy === item.secondaryValue) &&
                              !dontAllowSortIcons(buildingFilterFlag, item.name) &&
                              (sortType ? (
                                <ArrowDropUp active />
                              ) : (
                                <ArrowDropDown active />
                              ))}
                          </span>
                        </TableCell>
                      )
                    ) : (
                      <React.Fragment />
                    ),
                  )}
                </TableRow>
              </TableHead>
              {tableData && !loading && !error && (
                <TableBody>
                  {tableData.map((item, index) => {
                    const isItemSelected = isSelected(item.package_id);
                    const isDisabled = isVoTable && selected.length && item.building_id !== selected[0].locId;
                    // const firstItemLocationId = selected.length &&  tableData.filter(x=>x.package_id === selected )
                    return (<TableRow
                      // hover
                      // onClick={(event) => handleClick(event, item.package_id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      className="padding-right-left"
                      key={"table-rows-".concat(index)}
                    >
                      <TableCell padding="checkbox">
                        <BlueCheckbox
                          onClick={(event) => handleClick(event, item.package_id, index, item.building_id)}
                          checked={isItemSelected}
                          inputProps="aria-labelledby"
                          color="primary"
                          disabled={isDisabled}
                        />
                      </TableCell>
                      {tableRows(item, tableHeads).map(element => (
                        <React.Fragment key={"table-elements-".concat(index)}>
                          {element}
                        </React.Fragment>
                      ))}
                    </TableRow>);
                  })}
                </TableBody>
              )}
            </Table>
          </div>
        </React.Fragment>
      )}
      {!loading &&
        tableData &&
        (tableData.length > 0 || tableData.length === 0) &&
        !error &&
        message &&
        view !== "filter" && (
          <AlignCenter>
            <VerticallyCenter
              style={{ color: "#646777", paddingTop: 10, paddingBottom: 10 }}
            >
              <p>
                {message}
              </p>
            </VerticallyCenter>
          </AlignCenter>
        )}
      {loading && (
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          <AlignCenter>
            <VerticallyCenter>
              <CircularProgress className="blue" size={30} />
            </VerticallyCenter>
          </AlignCenter>
        </div>
      )}
      {(!tableData || error) && (
        <AlignCenter>
          <VerticallyCenter style={{ minHeight: "auto" }}>
            {error && <div>{error}</div>}
          </VerticallyCenter>
        </AlignCenter>
      )}
    </div>
  );
}

MaterialUITable.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  tableHeads: PropTypes.arrayOf.isRequired,
  tableData: PropTypes.arrayOf.isRequired,
  selected: PropTypes.arrayOf.isRequired,
  handleSwitchChange: PropTypes.func.isRequired,
  handleSortByColumn: PropTypes.func.isRequired,
  tableRows: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortType: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  withoutSorting: PropTypes.bool.isRequired,
  buildingDetail: PropTypes.bool.isRequired,
  packageData: PropTypes.arrayOf.isRequired,
  buildingFilterFlag: PropTypes.bool,
  isVoTable: PropTypes.bool
};

MaterialUITable.defaultProps = {
  buildingFilterFlag: false,
  isVoTable: false
};

export default MaterialUITable;
