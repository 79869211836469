/* eslint-disable */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { CircularProgress, withWidth, Grid } from '@material-ui/core';
import BuildingsTable from './components/Buildings';
import {
  SpaceBetween,
  AlignCenter,
  VerticallyCenter,
} from '../../UI/ResponsiveRelated';
import BlueButton from '../../UI/BlueButton';
import NotAuthorized from '../../UI/403/403';
import browser from '../../../util/getBrowserInfo';
import { resolveSafari } from '../../../util/resolveSafari';
import Footer from '../../UI/Footer/Footer';
import { checkAWSSession } from '../../../util/application.utils';
// import { ConfrimationModal } from './components/ConfrimationModal'
import ConfrimModal from './ConfrimModal';
import PlanUpgradeNeeded from '../PaymentModals/PlanUpgradeNeeded';
import FreeTrailExpiredInternal from '../PaymentModals/FreeTrailExpiredInternal';
import { function_checkFreeTrailExpired, function_checkPlanUpgradeNeededBuildingCheck } from '../../../util/application.utils';
import { getDaysDifference } from '../../../util/utility.functions';

class Buildings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedText: '',
      startSearch: false,
      match: props.match,
      country: null,
      encryptedEmail: null,
      loading: false,
      showPlanUpgradeNeededModal: false,
      showTrailExpiredModal: false,
    };
  }

  componentDidMount() {
    checkAWSSession((res) => {
      if (res) {
        this.setState({
          encryptedEmail: `/onboarding?email=${res}`,
        });
      }
      if (browser.name === 'Safari') {
        resolveSafari();
      }
    });
  }

  setCountry = (country) => this.setState({ country });

  changeRoute = (match) => this.setState({ match, country: null });

  handleAddBuilding = (e) => {
    const { buildings } = this.props;
    if (buildings && buildings[0] && buildings[0].tier) {
      e.preventDefault();
      this.setState({
        openErrorModel: true,
      });
    }
  };

  closePlanUpgradeNeededModal = callback =>
    this.setState({ showPlanUpgradeNeededModal: false }, () => {
      callback && callback();
    });

    closeFreeTrialExpiredModal = (callback) => {
      this.setState({ showTrailExpiredModal: false }, () => {
        if (callback) {
        callback();
      }
    });
  }

  addBuilding = () => {
    const { buildings } = this.props;
    if (buildings && buildings.length > 0 && buildings[0].tier) {
      const { showTrailExpiredModal } = this.state;
      const freeTrialExp = function_checkFreeTrailExpired(buildings, showTrailExpiredModal);
      const planUpgradeNeed = function_checkPlanUpgradeNeededBuildingCheck(buildings);
      if (freeTrialExp) {
        this.setState({
          showTrailExpiredModal: true
        });
        return;
      } else if (planUpgradeNeed) {
        this.setState({
          showPlanUpgradeNeededModal: true,
        });
        return;
      } else {
        this.props.history.push('/add_building');
      }
    } else {
      this.props.history.push('/add_building');
    }
  };

  render() {
    const { t, style, userRole, width, buildings } = this.props;
    const {
      encryptedEmail,
      openErrorModel,
      showPlanUpgradeNeededModal,
      showTrailExpiredModal
    } = this.state;
    if (encryptedEmail) {
      this.props.history.replace(encryptedEmail);
    }
    return !userRole ? (
      <VerticallyCenter>
        <AlignCenter>
          <CircularProgress className="blue" size={30} />
        </AlignCenter>
      </VerticallyCenter>
    ) : userRole === 'mailroom_supervisor' ? (
      <NotAuthorized />
    ) : (
      <Fragment>
        <div className="dashboard container">
          <Grid container>
            <Grid item xs={12} md={12}>
              <SpaceBetween
                style={{ ...style.flex.align_center, marginBottom: 10 }}
              >
                {this.state.country ? (
                  <h3 className="page-title">{this.state.country}</h3>
                ) : (
                  <h3 className="page-title">{t('buildings.info')}</h3>
                )}
                {(userRole === 'admin' || userRole === 'super_admin') && (
                  // <Link onClick={userRole === 'super_admin' ? undefined : (e) => this.handleAddBuilding(e)} to="/add_building">
                  //   <BlueButton width={160}>{t('buildings.add')}</BlueButton>
                  // </Link>   Hide CLa
                  // <Link to="/add_building">
                  <BlueButton width={160} onClick={() => this.addBuilding()}>
                    {t('buildings.add')}
                  </BlueButton>
                  // </Link>
                )}
                <ConfrimModal
                  t={t}
                  open={openErrorModel}
                  handleClose={() =>
                    this.setState({ openErrorModel: !openErrorModel })
                  }
                />
              </SpaceBetween>
            </Grid>
            <Grid item xs={12} md={12}>
              <BuildingsTable
                {...this.state}
                t={t}
                width={width}
                userRole={userRole}
                style={style}
                pagination={this.props.pagination}
                dispatch={this.props.dispatch}
                changeRoute={(m) => this.changeRoute(m)}
                setCountry={this.setCountry}
              />
            </Grid>
          </Grid>
        </div>
        <PlanUpgradeNeeded
          type="Buildings"
          open={showPlanUpgradeNeededModal}
          onClose={this.closePlanUpgradeNeededModal}
          t={t}
        />
        <FreeTrailExpiredInternal 
          type="Buildings"
          userRole={this.props.userRole}
          open={showTrailExpiredModal}
          onClose={this.closeFreeTrialExpiredModal}
          t={t}
        />
        <Footer />
      </Fragment>
    );
  }
}

Buildings.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  history: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default connect((state) => ({
  pagination: state.pagination,
  userRole: state.userRole,
  buildings: state.allBuildings,
}))(withRouter(withWidth()(Buildings)));

// export default connect(state => {
//   debugger;
//   console.log(state);
//   return {
//     pagination: state.pagination,
//     userRole: state.userRole,
//     buildings: state.buildings
//   };
// })(withRouter(withWidth()(Buildings)));
