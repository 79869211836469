/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';
import {
  Divider,
  IconButton,
  TableCell,
  Zoom,
  Tooltip,
  Grid,
  InputBase,
  InputAdornment,
  Paper,
  Button,
} from '@material-ui/core';
import { translate } from 'react-i18next';
import { Cancel, Search, Settings } from '@material-ui/icons';
import Alert from '../../../UI/Poper';
import { SpaceBetween } from '../../../UI/ResponsiveRelated';
import '../styles/buildings.style.css';
import PaginationComponent from '../../../../shared/components/PaginationWithNumbers/components/paginationComponent';
import MaterialUITable from '../../../../shared/components/MaterialUITable/components/materialUITableComponent';
import {
  setTableColumn,
  checkAWSSession,
} from '../../../../util/application.utils';
import {
  truncateLabel,
  getBuildingFromCountries,
} from '../../../../util/utility.functions';
import { setPagination } from '../../../../redux/actions/paginationAction';
import { setDynamicPagination } from '../../../../redux/actions/dynamicPaginationActions';
import { searchBuilding, getBuildings } from '../../../../server';
import { setBuilding } from '../../../../redux/actions/locationsFilterActions';
import { setTableColumnFilter } from '../../../../redux/actions/tableColumnActions';
import { sortByBuildings } from '../../../../util/sortBy';
import { validateSearchCharacter } from '../../../../util';

const BuildingsIconInactive = require('../../../Layout/sidebar/BuildingsIconInactive.png');

class BuildingsTable extends Component {
  constructor(props) {
    super(props);
    let env = process.env.REACT_APP_ENV
      ? process.env.REACT_APP_ENV
      : "development";
    const { match, t } = props;
    this.state = {
      isWeWork: env.includes("wework") || env.includes('uat'),
      limit: props.limitPerPage.buildingsLimit,
      match,
      loading: true,
      members: [],
      totalPages: 0,
      activePage: 1,
      error: null,
      noMore: false,
      buildingId: null,
      view: 'list',
      sortBy: 'name',
      sortType: true,
      searchedText: '',
      tableHeads: setTableColumn(t, env.includes("wework") || env.includes('uat') ? props.tableColumn.voBuilding : props.tableColumn.buildings),
      oneTableColumn: '',
      buildingsData: [],
      encryptedEmail: null,
    };
    this.tableRows = this.tableRows.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleSortByColumn = this.handleSortByColumn.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentDidMount() {
    checkAWSSession((res) => {
      if (res) {
        this.setState({
          encryptedEmail: `/onboarding?email=${res}`,
        });
      }
      if (this.state.match.params.id === 'country') {
        this.props.dispatch(setPagination({ route: 'buildings', offset: 0 }));
      }
      this.getBuildings();
      this.props.history.listen((location) => {
        const url = location.pathname;
        if (url && !url.includes('/country')) {
          this.props.changeRoute({ params: {} });
          this.setState(
            {
              match: { params: {} },
            },
            () => !this.state.loading && this.getBuildings()
          );
        }
      });
    });
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidUpdate(props) {
    if (this.props.lng !== props.lng) {
      const { t } = this.props;
      const { searchedText } = this.state;
      let tableHeads = setTableColumn(t, this.state.isWeWork ? props.tableColumn.voBuilding : props.tableColumn.buildings);
      if (searchedText.length !== '') {
        tableHeads[2].value = '_';
      }
      this.setState({
        tableHeads,
        buildings: this.createRows(this.state.buildingsData),
      });
    }
  }

  onPageChange = (currentActionPage, limit) => {
    let action;
    if (currentActionPage === 'next') {
      action = this.state.activePage + 1;
    } else if (currentActionPage === 'previous') {
      action = this.state.activePage - 1;
    } else {
      action = currentActionPage;
      if (limit) {
        let tempLimits = {};
        if (localStorage.px_dynamic_pagination) {
          tempLimits = JSON.parse(localStorage.px_dynamic_pagination);
          tempLimits.buildingsLimit = limit;
        } else {
          tempLimits = {
            membersLimit: this.props.limitPerPage.membersLimit,
            packagesLimit: this.props.limitPerPage.packagesLimit,
            businessesLimit: this.props.limitPerPage.businessesLimit,
            associatesLimit: this.props.limitPerPage.associatesLimit,
            buildingsLimit: limit,
          };
        }
        this.props.dispatch(setDynamicPagination(tempLimits));
        const currentPage = Math.ceil(
          this.state.buildings[0].buildingsCount / this.state.limit
        );
        if (this.state.activePage === currentPage) {
          action = Math.ceil(this.state.buildings[0].buildingsCount / limit);
        }
      }
    }
    this.props.dispatch(
      setPagination({
        route: 'buildings',
        offset: (action - 1) * (limit || this.state.limit),
      })
    );
    this.setState(
      {
        limit: limit || this.state.limit,
        loading: true,
        activePage: action,
        error: null,
        noMore: false,
      },
      () => this.getBuildings()
    );
  };

  getBuildings = () => {
    const { pagination, t, setCountry } = this.props;
    const { match, searchedText, sortBy, sortType } = this.state;
    const routes = pagination.map((pg) => pg.route);
    const routeIndex = routes.indexOf('buildings');
    const offset = routeIndex > -1 ? pagination[routeIndex].offset : 0;
    this.setState({
      activePage: Math.floor(offset / this.state.limit) + 1,
      loading: true,
      error: null,
    });
    // buildingsTableColumnHeader(t)[2].value = 'code';
    // this.setState({ tableHeads: buildingsTableColumnHeader(t) });
    if (match && match.params.id === 'country' && searchedText.length === 0) {
      getBuildings(
        this.state.limit,
        sortBy,
        sortType,
        offset,
        null,
        null,
        match.params.country
      )
        .then((buildings) => {
          if (buildings.length === 0 && this.state.activePage > 1) {
            this.onPageChange(1);
          } else {
            if (buildings.length > 0) setCountry(buildings[0].country);
            this.setState({
              buildingsData: buildings,
              buildings: this.createRows(buildings),
              loading: false,
              noMore: buildings.length < this.state.limit,
            });
          }
        })
        .catch((err) => {
          let errorMessage =
            err.message.indexOf('status code') > -1
              ? t('common.error.networkErrorMessage')
              : t('common.error.networkError');
          this.setState({
            error: errorMessage,
            loading: false,
          });
        });
    } else if (searchedText.length > 0) {
      // let { tableHeads } = this.state;
      // tableHeads[2].value = 'code_value';
      this.setState(
        {
          // sortType: sortBy !== 'name' ? true : sortType,
          // sortBy: "name",
          // tableHeads
        },
        () => {
          searchBuilding(
            searchedText,
            this.state.limit,
            offset,
            this.state.sortBy,
            this.state.sortType,
            match.params.country
          )
            .then((buildings) => {
              this.setState({
                buildings: this.createRows(buildings),
                loading: false,
                totalMembers:
                  buildings.length > 0 ? buildings[0].buildingCount : 0,
                noMore: buildings.length < 15,
              });
            })
            .catch((err) => {
              let errorMessage =
                err.message.indexOf('status code') > -1
                  ? t('common.error.networkErrorMessage')
                  : t('common.error.networkError');
              this.setState({
                error: errorMessage,
                loading: false,
              });
            });
        }
      );
    } else {
      // dispatch(setPagination({ route: "buildings", offset: (activePage - 1) * (this.state.limit) }));
      getBuildings(this.state.limit, sortBy, sortType, offset)
        .then((buildings) => {
          if (buildings.length === 0 && this.state.activePage > 1) {
            this.onPageChange(1);
          } else {
            this.setState({
              buildingsData: buildings,
              buildings: this.createRows(buildings),
              loading: false,
              noMore: buildings.length < this.state.limit,
            });
          }
        })
        .catch((err) => {
          let errorMessage =
            err.message.indexOf('status code') > -1
              ? t('common.error.networkErrorMessage')
              : t('common.error.networkError');
          this.setState({
            error: errorMessage,
            loading: false,
          });
        });
    }
  };

  handleSearchBarChange = (event) => {
    this.setState(
      {
        searchedText: event.target.value.trimLeft(),
      },
      () => {
        if (this.state.searchedText.length === 0) {
          this.handleClearSearchBar();
        }
      }
    );
  };

  createRows = (buildings) =>
    buildings.map((building) => ({
      logo: (
        <Link to={`/building_detail/${building.building_id}`}>
          <img
            style={{ borderRadius: 5, height: 32, width: 32 }}
            src={BuildingsIconInactive}
            alt=""
          />
        </Link>
      ),
      id: building.building_id || '---',
      name:
        building.name.length > 20 ? (
          <Tooltip TransitionComponent={Zoom} title={building.name}>
            <Link to={`/building_detail/${building.building_id}`}>
              {truncateLabel(building.name) || '---'}
            </Link>
          </Tooltip>
        ) : (
          <Link to={`/building_detail/${building.building_id}`}>
            {truncateLabel(building.name) || '---'}
          </Link>
        ),
      code: !building.code ? (
        <p> --- </p>
      ) : building.code.length > 20 ? (
        <Tooltip TransitionComponent={Zoom} title={building.code}>
          <p> {truncateLabel(building.code)} </p>
        </Tooltip>
      ) : (
        <p> {truncateLabel(building.code)} </p>
      ),
      city: !building.city ? (
        <p> --- </p>
      ) : building.city.length > 20 ? (
        <Tooltip TransitionComponent={Zoom} title={building.city}>
          <p> {truncateLabel(building.city)} </p>
        </Tooltip>
      ) : (
        <p> {truncateLabel(building.city)} </p>
      ),
      country: !building.country ? (
        <p> --- </p>
      ) : building.country.length > 20 ? (
        <Tooltip TransitionComponent={Zoom} title={building.country}>
          <p> {truncateLabel(building.country)} </p>
        </Tooltip>
      ) : (
        <p> {truncateLabel(building.country)} </p>
      ),
      activeMembers: (
        <Link to={`/member/building_members/${building.building_id}`}>
          {building.num_active_members || '---'}
        </Link>
      ),
      activeVoMembers: (
        <Link to={`/member/mp_members_building_members/${building.building_id}`}>
          {building.num_active_vo_members || '---'}
        </Link>
      ),
      activeBusinesses: (
        <Link to={`/member/building_businesses/${building.building_id}`}>
          {building.num_active_business || '---'}
        </Link>
      ),
      overduePackages: (
        <Link
          to={`/packages/building_packages/${building.building_id}/overdue`}
          onClick={() => this.handleRedirectToPackages(building.building_id)}
        >
          {building.overdue_packages_count || '0'}
        </Link>
      ),
      lastWeekScans: (
        <Link
          to={`/packages/building_packages/${building.building_id}/scanned`}
        >
          {building.recent_scans_count || '---'}
        </Link>
      ),
      newMembers: (
        <Link
          to={`/member/building_members/${building.building_id}/recently_added`}
        >
          {building.recent_added_members || '---'}
        </Link>
      ),
      deletedLastWeek: (
        <Link
          to={`/packages/building_packages/${building.building_id}/deleted`}
        >
          {building.deletedLastWeek || '---'}
        </Link>
      ),
      buildingsCount: building.building_count,
    }));

  handleRedirectToPackages = (buildingID) => {
    const allCountries = this.props.allCountries.length
      ? this.props.allCountries
      : JSON.parse(localStorage.getItem('px_Countries'));
    const currentBuilding = getBuildingFromCountries(allCountries, buildingID);
    this.props.dispatch(setBuilding([currentBuilding[0]]));
  };

  filterView = (name, saveTableColumn) => {
    this.setState({ [name]: this.state.view === 'list' ? 'filter' : 'list' });
    if (saveTableColumn) {
      const { tableHeads } = this.state;
      const originalColumn = this.props.tableColumn.buildings;
      const updatedBuildingsArray = tableHeads.map((item, index) => {
        let tempColumn = originalColumn[index];
        tempColumn.expanded = item.expanded;
        return tempColumn;
      });
      const tempTableColumn = {
        buildings: updatedBuildingsArray,
        bussiness: this.props.tableColumn.bussiness,
        associates: this.props.tableColumn.associates,
        members: this.props.tableColumn.members,
        voMembers: this.props.tableColumn.voMembers && this.props.tableColumn.voMembers,
        voBuilding: this.props.tableColumn.voBuilding && this.props.tableColumn.voBuilding,
      };
      this.props.dispatch(setTableColumnFilter(tempTableColumn));
    }
  };

  tableRows(item) {
    const { tableHeads, isWeWork } = this.state;
    let tableRowCells = [];

    if (isWeWork) {
      if (tableHeads[0] && tableHeads[0].expanded) {
        tableRowCells.push(
          <TableCell
            className="thumbnail-cell width-60px"
            style={{ cursor: 'default' }}
          >
            {' '}
            {item.logo}{' '}
          </TableCell>
        );
      }
      if (tableHeads[1] && tableHeads[1].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-200px">
            {' '}
            <span> {item.name} </span>{' '}
          </TableCell>
        );
      }
      if (tableHeads[2] && tableHeads[2].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-200px">
            {' '}
            {item.code}{' '}
          </TableCell>
        );
      }
      if (tableHeads[3] && tableHeads[3].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-200px">
            {' '}
            {item.city}{' '}
          </TableCell>
        );
      }
      if (tableHeads[4] && tableHeads[4].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-200px">
            {' '}
            {item.country}{' '}
          </TableCell>
        );
      }
      if (tableHeads[5] && tableHeads[5].expanded) {
        tableRowCells.push(
          <TableCell className="padding-left-60px truncated-heading width-100px">
            {' '}
            {item.activeMembers}{' '}
          </TableCell>
        );
      }
      if (tableHeads[6] && tableHeads[6].expanded) {
        tableRowCells.push(
          <TableCell className="padding-left-60px truncated-heading width-100px">
            {' '}
            {item.activeVoMembers}{' '}
          </TableCell>
        );
      }
      if (tableHeads[7] && tableHeads[7].expanded) {
        tableRowCells.push(
          <TableCell className="padding-left-60px truncated-heading width-100px">
            {' '}
            {item.activeBusinesses}{' '}
          </TableCell>
        );
      }
      if (tableHeads[8] && tableHeads[8].expanded) {
        tableRowCells.push(
          <TableCell className="padding-left-60px truncated-heading width-100px">
            {' '}
            {item.overduePackages}{' '}
          </TableCell>
        );
      }
    }

    else {
      if (tableHeads[0] && tableHeads[0].expanded) {
        tableRowCells.push(
          <TableCell
            className="thumbnail-cell width-60px"
            style={{ cursor: 'default' }}
          >
            {' '}
            {item.logo}{' '}
          </TableCell>
        );
      }
      if (tableHeads[1] && tableHeads[1].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-200px">
            {' '}
            <span> {item.name} </span>{' '}
          </TableCell>
        );
      }
      if (tableHeads[2] && tableHeads[2].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-200px">
            {' '}
            {item.code}{' '}
          </TableCell>
        );
      }
      if (tableHeads[3] && tableHeads[3].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-200px">
            {' '}
            {item.city}{' '}
          </TableCell>
        );
      }
      if (tableHeads[4] && tableHeads[4].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-200px">
            {' '}
            {item.country}{' '}
          </TableCell>
        );
      }
      if (tableHeads[5] && tableHeads[5].expanded) {
        tableRowCells.push(
          <TableCell className="padding-left-60px truncated-heading width-100px">
            {' '}
            {item.activeMembers}{' '}
          </TableCell>
        );
      }
      if (tableHeads[6] && tableHeads[6].expanded) {
        tableRowCells.push(
          <TableCell className="padding-left-60px truncated-heading width-100px">
            {' '}
            {item.activeBusinesses}{' '}
          </TableCell>
        );
      }
      if (tableHeads[7] && tableHeads[7].expanded) {
        tableRowCells.push(
          <TableCell className="padding-left-60px truncated-heading width-100px">
            {' '}
            {item.overduePackages}{' '}
          </TableCell>
        );
      }
    }

    return tableRowCells;
  }

  handleSwitchChange(index) {
    if (this.state.tableHeads[index].expanded) {
      const totalActive = [...this.state.tableHeads].filter(
        (item) => item.expanded === true
      ).length;
      if (totalActive > 1) {
        const tempArray = [...this.state.tableHeads];
        tempArray[index].expanded = !tempArray[index].expanded;
        this.setState({
          tableHeads: [...tempArray],
        });
      } else {
        this.setState({
          oneTableColumn: this.props.t('common.error.atleastOneActiveColumn'),
        });
      }
    } else {
      const tempArray = [...this.state.tableHeads];
      tempArray[index].expanded = !tempArray[index].expanded;
      this.setState({
        tableHeads: [...tempArray],
      });
    }
  }

  handleSearchBarSubmit = () => {
    let { tableHeads } = this.state;
    // tableHeads[2].value = '_';
    if (!validateSearchCharacter(this.state.searchedText)) {
      this.setState({
        oneTableColumn: this.props.t('common.error.notAllowSpecialCharacters'),
      });
    } else {
      this.setState(
        {
          tableHeads,
        },
        () => this.onPageChange(1)
      );
    }
  };

  handleClearSearchBar = () => {
    let { tableHeads } = this.state;
    tableHeads[2].value = 'code';
    this.setState(
      {
        sortBy: 'name',
        sortType: true,
        tableHeads,
        searchedText: '',
      },
      () => this.getBuildings()
    );
  };

  handleSortByColumn(columnName) {
    let { sortBy, sortType } = this.state;
    if (sortBy === sortByBuildings[columnName]) sortType = !sortType;
    else if (!sortByBuildings[columnName]) sortType = false;
    else sortType = true;

    this.setState(
      {
        loading: true,
        sortBy: columnName,
        sortType,
      },
      () => this.getBuildings()
    );
  }

  render() {
    if (this.state.encryptedEmail) {
      this.props.history.replace(this.state.encryptedEmail);
    }
    if (window.history && window.history.pushState) {
      window.onpopstate = () => {
        const url = window.location.href;
        if (url && !url.includes('/country')) {
          this.props.changeRoute({ params: {} });
          this.setState(
            {
              match: { params: {} },
            },
            () => !this.state.loading && this.getBuildings()
          );
        } else if (url && url.includes('/country')) {
          const match = {
            params: { id: 'country', country: url.split('/').pop() },
            path: '/buildings/:id?/:country?',
            url: '/buildings/country/US',
          };
          this.props.changeRoute(match);
          this.setState(
            {
              match,
            },
            () => !this.state.loading && this.getBuildings()
          );
        }
      };
    }
    const {
      buildings,
      loading,
      error,
      buildingId,
      view,
      tableHeads,
      sortBy,
      sortType,
      activePage,
      oneTableColumn,
      searchedText,
    } = this.state;
    const { style, t, width } = this.props;
    const showingColumns = [...tableHeads].filter(
      (item) => item.expanded === true
    );
    const activeColumns =
      showingColumns.length === tableHeads.length
        ? t('associates.showing')
        : `Showing ${showingColumns.length} of ${tableHeads.length} columns`;

    if (buildingId) return <Redirect to={`/building_detail/${buildingId}`} />;
    return (
      <Paper className="paper">
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="space-between-with-767-block align-items-center margin-0px padding-0-14px padding-0-15px">
            {width !== 'xs' && (
              <div className="align-items-center font-size-14 black font-weight-bold width-100">
                {t('tables.buildings')}
                {this.state.match.params.id === 'country' && (
                  <Button
                    to="/buildings"
                    component={Link}
                    onClick={() => this.props.changeRoute({ params: {} })}
                    className="fs-14px blue"
                  >
                    {t('package.clearFilter')}
                  </Button>
                )}
              </div>
            )}
            <div className="topbar__search width-100 margin-0px">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!loading && searchedText.length !== 0) {
                    this.handleSearchBarSubmit();
                  }
                }}
                className="width-100"
              >
                <InputBase
                  aria-haspopup="true"
                  className="topbar__search-field topbar__search-field--open search margin-4px fs-14px"
                  inputProps={{
                    style: { cursor: 'pointer', textOverflow: 'ellipsis' },
                    maxlength: 100,
                  }}
                  value={searchedText}
                  onChange={this.handleSearchBarChange}
                  placeholder={t('members.searchBuilding')}
                  startAdornment={
                    <Search
                      style={{
                        marginRight: window.screen.width < 500 ? 0 : 10,
                        fontSize: 18,
                      }}
                    />
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      {this.state.searchedText.length > 0 ? (
                        <IconButton
                          style={{ padding: 14 }}
                          onClick={this.handleClearSearchBar}
                        >
                          <Cancel className="cross-btn" />
                        </IconButton>
                      ) : (
                        <div />
                      )}
                    </InputAdornment>
                  }
                />
              </form>
            </div>
          </div>
        </Grid>
        <Divider />
        <Grid
          item
          className="container"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          {oneTableColumn && (
            <Alert
              variant="error"
              open
              message={oneTableColumn}
              onClose={() => this.setState({ oneTableColumn: '' })}
            />
          )}
          <SpaceBetween>
            <div className="justify-center">
              <p className="font-in-bold">{activeColumns}</p>
            </div>
            <IconButton
              disabled={loading}
              onClick={() => this.filterView('view')}
            >
              <Settings
                style={
                  view === 'filter'
                    ? {
                      ...style.common.fs_14,
                      ...style.colors.blue,
                      fontSize: 20,
                    }
                    : {
                      ...style.common.fs_14,
                      fontSize: 20,
                    }
                }
              />
            </IconButton>
          </SpaceBetween>
          <Divider />
          {
            <MaterialUITable
              t={t}
              loading={loading}
              view={view}
              tableHeads={tableHeads}
              tableData={buildings}
              handleSwitchChange={this.handleSwitchChange}
              handleSortByColumn={this.handleSortByColumn}
              tableRows={this.tableRows}
              sortBy={sortBy}
              sortType={sortType}
              message={
                !loading &&
                !error &&
                buildings.length === 0 &&
                t('tables.noBuildingsToShow')
              }
              error={error}
              changeView={this.filterView}
              sortDataColumns={sortByBuildings}
            />
          }
          {view === 'list' && buildings && buildings.length > 0 && !error && (
            <div className="table-pagination">
              <PaginationComponent
                width={width}
                loading={loading}
                totalItems={buildings[0].buildingsCount}
                activePage={activePage}
                itemsPerPage={this.state.limit}
                availableItems={buildings.length}
                onChange={this.onPageChange}
                showLimit
              />
            </div>
          )}
        </Grid>
      </Paper>
    );
  }
}

BuildingsTable.propTypes = {
  t: PropTypes.func.isRequired,
  lng: PropTypes.string.isRequired,
  style: PropTypes.node.isRequired,
  match: PropTypes.node.isRequired,
  pagination: PropTypes.node.isRequired,
  history: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  changeRoute: PropTypes.func.isRequired,
  setCountry: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  limitPerPage: PropTypes.number.isRequired,
  allCountries: PropTypes.node.isRequired,
  tableColumn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tableColumn: state.tableColumn,
  limitPerPage: state.limitPerPage,
  allCountries: state.countries,
});

export default translate('common')(
  withRouter(connect(mapStateToProps)(BuildingsTable))
);
