/* eslint-disable */

import styled from "styled-components";
import { DialogContent, Grid } from "@material-ui/core";

export const AddPaymentMethod = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  color: #1da8eb;
  margin-left: 35px;
  margin-top: 0.5rem;
  &:hover {
    text-decoration: underline;
  }
`;
export const ContentContainer = styled(Grid)`
  padding: 1rem 1.5rem;
  // border: 1px solid red;
`;
export const Amount = styled.div`
  font-family: Muli, sans-serif !important;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
`;
export const Details = styled.div`
  font-size: 13px;
  color: #737a92;
`;
export const SectionHeading = styled.div`
  font-weight: 700;
  padding-left: 1.5rem;
  margin: 0.5rem 0;
  font-size: 15px;
  font-family: Muli, sans-serif !important;
`;
export const Back = styled.img`
  width: 70px;
  cursor: pointer;
  @media (max-width: 350px) {
    width: 65px;
    margin-left: 10px;
  }
`;
export const Title = styled.div`
  text-align: center;
  color: #23201f;
  font-weight: 700;
  font-size: 24px;
  font-family: Muli, sans-serif !important;
  @media (max-width: 600px) {
    margin-top: 1rem;
  }
  @media (max-width: 480px) {
    font-size: 21px;
  }
  @media (max-width: 350px) {
    font-size: 20px;
  }
`;
export const Content = styled(DialogContent)`
  font-family: Muli, sans-serif !important;
  line-height: 1.4;
  padding: 0;
`;
export const CaptionText = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  padding: 0 1.5rem;
  font-size: 15px;
  @media (max-width: 480px) {
    font-size: 14px;
  }
  @media (max-width: 350px) {
    line-height: 1.5;
    font-size: 13.5px;
  }
`;
export const Button = styled.button`
  font-size: 13px;
  cursor: pointer;
  color: white;
  background: ${(props) => (props.bg ? props.bg : "#eee")};
  padding: 0.75rem 0;
  width: ${(props) => props.width && props.width};
  border-radius: 5px;
  border: none;
  outline: none;
  margin-bottom: 1rem;
  box-shadow: 5px 5px 10px #ccc;
  @media (max-width: 480px) {
    font-size: 11px;
    line-height: 1.3;
  }
`;
