/* eslint-disable no-plusplus */
import React, { PureComponent } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { withWidth } from '@material-ui/core';
import ReactHighcharts from 'react-highcharts';
// import { LineChart, Line, Tooltip, ResponsiveContainer } from 'recharts';
import { VerticallyCenter, AlignCenter } from '../../../UI/ResponsiveRelated';

class SimpleLineChart extends PureComponent {
  render() {
    const {
      scanDetailLoading,
      scanDetailError,
      data,
      sidebar,
      width,
    } = this.props;
    let categories = [];
    let series = [
      {
        name: 'Scanned',
        data: [],
        color: '#000000',
      },
      {
        name: 'Notified',
        data: [],
        color: '#FFB02F',
      },
      {
        name: 'Picked Up',
        data: [],
        color: '#02B67C',
      },
    ];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        series[0].data.push(parseInt(data[i].Scanned, 10));
        series[1].data.push(parseInt(data[i].Notified, 10));
        series[2].data.push(parseInt(data[i].Picked, 10));
        categories.push(data[i].label);
      }
    }
    const config = {
      title: {
        text: '',
      },
      tooltip: {
        shared: true,
        useHTML: true,
        headerFormat: '<large><b>{point.key}</b></large><br/><br/>',
        backgroundColor: 'rgba(97,97,97,0.9)',
        style: { color: 'white' },
        borderWidth: 0,
        shadow: {
          color: 'rgba(0,0,0,0.1)',
          offsetX: 0,
          offsetY: 5,
          width: 10,
        },
        borderRadius: 0,
      },
      chart: {
        height: width === 'md' || width === 'sm' ? 100 : 150,
        style: { margin: 'auto' },
      },

      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          enabled: false,
        },
      },

      xAxis: {
        categories,
        lineWidth: 0,
        gridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          enabled: false,
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        enabled: false,
      },

      plotOptions: {
        series: {
          lineWidth: 1,
          marker: {
            symbol: 'circle',
            states: {
              hover: {
                fillColor: 'white',
                lineColor: 'black',
                lineWidth: 1,
                radius: 4,
              },
            },
          },
          label: {
            connectorAllowed: true,
          },
        },
      },
      credits: {
        enabled: false,
      },

      series,

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };

    if (!sidebar.collapse) {
      config.chart.width = width === 'md' ? 300 : width === 'sm' ? 180 : width === 'lg' ? 450 : 600;
      config.chart.height = ((width === 'md' || width === 'sm') && 150) || ((width === 'lg' || width === 'xl') && 150);
    }
    if (sidebar.collapse) {
      delete config.chart.width;
    }

    return (
      <Col xs={12} md={12} lg={12} style={{ height: 144 }}>
        {scanDetailLoading && (
          <VerticallyCenter>
            <AlignCenter>
              <div className="intro-banner-vdo-play-btn pinkBg" target="_blank">
                <i
                  className="glyphicon glyphicon-play whiteText"
                  aria-hidden="true"
                />
                <span className="ripple pinkBg" />
                <span className="ripple pinkBg" />
                <span className="ripple pinkBg" />
              </div>
            </AlignCenter>
          </VerticallyCenter>
        )}
        {!scanDetailLoading && !scanDetailError && (
          <div style={{ height: 100 }}>
            <ReactHighcharts config={config} />
          </div>
        )}
      </Col>
    );
  }
}

SimpleLineChart.propTypes = {
  // t: PropTypes.func.isRequired,
  scanDetailLoading: PropTypes.bool.isRequired,
  data: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
  scanDetailError: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default withWidth()(SimpleLineChart);
