import { combineReducers, createStore } from 'redux';
import {
  sidebarReducer,
  customizerReducer,
  allBuildingsReducer,
  countryCodeReducer,
  locationFilterReducer,
  csvUploadingReducer,
  userRoleReducer,
  paginationReducer,
  countriesReducer,
  searchReducer,
  userReducer,
  userDetailsReducer,
  scanFilterReducer,
  dynamicLabelsReducer,
  tableExportReducer,
  dynamicPaginationReducer,
  tableColumnReducer,
  dateFilterReducer,
  userStepReducer,
  csvFlagActionReducer,
  paymentsReducer,
  notificationReducer,
  loaderReducer
} from '../../redux/reducers/index';

const reducer = combineReducers({
  sidebar: sidebarReducer,
  customizer: customizerReducer,
  allBuildings: allBuildingsReducer,
  codeOfCountry: countryCodeReducer,
  locationFilter: locationFilterReducer,
  csvuploading: csvUploadingReducer,
  userRole: userRoleReducer,
  pagination: paginationReducer,
  countries: countriesReducer,
  search: searchReducer,
  user: userReducer,
  userDetails: userDetailsReducer,
  scanFilter: scanFilterReducer,
  dynamicLabels: dynamicLabelsReducer,
  tableExportStatus: tableExportReducer,
  limitPerPage: dynamicPaginationReducer,
  tableColumn: tableColumnReducer,
  dateFilter: dateFilterReducer,
  userStep: userStepReducer,
  csvFlagForLayout: csvFlagActionReducer,
  paymentsDetails: paymentsReducer,
  notificationPrompt: notificationReducer,
  loader: loaderReducer
});
const store = createStore(reducer);

export default store;
