/* eslint-disable */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PaginationComponent from '../../../../shared/components/PaginationWithNumbers/components/paginationComponent';
import PaymentHistorySections from './PaymentHistorySections';
import PaymentHistorySingle from './PaymentHistorySingle';

class PaymentHistoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      totalItems: null,
      activePage: 1,
      itemsPerPage: 5,
      availableItems: 5,
    };
  }

  handlePagination = (currentActionPage) => {
    let action;
    if (currentActionPage === 'next') {
      action = this.state.activePage + 1;
    } else if (currentActionPage === 'previous') {
      action = this.state.activePage - 1;
    } else {
      action = currentActionPage;
    }
    this.setState(
      {
        loading: true,
        activePage: action,
      });
  };

  render() {
    const { t, paymentHistory, buildings } = this.props;
    
    return (
      <Dialog {...this.props} maxWidth="sm" fullWidth>
        <div style={{ padding: '2rem' }}>
          <h5 className="bold-text">{t('paymentHistory.heading')}</h5>

          <PaymentHistorySections t={t} />

          {paymentHistory.map((record, index) => (
            <PaymentHistorySingle record={record} key={index}  buildings={buildings}/>
          ))}
          {/* {paymentHistory.length > 5 && ( */}
          <div style={{ marginTop: 20 }}>
            <PaginationComponent
              loading={false}
              totalItems={this.props.paymentHistory.length}
              activePage={this.state.activePage}
              itemsPerPage={this.state.itemsPerPage}
              availableItems={this.state.availableItems}
              onChange={this.handlePagination}
            />
          </div>
          {/* )} */}
        </div>
      </Dialog>
    );
  }
}
export default PaymentHistoryModal;
