import { 
  UPDATE_NOTIFICATIONS,
  REFRESH_NOTIFICATIONSPOPUP,
  REFRESH_NOTIFICATIONCENTER
} from '../actions/notificationAction';

let initialState = {
  update: false,
  refreshPopup: false,
  refreshCenter: false
};


export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS: {
      const newState = {
        update: action.flag,
        refreshPopup: false,
        refreshCenter: false
    };
      return newState;
    }
    case REFRESH_NOTIFICATIONSPOPUP: {
      const newState = {
        update: false,
        refreshPopup: action.flag,
        refreshCenter: false
    };
      return newState;
    }
    case REFRESH_NOTIFICATIONCENTER: {
      const newState = {
        update: false,
        refreshPopup: false,
        refreshCenter: action.flag
    };
      return newState;
    }
    default: return state;
  }
}
