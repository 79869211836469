import React from 'react';
import PropTypes from 'prop-types';

const Flex = ({ children, style, className }) => {
  className = className !== '' || className !== undefined ? '' : className;
  return (
  <div className={className} style={window.screen.width > 500 ? { display: 'flex', ...style } : { ...style }}>{children}</div>
);
};

Flex.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Flex.defaultProps = {
  className: '',
};
export default Flex;
