/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem, Fade, IconButton } from '@material-ui/core';
import { deleteUserCard, updatedUserCard } from '../../../../server/payment';
import DeleteCardConfirmation from '../../PaymentModals/DeleteCardConfirmation';
import DefaultCardConfirmation from '../../PaymentModals/DefaultCardConfirmation';

class CardOptionsDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      openDeleteCardConfirmationModal: false,
      openDefaultCardConfirmationModal: false,
      deleteCardLoader: false,
      cardDefaultLoader: false,
    };
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  toggle = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  openDeleteCardConfirmationModal = () =>
    this.setState({ openDeleteCardConfirmationModal: true, anchorEl: null });

  openDefaultCardConfirmationModal = () =>
    this.setState({ openDefaultCardConfirmationModal: true, anchorEl: null });

  closeDeleteCardConfirmationModal = () =>
    this.setState({
      openDeleteCardConfirmationModal: false,
      cardDefaultLoader: false,
      anchorEl: null,
    });

  closeDefaultCardConfirmationModal = () =>
    this.setState({
      openDefaultCardConfirmationModal: false,
      deleteCardLoader: false,
      anchorEl: null,
    });

  deleteCard = () => {
    const { id, loadData } = this.props;
    this.setState(
      {
        deleteCardLoader: true,
      },
      () => {
        deleteUserCard(id)
          .then(() => {
            this.setState(
              {
                deleteCardLoader: false,
              },
              () => {
                loadData();
              }
            );
          })
          .catch((err) => {
            this.setState(
              {
                deleteCardLoader: false,
              },
              () => {
                console.log(err);
              }
            );
          });
      }
    );
  };

  makeCardDefault = () => {
    const { id, loadData } = this.props;
    this.setState(
      {
        cardDefaultLoader: true,
      },
      () => {
        updatedUserCard(id)
          .then(() => {
            this.setState(
              {
                cardDefaultLoader: false,
              },
              () => {
                loadData();
              }
            );
          })
          .catch((err) => {
            this.setState(
              {
                cardDefaultLoader: false,
              },
              () => {
                console.log(err);
              }
            );
          });
      }
    );
  };
  render() {
    const {
      anchorEl,
      openDeleteCardConfirmationModal,
      deleteCardLoader,
      openDefaultCardConfirmationModal,
      cardDefaultLoader,
    } = this.state;
    const { cardLastDigits, defaultCard, showSetDefaultOption } = this.props;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <span>
        <IconButton
          aria-describedby={id}
          aria-haspopup="true"
          color="white"
          style={{ padding: 0 }}
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={id}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          PaperProps={{
            style: {
              borderRadius: 4,
              boxShadow:
                'rgba(0, 0, 0, 0) 0px 6px 7px -4px, rgba(0, 0, 0, 0) 0px 11px 15px 1px, rgba(0, 0, 0, 0.12) 0px 4px 20px 3px',
            },
          }}
          TransitionComponent={Fade}
          onClose={this.handleClose}
        >
          {!defaultCard && showSetDefaultOption && (
            <MenuItem
              className="cod-menuItem cod-black"
              onClick={this.openDefaultCardConfirmationModal}
            >
              Set as Default
            </MenuItem>
          )}
          <MenuItem
            className="cod-menuItem cod-red"
            onClick={this.openDeleteCardConfirmationModal}
          >
            Delete Card
          </MenuItem>
        </Menu>
        <DeleteCardConfirmation
          open={openDeleteCardConfirmationModal}
          onClose={this.closeDeleteCardConfirmationModal}
          deleteCard={this.deleteCard}
          loader={deleteCardLoader}
          cardLastDigits={cardLastDigits}
        />
        <DefaultCardConfirmation
          open={openDefaultCardConfirmationModal}
          onClose={this.closeDefaultCardConfirmationModal}
          makeCardDefault={this.makeCardDefault}
          loader={cardDefaultLoader}
          cardLastDigits={cardLastDigits}
        />
      </span>
    );
  }
}

CardOptionsDropdown.defaultProps = {
  showSetDefaultOption: false 
};


CardOptionsDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  cardLastDigits: PropTypes.string.isRequired,
  defaultCard: PropTypes.bool.isRequired,
  showSetDefaultOption: PropTypes.bool,
};

export default CardOptionsDropdown;
