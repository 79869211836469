export const SET_DATEFILTER = 'SET_DATEFILTER';
export const CLEAR_DATEFILTER = 'CLEAR_DATEFILTER';

export const setDateFilter = (dates) => ({
  type: SET_DATEFILTER,
  payload: dates,
});

export const removeDateFilter = () => ({
  type: CLEAR_DATEFILTER,
});
