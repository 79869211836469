/* eslint-disable function-paren-newline */
/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
/* eslint-disable no-plusplus */
import React from "react";
import { withRouter } from "react-router-dom";
import ReactHighcharts from "react-highcharts";
import PropTypes from "prop-types";
import { withWidth } from "@material-ui/core";
import { connect } from "react-redux";

export const color = [
  "#FF1E59",
  "#02B67C",
  "#027AFF",
  "#804DDF",
  "#FFB02F",
  "#E21AD8",
  "#e43ce7f0",
  "#34108bf0",
];
function BarCharts(props) {
  const { data, width, sidebar, index, category } = props;
  let total = 0;
  let config = {
    chart: {
      type: "bar",
      fill: "transparent",
      fontSize: 10,
    },
    title: {
      text: "",
    },
    dataLabels: {
      enabled: true,
      crop: false,
      overflow: "none",
    },
    yAxis: {
      max: 101,
      allowDecimals: false,
      labels: {
        enabled: false,
      },
      minTickInterval: 25,
      min: 0,
      title: {
        text: "",
      },
      opposite: true,
      gridLineWidth: 0,
    },

    xAxis: {
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      showEmpty: false,
    },
    tooltip: { // Tooltips display informative text when users hover over.
      textTransform: "capitalize",
      valueSuffix: "",
      backgroundColor: "rgba(97,97,97,0.9)",
      opacity: 2,
      borderWidth: 0,
      borderRadius: 0,
      headerFormat: "",
      followPointer: true,
      formatter() {
        return ` <b> ${Intl.NumberFormat('en').format(this.series.userOptions.count)}</b>`;
      },
      style: {
        color: "white",
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        zIndex: 2,
      },
    },
    plotOptions: { // The plotOptions is a wrapper object for config objects for each series type.
      series: {
        dataLabels: {
          enabled: true,
          crop: false,
          overflow: "none",
        },
        events: {
          click: () => {
            if (data[0].key !== "others" && data[0].key !== "no-label") {
              props.history.push(`/packages/package_label/${data[0].id}`);
            }
          },
        },
        maxWidth: '100px !important',
        cursor: data[0].key !== "others" && data[0].key !== "no-label" && "pointer",
        pointPadding: 0.05,
        borderWidth: 0,
        marginLeft: 0,
        shadow: {
          color: "rgba(0,0,0,0.1)",
          offsetX: 0,
          offsetY: 5,
          width: 10,
        },
      },
      bar: {
        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter() {
            if (category) {
              return `${data[0].count}`;
            }
            return `${this.y.toFixed(2)}%`;
          },
          style: {
            color: "#31394D",
            fontSize: 16,
            textTransform: "capitalize",
            height: 20,
          },
        },
      },

      labels: {
        style: {
          left: 30,
          marginLeft: 30,
          align: "left",
        },
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor: "#red",
      shadow: true,
      enabled: false,
      textTransform: "capitalize",
    },
    credits: {
      enabled: false,
    },
  };

  if (!sidebar.collapse) { // if user expand the sidebar
    if (width === "xl") { // set width of package labels barChart
      config.chart.width = 750;
    } else if (width === "lg") { // set width of package labels barChart which devices width is greater than or equal to 992px.
      config.chart.width = 400;
    } else if (width === "md") { // set width of package labels barChart which devices width is greater than or equal to 768px.
      config.chart.width = 210;
    } else if (width === "sm") { // set width of package labels barChart which devices width is greater than or equal to 576px.
      config.chart.width = 330;
    } else if (width === 'xs') { // set width of package labels barChart which devices width is less than to 576px.
      config.chart.width = 100;
    }
  } else if (sidebar.collapse) {
    if (width === "xl") {
      config.chart.width = 700;
    } else if (width === "lg") { // set width of package labels barChart which devices width is greater than or equal to 992px.
      config.chart.width = 430;
    } else if (width === "md") { // set width of package labels barChart which devices width is greater than or equal to 768px.
      config.chart.width = 280;
    } else if (width === "sm") { // set width of package labels barChart which devices width is greater than or equal to 576px.
      config.chart.width = 365;
    } else if (width === 'xs') { // set width of package labels barChart which devices width is less than to 576px.
      config.chart.width = 100;
    }
  }
  config.series = [];
  for (let i = 0; i < data.length; i++) {
    config.series.push({
      name: data[i].name,
      data: [data[i].percentage],
      count: data[i].count,
      color: color[index],
    });
    total = Number(total) + Number(data[i].percentage);
  }
  return (
    <div className="barchart-div2">
      <ReactHighcharts config={config} />
    </div>
  );
}

BarCharts.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
  history: PropTypes.node.isRequired,
  category: PropTypes.bool
};

BarCharts.defaultProps = {
  category: false, // for category bar cart
};

export default withRouter(
  connect(state => ({
    sidebar: state.sidebar,
  }))(withWidth()(BarCharts)));
