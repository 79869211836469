import React from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import './style.css';
import { handleSidebarHover, handleSidebarHoverAway } from '../../../util/utility.functions';

const DashboardIconActive = require('./DashboardIconActive.png');
const DashboardIconInactive = require('./DashboardIconInactive.png');

function DashboardIcon(props) {
  const { active, label } = props;
  const imgSrc = active ? DashboardIconActive : DashboardIconInactive;

  return (
    <Tooltip title={label}>
      <img
        src={imgSrc}
        alt=""
        height="20"
        width="20"
        onMouseOver={(event) => { handleSidebarHover(event, DashboardIconActive); }}
        onFocus={(event) => { handleSidebarHover(event, DashboardIconActive); }}
        onMouseLeave={(event) => { handleSidebarHoverAway(event, active, DashboardIconInactive); }}
      />
    </Tooltip>
  );
}

DashboardIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};
export default DashboardIcon;
