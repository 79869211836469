import { SET_DYNAMIC_PAGINATION } from '../actions/dynamicPaginationActions';
import { informSlackDBCodeErrs } from '../../util/informSlack';

const limit = 10;
let initialState = {
    membersLimit: limit,
    businessesLimit: limit,
    buildingsLimit: limit,
    associatesLimit: limit,
    packagesLimit: limit,
};

try {
    initialState = localStorage.px_dynamic_pagination ? JSON.parse(localStorage.px_dynamic_pagination) : {
        membersLimit: limit,
        businessesLimit: limit,
        buildingsLimit: limit,
        associatesLimit: limit,
        packagesLimit: limit,
    };
} catch (err) {
    informSlackDBCodeErrs(err,'source > src/redux/reducers/dynamicPaginationReducer', 'line # 22');
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DYNAMIC_PAGINATION: {
            const newState = {
                membersLimit: action.dynamicPagination.membersLimit,
                businessesLimit: action.dynamicPagination.businessesLimit,
                buildingsLimit: action.dynamicPagination.buildingsLimit,
                associatesLimit: action.dynamicPagination.associatesLimit,
                packagesLimit: action.dynamicPagination.packagesLimit,
            };
            localStorage.px_dynamic_pagination = JSON.stringify(newState);
            return newState;
        }

        default: return state;
    }
}
