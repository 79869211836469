import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Grid, CircularProgress } from '@material-ui/core';
// import AddMemberComp from './components/AddMember';
import NotAuthorized from '../../UI/403/403';
import CSVUpload from './newComponents/index';
import { AlignCenter, VerticallyCenter } from '../../UI/ResponsiveRelated';
import Footer from '../../UI/Footer/Footer';
import { csvUploadFlagForLayout } from './../../../redux/actions/csvFlagAction';
import { checkAWSSession } from '../../../util/application.utils';

class AddMember extends Component {
  constructor(props) {
    super(props);
    const { building, buildings } = props;
    this.state = {
      uploading: false,
      selectedBuilding:
        building.length > 0
          ? building[0].building_id.toString()
          : buildings.length === 1
          ? buildings[0].building_id
            ? buildings[0].building_id.toString()
            : ''
          : '',
    };
  }

  componentDidMount() {
    checkAWSSession((res) => {
      if (res) {
        return <Redirect replace to={`/onboarding?email=${res}`} />;
      }
    });
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidUpdate(props) {
    if (props !== this.props && this.state.selectedBuilding.length === 0) {
      const { building, buildings } = props;
      this.setState({
        selectedBuilding:
          building.length > 0
            ? building[0].building_id.toString()
            : buildings.length === 1
            ? buildings[0].building_id
              ? buildings[0].building_id.toString()
              : ''
            : '',
      });
    }
  }

  selectBuilding = (event) =>
    this.setState({ selectedBuilding: event.target.value });

  redirect = () => this.setState({ redirect: true });

  render() {
    const { redirect } = this.state;
    const { userRole, setCsvUploadFlagForLayout } = this.props;
    checkAWSSession((res) => {
      if (res) {
        return <Redirect replace to={`/onboarding?email=${res}`} />;
      }
    });
    if (redirect) return <Redirect to="/members/all_members" />;
    return !userRole ? (
      <VerticallyCenter>
        <AlignCenter>
          <CircularProgress style={{ color: '#70bbfd' }} size={30} />
        </AlignCenter>
      </VerticallyCenter>
    ) : userRole === 'mailroom_supervisor' ? (
      <NotAuthorized />
    ) : (
      <Fragment>
        <div className="dashboard container">
          <Grid container>
            <Grid item md={12}>
              <h3 className="page-title">
                {this.props.t('members.addmembers')}
              </h3>
            </Grid>
            <CSVUpload
              {...this.state}
              {...this.props}
              redirect={this.redirect}
              selectBuilding={(e) => this.selectBuilding(e)}
              setCsvUploadFlag={setCsvUploadFlagForLayout}
            />
          </Grid>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCsvUploadFlagForLayout: (flag) => dispatch(csvUploadFlagForLayout(flag)),
});

export default connect(
  (state) => ({
    buildings: state.allBuildings,
    building: state.locationFilter,
    uploading: state.csvuploading,
    userRole: state.userRole,
  }),
  mapDispatchToProps
)(withRouter(AddMember));
