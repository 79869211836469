/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Divider, Grid, Paper, TableCell, TableRow } from '@material-ui/core';
import MaterialUITable from '../../../../shared/components/MaterialUITable/components/materialUITableComponent';
import { getFullTierName } from '../../../../util/application.utils'
import { getDaysDifference, getCommaSeperatedNumber } from '../../../../util/utility.functions';
const BuildingsIconInactive = require('../../../Layout/sidebar/BuildingsIconInactive.png');

class ManagePlan extends React.Component {
  state = {};
  render() {
    const { t, buildings } = this.props;

    /** Conditions */
    const isValidBuilding = buildings && buildings.length > 0 && buildings[0];
    // const isValidBuildingFeatureLimit = buildings && buildings[0] && buildings[0].features && buildings[0].features.SCAN_LIMIT;
    const isValidUntil =
      buildings && buildings.length > 0 && buildings[0].valid_until;
    const daysLeft = isValidUntil && getDaysDifference(isValidUntil);
    const isStripeCustomer = isValidBuilding && buildings[0].billing_method && buildings[0].billing_method.toLowerCase() === 'stripe';
    const isValidInnerBuilding =
      buildings &&
      buildings.length > 0 &&
      buildings[0].buildings &&
      buildings[0].buildings.length > 0 &&
      buildings[0].buildings[0];

    const validScans =
      isValidInnerBuilding &&
      buildings[0].buildings[0].features &&
      buildings[0].billing &&
      (buildings[0].buildings[0].features.MONTHLY_SCAN_LIMIT ||
        buildings[0].buildings[0].features.ANNUALLY_SCAN_LIMIT)
        ? buildings[0].buildings[0].features.MONTHLY_SCAN_LIMIT
          ? buildings[0].buildings[0].features.MONTHLY_SCAN_LIMIT
          : buildings[0].buildings[0].features.ANNUALLY_SCAN_LIMIT
        : null;

    /** Calculations */
    const selectedTier = isValidBuilding ? buildings[0].tier : '';
    const totalScans =
      isValidInnerBuilding && buildings[0].buildings[0].total_scans
        ? buildings[0].buildings[0].total_scans
        : 0;
    let scanLimit = 0;

    const renewDate = isValidUntil
      ? moment.utc(buildings[0].valid_until).format('MMMM Do, YYYY')
      : '';
    const trialUser =
      isValidBuilding &&
      buildings[0].status &&
      buildings[0].status.toLowerCase() === 'trial';

    const hasDiscount = isValidBuilding && buildings[0].discount;
    const renewPrice = () => {
      if(isValidBuilding && buildings[0].billing && buildings[0].billing_price) {
        if (hasDiscount) {
          const discountPrice = parseInt(buildings[0].billing_price,10) * (hasDiscount / 100); 
          return ` $${(buildings[0].billing_price - discountPrice).toFixed(2)}`;
        } else {
          return ` $${getCommaSeperatedNumber(buildings[0].billing_price)}`;
        }
      } else {
        return '$****';
      }
    }

    if (validScans) {
      if (buildings[0].billing === 'monthly') {
        if (buildings[0].buildings[0].features.MONTHLY_SCAN_LIMIT) {
          scanLimit = buildings[0].buildings[0].features.MONTHLY_SCAN_LIMIT;
        } else {
          scanLimit = 0;
        }
      } else {
        scanLimit = 0;
        if (buildings[0].buildings[0].features.ANNUALLY_SCAN_LIMIT) {
          scanLimit = buildings[0].buildings[0].features.ANNUALLY_SCAN_LIMIT;
        } else {
          scanLimit = 0;
        }
      }
    } else {
      scanLimit = 0;
    }

    const tableHeads = ['', 'Name', 'Code', 'Scan Quota'].map((item) => {
      return {
        name: item,
        expanded: true,
        value: item,
      };
    });
    const tableRows = (item) => {
      let tableRowCells = [];

      const billingType =
        buildings && buildings.length > 0 && buildings[0].billing;

      const totalScans =
        billingType === 'monthly'
          ? item.features.MONTHLY_SCAN_LIMIT
          : item.features.ANNUALLY_SCAN_LIMIT;

      if (tableHeads[1] && tableHeads[1].expanded) {
        tableRowCells.push(
          <TableCell
            className=""
            style={{
              cursor: 'default',
            }}
          >
            <Link to={`/building_detail/${item.building_id}`}>
              <img
                style={{ borderRadius: 5, height: 32, width: 32 }}
                src={BuildingsIconInactive}
                alt=""
              />
            </Link>
          </TableCell>
        );
      }

      if (tableHeads[1] && tableHeads[1].expanded) {
        tableRowCells.push(
          <TableCell
            className="thumbnail-cell width-60px"
            style={{ cursor: 'default' }}
          >
            <Link to={`/building_detail/${item.building_id}`}>
              {item.building_name}
            </Link>
          </TableCell>
        );
      }
      if (tableHeads[2] && tableHeads[2].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-200px">
            {' '}
            <span> {item.building_code}</span>{' '}
          </TableCell>
        );
      }
      if (tableHeads[3] && tableHeads[3].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-200px">
            {item.total_scans ? getCommaSeperatedNumber(item.total_scans) : 0}/{getCommaSeperatedNumber(totalScans)}
          </TableCell>
        );
      }

      return tableRowCells;
    };

    const getDaysLeft = () => {
      if (!daysLeft) return '';
      if (daysLeft === 1) {
        return `${daysLeft} day`
      } else if (daysLeft > 1) {
        return `${daysLeft} days`
      }
    }

    const getMessage = () => {
      if (isStripeCustomer) {
        if (trialUser) {
          return `You're currently on a free trial of ${getFullTierName(selectedTier)} plan. Your trial will expire on ${renewDate}.
          Please enter a payment method to continue using the ${getFullTierName(selectedTier)} plan after your free trial expires.`;
        } else {
          return `${t('managePlanPara.one')} ${getFullTierName(selectedTier)} plan. Your account will renew on ${renewDate} ${t('managePlanPara.four')} ${renewPrice()}.`;
        }
      } else {
        if (trialUser) {
          // return `${t('managePlanPara.one')} ${trialUser ? 'trial of' : ''} ${selectedTier} ${t('managePlanPara.two')} ${totalScans/scanLimit} ${t('managePlanPara.three')} ${renewDate} ${t('managePlanPara.four')} ${renewPrice()}.`
          if(daysLeft === null || daysLeft === undefined || daysLeft === false) return '';
          else if(daysLeft >= 0) {
            return `You're currently on the trial of ${getFullTierName(selectedTier)} plan. Your trial will expire on ${renewDate}.`;
          } else {
            return `It looks like your free trial for ${getFullTierName(selectedTier)} plan has expired. Please contact sales to pay for your plan if you wish to continue using Mailroom by PackageX`;
          }     
        } else {
          return `${t('managePlanPara.one')} ${getFullTierName(selectedTier)} plan. Your account will renew on ${renewDate} ${t('managePlanPara.four')} ${renewPrice()}.`;
        }
      }
    }

    return (
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Paper className="just-paper manage-plan-paper">
          <div className="manage-plan-heading-container">
            {t('common.planDetails')}
            <div className="manage-plan-text">
              {' '}
              <Link to="/manage-plan">{t('common.managePlan')}</Link>
            </div>
          </div>
          <div className="manage-plan-content-container">
            {getMessage()}
          </div>
          {!trialUser && (
            <>
              <Divider />
              <MaterialUITable
                t={t}
                loading={false}
                view="list"
                tableHeads={tableHeads}
                tableData={buildings[0].buildings}
                tableRows={tableRows}
                sortBy="name"
                sortType
                message={
                  buildings.length === 0 && t('tables.noBuildingsToShow')
                }
                error={null}
              />
            </>
          )}
        </Paper>
      </Grid>
    );
  }
}

export default connect((state) => ({
  buildings: state.allBuildings,
}))(ManagePlan);
