/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { withWidth } from "@material-ui/core";
import BusinessDetailComp from "./components/BusinessDetail";
import { Margin } from "../../UI/ResponsiveRelated";
import Alert from "../../UI/Poper";
import WarningAlert from "../../../shared/components/Alert";
import { informSlackDBCodeErrs } from "../../../util/informSlack";
import Footer from "../../UI/Footer/Footer";
import { validateBusinessName } from '../../../util';

const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

class BusinessDetail extends Component {
  state = {
    loading: true,
    business: {},
    error: null,
    editing: true,
    updating: false,
    altName: [],
    buildings: [],
    name: "",
    email: "",
    phone: "",
    address: "",
    note: "",
    businessUpdated: false,
    warning: null,
  };

  componentDidMount() {
    this.getBusiness(this.props);
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidUpdate(prevProps) {
    const { match } = prevProps;
    if (match.params.id !== this.props.match.params.id) {
      this.getBusiness(this.props);
    }
  }

  getBusiness = props => {
    this.setState({
      loading: true,
      business: {},
      error: null,
      editing: true,
      updating: false,
      buildings: [],
      name: "",
      email: "",
      phone: "",
      address: "",
      note: "",
      businessUpdated: false,
      warning: null,
    });
    const { getBusiness, match } = props;
    // const { getBusiness, match, buildings } = props;
    getBusiness(match.params.id)
      .then(business => {
        localStorage.alternate_name = JSON.stringify(business.alternate_name);
        // const buildingNames = buildings.map(building => building.building_name);
        let phoneError = false;
        if (business.phone) {
          try {
            const phoneObj = phoneUtil.parse(business.phone);
            phoneError = !phoneUtil.isValidNumber(phoneObj);
          } catch (e) {
            informSlackDBCodeErrs(e, 'source > src/containers/Dashboard/BusinessDetail/index', 'line # 82');
          }
        }
        this.setState({
          business,
          name: business.name || "",
          email: business.email || "",
          phone: business.phone || "",
          // address: buildingNames.indexOf(business.building_name) || "",
          office: business.office || "",
          altName: business.alternate_name || [],
          addingAltName: !this.state.addingAltName,
          note: business.note,
          address: business.address || "",
          phoneError,
          loading: false,
        });
      })
      .catch(error => this.setState({ error: error.message, loading: false }));
  };

  setAlternativeName = index => event => {
    const { altName, addingAltName } = this.state;
    altName[index] = event.target.value.trimLeft();
    this.setState({ altName, addingAltName: !addingAltName });
  };

  setBuildings = buildings => this.setState({ buildings });

  handleChange = name => event => {
    let value = event.target.value;
    this.closeAlert();
    this.setState({ [name]: value.trimLeft() }, () => {
      const { validateEmail, generalValidation } = this.props;
      const { email } = this.state;
      this.setState({
        emailError: email.length ? !validateEmail(email) : false,
      });
      if (name === 'name') {
        this.setState({
          nameError: value.length ? !validateBusinessName(value.trim()) : false,
        });
      } else if (name === 'address') {
        this.setState({
          addressError: value.length ? !generalValidation(value) : false,
        });
      } else if (name === 'office') {
        this.setState({
          officeError: value.length ? !generalValidation(value) : false,
        });
      }
    });
  };

  handlePhone = (name, phone) => {
    if (phone.length < 3) {
      this.setState({ phone, phoneError: false });
    } else if (phone) {
      try {
        phone = `+${phone}`;
        const phoneObj = phoneUtil.parse(phone);
        const phoneError = phone.length === 0 ? false : phone.length < 10 || !phoneUtil.isValidNumber(phoneObj);
        this.setState({ phone, phoneError });
      } catch (err) {
        this.setState({ phone, phoneError: true });
      }
    } else this.setState({ phone: "", phoneError: null });
  };

  saveBusiness = () => {
    const {
      name,
      email,
      phone,
      office,
      business,
      altName,
      note,
      address,
      alternateEmail,
      alternatePhone,
      phoneError,
      emailError,
      nameError,
      addressError,
      officeError
    } = this.state;
    const { t, updateBusiness, match } = this.props;
    const filteredAltNamed = altName.filter(
      val => val !== (null || undefined || ''),
    );

    const alternateNameExists = filteredAltNamed.filter(
      item => item.trim().toLowerCase() === name.trim().toLowerCase(),
    );

    const alternateFilteredAltNamedExists = filteredAltNamed.filter(item => {
      const altData = filteredAltNamed.map(tempItem => tempItem.toLowerCase());
      return altData.indexOf(item.toLowerCase()) !== altData.lastIndexOf(item.toLowerCase());
    });

    if (name.trim().length < 2) {
      this.setState({ warning: t("common.error.nameLength") });
    }
    if (emailError) this.setState({ warning: t("emailValidation") });
    if (nameError) this.setState({ warning: t('common.error.nameValidation') });
    if (officeError) this.setState({ warning: t('common.error.officeValidation') });
    if (addressError) this.setState({ warning: t('common.error.addressValidation') });
    if (phoneError) this.setState({ warning: t("phoneValidation") });
    if (alternateNameExists.length !== 0) {
      this.setState({
        warning: t('common.error.nameAndBusinessAlias'),
      });
    } else if (alternateFilteredAltNamedExists.length !== 0) {
      this.setState({
        warning: t('common.error.businessAliasDuplicate'),
      });
    } else if (name.trim().length >= 2 && !phoneError && !emailError) {
      let alternate_name = altName.map(alt => alt.length > 0 && alt);
      alternate_name = alternate_name.filter(alt => alt);
      let tempPhone = "";
      if (phone && phone !== [] && phone.toString().length > 1) {
        tempPhone = '+'.concat(phone.replace(/[^\d]/g, ''));
      }
      const newBusiness = {
        name,
        email,
        phone: tempPhone,
        office,
        alternateEmail,
        alternatePhone,
        alternate_name,
        building: business.building_id,
        note,
        address,
      };
      if (email.length === 0) {
        delete newBusiness.email;
      }
      if (!office || office === "") {
        delete newBusiness.office;
      }
      if (phone.length < 10) delete newBusiness.phone;

      if (
        !newBusiness.alternate_name ||
        (newBusiness.alternate_name && newBusiness.alternate_name.length === 0)
      ) {
        delete newBusiness.alternate_name;
      }
      if (address.length === 0) delete newBusiness.address;
      this.setState({ updating: true, errorUpdating: null });
      updateBusiness(match.params.id, newBusiness)
        .then(() => {
          localStorage.alternate_name = JSON.stringify(
            newBusiness.alternate_name,
          );
          this.setState({
            businessUpdated: true,
            updating: false,
            editing: false,
            name: newBusiness.name,
            email: newBusiness.email,
            phone: newBusiness.phone,
            office: newBusiness.office,
            address: newBusiness.address,
            business: newBusiness,
            building: newBusiness.building,
            note: newBusiness.note,
          });
        })
        .catch(err => {
          let errorMessage = err.response
            ? err.response.data.message
            : err.message;
          let error = errorMessage.includes("office")
            ? t("common.error.officeNotEmpty")
            : errorMessage.includes(
              'child "alternate_name" fails because ["alternate_name" position 1 contains a duplicate value',
            )
              ? t("common.error.busalia")
              : errorMessage.includes("email")
                ? t("common.error.enterValidEmail")
                : errorMessage.includes("Invalid Phone Number")
                  ? t("phoneValidation")
                  : errorMessage.includes(
                    "User not authorized to perform this action",
                  )
                    ? t("common.error.userNotFound")
                    : errorMessage.includes("alternate_name") ||
                      errorMessage.includes("Alternate Name")
                      ? t("common.error.duplicate")
                      : t("common.error.networkError");
          this.setState({
            errorUpdating: error,
            updating: false,
          });
        });
    }
  };

  closeAlert = () => {
    if (this.state.errorUpdating) {
      this.setState({ warning: null, errorUpdating: false });
    } else if (this.state.warning) {
      this.setState({ warning: null });
    } else if (this.state.businessUpdated) {
      this.setState({ warning: null, businessUpdated: false });
      this.props.history.push("/members/businesses");
    }
  };

  addInputField = () => {
    const { altName } = this.state;
    altName.push("");
    this.setState(state => ({ altName, addingAltName: !state.addingAltName }));
  };

  removeInputField = index => {
    const { altName } = this.state;
    altName.splice(index, 1);
    this.setState(state => ({ altName, addingAltName: !state.addingAltName }));
  };

  render() {
    const {
      loading,
      business,
      error,
      errorUpdating,
      businessUpdated,
      warning,
    } = this.state;
    const { t } = this.props;

    return (
      <Fragment>
        <Container style={{ minWidth: "100%" }}>
          {errorUpdating && (
            <Alert
              variant="error"
              open
              message={errorUpdating}
              onClose={this.closeAlert}
            />
          )}
          {businessUpdated && (
            <Alert
              variant="success"
              open
              message={t("members.businessUpdated")}
              onClose={this.closeAlert}
            />
          )}
          <Row>
            <Col md={12}>
              <div
                style={
                  this.props.width === "xs"
                    ? {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }
                    : { display: "flex", justifyContent: "space-between" }
                }
              >
                <div>
                  <h3 className="page-title">
                    {loading && !error ? (
                      <Skeleton width={100} />
                    ) : (
                        business.name || ""
                      )}
                  </h3>
                  <h3 className="page-subhead subhead">
                    <Link to="/members/businesses">
                      {t("tables.businesses")}
                    </Link>

                    <ArrowRightIcon />
                    {loading && !error ? (
                      <Skeleton width={100} />
                    ) : (
                        business.name || ""
                      )}
                  </h3>
                </div>
              </div>
              <div style={{ marginBottom: 20 }}>
                {warning && (
                  <Margin right={20} left={20}>
                    <WarningAlert
                      variant="error"
                      onClose={this.closeAlert}
                      message={warning}
                      open
                    />
                  </Margin>
                )}
              </div>
            </Col>
          </Row>
          <BusinessDetailComp
            {...this.state}
            {...this.props}
            handleChange={this.handleChange}
            setBuildings={this.setBuildings}
            handlePhone={this.handlePhone}
            handleSearch={this.handleSearch}
            addInputField={this.addInputField}
            setAlternativeName={this.setAlternativeName}
            removeInputField={this.removeInputField}
            saveBusiness={this.saveBusiness}
          />
        </Container>
        <Footer />
      </Fragment>
    );
  }
}

BusinessDetail.propTypes = {
  getBusiness: PropTypes.func.isRequired,
  updateBusiness: PropTypes.func.isRequired,
  match: PropTypes.string.isRequired,
};

export default withRouter(
  withWidth()(
    connect(state => ({
      buildings: state.allBuildings,
    }))(BusinessDetail),
  ),
);
