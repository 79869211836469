import moment from 'moment';

const DummyData = [
    {
        updated_by: 'Ahmad Shoaib',
        time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
            .format('MM/DD/YYYY'),
        status: 'Success',
        link: 'https://www.slideshare.net/rakhithota/js-ppt',
    },
    {
        updated_by: 'Ehsan Ellahi',
        time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
            .format('MM/DD/YYYY'),
        status: 'Success',
        link: 'https://www.slideshare.net/rakhithota/js-ppt'
    },
    {
        updated_by: 'Ahmad Shoaib',
        time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
            .format('MM/DD/YYYY'),
        status: 'Failed',
        link: 'https://www.slideshare.net/rakhithota/js-ppt'
    },
    {
        updated_by: 'Tayyab Nasrullah',
        time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
            .format('MM/DD/YYYY'),
        status: 'Success',
        link: 'https://www.slideshare.net/rakhithota/js-ppt'
    },
    {
        updated_by: 'Ehsan Ellahi',
        time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
            .format('MM/DD/YYYY'),
        status: 'Failed',
        link: 'https://www.slideshare.net/rakhithota/js-ppt'
    },
    {
        updated_by: 'Hassan Sultan',
        time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
            .format('MM/DD/YYYY'),
        status: 'Success',
        link: 'https://www.slideshare.net/rakhithota/js-ppt'
    },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ahmad Shoaib',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Ehsan Ellahi',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Success',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Tayyab Nasrullah',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
    // {
    //     updated_by: 'Hassan Sultan',
    //     time: moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)))
    //         .format('MM/DD/YYYY'),
    //     status: 'Failed',
    //     link: 'https://www.slideshare.net/rakhithota/js-ppt'
    // },
];

export default DummyData;
