import { SET_USER_DETAIL } from '../actions/userDeatilsActions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_DETAIL: {
      state = action.userDetail;
      return state;
    }
    default: return state;
  }
}
