/* eslint-disable*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { cardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CachedIcon from '@material-ui/icons/Cached';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
// import { Auth } from 'aws-amplify';
import {
  Paper,
  LinearProgress,
  IconButton,
} from '@material-ui/core';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import NotificationsPopup from './NotificationsPopup';
// import Timer from "../Timer";
// import TopbarMail from './TopbarMail';
// import TopbarNotification from './TopbarNotification';
import TopbarSearch from './TopbarSearch';
import TopbarLanguage from './TopbarLanguage';
import Alert from '../../UI/Poper';
// import pxLogo from '../../../assets/images/package_x.svg';
import mailroomLogo from '../../../shared/img/logo/mailroom_logo.svg';
import { tableExportStatus } from '../../../server/packages';
import {
  getStripePaymentDetails,
  setPaymentPlan,
} from '../../../server/payment';
import { tableDataExport } from '../../../redux/actions/tableExportAction';
import { getDaysDifference } from '../../../util/utility.functions';
import { function_checkScanLimitReached, function_checkTrialAboutToExpire, function_checkFreeTrailExpired, function_checkInsufficientFunds } from '../../../util/application.utils';
import TrailAboutToExpire from '../../Dashboard/PaymentModals/TrailAboutToExpire';
import FreeTrailExpired from '../../Dashboard/PaymentModals/FreeTrailExpiredInternal';
import ScanLimitReached from '../../Dashboard/PaymentModals/ScanLimitReached';
import UserCardDetail from '../../Dashboard/PaymentModals/UserCardDetail';
import InsufficientFunds from '../../Dashboard/PaymentModals/InsufficientFunds';
import PaymentError from '../../Dashboard/PaymentModals/PaymentError';
import { setRole } from "../../../redux/actions/userRoleActions";
import { showSkipTutorial } from '../../../redux/actions/sidebarActions';
import { setUser as setUserDetail } from '../../../redux/actions/userDeatilsActions';
import PaymentConfirmationModal from './PaymentConfirmationModal';

const env = process.env.REACT_APP_ENV;

class Topbar extends Component {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    user: PropTypes.node.isRequired,
    width: PropTypes.string.isRequired,
    changeTopBarModeflag: PropTypes.bool.isRequired,
    setRole: PropTypes.func.isRequired,
    skipTutorial: PropTypes.bool.isRequired,
  };
  state = {
    done: null,
    exporting: localStorage.getItem('exportingData') === 'true',
    downloadError: null,
    hideTopBarDropDown: false,
    showTrialAboutToExpireModal: false,
    showFreeTrailExpiredModal: false,
    showScanLimitReachedModal: false,
    loadingCheckout: false,
    useCardDetailModal: false,
    error: false,
    redirectToDashboard: false, //For Onboarding
    showInsufficientFundsModal: false,
    showPaymentErrorFreezeModal: false,
    openPaymentConfirmationModal: false
  };

  componentDidMount() {
    if (this.state.exporting) {
      this.checkExportStatus();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.buildings.length !== prevProps.buildings.length &&
      this.props.buildings.length > 0
    ) {
      const { buildings } = this.props;
      const { showTrialAboutToExpireModal, showFreeTrailExpiredModal } = this.state;

      this.setState({
        showInsufficientFundsModal: function_checkInsufficientFunds(buildings),
      });
      this.setState({
        showFreeTrailExpiredModal: function_checkFreeTrailExpired(buildings, showFreeTrailExpiredModal),
      });
      this.setState({
        showTrialAboutToExpireModal: function_checkTrialAboutToExpire(buildings, showTrialAboutToExpireModal),
      });
      this.setState({
        showScanLimitReachedModal: function_checkScanLimitReached(buildings),
      });
      this.setState({
        showPaymentErrorFreezeModal : buildings && buildings[0].status && buildings[0].status.toLowerCase() === 'freezed'
      })
    }
    if (prevProps.tableExportStatus !== this.props.tableExportStatus) {
      if (this.props.tableExportStatus) {
        if (
          this.props.userRole === 'super_admin' &&
          env &&
          env.includes('demo')
        ) {
          this.tempLoaderIcon();
        } else {
          this.checkExportStatus();
        }
      }
      this.setState({
        exporting: this.props.tableExportStatus,
      });
    }
    if (prevProps.userStep !== this.props.userStep) {
      if (
        JSON.parse(localStorage.getItem('onboarding')) === true &&
        this.props.userStep === 1
      ) {
        this.setState({
          hideTopBarDropDown: true,
        });
      } else {
        this.setState({
          hideTopBarDropDown: false,
        });
      }
    }
  }

  tempLoaderIcon = () => {
    this.interval = setTimeout(() => {
      this.props.dispatch(tableDataExport(false));
      this.setState({
        exporting: false,
        downloadError: 'Your file is downloading',
      });
    }, 5000);
  };

  checkExportStatus = () => {
    let statusId = parseInt(localStorage.getItem('exportingDataId'), 10);
    this.interval = setInterval(() => {
      if (statusId) {
        tableExportStatus(statusId)
          .then((res) => {
            if (res[0].status === 'COMPLETE' || res[0].status === 'ERROR') {
              if (res[0].status === 'COMPLETE') {
                const baseURL = process.env.BaseUrlS3;
console.log(baseURL);
                const path = res[0].path;
                const file_name = res[0].file_name;
                const completeUrl = `${baseURL}/${path}/${file_name}`;
console.log(completeUrl);

                // const a = document.createElement('a');
                // a.href = completeUrl;
                // a.download = `${file_name}.csv`;
                // document.body.appendChild(a);
                // a.click();
                // a.remove();
                clearInterval(this.interval);
                window.location.href = completeUrl;
              } else {
                clearInterval(this.interval);
              }
              clearInterval(this.interval);
              this.props.dispatch(tableDataExport(false));
              localStorage.removeItem('exportingData');
              localStorage.removeItem('exportingDataId');
              this.setState({
                exporting: false,
                downloadError:
                  res[0].status === 'ERROR'
                    ? 'File download failed'
                    : 'Your file is downloading',
              });
              setTimeout(() => {
                this.closeDownloadAlert();
              }, 3000);
            }
          })
          .catch((err) => {
            console.log(err);
            this.props.dispatch(tableDataExport(false));
            localStorage.removeItem('exportingData');
            localStorage.removeItem('exportingDataId');
            this.setState({
              exporting: false,
            });
            clearInterval(this.interval);
          });
      } else {
        statusId = parseInt(localStorage.getItem('exportingDataId'), 10);
      }
    }, 8000);
  };

  closeDownloadAlert = () => {
    this.setState({
      downloadError: null,
    });
  };

  closePlanExpireModal = () => this.setState({ showTrialAboutToExpireModal: false });

  closeInsufficientFundsModal = () => this.setState({ showInsufficientFundsModal: false });

    closeFreeTrailExpiredModal = callback => {
      this.setState({ showFreeTrailExpiredModal: false }, () => {
        if (callback) {
          callback();
        }
        });
    }

    closesScanLimitReachedModal = callback => {
      this.setState({ showScanLimitReachedModal: false }, () => {
        if (callback) {
          callback();
        }
        });
    }

    closePaymentErrorFreezeModal = callback => {
      this.setState({ showPaymentErrorFreezeModal: false}, () => {
        if (callback) {
          callback();
        }
      })
    }

  closesUserDetailModal = () => this.setState({ UserCardDetailModal: false });
  closePaymentConfirmationModal = () => this.setState({ openPaymentConfirmationModal: false});

  closeAlert = () => this.setState({ done: null });

  showMessage = (done) => {
    this.setState(done);
    localStorage.removeItem('px_timer');
  };

  setPaymentCheckout = (stripeId, loadingFalse) => {
    setPaymentPlan(stripeId)
      .then(async (res) => {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: res.data.id,
        });

        if (result.error) {
          return res;
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
        return res;
      })
      .then((res) => {
        loadingFalse();
      })
      .catch((error) => {
        this.setState({ error: 'Unable to set payment plan' });
        loadingFalse();
        console.log(error);
      });
  };

  addPaymentInfo = () => {
    // const loadingFalse = () =>
    //   this.setState({
    //     loadingCheckout: false,
    //   });
    // this.setState(
    //   {
    //     loadingCheckout: true,
    //   },
    //   () => {
    //     getStripePaymentDetails()
    //       .then((res) => {
    //         this.setPaymentCheckout(res.data.stripe_price_id, loadingFalse);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         this.setState({ error: 'Unable to fetch stripe payment details' });
    //         loadingFalse();
    //       });
    //   }
    // );
    this.setState({
      openPaymentConfirmationModal: true
    })
  };

  redirectToDashboardHandler = () =>{
    this.props.showSkipTutorial(false);
    localStorage.setItem('trialUser', true);
    localStorage.removeItem('building');
    localStorage.removeItem('clientName');
    localStorage.removeItem('onBoarding_email');
    localStorage.removeItem('recipientsNames');
    localStorage.removeItem('recipientsEmails');
    // Set bit in Local Storage that indicates new user has not uploaded CSV
    localStorage.setItem('trialUserUploadCSV', true);
    // this.props.setUserDetail(null);
    this.setState({
    redirectToDashboard: true
    })
  }

  setRoleLocal = val => this.props.dispatch(setRole(val));

  render() {
    const {
      changeMobileSidebarVisibility,
      changeSidebarVisibility,
      user,
      sidebar,
      uploading,
      width,
      hideSearch,
      buildings,
      userRole,
      changeTopBarModeflag,
      setRole,
      skipTutorial,
      t,
    } = this.props;
    // console.log(setRole,' < setRole');
    const {
      done,
      exporting,
      downloadError,
      hideTopBarDropDown,
      showTrialAboutToExpireModal,
      showFreeTrailExpiredModal,
      showScanLimitReachedModal,
      loadingCheckout,
      UserCardDetailModal,
      redirectToDashboard,
      showInsufficientFundsModal,
      showPaymentErrorFreezeModal,
      openPaymentConfirmationModal,
    } = this.state;

    if(redirectToDashboard)
      return <Redirect replace to='/dashboard'/>;
    let link;
    if (env && env.includes('wework')) {
      link = <Link className="topbar__logo" to="/dashboard" />;
    } else {
      link = !hideSearch ? (
        <Link className="flex" to="/dashboard">
          {' '}
          <img src={mailroomLogo} alt="px logo" width={110} />{' '}
        </Link>
      ) : (
        <img
          src={mailroomLogo}
          alt="px logo"
          width={110}
          style={{ marginLeft: '30px' }}
        />
      );
    }

    const getDaysLeft = () => {
      const hasBuilding =
        buildings &&
        buildings[0] &&
        buildings[0].tier &&
        buildings[0].valid_until;
      if (hasBuilding) {
        const daysLeft = getDaysDifference(buildings[0].valid_until);
        if (daysLeft > 1) {
          return `${daysLeft} ${t('onboarding.dashboard.daysLeft')}`;
        } else if (daysLeft === 1) {
          return `${daysLeft} ${t('onboarding.dashboard.dayLeft')}`;
        } else {
          return t('onboarding.dashboard.trialExpired');
        }
      } else {
        return '';
      }
    };

    const hasTier = buildings && buildings[0] &&buildings[0].tier;

    const hasBuilding = hasTier && buildings[0].valid_until;

    const trailUser = hasBuilding && buildings[0].status && buildings[0].status.toLowerCase() !== 'paid';
    const isStripeCustomer = hasBuilding && buildings[0].billing_method && buildings[0].billing_method.toLowerCase() === 'stripe';
    const hasCheckoutRequired = hasBuilding && buildings[0].checkout_required;
    
    const planType = hasBuilding && buildings[0].billing === 'monthly' ? 1 : 0;
    return (
      <Paper className="topbar just-paper" square>
        <div className="topbar__wrapper">
          <div className="topbar__left">
            {this.state.error && (
              <Alert
                variant="error"
                open
                message={this.state.error}
                onClose={() => this.setState({ error: null })}
              />
            )}
            {downloadError && (
              <Alert
                variant={
                  downloadError === 'File download failed' ? 'error' : 'success'
                }
                open
                message={downloadError}
                onClose={this.closeDownloadAlert}
              />
            )}
            {done && (
              <Alert
                variant="success"
                open
                message={done}
                onClose={this.closeAlert}
              />
            )}
            {!hideSearch && (
              <TopbarSidebarButton
                changeMobileSidebarVisibility={changeMobileSidebarVisibility}
                changeSidebarVisibility={changeSidebarVisibility}
              />
            )}
            {!hideSearch && !sidebar.collapse && link}
            {(hideSearch || changeTopBarModeflag) && link}
            {!hideSearch && <TopbarSearch {...this.props} />}
          </div>
          <div className="topbar__right">
            {uploading && (
              <Timer showMessage={(m) => this.showMessage(m)} {...this.props} />
            )}
            {/* hide CLA section */}
            {isStripeCustomer && userRole && userRole !== 'super_admin' && trailUser
              ? !hideSearch &&
                width !== 'xs' && hasCheckoutRequired && (
                  <div className="topbarTrial">
                    <span style={{ fontWeight: 'bold' }}>{getDaysLeft()}</span>
                    {hasBuilding && userRole === 'admin' && (
                      <div className="flex">
                        <span
                          style={{
                            color: ' rgb(2, 122, 255)',
                            cursor: 'pointer',
                          }}
                          onClick={() => this.addPaymentInfo()}
                        >
                          {t('addPaymentInfo')}
                        </span>
                        {loadingCheckout && (
                          <span className="refresh-notifications-circular">
                            <CachedIcon fontSize="small" />
                          </span>
                        )}
                        {/* <IconButton
                      className="icon-btn-refresh"
                      onClick={() => !loadingCheckout? this.addPayment() : {}}>
                          <span className={!loadingCheckout ? "refresh-notifications" : "refresh-notifications-circular"}><CachedIcon fontSize="small" /></span>
                      </IconButton> */}
                      </div>
                    )}
                  </div>
                )
              : ''}
            <div
              style={{
                display: 'flex',
                justifyContent:
                userRole !== 'mailroom_supervisor' ? 'flex-end' : '',
                width: exporting ? (width === 'xs' ? 106 : 166) : skipTutorial ? 190 : 100,
              }}
            >
              {skipTutorial && <div className="skip-tutorial" onClick={() => this.redirectToDashboardHandler()}>Skip Tutorial <span className='spacer'></span><ArrowRightIcon fontSize="small" /></div>}
              {!exporting && (
                <div style={{ display: 'flex' }}>
                  {userRole && userRole === 'mailroom_supervisor' && (
                    <NotificationsPopup
                      user={user}
                      {...this.props}
                      hideSearch={hideSearch}
                      changeTopBarModeflag={changeTopBarModeflag}
                    />
                  )}

                  <TopbarLanguage {...this.props} />
                  {/* <TopbarMail new /> */}

                  {changeTopBarModeflag || hideTopBarDropDown ? (
                    ''
                  ) : (
                    <TopbarProfile
                      setRole={this.setRoleLocal}
                      user={user}
                      {...this.props}
                      hideSearch={hideSearch}
                      changeTopBarModeflag={changeTopBarModeflag}
                    />
                  )}
                </div>
              )}
              {exporting && (
                <React.Fragment>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: 60,
                      marginLeft: 30,
                      textAlign: 'center',
                      fontWeight: 'bolder',
                    }}
                  >
                    <p style={{ margin: 0, color: 'black' }}>
                      {' '}
                      Exporting data{' '}
                    </p>
                    {/* <CircularProgress className="blue" size={20} thickness={6} /> */}
                    <LinearProgress
                      className="blue"
                      style={{ width: '100%' }}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <TrailAboutToExpire
          userRole={userRole}
          open={showTrialAboutToExpireModal}
          onClose={() => this.closePlanExpireModal()}
          t={t}
          buildings={buildings}
          days={
            buildings &&
            buildings[0] &&
            buildings[0].tier &&
            buildings[0].valid_until &&
            getDaysDifference(buildings[0].valid_until)
          }
        />
        <FreeTrailExpired
          t={t}
          userRole={userRole}
          open={showFreeTrailExpiredModal}
          buildings={this.props.buildings}
          onClose={() => this.closeFreeTrailExpiredModal()}
        />
        <ScanLimitReached
          buildings={this.props.buildings}
          userRole={userRole}
          open={showScanLimitReachedModal}
          onClose={() => this.closesScanLimitReachedModal()}
          t={t}
        />
        <UserCardDetail
          t={t}
          userRole={userRole}
          open={UserCardDetailModal}
          onClose={() => this.closesUserDetailModal()}
        />
        <InsufficientFunds
          t={t}
          userRole={userRole}
          open={showInsufficientFundsModal}
          onClose={() => this.closeInsufficientFundsModal()}
          buildings={buildings}
        />
        <PaymentError 
          t={t}
          userRole={userRole}
          open={showPaymentErrorFreezeModal}
          onClose={() => this.closePaymentErrorFreezeModal()}
          buildings={buildings}
        />
        <PaymentConfirmationModal buildings={buildings} type={planType} open={openPaymentConfirmationModal} onClose={() => this.closePaymentConfirmationModal()}/>
      </Paper>
    );
  }
}

// export default Topbar;

const mapDispatchToProps = dispatch => ({
  showSkipTutorial: status => dispatch(showSkipTutorial(status)),
  setUserDetail: user => dispatch(setUserDetail(user)),
});

const mapStateToProps = state => ({
  userRole: state.userRole,
  tableExportStatus: state.tableExportStatus,
  userStep: state.userStep,
  changeTopBarModeflag: state.sidebar.changeTopBarModeflag,
});

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
