export const sortByAssociate = {
  name: 'name',
  email: 'email',
  role: 'role',
  status: 'status'
};

export const sortByMembers = {
  name: 'name',
  phone: 'phone',
};

export const sortByBusiness = {
  name: 'name',
};

export const sortByBuildings = {
  name: 'name',
  // code: 'code'
};

export const sortByApiExposeBuildings = {
  name: 'name',
  // code: 'code'
};

