import moment from 'moment-timezone';
import ct from 'countries-and-timezones';
import { SET_COUNTRY_CODE, SET_TIMEZONE } from '../actions/countryCodeAction';
import { informSlackDBCodeErrs } from '../../util/informSlack';

let timezone = moment.tz.guess(true);
let iso = null;
try {
  iso = ct.getCountriesForTimezone(timezone)[0].id;
} catch (err) {
  iso = 'US';
  informSlackDBCodeErrs(err, `timezone > ${timezone} > source > src/redux/reducer/countryCodeReducer.js`, 'line # 12');
}

const initialState = {
  countryCode: iso,
  timezone,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY_CODE: {
      return { ...state, countryCode: action.countryCode };
    }
    case SET_TIMEZONE: {
      return { ...state, timezone: action.timezone };
    }
    default:
      return state;
  }
};
