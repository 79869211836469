import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, List } from '@material-ui/core';
import CollapsableItem from './Collapse';

function CollapsableBuildingList({ buildings, t }) {
  return (
    <Grid item xs={12} sm={12} md={6} className="margin-bottom-15">
      <Paper className="just-paper height-100p padding-11-16 max-height-310px overflow-hidden">
        <div className="font-size-14 black font-weight-bold">{t('tables.buildings')}</div>
        <List className="height-100p overflowy-scroll">
          {buildings.map(building => <CollapsableItem building={building} />)}
        </List>
      </Paper>
    </Grid>
  );
}

CollapsableBuildingList.propTypes = {
  buildings: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default CollapsableBuildingList;
