/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Divider,
  Grid,
  IconButton,
  TableCell,
  withMobileDialog,
} from '@material-ui/core';
import { Clear, NavigateBefore, NavigateNext } from '@material-ui/icons';
// import Skeleton from 'react-loading-skeleton';
import { Flex, SpaceBetween } from '../../../UI/ResponsiveRelated';
import NoImage from '../../../../assets/images/no-image.jpg';
import PackageDetails from './PackageDetails';
import ActionItemPackageImage from './PackageImageSection/index';
import {
  getPackageDetail,
  // getPackageImage
} from '../../../../server';
import HistoryItemSingle from './HistoryItemSingle';
// import placeholder from '../../../../assets/images/dashboard icons/packages_icon.svg';

const PackagesIconInactive = require('../../../Layout/sidebar/PackagesIconInactive.png');

class PackageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      currentIndex: props.index,
      packageDetail: '',
      packageImage: '',
      signature: '',
      activity_type: '',
      apiCallFlag: false,
      forwarded: false,
      activeItem: true,
      activeHistory: false,
      // packageHistory: [
      //   {
      //     date: '2 March 2021 8:30 am EST',
      //     detail: 'Item scanned in mailroom',
      //   },
      //   {
      //     date: '2 March 2021 8:32 am EST',
      //     detail: 'Item notified to jamiejones@hotmail.com',
      //   },
      //   {
      //     date: '2 March 2021 9:30 am EST',
      //     detail: 'Scan & Send (First Page Only) requested by recipient',
      //   },
      // ],
      package_history: [],
    };
  }

  componentWillUnmount() {
    this.setState({
      activeItem: true,
      activeHistory: false,
      forwarded: false,
    });
  }

  setOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  setIndex = (index = -1) => {
    if (index !== -1) {
      this.setState({
        currentIndex: index,
        forwarded: false,
      });
    } else this.setState({ forwarded: true });
  };

  setPackageImage = (data) => {
    this.setState({
      packageImage: data,
    });
  };

  setSignature = (data) => {
    this.setState({
      signature: data,
    });
  };

  setPackageType = (type) => {
    this.setState({
      activity_type: type,
    });
  };

  setDetails = (data) => {
    this.setState({
      packageDetail: data,
    });
  };

  setApiCallFlag = (flag, callback) => {
    this.setState(
      {
        apiCallFlag: flag,
      },
      () => {
        callback();
      }
    );
  };

  getPackage = (pkgId) => {
    const { apiCallFlag } = this.state;
    if (!apiCallFlag) {
      this.setApiCallFlag(true, () => {});
      getPackageDetail(pkgId)
        .then((detail) => {
          // console.log(detail);
          this.setPackage(detail);
          this.setApiCallFlag(false, () => {});
          // getPackageImage(pkgId, detail.status)
          //   .then(image => {
          //     this.setPackageImage(image.package_image.length > 0 ? image.package_image : 'noImage');
          //     this.setSignature(image.package_pickup
          //       ? image.package_pickup.image === ''
          //         ? 'noSignature'
          //         : image.package_pickup.image
          //       : 'noSignature');
          //     this.setApiCallFlag(false, () => { });
          //   }).catch(() => {
          //     this.setPackageImage('noImage');
          //     this.setSignature('noSignature');
          //     this.setApiCallFlag(false, () => { });
          //   });
        })
        .catch(() => {
          this.setPackageImage('noImage');
          this.setSignature('noSignature');
        });
    }
  };

  setPackage = async (detail) => {
    const { dynamicLabels } = this.props;
    let labelsData = [];
    let labelslocations = [];
    if (dynamicLabels) {
      if (dynamicLabels.label || (dynamicLabels[0] && dynamicLabels[0].label)) {
        dynamicLabels[0].label.map((eachLabel) => {
          labelsData.push(eachLabel);
          return eachLabel;
        });
      }
      if (
        dynamicLabels.location ||
        (dynamicLabels[0] && dynamicLabels[0].location)
      ) {
        dynamicLabels[0].location.map((eachLabel) => {
          labelsData.push(eachLabel);
          return eachLabel;
        });
      }
    }
    const defaultState = '---';
    // const labelsData = dynamicLabels ? dynamicLabels[0].label : [];
    await this.setState({
      package_history: detail.package_history,
      packageDetail: {
        to_address: detail.to_address,
        forwarded_detail: detail.forwarded_detail,
        package_history: detail.package_history,
        last_action_by: detail.last_action_by,
        package_image: detail.package_image || null,
        member_active: detail.is_active || false,
        activity_type: detail.activity_type,
        package_id: detail.package_id || defaultState,
        business_id: detail.business_id || defaultState,
        business_name: detail.business_name || defaultState,
        category: detail.category,
        checkout_by_member_id: detail.checkout_by_member_id || defaultState,
        checkout_by_name: detail.checkout_by_name || defaultState,
        created_at: detail.created_at || defaultState,
        created_by: detail.created_by || defaultState,
        delivered_by_name: detail.delivered_by_name || defaultState,
        delivered_by_user_id: detail.delivered_by_user_id || defaultState,
        json_final: detail.json_final || defaultState,
        package_discard_reason: detail.package_discard_reason || defaultState,
        package_note: detail.package_note || defaultState,
        package_picked_reason: detail.package_picked_reason || defaultState,
        reminders: detail.reminders || defaultState,
        scanned_by: detail.scanned_by || defaultState,
        scanned_by_name: detail.scanned_by_name || defaultState,
        status: detail.status || defaultState,
        type: detail.type || defaultState,
        updated_at: detail.updated_at || defaultState,
        updated_by: detail.updated_by || defaultState,
        member_id: detail.member_id || defaultState,
        member_name: detail.member_name || defaultState,
        member_name_scanout: detail.pickup_info
          ? detail.pickup_info.name || defaultState
          : defaultState,
        dynamicLabels: labelsData,
        pickup_info: detail.pickup_info,
        dyanmicLabelsLocations: labelslocations,
        courier_name: detail.courier_name,
        tracking_number: detail.tracking_number,
        action_status: detail.action_status,
        package_labels: detail.package_labels,
        package_location: detail.package_location,
      },
    });
  };

  handleDialog = async () => {
    this.setOpen();
    this.setIndex(this.props.index);
    const { packageDetail, apiCallFlag } = this.state;
    const { id, packages, index } = this.props;
    // const filtered = [...packages].filter(item => id === item.package_id);
    const filtered = packages[index];
    if (packageDetail === '') {
      this.setPackageType(
        filtered.activity_type ? filtered.activity_type : filtered.scanType
      );
      if (!apiCallFlag) {
        await this.getPackage(id);
      }
    } else if (id !== packageDetail.package_id) {
      this.setDetails('');
      this.setPackageImage('');
      this.setSignature('');
      this.setPackageType(
        filtered.activity_type ? filtered.activity_type : filtered.scanType
      );
      if (!apiCallFlag) getPackageDetail(id);
      // this.setApiCallFlag(true,() => { this.getPackage(id); });
    }
  };

  handlePrevious = () => {
    // pkgId, nextIndex
    if (!this.state.forwarded) {
      this.setState({
        activeItem: true,
        activeHistory: false,
      });
      const { packages } = this.props;

      const { currentIndex, apiCallFlag } = this.state;
      const pkgId = packages[currentIndex - 1].package_id;
      const prevIndex = currentIndex - 1;
      const filtered = packages[prevIndex];
      const typeOfPackage = filtered.activity_type
        ? filtered.activity_type
        : filtered.scanType;
      this.setPackageType(typeOfPackage);
      this.setIndex(prevIndex);
      this.setDetails('');
      this.setPackageImage('');
      this.setSignature('');
      if (!apiCallFlag) {
        this.getPackage(pkgId);
      }
    }
  };

  handleNext = () => {
    if (!this.state.forwarded) {
      this.setState({
        activeItem: true,
        activeHistory: false,
      });
      const { packages } = this.props;
      const { currentIndex, apiCallFlag } = this.state;

      const pkgId = packages[currentIndex + 1].package_id;
      const nextIndex = currentIndex + 1;

      const filtered = packages[nextIndex];
      const typeOfPackage = filtered.activity_type
        ? filtered.activity_type
        : filtered.scanType;
      this.setPackageType(typeOfPackage);
      this.setIndex(nextIndex);
      this.setDetails('');
      this.setPackageImage('');
      this.setSignature('');
      if (!apiCallFlag) {
        this.getPackage(pkgId);
      }
    }
  };

  handleKeyPress = (e) => {
    const { packages } = this.props;
    const { currentIndex, apiCallFlag } = this.state;
    if (!apiCallFlag) {
      if (e.keyCode === 39) {
        if (packages[currentIndex + 1]) {
          this.handleNext();
        }
      } else if (e.keyCode === 37) {
        if (packages[currentIndex - 1]) {
          this.handlePrevious();
        }
      }
    }
  };

  render() {
    const { fullScreen, packages, thumbnail, style, t, fullSize } = this.props;
    const {
      open,
      currentIndex,
      packageDetail,
      // packageImage,
      // signature,
      activity_type,
      apiCallFlag,
    } = this.state;
    return (
      <>
        {fullSize ? (
          <div
            className="gallery__img-wrap padding-10px"
            role="presentation"
            onClick={this.handleDialog}
          >
            <img
              height={350}
              width={300}
              id={`pkg-${currentIndex}`}
              className="just-paper border-radius-4px"
              src={thumbnail}
              alt=""
              onError={() => {
                const elem = document.getElementById(`pkg-${currentIndex}`);
                elem.style.boxShadow = 'none';
                elem.src = NoImage;
                return elem;
              }}
            />
          </div>
        ) : (
          <TableCell className="thumbnail-cell width-60px cursor-none">
            <div aria-hidden onClick={this.handleDialog}>
              {thumbnail ? (
                <img
                  className="package-thumbnail bg-white"
                  id={`pkg-${currentIndex}`}
                  src={thumbnail}
                  alt=""
                  onError={() => {
                    const elem = document.getElementById(`pkg-${currentIndex}`);
                    elem.src = NoImage;
                    return elem;
                  }}
                />
              ) : (
                <img
                  className="icon package-thumbnail"
                  src={PackagesIconInactive}
                  alt=""
                />
              )}
            </div>
          </TableCell>
        )}
        <Dialog
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflowY: 'auto',
          }}
          on
          fullScreen={fullScreen}
          open={open}
          onClose={() => {
            this.setState({
              activeItem: true,
              activeHistory: false,
            });
            this.handleDialog();
          }}
          maxWidth="lg"
          fullWidth={fullScreen}
          onKeyDown={this.handleKeyPress}
          onKeyPress={this.handleKeyPress}
          scroll="body"
          PaperProps={{
            style: {
              width: window.screen.width < 1025 ? '100%' : '65%',
              overflowX: 'hidden',
              height: !fullScreen && '95%',
            },
          }}
          TransitionProps={{
            style: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              overflowY: 'auto',
            },
          }}
        >
          <SpaceBetween style={{ alignItems: 'center' }}>
            <div style={{ display: 'flex' }}>
              <div
                className={'package-heading'}
                onClick={() =>
                  this.setState({ activeItem: true, activeHistory: false })
                }
                style={{
                  borderBottom: this.state.activeItem
                    ? '5px solid black'
                    : 'none',
                }}
              >
                {t('package.title2')}
              </div>
              <div
                className={'package-heading'}
                onClick={() =>
                  this.setState({ activeHistory: true, activeItem: false })
                }
                style={{
                  borderBottom: this.state.activeHistory
                    ? '5px solid black'
                    : 'none',
                }}
              >
                {t('common.history')}
              </div>
            </div>
            <IconButton
              color="inherit"
              onClick={() => {
                this.setState({
                  activeItem: true,
                  activeHistory: false,
                });
                this.handleDialog();
              }}
              aria-label="Close"
              className="margin-right-10"
            >
              <Clear />
            </IconButton>
          </SpaceBetween>
          <Divider />

          {this.state.activeItem && (
            <Flex style={fullScreen && style.common.mt_16}>
              <Grid container style={{ overflowY: 'auto', marginBottom: 20 }}>
                <Grid
                  item
                  lg={6}
                  sm={12}
                  xs={12}
                  md={4}
                  className="package-image-grid"
                >
                  {!this.state.forwarded && window.screen.width < 1000 && (
                    <IconButton
                      disabled={apiCallFlag}
                      className={`package-prev-button ${currentIndex === 0 &&
                        'display-none'}`}
                    >
                      <NavigateBefore onClick={() => this.handlePrevious()} />
                    </IconButton>
                  )}

                  {!this.state.forwarded && window.screen.width < 1000 && (
                    <IconButton
                      disabled={apiCallFlag}
                      className={`package-next-button ${currentIndex ===
                        packages.length - 1 && 'display-none'}`}
                    >
                      <NavigateNext onClick={() => this.handleNext()} />
                    </IconButton>
                  )}
                  <ActionItemPackageImage
                    {...this.props}
                    {...this.state}
                    t={t}
                    style={style}
                  />
                  {/* {packageImage === '' ? (
                  <div style={style.package_img_height}>
                    <Skeleton width={251} />
                  </div>
                ) : (
                    <img
                      className="package-image"
                      src={packageImage === 'noImage' ? NoImage : `data:image/jpg;base64,${packageImage}`}
                      alt="Package"
                    />
                  )}
                {signature !== 'noSignature' && (
                  <>
                    {signature.length === 0 ? (
                      <div style={style.package_signature_height}>
                        <Skeleton width={251} />
                      </div>
                    ) : (
                        <img
                          src={`data:image/jpg;base64,${signature}`}
                          alt="Signature"
                          className="just-paper package-image"
                        />
                      )}
                  </>
                )} */}
                </Grid>
                <Grid item lg={6} sm={12} md={8} xs={12}>
                  <PackageDetails
                    {...this.props}
                    t={t}
                    style={style}
                    packages={packages}
                    details={packageDetail}
                    currentIndex={currentIndex}
                    forwarded={this.state.forwarded}
                    // activity_type={this.state.forwarded ? packageDetail.activity_type : activity_type === "" ? this.props.scanType : activity_type }
                    activity_type={packageDetail.activity_type}
                    getPackage={(id) => {
                      this.setState({ packageDetail: '' }, () => {
                        this.getPackage(id);
                        this.setIndex(
                          packages.findIndex((el) => el.package_id === id)
                        );
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Flex>
          )}

          {this.state.activeHistory &&
            this.state.package_history.map((item, index) => (
              <HistoryItemSingle
                key={index}
                data={item}
                activity_type={packageDetail.activity_type}
                pickup_info={packageDetail.pickup_info}
                t={t}
              />
            ))}

          {!this.state.forwarded && window.screen.width > 1000 && (
            <IconButton
              disabled={currentIndex === packages.length - 1}
              style={{
                position: 'fixed',
                top: '50%',
                right:
                  window.screen.width < 1100
                    ? '0%'
                    : window.screen.width === 1024
                    ? -10
                    : '10%',
                color: 'white',
                border: '3px solid',
                display:
                  currentIndex === packages.length - 1 || apiCallFlag
                    ? 'none'
                    : 'inherit',
              }}
              onClick={() => this.handleNext()}
            >
              <NavigateNext />
            </IconButton>
          )}
          {!this.state.forwarded && window.screen.width > 1000 && (
            <IconButton
              disabled={currentIndex === 0}
              style={{
                position: 'fixed',
                top: '50%',
                left:
                  window.screen.width < 1100
                    ? '0%'
                    : window.screen.width === 1024
                    ? -10
                    : '10%',
                color: 'white',
                border: '3px solid',
                display: currentIndex === 0 || apiCallFlag ? 'none' : 'inherit',
              }}
              onClick={() => this.handlePrevious()}
            >
              <NavigateBefore />
            </IconButton>
          )}
        </Dialog>
      </>
    );
  }
}

PackageDialog.propTypes = {
  index: PropTypes.number.isRequired,
  dynamicLabels: PropTypes.node.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  packages: PropTypes.object.isRequired,
  thumbnail: PropTypes.node.isRequired,
  id: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withMobileDialog()(PackageDialog);
