/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Topbar from '../topbar/Topbar';
import {
  changeMobileSidebarVisibility,
  //   changeSidebarVisibility,
} from '../../../redux/actions/sidebarActions';
import { setBuildings } from '../../../redux/actions/allBuildingsActions';
import { setRole } from '../../../redux/actions/userRoleActions';
import {
  CustomizerProps,
  SidebarProps,
  ThemeProps,
} from '../../../shared/prop-types/ReducerProps';
import { setBuilding } from '../../../redux/actions/locationsFilterActions';
import { setUser } from '../../../redux/actions/userActions';
import { setUser as setUserDetail } from '../../../redux/actions/userDeatilsActions';
// import { refreshToken } from '../../server/auth';

// let notification = null;
/* const showNotification = () => {
  notification.notice({
    content: <BasicNotification
      title="👋 Welcome to the EasyDev!"
      message="You have successfully registered in the EasyDev. Now you can start to explore the dashboard
                interface with a bunch of components and applications. Enjoy!"
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  });
}; */

class OnBoardingLayout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    customizer: CustomizerProps.isRequired,
    theme: ThemeProps.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      loggingout: false,
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleUnload);
    const { getProfile, dispatch } = this.props;
    // this.props.dispatch(changeSidebarVisibility());
    const env = process.env.REACT_APP_ENV;
    if (env && env.includes('cn')) {
      const auth = localStorage.px_auth;
      if (auth) {
        getProfile(localStorage.px_uid).then(profile => {
          const { user } = profile;
          this.setState({
            user: {
              name: user.UserAttributes[1].Value,
              image: user.user && user.user.user_image_link,
            },
          });
          if (profile.tenant.length === 1 && profile.tenant[0].buildings.length === 1) {
            dispatch(setBuilding([
              {
                building_id: profile.tenant[0].buildings[0].building_id,
                building_name: profile.tenant[0].buildings[0].building_name,
              },
            ]));
          }
          sessionStorage.setItem('px_tenant', user.role.tenant_id);
          let userdetails = user;
          userdetails.buildings = profile.tenant;
          const newBuildings = [];
          profile.tenant.map(item => {
            item.buildings.map(eachBuilding => {
              newBuildings.push(eachBuilding);
              return eachBuilding;
            });
            return item;
          });
          if (localStorage.px_search_suggestions) {
            const currentSuggestions = JSON.parse(localStorage.px_search_suggestions);
            const filteredArray = [...currentSuggestions].filter(item => {
              const suggestedBuilding = newBuildings.filter(currentItem => currentItem.building_id === item.building_id);
              if (suggestedBuilding.length === 0) {
                return false;
              }
              return true;
            });
            localStorage.px_search_suggestions = JSON.stringify(filteredArray);
          }
          dispatch(setUserDetail(userdetails));
          dispatch(setUserDetail(user));
          dispatch(setBuildings(profile.tenant));
          dispatch(setRole(profile.role.name));
          dispatch(setUser(profile.user.UserAttributes[3].Value));
        });
      }
    } else {
      let redirectState = localStorage.getItem('initialLoginState') ? localStorage.getItem('initialLoginState') : null;
      if (redirectState === 'FCP') this.props.history.replace(`/force_reset_password/${localStorage.getItem('px_user')}`);
      else if (redirectState === 'CND') this.props.history.replace(`/user_initial_details/${localStorage.getItem('px_user')}`);
      else {
        Auth.currentSession()
          .then(user => {
            const username = 'cognito:username';
            this.setState({ user: user.idToken.payload });
            if (!localStorage.getItem('onboarding')) {
              getProfile(user.idToken.payload[username]).then(profile => {
                this.setState({
                  user: {
                    name: profile.user && profile.user.UserAttributes[1].Value,
                    image: profile.user && profile.user.user_image_link,
                  },
                });

                if (localStorage.px_search_suggestions) {
                  const newBuildings = [];
                  profile.tenant.map(item => {
                    item.buildings.map(eachBuilding => {
                      newBuildings.push(eachBuilding);
                      return eachBuilding;
                    });
                    return item;
                  });
                  const currentSuggestions = JSON.parse(localStorage.px_search_suggestions);
                  const filteredArray = [...currentSuggestions].filter(item => {
                    const suggestedBuilding = [...newBuildings].filter(currentItem => currentItem.building_id === item.building_id);
                    if (suggestedBuilding.length === 0) {
                      return false;
                    }
                    return true;
                  });
                  localStorage.px_search_suggestions = JSON.stringify(filteredArray);
                }
                if (profile.tenant.length === 1 && profile.tenant[0].buildings.length === 1) {
                  dispatch(setBuilding([
                    {
                      building_id: profile.tenant[0].buildings[0].building_id,
                      building_name: profile.tenant[0].buildings[0].building_name,
                    },
                  ]));
                }
                sessionStorage.setItem('px_tenant', profile.role.tenant_id);
                let userdetails = profile.user;
                userdetails.buildings = profile.tenant;
                dispatch(setUserDetail(userdetails));
                dispatch(setBuildings(profile.tenant));
                dispatch(setRole(profile.role.name));
                dispatch(setUser(profile.user.UserAttributes[3].Value));
              });
            }
          })
          .catch(() => {
            // if (window.location.pathname !== '/' && 
            //       window.location.pathname !== 'forgot_password' &&
            //       !window.location.pathname.includes('/reset_password/')) {
            //         localStorage.setItem('redirectPath',window.location.pathname);
            //       }
          });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
  }

  handleUnload = (e) => {
    const message = '\o/';
    (e || window.event).returnValue = message; // Gecko + IE
    return message;
  }

  changeSidebarVisibility = () => {
    // this.props.dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    this.props.dispatch(changeMobileSidebarVisibility());
  };

  loggingout = () => this.setState({ loggingout: !this.state.loggingout });


  render() {
    const { user } = this.state;
    const { customizer, sidebar } = this.props;
    const hideSearch = true;
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
      'layout--top-navigation': customizer.topNavigation,
    });

    window.scrollTo(0, 0);
    // if (loggingout) return null;
    // else if (loggedin) {
    return (
      <div className={layoutClass}>
        <Topbar
          user={user}
          {...this.props}
          hideSearch={hideSearch}
          loggingout={this.loggingout}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
          skipTutorial={this.props.showSkipTutorial}
        />
      </div>
    );
    // }
    // return <Redirect to="/" />;
  }
}

export default withRouter(connect(state => ({
  customizer: state.customizer,
  sidebar: state.sidebar,
  buildings: state.allBuildings,
  uploading: state.csvuploading,
  userRole: state.userRole,
  countries: state.countries,
  username: state.user,
  showSkipTutorial: state.sidebar.showSkipTutorial
}))(OnBoardingLayout));
