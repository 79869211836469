import React from 'react';
import PropTypes from 'prop-types';

const RippleLoading = ({ variant }) => (
  <div className="align-center-column">
    <div className={`intro-banner-vdo-play-btn ${variant}Bg`} target="_blank">
      <i
        className="glyphicon glyphicon-play whiteText"
        aria-hidden="true"
      />
      <span className={`ripple ${variant}Bg`} />
      <span className={`ripple ${variant}Bg`} />
      <span className={`ripple ${variant}Bg`} />
    </div>
  </div>
);

RippleLoading.propTypes = {
  variant: PropTypes.string,
};

RippleLoading.defaultProps = {
  variant: 'black',
};

export default RippleLoading;
