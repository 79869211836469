import React from 'react';
import {
    // Select,
    // MenuItem,
    FormControl,
    NativeSelect
} from '@material-ui/core';
import PropTypes from 'prop-types';
// import { CustomInputLabel, CustomInput } from '../../UI/Input';

function TenantSelector(props) {
    const {
        // t,
        tenants,
        handleOnchange,
        selectedTenant,
    } = props;
    // const labelStyle = { fontSize: 13 };
    // const inputStyle = { fontSize: 13, color: '#000' };
    return (
        <React.Fragment>
            <FormControl style={{ marginTop: '-7px !important' }}>
                <NativeSelect
                    value={selectedTenant && selectedTenant.tenant_name}
                    onChange={(e) => handleOnchange(e)}
                    name="age"
                    inputProps={{ 'aria-label': 'age' }}
                >
                    {
                        tenants.map(eachTenant => {
                            const value = {
                                value: eachTenant.tenant_name,
                                tenant_id: eachTenant.tenant_id,
                            };
                            return (
                                <option value={JSON.stringify(value)}>{eachTenant.tenant_name}</option>
                                // <MenuItem value={value}>{eachTenant.tenant_name}</MenuItem>
                            );
                        })
                    }
                </NativeSelect>
                {/* <CustomInputLabel style={labelStyle} shrink>
                    {t('tables.tenant')}
                </CustomInputLabel>
                <Select
                    value={selectedTenant && selectedTenant.tenant_name}
                    onChange={(e) => handleOnchange(e)}
                    input={
                        <CustomInput required name="selectedRole" style={inputStyle} />
                    }
                >
                    {
                        tenants.map(eachTenant => {
                            const value = {
                                value: eachTenant.tenant_name,
                                tenant_id: eachTenant.tenant_id,
                            };
                            return (
                                <MenuItem value={value}>{eachTenant.tenant_name}</MenuItem>
                            );
                        })
                    }
                </Select> */}
            </FormControl>
        </React.Fragment>
    );
}

TenantSelector.propTypes = {
    // t: PropTypes.func.isRequired,
    tenants: PropTypes.array.isRequired,
    handleOnchange: PropTypes.func.isRequired,
    selectedTenant: PropTypes.node.isRequired,
};

export default TenantSelector;
