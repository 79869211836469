import React from 'react';
import PropTypes from 'prop-types';

const AlignCenter = ({ children, style }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      padding: 10,
      ...style,
    }}
  >
    {children}
  </div>
);

AlignCenter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.node.isRequired,
};

export default AlignCenter;
