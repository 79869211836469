import React from 'react';
import PropTypes from 'prop-types';
import {
  Collapse, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import BuildingsIcon from '../../../Layout/sidebar/BuildingsIcon';

function CollapsableBuildingList({ building }) {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => setOpen(!open);
  return (
    <>
      <ListItem className="padding-top-bottom-5px">
        <ListItemIcon className="margin-0px">
          <BuildingsIcon className="font-size-14" />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ style: { fontSize: 14 } }} primary={building.building_name} />
        <IconButton className="padding-0" onClick={handleClick}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItem>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {building.mailrooms.map(mailroom => (
            <>
              <ListItem className="padding-left-right-5px55px">
                <ListItemText primaryTypographyProps={{ style: { fontSize: 12 } }} primary={mailroom.mailroom_name} />
                <div className="font-size-12">{mailroom.mailroom_code}</div>
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
      </Collapse>
    </>
  );
}

CollapsableBuildingList.propTypes = {
  building: PropTypes.array.isRequired,
};

export default CollapsableBuildingList;
