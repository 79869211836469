import axios from 'axios';
import { server } from './server';


export function getNotifications(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${server}/entity/user/notification`,{ params })
        .then(response => {
          resolve(response);
        }).catch(error => reject(error));
    });
}

export function updateNotifications(params) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${server}/entity/user/notification`,params)
      .then(response => {
        resolve(response);
      }).catch(error => reject(error));
  });
}
