import CryptoJS from 'crypto-js';
import { Auth } from 'aws-amplify';
import { getDaysDifference, getCommaSeperatedNumber } from './utility.functions';
// const CryptoJS = require('crypto-js');
/* eslint-disable quotes */
const buildingsTableColumnHeader = [
  {
    name: 'Thumbnail',
    expanded: true,
    value: 'buisness_logo',
  },
  {
    name: 'tables.name',
    expanded: true,
    value: 'name',
  },
  {
    name: 'tables.code',
    expanded: true,
    value: 'code',
  },
  {
    name: 'buildings.city',
    expanded: true,
    value: '_',
  },
  {
    name: 'buildings.country',
    expanded: true,
    value: '_',
  },
  {
    name: 'members.activeMembers',
    expanded: true,
    value: 'active_members',
  },
  {
    name: 'members.activeBusinesses',
    expanded: true,
    value: 'active_businesses',
  },
  {
    name: 'tables.overduePackages',
    expanded: true,
    value: 'overdue_packages',
  },
];

const voBuildingsTableColumnHeader = [
  {
    name: 'Thumbnail',
    expanded: true,
    value: 'buisness_logo',
  },
  {
    name: 'tables.name',
    expanded: true,
    value: 'name',
  },
  {
    name: 'tables.code',
    expanded: true,
    value: 'code',
  },
  {
    name: 'buildings.city',
    expanded: true,
    value: '_',
  },
  {
    name: 'buildings.country',
    expanded: true,
    value: '_',
  },
  {
    name: 'members.activeMembers',
    expanded: true,
    value: 'active_members',
  },
  {
    name: 'M&P Members',
    expanded: true,
    value: 'active_voMembers',
  },
  {
    name: 'members.activeBusinesses',
    expanded: true,
    value: 'active_businesses',
  },
  {
    name: 'tables.overduePackages',
    expanded: true,
    value: 'overdue_packages',
  },
];


const recentHistoryTableColumnHeader = [
  {
    name: 'Updated By',
    expanded: true,
    value: 'updated_by',
  },
  {
    name: 'Time',
    expanded: true,
    value: 'time',
  },
  {
    name: 'Type',
    expanded: true,
    value: 'Type',
  },
  {
    name: 'Status',
    expanded: true,
    value: 'status',
  },
  {
    name: 'File',
    expanded: true,
    value: 'link',
  },
];

const associatesTableColumnHeader = [
  {
    name: 'Thumbnail',
    expanded: true,
    value: 'associate_THUMBNAIL',
    secondaryValue: 'associate_THUMBNAIL',
  },
  {
    name: 'tables.name',
    expanded: true,
    value: 'name',
    secondaryValue: 'name',
  },
  {
    name: 'common.email',
    expanded: true,
    value: 'email',
    secondaryValue: 'email',
  },
  {
    name: 'tables.location',
    expanded: true,
    value: 'user_location',
    secondaryValue: 'user_location',
  },
  {
    name: 'tables.role',
    expanded: true,
    value: 'role',
    secondaryValue: 'role',
  },
  {
    name: 'tables.user_status',
    expanded: true,
    value: 'user_status',
    secondaryValue: 'user_status',
  },
  {
    name: 'tables.delete',
    expanded: true,
    value: 'deleteAssociate',
    secondaryValue: 'deleteAssociate',
  },
];

const voMembersTableColumnHeader = [
  {
    name: 'Thumbnail',
    expanded: true,
    value: 'member_THUMBNAIL',
  },
  {
    name: 'tables.name',
    expanded: true,
    value: 'name',
  },
  {
    name: 'common.business',
    expanded: true,
    value: 'business_name',
  },
  {
    name: 'tables.building',
    expanded: true,
    value: 'building_name',
  },
  {
    name: 'tables.office',
    expanded: true,
    value: 'office_number',
  },
  {
    name: 'tables.email',
    expanded: false,
    value: 'email',
  },
  {
    name: 'tables.phone',
    expanded: true,
    value: 'phone',
  },
  {
    name: 'tables.totalReceived',
    expanded: true,
    value: 'Total_Received',
  },
  {
    name: 'tables.pendingPackages',
    expanded: true,
    value: 'pending_packages',
  },
  // {
  //   name: "tables.allPackages",
  //   expanded: true,
  //   value: "all_packages",
  // },
];

const membersTableColumnHeader = [
  {
    name: 'Thumbnail',
    expanded: true,
    value: 'member_THUMBNAIL',
  },
  {
    name: 'tables.name',
    expanded: true,
    value: 'name',
  },
  {
    name: 'common.business',
    expanded: true,
    value: 'business_name',
  },
  {
    name: 'tables.building',
    expanded: true,
    value: 'building_name',
  },
  {
    name: 'tables.office',
    expanded: true,
    value: 'office_number',
  },
  {
    name: 'tables.email',
    expanded: false,
    value: 'email',
  },
  {
    name: 'tables.phone',
    expanded: true,
    value: 'phone',
  },
  {
    name: 'tables.totalReceived',
    expanded: true,
    value: 'Total_Received',
  },
  {
    name: 'tables.pendingPackages',
    expanded: true,
    value: 'pending_packages',
  },
  // {
  //   name: "tables.allPackages",
  //   expanded: true,
  //   value: "all_packages",
  // },
];

const businessesTableColumnHeader = [
  {
    name: 'Thumbnail',
    expanded: true,
    value: 'business_thumbnail',
  },
  {
    name: 'tables.name',
    expanded: true,
    value: 'name',
  },
  {
    name: 'tables.building',
    expanded: true,
    value: 'building_name',
  },
  {
    name: 'tables.totalMembers',
    expanded: true,
    value: 'total_members',
  },
  {
    name: 'tables.designated',
    expanded: true,
    value: 'designated_members',
  },
  // {
  //   name: t('tables.pending_packages'),
  //   expanded: false,
  //   value: "pending_packages",
  // },
  {
    name: 'tables.membersPackages',
    expanded: true,
    value: 'all_packages',
  },
];

const packagesTableColumnHeader = (t) => [
  {
    name: 'Thumbnail',
    expanded: true,
    value: 'package_thumbnail',
    secondaryValue: 'package_thumbnail',
  },
  {
    name: t('tables.name'),
    expanded: true,
    value: 'memberName',
    secondaryValue: 'member_name',
  },
  {
    name: t('common.business'),
    expanded: true,
    value: 'business_name',
  },
  {
    name: t('tables.location'),
    expanded: true,
    value: 'buildingName',
    secondaryValue: 'building_name',
  },
  {
    name: t('tables.status'),
    expanded: true,
    value: 'status',
  },
  {
    name: t('package.checkedIn'),
    expanded: true,
    value: 'createdAt',
    secondaryValue: 'created_at',
  },
  {
    name: t('package.checkedOut'),
    expanded: true,
    value: 'updatedAt',
    secondaryValue: 'updated_at',
  },
  {
    name: t('package.scanType'),
    expanded: true,
    value: 'activity_type',
    secondaryValue: 'activity_type',
  },
];

const voPackagesTableColumnHeader = (t) => [
  {
    name: 'Thumbnail',
    expanded: true,
    value: 'package_thumbnail',
    secondaryValue: 'package_thumbnail',
  },
  {
    name: t('tables.name'),
    expanded: true,
    value: 'memberName',
    secondaryValue: 'member_name',
  },
  {
    name: t('common.business'),
    expanded: true,
    value: 'business_name',
  },
  {
    name: t('tables.location'),
    expanded: true,
    value: 'buildingName',
    secondaryValue: 'building_name',
  },
  {
    name: t('tables.status'),
    expanded: true,
    value: 'status',
  },
  {
    name: t('package.checkedIn'),
    expanded: true,
    value: 'createdAt',
    secondaryValue: 'created_at',
  },
  {
    name: t('package.checkedOut'),
    expanded: true,
    value: 'updatedAt',
    secondaryValue: 'updated_at',
  },
  {
    name: t('package.scanType'),
    expanded: true,
    value: 'activity_type',
    secondaryValue: 'activity_type',
  },
];


const advancedSearchOptions = [
  // {
  //   label: 'package.recipientName',
  //   minWidth: 111,
  //   fieldType: 'input',
  //   hasInfo: false,
  //   display: false,
  // },
  {
    label: 'package.recipientLocation',
    minWidth: 128,
    fieldType: 'dropdown',
    hasInfo: false,
    display: false,
  },
  {
    label: 'package.pickedupBy',
    minWidth: 94,
    fieldType: 'input',
    hasInfo: false,
    display: true,
  },
  {
    label: 'package.scannedBy',
    minWidth: 86,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'tables.scanType',
    minWidth: 75,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'tables.status',
    minWidth: 50,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'tables.checkIn',
    minWidth: 65,
    fieldType: 'date',
    hasInfo: false,
    display: true,
  },
  // {
  //   label: 'package.checkinDate',
  //   minWidth: 100,
  //   fieldType: 'date',
  //   hasInfo: false,
  //   display: true,
  // },
  // {
  //   label: 'package.checkoutDate',
  //   minWidth: 82,
  //   fieldType: 'date',
  //   hasInfo: false,
  //   display: true,
  // },
  // {
  //   label: 'package.tags',
  //   minWidth: 50,
  //   fieldType: 'dropdown',
  //   hasInfo: false,
  //   display: false,
  // },
  // {
  //   label: 'package.trackingNumber',
  //   minWidth: 119,
  //   fieldType: 'input',
  //   hasInfo: false,
  //   display: false,
  // },
  {
    label: 'package.courier',
    minWidth: 52,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'sidebar.members',
    minWidth: 75,
    fieldType: 'dropdown',
    hasInfo: false,
  },
  {
    label: 'tables.businesses',
    minWidth: 57,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  // {
  //   label: 'package.recipientCity',
  //   minWidth: 108,
  //   fieldType: 'input',
  //   hanInfo: true,
  //   info: 'package.recipientCity',
  //   display: false,
  // },
  // {
  //   label: 'package.recipientState',
  //   minWidth: 117,
  //   fieldType: 'input',
  //   hanInfo: true,
  //   info: 'package.recipientState',
  //   display: false,
  // },
  // {
  //   label: 'package.recipientZipcode',
  //   minWidth: 139,
  //   fieldType: 'input',
  //   hanInfo: true,
  //   info: 'package.recipientZipcode',
  //   display: false,
  // },
  // {
  //   label: 'package.senderCity',
  //   minWidth: 93,
  //   fieldType: 'input',
  //   hanInfo: true,
  //   info: 'package.senderCity',
  //   display: false,
  // },
  // {
  //   label: 'package.senderState',
  //   minWidth: 102,
  //   fieldType: 'input',
  //   hanInfo: true,
  //   info: 'package.senderState',
  //   display: false,
  // },
  // {
  //   label: 'package.senderZipcode',
  //   minWidth: 132,
  //   fieldType: 'input',
  //   hanInfo: true,
  //   info: 'package.senderZipcode',
  //   display: false,
  // },
  // {
  //   label: 'tables.location',
  //   minWidth: 64,
  //   fieldType: 'input',
  //   hanInfo: true,
  //   info: 'tables.location',
  //   display: true,
  // },
  {
    label: 'tables.actionStatus',
    minWidth: 54,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'tables.label',
    minWidth: 54,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'tables.location',
    minWidth: 65,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
];

const voAdvanceSearchOptions = [
  // {
  //   label: 'package.recipientName',
  //   minWidth: 111,
  //   fieldType: 'input',
  //   hasInfo: false,
  //   display: false,
  // },
  {
    label: 'package.recipientLocation',
    minWidth: 128,
    fieldType: 'dropdown',
    hasInfo: false,
    display: false,
  },
  {
    label: 'package.pickedupBy',
    minWidth: 94,
    fieldType: 'input',
    hasInfo: false,
    display: true,
  },
  {
    label: 'package.scannedBy',
    minWidth: 86,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'tables.scanType',
    minWidth: 75,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'tables.status',
    minWidth: 50,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'tables.checkIn',
    minWidth: 65,
    fieldType: 'date',
    hasInfo: false,
    display: true,
  },
  {
    label: 'package.courier',
    minWidth: 52,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'voItems.voMembers',
    minWidth: 75,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'tables.businesses',
    minWidth: 57,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'tables.actionStatus',
    minWidth: 54,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'tables.label',
    minWidth: 54,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
  {
    label: 'tables.location',
    minWidth: 65,
    fieldType: 'dropdown',
    hasInfo: false,
    display: true,
  },
];

const buildingsAPIExposeTableColumnHeader = (t) => [
  {
    name: 'Thumbnail',
    expanded: true,
    value: 'buisness_logo',
  },
  {
    name: t('tables.name'),
    expanded: true,
    value: 'name',
  },
  {
    name: t('tables.code'),
    expanded: true,
    value: 'code',
  },
  {
    name: t('apiexpose.id'),
    expanded: true,
    value: '_',
  },
  // {
  //   name: t('buildings.city'),
  //   expanded: true,
  //   value: "_",
  // },
  // {
  //   name: t('buildings.country'),
  //   expanded: true,
  //   value: "_",
  // },
];

const userRolesHeirarchy = [
  'super_admin',
  'admin',
  'building_manager',
  'mailroom_supervisor',
];

const daysLabels = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const hoursLabels = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];

function GetMonthName(number, t) {
  number = parseInt(number, 10);
  if (number === 1) return t('month.january');
  else if (number === 2) return t('month.february');
  else if (number === 3) return t('month.march');
  else if (number === 4) return t('month.april');
  else if (number === 5) return t('month.may');
  else if (number === 6) return t('month.june');
  else if (number === 7) return t('month.july');
  else if (number === 8) return t('month.august');
  else if (number === 9) return t('month.september');
  else if (number === 10) return t('month.october');
  else if (number === 11) return t('month.november');
  return t('month.december');
}

const chartFilterOptions = [
  'today',
  'last_7_days',
  'last_4_weeks',
  'last_12_months',
];

const scanDetailsDropdownOptions = [
  'main.filterHourly',
  'main.filterDaily',
  'main.filterWeekly',
  'main.filterMonthly',
];

const busySlotsOptions = [
  {
    label: 'main.none',
    value: 'none',
  },
  {
    label: 'main.hourly',
    value: 'hourly',
  },
  {
    label: 'main.weekly',
    value: 'weekly',
  },
];

const packagesStatues = [
  {
    label: 'tables.overdue',
    value: 'overdue',
  },
  {
    label: 'tables.holding',
    value: 'holding',
  },
  {
    label: 'tables.collected',
    value: 'collected',
  },
  {
    label: 'package.scanned',
    value: 'scanned',
  },
  {
    label: 'tables.notified',
    value: 'notified',
  },
  {
    label: 'tables.pickedup',
    value: 'pickedup',
  },
];

const onBoardingScreens = [
  'SetPassword',
  'Welcome',
  'AddBuilding',
  'AddRecipients',
  'DownloadApp',
  'AccountReady',
  'SignInMobile',
  'ScanLabel',
  'SendNotifications',
  'PickupPackages',
  'AllSet',
];

const setTableColumn = (t, columns) => {
  const updatedColumn = columns.map((item, index) => {
    let tempColumn = { ...item };
    if (index > 0) {
      const columnsNames = tempColumn.name.split(' ');
      tempColumn.name = '';
      columnsNames.map((item1) => {
        tempColumn.name = `${tempColumn.name} ${t(item1)} `
          .trim()
          .toLowerCase();
        return item1;
      });
    }
    return tempColumn;
  });
  return updatedColumn;
};

const getUserEmailURL = () => {
  let emailString = window.location.search.substring(1);
  if (emailString === '') {
    return null;
  } else {
    return emailString.split('=')[1];
  }
};

const decryptEmailWithAES = async (ciphertext) => {
  const bytes = await CryptoJS.AES.decrypt(
    decodeURIComponent(ciphertext),
    process.env.REACT_APP_PASSPHRASE,
  );
  const originalText = await bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const encryptEmailWithAES = async (text) => {
  const email = await CryptoJS.AES.encrypt(
    text,
    process.env.REACT_APP_PASSPHRASE,
  ).toString();
  return email;
};

export const getEncryptedEmail = (email, callback) => {
  let encryptedEmail = null;
  encryptEmailWithAES(email).then((res) => {
    callback(res);
  });
  return encryptedEmail;
};

const checkAWSSession = (callBack) => {
  Auth.currentUserInfo()
    .then((session) => {
      if (
        session.attributes &&
        session.attributes['custom:signup_state'] === 'CNOD'
      ) {
        const userEmail =
          localStorage.getItem('px_user') ||
          (session.idToken.payload && session.idToken.payload.email);
        getEncryptedEmail(
          userEmail,
          (encryptedEmail) => callBack(encryptedEmail),
          /* eslint-disable next-line */
        );
      } else {
        callBack(null);
      }
    })
    .catch(() => {
      callBack(null);
    });
};

const getPlanFeaturesArrayNew = (features) => {
  let featureArray = new Array(10).fill(null);
  if (!features) return featureArray;

  if (features.MAILROOM_LIMIT) {
    featureArray[0] = features.MAILROOM_LIMIT;
  }

  if (features.USERS_LIMIT) {
    featureArray[1] = features.USERS_LIMIT;
  }

  if (features.SMS) {
    featureArray[2] = features.SMS;
  }

  if (features.EMAIL_NOTIFICATIONS || features.EMAILS) {
    featureArray[3] = features.EMAIL_NOTIFICATIONS || features.EMAILS;
  }

  if (features.CO_BRANDING || features.LOGO_BRANDING) {
    featureArray[4] = features.CO_BRANDING || features.LOGO_BRANDING;
  }

  if (features.CUSTOM_LABELS) {
    featureArray[5] = features.CUSTOM_LABELS;
  }

  if (features.ACTION_ITEMS) {
    featureArray[6] = features.ACTION_ITEMS;
  }

  if (features.WEBHOOK_NOTIFICATION || features.EXPOSE_API) {
    featureArray[7] = true;
  }

  if (features.CUSTOMIZATION) {
    featureArray[8] = features.CUSTOMIZATION;
  }

  if (features.SUPPORT) {
    featureArray[9] = features.SUPPORT;
  }
  return featureArray;
};

const getPlanFeatures = (features, type) => {
  let featureArray = [];
  if (!features) return featureArray;

  if (features.MAILROOM_LIMIT) {
    if (features.MAILROOM_LIMIT > 1) {
      featureArray = [
        ...featureArray,
        `${features.MAILROOM_LIMIT}  Mailrooms`,
      ];
    } else {
      featureArray = [...featureArray, `1  Mailroom`];
    }
  }
  if (features.BUILDING_LIMIT) {
    if (features.BUILDING_LIMIT > 1) {
      featureArray = [
        ...featureArray,
        `${features.BUILDING_LIMIT} Delivery site at building`,
      ];
    } else {
      featureArray = [...featureArray, `1 Delivery site at building`];
    }
  }

  if (features.MONTHLY_SCAN_LIMIT || features.ANNUALLY_SCAN_LIMIT) {
    if (type === 1) { // monthly
      const limitMonthly = getCommaSeperatedNumber(parseInt(features.MONTHLY_SCAN_LIMIT / 12, 10));
      featureArray = [
        ...featureArray,
        `Monthly deliveries Up to ${limitMonthly} (${getCommaSeperatedNumber(features.MONTHLY_SCAN_LIMIT)} annually)`,
      ];
    } else { // yearly
      const limitMonthly = parseInt(features.ANNUALLY_SCAN_LIMIT / 12, 10);
      featureArray = [
        ...featureArray,
        `Monthly deliveries Up to ${limitMonthly} (${getCommaSeperatedNumber(features.ANNUALLY_SCAN_LIMIT)} annually)`,
      ];
    }
    // featureArray = [
    //   ...featureArray,
    //   `Monthly deliveries Up to ${
    //     features.SCAN_LIMIT
    //   } (${features.SCAN_LIMIT * 12} annually)`,
    // ];
  }

  if (features.USERS_LIMIT) {
    if (features.USERS_LIMIT > 1) {
      featureArray = [
        ...featureArray,
        `${features.USERS_LIMIT} Mailroom users`,
      ];
    } else {
      featureArray = [...featureArray, `1 Mailroom user`];
    }
  }

  if (features.EMAIL_NOTIFICATIONS || features.EMAILS) {
    featureArray = [...featureArray, `Email notifications`];
  }

  if (features.SMS) {
    featureArray = [
      ...featureArray,
      'Text notifications (SMS) available in (US, Canada)​',
    ];
  }

  if (features.CO_BRANDING || features.LOGO_BRANDING) {
    featureArray = [...featureArray, 'Co-branding (Your logo, messaging)'];
  }

  if (features.LABELS_SORTING || features.CUSTOM_LABELS) {
    featureArray = [...featureArray, `Custom labels for sorting & storage`];
  }

  if (features.EXPOSE_API) {
    featureArray = [
      ...featureArray,
      `Integration with existing systems (APIs)`,
    ];
  }

  if (features.WEBHOOK_NOTIFICATION) {
    featureArray = [
      ...featureArray,
      `Integration with existing systems (Webhooks)`,
    ];
  }

  if (features.ACTION_ITEMS) {
    featureArray = [
      ...featureArray,
      `Mail and packages actions (remote recipient & mailroom coordination)`,
    ];
  }

  if (features.CUSTOMIZATION) {
    featureArray = [
      ...featureArray,
      `Custom integrations`,
    ];
  }

  if (features.SUPPORT) {
    featureArray = [...featureArray, features.SUPPORT];
  }
  return featureArray;
};

const starterFeatures = [
  '1 Delivery site at building',
  'Monthly deliveries Up to 400 (4,800 annually)​',
  '1 Mailroom user',
  'Email notifications',
  'Support through email with 24 hr response time​',
];

const basicFeatures = [
  '3 Delivery sites at buildings',
  'Monthly deliveries Up to 2,000 (24,000 annually)​​',
  '6 Mailroom user',
  'Email notifications',
  'Co-branding (Your logo, messaging)',
  'Custom labels for sorting & storage',
  'Support through email with 12 hr response time​',
];

const premiumFeatures = [
  '5 Delivery sites at buildings',
  'Monthly deliveries Up to 7,000 (84,000 annually)​​​',
  '10 Mailroom user',
  'Email notifications',
  'Text notifications (SMS) available in (US, Canada)​',
  'Co-branding (Your logo, messaging)',
  'Custom labels for sorting & storage',
  'Mail and packages actions (remote recipient & mailroom coordination)',
  'Integration with existing systems (API’s and webhooks)',
  'Support through email with 4 hr response time​',
];

const getAppURLs = (redirectURL) => {
  const variable =
    process.env.REACT_APP_ENV === undefined
      ? process.env.NODE_ENV
      : process.env.REACT_APP_ENV;
  const env =
    variable === 'development'
      ? 'dev'
      : variable === 'production'
        ? 'prod'
        : variable;
  switch (env) {
    case 'dev':
      return `https://dev.packagex.xyz${redirectURL}`;
    case 'qa':
      return `https://qa.packagex.xyz${redirectURL}`;
    case 'uat':
      return `https://uat.packagex.app${redirectURL}`;
    case 'wework':
      return `https://wework.packagex.app${redirectURL}`;
    case 'demo':
      return `https://wework.packagex.app${redirectURL}`;
    case 'production':
      return `https://portal.packagex.app${redirectURL}`;
    default:
      return `https://portal.packagex.app${redirectURL}`;
  }
};

const getPaymentSuccessURL = () => getAppURLs('/payment-successful');

const getPaymentCancelURL = () => getAppURLs('/payment-failed');

const getPackageHistoryMessage = (status, activity_type, t, pickup_info) => {
  if (status === 'SCANNED') return t('historySentences.scanned');
  if (status === 'NOTIFIED') return t('historySentences.notified');
  if (status === 'REMINDER') return t('historySentences.reminder');
  if (status === 'SNAPSEND') return t('historySentences.receiveScanSend');
  if (status === 'REQUEST_FORWARD') return t('historySentences.requestForward');
  if (status === 'FORWARDED') return t('historySentences.receiveForward');
  if (status === 'REQUEST_HOLD') return t('historySentences.requestHold');
  if (status === 'HOLD') return t('historySentences.held');
  if (status === 'REQUEST_DESTROY') return t('historySentences.requestDestroy');
  if (status === 'DESTROY') return t('historySentences.destroyed');
  if (status === 'FORWARD' && activity_type === 'SCAN-OUT') {
    return t('historySentences.forwardItemCreated');
  }
  if (status === 'PICKED' && activity_type === 'SCAN-IN') {
    if (pickup_info && pickup_info.name) {
      return (
        t('historySentences.pickedRecipient') + (pickup_info && pickup_info.name && pickup_info.name)
      );
    } else {
      return (
        t('historySentences.markedPicked')
      );
    }
  }
  if (status === 'PICKED' && activity_type === 'SCAN-OUT') {
    return t('historySentences.pickedCarrier');
  }
  if (status === 'REQUEST_SNAPSEND') {
    return t('historySentences.requestScanSend');
  }
  if (status === 'DISCARDED') {
    return t('historySentences.discarded');
  }
  if (status === 'REROUTED') {
    return t('historySentences.rerouted');
  }
  if (status && status.toUpperCase() === "DISPATCHED") {
    return "Item was dispatched";
  }
};

const serializeURL = (obj) =>
  Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&');

const getURLParamsNotification = () => {
  const filteredParams = window.location.search.substring(1).split('&');
  if (filteredParams.length < 3) {
    return null;
  } else {
    const paramsArray = filteredParams.map((params) => params.split('=')[1]);
    if (paramsArray[0] && paramsArray[1] && paramsArray[2]) {
      return paramsArray;
    } else {
      return null;
    }
  }
};

const getDataFromAWSAttributes = (attributes) => {
  let filteredData = Array(2).fill(null);
  if (attributes.length > 0) {
    attributes.forEach((temp) => {
      if (temp.Name.toLowerCase() === 'name') {
        filteredData[0] = { name: temp.Value };
      } else if (temp.Name.toLowerCase() === 'email') {
        filteredData[1] = { email: temp.Value };
      }
    });
  }
  return filteredData;
};

const validateTanent = (tenant) => {
  if (tenant) {
    if (tenant.length > 0 && tenant[0]) {
      return tenant;
    } else {
      return null;
    }
  } else return null;
};

const checkValidCLATanent = (tenant) => {
  const validTenant = validateTanent(tenant);
  if (
    validTenant &&
    tenant[0].billing &&
    tenant[0].billing.toLowerCase() === 'monthly'
  ) {
    if (
      validTenant &&
      tenant[0].features &&
      tenant[0].features.MONTHLY_SCAN_LIMIT
    ) {
      return tenant;
    } else return null;
  } else if (
    validTenant &&
    tenant[0].billing &&
    (tenant[0].billing.toLowerCase() === 'annually' ||
      tenant[0].billing.toLowerCase() === 'yearly')
  ) {
    if (
      validTenant &&
      tenant[0].features &&
      tenant[0].features.ANNUALLY_SCAN_LIMIT
    ) {
      return tenant;
    } else return null;
  } else return null;
};

const function_checkScanLimitReached = (incomingTenant) => {
  const tenant = checkValidCLATanent(incomingTenant);
  if (tenant) {
    if (tenant[0].billing && tenant[0].billing.toLowerCase() === 'monthly') {
      if (
        tenant[0].buildings[0].total_scans &&
        tenant[0].features.MONTHLY_SCAN_LIMIT <=
        parseInt(tenant[0].buildings[0].total_scans, 10)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      tenant[0].billing &&
      (tenant[0].billing.toLowerCase() === 'annually' ||
        tenant[0].billing.toLowerCase() === 'yearly')
    ) {
      if (
        tenant[0].buildings[0].total_scans &&
        tenant[0].features.ANNUALLY_SCAN_LIMIT <=
        parseInt(tenant[0].buildings[0].total_scans, 10)
      ) {
        return true;
      } else {
        return false;
      }
    }
  } else return false;
};

const function_checkTrialAboutToExpire = (
  incomingTenant,
  showTrialAboutToExpireModal,
) => {
  const tenant = checkValidCLATanent(incomingTenant);
  const validUntill =
    tenant &&
    tenant[0].tier &&
    tenant[0].valid_until &&
    getDaysDifference(tenant[0].valid_until);
  if (
    tenant &&
    validUntill &&
    validUntill < 5 &&
    validUntill > 0 &&
    showTrialAboutToExpireModal !== true &&
    tenant[0].status &&
    tenant[0].status === 'TRIAL'
  ) {
    return true;
  } else return false;
};

const function_checkFreeTrailExpired = (
  incomingTenant,
  showFreeTrailExpiredModal,
) => {
  const tenant = checkValidCLATanent(incomingTenant);
  const validUntill =
    tenant &&
    tenant[0].tier &&
    tenant[0].valid_until &&
    getDaysDifference(tenant[0].valid_until);
  if (
    tenant &&
    validUntill <= 0 &&
    showFreeTrailExpiredModal !== true &&
    tenant[0].billing_method &&
    tenant[0].billing_method.toLowerCase() === 'stripe' &&
    tenant[0].status &&
    (tenant[0].status.toLowerCase() === 'trial')
  ) {
    return true;
  } else return false;
};

const function_checkInsufficientFunds = (incomingTenant) => {
  const tenant = checkValidCLATanent(incomingTenant);
  if (tenant && tenant[0].status && tenant[0].status.toLowerCase() === 'open') {
    return true;
  } else return false;
};

const function_checkPlanUpgradeNeededUserCheck = (incomingTenant) => {
  const tenant = checkValidCLATanent(incomingTenant);
  if (tenant) {
    const totalUsers = parseInt(tenant[0].total_users, 10);
    const UserLimit = parseInt(tenant[0].features.USERS_LIMIT, 10);
    if (totalUsers >= UserLimit) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

const function_checkPlanUpgradeNeededBuildingCheck = (incomingTenant) => {
  const tenant = checkValidCLATanent(incomingTenant);
  if (tenant) {
    const totalBuildings = parseInt(tenant[0].total_buildings, 10);
    const buildingLimit = parseInt(tenant[0].features.BUILDING_LIMIT, 10);
    if (totalBuildings >= buildingLimit) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

const function_checkPlanUpgradeNeededMailroomCheck = (incomingTenant) => {
  const tenant = checkValidCLATanent(incomingTenant);
  if (tenant) {
    const totalMailroom = parseInt(tenant[0].total_mailrooms, 10);
    const mailroomLimit = parseInt(tenant[0].features.MAILROOM_LIMIT, 10);
    if (totalMailroom >= mailroomLimit) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

const getFullTierName = (tier) => {
  if (!tier) return '';
  let tempTier = tier.toLowerCase();
  if (tempTier === 'starter_custom') {
    tempTier = 'Customized Starter';
  } else if (tempTier === 'basic_custom') {
    tempTier = 'Customized Basic';
  } else if (tempTier === 'premium_custom') {
    tempTier = 'Customized Premium';
  } else if (tempTier === 'custom') {
    tempTier = 'Enterprise';
  } else if (tempTier === 'basic') {
    tempTier = 'Basic';
  } else if (tempTier === 'premium') {
    tempTier = 'Premium';
  } else if (tempTier === 'starter') {
    tempTier = 'Starter';
  } else {
    tempTier = '';
  }
  return tempTier;
};

export {
  getPlanFeatures,
  getPlanFeaturesArrayNew,
  checkAWSSession,
  daysLabels,
  hoursLabels,
  GetMonthName,
  packagesStatues,
  busySlotsOptions,
  userRolesHeirarchy,
  chartFilterOptions,
  advancedSearchOptions,
  membersTableColumnHeader,
  packagesTableColumnHeader,
  buildingsTableColumnHeader,
  scanDetailsDropdownOptions,
  businessesTableColumnHeader,
  associatesTableColumnHeader,
  buildingsAPIExposeTableColumnHeader,
  setTableColumn,
  getUserEmailURL,
  recentHistoryTableColumnHeader,
  encryptEmailWithAES,
  decryptEmailWithAES,
  onBoardingScreens,
  starterFeatures,
  basicFeatures,
  premiumFeatures,
  getPaymentSuccessURL,
  getPaymentCancelURL,
  getPackageHistoryMessage,
  serializeURL,
  getURLParamsNotification,
  getDataFromAWSAttributes,
  function_checkScanLimitReached,
  function_checkTrialAboutToExpire,
  function_checkFreeTrailExpired,
  function_checkInsufficientFunds,
  function_checkPlanUpgradeNeededBuildingCheck,
  function_checkPlanUpgradeNeededUserCheck,
  function_checkPlanUpgradeNeededMailroomCheck,
  getFullTierName,
  voMembersTableColumnHeader,
  voBuildingsTableColumnHeader,
  voPackagesTableColumnHeader,
  voAdvanceSearchOptions
};
