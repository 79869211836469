import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@material-ui/core';
import slider_img from '../../../../assets/images/CSVUploadImages/slider_img.svg';
import CLA_CSV_1 from '../../../../assets/images/CSVUploadImages/CLA_CSV_1.svg';
import CLA_CSV_2 from '../../../../assets/images/CSVUploadImages/CLA_CSV_2.svg';
import CLA_CSV_3 from '../../../../assets/images/CSVUploadImages/CLA_CSV_3.svg';
import CLA_CSV_4 from '../../../../assets/images/CSVUploadImages/CLA_CSV_4.svg';
import CLA_CSV_5 from '../../../../assets/images/CSVUploadImages/CLA_CSV_5.svg';
import CLA_CSV_6 from '../../../../assets/images/CSVUploadImages/CLA_CSV_6.svg';
import CLA_CSV_7 from '../../../../assets/images/CSVUploadImages/CLA_CSV_7.svg';
import CLA_CSV_8 from '../../../../assets/images/CSVUploadImages/CLA_CSV_8.svg';

const images = [
    { imag: CLA_CSV_1 },
    { imag: CLA_CSV_2 },
    { imag: CLA_CSV_3 },
    { imag: CLA_CSV_4 },
    { imag: CLA_CSV_5 },
    { imag: CLA_CSV_6 },
    { imag: CLA_CSV_7 },
    { imag: CLA_CSV_8 },

];

const CarousalImage = () => (
    <Carousel
        className="slider-csv-upload"
        animation="slide"
       // autoPlay={false}
        IndicatorIcon={<img src={slider_img} alt="carousal csv tutorial" style={{ width: '100%' }} />}
        indicatorIconButtonProps={{
            className: 'sliderBtn',
            style: {
                padding: '10px',
                color: '#C4C4C4'
            }
        }}
        activeIndicatorIconButtonProps={{
            className: 'activeSlide',
            style: {
                backgroundColor: '#2F80ED',
                margin: '0 10px',
                padding: 0,
                // margin: 0
            }
        }}
        indicatorContainerProps={{
            style: {
                // marginTop: '50px',
                textAligh: 'right',
                borderRadius: '100px'
            }

        }}
    >
        {images && images.map(img => (
            <Paper className="slider-item-wrapper">
                {/* <div style={{
                    width: '100%',
                    backgroundImage: `url(${img.imag})`
                }} /> */}

                <img src={img.imag} alt="carousal csv tutorial" style={{ width: 'inherit', height: 'inherit', objectFit: 'contain' }} />
            </Paper>
        ))
        }
    </Carousel >
);

export default CarousalImage;
