/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-typos */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/core/styles";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
    maxWidth: window.screen.width === 768 ? "50%" : "100%"
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    maxWidth: window.screen.width === 768 ? "50%" : "100%"
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
    maxWidth: window.screen.width === 768 ? "50%" : "100%"
  },
  warning: {
    backgroundColor: amber[700],
    maxWidth: window.screen.width === 768 ? "50%" : "100%"
  },
  icon: {
    fontSize: window.screen.width < 365 ? 12 : 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center",
    fontSize: window.screen.width < 365 ? 12 : 14
  },
  close: {
    marginLeft: window.screen.width < 365 ? 0 : 5
  },
  root: {
    padding:
      window.screen.width < 550
        ? "2px 12px 2px 12px !important"
        : "4px 12px 4px 12px !important",
    flexWrap: "inherit !important"
  }
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className, classes.root)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

MySnackbarContent.PropTypes = {
  classes: PropTypes.node.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit
  }
});

class CustomizedSnackbars extends React.PureComponent {
  render() {
    const {
      classes,
      open,
      variant,
      message,
      onClose,
      onExited,
      forceOnExited
    } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={open}
        autoHideDuration={5000}
        onClose={onClose}
        onExited={
          variant !== "error" ? onExited : forceOnExited ? onExited : () => {}
        }
      >
        {variant !== "error" ? (
          <MySnackbarContentWrapper
            onClose={onClose}
            variant="success"
            message={message}
          />
        ) : (
          <MySnackbarContentWrapper
            variant="error"
            className={classes.margin}
            message={message}
            onClose={onClose}
          />
        )}
      </Snackbar>
    );
  }
}

CustomizedSnackbars.PropTypes = {
  classes: PropTypes.node.isRequired,
  variant: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  forceOnExited: PropTypes.func.isRequired
};

export default withStyles(styles2)(CustomizedSnackbars);
