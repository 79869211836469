import { SET_COUNTRIES } from '../actions/countriesActions';

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_COUNTRIES: {
      const { countries } = action;
      state = countries;
      localStorage.px_Countries = JSON.stringify(countries);
      return state;
    }
    default: return state;
  }
}
