import React from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import {
  Paper,
  TextField,
  IconButton,
  Divider,
  Button,
  Popover,
  withStyles,
  withWidth,
  InputAdornment,
  InputBase,
} from '@material-ui/core';
import { CalendarToday, Done } from '@material-ui/icons';
import {
  // getTodayEnd, 
  getDateInAYearDifference
} from '../../../../util/utility.functions';
import { SpaceBetween } from '../../../UI/ResponsiveRelated';
import BlueBtn from '../../../UI/BlueButton';
import { DateRangeStyles as styles } from '../../../../util/StyleConsts';

const moment = extendMoment(originalMoment);

class DateRangePick extends React.Component {
  constructor(props, context) {
    super(props, context);
    // const today = moment();
    const dateTo = moment(props.to);
    const dateFrom = moment(props.from);
    this.state = {
      anchorEl: null,
      filter: 1,
      // value: moment.range(today.format('YYYY-MM-DD'), today.format('YYYY-MM-DD')),
      value: moment.range(dateFrom.format('YYYY-MM-DD'), dateTo.format('YYYY-MM-DD')),
    };
  }

  componentDidMount() {
    this.handlePresets();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.from !== prevProps.from && this.props.to !== prevProps.to) {
      if (this.props.from === undefined && this.props.to === undefined) {
        this.clearDateFilter();
      }
    }
  }

  onSelect = (value, states, id) => {
    const today = moment();
    if (id === '1') {
      this.setState({
        filter: 1,
        value: moment.range(today.subtract(0, 'days').format('YYYY-MM-DD'), today.format('YYYY-MM-DD')),
      });
    } else if (id === '7') {
      let from = moment().subtract(6, 'days');
      this.setState({
        filter: 7,
        value: moment.range(from.format('YYYY-MM-DD'), today.clone().format('YYYY-MM-DD')),
      });
    } else if (id === '30') {
      let from = today.clone().subtract(29, 'days');
      this.setState({
        filter: 30,
        value: moment.range(from.format('YYYY-MM-DD'), today.clone().format('YYYY-MM-DD')),
      });
    } else {
      let daysDiff = value.end.diff(value.start, 'days');
      if (daysDiff !== 0) daysDiff += 1;
      let isToday = moment(value.end).format('dd') === moment().format('dd');
      this.setState({
        value,
        filter: (daysDiff === 0 && isToday) ? 1 : (daysDiff === 7 && isToday) ? 7 : (daysDiff === 30 && today) ? 30 : 'custom',
      });
    }
  };

  setFilter = (e) => this.setState({ filter: e });

  handlePresets() {
    const dateTo = moment(this.state.value.end);
    const dateFrom = moment(this.state.value.start);
    const today = moment();
    const fromSevendate = today.clone().subtract(6, 'days');
    const fromThirtyDate = today.clone().subtract(29, 'days');
    if (moment(dateFrom.format('YYYY-MM-DD')).isSame(moment(fromSevendate.format('YYYY-MM-DD'))) && moment(today.format('YYYY-MM-DD')).isSame(moment(dateTo.format('YYYY-MM-DD')))) {
      this.setFilter(7);
    } else if (moment(dateFrom.format('YYYY-MM-DD')).isSame(moment(fromThirtyDate.format('YYYY-MM-DD'))) && moment(today.format('YYYY-MM-DD')).isSame(moment(dateTo.format('YYYY-MM-DD')))) {
      this.setFilter(30);
    } else if (moment(dateFrom.format('YYYY-MM-DD')).isSame(moment(today.format('YYYY-MM-DD'))) && moment(today.format('YYYY-MM-DD')).isSame(moment(dateTo.format('YYYY-MM-DD')))) {
      this.setFilter(1);
    } else {
      this.setFilter('custom');
    }
  }

  handleClick = () => event => this.setState({ anchorEl: event.currentTarget });

  close = () => {
    this.setState({ anchorEl: null });
  }

  clearFilter = () => {
    this.onSelect(undefined, undefined, '30');
    this.close();
  };

  handleChange = event =>
    this.setState({ [event.target.name]: event.target.value });


  addTimeToRange = (range) => {
    const d1 = moment();
    const value = moment.range(range.start.format('YYYY-MM-DD'), range.end.format('YYYY-MM-DD'));
    value.start = moment(value.start).set({
      hour: d1.get('hour'),
      minute: d1.get('minute'),
      second: d1.get('second')
    });
    value.end = moment(value.end).set({
      hour: d1.get('hour'),
      minute: d1.get('minute'),
      second: d1.get('second')
    });
    this.props.applyFilter(value);
  }

  handleAway = () => {
    this.setState({ anchorEl: null, value: moment.range(moment(this.props.from).format('YYYY-MM-DD'), moment(this.props.to).format('YYYY-MM-DD')) }, () => this.handlePresets());
  }

  clearDateFilter = () => {
    const dateTo = moment(undefined);
    const dateFrom = moment(undefined);
    this.setState({
      value: moment.range(dateFrom.format('YYYY-MM-DD'), dateTo.format('YYYY-MM-DD')),
      filter: 1
    });
    this.props.resetFilter(this.state.value);
  }

  renderSelectionValue = () => {
    const { t } = this.props;
    return (
      <div className="border-on-large-screen">
        <Button
          color="default"
          className="date-range-list-item"
          onClick={() => this.onSelect(undefined, undefined, '1')}
        >
          <SpaceBetween className="width-100 font-size-12">
            <div className="">{t('main.filterHourly')}</div>
            {this.state.filter === 1 && <Done className="blue font-size-12 width-55pc" />}
          </SpaceBetween>
        </Button>
        <Divider />
        <Button
          color="default"
          className="date-range-list-item"
          onClick={() => this.onSelect(undefined, undefined, '7')}
        >
          <SpaceBetween className="width-100 font-size-12">
            <div className="">{t('package.last7Days')}</div>
            {this.state.filter === 7 && <Done className="blue font-size-12 width-55pc" />}
          </SpaceBetween>
        </Button>
        <Divider />
        <Button
          color="default"
          className="date-range-list-item"
          onClick={() => this.onSelect(undefined, undefined, '30')}
        >
          <SpaceBetween className="width-100 font-size-12">
            <div className="">{t('package.last30Days')}</div>
            {this.state.filter === 30 && <Done className="blue font-size-12 width-55pc" />}
          </SpaceBetween>
        </Button>
        <Divider />
        <Button className="width-100" color="default" disabled>
          <SpaceBetween className="width-100 font-size-12">
            <div className="black">{t('package.customRange')}</div>
            {this.state.filter === 'custom' && <Done className="blue font-size-12 width-55pc" />}
          </SpaceBetween>
        </Button>

        <Divider />
        <div className="classes.text">
          <TextField
            style={{ width: 88 }}
            id="outlined-name"
            label="From"
            inputProps={{ style: { height: 0, fontSize: 11 } }}
            value={this.state.value.start.format('MM/DD/YYYY')}
            onChange={this.state.value.start.format('MM/DD/YYYY')}
            margin="normal"
            variant="outlined"
            disabled
          />{' '}
          &nbsp;&nbsp; &nbsp;
          <TextField
            style={{ width: 88 }}
            id="outlined-name"
            label="To"
            inputProps={{ style: { height: 0, fontSize: 11 } }}
            value={this.state.value.end.format('MM/DD/YYYY')}
            onChange={this.state.value.end.format('MM/DD/YYYY')}
            margin="normal"
            variant="outlined"
            disabled
          />{' '}
        </div>
      </div>
    );
  };

  render() {
    const {
      t, classes, width, from, to, loading, memberFilter
    } = this.props;
    const { anchorEl } = this.state;
    let today = false;
    let date = moment(to).format('MMM D, YYYY');
    let fromdate = moment(from).format('MMM D, YYYY');
    let defaultDate = false;
    if (!from && !to) {
      defaultDate = true;
    } else if (fromdate === date && moment(to).get('date') === moment().get('date')) { // Without UTC date checking
      today = true;
    } else if (moment(from).format('MMM D, YYYY') === moment(to).format('MMM D, YYYY') && moment(to).get('date') === moment().get('date')) { // With UTC date checking 
      today = true;
    } else {
      fromdate = moment(from).format('MMM D, YYYY');
    }

    let displayDate = `${fromdate} - ${date}`;
    if (defaultDate && memberFilter) { // When no Default Date is there
      displayDate = '';
    } else if (today && memberFilter) { // When we have current date in member function
      displayDate = moment(from).format('MMM D, YYYY');
    } else if (today && !memberFilter) { // When we have current date and its other than member function
      displayDate = `${today ? '' : `${fromdate} - `}${date}`;
    } else if (!today && memberFilter) { // When dont have Today's Date and its member function
      displayDate = `${fromdate} - ${date}`;
    } else { // Other cases
      displayDate = `${fromdate} - ${date}`;
    }

    return (
      <>
        <InputBase
          disabled
          inputProps={{
            style: {
              width: today ? 110 : 218,
              padding: '3px 0 3px',
              marginRight: -10,
              //  marginBottom: memberFilter ? 5 : 0
            },
            'aria-label': 'naked'
          }}
          className={`${this.props.BackBtn ? 'btnMemberStyle' : ''}`}
          value={displayDate}
          endAdornment={
            <InputAdornment position="end">
              <IconButton disabled={loading} className={`padding-5px ${this.props.BackBtn ? 'btnBackground' : ''} `} onClick={this.handleClick('bottom-end')}>
                <CalendarToday className="fs-14px" />
              </IconButton>
            </InputAdornment>
          }
        />
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handleAway}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Paper className="padding-5px just-paper">
            <div
              style={
                width !== 'xs'
                  ? {
                    display: 'flex',
                    width: 510,
                    height: 280,
                  }
                  : {}
              }
            >
              <div style={{ width: width !== 'xs' && '50%' }}>
                {this.renderSelectionValue()}
                {width === 'xs' && <Divider />}
              </div>
              <div style={{ marginLeft: width === 'xs' ? -20 : 0 }}>
                <DateRangePicker
                  value={this.state.value}
                  onSelect={this.onSelect}
                  singleDateRange
                  className={classes.datePicker}
                  // onClick={this.handleClick('bottom-end')}
                  maximumDate={moment()}
                  minimumDate={getDateInAYearDifference()}
                />
              </div>
            </div>
            <Divider />
            <div
              style={{ display: 'flex', justifyContent: memberFilter ? 'flex-end' : 'space-between', alignItems: 'center' }}>
              <div />
              {memberFilter &&
                <div>
                  <BlueBtn
                    top={10}
                    bottom={10}
                    width={120}
                    right={10}
                    onClick={() => {
                      this.close();
                      this.clearDateFilter();
                      // const dateTo = moment(undefined);
                      // const dateFrom = moment(undefined);
                      // this.setState({
                      //   value: moment.range(dateFrom.format('YYYY-MM-DD'), dateTo.format('YYYY-MM-DD')),
                      //   filter: 1
                      // });
                      // resetFilter(this.state.value);
                    }}
                  >
                    {t('email.reset')}
                  </BlueBtn>
                </div>
              }
              <BlueBtn
                top={10}
                bottom={10}
                width={120}
                right={10}
                onClick={() => {
                  this.close();
                  this.addTimeToRange(this.state.value);
                }}
              >
                {t('package.apply')}
              </BlueBtn>
            </div>
          </Paper>
        </Popover>
      </>
    );
  }
}

DateRangePick.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.node.isRequired,
  applyFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  memberFilter: PropTypes.bool.isRequired,
  BackBtn: PropTypes.bool // For adding background class 

};

DateRangePick.defaultProps = {
  BackBtn: false,
};

export default withWidth()(withStyles(styles)(DateRangePick));

