export const SET_BUILDING = 'SET_BUILDING';
export const GET_BUILDING = 'GET_BUILDING';
export const REMOVE_BUILDING = 'REMOVE_BUILDING';

export const setBuilding = (building) => ({
  type: SET_BUILDING,
  payload: building,
});

export const getBuilding = () => ({
  type: GET_BUILDING,
});

export const removeBuilding = () => ({
  type: REMOVE_BUILDING,
});
