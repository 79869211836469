/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Dialog,
  Divider,
  Grid,
  IconButton,
  withMobileDialog,
} from '@material-ui/core';
import { AlignCenter, VerticallyCenter, Ripple } from '../../../UI/ResponsiveRelated';
import { Clear } from '@material-ui/icons';
import { Flex, SpaceBetween } from '../../../UI/ResponsiveRelated';
// import NoImage from '../../../../assets/images/no-image.jpg';
import PackageDetails from '../../Packages/components/PackageDetails';
import ActionItemPackageImage from '../../Packages/components/PackageImageSection/index';
import {
  getPackageDetail,
} from '../../../../server';
import HistoryItemSingle from '../../Packages/components/HistoryItemSingle';
import { PackagesIndexStyles as style } from '../../../../util/StyleConsts';

class PackageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      currentIndex: props.index,
      packageDetail: '',
      packageImage: '',
      signature: '',
      apiCallFlag: false,
      forwarded: false,
      activeItem: true,
      activeHistory: false,
      package_history: [],
    };
  }

  componentDidMount() {
    const {packageId} = this.props;
    this.getPackage(packageId);
  }

  componentWillUnmount() {
    this.setState({
      activeItem: true,
      activeHistory: false,
      forwarded: false,
    });
  }

  setPackageImage = (data) => {
    this.setState({
      packageImage: data,
    });
  };

  setSignature = (data) => {
    this.setState({
      signature: data,
    });
  };

  setPackageType = (type) => {
    this.setState({
      activity_type: type,
    });
  };

  setDetails = (data) => {
    this.setState({
      packageDetail: data,
    });
  };

  setApiCallFlag = (flag, callback) => {
    this.setState(
      {
        apiCallFlag: flag,
      },
      () => {
        callback();
      }
    );
  };

  getPackage = (pkgId) => {
    const { apiCallFlag } = this.state;
    if (!apiCallFlag) {
      this.setApiCallFlag(true, () => {});
      getPackageDetail(pkgId)
        .then((detail) => {
          this.setPackage(detail);
          this.setApiCallFlag(false, () => {});
        })
        .catch(() => {
          this.setPackageImage('noImage');
          this.setSignature('noSignature');
        });
    }
  };

  setPackage = async (detail) => {
    const { dynamicLabels } = this.props;
    let labelsData = [];
    let labelslocations = [];
    if (dynamicLabels) {
      if (dynamicLabels.label || (dynamicLabels[0] && dynamicLabels[0].label)) {
        dynamicLabels[0].label.map((eachLabel) => {
          labelsData.push(eachLabel);
          return eachLabel;
        });
      }
      if (
        dynamicLabels.location ||
        (dynamicLabels[0] && dynamicLabels[0].location)
      ) {
        dynamicLabels[0].location.map((eachLabel) => {
          labelsData.push(eachLabel);
          return eachLabel;
        });
      }
    }
    const defaultState = '---';
    // const labelsData = dynamicLabels ? dynamicLabels[0].label : [];
    await this.setState({
      package_history: detail.package_history,
      packageDetail: {
        images: detail.images,
        forwarded_detail: detail.forwarded_detail,
        package_history: detail.package_history,
        last_action_by: detail.last_action_by,
        package_image: detail.package_image || null,
        member_active: detail.is_active || false,
        activity_type: detail.activity_type,
        package_id: detail.package_id || defaultState,
        business_id: detail.business_id || defaultState,
        business_name: detail.business_name || defaultState,
        category: detail.category,
        checkout_by_member_id: detail.checkout_by_member_id || defaultState,
        checkout_by_name: detail.checkout_by_name || defaultState,
        created_at: detail.created_at || defaultState,
        created_by: detail.created_by || defaultState,
        delivered_by_name: detail.delivered_by_name || defaultState,
        delivered_by_user_id: detail.delivered_by_user_id || defaultState,
        json_final: detail.json_final || defaultState,
        package_discard_reason: detail.package_discard_reason || defaultState,
        package_note: detail.package_note || defaultState,
        package_picked_reason: detail.package_picked_reason || defaultState,
        reminders: detail.reminders || defaultState,
        scanned_by: detail.scanned_by || defaultState,
        scanned_by_name: detail.scanned_by_name || defaultState,
        status: detail.status || defaultState,
        type: detail.type || defaultState,
        updated_at: detail.updated_at || defaultState,
        updated_by: detail.updated_by || defaultState,
        member_id: detail.member_id || defaultState,
        member_name: detail.member_name || defaultState,
        member_name_scanout: detail.pickup_info
          ? detail.pickup_info.name || defaultState
          : defaultState,
        dynamicLabels: labelsData,
        dyanmicLabelsLocations: labelslocations,
        courier_name: detail.courier_name,
        tracking_number: detail.tracking_number,
        action_status: detail.action_status,
        package_labels: detail.package_labels,
        package_location: detail.package_location,
      },
    });
  };

  handleDialog = () => {
    this.props.history.goBack();
  }

  render() {
    const { fullScreen, t } = this.props;
    const {
      open,
      packageDetail,
      apiCallFlag
    } = this.state;
    return (
      <>
        <Dialog
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflowY: 'auto',
          }}
          on
          fullScreen={true}
          open={open}
          maxWidth="lg"
          fullWidth={true}
          scroll="body"

          PaperProps={{
            style: {
              width: window.screen.width < 1025 ? '100%' : '65%',
              overflowX: 'hidden',
              height: window.screen.width < 1025 ? '100%' : '95%',
            },
          }}
          TransitionProps={{
            style: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              overflowY: 'auto',
            },
          }}
        >
          {
            apiCallFlag ? <VerticallyCenter>
            <AlignCenter>
                <Ripple variant="pink" />
            </AlignCenter>
            </VerticallyCenter> : <>
            <SpaceBetween style={{ alignItems: 'center' }}>
            <div style={{ display: 'flex' }}>
              <div
                className={'package-heading'}
                onClick={() =>
                  this.setState({ activeItem: true, activeHistory: false })
                }
                style={{
                  borderBottom: this.state.activeItem
                    ? '5px solid black'
                    : 'none',
                }}
              >
                {t('package.title2')}
              </div>
              <div
                className={'package-heading'}
                onClick={() =>
                  this.setState({ activeHistory: true, activeItem: false })
                }
                style={{
                  borderBottom: this.state.activeHistory
                    ? '5px solid black'
                    : 'none',
                }}
              >
                {t('common.history')}
              </div>
            </div>
            <IconButton
              color="inherit"
              onClick={this.handleDialog}
              aria-label="Close"
              className="margin-right-10"
            >
              <Clear />
            </IconButton>
          </SpaceBetween>
          <Divider />

          {this.state.activeItem && packageDetail && (
            <Flex>
              <Grid container style={{ overflowY: 'auto', marginBottom: 20 }}>
                <Grid
                  item
                  lg={6}
                  sm={12}
                  xs={12}
                  md={4}
                  className="package-image-grid"
                >
                  <ActionItemPackageImage
                    {...this.props}
                    {...this.state}
                    packages={[packageDetail]}
                    currentIndex={0}
                    t={t}
                    style={style}
                  />
                </Grid>
                <Grid item lg={6} sm={12} md={8} xs={12}>
                  <PackageDetails
                    {...this.props}
                    t={t}
                    style={style}
                    packages={[packageDetail]}
                    details={packageDetail}
                    currentIndex={0}
                    forwarded={this.state.forwarded}
                    // activity_type={this.state.forwarded ? packageDetail.activity_type : activity_type === "" ? this.props.scanType : activity_type }
                    activity_type={packageDetail.activity_type}
                    getPackage={(id) => {
                      this.setState({ packageDetail: '' }, () => {
                        this.getPackage(id);
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Flex>
          )}

          {this.state.activeHistory &&
            this.state.package_history.map((item, index) => (
              <HistoryItemSingle key={index} data={item} t={t}/>
            ))} 
            </>
          }
        </Dialog>
      </>
    );
  }
}

PackageDialog.propTypes = {
  packageId: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  dynamicLabels: PropTypes.node.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  packages: PropTypes.object.isRequired,
  thumbnail: PropTypes.node.isRequired,
  id: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withMobileDialog()(withRouter(PackageDialog));
