/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import axios from 'axios';
import moment from 'moment';
import { server } from './server';

export function getMembers(
  limit,
  orderBy,
  sortType,
  paginationOffset,
  buildingId,
  businessId,
  onlyDesignated,
  voMember
) {
  const params = {
    pagination_limit: limit,
    order_by: orderBy,
    order_asc: sortType,
    include_business: true,
    include_profile_pictures: true,
    include_num_pending_packages: true,
    platform: 'dashboard', 
    membership: voMember ? "VO_MEMBER" : "MEMBER"
  };
  if (paginationOffset || paginationOffset !== 0) {
    params.pagination_offset = paginationOffset;
  }
  if (buildingId) params.building_id = buildingId;
  if (businessId) params.business_id = businessId;
  if (onlyDesignated) params.include_designated_members_only = true;
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/member`, { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function getMembersForDateFilter(
  limit,
  orderBy,
  sortType,
  paginationOffset,
  buildingId,
  from,
  to,
  businessId,
  onlyDesignated
) {
  const params = {
    pagination_limit: limit,
    order_by: orderBy,
    order_asc: sortType,
    include_business: true,
    include_profile_pictures: true,
    include_num_pending_packages: true,
    platform: 'dashboard',
    from_date: moment(from).utc().startOf('day').toISOString(),
    to_date: moment(to).utc().endOf('day').toISOString(),
  };
  if (paginationOffset || paginationOffset !== 0) {
    params.pagination_offset = paginationOffset;
  }
  if (buildingId) params.building_id = buildingId;
  if (businessId) params.business_id = businessId;
  if (onlyDesignated) params.include_designated_members_only = true;
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/member`, { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function getMember(id) {
  return new Promise((resolve, reject) => {
    const params = {
      include_notes: true,
      include_business: true,
      include_profile_picture: true,
      include_pending_packages: true,
    };
    axios
      .get(`${server}/entity/member/${id}`, { params })
      .then(response => resolve(response.data[0]))
      .catch(error => reject(error));
  });
}

export function addMember(member) {
  return new Promise((resolve, reject) => {
    const modifiedMember = {
      building_id: member.building,
      name: member.name,
      note: member.note,
      email: member.email.trim(),
      phone: member.phone,
      office_number: member.office,
      floor: member.floor,
      permanent_address: member.address,
      is_hot_desk: member.isHotdesk,
      alternate_name:
        member.alternateName === null || member.alternateName === ''
          ? undefined
          : member.alternateName,
      alternate_email:
        member.alternateEmail === null || member.alternateEmail === ''
          ? undefined
          : member.alternateEmail,
      alternate_phone:
        member.alternatePhone === undefined ||
          member.alternatePhone === null ||
          member.alternatePhone === ''
          ? undefined
          : member.alternatePhone,
    };

    if (member.business && member.business.length > 0) {
      modifiedMember.business_ids = member.business.map(bus => ({
        business_id: bus.business_id,
        is_designated: !!bus.is_designated,
        is_super_designated: !!bus.is_super_designated,
      }));
    }
    if (modifiedMember.phone === '' || !modifiedMember.phone) {
      delete modifiedMember.phone;
    }
    if (modifiedMember.office_number === '' || !modifiedMember.office_number) {
      delete modifiedMember.office_number;
    }
    if (modifiedMember.floor === '' || modifiedMember.floor) {
      delete modifiedMember.floor;
    }
    if (
      modifiedMember.permanent_address === '' ||
      !modifiedMember.permanent_address
    ) {
      delete modifiedMember.permanent_address;
    }
    if (!modifiedMember.alternate_name || !modifiedMember.alternate_name[0]) {
      delete modifiedMember.alternate_name;
    }
    if (!modifiedMember.alternate_email || !modifiedMember.alternate_email[0]) {
      delete modifiedMember.alternate_email;
    }
    if (!modifiedMember.alternate_phone || !modifiedMember.alternate_phone[0]) {
      delete modifiedMember.alternate_phone;
    }
    if (
      !modifiedMember.profile_picture ||
      modifiedMember.profile_picture === ''
    ) {
      delete modifiedMember.profile_picture;
    }
    if (!modifiedMember.alternate_email) {
      delete modifiedMember.alternate_email;
    }
    if (!modifiedMember.alternate_phone) {
      delete modifiedMember.alternate_phone;
    }

    axios
      .post(`${server}/entity/member`, modifiedMember)
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
}

export function updateMember(id, member) {
  return new Promise((resolve, reject) => {
    const modifiedMember = {
      building_id: member.building_id,
      name: member.name,
      email: member.email.trim(),
      phone: member.phone,
      office_number: member.office,
      floor: member.floor,
      position: member.position,
      is_hot_desk: false,
      permanent_address: member.perAddress,
      alternate_name:
        member.alternateName.length > 0 ? member.alternateName : undefined,
      alternate_email:
        member.alternateEmail.length > 0 ? member.alternateEmail : undefined,
      alternate_phone:
        member.alternatePhone.length > 0 ? member.alternatePhone : undefined,
      note: member.note,
    };

    if (member.business && member.business.length > 0) {
      modifiedMember.business_ids = member.business.map(bus => ({
        business_id: bus.business_id,
        is_designated: !!bus.is_designated,
        is_super_designated: !!bus.is_super_designated,
      }));
    } else {
      modifiedMember.business_ids = [];
    }
    if (modifiedMember.phone === '' || !modifiedMember.phone) {
      delete modifiedMember.phone;
    }
    if (modifiedMember.office_number === '' || !modifiedMember.office_number) {
      delete modifiedMember.office_number;
    }
    if (modifiedMember.floor === '' || modifiedMember.floor) {
      delete modifiedMember.floor;
    }
    if (
      modifiedMember.permanent_address === '' ||
      !modifiedMember.permanent_address
    ) {
      delete modifiedMember.permanent_address;
    }
    if (!modifiedMember.alternate_name || !modifiedMember.alternate_name[0]) {
      delete modifiedMember.alternate_name;
    }
    if (!modifiedMember.alternate_email || !modifiedMember.alternate_email[0]) {
      delete modifiedMember.alternate_email;
    }
    if (!modifiedMember.alternate_phone || !modifiedMember.alternate_phone[0]) {
      delete modifiedMember.alternate_phone;
    }
    if (
      !modifiedMember.profile_picture ||
      modifiedMember.profile_picture === ''
    ) {
      delete modifiedMember.profile_picture;
    }
    if (!modifiedMember.alternate_email) {
      delete modifiedMember.alternate_email;
    }
    if (!modifiedMember.note) delete modifiedMember.note;
    if (!modifiedMember.alternate_phone) {
      delete modifiedMember.alternate_phone;
    }

    axios
      .put(`${server}/entity/member/${id}`, modifiedMember)
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
}

export function deleteMember(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${server}/entity/member/${id}`)
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
}

export function getMemberPreferences(id) {
  return new Promise((resolve, reject) => {
    const params = {
      linked_id: id,
      linked_type: 'MEMBER',
    };
    axios
      .get(`${server}/settings/preference`, { params })
      .then(response => resolve(response.data[0]))
      .catch(error => reject(error));
  });
}

export function updateMemberPreferences(preference_json, id) {
  let modifiedPrefs = {
    linked_id: id,
    linked_type: 'MEMBER',
    preference_json,
  };
  return new Promise((resolve, reject) => {
    axios
      .put(`${server}/settings/preference`, modifiedPrefs)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}
