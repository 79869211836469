import { createMuiTheme } from '@material-ui/core';
import purple from '@material-ui/core/colors/purple';
import { blue } from '@material-ui/core/colors';

export const MembersIndexStyles = theme => ({
  paper: {
    top: '15%',
    right: '1%',
    position: 'absolute',
    width: 200,
    height: 130,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
    borderRadius: 2,
  },
  tabsIndicator: {
    backgroundColor: 'black',
  },
  menuitem: { height: 16 },
  itemtext: { fontSize: 15, color: '#757575' },
});

export const PackagesIndexStyles = () => ({
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#70bbfd',
    },
  },
});

export const DateRangeStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'left',
  },
  textfield: {
    margin: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'left',
    width: 12,
  },
  button: {
    display: 'inline-grid',
    margin: theme.spacing.unit,
    fontSize: 14,
    alignItems: 'left',
    marginRight: 5,
    color: 'black',
  },
  sideButton: {
    fontSize: 16,
    backgroundColor: 'transparent',
  },
  text: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonBottom: {
    padding: 10,
    fontWeight: 'bold',
  },
  input: {
    display: 'inherit',
    marginTop: 5,
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  datePicker: {
    margin: 0,
    height: '105%',
  },
  done: {
    color: '#027AFF',
    fontSize: 14,
    margin: 'auto',
  },
  filterList: {
    width: '100%',
    backgroundColor: 'transparent',
    fontSize: 12,
    textTransform: 'capitalize',
    color: '#000',
  },
  cssUnderline: {
    '&:after': {
      border: '#027AFF',
    },
  },
  cssFocused: {},
});

export const AddMemberStyles = () => ({
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#027AFF',
    },
  },

  cssText: {
    '&:after': {
      color: '#027AFF',
    },
  },
  cssFocused: {},
  cssLabel: {
    '&$cssFocused': {
      color: '#027AFF',
    },
  },
});

export const materialTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      textPrimary: { color: 'white' },
    },
    MuiInput: {
      input: {
        cursor: 'pointer !important',
        textAlign: 'left',
        fontSize: window.screen.width < 500 ? 12 : 14,
      },
      root: {
        '&:hover': {
          borderBottom: '0px solid transparent',
        },
      },
      underline: {
        '&:after': {
          borderBottom: '0px solid transparent',
        },
        '&:hover': {
          '&:before': {
            borderBottom: '0px solid transparent !important',
          },
        },
        '&:before': {
          borderBottom: '0px solid transparent',
        },
      },
    },

    MuiInputBase: {
      input: {
        fontSize: window.screen.width < 500 ? 12 : 14,
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#027AFF',
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: '#027AFF',
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#027AFF',
      },
      thumb: {
        borderColor: '#027AFF',
        backgroundColor: '#027AFF',
      },
      noPoint: {
        backgroundColor: '#027AFF',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        backgroundColor: '#027AFF',
      },
    },

    MuiPickersDay: {
      isSelected: { backgroundColor: '#027AFF' },
    },
  },
});

export const AdvanceFilterStyles = theme => ({
  root: {
    '&$checked': {
      color: '#027AFF',
    },
  },
  iconHover: {
    color: 'grey',
    '&:hover': {
      color: 'black',
    },
  },
  checked: {},
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  grid: {
    width: '60%',
    paddingLeft: 20,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const AdvanceMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const PackageLabels = [
  { id: 'return_to_sender_flag', name: 'Return To Sender' },
  { id: 'time_sensitive_flag', name: 'Time Sensitive' },
  { id: 'fragile_flag', name: 'Fragile' },
  { id: 'confidential_flag', name: 'Confidential' },
  { id: 'oversize_flag', name: 'Oversize' },
  { id: 'food_delivery_flag', name: 'Food Delivery' },
  { id: 'legal_document_flag', name: 'Legal Document' },
  { id: 'pay_stubs_flag', name: 'Pay Stubs' },
];

export const PackageLabelsExtended = [
  { id: 'top_shelf_flag', name: 'Top Shelf' },
  { id: 'shelf_a_flag', name: 'Shelf A' },
  { id: 'shelf_b_flag', name: 'Shelf B' },
  { id: 'shelf_c_flag', name: 'Shelf C' },
  { id: 'shelf_d_flag', name: 'Shelf D' },
  { id: 'shelf_f_flag', name: 'Shelf F' },
  { id: 'tub_flag', name: 'Tub' },
  { id: 'floor_flag', name: 'Floor' },
  { id: 'heavy_flag', name: 'Heavy' },
  { id: 'large_flag', name: 'Large' },
  { id: 'damaged_flag', name: 'Damaged' },
  { id: 'refrigerated_flag', name: 'Refrigerated' },
];


export const PrefsStyles = theme => ({
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#027AFF',
    },
  },

  cssText: {
    '&:after': {
      color: '#027AFF',
    },
  },
  cssFocused: {},
  cssLabel: {
    '&$cssFocused': {
      color: '#027AFF',
    },
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
});

export const MemberDetailStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: 0,
  },
  cssLabel: {
    '&$cssFocused': {
      color: purple[500],
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: purple[500],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: purple[500],
    },
  },
  notchedOutline: {},
  bootstrapRoot: {
    'label + &': {
      marginTop: theme.spacing.unit * 0,
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',

    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      fontSize: 12,
    },
  },
  bootstrapFormLabel: {
    fontSize: 14,
  },
  bootstrapPlaceholder: {
    fontSize: 14,
  },
});

export const MemberDetailTheme = createMuiTheme({
  root: {
    fontSize: 14,
  },
  MuiInputBase: {
    root: {
      fontSize: 14,
    },
    input: {
      fontSize: 14,
    },
  },
  palette: {
    primary: blue,
  },
  typography: { useNextVariants: true, fontSize: 14 },
});

export const BusinessDetailStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: 0,
  },
  cssLabel: {
    '&$cssFocused': {
      color: purple[500],
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: purple[500],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: purple[500],
    },
  },
  notchedOutline: {},
  bootstrapRoot: {
    'label + &': {
      marginTop: theme.spacing.unit * 0,
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',

    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  bootstrapFormLabel: {
    fontSize: 14,
  },
  bootstrapPlaceholder: {
    fontSize: 14,
  },
});

export const buildingStyles = theme => ({
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#027AFF',
    },
  },

  cssText: {
    '&:after': {
      color: '#027AFF',
    },
  },
  cssFocused: {},
  cssLabel: {
    '&$cssFocused': {
      color: '#027AFF',
    },
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
});
