/* eslint-disable */
/* eslint-disable object-curly-newline */
import React from "react";
import PropTypes from "prop-types";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Menu, Fade, IconButton } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import { AlignCenter, VerticallyCenter, Ripple } from '../../UI/ResponsiveRelated';
import { setTriggerUserID } from "../../../util/Triggers/function";
import { serializeURL } from "../../../util/application.utils";
import NotificationIcon from "../../../assets/bell.svg";
import BoxSvg from '../../../assets/images/box.svg';
import CachedIcon from '@material-ui/icons/Cached';
// import DawnImg from "../../../assets/dawn.jpg";
// import ButterflyImg from "../../../assets/butterfly.jpeg";
import { getNotifications } from '../../../server/notifications';
import { updateNotificationsAction, refreshNotificationsPopupAction, refreshNotificationsCenterAction } from '../../../redux/actions/notificationAction';
// import { setBuildings } from "../../../redux/actions/allBuildingsActions";
// import { setBuilding } from "../../../redux/actions/locationsFilterActions";
// import { setRole } from "../../../redux/actions/userRoleActions";

class NotificationsPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      anchorEl: null,
      loadingLogs: false,
      logs: [],
    };
  }

  componentDidMount() {
    this.getLogs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.notificationPrompt !== prevProps.notificationPrompt && this.props.notificationPrompt === true) {
      this.getLogs();
      this.props.updateNotificationsAction(false);
    }
    if (this.props.refreshCenter !== prevProps.refreshCenter && this.props.refreshCenter === true ) {
      this.getLogs();
      this.props.refreshNotificationsCenterAction(false);
    }
  }

  getLogs = () => {
    this.setState({
      loadingLogs: true
    }, () => {
      getNotifications({
        pagination_limit: 4,
        pagination_offset: 0,
        order_by: 'created_at',
        order_asc: false
      })
        .then(res => {
          this.setState({
            loadingLogs: false,
            logs: res.data
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({
            loadingLogs: false
          });
        });
    });
  }

  toggle = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  logout = async () => {
    const { signout } = this.props;
    await signout();
    setTriggerUserID(null);
    this.setState({
      redirect: true,
      anchorEl: null,
    });
  };

  redirectToDetail = (notificationId, packageId, checked) => {
    this.props.history.push({
      pathname: '/notification-center/package-detail/',
      search: serializeURL({
        notificationId,
        packageId,
        checked
      })
    });
    this.handleClose();
  }

  refreshNotificationLogs = () => {
    this.getLogs(); 
    this.props.refreshNotificationsPopupAction(true);
  }

  render() {
    const { anchorEl, redirect, logs, loadingLogs } = this.state;
    if (redirect) {
      const { history } = this.props;
      history.replace("/login");
    }

    const { t } = this.props;
    return (
      <div className="topbar__profile">
        <button
          className="topbar__notification"
          onClick={this.toggle}
          style={{ alignItems: "center", paddingRight: 0 }}
        >
          <img src={NotificationIcon} alt="" />
          {!loadingLogs && logs.length > 0 && parseInt(logs[0].unread_count) !== 0 && <div className="unread-notification-popup-number">{logs[0].unread_count > 9 ? "9+" : logs[0].unread_count}</div>}
        </button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              width: 320,
              borderRadius: 4,
              boxShadow:
                "rgba(0, 0, 0, 0) 0px 6px 7px -4px, rgba(0, 0, 0, 0) 0px 11px 15px 1px, rgba(0, 0, 0, 0.12) 0px 4px 20px 3px",
            },
          }}
          TransitionComponent={Fade}
          style={{ marginTop: 45, marginLeft: 12 }}
        >
          <div className="notification-popup-center-heading">
            {t('notifications.title')}
            <Tooltip title="Refresh">
              <IconButton
              className="icon-btn-refresh"
              onClick={() => !loadingLogs? this.refreshNotificationLogs() : {}}>
                <span className={!loadingLogs ? "refresh-notifications" : "refresh-notifications-circular"}><CachedIcon fontSize="small" /></span>
              </IconButton>
            </Tooltip>
          </div>
          {
            <ul className={!loadingLogs && logs.length === 0 ? "no-new-notification-popup" : "notification-popup-container"} >
              {
                loadingLogs ? <VerticallyCenter>
                  <AlignCenter>
                    <Ripple variant="pink" />
                  </AlignCenter>
                </VerticallyCenter> :
                  <>
                    {
                      logs.length > 0 ? logs.map((log, index) => (
                        <li
                          className="notification-popup-item-container"
                          onClick={() => this.redirectToDetail(log.app_notification_id, log.content.data.package_id, !log.is_read)}
                        >
                          <div className={`notification-center-unread-container ${log.is_read && 'old-notification-gap'}`}>
                            {!log.is_read && <div className="unread-notification">.</div>}
                          </div>
                          <div className="notification-popup-item-picture">
                            <img src={log.content.data.package_image || BoxSvg} alt="Notification snap" />
                          </div>
                          <div className="notification-popup-item-info">
                            <div>{moment(log.created_at).format('DD/MM/YYYY, hh:mm A')}</div>
                            <p>{log.content.notification.body}</p>
                          </div>
                        </li>
                      )) : <span>{t('notifications.noNew')}</span>
                    }
                    {
                      logs.length > 0 && logs[0].total_count > 4 && <Link className="notification-popup-view-all" to="/notification-center" onClick={this.handleClose} >{t('notifications.viewAll')}</Link>
                    }
                  </>
              }
            </ul>
          }
        </Menu>
      </div>
    );
  }
}

NotificationsPopup.propTypes = {
  t: PropTypes.func.isRequired,
  signout: PropTypes.func.isRequired,
  history: PropTypes.node.isRequired,
  // history: PropTypes.node.isRequired
  // dispatch: PropTypes.func.isRequired,
  // i18n: PropTypes.node.isRequired,
};

const mapDispatchToProps = dispatch => ({
  refreshNotificationsCenterAction: flag => dispatch(refreshNotificationsCenterAction(flag)),
  updateNotificationsAction: flag => dispatch(updateNotificationsAction(flag)),
  refreshNotificationsPopupAction: flag => dispatch(refreshNotificationsPopupAction(flag)),
});

const mapStateToProps = state => ({
  notificationPrompt: state.notificationPrompt.update,
  refreshCenter: state.notificationPrompt.refreshCenter,
});


export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NotificationsPopup);
