import React from 'react';
import PropTypes from 'prop-types';

const SpaceBetween = ({
    children, style, className, onClick,
  }) => (
  <div
    style={{
      alignItems: 'center' , display: 'flex', justifyContent: 'space-between', ...style,
    }}
    onClick={onClick}
    className={className}
    role="presentation"
  >
    {children}
  </div>
);

SpaceBetween.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.node,
  className: PropTypes.node,
  onClick: PropTypes.func,
};

SpaceBetween.defaultProps = {
  style: {},
  onClick: () => {},
  className: '',
};

export default SpaceBetween;
