
export const SET_LOADER = 'SET_LOADER';
export function setLoader(loaderType, value) {
    const obj = {
        loaderType,
        value
    };
    return { 
      type: SET_LOADER,
      loader: obj,
    };
  }
