import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import ComparePlans from './ComparePlans';
import PaymentSuccessful from './PaymentSuccessful';
import PaymentFailed from './PaymentFailed';

class PaymentModals extends React.Component {
    onCloseModal = () => {
        this.props.history.goBack();
    }
    getModal = () => {
        const { path } = this.props.match;
        const { t } = this.props;
        switch (path) {
            case '/manage-plan':
                return <ComparePlans {...this.props} open onClose={this.onCloseModal} t={t} />;
            case '/payment-successful':
                return <PaymentSuccessful {...this.props} open />;
            case '/payment-failed':
                return <PaymentFailed {...this.props} open />;
            default:
                return <Redirect replace to="/dashboard" />;
        }
    }
  render() {
      if (this.props.userRole && this.props.userRole !== 'admin') return this.props.history.goBack();
      return <>{this.getModal()}</>;
  }
}

const mapStateToProps = state => ({
    userRole: state.userRole,
  });
  
  export default connect(mapStateToProps, null)(withRouter(PaymentModals));
