/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactHighcharts from 'react-highcharts';
import { withWidth, Paper, Grid } from '@material-ui/core';
import {
  AlignCenter,
  Ripple,
  VerticallyCenter,
} from '../../../UI/ResponsiveRelated';

class HeldPackages extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.sidebar.collapse !== nextProps.sidebar.collapse) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    const {
      t,
      style,
      charts,
      heldPkgLoading,
      heldPkgs,
      heldPkgError,
      width,
    } = this.props;
    let total = 0;
    if (!heldPkgLoading) {
      total =
        parseInt(heldPkgs['1_3'], 10) +
        parseInt(heldPkgs['3_10'], 10) +
        parseInt(heldPkgs['11'], 10);
    }

    let config = {
      chart: {
        type: 'pie',
        width: window.screen.width < 770 ? 150 : 200,
        height: window.screen.width < 770 ? 150 : 170,
        marginTop: 0,
      },
      title: {
        text: null,
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          colors: ['#02B67C', '#FFB02F', '#FF1E59'],
          borderWidth: null,
          backgroundColor: null,
        },
      },
      tooltip: {
        textTransform: 'capitalize',
        valueSuffix: '',
        backgroundColor: 'rgba(97,97,97,0.9)',
        borderWidth: 0,
        borderRadius: 0,
        headerFormat: '',
        formatter() {
          return `<b> ${this.point.name}</b>: <b> ${(
            (parseInt(this.y, 10) / total) *
            100
          ).toFixed(2)}%</b>`;
        },
        shadow: {
          color: "rgba(0,0,0,0.1)",
          offsetX: 0,
          offsetY: 5,
          width: 10,
        },
        style: { color: 'white', textTransform: 'capitalize' },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: 'pie',
          name: 'Held Packages',
          data: [
            [t('main.last3Days'), parseInt(heldPkgs['1_3'], 10)],
            [t('main.last3To5Days'), parseInt(heldPkgs['3_10'], 10)],
            [t('main.over10Days'), parseInt(heldPkgs['11'], 10)],
          ],
          backgroundColor: ['#02B67C', '#FFB02F', '#FF1E59'],
          hoverBackgroundColor: ['#02B67C', '#FFB02F', '#FF1E59'],
          size: '100%',
          innerSize: '40%',
          states: {
            hover: {
              enabled: false,
            },
          },
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
          borderColor: '#ffffff',
          borderWidth: 1,
        },
      ],
    };
    const data = {
      labels: [t('main.last3Days'), t('main.last3To5Days'), t('main.over10Days')],
      datasets: [
        {
          data: [
            parseInt(heldPkgs["1_3"], 10),
            parseInt(heldPkgs["3_10"], 10),
            parseInt(heldPkgs["11"], 10),
          ],
          backgroundColor: ["#02B67C", "#FFB02F", "#FF1E59"],
          hoverBackgroundColor: ["#02B67C", "#FFB02F", "#FF1E59"],
        },
      ],
    };
    let message = null;
    if (
      heldPkgs["1_3"] === "0" &&
      heldPkgs["3_10"] === "0" &&
      heldPkgs["11"] === "0"
    ) {
      message = t('common.noData');
    }

    return (
      <Paper
        style={{
          paddingLeft: 17,
          paddingRight: 17,
          height: width === "xs" && !charts ? 350 : 220,
          paddingTop: 26,
        }}
        className="just-paper"
      >
        <div className="space-between-with-block">
          <div style={style.common.subheading}>
            {' '}
            {t('package.currentlyHeldPkgs')}
          </div>
        </div>
        {(heldPkgError || (charts && message)) && (
          <VerticallyCenter className="height-80p">
            <AlignCenter>{heldPkgError || message}</AlignCenter>
          </VerticallyCenter>
        )}
        {!charts && !heldPkgError && (
          <div
            style={{
              ...style.flex.jc_c,
              ...style.flex.display,
              ...style.common.mt_16,
            }}
          >
            <div className="currently-held">
              <div className="flex-column-center">
                <div className="scan-detail-label">{data.labels[0]}</div>
                <Link
                  to="/packages/currently_held/0_3/outstanding"
                  className="scan-detail-counts green"
                >
                  {heldPkgLoading && (
                    <div className="spinner-grow text-success" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  {!heldPkgLoading ? (
                    !heldPkgError &&
                    Intl.NumberFormat('en').format(heldPkgs['1_3'])
                  ) : (
                      <React.Fragment />
                    )}
                </Link>
              </div>
              <div className="flex-column-center">
                <div className="scan-detail-label">{data.labels[1]}</div>
                <Link
                  to="/packages/currently_held/3_10/outstanding"
                  className="scan-detail-counts yellow"
                >
                  {heldPkgLoading && (
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  {!heldPkgLoading &&
                    !heldPkgError &&
                    Intl.NumberFormat('en').format(heldPkgs['3_10'])}
                </Link>
              </div>
              <div className="flex-column-center">
                <div className="scan-detail-label">{data.labels[2]}</div>
                <Link
                  to="/packages/currently_held/10/overdue"
                  className="scan-detail-counts red"
                >
                  {heldPkgLoading && (
                    <div className="spinner-grow text-danger" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  {!heldPkgLoading &&
                    !heldPkgError &&
                    Intl.NumberFormat('en').format(heldPkgs['11'])}
                </Link>
              </div>
            </div>
          </div>
        )}
        {charts && !heldPkgError && !message && (
          <Grid container>
            <Grid item xs={6} sm={6} md={6}>
              <div className="currently-held-side-labels">
                <div style={style.common.dotGreen} />
                <div>{data.labels[0]}</div>
              </div>
              <div className="currently-held-side-labels">
                <div style={style.common.dotYellow} />
                <div>{data.labels[1]}</div>
              </div>
              <div className="currently-held-side-labels">
                <div style={style.common.dotRed} />
                <div>{data.labels[2]}</div>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={6} className="margin-top-m20px">
              {heldPkgLoading ? (
                <Ripple variant="pink" />
              ) : (
                  <div className="float-right">
                    <ReactHighcharts config={config} />
                  </div>
                )}
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  }
}

HeldPackages.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
  charts: PropTypes.bool.isRequired,
  heldPkgLoading: PropTypes.bool.isRequired,
  heldPkgs: PropTypes.node.isRequired,
  heldPkgError: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};
export default connect(state => ({
  sidebar: state.sidebar,
}))(withWidth()(HeldPackages));
