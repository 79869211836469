import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  withWidth,
  MobileStepper,
} from '@material-ui/core';
import { AlignCenter, VerticallyCenterLogin } from '../../../UI/ResponsiveRelated';
import slide1 from '../../../../assets/login/1.png';
import slide2 from '../../../../assets/login/2.png';
import slide3 from '../../../../assets/login/3.png';
import bg from '../../../../assets/login/bg.svg';

let ua = window.navigator.userAgent;
let msie = ua.indexOf('MSIE ');

class Index extends Component {
  static propTypes = {
    width: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
    };
  }

  componentDidMount() {
    setInterval(() => {
      const { activeStep } = this.state;
      this.setState({ activeStep: activeStep === 2 ? 0 : (activeStep + 1) });
    }, 2000);
  }

  render() {
    const {
      activeStep,
    } = this.state;
    const { width } = this.props;
    const paperClasses = 'border-radius-0 padding-3246 height-width-p background-pink';
    return (
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        {width !== 'sm' && width !== 'xs' &&
          <div style={{ backgroundImage: `url(${bg})`, backgroundSize: '100%' }} className={paperClasses}>
            <div className={`height-95p ${msie < 1 && 'align-center'}`}>
              <VerticallyCenterLogin>
                <div>
                  <AlignCenter>
                    <img className="width-65p height-100p opacity-2" src={activeStep === 0 ? slide1 : activeStep === 1 ? slide2 : slide3} alt="slider" />
                  </AlignCenter>
                  <br />
                  <div className="login-text-img white font-weight-bolder font-size-18 opacity-2">
                    {activeStep === 0 && "What's New - More Visibility"}
                    {activeStep === 1 && "What's New - Advance Search"}
                    {activeStep === 2 && "What's New - CSV Upload"}
                  </div>
                  <div className="login-text-img white font-size-14  opacity-2">
                    {activeStep === 0 && 'Dashboard is now equipped with more intuitive graphs.'}
                    {activeStep === 1 && "You can now search more efficiently by sender/recipient's name."}
                    {activeStep === 2 && 'Now you can upload members via CSV.'}
                  </div>
                </div>
              </VerticallyCenterLogin>
            </div>
            <div className="steppers opacity-2">
              <AlignCenter>
                <MobileStepper
                  variant="dots"
                  steps={3}
                  position="static"
                  activeStep={activeStep}
                  className="mobile-stepper-color"
                  dotActive={{ style: { backgroundColor: '#fff !important' } }}
                />
              </AlignCenter>
            </div>
          </div>
        }
      </Grid>
    );
  }
}

export default withWidth()(Index);
