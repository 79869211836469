import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from './Styled';
import { contactSalesTeam } from '../../../server/payment';
import ContactSalesResponse from './ContactSalesResponse';

function PaymentError(props) {
    const [openContactSalesResponseModal, setOpenContactSalesResponseModal] = useState(false);
    const [loading, setLoading] = useState(false);
  const { userRole, open } = props;
  let message = '';
  if (userRole === 'admin') {
    message =
      'Uh Oh! it looks like we could not process your plan renewal fee, please get in touch with our sales team so you can keep enjoying the Mailroom by PackageX experience without interruptions!';
  } else {
    message =
      'Uh Oh! it looks like we could not process your plan renewal fee, please get in touch with your admin so they can contact sales for an uninterrupted Mailroom by PackageX experience.';
  }
  const closeModal = () => {
    props.onClose(null);
  };
  const contactSales = () => {
    const { buildings } = props;
    if (
      buildings &&
      buildings.length > 0 &&
      buildings[0].tier &&
      buildings[0].tenant_name
    ) {
      if (loading) return;
      setLoading(true);
      const { tenant_name } = buildings[0];
      contactSalesTeam(tenant_name)
    .then(() => {
                setOpenContactSalesResponseModal(true);
            }).catch((err) => {
              console.log(err);
            }).finally(() => {
                setLoading(false);
            });
    }
    };
    const closeContactSalesResponseModal = () => {
      setOpenContactSalesResponseModal(false);
      closeModal();
      props.history.push('/dashboard');
    };

  return (
    <div>
      <Dialog keepMounted maxWidth="sm" onClose={closeModal} open={open}>
        <DialogContent className="pm-modal-container">
          <h3 className="pm-heading">
            Payment {' '}
            <span className="pm-heading-red">
              Error
            </span>
          </h3>
          <p className="pm-description">
            {message}
          </p>
          <div className="pm-single-button-section">
            {userRole === 'admin' ? <Button
              className="pm-cp-upgrade-btn"
              onClick={() => {
                console.log('contact sales');
                contactSales();
              }}
            > Contact Sales {loading && <CircularProgress size={15} className="white" />}
                                    </Button>
            : <Button className="pm-cp-upgrade-btn" onClick={() => closeModal()} > Okay </Button>}
          </div>
          <ContactSalesResponse
            open={openContactSalesResponseModal}
            onClose={closeContactSalesResponseModal}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

PaymentError.propTypes = {
    // t: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    userRole: PropTypes.string.isRequired,
    buildings: PropTypes.array.isRequired,
    history: PropTypes.node.isRequired,
};

export default withRouter(PaymentError);
