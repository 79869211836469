/* eslint-disable quotes */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import ReactHighcharts from "react-highcharts";
import { Paper, withWidth } from "@material-ui/core";
import DateRange from "./DateRange";
import { Ripple } from "../../../UI/ResponsiveRelated";

ReactHighcharts.Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const BuildingBarChart = props => {
  const {
    t, data, loading, scanCount, error, top, sidebar, width,
  } = props;
  let allZero = scanCount === 0;
    const winWidth = window.innerWidth;
    let green = {
      linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
      },
      stops: [[0, "#02B67C"], [1, "#02B67C"]],
    };
    let red = {
      linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
      },
      stops: [[0, "#FF1E59"], [1, "#FF1E59"]],
    };
    const color = top ? green : red;
    let graphData = [];
    let highestGreenCount = 0;
    let highestRedCount = 0;
    for (let i = 0; i < data.length; i++) {
      if (data.length > 0) {
        if (top) {
          highestGreenCount = Math.max(
            highestGreenCount,
            parseInt(data[i].scans_count, 10),
          );
        } else {
          highestRedCount = Math.max(
            highestRedCount,
            parseInt(data[i].scans_count, 10),
          );
        }

        graphData.push({
          name: data[i].name,
          y: parseFloat(data[i].scans_count, 10),
          color,
          country: data[i].country,
        });
      }
    }
    let config = {
      chart: {
        height: width === 'xs' ? 370 : 400,
        type: "column",
        style:
          !sidebar.collapse || width === "xl"
            ? { marginLeft: -8, marginRight: -8 }
            : { marginLeft: -8, marginRight: -8 },
      },
      title: { text: "" },
      xAxis: {
        type: "category",
        lineWidth: 0,
        labels: {
          rotation: 0,

          style: {
            fontSize: "14px",
            fontFamily: "Muli",
            color: " #000",
            fontWeight: 400,
          },
        },
      },
      yAxis: {
        allowDecimals: false,
        max: top
          ? highestGreenCount > 5
            ? highestGreenCount
            : 5
          : !top
            ? highestRedCount > 5
              ? highestRedCount
              : 5
            : 0,
        min: 0,
        visible: !allZero,
        gridLineDashStyle: "longdash",
        title: {
          text: "",
          fontFamily: "Muli !important",
        },
        labels: {
          rotation: 0,
          style: {
            fontSize: "14px",
            fontFamily: "Muli",
            color: " #000",
            fontWeight: 400,
          },
        },
      },
      legend: { enabled: false },
      tooltip: {
        headerFormat: "",
        pointFormat:
          '<b style="margin-right: 15px; width: 50px">{point.y:,.0f}</b><br/><span style="color:#AEBBC9; margin-right: 15px; width: 50px"">{point.country}</span>',
        borderColor: "#E6E6E6",
        backgroundColor: "rgba(97,97,97,0.9)",
        useHTML: true,
        style: { textAlign: "center", color: "white", borderRadius: 1 },
      },
      series: [
        {
          name: "",
          data: graphData,
        },
      ],
      credits: false,
    };

    if (sidebar.collapse === false) {
      if (winWidth <= 320) {
        config.chart.width = 250;
      } else if (winWidth <= 360) {
        config.chart.width = 300;
      } else if (winWidth <= 410) {
        config.chart.width = 310;
      } else if (winWidth <= 520) {
        config.chart.width = 340;
      } else if (winWidth <= 620) {
        config.chart.width = 300;
      } else if (winWidth <= 720) {
        config.chart.width = 320;
      } else if (winWidth <= 800) {
        config.chart.width = 380;
      } else if (winWidth <= 850) {
        config.chart.width = 470;
      } else if (winWidth <= 900) {
        config.chart.width = 550;
      } else if (winWidth <= 959) {
        config.chart.width = 590;
      } else if (winWidth <= 1000) {
        config.chart.width = 300;
      } else if (winWidth <= 1200) {
        config.chart.width = 350;
      } else if (winWidth <= 1250) {
        config.chart.width = 390;
      } else if (winWidth <= 1440) {
        config.chart.width = 530;
      }// else config.chart.width = (winWidth / 3) * 1.12;
    } else if (sidebar.collapse === true) {
      if (winWidth <= 320) {
        config.chart.width = 250;
      } else if (winWidth <= 360) {
        config.chart.width = 300;
      } else if (winWidth <= 415) {
        config.chart.width = 340;
      } else if (winWidth <= 520) {
        config.chart.width = 430;
      } else if (winWidth <= 650) {
        config.chart.width = 485;
      } else if (winWidth <= 750) {
        config.chart.width = 535;
      } else if (winWidth <= 850) {
        config.chart.width = 600;
      } else if (winWidth <= 959) {
        config.chart.width = 700;
      } else if (winWidth <= 980) {
        config.chart.width = 385;
      } else if (winWidth <= 1099) {
        config.chart.width = 420;
      } else if (winWidth <= 1440) {
        config.chart.width = 550;
      }// else config.chart.width = (winWidth / 3) * 1.3;
    }

    return (
      <Paper className="building-cart-paper just-paper">
        <div className="space-between-with-block">
          <p
            className={`top-building-heading truncated-heading black ${
              width === "sm"
                ? !sidebar.collapse
                ? "width-48px"
                : "width-135"
                : width === "xs"
                ? ""
                : !sidebar.collapse && width === "md" && "width-165"
              }`}
          >
            {top && window.screen.width < 770
              ? t("common.topScans")
              : top && window.screen.width > 770
                ? t("common.topScanning")
                : !top && window.screen.width < 770
                  ? t("common.leastScans")
                  : t("common.leastScanning")}
          </p>
          <DateRange {...props} />
        </div>
        {loading ? <Ripple variant={top ? 'green' : 'red'} />
          : allZero ? <div className="buildings-zero-message align-items-center height-100p">
            <div>{t("common.0Scans")}</div>
          </div>
            : error.length > 0 ? <div className="buildings-zero-message align-items-center height-100p">
              <div>{error}</div>
            </div>
              : <ReactHighcharts config={config} />}
      </Paper>
    );
};

BuildingBarChart.propTypes = {
  top: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  sidebar: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
};

export default (withWidth()(BuildingBarChart));
