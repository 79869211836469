import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import { PermIdentity } from '@material-ui/icons';
import ChangePassword from '../UserAccountSettings/components/accountSettings';
import CollapsableBuildingList from './component/CollapsableBuildingList';
import usericon from '../../../assets/images/user-icon.png';
import Footer from '../../UI/Footer/Footer';

function Profile(props) {
  const { userDetail, t } = props;
  let name = '';
  let email = '';
  if (userDetail.UserAttributes) {
   userDetail.UserAttributes.filter(key => {
      if (key.Name === 'name') name = key.Value;
      else if (key.Name === 'email') email = key.Value;
      return key;
    });
  }

  return (
    <>
      <Grid container className="dashboard container">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h3 className="page-title margin-right-10">Profile </h3>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper className="just-paper padding-20px margin-bottom-15">
            <Grid container>
              <Grid item xs={12} sm={3} md={2} lg={1} className="height-width-100px align-center">
                <PermIdentity id="placeholder-user-icon" className="profile-icon z-index-1 height-width-100px" />
                {userDetail.user_image_link &&
                <img
                  src={userDetail.user_image_link}
                  className="height-width-100px border-radius-50px z-index-2 just-paper"
                  id="prof-img"
                  onLoad={() => {
                    let elem = document.getElementById('placeholder-user-icon');
                    elem.style.display = 'none';
                  }}
                  onError={() => {
                    let elem = document.getElementById('prof-img');
                    elem.style.display = 'none';
                    elem.src = usericon;
                  }}
                  alt=""
                />}
              </Grid>
              <Grid item xs={12} sm={9} md={10} lg={11} className="justify-center">
                <div>
                  <p className="page-subhead subhead small-screen-center margin-0px">{name}</p>
                  <p className="subhead small-screen-center margin-0px">{email}</p>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <ChangePassword t={t} />
        <CollapsableBuildingList buildings={userDetail.buildings || []} t={t} />
      </Grid>
      <Footer />
    </>
  );
}

Profile.propTypes = {
  userDetail: propTypes.object.isRequired,
  t: propTypes.func.isRequired,
};

export default connect(state => ({ userDetail: state.userDetails }))(Profile);
