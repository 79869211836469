/* eslint-disable */

import React from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../../UI/BlueButton';
import Alert from '../../../UI/Poper';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import AccountDeletion from '../../PaymentModals/AccountDeletion';
import { CustomInputLabel, CustomInput } from '../../../UI/Input';
import { deleteUser } from '../../../../server/payment';
import { signout } from '../../../../server/auth';

class DeleteConfirmationPopup extends React.Component {
  state = {
    showNewPass: false,
    loading: false,
    deleteAccount: false,
    deleteAccountLoader: false,
    password: '',
    showError: false,
    message: null,
  };

  // Form Fields
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // Hide and Un-hide Password
  showPassword = () => {
    this.setState((prev) => {
      return {
        showNewPass: !prev.showNewPass,
      };
    });
  };

  hasDigits = (string) => /\d/.test(string);
  hasUppercase = (string) => /[A-Z]/.test(string);
  hasLowercase = (string) => /[a-z]/.test(string);
  hasEightLength = (string) => (string.length >= 8 ? true : false);
  hasSpecialCharacter = (string) =>
    /.*[/!`~_;'+=@#$%^&*()\[\]\\,.?":{}|<>-]/.test(string);

  validatePass = (newPassword) => {
    return (
      this.hasDigits(newPassword) &&
      this.hasSpecialCharacter(newPassword) &&
      this.hasUppercase(newPassword) &&
      this.hasLowercase(newPassword) &&
      this.hasEightLength(newPassword)
    );
  };

  resetFields = () => {
    this.setState({ password: '' });
  };

  deleteAccount = () => {
    const { password } = this.state;
    // this.setState({ openConfirmDelete: true });
    const setLoadingFalse = () => this.setState({ loading: false });
    this.setState(
      {
        loading: true,
      },
      () => {
        Auth.signIn(localStorage.getItem('px_user'), password)
          .then((user) => {
            const { buildings } = this.props;
            deleteUser(buildings[0].tenant_id)
              .then((res) => {
                this.setState(
                  {
                    deleteAccount: true,
                  },
                  () => {
                    setLoadingFalse();
                  }
                );
              })
              .catch((err) => {
                console.log(err);
                this.setState({
                  showError: true,
                  message: this.props.t('common.error.something'),
                });
              });
          })
          .catch((err) => {
            // console.log(err);
            this.setState({
              showError: true,
              message: this.props.t('common.error.incorrect'),
            });
            setLoadingFalse();
          });
      }
    );
  };

  closeDeletionModal = () => {
    this.setState({
      deleteAccount: false
    }, () => {
      this.resetFields();
      this.props.onClose();
    });
    // this.setState(
    //   {
    //     deleteAccountLoader: true,
    //   },
    //   () => {
    //     new Promise((resolve, reject) => {
    //       signout();
    //       resolve('success');
    //     })
    //       .then((res) => {
    //         this.props.history.replace('/');
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }
    // );
  };

  render() {
    const { t, buildings } = this.props;
    const {
      showNewPass,
      password,
      loading,
      deleteAccount,
      deleteAccountLoader,
    } = this.state;
    return (
      <>
        {this.state.showError && (
          <Alert
            variant="error"
            open
            message={this.state.message}
            onClose={() => this.setState({ showError: false })}
          />
        )}
        <Dialog
          {...this.props}
          onClose={() => {
            this.resetFields();
            this.props.onClose();
          }}
          maxWidth="sm"
          fullWidth
        >
          <div className="account-settings-card-header">
            {t('deleteAccount.deleteAccount')}
          </div>
          <div style={{ padding: '30px' }}>
            <div>{t('deletePopupText')}</div>
            <div style={{ marginBottom: '3rem' }}>
              <FormControl fullWidth>
                <CustomInputLabel htmlFor="adornment-password">
                  {t('common.password')}
                </CustomInputLabel>
                <CustomInput
                  type={showNewPass ? 'text' : 'password'}
                  value={password}
                  name="password"
                  onChange={this.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => this.showPassword()}>
                        {showNewPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!this.validatePass(this.state.password) && (
                  <FormHelperText className="red">
                    {t('passwordValidation')}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="popup-buttons-container">
              {/* Delete Button */}
              <div className="popup-delete-button">
                <Button
                  width={145}
                  height={45}
                  color="#ff1f5a"
                  disabled={
                    this.state.loading ||
                    this.state.password.length === 0 ||
                    !this.validatePass(this.state.password)
                  }
                  loading={this.state.loading}
                  onClick={() => this.deleteAccount()}
                >
                  {t('deletePopupDelete')}{' '}
                  {/* {loading && (
                    <span className="ml-2">
                      <CircularProgress size={15} className="white" />
                    </span>
                  )} */}
                </Button>
              </div>
              {/* Cancel Button */}
              <div>
                <Button
                  width={145}
                  height={45}
                  color="grey"
                  onClick={() => {
                    this.resetFields();
                    this.props.onClose();
                  }}
                >
                  {t('email.cancel')}
                </Button>
              </div>
            </div>
          </div>
          <AccountDeletion
            deleteAccountLoader={deleteAccountLoader}
            open={deleteAccount}
            onClose={() => this.closeDeletionModal()}
            t={t}
          />
        </Dialog>
      </>
    );
  }
}
export default connect((state) => ({
  buildings: state.allBuildings,
}))(DeleteConfirmationPopup);
