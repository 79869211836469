import { SET_ROLE } from '../actions/userRoleActions';

const initialState = null;

export default function (role = initialState, action) {
  switch (action.type) {
    case SET_ROLE: {
      role = action.role;
      return role;
    }
    default: return role;
  }
}
