import { DYNAMIC_LABELS } from '../actions/dynamicLabelsActions';

let initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case DYNAMIC_LABELS: {
        const { dynamicLabelsData } = action;
        state = [dynamicLabelsData];
      return state;
    }
    default: return state;
  }
}
