/* eslint-disable quotes */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import {
  AddCircleOutline,
  CancelOutlined,
  Search,
  Person,
} from '@material-ui/icons';
import {
  CircularProgress,
  Dialog,
  Divider,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Paper,
  Grid,
  FormHelperText,
  withWidth,
  TextField,
  Button,
  Checkbox,
  Badge,
} from '@material-ui/core';
import {
  withStyles,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import {
  AlignCenter,
  Flex,
  SpaceBetween,
  VerticallyCenter,
  Margin,
  Truncate,
} from '../../../UI/ResponsiveRelated';
import Switch from '../../../UI/Switch';
import { CustomTextField, CustomPhoneNumInput } from '../../../UI/Input';
import { MembersIndexStyles as styles, MemberDetailTheme as theme } from '../../../../util/StyleConsts';

class MemberDetail extends Component {
  state = { open: false, openConfirmation: false };
  openDialog = () => this.setState({ open: !this.state.open });

  popUp = () => this.setState({ openConfirmation: true });

  closepopUp = () => this.setState({ openConfirmation: false });

  cancel = () => {
    this.props.emptyBusinesses();
    this.openDialog();
  };

  render() {
    const {
      t,
      loading,
      error,
      startEdit,
      editing,
      handleChange,
      name,
      email,
      phone,
      phoneError,
      perAddress,
      status,
      alternateName,
      alternateEmail,
      alternatePhone,
      altPhoneError,
      emailError,
      nameError,
      addressError,
      officeError,
      address,
      office,
      note,
      businesses,
      business,
      handlePhone,
      search,
      handleSearch,
      loadingBus,
      selectBusiness,
      setAlternativeName,
      addInputField,
      removeInputField,
      setAltPhone,
      validateEmail,
      validateName,
      deactivateMember,
      assignDesignated,
      // assignSuperDesignated,
      width,
      profilePicture,
      businessError,
      classes,
      formatPhoneNumber,
      isVoMember
    } = this.props;
    const inputStyle = { fontSize: 14, color: '#000' };
    const labelStyle = { fontSize: 14 };
    const addIconStyle = {
      height: 14,
      width: 14,
      color: '#16A085',
      cursor: 'pointer',
    };
    const cancelIconStyle = {
      height: 14,
      width: 14,
      color: '#E74C3C',
      cursor: 'pointer',
    };
    const statusLabel = {
      fontSize: 12,
      color: '#000',
      alignItems: 'center',
      display: 'flex',
    };
    const addressValue =
      address.length > 40
        ? width === 'xs'
          ? address.substr(0, 40).concat('...')
          : address.substr(0, 100).concat('...')
        : address;
    const checkboxStyle = { padding: 0, color: 'rgb(2, 122, 255)' };
    const padding = { padding: 0 };
    const paddingRightLeft = {
      paddingTop: 0,
      paddingBottom: 0,
    };

    const displayCenter = { display: 'flex', justifyContent: 'center' };
    const selectedIds = business.map(bus => bus.business_id);
    return (
      <Grid container>

        {loading ?
          <Grid
            item
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className="margin-bottom-20"
            style={{
              width: "100%",
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <div className="align-center"><CircularProgress className="blue" size={40} /></div>
          </Grid> : (
            <>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="margin-bottom-20">
                <Paper className="paper card-body margin-right-10px">
                  <SpaceBetween className="card__title">
                    <Flex>
                      <Person style={{ color: 'grey', width: 20, height: 20 }} />
                      <h5 className="bold-text">{isVoMember ? "Member Information" : t('tables.individualInfo')}</h5>
                    </Flex>
                    {!loading && !error && (
                      <div>
                        {isVoMember && <Badge className="badge fs-14px white bg-black" style={{ marginRight: "10px" }}>
                          {'Mail & Package Forwarding'}
                        </Badge>
                        }
                        <Badge className={`badge fs-14px white ${status ? 'bg-green' : 'bg-moderate-grey'}`}>
                          {status ? t('associates.activesmall') : t('associates.inactivesmall')}
                        </Badge>
                      </div>

                    )}
                  </SpaceBetween>
                  {error && (
                    <VerticallyCenter style={{ height: 400 }}>
                      {error}
                    </VerticallyCenter>
                  )}
                  {!error && (
                    <div>
                      <AlignCenter>
                        <div style={{ width: '14%' }}>
                          {profilePicture && profilePicture}
                        </div>
                      </AlignCenter>
                      <Grid container direction="row" justify="space-between">
                        <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                          {loading ? (
                            <Skeleton width={100} />
                          ) : (
                            <CustomTextField
                              fullWidth
                              disabled={!editing}
                              label={t('tables.name')}
                              value={name}
                              InputProps={{
                                style: inputStyle,
                              }}
                              InputLabelProps={{ style: labelStyle }}
                              onChange={handleChange('name')}
                              onClick={() => startEdit()}
                              helperText={nameError ? t('common.error.nameValidation') : ''}
                              FormHelperTextProps={{ style: { color: '#FF1E59' } }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                          {loading ? (
                            <Skeleton width={100} />
                          ) : (
                            <CustomTextField
                              notchedOutline="false"
                              fullWidth
                              disabled={!editing}
                              label={t('tables.email')}
                              value={email}
                              SelectProps={{ style: inputStyle }}
                              InputProps={{ style: inputStyle }}
                              InputLabelProps={{ style: labelStyle }}
                              onChange={handleChange('email')}
                              onClick={() => startEdit()}
                              helperText={emailError ? t('emailValidation') : ''}
                              FormHelperTextProps={{ style: { color: '#FF1E59' } }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                          {loading ? (
                            <Skeleton width={100} />
                          ) : (
                            <CustomPhoneNumInput
                              formClass="external-container-phone-input"
                              placeholder={t('tables.phone')}
                              value={formatPhoneNumber(phone)}
                              onChange={value => handlePhone('phone', value)}
                              error={phoneError ? t('phoneValidation') : ''}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={5} style={{ marginTop: 19 }} className="align-items-center">
                          {loading ? (
                            <Skeleton width={100} />
                          ) : (
                            <CustomTextField
                              disabled
                              fullWidth
                              label={t('tables.location')}
                              value={addressValue}
                              InputProps={{
                                inputProps: {
                                  style: {
                                    inputStyle,
                                    textOverflow: 'ellipsis',
                                    color: 'black',
                                    fontSize: 14,
                                  },
                                },
                              }}
                              InputLabelProps={{ style: labelStyle }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                          {loading ? (
                            <Skeleton width={100} />
                          ) : (
                            <CustomTextField
                              fullWidth
                              disabled={!editing}
                              label={t('tables.office')}
                              value={office}
                              InputProps={{ style: inputStyle }}
                              InputLabelProps={{ style: labelStyle }}
                              onChange={handleChange('office')}
                              onClick={() => startEdit()}
                              helperText={officeError ? t('common.error.officeValidation') : ''}
                              FormHelperTextProps={{ style: { color: '#FF1E59' } }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                          {loading ? (
                            <Skeleton width={100} />
                          ) : (
                            <CustomTextField
                              fullWidth
                              disabled={!editing}
                              label={t('tables.address')}
                              value={perAddress}
                              InputProps={{ style: inputStyle }}
                              InputLabelProps={{ style: labelStyle }}
                              onChange={handleChange('perAddress')}
                              onClick={() => startEdit()}
                              helperText={addressError ? t('common.error.addressValidation') : ''}
                              FormHelperTextProps={{ style: { color: '#FF1E59' } }}
                            />
                          )}
                        </Grid>
                        {loading ? (
                          <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                            <Skeleton width={100} />
                          </Grid>
                        ) : (
                          alternateName.map((altName, index) => (
                            <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                              <CustomTextField
                                fullWidth
                                disabled={!editing}
                                label={t('members.alternateName')}
                                value={altName}
                                InputProps={{
                                  style: inputStyle,
                                  endAdornment: editing ? (
                                    index === 0 ? (
                                      <AddCircleOutline
                                        style={addIconStyle}
                                        onClick={() =>
                                          addInputField('alternateName')
                                        }
                                      />
                                    ) : (
                                      <CancelOutlined
                                        style={cancelIconStyle}
                                        onClick={() =>
                                          removeInputField('alternateName', index)
                                        }
                                      />
                                    )
                                  ) : (
                                    <div />
                                  ),
                                }}
                                InputLabelProps={{ style: labelStyle }}
                                onChange={setAlternativeName(
                                  'alternateName',
                                  index,
                                )}
                                onClick={() => startEdit()}
                              />
                              {altName.length > 0 && !validateName(altName) && (
                                <FormHelperText>
                                  <p style={{ color: '#FF1E59' }}>
                                    {t('common.error.altNameValidation')}
                                  </p>
                                </FormHelperText>
                              )}
                            </Grid>
                          ))
                        )}
                        {loading ? (
                          <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                            <Skeleton width={100} />
                          </Grid>
                        ) : (
                          alternateEmail.map((altEmail, index) => (
                            <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                              <CustomTextField
                                fullWidth
                                disabled={!editing}
                                label={t('members.alternateEmail')}
                                value={altEmail}
                                InputProps={{
                                  style: inputStyle,
                                  endAdornment: editing ? (
                                    index === 0 ? (
                                      <AddCircleOutline
                                        style={addIconStyle}
                                        onClick={() =>
                                          addInputField('alternateEmail')
                                        }
                                      />
                                    ) : (
                                      <CancelOutlined
                                        style={cancelIconStyle}
                                        onClick={() =>
                                          removeInputField('alternateEmail', index)
                                        }
                                      />
                                    )
                                  ) : (
                                    <div />
                                  ),
                                }}
                                InputLabelProps={{ style: labelStyle }}
                                onChange={setAlternativeName('alternateEmail', index)}
                              />
                              {altEmail.length > 0 && !validateEmail(altEmail) && (
                                <FormHelperText>
                                  <p style={{ color: '#FF1E59' }}>
                                    {t('emailValidation')}
                                  </p>
                                </FormHelperText>
                              )}
                            </Grid>
                          ))
                        )}
                        {loading ? (
                          <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                            <Skeleton width={100} />
                          </Grid>
                        ) : (
                          alternatePhone.map((value, index) => (
                            <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                              <div className="flex">
                                <CustomPhoneNumInput
                                  placeholder={t('members.alternatePhone')}
                                  value={value}
                                  formClass="external-container-phone-input width-100"
                                  onChange={altPhone => setAltPhone('alternatePhone', index, altPhone)}
                                  error={altPhoneError[index] && t('altPhoneValidation')}
                                />
                                <div style={{ marginTop: '2rem' }}>
                                  {index === 0 ? (
                                    <AddCircleOutline
                                      style={addIconStyle}
                                      onClick={() =>
                                        addInputField('alternatePhone')
                                      }
                                    />
                                  ) : (
                                    <CancelOutlined
                                      style={cancelIconStyle}
                                      onClick={() => removeInputField('alternatePhone', index)}
                                    />
                                  )}
                                </div>
                              </div>
                            </Grid>
                          ))
                        )}
                        <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                          {loading ? (
                            <Skeleton width={100} />
                          ) : (
                            <SpaceBetween>
                              <div style={statusLabel}>
                                {t('associates.activesmall')}
                              </div>
                              <Switch
                                checked={status}
                                onChange={() => {
                                  this.popUp();
                                }}
                              />
                            </SpaceBetween>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12} style={{ marginTop: 19 }}>
                        {loading ? (
                          <Skeleton width={100} />
                        ) : (
                          <MuiThemeProvider theme={theme}>
                            <TextField
                              fullWidth
                              multiline
                              rows={5}
                              className="font-input"
                              value={note}
                              label={t('tables.notes')}
                              variant="outlined"
                              placeholder={t('tables.noNotes')}
                              onChange={handleChange('note')}
                              InputProps={{
                                inputProps: {
                                  style: {
                                    inputStyle,
                                    textOverflow: 'ellipsis',
                                    color: 'black',
                                    fontSize: 14,
                                  },
                                },
                              }}
                            />
                          </MuiThemeProvider>
                        )}
                        <form onSubmit={e => e.preventDefault()}>
                          <Dialog
                            open={this.state.openConfirmation}
                            onClose={this.closepopUp}
                            disableBackdropClick={false}
                            onBackdropClick={this.closepopUp}
                          >
                            {' '}
                            <div
                              style={{
                                width:
                                  window.screen.width < 450 &&
                                    window.screen.width > 365
                                    ? 278
                                    : window.screen.width < 365
                                      ? 220
                                      : 400,
                                overflowX: 'hidden',
                              }}
                            >
                              <Margin
                                style={{
                                  padding: 20,
                                  paddingBottom: window.screen.width < 400 ? 10 : 0,
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: window.screen.width < 400 ? 20 : 22,
                                    fontWeight: 500,
                                  }}
                                >
                                  {isVoMember ? "Deactivate VoRecipient" : ('members.deactivateMember')}
                                </p>
                                <p
                                  style={{
                                    fontSize: 14,
                                    color: '#898b8a',
                                  }}
                                >
                                  {t('members.areYouSureToDEactivate')}
                                </p>
                              </Margin>

                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  padding: window.screen.width < 400 ? 10 : 20,
                                }}
                              >
                                <Button
                                  className={classes.button}
                                  style={{
                                    width: window.screen.width < 400 ? 40 : 90,
                                    height: 34,
                                    margin: 2,
                                  }}
                                  onClick={this.closepopUp}
                                >
                                  {t('email.cancel')}
                                </Button>
                                <Button
                                  style={{
                                    width: window.screen.width < 350 ? 40 : 90,
                                    height: 33,
                                    backgroundColor: '#027AFF',
                                    color: 'white',
                                    margin: 2,
                                  }}
                                  variant="contained"
                                  className={classes.button}
                                  onClick={() => {
                                    this.closepopUp();
                                    deactivateMember();
                                  }}
                                >
                                  {t('email.yes')}
                                </Button>
                              </div>
                            </div>
                          </Dialog>
                        </form>
                      </Grid>
                    </div>
                  )}
                </Paper>
              </Grid>
              {<Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="margin-bottom-20">
                <Paper className="paper card-body margin-right-10px">
                  <h5 className="bold-text">{t('members.selectBusiness')}</h5>
                  {loading ?
                    <div className="align-center height-400p"><CircularProgress className="blue" size={20} /></div>
                    : (error && <div className="align-center height-400p">{error}</div>) || (<>
                      <form
                        onSubmit={e => {
                          e.preventDefault();
                          if (!loadingBus) {
                            if (!loading && !error) handleSearch();
                          }
                        }}
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                      >
                        <div className="before-search">
                          <InputBase
                            aria-haspopup="true"
                            className="topbar__search-field topbar__search-field--open background-grey search"
                            inputProps={{ style: { cursor: 'pointer' }, maxlength: 100 }}
                            value={search}
                            onChange={handleChange('search')}
                            placeholder={t('members.searchBusiness')}
                            startAdornment={
                              <Search
                                style={{
                                  marginRight: window.screen.width < 500 ? 0 : 10,
                                  fontSize: 18,
                                }}
                              />
                            }
                          />
                        </div>
                      </form>
                      <div className="overflowy-scroll">
                        {businesses.length > 0 || business.length ? (
                          <p>{t('members.designateMessage')}</p>
                        ) : (
                          <div />
                        )}
                        {business.length > 0 && (
                          <>
                            {business.map(bus => (
                              <ListItem style={paddingRightLeft}>
                                <div>
                                  <div style={{ display: 'flex' }}>
                                    <div style={{ width: '10%' }}>
                                      <Checkbox
                                        checked
                                        style={checkboxStyle}
                                        onChange={() => selectBusiness(bus)}
                                      />
                                    </div>
                                    <div style={{ ...displayCenter, width: '70%' }}>
                                      <ListItemText
                                        primary={
                                          <Truncate width={200}>{bus.name}</Truncate>
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div style={{ display: 'block', paddingLeft: 30 }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <p className="font-size-14 label-width-auto">{t('package.designatedpackage')}</p>
                                      <Switch
                                        checked={bus.is_designated}
                                        // checked={selectedIds.filter(idfy => idfy && (idfy.id === bus.business_id) && idfy.value === "designated").length > 0}

                                        onClick={() => assignDesignated(bus)}
                                      />
                                    </div>
                                    {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                                   <p className="font-size-14 label-width-auto"> Designated for all peer packages</p>
                                   <Switch
                                     checked={bus.is_super_designated}
                                     onClick={() => assignSuperDesignated(bus)}
                                   />
                                 </div> */}
                                  </div>
                                </div>
                              </ListItem>
                            ))}
                            <Divider /></>
                        )}
                        <List style={padding}>
                          {loadingBus && (
                            <AlignCenter style={{ flex: 1, alignItems: 'center' }}>
                              <CircularProgress className="blue" size={20} />
                            </AlignCenter>
                          )}
                          {!loadingBus && businesses.length === 0 && businessError && (
                            <AlignCenter style={{ flex: 1, alignItems: 'center' }}>
                              {businessError}
                            </AlignCenter>
                          )}
                          <div className="overflowy-scroll">
                            {businesses.map(bus => (
                              <ListItem>
                                <Checkbox
                                  checked={selectedIds.indexOf(bus.business_id) > -1}
                                  style={checkboxStyle}
                                  onChange={() => selectBusiness(bus)}
                                />
                                <ListItemText
                                  primary={<Truncate width="auto">{bus.name}</Truncate>}
                                />
                              </ListItem>
                            ))}
                          </div>
                        </List>
                      </div>
                    </>)}
                </Paper>
              </Grid>}
            </>
          )}
      </Grid>
    );
  }
}

MemberDetail.propTypes = {
  t: PropTypes.func.isRequired,
  startEdit: PropTypes.func.isRequired,
  handlePhone: PropTypes.func.isRequired,
  loading: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  business: PropTypes.node.isRequired,
  editing: PropTypes.bool.isRequired,
  emailError: PropTypes.bool.isRequired,
  nameError: PropTypes.bool.isRequired,
  addressError: PropTypes.bool.isRequired,
  officeError: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  phoneError: PropTypes.bool.isRequired,
  address: PropTypes.string.isRequired,
  office: PropTypes.string.isRequired,
  businesses: PropTypes.string.isRequired,
  perAddress: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  loadingBus: PropTypes.bool.isRequired,
  selectBusiness: PropTypes.func.isRequired,
  alternateName: PropTypes.string.isRequired,
  alternateEmail: PropTypes.string.isRequired,
  alternatePhone: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  setAlternativeName: PropTypes.func.isRequired,
  altPhoneError: PropTypes.func.isRequired,
  addInputField: PropTypes.func.isRequired,
  removeInputField: PropTypes.func.isRequired,
  setAltPhone: PropTypes.func.isRequired,
  validateEmail: PropTypes.func.isRequired,
  validateName: PropTypes.func.isRequired,
  deactivateMember: PropTypes.func.isRequired,
  assignDesignated: PropTypes.func.isRequired,
  // assignSuperDesignated: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  profilePicture: PropTypes.node.isRequired,
  businessError: PropTypes.string.isRequired,
  classes: PropTypes.node.isRequired,
  formatPhoneNumber: PropTypes.func.isRequired,
  emptyBusinesses: PropTypes.func.isRequired,
  isVoMember: PropTypes.bool
};

MemberDetail.defaultProps = {
  isVoMember: false,
};

export default withStyles(styles)(withWidth()(MemberDetail));
