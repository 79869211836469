import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { loadStripe } from '@stripe/stripe-js';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  getStripePaymentDetails,
  setPaymentPlan,
} from '../../../server/payment';
import { Button } from './Styled';


function FreeTrailExpired(props) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { userRole, buildings, t } = props;

  const { billing_method } = buildings;
  const isStripeCustomer = billing_method && billing_method.toLowerCase() === 'stripe';

  const setPaymentCheckout = (stripeId) => {
    setPaymentPlan(stripeId)
      .then(async (res) => {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: res.data.id,
        });

        if (result.error) {
          return res;
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
        return res;
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setError('Unable to set payment plan');
        setLoading(false);
        console.log(error);
      });
  };

  const addPaymentInfo = () => {
    setLoading(true);
    getStripePaymentDetails()
          .then((res) => {
            setPaymentCheckout(res.data.stripe_price_id);
          })
          .catch((err) => {
            console.log(err);
            setError('Unable to fetch stripe payment details');
            setLoading(false);
          });
  };

  const getMessage = () => {
    let message = '';
    if (buildings && buildings.length > 0 && buildings[0].status) {
      if (buildings[0].status.toLowerCase() === 'trial') {
        if (userRole === 'admin') {
          message =
            'Uh Oh, Looks like your Free Trial has expired. To continue enjoying the PackageX Mailroom experience, please add a payment method so we can charge you for your chosen plan. If you wish to choose a different plan after your Trial experience. You can view plans below.';
        } else {
          message =
            'Uh Oh, Looks like your Free Trial has expired. To continue enjoying the PackageX Mailroom experience, please contact your admin.';
        }
      } else if (buildings[0].status.toLowerCase() === 'paid') {
        if (userRole === 'admin') {
          message =
            'Uh Oh, Looks like we were unable to deduct your payment for plan renewal. Please verify your payment details so you can continue enjoying the PackageX Mailroom experience.';
        } else {
          message =
            'Uh Oh, Looks like we were unable to deduct your payment for plan renewal. Please contact your admin to verify your payment details so you can continue enjoying the PackageX Mailroom experience.';
        }
      }
      if (message === '') {
        if (userRole === 'admin') {
          message =
            'Uh Oh, Looks like we were unable to deduct your payment for plan renewal. Please verify your payment details so you can continue enjoying the PackageX Mailroom experience.';
        } else {
          message =
            'Uh Oh, Looks like we were unable to deduct your payment for plan renewal. Please contact your admin to verify your payment details so you can continue enjoying the PackageX Mailroom experience.';
        }
      }
    } else return '';
    return message;
  };

  const closeModal = () => {
    props.onClose(null);
  };

  const getButtons = () => { // isStripeCustomer
    if (isStripeCustomer) {
      if (userRole === 'admin') {
        return (<div className="pm-button-section">
          <Button
            onClick={() => {
            props.onClose(props.history.push('/manage-plan'));
          }}
            className="pm-cp-add-payment-btn"
          >
            {t('paymentModals.freeTrialExpired.viewPlan')}
          </Button>
          <Button
            className="pm-cp-upgrade-btn"
            onClick={() => addPaymentInfo()}
          >
          Add Payment {loading && <CircularProgress size={15} className="white" />}
          </Button>
        </div>);
      } else {
        return (<div className="pm-single-button-section">
          <Button
            className="pm-cp-upgrade-btn"
            onClick={closeModal}
          >
            {t('paymentModals.paymentSuccessful.button')}
          </Button>
        </div>);
      }
    }

      if (userRole === 'admin') {
        return (<div className="pm-button-section">
          <Button
            className="pm-cp-upgrade-btn"
            onClick={() => {
            props.onClose(props.history.push('/manage-plan'));
          }}
          >
            {t('paymentModals.upgradePlan.upgradeBtn')}
          </Button>
          <Button className="pm-cp-downgrade-btn" onClick={closeModal}>
            {t('common.continue')}
          </Button>
        </div>);
      } else {
        return (<div className="pm-single-button-section">
          <Button
            className="pm-cp-upgrade-btn"
            onClick={closeModal}
          >
            {t('paymentModals.paymentSuccessful.button')}
          </Button>
        </div>);
      }
  };

  return (
    <div>
      <Dialog keepMounted maxWidth="sm" onClose={closeModal} open={props.open}>
        <DialogContent className="pm-modal-container">
          <h3 className="pm-heading">
            {t('paymentModals.freeTrialExpired.headingNormal')}{' '}
            <span className="pm-heading-red">
              {t('paymentModals.freeTrialExpired.headingColored')}
            </span>
          </h3>
          <p className="pm-description">{getMessage()}</p>
          {getButtons()}
        </DialogContent>
      </Dialog>
    </div>
  );
}

FreeTrailExpired.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  history: PropTypes.node.isRequired,
  buildings: PropTypes.array.isRequired,
};

export default withRouter(FreeTrailExpired);
