import React from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from './Styled';

function PlanChangeRequested(props) {
  const { t } = props;
  return (
    <div>
      <Dialog keepMounted maxWidth="sm" {...props}>
        <DialogContent className="pm-modal-container">
          <h3 className="pm-heading">
            <span className="pm-heading-green">
              {t('paymentModals.planChangeRequested.headingColored')}
            </span>{' '}
            {t('paymentModals.planChangeRequested.headingNormal')}
          </h3>
          <p className="pm-description">
            {t('paymentModals.planChangeRequested.description')}
          </p>
          <div className="pm-single-button-section">
            <Button
              className="pm-cp-upgrade-btn"
              onClick={() => props.history.replace('/dashboard')}
            >
              {t('paymentModals.paymentSuccessful.button')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withRouter(PlanChangeRequested);
