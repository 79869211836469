/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  //   FormHelperText,
  CircularProgress,
  Button,
} from '@material-ui/core';
import QRCODE from '../../../../assets/QR_APP.svg';
import { CallTrigger } from '../../../../util/Triggers/function';
import { CLA } from '../../../../util/Triggers/triggerNames';

class DownloadApp extends React.Component {
  componentDidMount() {
    this.props.handleDisablePrev(false);
    this.props.handleDisableNext(false);
    CallTrigger(CLA.DOWNLOAD_APP.Download_App_Screen, { Previous_event: '' });
  }
  render() {
    const email = localStorage.getItem('onBoarding_email');
    const { next, t } = this.props;
    return (
      <div className="onboard-new-outer-container">
        <Grid /*container*/ className="container" style={{ minWidth: '100%' }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              //   setwaiting(true);
              CallTrigger(CLA.DOWNLOAD_APP.Download_App_Continue_Bclick, {
                Previous_event: '',
              });
              next();
            }}
          >
            <Grid item xs={12} sm={12}>
              <h3 className="onboarding-welcome-title">
                {/* {t('onboarding.downloadApp.title')} */}
                Your Account is Ready!
              </h3>
              <br />
              {/* <p className="onboarding-new-text-description"> */}
              {/* {t('onboarding.downloadApp.description')} */}
              {/* Congratulations, your Mailroom account is now ready for you to
                begin scanning!
              </p> */}
              <p className="onboarding-welcome-description">
                We've curated a simple tutorial where we'll walk you through the
                process of scan, notify &amp; pickup. Please follow the steps
                below to get started.
              </p>
              <p className="onboarding-text-heading">
                How to install Mobile App:
              </p>
            </Grid>
            <Grid xs={12} sm={12} container>
              <Grid xs={12} sm={7} md={7}>
                <p className="onboarding-new-text-bullet">
                  1. Scan the QR code with your smart device.
                </p>
                <p className="onboarding-new-text-bullet">
                  2. Download "Mailroom by PackageX" from your devices' app
                  store.
                </p>
                <p
                  className="onboarding-new-text-bullet"
                  style={{ marginBottom: '10px' }}
                >
                  3. Sign in to the mobile app using your email address ({email})
                  and the password you set on the previous screen.
                </p>
              </Grid>
              <Grid
                xs={12}
                sm={5}
                md={5}
                container
                alignItems="center"
                className="qr-code-container-responsive"
              >
                <div className="qrcode-container">
                  <img
                    src={QRCODE}
                    alt="PackageX Mailroom App Qrcode"
                    className=" onboard-page-qr"
                    width="100%"
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                type="submit"
                style={{
                  marginTop: 25,
                }}
                disabled={this.props.waiting}
                className={`onBoard-page-btn ${
                  this.props.waiting ? 'onBoard-page-btn-disabled' : ''
                }`}
                onClick={() => next()}
              >
                {t('onboarding.downloadApp.button')}
                {this.props.waiting && (
                  <div className="ml-2">
                    <CircularProgress size={15} className="white" />
                  </div>
                )}
              </Button>
            </Grid>
          </form>
        </Grid>
      </div>
    );
  }
}

DownloadApp.propTypes = {
  next: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  waiting: PropTypes.bool.isRequired,
  handleDisablePrev: PropTypes.func.isRequired,
  handleDisableNext: PropTypes.func.isRequired,
};

export default DownloadApp;
