
import React from 'react';
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
// import PaginationComponent from '../../../../shared/components/PaginationWithNumbers/components/paginationComponent';

class NotificationPagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleChangePage = (event, newPage) => {
      this.props.setOffset(newPage);  
    };

    handleChangeRowsPerPage = (event) => {
      this.props.setPaginationLimit(parseInt(event.target.value, 10));
    };

    render() {
      const { totalRecords, offset, paginationLimit } = this.props;
        return (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={totalRecords}
            page={offset}
            onChangePage={this.handleChangePage}
            rowsPerPage={paginationLimit}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        );
    }
}

NotificationPagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  setOffset: PropTypes.func.isRequired,
  setPaginationLimit: PropTypes.func.isRequired,
  paginationLimit: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired
};

export default NotificationPagination;
