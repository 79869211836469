/* eslint-disable */

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { loadStripe } from '@stripe/stripe-js';
import { connect } from 'react-redux';
import { CircularProgress, Grid, Button } from '@material-ui/core';
import { isEqual } from 'lodash';
import { AlignCenter, VerticallyCenter } from '../../UI/ResponsiveRelated';
import CachedIcon from '@material-ui/icons/Cached';
import Alert from '../../UI/Poper';
import { setPaymentPlan, changeUserPlan } from '../../../server/payment';
import CircleCheck from '../../../shared/img/circlecheck.svg';
import CircleCheckWhite from '../../../shared/img/circlecheckwhite.svg';
import PlanChangeRequested from './PlanChangeRequested';
import ContactSalesResponse from './ContactSalesResponse';
import CustomPlan from './CustomPlan';
import { getPlanFeatures } from '../../../util/application.utils';

class ComparePlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadArray: true,
      type: 1,
      plans: [],
      selectedPlan: '',
      newSelectedPlan: '',
      loadingBtns: [],
      loadCustomContactSales: false,
      disableBtns: false,
      starterLoader: false,
      basicLoader: false,
      premiumLoader: false,
      internalLoader: true,
      openPlanChangeRequestModal: false,
      openContactSalesResponseModal: false,
      error: false,
      starterFeatures: [],
      basicFeatures: [],
      premiumFeatures: [],
    };
    this.getPlans = this.getPlans.bind(this);
  }

  componentDidMount() {
    if (this.props.buildings.length > 0) {
      const { tier } = this.props.buildings[0];
      const planType = this.props.buildings[0].billing === 'monthly' ? 1 : 0;
      let selectedTier = '';
      if (tier && tier.toLowerCase() === 'premium') {
        selectedTier = 'PREMIUM';
      } else if (tier && tier.toLowerCase() === 'basic') {
        selectedTier = 'BASIC';
      } else if (tier && tier.toLowerCase() === 'starter') {
        selectedTier = 'STARTER';
      } else if (tier && tier.toLowerCase() === 'enterprise') {
        selectedTier = 'Enterprise';
      } else if (tier && tier.toLowerCase() === 'custom') {
        selectedTier = 'Custom';
      }
      this.setState(
        {
          selectedPlan: selectedTier,
          loadArray: false,
          type: planType,
        },
        () => {
          this.composePlans();
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.buildings.length !== this.props.buildings.length &&
      this.props.buildings.length > 0
    ) {
      // this.props.buildings[0].tier;
      const planType = this.props.buildings[0].billing === 'monthly' ? 1 : 0;
      const { tier } = this.props.buildings[0];
      let selectedTier = '';
      if (tier && tier.toLowerCase() === 'premium') {
        selectedTier = 'PREMIUM';
      } else if (tier && tier.toLowerCase() === 'basic') {
        selectedTier = 'BASIC';
      } else if (tier && tier.toLowerCase() === 'starter') {
        selectedTier = 'STARTER';
      } else if (tier && tier.toLowerCase() === 'enterprise') {
        selectedTier = 'Enterprise';
      } else if (tier && tier.toLowerCase() === 'custom') {
        selectedTier = 'Custom';
      }
      this.setState({
        selectedPlan: selectedTier,
        loadArray: false,
        type: planType,
      });
    }
    if (
      this.props.paymentsDetails.details.length > 0 &&
      prevProps.paymentsDetails.details.length !==
        this.props.paymentsDetails.details.length
    ) {
      this.composePlans();
    }
    if (
      this.props.buildingLoader !== prevProps.buildingLoader &&
      this.props.buildingLoader === false &&
      this.props.paymentsDetails.loading !==
        prevProps.paymentsDetails.loading &&
      this.props.paymentsDetails.loading === false
    ) {
      this.setState({
        internalLoader: false,
      });
    }
  }

  setBtnLoading = (type, status, callback) => {
    let loadingArray = [...this.state.loadingBtns];
    if (type === 'STARTER') {
      this.setState({ starterLoader: status, newSelectedPlan: type });
      loadingArray[0] = status;
    } else if (type === 'BASIC') {
      this.setState({ basicLoader: status, newSelectedPlan: type });
      loadingArray[1] = status;
    } else if (type === 'PREMIUM') {
      this.setState({ premiumLoader: status, newSelectedPlan: type });
      loadingArray[2] = status;
    }

    this.setState(
      {
        loadingBtns: loadingArray,
      },
      () => {
        callback();
      }
    );
  };

  composePlans = () => {
    const { details } = this.props.paymentsDetails;
    let plansComposed = new Array(3);
    let starterFeature = [];
    let premiumFeature = [];
    let basicFeature = [];
    if (details.length > 0) {
      details.forEach((planDetail) => {
        let type = '';
        if (planDetail.key === 'starter') {
          plansComposed[0] = {
            type: 'STARTER',
            priceMonthly: planDetail.features.MONTHLY_PRICE,
            priceYearly: planDetail.features.ANNUALLY_PRICE.toLocaleString(
              'en-US'
            ),
          };
          starterFeature = getPlanFeatures(planDetail.features);
        } else if (planDetail.key === 'basic') {
          plansComposed[1] = {
            type: 'BASIC',
            priceMonthly: planDetail.features.MONTHLY_PRICE,
            priceYearly: planDetail.features.ANNUALLY_PRICE.toLocaleString(
              'en-US'
            ),
          };
          basicFeature = getPlanFeatures(planDetail.features);
        } else if (planDetail.key === 'premium') {
          plansComposed[2] = {
            type: 'PREMIUM',
            priceMonthly: planDetail.features.MONTHLY_PRICE,
            priceYearly: planDetail.features.ANNUALLY_PRICE.toLocaleString(
              'en-US'
            ),
          };
          premiumFeature = getPlanFeatures(planDetail.features);
        }
      });
      // starterFeature = getPlanFeatures(details[2].features);
      // premiumFeature = getPlanFeatures(details[1].features);
      // basicFeature = getPlanFeatures(details[0].features);
      // plansComposed = [
      //   ...plansComposed,
      //   {
      //     type: 'STARTER',
      //     priceMonthly: details[2].features.MONTHLY_PRICE,
      //     priceYearly: details[2].features.ANNUALLY_PRICE,
      //   },
      // ];
      // plansComposed = [
      //   ...plansComposed,
      //   {
      //     type: 'BASIC',
      //     priceMonthly: details[0].features.MONTHLY_PRICE,
      //     priceYearly: details[0].features.ANNUALLY_PRICE,
      //   },
      // ];
      // plansComposed = [
      //   ...plansComposed,
      //   {
      //     type: 'PREMIUM',
      //     priceMonthly: details[1].features.MONTHLY_PRICE,
      //     priceYearly: details[1].features.ANNUALLY_PRICE,
      //   },
      // ];
    }
    let loadingArray = [false, false, false];

    this.setState({
      plans: plansComposed,
      loadingBtns: loadingArray,
      internalLoader: false,
      starterFeatures: starterFeature,
      basicFeatures: basicFeature,
      premiumFeatures: premiumFeature,
    });
  };

  changePlan = (planType, changeType) => {
    const { loadCustomContactSales } = this.state;
    if (loadCustomContactSales) return;
    const { tenant_name, tier } = this.props.buildings[0];
    this.setBtnLoading(planType, true, () =>
      this.setState(
        {
          disableBtns: true,
        },
        () => {
          changeUserPlan(tenant_name, tier, planType)
            .then((res) => {
              this.setBtnLoading(planType, false, () =>
                this.setState({
                  openPlanChangeRequestModal: true,
                  disableBtns: false,
                })
              );
            })
            .catch((err) => {
              this.setBtnLoading(planType, false, () =>
                this.setState({ disableBtns: false })
              );
              this.setState({ error: 'Failed to change plan!' });
              console.log(err);
            });
        }
      )
    );
  };

  getButton = (plan, classBtn, classDisableBtn, disableBtn, text) => {
    const { starterLoader, basicLoader, premiumLoader } = this.state;
    const { newSelectedPlan } = this.state;
    if (starterLoader && newSelectedPlan === plan)
      return (
        <Button
          disabled={disableBtn}
          className={disableBtn ? classDisableBtn : classBtn}
          onClick={() => this.changePlan(plan, text)}
        >
          <span className="pm-cp-btn-spacer">{text}</span>{' '}
          {starterLoader ? (
            <CircularProgress size={15} className="white" />
          ) : (
            ''
          )}
        </Button>
      );
    else if (basicLoader && newSelectedPlan === plan)
      return (
        <Button
          disabled={disableBtn}
          className={disableBtn ? classDisableBtn : classBtn}
          onClick={() => this.changePlan(plan, text)}
        >
          <span className="pm-cp-btn-spacer">{text}</span>{' '}
          {basicLoader ? <CircularProgress size={15} className="white" /> : ''}
        </Button>
      );
    else if (premiumLoader && newSelectedPlan === plan)
      return (
        <Button
          disabled={disableBtn}
          className={disableBtn ? classDisableBtn : classBtn}
          onClick={() => this.changePlan(plan, text)}
        >
          <span className="pm-cp-btn-spacer">{text}</span>{' '}
          {premiumLoader ? (
            <CircularProgress size={15} className="white" />
          ) : (
            ''
          )}
        </Button>
      );
    else
      return (
        <Button
          disabled={disableBtn}
          className={classBtn}
          onClick={() => this.changePlan(plan, text)}
        >
          {text}
        </Button>
      );
  };

  getPlans = () => {
    const {
      type,
      selectedPlan,
      plans,
      disableBtns,
      starterFeatures,
      basicFeatures,
      premiumFeatures,
    } = this.state;
    return plans.map((plan) => {
      if (plan.type === selectedPlan) {
        return (
          <div className="pm-cp-column pm-cp-silver">
            <div className="pm-cp-column-upper-column">
              <div className="pm-container-inner">
                <h3 className="pm-cp-heading-silver">{plan.type}</h3>
                <p className="pm-cp-cost-silver">
                  {type === 0 ? (
                    <span className="pm-cp-cost">
                      {plan.priceYearly !== 'FREE' && (
                        <span className="pm-cp-currency">$</span>
                      )}{' '}
                      {plan.priceYearly}
                    </span>
                  ) : (
                    <span className="pm-cp-cost">
                      {plan.priceMonthly !== 'FREE' && (
                        <span className="pm-cp-currency">$</span>
                      )}{' '}
                      {plan.priceMonthly}
                    </span>
                  )}
                </p>
                <div className="pm-cp-plan-description-silver">
                  {plan.type === 'STARTER'
                    ? 'LIMITED TO ONE BUILDING'
                    : type === 0
                    ? 'PER YEAR'
                    : 'PER MONTH'}
                </div>
              </div>

              <ul className="pm-cp-features">
                {plan.type === 'STARTER'
                  ? starterFeatures.map((obj, index) => (
                      <li
                        className={
                          index === starterFeatures.length - 1
                            ? 'pm-cp-features-item-silver pm-cp-no-border'
                            : 'pm-cp-features-item-silver'
                        }
                      >
                        <img src={CircleCheckWhite} alt="" />
                        <span>{obj}</span>
                      </li>
                    ))
                  : plan.type === 'BASIC'
                  ? basicFeatures.map((obj, index) => (
                      <li
                        className={
                          index === basicFeatures.length - 1
                            ? 'pm-cp-features-item-silver pm-cp-no-border'
                            : 'pm-cp-features-item-silver'
                        }
                      >
                        <img src={CircleCheckWhite} alt="" />
                        <span>{obj}</span>
                      </li>
                    ))
                  : plan.type === 'PREMIUM'
                  ? premiumFeatures.map((obj, index) => (
                      <li
                        className={
                          index === premiumFeatures.length - 1
                            ? 'pm-cp-features-item-silver pm-cp-no-border'
                            : 'pm-cp-features-item-silver'
                        }
                      >
                        <img src={CircleCheckWhite} alt="" />
                        <span>{obj}</span>
                      </li>
                    ))
                  : ''}
              </ul>
            </div>
            <Button disabled className="pm-cp-current-btn">
              {this.props.t('paymentModals.comparePlans.currentPlanBtn')}
            </Button>
          </div>
        );
      } else {
        return (
          <div className="pm-cp-column">
            <div className="pm-cp-column-upper-column">
              <div className="pm-container-inner">
                <h3 className="pm-cp-heading">{plan.type}</h3>
                <p className="pm-cp-cost">
                  {type === 0 ? (
                    <span className="pm-cp-cost">
                      {plan.priceYearly !== 'FREE' && (
                        <span className="pm-cp-currency">$</span>
                      )}{' '}
                      {plan.priceYearly}
                    </span>
                  ) : (
                    <span className="pm-cp-cost">
                      {plan.priceMonthly !== 'FREE' && (
                        <span className="pm-cp-currency">$</span>
                      )}{' '}
                      {plan.priceMonthly}
                    </span>
                  )}
                </p>
                <div className="pm-cp-plan-description">
                  {plan.type === 'STARTER'
                    ? 'LIMITED TO ONE BUILDING'
                    : 'PER MONTH'}
                </div>
              </div>

              <ul className="pm-cp-features">
                {plan.type === 'STARTER'
                  ? starterFeatures.map((obj, index) => (
                      <li
                        className={
                          index === starterFeatures.length - 1
                            ? 'pm-cp-features-item pm-cp-no-border'
                            : 'pm-cp-features-item'
                        }
                      >
                        <img src={CircleCheck} alt="" />
                        <span>{obj}</span>
                      </li>
                    ))
                  : plan.type === 'BASIC'
                  ? basicFeatures.map((obj, index) => (
                      <li
                        className={
                          index === basicFeatures.length - 1
                            ? 'pm-cp-features-item pm-cp-no-border'
                            : 'pm-cp-features-item'
                        }
                      >
                        <img src={CircleCheck} alt="" />
                        <span>{obj}</span>
                      </li>
                    ))
                  : plan.type === 'PREMIUM'
                  ? premiumFeatures.map((obj, index) => (
                      <li
                        className={
                          index === premiumFeatures.length - 1
                            ? 'pm-cp-features-item pm-cp-no-border'
                            : 'pm-cp-features-item'
                        }
                      >
                        <img src={CircleCheck} alt="" />
                        <span>{obj}</span>
                      </li>
                    ))
                  : ''}
              </ul>
            </div>
            {selectedPlan === 'PREMIUM' &&
              this.getButton(
                plan.type,
                'pm-cp-downgrade-btn',
                'pm-cp-btn-disabled',
                disableBtns,
                'Downgrade Plan '
              )}
            {selectedPlan === 'BASIC'
              ? plan.type === 'STARTER'
                ? this.getButton(
                    plan.type,
                    'pm-cp-downgrade-btn',
                    'pm-cp-btn-disabled',
                    disableBtns,
                    'Downgrade Plan '
                  )
                : this.getButton(
                    plan.type,
                    'pm-cp-upgrade-btn',
                    'pm-cp-btn-disabled',
                    disableBtns,
                    'Upgrade Plan '
                  )
              : ''}
            {selectedPlan === 'STARTER' &&
              this.getButton(
                plan.type,
                'pm-cp-upgrade-btn',
                'pm-cp-btn-disabled',
                disableBtns,
                'Upgrade Plan '
              )}
          </div>
        );
      }
    });
  };

  toggleTypeYearly = () => this.setState({ type: 0 });
  toggleTypeMonthly = () => this.setState({ type: 1 });
  closePlanChangeRequested = () => {
    this.setState(
      {
        openPlanChangeRequestModal: false,
      },
      () => this.props.history.push('/dashboard')
    );
  };
  closeContactSalesResponseModal = () => {
    this.setState(
      {
        openContactSalesResponseModal: false,
      },
      () => this.props.history.push('/dashboard')
    );
  };
  render() {
    const {
      type,
      selectedPlan,
      plans,
      disableBtns,
      internalLoader,
      openPlanChangeRequestModal,
      loadCustomContactSales,
      openContactSalesResponseModal,
    } = this.state;
    const { paymentsDetails, buildings } = this.props;
    const { loadingType } = paymentsDetails;
    const { t } = this.props;

    const contactSales = () => {
      const { loadingBtns } = this.state;
      const { buildings } = this.props;
      if (
        !loadingBtns ||
        loadingBtns.length === 0 ||
        loadingBtns[0] ||
        loadingBtns[1] ||
        loadingBtns[2]
      ) {
        this.setState({
          loadCustomContactSales: false,
        });
        return;
      }
      if (
        buildings &&
        buildings.length > 0 &&
        buildings[0].tier &&
        buildings[0].tenant_name
      ) {
        const { tenant_name, tier } = buildings[0];
        if (this.state.loadCustomContactSales) return;
        this.setState(
          {
            loadCustomContactSales: true,
          },
          () => {
            contactSalesTeam(tenant_name)
              .then(() => {
                this.setState(
                  {
                    loadCustomContactSales: false,
                    openContactSalesResponseModal: true,
                  },
                  () => {}
                );
              })
              .catch((err) => {
                this.setState({ loadCustomContactSales: false });
                console.log(err);
              });
          }
        );
      } else return;
    };

    const getHeader = (hasCustomPlan) => {
      // const { t } = this.props;
      return (
        <>
          <div className="pm-main-heading">
            {hasCustomPlan ? 'Current Plan' : 'Compare Plans'}
          </div>
          {!hasCustomPlan && (
            <div className="pm-main-plan-container">
              {type === 1 ? (
                <span
                  className="pm-main-plan-type-selected"
                  onClick={() => type !== 1 && this.toggleTypeMonthly()}
                >
                  {t('paymentModals.comparePlans.monthlyPlan')}
                </span>
              ) : (
                <span
                  className="pm-main-plan-type"
                  onClick={() => type !== 1 && this.toggleTypeMonthly()}
                >
                  {t('paymentModals.comparePlans.monthlyPlan')}
                </span>
              )}
              {type === 0 ? (
                <span
                  className="pm-main-plan-type-selected"
                  onClick={() => type !== 0 && this.toggleTypeYearly()}
                >
                  {t('paymentModals.comparePlans.yearlyPlan')}
                </span>
              ) : (
                <span
                  className="pm-main-plan-type"
                  onClick={() => type !== 0 && this.toggleTypeYearly()}
                >
                  {t('paymentModals.comparePlans.yearlyPlan')}
                </span>
              )}
            </div>
          )}
        </>
      );
    };
    const hasCustomPlan =
      buildings &&
      buildings.length > 0 &&
      buildings[0].tier &&
      buildings[0].tier.toLowerCase() === 'custom';
    return (
      <>
        <Dialog maxWidth="md" {...this.props}>
          {this.state.error && (
            <Alert
              variant="error"
              open
              message={this.state.error}
              onClose={() => {
                this.setState({ error: false });
              }}
            />
          )}
          {getHeader(hasCustomPlan)}
          <div className="pm-container">
            {loadingType !== 1 && loadingType !== 2 && !internalLoader ? (
              hasCustomPlan ? (
                <CustomPlan buildings={buildings} type={type} />
              ) : (
                this.getPlans()
              )
            ) : (
              <VerticallyCenter>
                <AlignCenter>
                  <CircularProgress />
                </AlignCenter>
              </VerticallyCenter>
            )}
          </div>
          {!hasCustomPlan ? (
            <div className="pm-footer">
              {t('paymentModals.comparePlans.footer.text1')}{' '}
              <span
                className="pm-footer-highlight"
                onClick={() => !loadCustomContactSales && contactSales()}
              >
                {t('paymentModals.comparePlans.footer.highlight')}{' '}
                {loadCustomContactSales && (
                  <span className="refresh-notifications-circular">
                    <CachedIcon fontSize="small" />
                  </span>
                )}
              </span>{' '}
              {t('paymentModals.comparePlans.footer.text2')}{' '}
              <span className="pm-footer-highlight">
                <a href="https://packagex.io/mailroom/pricing/" target="_blank">
                  {t('paymentModals.comparePlans.footer.click')}
                </a>
              </span>
            </div>
          ) : (
            <div className="pm-custom-footer">
              <p>
                If you wish to change the features on your current plan or
                request a new plan altogether. You can contact sales.
              </p>
              <Button
                disabled={loadCustomContactSales}
                className="pm-cp-upgrade-btn"
                onClick={() => contactSales()}
              >
                <span className="pm-cp-btn-spacer">
                  CONTACT SALES{' '}
                  {loadCustomContactSales && (
                    <CircularProgress size={15} className="white" />
                  )}
                </span>
              </Button>
            </div>
          )}

          <PlanChangeRequested open={openPlanChangeRequestModal} t={t} />
          <ContactSalesResponse
            open={openContactSalesResponseModal}
            onClose={this.closeContactSalesResponseModal}
          />
        </Dialog>
      </>
    );
  }
}

export default connect((state) => ({
  paymentsDetails: state.paymentsDetails,
  buildings: state.allBuildings,
  buildingLoader: state.loader.buildings,
}))(ComparePlans);
