import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  //   FormHelperText,
  Button,
  CircularProgress
} from '@material-ui/core';
// import { getEncryptedEmail } from '../../../../util/application.utils';
import { CallTrigger, setTriggerUserID } from '../../../../util/Triggers/function';
import { CLA } from '../../../../util/Triggers/triggerNames';

class Welcome extends React.Component {
  state = {
    disable: false
  }
  componentDidMount() {
    this.props.handleDisablePrev(true);
    this.props.handleDisableNext(false);
    CallTrigger(CLA.WELCOME.Welcome_screen, { Previous_event: '', id: localStorage.onBoardingId });
    setTriggerUserID(localStorage.onBoardingId);
    // CallTrigger(CLA.WELCOME.Welcome_screen, { Previous_event: '', userId: encryptedEmail });
    // getEncryptedEmail(localStorage.onBoarding_email)
  }
  render() {
    const {
      next,
      getUserCurrentStep,
      t,
      waiting
    } = this.props;
    const { disable } = this.state;
    const moveToNext = () => {
      // getStepOnboarding
      //   .then(res => {
      //     debugger;
      //     localStorage.setItem('clientName', res.data.tenant_info.client_name);
      //     next();
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
      getUserCurrentStep(() => next());
    };
    return (
      <div className="onBoard-page-container-welcome">
        <Grid container className="container" style={{ minWidth: '100%' }}>
          {/* <form
              onSubmit={() => next()}
          > */}
          <Grid item xs={12} sm={12}>
            <h3 className="onboarding-welcome-title">{t('onboarding.welcome.title')}</h3>
            <p className="onboarding-welcome-description">{t('onboarding.welcome.description')}</p>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              disabled={waiting || disable}
              className={`onBoard-page-btn ${waiting || disable ? 'onBoard-page-btn-disabled' : ''}`}
              onClick={() => {
                CallTrigger(CLA.WELCOME.Welcome_get_started_bclick, { Previous_event: '' });
                this.setState({
                  disable: true
                }, () => moveToNext());
                //   this.setState({ waiting: true });
              }}
            >
              {t('onboarding.welcome.button')}
              {(waiting || disable) && <div className="ml-2"><CircularProgress size={15} className="white" /></div>}
            </Button>
          </Grid>
          {/* </form> */}
        </Grid>
      </div >
    );
  }
}

Welcome.propTypes = {
  next: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  getUserCurrentStep: PropTypes.func.isRequired,
  handleDisablePrev: PropTypes.func.isRequired,
  handleDisableNext: PropTypes.func.isRequired,
  waiting: PropTypes.bool.isRequired,
};

export default Welcome;
