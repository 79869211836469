/* eslint-disable quotes */
import React, { Component } from "react";
import PropTypes from "prop-types";
// import { ArrowDropDown } from "@material-ui/icons";
import {
    // Typography,
    Divider,
    CircularProgress,
    withWidth,
    withStyles,
    Paper,
    Grid,
} from "@material-ui/core";
import {
    AlignCenter,
    VerticallyCenter,
} from "../../../UI/ResponsiveRelated";
import { CustomInputLabel } from '../../../UI/Input';

import { PrefsStyles as styles } from "../../../../util/StyleConsts";


class CompanyProfile extends Component {
    state = {
        // loading: null
    }
    render() {
        const {
            height,
            t,
            width,
            name,
            email,
            phone,
            // style
        } = this.props;

        let phoneNum = null;

        if (phone) {
            const plusCount = phone.split("+").length - 1;
            if (plusCount > 1) {
                phoneNum = phone.substring(1);
            } else if (plusCount === 1) {
                phoneNum = phone;
            } else {
                phoneNum = `+${phone}`;
            }
        }

        const inputStyle = { fontSize: 13, color: '#000' };
        const labelStyle = { fontSize: 13 };
        return (
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Paper
              style={{
                        height: width === 'sm' || width === 'xs' ? 550 : height,
                        marginBottom: 15,
                        marginRight: width === 'lg' || width === 'xl' ? 15 : 0,
                        //   ...style.common.oy_a,
                    }}
              className="just-paper"
            >
              <div className="card-body">
                <div className="card__title">
                  <h5 className="bold-text">
                    {t("settings.companyProfile")}
                  </h5>
                </div>
                <Divider />
                {name === null ? (
                  <VerticallyCenter>
                    <AlignCenter>
                      <CircularProgress className="blue" size={30} />
                    </AlignCenter>
                  </VerticallyCenter>
                        ) : (
                          <Grid container direction="row" justify="space-between">
                            <Grid item md={12} xs={12} style={{ marginTop: 19 }}>
                              <CustomInputLabel style={labelStyle} shrink>
                                {t('tables.name')}
                              </CustomInputLabel>
                              <div
                                style={
                                                {
                                                    inputStyle,
                                                    paddingBottom: '4px',
                                                    textOverflow: 'ellipsis',
                                                    borderBottom: '2px solid #90A4B7',
                                                }
                                            }
                              >{name}
                              </div>
                            </Grid>
                            {<Grid item md={12} xs={12} style={{ marginTop: 25 }}>
                              <CustomInputLabel style={labelStyle} shrink>
                                {t('tables.email')}
                              </CustomInputLabel>
                              <div style={
                                            {
                                                inputStyle,
                                                paddingBottom: '4px',
                                                textOverflow: 'ellipsis',
                                                borderBottom: '2px solid #90A4B7',
                                                minHeight: email ? '' : '20.8px',
                                            }
                                        }
                              >{email}
                              </div>
                             </Grid>}
                            {<Grid item md={12} xs={12} style={{ marginTop: 25 }}>
                              <CustomInputLabel style={labelStyle} shrink>
                                {t('tables.phone')}
                              </CustomInputLabel>

                              <div style={
                                            {
                                                inputStyle,
                                                paddingBottom: '4px',
                                                textOverflow: 'ellipsis',
                                                borderBottom: '2px solid #90A4B7',
                                                minHeight: phone ? '' : '20.8px',

                                            }}
                              >{phoneNum}
                              </div>

                             </Grid>}


                          </Grid>

                            )}
              </div>
            </Paper>
          </Grid>
        );
    }
}

CompanyProfile.propTypes = {
    height: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    // style: PropTypes.node.isRequired,
    width: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
};

export default withStyles(styles)(withWidth()(CompanyProfile));
