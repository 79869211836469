/* eslint-disable quotes */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Dropzone from 'react-dropzone';
// import { ArrowDropDown } from "@material-ui/icons";
import {
  // Typography,
  Divider,
  // CircularProgress,
  withWidth,
  withStyles,
  Paper,
  Grid,
  IconButton,
  CircularProgress
} from "@material-ui/core";
// import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
// import {
//     Margin,
//     AlignCenter,
//     SpaceBetween,
//     VerticallyCenter,
// } from "../../../UI/ResponsiveRelated";
import WarningAlert from "../../../../shared/components/Alert";
import cameraIcon from '../../../../assets/images/Icons/cameraIcon.svg';
// import DropZoneField from '../../../../shared/components/form/DropZone';

import { PrefsStyles as styles } from "../../../../util/StyleConsts";

class CompanyAsssets extends Component {
  state = {
    // fileSizeWarning: null,
    // picture: null,
  };

  loadimage = (picture, key) => this.props.setImage(picture, key);


  loadingimage = async (accepted, key) => {
    if (accepted.length === 0) {
      this.props.setWarningError((this.props.t('common.error.invalidFileType')));
      // setValue(t('common.fileLargerThan1Mb'));
    } else {
      const reader = new window.FileReader();
      reader.readAsDataURL(accepted[0]);
      reader.onloadend = () => {
        this.loadimage(reader.result, key);
      };
    }
  };

  render() {
    const {
      height,
      t,
      width,
      profileImage,
      coverImage,
      loadingCover,
      loadingDp
    } = this.props;
    const { warningerror } = this.props;
    return (
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Paper
          style={{
            height: width === 'sm' || width === 'xs' ? 550 : height,
            marginBottom: 15,
            marginRight: width === 'lg' || width === 'xl' ? 15 : 0,
            //   ...style.common.oy_a,
          }}
          className="just-paper margin-bottom-15">
          <div className="card-body p-0">
            <div className="card__title" style={{ padding: '15px' }}>
              <h5 className="bold-text mb-0">
                {t("settings.companyAssets")}
              </h5>
            </div>
            {warningerror && <WarningAlert variant="error" message={warningerror} open onClose={() => this.props.setWarningError(null)} />}
            <Divider />
            <Dropzone
              // disabled
              //  className="height-width-80px"
              accept="image/png, image/jpg, image/jpeg"
              multiple={false}
              onDrop={accepted => this.loadingimage(accepted, 'cover')}
              style={{ width: '100%', height: 'auto' }}
            >
              {
                coverImage === null ? (
                  <div
                    style={{
                      minHeight: '250px',
                      backgroundColor: loadingCover ? '' : '#3C91AD',
                      backgroundPosition: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      color: '#FFFFFF',
                      position: 'relative ',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain'
                    }}
                  >
                    {
                      loadingCover ? (
                        <CircularProgress className="blue" size={30} />
                      ) : (
                          <IconButton color="white">
                            <img src={cameraIcon} alt="camera" style={{ paddingTop: 9, paddingLeft: 5 }} />

                          </IconButton>
                        )
                    }

                  </div>

                ) : (
                    <div
                      style={{
                        height: '250px',
                        // backgroundImage: `url(${coverImage})`,
                        backgroundPosition: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        color: '#FFFFFF',
                        position: 'relative ',
                        //  backgroundRepeat: 'no-repeat',
                        // backgroundSize: 'contain',
                      }}
                    >
                      {
                        loadingCover ? (
                          <CircularProgress className="blue" size={30} />
                        ) : (
                            <>
                              <img src={coverImage} alt="dfsdf" style={{ height: '100%', width: '100%', objectFit: 'cover' }} height="250" />
                              <IconButton style={{ position: 'absolute' }} color="white">
                                <img src={cameraIcon} alt="camera" style={{ paddingTop: 9, paddingLeft: 5 }} />
                              </IconButton>
                            </>
                          )
                      }

                    </div>

                  )
              }
            </Dropzone>
            <Dropzone
              // disabled
              //  className="height-width-80px"
              accept="image/png, image/jpg, image/jpeg"
              multiple={false}
              onDrop={accepted => this.loadingimage(accepted, 'profile')}
              style={{ width: '100%', height: 'auto' }}
            >

              <div
                style={{
                  height: '174px',
                  width: '174px',
                  // backgroundImage: `url(${profileImage})`,
                  backgroundPosition: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  color: '#FFFFFF',
                  position: 'absolute ',
                  zIndex: '1',
                  top: '-40px',
                  left: '27px',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundColor: '#ffff',
                  borderRadius: '10px'
                }}
              >
                {/* <img style={{ width: "100%" }} src={logorounded} alt="dp-pictures" /> */}
                {
                  loadingDp ? (
                    <CircularProgress className="blue" size={30} />
                  ) : (
                      <>
                        <img src={profileImage} alt="dfsdf" style={{ height: '100%', width: '100%', objectFit: 'cover' }} height="250" />
                        <div className="shade" />
                        <IconButton style={{ position: 'absolute' }} color="white">
                          <img src={cameraIcon} alt="camera" style={{ paddingTop: 9, paddingLeft: 5 }} />
                        </IconButton>
                      </>
                    )
                }
              </div>
            </Dropzone>
          </div>
        </Paper>
      </Grid >
    );
  }
}

CompanyAsssets.propTypes = {
  height: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  width: PropTypes.node.isRequired,
  setImage: PropTypes.func.isRequired,
  profileImage: PropTypes.string.isRequired,
  coverImage: PropTypes.string.isRequired,
  loadingCover: PropTypes.bool.isRequired,
  loadingDp: PropTypes.bool.isRequired,
  warningerror: PropTypes.node.isRequired,
  setWarningError: PropTypes.func.isRequired
};

export default withStyles(styles)(withWidth()(CompanyAsssets));
