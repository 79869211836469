/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CircleCheck from '../../../shared/img/circlecheck.svg';
import { getPlanFeatures } from '../../../util/application.utils';

function CustomPlan(props) {
  const { buildings, type } = props;
  const hasBuilding = buildings && buildings.length > 0 && buildings[0];
  const features = hasBuilding ? getPlanFeatures(buildings[0].features) : [];
  const hasBillingInfo =
    hasBuilding && buildings[0].billing_price && buildings[0].billing;
  const getPrice = () =>
    hasBillingInfo && buildings[0].billing_price
      ? buildings[0].billing_price
      : '';
  // if (hasBillingInfo) {
  //     if (buildings[0].billing === 'monthly') {
  //         return type === 1 ? buildings[0].billing_price : buildings[0].billing_price * 12;
  //     } else {
  //         return type === 1 ? buildings[0].billing_price / 12 : buildings[0].billing_price;
  //     }
  // } else {
  //     return '';
  // }
  const getPriceType = () => {
    if (hasBillingInfo) {
      return type === 1 ? 'PER MONTH' : 'PER YEAR';
    } else {
      return '';
    }
  };
  return (
    <div className="pm-custom-main">
      <p>
        It looks like you are on a Customized Enterprise Plan, You have access
        to the following features with your Customized Plan, you can also get in
        touch with our Sales team to customize your plan further or request a
        new bundle.{' '}
      </p>
      <div className="pm-custom-plan-card">
        <div className="pm-custom-plan-header">
          <span className="pm-custom-plan-heading">Features</span>
          <div className="pm-custom-plan-cost-section">
            <span className="pm-custom-currency">
              {hasBillingInfo ? '$' : ''}
            </span>
            <div className="pm-custom-plan-cost-inner">
              <span className="cost">{getPrice()}</span>
              <span className="occurance">{getPriceType()}</span>
            </div>
          </div>
        </div>
        <ul className="pm-custom-plan-features">
          {features &&
            features.length > 0 &&
            features.map((feature, index) => (
              <li key={index} className="pm-custom-plan-list-item">
                <img src={CircleCheck} alt="" />
                <span>{feature}</span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
CustomPlan.propTypes = {
  buildings: PropTypes.node.isRequired,
  type: PropTypes.number.isRequired,
};

export default withRouter(CustomPlan);
