/* eslint-disable quotes */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { ArrowDropDown } from "@material-ui/icons";
import {
  Typography,
  Divider,
  CircularProgress,
  withWidth,
  withStyles,
  Paper,
  Table,
  Grid,
} from "@material-ui/core";
import {
  Margin,
  AlignCenter,
  SpaceBetween,
  VerticallyCenter,
} from "../../../UI/ResponsiveRelated";
import Button from "../../../UI/BlueButton";
import Switch from "../../../UI/Switch";
import { PrefsStyles as styles } from "../../../../util/StyleConsts";
import { CustomAdornmentInput } from "../../../UI/Input";
import Dropdown from "../../../UI/Dropdown/TimeDropdown";
import ReminderFrequencyDropdown from "../../../UI/Dropdown/Dropdown";
import { updatePackageNotificationPrefs } from "../../../../server/buildings";
import Alert from "../../../UI/Poper";

class Preferences extends Component {
  state = {
    openMTime: false,
    openTTime: false,
    openWTime: false,
    openTHTime: false,
    openFTime: false,
    selectedDay: undefined,
    savingTime: false,
    savingTimeError: null,
    savingTimeStatus: false,
  };

  setOpen = (state) => (e) => {
    this.setState({ [state]: e.currentTarget, selectedDay: state });
  };

  setClose = (state) =>
    this.setState({ [state]: null, selectedDay: undefined });

  saveNotificationPreferences = () => {
    this.setState(
      {
        savingTime: true,
      },
      () => {
        const {
          building_id,
          reminderPerPkg,
          mondaySwitch,
          tuesdaySwitch,
          wednesdaySwitch,
          thursdaySwitch,
          fridaySwitch,
          mondayTime,
          tuesdayTime,
          wednesdayTime,
          thursdayTime,
          fridayTime,
          timeBetweenReminders,
        } = this.props;
        const reminderJSON = {
          preference_json: {
            reminder_per_package: reminderPerPkg.value,
            time_automated_reminders: timeBetweenReminders.value,
            reminders: {
              monday: {
                active: mondaySwitch,
                time: mondayTime,
              },
              tuesday: {
                active: tuesdaySwitch,
                time: tuesdayTime,
              },
              wednesday: {
                active: wednesdaySwitch,
                time: wednesdayTime,
              },
              thursday: {
                active: thursdaySwitch,
                time: thursdayTime,
              },
              friday: {
                active: fridaySwitch,
                time: fridayTime,
              },
            },
          },
        };
        updatePackageNotificationPrefs(building_id, reminderJSON)
          .then(() => {
            this.setState({
              savingTimeStatus: true,
              savingTime: false,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              savingTimeError: true,
              savingTime: false,
            });
          });
      }
    );
  };

  closeAlert = () => {
    this.setState({
      savingTimeError: null,
      savingTimeStatus: false,
    });
  };

  render() {
    const {
      t,
      prefsLoading,
      prefsError,
      reminderPerPkg,
      timeBetweenReminders,
      mondaySwitch,
      tuesdaySwitch,
      wednesdaySwitch,
      thursdaySwitch,
      fridaySwitch,
      mondayTime,
      tuesdayTime,
      wednesdayTime,
      thursdayTime,
      fridayTime,
      handleSwitching,
      style,
      updating,
      savePrefs,
      globalSettings,
      height,
      error,
      hourSelection,
      minSelection,
      handleSelect,
      type,
      isSettings
    } = this.props;
    const { savingTime, savingTimeError, savingTimeStatus } = this.state;
    const selectedDayTime = (selectedDay) =>
      this.state.selectedDay === selectedDay;
    const reminderWindow_InputProps = {
      style: { cursor: "pointer", fontSize: 14 },
    };
    const reminderWindow_StyleProps = { width: 70, cursor: "pointer" };
    const count = [
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
      { value: 5, label: 5 },
      { value: 6, label: 6 },
      { value: 7, label: 7 },
      { value: 8, label: 8 },
      { value: 9, label: 9 },
      { value: 10, label: 10 },
      { value: 11, label: 11 },
      { value: 12, label: 12 },
    ];
    const interval = [
      { value: 24, label: `${24} ${t("common.hours")}` },
      { value: 48, label: `${48} ${t("common.hours")}` },
      { value: 72, label: `${72} ${t("common.hours")}` },
    ];

    const displayError =
      prefsError !== null && prefsError !== undefined
        ? prefsError
        : t("common.error.networkError");

    return (
      <Grid item xs={12} sm={12} md={12} lg={!type ? 6 : 12} xl={6}>
        {(savingTimeError || savingTimeStatus) && (
          <Alert
            open
            variant={savingTimeError ? "error" : "success"}
            message={
              savingTimeError
                ? t("common.error.networkError")
                : t("preferencesPortal.updated")
            }
            onClose={this.closeAlert}
          />
        )}
        <Paper
          style={{
            height,
            marginRight: isSettings ? 15 : 0,
          }}
          className="just-paper margin-bottom-15">
          {prefsLoading ? (
            <VerticallyCenter>
              <AlignCenter>
                <CircularProgress className="blue" size={20} /> &nbsp;{" "}
                {t("buildings.loadingPrefs")}
              </AlignCenter>
            </VerticallyCenter>
          ) : prefsError || error ? (
            <VerticallyCenter>
              <AlignCenter>
                <p>
                  {" "}
                  <b> {displayError} </b>{" "}
                </p>
              </AlignCenter>
            </VerticallyCenter>
          ) : (
                <div className="card-body">
                  <div className="card__title">
                    <h5 className="bold-text">
                      {t("tables.notificationFrequency")}
                    </h5>
                  </div>
                  <Divider />
                  <Table className="table--bordered overflow-hidden" responsive>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            ...style.common.p_0,
                            ...style.common.b_2,
                            overflowX: "hidden",
                          }}
                        >
                          <SpaceBetween className="height-40px">
                            <Typography className="black">
                              {t("settings.automatedRemindersPerPackage")}
                            </Typography>
                            <ReminderFrequencyDropdown
                              width={50}
                              interval={count}
                              value={reminderPerPkg}
                              onChange={(value) =>
                                handleSelect("reminderPerPkg", value)
                              }
                            />
                          </SpaceBetween>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...style.common.p_0, ...style.common.b_2 }}>
                          <SpaceBetween className="height-40px">
                            <Typography className="black">
                              {t("settings.timeBetweenReminders")}
                            </Typography>
                            <ReminderFrequencyDropdown
                              width={90}
                              interval={interval}
                              value={timeBetweenReminders}
                              onChange={(value) =>
                                handleSelect("timeBetweenReminders", value)
                              }
                            />
                          </SpaceBetween>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="card__title margin-top-10px">
                    <h5 className="bold-text">
                      {t("settings.notificationWindow")}
                    </h5>
                  </div>
                  <Divider />
                  <Table
                    className="table--bordered"
                    style={style.common.o_h}
                    responsive
                  >
                    <tbody>
                      <tr>
                        <td style={{ ...style.common.p_0, ...style.common.b_2 }}>
                          <SpaceBetween className="height-40px">
                            <div className="black align-center margin-left-m10p">
                              <Switch
                                checked={mondaySwitch}
                                onChange={handleSwitching("mondaySwitch")}
                              />
                              {t("common.monday")}
                            </div>
                            {!prefsLoading && (
                              <CustomAdornmentInput
                                autoFocus={selectedDayTime("openMTime")}
                                inputProps={reminderWindow_InputProps}
                                style={reminderWindow_StyleProps}
                                value={mondayTime}
                                onClick={this.setOpen("openMTime")}
                              >
                                <ArrowDropDown />
                              </CustomAdornmentInput>
                            )}
                          </SpaceBetween>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...style.common.p_0, ...style.common.b_2 }}>
                          <SpaceBetween className="height-40px">
                            <div className="black align-center margin-left-m10p">
                              <Switch
                                checked={tuesdaySwitch}
                                onChange={handleSwitching("tuesdaySwitch")}
                              />
                              {t("common.tuesday")}
                            </div>
                            {!prefsLoading && (
                              <CustomAdornmentInput
                                autoFocus={selectedDayTime("openTTime")}
                                inputProps={reminderWindow_InputProps}
                                style={reminderWindow_StyleProps}
                                value={tuesdayTime}
                                onClick={this.setOpen("openTTime")}
                              >
                                <ArrowDropDown />
                              </CustomAdornmentInput>
                            )}
                          </SpaceBetween>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...style.common.p_0, ...style.common.b_2 }}>
                          <SpaceBetween className="height-40px">
                            <div className="black align-center margin-left-m10p">
                              <Switch
                                checked={wednesdaySwitch}
                                onChange={handleSwitching("wednesdaySwitch")}
                              />
                              {t("common.wednesday")}
                            </div>

                            {!prefsLoading && (
                              <CustomAdornmentInput
                                autoFocus={selectedDayTime("openWTime")}
                                inputProps={reminderWindow_InputProps}
                                style={reminderWindow_StyleProps}
                                value={wednesdayTime}
                                onClick={this.setOpen("openWTime")}
                              >
                                <ArrowDropDown />
                              </CustomAdornmentInput>
                            )}
                          </SpaceBetween>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...style.common.p_0, ...style.common.b_2 }}>
                          <SpaceBetween className="height-40px">
                            <div className="black align-center margin-left-m10p">
                              <Switch
                                checked={thursdaySwitch}
                                onChange={handleSwitching("thursdaySwitch")}
                              />
                              {t("common.thursday")}
                            </div>

                            {!prefsLoading && (
                              <CustomAdornmentInput
                                autoFocus={selectedDayTime("openTHTime")}
                                inputProps={reminderWindow_InputProps}
                                style={reminderWindow_StyleProps}
                                value={thursdayTime}
                                onClick={this.setOpen("openTHTime")}
                              >
                                <ArrowDropDown />
                              </CustomAdornmentInput>
                            )}
                          </SpaceBetween>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...style.common.p_0, ...style.common.b_2 }}>
                          <SpaceBetween className="height-40px">
                            <div className="black align-center margin-left-m10p">
                              <Switch
                                checked={fridaySwitch}
                                onChange={handleSwitching("fridaySwitch")}
                              />
                              {t("common.friday")}
                            </div>
                            {!prefsLoading && (
                              <CustomAdornmentInput
                                autoFocus={selectedDayTime("openFTime")}
                                inputProps={reminderWindow_InputProps}
                                style={reminderWindow_StyleProps}
                                value={fridayTime}
                                onClick={this.setOpen("openFTime")}
                              >
                                <ArrowDropDown />
                              </CustomAdornmentInput>
                            )}
                          </SpaceBetween>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  {globalSettings && (
                    <div className="space-between-width-center justify-align-end height-150px">
                      <div />
                      <Button
                        width={180}
                        disabled={updating}
                        loading={updating}
                        onClick={() => savePrefs()}
                      >
                        {t("settings.saveSettings")}
                      </Button>
                    </div>
                  )}
                  <Dropdown
                    state="mondayTime"
                    value={mondayTime}
                    anchor={this.state.openMTime}
                    anchorName="openMTime"
                    hourSelection={hourSelection}
                    minSelection={minSelection}
                    setClose={this.setClose}
                  />
                  <Dropdown
                    state="tuesdayTime"
                    value={tuesdayTime}
                    anchor={this.state.openTTime}
                    anchorName="openTTime"
                    hourSelection={hourSelection}
                    minSelection={minSelection}
                    setClose={this.setClose}
                  />
                  <Dropdown
                    state="wednesdayTime"
                    value={wednesdayTime}
                    anchor={this.state.openWTime}
                    anchorName="openWTime"
                    hourSelection={hourSelection}
                    minSelection={minSelection}
                    setClose={this.setClose}
                  />
                  <Dropdown
                    state="thursdayTime"
                    value={thursdayTime}
                    anchor={this.state.openTHTime}
                    anchorName="openTHTime"
                    hourSelection={hourSelection}
                    minSelection={minSelection}
                    setClose={this.setClose}
                  />
                  <Dropdown
                    state="fridayTime"
                    value={fridayTime}
                    anchor={this.state.openFTime}
                    anchorName="openFTime"
                    hourSelection={hourSelection}
                    minSelection={minSelection}
                    setClose={this.setClose}
                  />
                  <div />
                  {!prefsLoading && !prefsError && this.props.comp && (
                    <Margin top={50}>
                      <div className="space-between-width-center align-center">
                        <div />
                        <Button
                          width={120}
                          loading={savingTime}
                          disabled={savingTime}
                          onClick={this.saveNotificationPreferences}
                        >
                          {t("common.save")}
                        </Button>
                      </div>
                    </Margin>
                  )}
                </div>
              )}
        </Paper>
      </Grid>
    );
  }
}

Preferences.defaultProps = {
  isSettings: false, // for CSV upload Check if true rename All Buildings to Select Buildings
};

Preferences.propTypes = {
  building_id: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  height: PropTypes.string.isRequired,
  style: PropTypes.node.isRequired,
  reminderPerPkg: PropTypes.number.isRequired,
  timeBetweenReminders: PropTypes.number.isRequired,
  mondaySwitch: PropTypes.bool.isRequired,
  tuesdaySwitch: PropTypes.bool.isRequired,
  wednesdaySwitch: PropTypes.bool.isRequired,
  thursdaySwitch: PropTypes.bool.isRequired,
  fridaySwitch: PropTypes.bool.isRequired,
  mondayTime: PropTypes.string.isRequired,
  tuesdayTime: PropTypes.string.isRequired,
  wednesdayTime: PropTypes.string.isRequired,
  thursdayTime: PropTypes.string.isRequired,
  fridayTime: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleSwitching: PropTypes.func.isRequired,
  prefsLoading: PropTypes.bool.isRequired,
  prefsError: PropTypes.string.isRequired,
  updating: PropTypes.bool.isRequired,
  savePrefs: PropTypes.func.isRequired,
  globalSettings: PropTypes.bool.isRequired,
  error: PropTypes.node.isRequired,
  hourSelection: PropTypes.func.isRequired,
  minSelection: PropTypes.func.isRequired,
  comp: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isSettings: PropTypes.node
};

export default withStyles(styles)(withWidth()(Preferences));
