/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import {
    Paper,
    Divider,
    Button,
    Popover,
    withStyles,
    withWidth,
    TextField,
    Tooltip
} from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { SpaceBetween } from "../../../UI/ResponsiveRelated";
import BlueBtn from "../../../UI/BlueButton";
import { DateRangeStyles as styles } from "../../../../util/StyleConsts";
import { getTodayEnd, getDateInAYearDifference } from "../../../../util/utility.functions";
// const EXPORT_BLUE = require('../../../../util/icons/export_icon_blue.png');
const EXPORT_BLUE = require('../../../../util/icons/download_icon_blue.svg');

const moment = extendMoment(originalMoment);

class DateRangeTableDataExport extends React.Component {
    constructor(props, context) {
        super(props, context);
        const { range } = props;
        let filter = 7;
        const today = moment();
        let value = moment.range(today.clone().subtract(6, "days")
            .format("YYYY-MM-DD"),
            today.clone().format("YYYY-MM-DD"),
        );
        if (range && range.start) {
            filter = 'cutom';
            value = moment.range(moment(range.start).format("YYYY-MM-DD"),
                moment(range.end).format("YYYY-MM-DD"),
            );
        }
        this.state = { anchorEl: null, filter, value };
    }

    onSelect = (value, states, id) => {
        const today = originalMoment();
        if (id === '1') {
            this.setState({
                filter: 1,
                value: moment.range(today.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")),
            });
        } else if (id === "7") {
            let from = originalMoment().subtract(6, 'days');
            this.setState({
                filter: 7,
                value: moment.range(from.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")),
            });
        } else if (id === "30") {
            let from = today.clone().subtract(29, 'days');
            this.setState({
                filter: 30,
                value: moment.range(from.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")),
            });
        } else {
            let daysDiff = value.end.diff(value.start, 'days');
            if (daysDiff !== 0) daysDiff += 1;
            let isToday = moment(value.end).format('dd') === moment().format('dd');
            this.setState({
                value,
                filter: (daysDiff === 0 && isToday) ? 1 : (daysDiff === 7 && isToday) ? 7 : (daysDiff === 30 && today) ? 30 : 'custom',
            });
        }
    };

    addTimeToRange = (range) => {
        const d1 = moment();
        const value = moment.range(range.start.format('YYYY-MM-DD'), range.end.format('YYYY-MM-DD'));
        value.start = moment(value.start).set({
            hour: d1.get('hour'),
            minute: d1.get('minute'),
            second: d1.get('second')
        });
        value.end = moment(value.end).set({
            hour: d1.get('hour'),
            minute: d1.get('minute'),
            second: d1.get('second')
        });
        this.props.applyFilter(value);
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    close = () => this.setState({ anchorEl: null });

    clearFilter = () => {
        this.onSelect(undefined, undefined, "30");
        this.close();
    };

    // handleImageHover = () => {
    //     document.getElementById('export-data-icon').src = EXPORT_BLUE;
    // }

    // handleImageHoverAway = () => {
    //     document.getElementById('export-data-icon').src = EXPORT_WHITE;
    // }

    renderSelectionValue = () => {
        const { t, classes, width } = this.props;
        return (
            <div style={{ borderRight: width !== "xs" && "1px solid #0000001f" }}>
                {/* <Button
                    className="date-range-list-item"
                    onClick={() => this.onSelect(undefined, undefined, '1')}
                >
                    <SpaceBetween className="width-100 font-size-12">
                        <div className="black">{t('main.filterHourly')}</div>
                        {this.state.filter === 1 && <Done className="blue font-size-12 width-55pc" />}
                    </SpaceBetween>
                </Button>
                <Divider /> */}
                <Button
                    className="date-range-list-item"
                    onClick={() => this.onSelect(undefined, undefined, "7")}
                >
                    <SpaceBetween className="width-100 font-size-12">
                        <div className="black">{t("package.last7Days")}</div>
                        {this.state.filter === 7 && <Done className="blue font-size-12 width-55pc" />}
                    </SpaceBetween>
                </Button>
                <Divider />
                <Button
                    className="date-range-list-item"
                    onClick={() => this.onSelect(undefined, undefined, "30")}
                >
                    <SpaceBetween className="width-100 font-size-12">
                        <div className="black">{t("package.last30Days")}</div>
                        {this.state.filter === 30 && <Done className="blue font-size-12 width-55pc" />}
                    </SpaceBetween>
                </Button>
                <Divider />
                <Button disabled color="default" className={classes.filterList}>
                    <SpaceBetween className="width-100 font-size-12">
                        <div className="black uppsercase">{t("package.customRange")}</div>
                        {this.state.filter === "custom" && <Done className="blue font-size-12 width-55pc" />}
                    </SpaceBetween>
                </Button>
                <Divider />
                <div className="classes.text">
                    <TextField
                        disabled
                        type="date"
                        label="From"
                        format="MM/dd/yyyy"
                        classes={classes.cssUnderline}
                        onChange={e => this.onSelect(moment.range(moment(e.target.value), moment(this.state.value.end)))}
                        inputProps={{ style: { height: 15, fontSize: 11, width: 110, padding: 10 }, shrink: true, classes: { underline: classes.cssUnderline } }}
                        value={this.state.value.start.format("YYYY-MM-DD")}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        disabled
                        type="date"
                        label="To"
                        format="MM/dd/yyyy"
                        onChange={e => this.onSelect(moment.range(moment(this.state.value.start), moment(e.target.value)))}
                        inputProps={{ style: { height: 15, fontSize: 11, width: 110, padding: 10 }, shrink: true }}
                        value={this.state.value.end.format("YYYY-MM-DD")}
                        margin="normal"
                        variant="outlined"
                    />
                </div>
            </div>
        );
    };

    render() {
        const {
            t,
            classes,
            disabled,
            // applyFilter,
            width,
            match,
            exportInProgress,
            isOperator,
            handleOperatorErrorMsg
        } = this.props;
        let { anchorEl } = this.state;
        // if (range) value = range;
        return (
            <React.Fragment>
                <Tooltip title="Export Data">
                    <img
                        role="presentation"
                        disabled={disabled}
                        src={EXPORT_BLUE}
                        alt="blue_button"
                        id="export-data-icon"
                        // onMouseOver={this.handleImageHover}
                        // onMouseLeave={this.handleImageHoverAway}
                        // onFocus={() => { }}
                        style={{
                            height: 32,
                            width: 32,
                            borderRadius: 5,
                            cursor: !exportInProgress && 'pointer',
                            opacity: exportInProgress && '0.5',
                            boxShadow: 'rgba(0,0,0,0.16) 0px 2px 10px 0px, rgba(0,0,0,0.26) 0px 2px 5px 0px'
                        }}
                        onClick={exportInProgress ? () => { } : isOperator ? handleOperatorErrorMsg : this.handleClick}
                    />
                </Tooltip>
                <Popover
                    open={anchorEl}
                    anchorEl={anchorEl}
                    onClose={this.close}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "end",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "start",
                    }}
                >
                    <Paper className="just-paper padding-5px">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p style={{ fontSize: 15 }}> Please select date range for export data: </p>
                            <div
                                style={width !== "xs"
                                    ? {
                                        display: "flex",
                                        width: 510,
                                        height: 280,
                                    } : {}
                                }
                            >
                                <div style={{ width: width !== "xs" && "50%" }}>
                                    {this.renderSelectionValue()}
                                    {width === "xs" && <Divider />}
                                </div>
                                <div style={{ marginLeft: width === "xs" ? -20 : 0 }}>
                                    <DateRangePicker
                                        value={this.state.value}
                                        onSelect={this.onSelect}
                                        singleDateRange
                                        className={classes.datePicker}
                                        maximumDate={getTodayEnd()}
                                        minimumDate={getDateInAYearDifference()}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <SpaceBetween>
                            <div />
                            <BlueBtn
                                to="/packages/all_packages"
                                top={10}
                                bottom={10}
                                width={120}
                                right={10}
                                onClick={() => {
                                    this.close();
                                    if (match && (match.params.module === 'scanned' || match.params.module === 'currently_held')) {
                                        history.replaceState({}, '', '/packages/all_packages');
                                    }
                                    this.addTimeToRange(this.state.value);
                                }}
                            >
                                {t("package.apply")}
                            </BlueBtn>
                        </SpaceBetween>
                    </Paper>
                </Popover>
            </React.Fragment>
        );
    }
}

DateRangeTableDataExport.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.node.isRequired,
    disabled: PropTypes.bool.isRequired,
    applyFilter: PropTypes.func.isRequired,
    width: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    range: PropTypes.object.isRequired,
    exportInProgress: PropTypes.bool.isRequired,
    isOperator: PropTypes.bool.isRequired,
    handleOperatorErrorMsg: PropTypes.func.isRequired
};

export default withWidth()(withStyles(styles)(DateRangeTableDataExport));
