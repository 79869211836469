/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";
import { Link as NavLink } from "react-router-dom";
import { ListItem } from "@material-ui/core";
import DashboardIcon from "./DashboardIcon";
import AssociatesIcon from "./AssociatesIcon";
import BuildingsIcon from "./BuildingsIcon";
import MembersIcon from "./MembersIcon";
import SettingsIcon from "./SettingsIcon";
import PackagesIcon from "./PackagesIcon";
import NotificationIcon from "./NotificationIcon";
import { ChangePasswordIcon } from "./ChangePasswordIcon";
import APIExposeIcon from "./APIExposeIcon";
import SupportIcon from "./SupportIcon";
import VoItemsIcon from "./VoItemsIcon";

const SidebarLink = (props) => {
  const { title, icon, route, onClick, sidebar, isActive, t, external } = props;

  const sideBarIcons = {
    home: (
      <DashboardIcon
        className="sidebar__link-title"
        active={isActive}
        label={t("sidebar.dashboard")}
      />
    ),
    inbox: (
      <PackagesIcon
        className="sidebar__link-title"
        active={isActive}
        label={t("sidebar.packages")}
      />
    ),
    users: (
      <MembersIcon
        className="sidebar__link-title"
        active={isActive}
        label={t("sidebar.members")}
      />
    ),
    briefcase: (
      <AssociatesIcon active={isActive} label={t("sidebar.associates")} />
    ),
    apartment: (
      <BuildingsIcon active={isActive} label={t("sidebar.buildings")} />
    ),
    lock: (
      <ChangePasswordIcon
        active={isActive}
        label={t("sidebar.changePassword")}
      />
    ),
    cog: <SettingsIcon active={isActive} label={t("sidebar.settings")} />,
    api: (
      <APIExposeIcon active={isActive} label={t("sidebar.apiInformation")} />
    ),
    support: <SupportIcon active={isActive} label={t("sidebar.support")} />,
    notification: <NotificationIcon active={isActive} label={'Notifications'} />,
    voItems: <VoItemsIcon
      className="sidebar__link-title"
      active={isActive}
      label={t("voItems.voItems")}
    />
  };

  return external ? (
    <a
      href="https://help.packagex.io/en/knowledge/mailroom"
      target="_blank"
    >
      <ListItem
        button
        className="sidebar__link"
        style={{
          padding: sidebar ? "14px 17px" : "15px 25px",
        }}
      >
        {icon ? sideBarIcons[icon] : ""}
        <p className="sidebar__link-title">{title}</p>
      </ListItem>
    </a>
  ) : (
    <NavLink
      to={route}
      onClick={onClick}
      activeClassName="sidebar__link-active"
      className={isActive ? "link-active" : ""}
    >
      <ListItem
        button
        className="sidebar__link"
        style={{
          padding: sidebar ? "14px 17px" : "15px 25px",
        }}
      >
        {icon ? sideBarIcons[icon] : ""}
        <p className="sidebar__link-title">{title}</p>
      </ListItem>
    </NavLink>
  );
};

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  route: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  sidebar: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

SidebarLink.defaultProps = {
  icon: "",
  route: "/",
  onClick: () => { },
  isActive: false,
};

export default SidebarLink;
