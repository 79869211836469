import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Button,
  Divider,
  List,
  ListItem,
  withWidth,
  DialogTitle,
  DialogContentText,
  ListItemText,
  DialogActions,
} from '@material-ui/core';
import {
  VerticallyCenter,
  SpaceBetween,
  Flex,
  Margin,
  Truncate,
} from '../../../UI/ResponsiveRelated';
import BlueButton from '../../../UI/BlueButton';

const WarningDialog = ({
  t,
  style,
  width,
  open,
  handleClose,
  loading,
  error,
  reupload,
  errorData,
  // showErrorData,
  removeErrorData,
  continueAnyways,
}) => (
  <Dialog
    fullWidth
    fullScreen={width === 'xs'}
    open={open}
    onClose={handleClose}
    style={{ width: width === 'xs' && '100%', height: '100%' }}
    PaperProps={{
      style: { height: '100%', padding: 20, maxWidth: '-webkit-fill-available' },
    }}
  >
    {errorData && (
      <Dialog
        className="card-body"
        open={errorData}
        onClose={() => removeErrorData()}
      >
        <DialogTitle id="draggable-dialog-title">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Rows with invalid information will not be saved. Still want to
            continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => removeErrorData()}>Cancel</Button>
          <BlueButton onClick={() => continueAnyways()}>Yes</BlueButton>
        </DialogActions>
      </Dialog>
    )}
    {!loading && (
      <div>
        <Margin bottom={10}>
          <SpaceBetween>
            <VerticallyCenter>
              <h5 style={{ fontWeight: 500 }}>{t('members.csvError')}</h5>
            </VerticallyCenter>
            <div>
              <Flex>
                <Margin right={10}>
                  {/* <Button
                    right={10}
                    width={160}
                    disabled={error && error.error_type === 1}
                    onClick={() => showErrorData()}
                  >
                    {t('members.continueAnyways')}
                  </Button> */}
                </Margin>
                <BlueButton right={10} width={160} onClick={() => reupload()}>
                  {t('members.reUploadCSV')}
                </BlueButton>
              </Flex>
            </div>
          </SpaceBetween>
        </Margin>
        <Divider />
      </div>
    )}
    <DialogContent style={{ height: 'inherit' }}>
      {loading && (
        <VerticallyCenter>
          <div className="spinner-grow text-dark" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </VerticallyCenter>
      )}
      {error &&
        (error.error_type === 0 ? (
          <List>
            <ListItem>
              <ListItemText>
                <div
                  style={{
                    ...style.common.fs_12,
                    ...style.common.fs_14,
                    ...style.common.lh_14,
                    ...style.colors.grey,
                  }}
                >
                  {t('members.rowData')}
                </div>
              </ListItemText>
              <ListItemText>
                <div
                  style={{
                    ...style.common.fs_12,
                    ...style.common.fs_14,
                    ...style.common.lh_14,
                    ...style.colors.grey,
                  }}
                >
                  {t('common.error.error')}
                </div>
              </ListItemText>
            </ListItem>
            <Divider />
            {error.invalid_data.map(err => (
              <ListItem>
                <Truncate width="50%">
                  <Truncate
                    width="100%"
                    style={{
                      ...style.common.fs_12,
                      ...style.common.fs_14,
                      ...style.common.lh_14,
                    }}
                  >
                    {err.row}
                  </Truncate>
                </Truncate>
                <Truncate width="50%" style={{ marginLeft: 50 }}>
                  <Truncate width="100%" style={style.colors.red}>
                    {err.errors.toString()}
                  </Truncate>
                </Truncate>
              </ListItem>
            ))}
          </List>
        ) : (
          <VerticallyCenter>{error.message || error}</VerticallyCenter>
        ))}
    </DialogContent>
  </Dialog>
);

WarningDialog.propTypes = {
  t: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reupload: PropTypes.func.isRequired,
  continueAnyways: PropTypes.func.isRequired,
  error: PropTypes.node.isRequired,
  style: PropTypes.node.isRequired,
  errorData: PropTypes.string.isRequired,
  removeErrorData: PropTypes.func.isRequired,
  // showErrorData: PropTypes.func.isRequired,
};

export default withWidth()(WarningDialog);
