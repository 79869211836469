/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-danger */
import React, { Fragment } from "react";
import {
  Grid,
  CircularProgress,
  MenuItem,
  Paper,
  withWidth,
} from "@material-ui/core";
import Info from "@material-ui/icons/InfoOutlined";
import ArrowRight from "@material-ui/icons/ArrowRight";
import Skeleton from "react-loading-skeleton";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { server as api } from "../../../server/server";
import EmailTemplateComponent from "../EmailTemplate";
import { CustomTextField } from "../../UI/Input";
import NotAuthorized from "../../UI/403/403";
import { AlignCenter, VerticallyCenter } from "../../UI/ResponsiveRelated";
import Footer from "../../UI/Footer/Footer";

class EmailTemplates extends React.Component {
  state = {
    loadingError_single: null,
    loadingError_multiple: null,
    template_single: {},
    changed_template_single: {},
    template_multiple: {},
    changed_template_multiple: {},
    loading_single: true,
    loading_multiple: true,
    value: (this.props.match.params.topic === "REROUTE" ||
      this.props.match.params.topic === "SNAPSEND" ||
      this.props.match.params.topic === "FORWARD" ||
      this.props.match.params.topic === "DESTROY")
      ? "single"
      : "multiple",
    value2: "normal",
    // name: "",
    building: {},
    isLgacy_email: false
  };

  componentDidMount() {
    this.getDate();
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidUpdate(props) {
    if (props.userRole !== this.props.userRole) {
      this.getDate();
    }
  }

  getDate = () => {
    const { match, getBuilding, userRole, buildings } = this.props;
    const { template_id, topic, tenant_id } = match.params;
    if (userRole && userRole !== "mailroom_supervisor") {
      // / Fetching Single Template ///
      const tenant = buildings.filter(x => x.tenant_id === +tenant_id);
      this.setState({ isLgacy_email: tenant[0].legacy_emails });
      // console.log({ tenant } , 'Tanent INfo');
      axios
        .get(`${api}/settings/notification_template/${template_id}`, {
          params: {
            topic: topic.toLowerCase(),
            type: "single",
            notification_type: tenant[0].legacy_emails ? "email" : "mailroom_emails"
          }
        })
        .then(response => {
          let template = response.data[0];
          if (topic !== "PICKUP") {
            this.setState({
              template_single: template,
              changed_template_single: template,
              loading_single: false
            });
          } else {
            let single_normal = {
              content_json: {
                body: template.content_json.body,
                title: template.content_json.title,
                subject: template.content_json.subject
              }
            };
            let single_someone = {
              content_json: {
                body: template.content_json.body_picked_by,
                title: template.content_json.someone_title,
                subject: template.content_json.someone_subject
              }
            };
            let single_belonging_to = {
              content_json: {
                body: template.content_json.body_belonging_to,
                title: template.content_json.title,
                subject: template.content_json.subject
              }
            };
            this.setState({
              template_single: [
                single_normal,
                single_someone,
                single_belonging_to
              ],
              changed_template_single: template,
              loading_single: false
            });
          }
        })
        .catch(err => {
          this.setState({
            loadingError_single: err.message,
            loading_single: false
          });
        });

      // / Fetching Multiple Template ///
      axios
        .get(`${api}/settings/notification_template/${template_id}`, {
          params: {
            topic: topic.toLowerCase(),
            type: (this.props.match.params.topic === "SNAPSEND" ||
              this.props.match.params.topic === "FORWARD" ||
              this.props.match.params.topic === "DESTROY")
              ? "single"
              : "multiple",
            notification_type: tenant[0].legacy_emails ? "email" : "mailroom_emails"
          }
        })
        .then(response => {
          let template = response.data[0];
          if (topic !== "PICKUP") {
            this.setState({
              template_multiple: template,
              changed_template_multiple: template,
              loading_multiple: false
            });
          } else {
            let multiple_normal = {
              content_json: {
                body: template.content_json.body,
                title: template.content_json.title,
                subject: template.content_json.subject,
                outbound_body: template.content_json.outbound_body,
                outbound_subject: template.content_json.outbound_subject,
                outbound_title: template.content_json.outbound_title,
              }
            };
            let multiple_someone = {
              content_json: {
                body: template.content_json.body_picked_by,
                title: template.content_json.someone_title,
                subject: template.content_json.someone_subject,
                outbound_body: template.content_json.outbound_body,
                outbound_subject: template.content_json.outbound_subject,
                outbound_title: template.content_json.outbound_title,
              }
            };
            let multiple_belonging_to = {
              content_json: {
                body: template.content_json.body_belonging_to,
                title: template.content_json.title,
                subject: template.content_json.subject,
                outbound_body: template.content_json.outbound_body,
                outbound_subject: template.content_json.outbound_subject,
                outbound_title: template.content_json.outbound_title,
              }
            };
            this.setState({
              template_multiple: [
                multiple_normal,
                multiple_someone,
                multiple_belonging_to
              ],
              changed_template_multiple: template,
              loading_multiple: false
            });
          }
        })
        .catch(err => {
          this.setState({
            loadingError_multiple: err.message,
            loading_multiple: false
          });
        });
      getBuilding(match.params.id)
        .then(building =>
          this.setState({
            building,
            // name: building.name
          })
        )
        .catch(
          err =>
            this.setState({
              loadingError_single: err.message
            })
          // eslint-disable-next-line function-paren-newline
        );
    }
  };

  handleChange = event => {
    let value = event.target.value;

    if (value === "single" || value === "multiple") {
      this.setState({ value });
    } else {
      this.setState({
        value2: value
      });
    }
  };
  pickupTemplateUpdateState = (content_json) => {
    const { topic } = this.props.match.params;
    let {
      value,
      value2,
      template_single,
      template_multiple,
      changed_template_single,
      changed_template_multiple,
    } = this.state;
    let normal = {
      content_json: {
        body: content_json.body,
        title: content_json.title,
        subject: content_json.subject
      }
    };
    let someone = {
      content_json: {
        body: content_json.body_picked_by,
        title: content_json.someone_title,
        subject: content_json.someone_subject
      }
    };
    let belonging_to = {
      content_json: {
        body: content_json.body_belonging_to,
        title: content_json.title,
        subject: content_json.subject
      }
    };
    if (value === 'single') {
      if (topic === "PICKUP") {
        if (value2 === 'normal') {
          this.setState({
            template_single: [
              normal,
              template_single[1],
              template_single[2],
            ],
            changed_template_single: {
              ...changed_template_single,
              body: normal.content_json.body,
              title: normal.content_json.title,
              subject: normal.content_json.subject
            },
            loading_single: false
          });
        } else if (value2 === 'someone') {
          this.setState({
            template_single: [
              template_single[0],
              someone,
              template_single[2],
            ],
            changed_template_single: {
              ...changed_template_single,
              body: someone.content_json.body_picked_by,
              title: someone.content_json.someone_title,
              subject: someone.content_json.someone_subject
            },
            loading_single: false
          });
        } else {
          this.setState({
            template_single: [
              template_single[0],
              template_single[1],
              belonging_to,
            ],
            changed_template_single: {
              ...changed_template_single,
              body: belonging_to.content_json.body_belonging_to,
              title: belonging_to.content_json.title,
              subject: belonging_to.content_json.subject
            },
            loading_single: false
          });
        }
      } else {
        let content_json_updated = {
          body: content_json.body,
          title: content_json.title,
          subject: content_json.subject
        };
        this.setState({
          template_single: content_json_updated,
          changed_template_single: {
            ...changed_template_single,
            content_json: content_json_updated
          },
          loading_single: false
        });
      }
    } else if (value === 'multiple') {
      if (topic === "PICKUP") {
        if (value2 === 'normal') {
          this.setState({
            template_multiple: [
              normal,
              template_multiple[1],
              template_multiple[2],
            ],
            changed_template_multiple: {
              ...changed_template_multiple,
              body: normal.content_json.body,
              title: normal.content_json.title,
              subject: normal.content_json.subject
            },
            loading_multiple: false
          });
        } else if (value2 === 'someone') {
          this.setState({
            template_multiple: [
              template_multiple[0],
              someone,
              template_multiple[2],
            ],
            changed_template_multiple: {
              ...changed_template_multiple,
              body: someone.content_json.body_picked_by,
              title: someone.content_json.someone_title,
              subject: someone.content_json.someone_subject
            },
            loading_multiple: false
          });
        } else {
          this.setState({
            template_multiple: [
              template_multiple[0],
              template_multiple[1],
              belonging_to,
            ],
            changed_template_multiple: {
              ...changed_template_multiple,
              body: belonging_to.content_json.body_belonging_to,
              title: belonging_to.content_json.title,
              subject: belonging_to.content_json.subject
            },
            loading_multiple: false
          });
        }
      } else {
        let content_json_updated = {
          body: content_json.body,
          title: content_json.title,
          subject: content_json.subject
        };
        this.setState({
          template_multiple: content_json_updated,
          changed_template_multiple: {
            ...changed_template_multiple,
            content_json: content_json_updated
          },
          loading_multiple: false
        });
      }
    } else {
      // console.log('');
    }
  };

  render() {
    const {
      template_single,
      changed_template_single,
      changed_template_multiple,
      template_multiple,
      loadingError_multiple,
      loadingError_single,
      loading_multiple,
      loading_single,
      value,
      value2,
      building,
      isLgacy_email
    } = this.state;
    const { match, t, style, userRole, width } = this.props;
    const { id, topic, template_id, tenant_id } = match.params;
    let doc = new DOMParser().parseFromString(
      changed_template_single.content,
      "text/html"
    );
    let firstDiv = doc.body.innerHTML;
    let doc2 = new DOMParser().parseFromString(
      changed_template_multiple.content,
      "text/html"
    );
    let firstDiv2 = doc2.body.innerHTML;
    let modTopic =
      topic === "PICKUP"
        ? t("email.pickup")
        : topic === "NOTIFY"
          ? t("email.notify")
          : topic === "DISCARD"
            ? t("email.discard")
            : topic === "REMINDER"
              ? t("email.reminder")
              : topic === "REROUTE"
                ? t("email.reroute")
                : t("email.default");
    let noPreview =
      '<div style="width:100%;height:100%;display:flex;justify-content:center;align-items:center; min-height: 690px;"><b>No Preview Available</b></div>';
    let pickupTemplateSingle =
      value2 === "normal"
        ? template_single[0]
        : value2 === "someone"
          ? template_single[1]
          : value2 === "belongingTo" && template_single[2];

    let pickupTemplateMultiple =
      value2 === "normal"
        ? template_multiple[0]
        : value2 === "someone"
          ? template_multiple[1]
          : value2 === "belongingTo" && template_multiple[2];
    return !userRole ? (
      <VerticallyCenter>
        <AlignCenter>
          <CircularProgress className="blue" size={30} />
        </AlignCenter>
      </VerticallyCenter>
    ) : userRole === "mailroom_supervisor" ? (
      <NotAuthorized />
    ) : (
      <Fragment>
        <Container style={{ maxWidth: "100%" }}>
          <Fragment>
            <Fragment>
              <h3 color="textPrimary" className="page-title">
                {[`${modTopic.toLowerCase()} `, t("settings.title3")]}
              </h3>
              <div style={{ display: "flex", alignItems: "center" }}>
                {!loading_multiple && !loading_single ? (
                  <Link
                    style={{
                      color: "#027AFF",
                      fontSize: 14,
                      fontWeight: 500,
                      textDecoration: "none"
                    }}
                    color="inherit"
                    to={`/building_detail/${match.params.id}`}
                  >
                    {building.name}
                  </Link>
                ) : (
                  <Skeleton width={100} />
                )}
                &nbsp;
                <ArrowRight style={{ color: "#999999" }} />
                &nbsp;
                <div
                  style={{
                    color: "#999999",
                    fontSize: 14,
                    fontWeight: 500,
                    textTransform: "capitalize"
                  }}
                >
                  {[`${modTopic.toLowerCase()} `, t("settings.temp")]}
                </div>
              </div>
              <Grid
                container
                justify="space-between"
                spacing={16}
                style={{ marginBottom: 20 }}
              >
                <Grid item xs={12} lg={7} xl={7}>
                  <Paper
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: 15,
                      height: 822,
                      overflow: "hidden",
                      padding: 18
                    }}
                    className="just-paper"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <Grid container justify="space-between">
                        <Grid item xs={12} md={4}>
                          <CustomTextField
                            select
                            label={
                              <p
                                style={{
                                  fontSize: 13,
                                  color: "#7f7f7f"
                                }}
                              >
                                {" "}
                                {t("settings.selectType")}{" "}
                                <Tooltip
                                  title={
                                    <p>
                                      {t("settings.typeInfo")}
                                    </p>
                                  }
                                  placement="right"
                                >
                                  <Info style={{ height: 16, width: 16 }} />
                                </Tooltip>
                              </p>
                            }
                            value={value}
                            onChange={this.handleChange}
                            fullWidth
                            margin="normal"
                            disabled={loading_single || loading_multiple}
                          >
                            <MenuItem value="single">
                              {" "}
                              {t("settings.singlePackage")}
                            </MenuItem>
                            {
                              (topic !== "REROUTE" && topic !== "SNAPSEND" && topic !== "HOLD" && topic !== "DESTROY") &&
                              <MenuItem value="multiple">
                                {t("settings.multiple")}
                              </MenuItem>
                            }
                          </CustomTextField>
                        </Grid>

                        {topic === "PICKUP" && (
                          <Grid item xs={12} md={6}>
                            <CustomTextField
                              select
                              label={
                                <p
                                  style={{
                                    fontSize: 13,
                                    color: "#7f7f7f"
                                  }}
                                >
                                  {t("settings.selectCategory")}
                                  <Tooltip
                                    title={
                                      <p>
                                        {t("settings.typeInfoCate")}
                                      </p>
                                    }
                                    placement="right"
                                  >
                                    <Info style={{ height: 16, width: 16 }} />
                                  </Tooltip>
                                </p>
                              }
                              value={value2}
                              onChange={this.handleChange}
                              fullWidth
                              margin="normal"
                              disabled={loading_single || loading_multiple}
                            >
                              {" "}
                              <MenuItem value="normal">
                                {" "}
                                {t("settings.selfPick")}
                              </MenuItem>
                              <MenuItem value="someone">
                                {t("settings.PickBy")}
                              </MenuItem>
                              <MenuItem value="belongingTo">
                                {t("settings.Pickon")}
                              </MenuItem>
                            </CustomTextField>
                          </Grid>
                        )}
                      </Grid>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center"
                        }}
                      >
                        {value === "single" &&
                          (loading_single ? (
                            <div
                              style={{
                                ...style.flex.flex1
                              }}
                            >
                              <CircularProgress
                                style={{ color: "#027AFF" }}
                                size={50}
                              />
                            </div>
                          ) : loadingError_single ? (
                            <div
                              style={{
                                height: 822,
                                display: "flex",
                                justifyContent: "center"
                              }}
                            >
                              <div status={loadingError_single}>
                                {loadingError_single}
                              </div>
                            </div>
                          ) : topic !== "PICKUP" ? (
                            <EmailTemplateComponent
                              pickupTemplateUpdateState={this.pickupTemplateUpdateState}
                              template={changed_template_single}
                              defaultTemplate={changed_template_single}
                              topic={topic}
                              type="single"
                              building_id={id}
                              notification_type="email"
                              t={t}
                              isLgacy_email={isLgacy_email}
                              template_id={template_id}
                              tenant_id={tenant_id}
                            />
                          ) : (
                            <EmailTemplateComponent
                              pickupTemplateUpdateState={this.pickupTemplateUpdateState}
                              template={pickupTemplateSingle}
                              defaultTemplate={changed_template_single}
                              topic={topic}
                              type="single"
                              building_id={id}
                              notification_type="email"
                              category={value2}
                              t={t}
                              isLgacy_email={isLgacy_email}
                              template_id={template_id}
                              tenant_id={tenant_id}
                            />
                          ))}
                        {value === "multiple" &&
                          (loading_multiple ? (
                            <div
                              style={{
                                height: 822,
                                ...style.flex.display,
                                ...style.flex.align_center,
                                ...style.flex.jc_c
                              }}
                            >
                              <CircularProgress
                                style={{ color: "#027AFF" }}
                                size={50}
                              />
                            </div>
                          ) : loadingError_multiple ? (
                            <div style={{ height: 822 }}>
                              <div status={loadingError_multiple}>
                                {loadingError_multiple}
                              </div>
                            </div>
                          ) : topic !== "PICKUP" ? (
                            <EmailTemplateComponent
                              pickupTemplateUpdateState={this.pickupTemplateUpdateState}
                              template={changed_template_multiple}
                              defaultTemplate={template_multiple}
                              topic={topic}
                              type="multiple"
                              building_id={id}
                              notification_type="email"
                              t={t}
                              isLgacy_email={isLgacy_email}
                              template_id={template_id}
                              tenant_id={tenant_id}
                            />
                          ) : (
                            <EmailTemplateComponent
                              pickupTemplateUpdateState={this.pickupTemplateUpdateState}
                              template={pickupTemplateMultiple}
                              defaultTemplate={changed_template_multiple}
                              topic={topic}
                              type="multiple"
                              building_id={id}
                              notification_type="email"
                              category={value2}
                              t={t}
                              isLgacy_email={isLgacy_email}
                              template_id={template_id}
                              tenant_id={tenant_id}
                            />
                          ))}
                      </div>
                    </div>
                  </Paper>
                </Grid>

                <Grid item xs={12} lg={5} xl={5}>
                  <Paper
                    elevation={11}
                    style={{
                      marginTop: 15,
                      height: 822,
                      overflowX: width === "xs" ? "auto" : "hidden",
                      overflowY: "auto"
                    }}
                  >
                    {value === "multiple" &&
                      (loading_multiple ? (
                        <div
                          style={{
                            minHeight: 822,
                            ...style.flex.display,
                            ...style.flex.align_center,
                            ...style.flex.jc_c
                          }}
                        >
                          <CircularProgress
                            style={{ color: "#027AFF" }}
                            size={50}
                          />
                        </div>
                      ) : loadingError_multiple ? (
                        <div
                          style={{
                            minHeight: 822,
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <div status={loadingError_multiple}>
                            {loadingError_multiple}
                          </div>
                        </div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              firstDiv2.includes('id="body"') &&
                                firstDiv2.includes('id="title"')
                                ? firstDiv2
                                : noPreview
                          }}
                        />
                      ))}
                    {value === "single" &&
                      (loading_single ? (
                        <div
                          style={{
                            minHeight: 822,
                            ...style.flex.display,
                            ...style.flex.align_center,
                            ...style.flex.jc_c
                          }}
                        >
                          <CircularProgress
                            style={{ color: "#027AFF" }}
                            size={50}
                          />
                        </div>
                      ) : loadingError_single ? (
                        <div
                          style={{
                            minHeight: 822,
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <div status={loadingError_single}>
                            {loadingError_single}
                          </div>
                        </div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              firstDiv.includes('id="body"') &&
                                firstDiv.includes('id="title"')
                                ? firstDiv
                                : noPreview
                          }}
                        />
                      ))}
                  </Paper>
                </Grid>
              </Grid>
            </Fragment>
          </Fragment>
        </Container>
        <Footer />
      </Fragment>
    );
  }
}

EmailTemplates.propTypes = {
  getBuilding: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
  match: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  buildings: PropTypes.object.isRequired,
};
export default withRouter(
  connect(state => ({
    buildings: state.allBuildings,
    userRole: state.userRole
  }))(withWidth()(EmailTemplates))
);
