const variable =
  process.env.REACT_APP_ENV === undefined
    ? process.env.NODE_ENV
    : process.env.REACT_APP_ENV;

export const ENV =
  variable === 'development'
    ? 'dev'
    : variable === 'production'
    ? 'prod'
    : variable;
