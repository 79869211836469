/* eslint-disable object-curly-newline */
/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  // FormControl,
  // TextField,
  withWidth,
  // Grid,
  // Tooltip
} from "@material-ui/core";
import { Link } from "react-router-dom";
// import CSV from "../components/CSV";
import Button from '../../../UI/BlueButton';
import Dialog from "../components/Dialog";
// import { Margin } from "../../../UI/ResponsiveRelated";
// import Button from "../../../UI/BlueButton";
// import csv from "../../../../assets/csv.png";
// import { csvUploading } from "../../../../redux/actions/csvActions";
import samplecsv from "../../../../assets/recipients-sample-csv.csv";
import Alert from "../../../UI/Poper";
import { validateCSV, getUploadUrl, postFileToSignedUrl } from '../../../../server';
import DownloadCSVSection from './DownloadCSV';
import RecentHistorySection from './RecentHistory';
import CSVUploadModal from './CSVUploadModal';
import BuildingToggle from '../../BuildingToggle';
// import RecentHistorySection from './RecentHistory';

class CSVUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      openDialog: false,
      percentage: 0,
      loading: false,
      success: null,
      error: null,
      buildingWarning: null,
      errorData: null,
      openCSVAttachmentModal: false,
      refreshHistory: false,
      overWrite: false,
    };
    this.addFile = this.addFile.bind(this);
  }

  // componentDidMount() {
  //   let { building, buildings } = this.props;
  // }

  setOverWrite = bool => this.setState({ overWrite: bool });

  addFile(file) {
    // remove bit from local storage that indicates user has attempted to upload CSV
    localStorage.removeItem('trialUserUploadCSV');

    let { t, building, buildings, setCsvUploadFlag } = this.props;
    this.setState({
      loading: true,
      files: [file],
      percentage: 1,
      error: null,
    }, () => {
      setInterval(() => {
        let { percentage } = this.state;
        percentage += 1;
        if (percentage < 98) this.setState({ percentage });
        if (percentage === 98) clearInterval();
      }, 10);
      getUploadUrl(building.length > 0 ? building[0].building_id : buildings[0].building_id)
        .then(response => {
          this.setState({ fileName: response.fileName });
          postFileToSignedUrl(response.uploadUrl, file)
            .then(() => {
              validateCSV(
                true,
                response.fileName,
                building.length > 0 ? building[0].building_id : buildings[0].building_id,
                this.state.overWrite,
              )
                .then(error => {
                  this.setState({ openDialog: true, percentage: 100 });
                  if (error.error_type === 0 || error.error_type === 1) {
                    this.setState({ loading: false, error });
                  } else {
                    this.setState({
                      success: t("members.csvsynched"),
                      openDialog: false,
                      loading: false,
                    });
                    setCsvUploadFlag(true);
                    // this.props.dispatch(csvUploading(true));
                  }
                })
                .catch(err => {
                  this.setState({
                    openDialog: false,
                    loading: false,
                    percentage: 100,
                    error: err.response ? err.response.data.message : err.message,
                    buildingWarning: err.response
                      ? err.response.data.message
                      : err.message.includes('Forbidden: Operation not support for the building')
                        ? t('common.error.operationNotSupport')
                        : err.message,
                  });
                });
            }).catch(err => {
              this.setState({
                openDialog: false,
                loading: false,
                error: err.response ? err.response.data.message : err.message,
              });
            });
        }).catch(err => {
          this.setState({
            openDialog: false,
            loading: false,
            percentage: 100,
            error: err.response ? err.response.data.message : err.message,
            buildingWarning: err.response
              ? err.response.data.message : t('common.error.networkError'),
          });
        });
    });
    // validateCSV(
    //   true,
    //   file && file.split(",")[1],
    //   building.length > 0 ? building[0].building_id : buildings[0].building_id,
    // )
    //   .then(error => {
    //     this.setState({ openDialog: true, percentage: 100 });
    //     if (error.error_type === 0 || error.error_type === 1) {
    //       this.setState({ loading: false, error });
    //     } else {
    //       this.setState({
    //         success: t("members.csvsynched"),
    //         openDialog: false,
    //         loading: false,
    //       });
    //       this.props.dispatch(csvUploading(true));
    //     }
    //   })
    //   .catch(err => {
    //     this.setState({
    //       openDialog: false,
    //       loading: false,
    //       percentage: 100,
    //       error: err.response ? err.response.data.message : err.message,
    //       buildingWarning: err.response
    //         ? err.response.data.message
    //         : err.message,
    //     });
    //   });
  }

  handleClose = () => this.setState({ openDialog: false });

  reupload = () =>
    this.setState({
      openDialog: false,
      files: [],
      percentage: 0,
      error: null,
    });

  continueAnyways = () => {
    this.setState({ loading: true, error: null, errorData: null });
    const { fileName } = this.state;
    let { t, buildings, building } = this.props;
    validateCSV(
      false,
      fileName,
      building.length > 0 ? building[0].building_id : buildings[0].building_id,
    )
      .then(() => {
        this.setState({
          success: t("members.csvsynched"),
          openDialog: false,
          loading: false,
        });
        // this.props.dispatch(csvUploading(true));
      })
      .catch(err => {
        this.setState({
          loading: false,
          percentage: 100,
          error: err.response ? err.response.data.message : err.message,
          buildingWarning: err.response
            ? err.response.data.message
            : err.message,
        });
      });
  };

  closeAlert = () => this.setState({ buildingWarning: null });

  showErrorData = () => this.setState({ errorData: true });

  removeErrorData = () => this.setState({ errorData: null });

  closeCSVAttachmentModal = () => this.setState({ openCSVAttachmentModal: false });

  handleRefreshHistory = bool => this.setState({ refreshHistory: bool });

  render() {
    const {
      t,
      style,
      building,
      buildings,
      width,
    } = this.props;

    const { buildingWarning, success, openCSVAttachmentModal } = this.state;
    const pxbuildingCheck = JSON.parse(localStorage.getItem('pxBuilding')) ? !JSON.parse(localStorage.getItem('pxBuilding')).length : true;
    return (
      <>
        <div className="card-body-upload-csv">
          {buildingWarning && (
            <Alert
              open={buildingWarning}
              variant="error"
              message={buildingWarning}
              onClose={this.closeAlert}
            />
          )}
          {success && (
            <Alert
              open="CSV upload request submitted. Please check the status of your request in the Recent History table."
              variant="success"
              message={success}
              onClose={() => {
                this.setState({ success: null, refreshHistory: true, openCSVAttachmentModal: false });
                // this.props.redirect();
              }}
            />
          )}

          <CSVUploadModal
            t={t}
            open={openCSVAttachmentModal}
            close={this.closeCSVAttachmentModal}
            addFile={this.addFile}
            loading={this.state.loading}
            percentage={this.state.percentage}
            overWrite={this.state.overWrite}
            setOverWrite={this.setOverWrite}
          />

          <Dialog
            errorData={this.state.errorData}
            open={this.state.openDialog}
            loading={this.state.loading}
            error={this.state.error}
            handleClose={this.handleClose}
            reupload={this.reupload}
            showErrorData={this.showErrorData}
            removeErrorData={this.removeErrorData}
            continueAnyways={this.continueAnyways}
            {...this.props}
          />

          <div className="top-section-upload-download">
            <BuildingToggle {...this.props} pxBuilding={pxbuildingCheck} />
            {/* <FormControl className="subhead" required>
              <Tooltip title={building.length > 0
                ? building[0].building_name
                : buildings.length > 0
                  ? buildings[0].building_name
                  : ""}>
                <TextField
                  disabled
                  label={t("members.chooseBuilding")}
                  inputProps={{ style: style.common.hide_text_dots }}
                  value={
                    building.length > 0
                      ? building[0].building_name
                      : buildings.length > 0
                        ? buildings[0].building_name
                        : ""
                  }
                />
              </Tooltip>
            </FormControl> */}

            <div className="top-section-upload-download-btn">
              <Button onClick={() => this.setState({ openCSVAttachmentModal: !pxbuildingCheck, buildingWarning: pxbuildingCheck ? t('members.noBuildingSelectAddCSV') : null })} target="_blank" capitalize width={180}>
                {t('members.addAttachment')}
              </Button>
            </div>
          </div>

          <div className="section-upload-download">
            <div className="card-upload-download-external">
              <DownloadCSVSection t={this.props.t} Link={Link} samplecsv={samplecsv} />
            </div>
            <div className="card-upload-download-external">
              <RecentHistorySection
                {...this.state}
                t={t}
                width={width}
                building={building}
                buildings={buildings}
                style={style}
                handleRefreshHistory={this.handleRefreshHistory}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

CSVUpload.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
  building: PropTypes.node.isRequired,
  buildings: PropTypes.node.isRequired,
  selectedBuilding: PropTypes.number.isRequired,
  selectBuilding: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  setCsvUploadFlag: PropTypes.func.isRequired,
};

export default withWidth()(CSVUpload);
