import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import BlueButton from '../../UI/BlueButton';
import { AlignCenter, VerticallyCenter } from '../../UI/ResponsiveRelated';
import box from '../../../assets/images/box.svg';
import Footer from '../../UI/Footer/Footer';

function NoRoute() {
  return (
    <Fragment>
      <Grid container className="dashboard container width-100 height-100p">
        <VerticallyCenter className="width-100">
          <AlignCenter>
            <div>
              <AlignCenter>
                <img
                  src={box}
                  alt="box"
                  style={{ height: '50%', width: '50%' }}
                />
              </AlignCenter>
              <AlignCenter>
                <h2 className="black fw500">Oops!</h2>
              </AlignCenter>
              <AlignCenter>
                <p className="black">
                  User not authorized to perform this action.
                </p>
              </AlignCenter>
              <AlignCenter>
                <BlueButton
                  width={160}
                  bottom={25}
                  to="/dashboard"
                  component={Link}
                >
                  GO TO HOMEPAGE
                </BlueButton>
              </AlignCenter>
            </div>
          </AlignCenter>
        </VerticallyCenter>
      </Grid>
      <Footer />
    </Fragment>
  );
}

export default NoRoute;
