/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withWidth, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper/index';
import NofificationsList from './components/NotificationsList';
import BuildingToggle from '../BuildingToggle';
import Footer from "../../UI/Footer/Footer";

class NotificationCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { width, t } = this.props;
    const styleOfBuildingToggle =
      width === 'xs'
        ? { display: 'flex', flexDirection: 'column' }
        : { display: 'flex', flexDirection: 'row', marginBottom: '12px' };
    const marginLeftBuildingToggle = width === 'xs' ? 0 : 15;
    return (
      <>
      <div className="dashboard container">
        <Grid className="" container>
          <Grid item md={12} style={styleOfBuildingToggle}>
            <h3 className="page-title">{t('notifications.title')}</h3>
            <div
              style={{
                marginLeft: marginLeftBuildingToggle,
                marginTop: 2,
              }}
            >
              <BuildingToggle {...this.props} />
            </div>
          </Grid>
        </Grid>
        <Paper className="paper">
          <NofificationsList t={t} />
        </Paper>
      </div>
      <Footer />
      </>
    );
  }
}

NotificationCenter.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(
  connect((state) => ({
    building: state.locationFilter,
    buildings: state.allBuildings,
    userRole: state.userRole,
    pagination: state.pagination,
  }))(withWidth()(NotificationCenter))
);
