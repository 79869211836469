import 'date-fns';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Select,
    Menu,
    MenuItem,
    Checkbox,
    FormControl,
    Chip,
    withStyles,
    IconButton,
    // withMobileDialog,
    Tooltip,
    MuiThemeProvider,
    InputLabel,
    Zoom,
    InputBase,
    CircularProgress,
    Grid,
    withWidth,
    ClickAwayListener,
    Button as MUIButton,
    InputAdornment,
    //   Slide,
} from '@material-ui/core';
import {
    Cancel,
    InfoOutlined,
    SearchOutlined,
    CalendarToday as Calendar,
} from '@material-ui/icons';
import BlueButton from '../../../../UI/BlueButton';
// import Filter from '../../../../../assets/images/filter.svg';
// import Alert from '../../../../UI/Poper';
import { couriersChinese, couriersGlobal, couriersGlobalTop } from '../../../../../util';
import { CustomInput } from '../../../../UI/Input';
import {
    //   SpaceBetween,
    Truncate,
    Margin,
    AlignCenter,
    VerticallyCenter,
} from '../../../../UI/ResponsiveRelated';
import Alert from '../../../../UI/Poper';
import {
    AdvanceFilterStyles as styles,
    AdvanceMenuProps as MenuProps,
    materialTheme,
    PackageLabels,
    PackageLabelsExtended,
} from '../../../../../util/StyleConsts';
import { voAdvanceSearchOptions } from '../../../../../util/application.utils';
import { searchMember, searchAssociate, getCountryStateCities, searchBusiness } from '../../../../../server';

import './styles.css';
import { convertTenantsIntoBuildings, getTodayEnd } from '../../../../../util/utility.functions';

const moment = extendMoment(originalMoment);

const ENV = process.env.NODE_ENV;

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

class AdvancedSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            selectedFilter: 1,
            open: false,
            member_name: '',
            business_name: '',
            filter_option: [],
            action_status: [],
            scan_type: 'All',
            package_courier: 'selectAValue',
            // package_courier: '',
            building_id: -1,
            from_date: null,
            to_date: null,
            package_category: [],
            selectedMembers: [],
            selectedAssociates: [],
            selectedMembers2: [],
            selectedBusinesses: [],
            package_tracking_number: '',
            package_scanned_by: [],
            package_belong_to: [],
            package_picked_by: [],
            package_businesses: [],
            package_receiver_state: '',
            package_receiver_city: '',
            package_receiver_zip: '',
            package_sender_city: '',
            package_sender_state: '',
            package_sender_zip: '',
            disabled: false,
            showError: false,
            key: '',
            associatesSearch: '',
            membersSearch2: '',
            businessesSearch: '',
            list: [],
            listAssociates: [],
            listMembers2: [],
            listBusinesses: [],
            error: null,
            notFound: false,
            notFoundAssociates: false,
            notFoundMember2: false,
            notFoundBusinesses: false,
            Memberloading: false,
            BusinessesLoading: false,
            memberAnchor: null,
            appliedFilters: [],
            loadingLocations: true,
            locations: [],
            errorLocations: null,
            basicSearch: '',
            fulltextSearch: '',
            package_label_id: [],
            package_location_id: [],
        };
    }

    componentDidMount = () => {
        const { t } = this.props;
        if (this.props.searchParams && this.props.searchParams !== 'cancelSearch') {
            const { searchParams } = this.props;
            const {
                key,
                member_name,
                building_id,
                package_picked_by,
                package_scanned_by,
                package_belong_to,
                package_businesses,
                scan_type,
                filter_option,
                action_status,
                from_date,
                to_date,
                package_category,
                package_tracking_number,
                package_courier,
                package_receiver_city,
                package_receiver_state,
                package_receiver_zip,
                package_sender_city,
                package_sender_state,
                package_sender_zip,
                selectedAssociates,
                selectedMembers,
                selectedMembers2,
                selectedBusinesses,
                associatesSearch,
                membersSearch2,
                businessesSearch,
                list,
                listMembers2,
                listAssociates,
                listBusinesses,
                basicSearch,
                fulltextSearch,
                package_label_id,
                package_location_id
            } = searchParams;
            let tempArray = [];

            if (member_name && member_name !== '') tempArray.push('package.recipientName');
            if (building_id) tempArray.push('package.recipientLocation');
            if (package_picked_by && package_picked_by.length !== 0) tempArray.push('package.pickedupBy');
            if (package_scanned_by && package_scanned_by.length !== 0) tempArray.push('package.scannedBy');
            if (package_belong_to && package_belong_to.length !== 0) tempArray.push('voItems.voMembers');
            if (package_businesses && package_businesses.length !== 0) tempArray.push('tables.businesses');
            if (scan_type && scan_type !== 'All') tempArray.push('tables.scanType');
            if (filter_option && filter_option.length !== 0) tempArray.push('tables.status');
            if (action_status && action_status.length !== 0) tempArray.push('tables.actionStatus');
            if (from_date || to_date) tempArray.push('tables.checkIn');
            if (package_category && package_category.length !== 0) tempArray.push('package.tags');
            if (package_tracking_number && package_tracking_number !== '') tempArray.push('package.trackingNumber');
            if (package_courier && package_courier !== '') tempArray.push('package.courier');
            if (package_receiver_city && package_receiver_city !== '') tempArray.push('package.recipientCity');
            if (package_receiver_state && package_receiver_state !== '') tempArray.push('package.recipientState');
            if (package_receiver_zip && package_receiver_zip !== '') tempArray.push('package.recipientZipcode');
            if (package_sender_city && package_sender_city !== '') tempArray.push('package.senderCity');
            if (package_sender_state && package_sender_state !== '') tempArray.push('package.senderState');
            if (package_sender_zip && package_sender_zip !== '') tempArray.push('package.senderZipcode');
            if (package_label_id && package_label_id.length !== 0) tempArray.push('tables.label');
            if (package_location_id && package_location_id.length !== 0) tempArray.push('tables.location');

            this.setState({
                key: key || '',
                to_date: to_date || null,
                from_date: from_date || null,
                scan_type: scan_type || 'All',
                member_name: member_name || '',
                building_id: building_id || -1,
                filter_option: filter_option || [],
                action_status: action_status || [],
                selectedMembers: (package_picked_by && package_picked_by.length !== 0) ? selectedMembers : [],
                selectedMembers2: (package_belong_to && package_belong_to.length !== 0) ? selectedMembers2 : [],
                selectedBusinesses: (package_businesses && package_businesses.length !== 0) ? selectedBusinesses : [],
                package_category: package_category || [],
                selectedAssociates: (package_scanned_by && package_scanned_by.length !== 0) ? selectedAssociates : [],
                package_tracking_number: package_tracking_number || '',
                package_receiver_state: package_receiver_state || '',
                package_receiver_city: package_receiver_city || '',
                package_receiver_zip: package_receiver_zip || '',
                package_sender_city: package_sender_city || '',
                package_sender_state: package_sender_state || '',
                package_sender_zip: package_sender_zip || '',
                package_scanned_by: package_scanned_by || [],
                package_belong_to: package_belong_to || [],
                package_picked_by: package_picked_by || [],
                package_businesses: package_businesses || [],
                associatesSearch: associatesSearch || '',
                membersSearch2: membersSearch2 || '',
                businessesSearch: businessesSearch || [],
                list: list || [],
                listMembers2: listMembers2 || [],
                listBusinesses: listBusinesses || [],
                listAssociates: listAssociates || [],
                package_courier: package_courier || 'selectAValue',
                // package_courier: package_courier || '',
                fulltextSearch: fulltextSearch || '',
                basicSearch: basicSearch || '',
                appliedFilters: [...tempArray],
                selectedFilter: (scan_type || 'All') === 'SCAN-OUT'
                    ? 2
                    : ((filter_option || []).length !== 0 && (filter_option || []).indexOf('collected') === -1)
                        ? 2
                        : 1,
                package_label_id: (package_label_id && package_label_id.length !== 0) ? package_label_id : [],
                package_location_id: (package_location_id && package_location_id.length !== 0) ? package_location_id : []

            });
        }

        getCountryStateCities()
            .then(locations => {
                const env = process.env.REACT_APP_ENV;
                this.setState({
                    locations: env && env.includes('cn') ? locations.filter(c => { if (c.code === 'CN') return c; return null; }) : locations,
                    loadingLocations: false,
                });
            }).catch(err => {
                this.setState({
                    loadingLocations: false,
                    errorLocations: err.response ? err.response.data.message
                        : err.message && err.message.includes('Network') ? t('common.error.networkError') : err.message,
                });
            });

        this.interval = setInterval(() => {
            const slider = document.querySelector('.child-div');
            if (slider) {
                let isDown = false;
                let startX;
                let scrollLeft;

                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 3;
                    slider.scrollLeft = scrollLeft - walk;
                });
                clearInterval(this.interval);
            }
        }, 50);
    }

    handleClickAway = () => {
        this.props.onClose();
    }

    handleOnFilterClick = (selectedFilter) => {
        this.setState({
            selectedFilter
        });
    }

    closeMemberList = () => {
        let { appliedFilters } = this.state;
        appliedFilters = appliedFilters.filter(item => item !== 'package.pickedupBy');
        this.setState({
            appliedFilters,
            selectedMembers: [],
            package_picked_by: []
        });
    }

    closeMember2List = () => {
        const appliedFilters = this.state.appliedFilters.filter(item => item !== 'voItems.voMembers');
        this.setState({
            appliedFilters,
            selectedMembers2: [],
            package_belong_to: []
        });
    }

    closeBusinessesList = () => {
        const appliedFilters = this.state.appliedFilters.filter(item => item !== 'tables.businesses');
        this.setState({
            appliedFilters,
            selectedBusinesses: [],
            package_businesses: []
        });
    }

    closeAssociatesList = () => {
        const appliedFilters = this.state.appliedFilters.filter(item => item !== 'package.scannedBy');
        this.setState({
            appliedFilters,
            selectedAssociates: [],
            package_scanned_by: []
        });
    }

    handleClickOpen = () => {
        const { clearAll } = this.props;
        let {
            from_date, to_date, filter_option, action_status
        } = this.state;
        if (clearAll) {
            this.setState({
                open: true,
                member_name: '',
                business_name: '',
                scan_type: '',
                filter_option: [],
                action_status: [],
                package_courier: '',
                building_id: null,
                from_date: null,
                to_date: null,
                package_category: [],
                package_tracking_number: '',
                package_scanned_by: '',
                package_picked_by: '',
                package_receiver_state: '',
                package_receiver_city: '',
                package_receiver_zip: '',
                package_sender_city: '',
                package_sender_state: '',
                package_sender_zip: '',
                disabled: true,
                selectedMembers: [],
            });
        } else {
            // if (range) {
            //   from_date = range.start;
            //   to_date = range.end;
            // }
            // if (statusOption) filter_option = statusOption;
            this.setState({
                open: true, from_date, to_date, filter_option, action_status
            });
        }
    };

    handleFilter = () => {
        const { match, building } = this.props;
        let { buildings } = this.props;
        const {
            from_date,
            to_date,
            building_id,
            appliedFilters,
            package_category,
        } = this.state;
        buildings = convertTenantsIntoBuildings(buildings);
        const buildingIsSelected = building && building.length !== 0;
        const filteredBuilding = buildingIsSelected
            ? buildings.filter(item => item.building_id === building[0].building_id)
            : building_id
                ? buildings.filter(item => item.building_id === building_id)
                : [];
        const selectedBuildingCode = ((buildingIsSelected && filteredBuilding.length !== 0) || (building_id && building_id !== -1))
            ? filteredBuilding[0].building_code
            : '';
        if (selectedBuildingCode !== 'DVU01') {
            const filteredArray = [...package_category].filter(item => {
                const newData = PackageLabelsExtended.filter(item2 => item.id === item2.id);
                if (newData.length !== 0) {
                    return false;
                }
                return true;
            });
            this.setState({
                package_category: [...filteredArray]
            });
        }

        if (from_date && to_date) {
            let amonth = originalMoment(from_date).month() + 1;
            let aday = originalMoment(from_date).date();
            let ayear = originalMoment(from_date).year();

            let bmonth = originalMoment(to_date).month() + 1;
            let bday = originalMoment(to_date).date();
            let byear = originalMoment(to_date).year();

            if (ayear < byear) {
                this.setState(
                    {
                        // from_date: appendTimeAtStart(moment(from_date).format('YYYY-MM-DD')),
                        // to_date: appendTimeAtEnd(moment(to_date).format('YYYY-MM-DD')),
                    },
                    () => {
                        this.props.onClose();
                        this.props.filter(this.state, appliedFilters);
                    },
                );
            } else if (amonth < bmonth && ayear === byear) {
                this.setState(
                    {
                        // from_date: appendTimeAtStart(moment(from_date).format('YYYY-MM-DD')),
                        // to_date: appendTimeAtEnd(moment(to_date).format('YYYY-MM-DD')),
                    },
                    () => {
                        this.props.onClose();
                        this.props.filter(this.state, appliedFilters);
                    },
                );
            } else if (aday <= bday && amonth === bmonth && ayear === byear) {
                this.setState(
                    {
                        // from_date: appendTimeAtStart(moment(from_date).format('YYYY-MM-DD')),
                        // to_date: appendTimeAtEnd(moment(to_date).format('YYYY-MM-DD')),
                    },
                    () => {
                        this.props.onClose();
                        this.props.filter(this.state, appliedFilters);
                    },
                );
            } else this.setState({ showError: true });
        } else {
            if (match.params.module === 'scanned' || match.params.module === 'currently_held') {
                history.replaceState({}, '', '/mp-items/all_items');
            }
            this.props.onClose();
            this.props.filter(this.state, appliedFilters);
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
            disabled: true,
        });
    };

    handleDisabled = () => {
        const {
            building_id,
            package_courier,
            member_name,
            scan_type,
            filter_option,
            action_status,
            from_date,
            to_date,
            package_category,
            package_tracking_number,
            package_scanned_by,
            package_picked_by,
            package_belong_to,
            package_businesses,
            package_receiver_state,
            package_receiver_city,
            package_receiver_zip,
            package_sender_city,
            package_sender_state,
            package_sender_zip,
            package_label_id,
            package_location_id
        } = this.state;

        let tempArray = [];

        if (member_name && member_name !== '') tempArray.push('package.recipientName');
        if (building_id && building_id !== -1) tempArray.push('package.recipientLocation');
        if (package_picked_by && package_picked_by.length !== 0) tempArray.push('package.pickedupBy');
        if (package_scanned_by && package_scanned_by.length !== 0) tempArray.push('package.scannedBy');
        if (package_belong_to && package_belong_to.length !== 0) tempArray.push('voItems.voMembers');
        if (package_businesses && package_businesses.length !== 0) tempArray.push('tables.businesses');
        if (scan_type && scan_type !== 'All') tempArray.push('tables.scanType');
        if (filter_option && filter_option.length !== 0) tempArray.push('tables.status');
        if (action_status && action_status.length !== 0) tempArray.push('tables.actionStatus');
        if (from_date || to_date) tempArray.push('tables.checkIn');
        // if (to_date) tempArray.push('tables.checkIn');
        if (package_category && package_category.length !== 0) tempArray.push('package.tags');
        if (package_tracking_number && package_tracking_number !== '') tempArray.push('package.trackingNumber');
        if (package_courier && package_courier !== '' && package_courier !== 'selectAValue') tempArray.push('package.courier');
        if (package_receiver_city && package_receiver_city !== '') tempArray.push('package.recipientCity');
        if (package_receiver_state && package_receiver_state !== '') tempArray.push('package.recipientState');
        if (package_receiver_zip && package_receiver_zip !== '') tempArray.push('package.recipientZipcode');
        if (package_sender_city && package_sender_city !== '') tempArray.push('package.senderCity');
        if (package_sender_state && package_sender_state !== '') tempArray.push('package.senderState');
        if (package_sender_zip && package_sender_zip !== '') tempArray.push('package.senderZipcode');
        if (package_label_id && package_label_id.length !== 0) tempArray.push('tables.label');
        if (package_location_id && package_location_id.length !== 0) tempArray.push('tables.location');

        this.setState({
            appliedFilters: [...tempArray]
        });
        // if (
        //     (building_id && building_id.toString().length > 0) ||
        //     package_courier.length > 0 ||
        //     member_name.length > 0 ||
        //     business_name.length > 0 ||
        //     filter_option.length > 0 ||
        //     scan_type.length > 0 ||
        //     (from_date && from_date.toString().length > 0) ||
        //     (to_date && to_date.toString().length > 0) ||
        //     package_category.length > 0 ||
        //     package_tracking_number.length > 0 ||
        //     package_scanned_by.length > 0 ||
        //     package_picked_by.length > 0 ||
        //     package_receiver_state.length > 0 ||
        //     package_receiver_city.length > 0 ||
        //     package_receiver_zip.length > 0 ||
        //     package_sender_city.length > 0 ||
        //     package_sender_state.length > 0 ||
        //     package_sender_zip.length > 0
        // ) {
        //     this.setState({ disabled: false });
        // } else {
        //     this.setState({ disabled: true });
        // }
    };

    handleBuilding = e => {
        let building_id = e.target.value === 'building' ? null : e.target.value;
        this.setState({ building_id, list: [] }, () => this.handleDisabled());
    };

    handleDelete = index => {
        this.setState({ value: this.state.package_category.splice(index, 1) }, () => this.handleDisabled());
    };

    handleCourier = e => {
        this.setState({ package_courier: e.target.value }, () => this.handleDisabled());
    };

    handleStatus = e => {
        const currentValue = e.target.value;
        this.setState({
            filter_option: currentValue,
            selectedMembers: currentValue.indexOf('holding') !== -1 ? [] : this.state.selectedMembers,
        }, () => this.handleDisabled());
    };

    handleActionStatus = e => {
        const currentValue = e.target.value;
        this.setState({
            action_status: currentValue,
            selectedMembers: currentValue.indexOf('snapsend') !== -1 ? [] : this.state.selectedMembers,
        }, () => this.handleDisabled());
    };

    handleScanType = e => {
        this.setState({
            scan_type: e.target.value,
            package_picked_by: e.target.value === 'SCAN-OUT' ? [] : this.state.package_picked_by,
            selectedMembers: e.target.value === 'SCAN-OUT' ? [] : this.state.selectedMembers
        }, () => this.handleDisabled());
    };

    handleInputChange = name => e => {
        // switch (name) {
        //     case 'member_name': this.setState({ member_name: e.target.value }, () => this.handleDisabled());
        //         break;
        //     case 'package_tracking_number': this.setState({ package_tracking_number: e.target.value }, () => this.handleDisabled());
        //         break;
        //     case 'package_receiver_city': this.setState({ package_receiver_city: e.target.value }, () => this.handleDisabled());
        //         break;
        //     case 'package_receiver_state': this.setState({ package_receiver_state: e.target.value }, () => this.handleDisabled());
        //         break;
        //     case 'package_receiver_zip': this.setState({ package_receiver_zip: e.target.value }, () => this.handleDisabled());
        //         break;
        //     case 'package_sender_city': this.setState({ package_sender_city: e.target.value }, () => this.handleDisabled());
        //         break;
        //     case 'package_sender_state': this.setState({ package_sender_state: e.target.value }, () => this.handleDisabled());
        //         break;
        //     case 'package_sender_zip': this.setState({ package_sender_zip: e.target.value }, () => this.handleDisabled());
        //         break;

        // }
        this.setState({ [name]: e.target.value }, () => this.handleDisabled());
    };

    handleDateChange = name => e => {
        const date = new Date(e);
        let newDate = date.toISOString();
        // const dateToday = date.toISOString().substr(0,10);
        // if (name === 'from_date') {
        //     newDate = appendTimeAtStart(dateToday);
        // } else {
        //     newDate = appendTimeAtEnd(dateToday);
        // }
        this.setState({ [name]: e ? newDate : null }, () => this.handleDisabled());
        // this.setState({ [name]: e ? moment(e).format('MM/DD/YYYY') : null }, () => this.handleDisabled());
    };

    startSearching = () => {
        this.setState({
            error: null,
            notFound: false,
            package_picked_by: [],
            selectedMembers: []
        });
        // const { key, building_id } = this.state;
        const { key } = this.state;
        const { building } = this.props;
        // let buildingId = building_id === -1 ? null : building_id;
        let buildingId = null;
        const { pathname } = window.location;
        if (building.length > 0) buildingId = building[0].building_id;
        if (pathname.includes('building_packages')) {
            const { match } = this.props;
            buildingId = match.params.id;
        }
        if (key.length > 0) {
            this.setState({ Memberloading: true, list: [] });
            searchMember(key, buildingId, undefined, undefined, undefined, undefined, undefined, true)
                .then(members => {
                    if (this.state.Memberloading !== false) {
                        this.setState({
                            list: members,
                            Memberloading: false,
                            notFound: members.length === 0,
                        });
                    }
                })
                .catch(error =>
                    this.setState({
                        Memberloading: false,
                        error: error.response && error.response.data.message,
                    }));
        }
    };

    startSearchingMembers2 = () => {
        this.setState({
            error: null,
            notFound: false,
            package_belong_to: [],
            selectedMembers2: []
        });
        const { membersSearch2, selectedBusinesses } = this.state;
        const { building } = this.props;
        // const { t } = this.props;
        let buildingId = null;
        const { pathname } = window.location;
        if (building.length > 0) buildingId = building[0].building_id;
        if (pathname.includes('building_packages')) {
            const { match } = this.props;
            buildingId = match.params.id;
        }
        if (membersSearch2.length > 0) {
            this.setState({ MembersLoading2: true, listMembers2: [] });
            const businessId = selectedBusinesses.length > 0 ? selectedBusinesses[0].business_id : null;
            searchMember(membersSearch2, buildingId, null, null, null, null, businessId, true)
                .then(members => {
                    if (this.state.MembersLoading2 !== false) {
                        this.setState({
                            listMembers2: members,
                            MembersLoading2: false,
                            notFoundMember2: members.length === 0,
                        });
                    }
                })
                .catch(error =>
                    this.setState({
                        MembersLoading2: false,
                        error: error.response && error.response.data.message,
                    }));
        }
    }

    startSearchingBusinesses = () => {
        this.setState({
            error: null,
            notFoundBusinesses: false,
            package_businesses: [],
            selectedBusinesses: []
        });
        const { businessesSearch } = this.state;
        const { building } = this.props;
        // const { t } = this.props;
        let buildingId = null;
        const { pathname } = window.location;
        if (building.length > 0) buildingId = building[0].building_id;
        if (pathname.includes('building_packages')) {
            const { match } = this.props;
            buildingId = match.params.id;
        }
        if (businessesSearch.length > 0) {
            this.setState({ BusinessesLoading: true, listBusinesses: [] });
            searchBusiness(businessesSearch, buildingId,undefined,undefined,undefined,undefined,true)
                .then(businesses => {
                    if (this.state.BusinessesLoading !== false) {
                        this.setState({
                            listBusinesses: businesses,
                            BusinessesLoading: false,
                            notFoundBusinesses: businesses.length === 0,
                            notFound: businesses.length === 0,
                        });
                    }
                })
                .catch(error =>
                    this.setState({
                        BusinessesLoading: false,
                        error: error.response && error.response.data.message,
                    }));
        }
    }

    startSearchingAssociates = () => {
        this.setState({
            error: null,
            notFoundAssociates: false,
            package_scanned_by: [],
            selectedAssociates: []
        });
        const { associatesSearch } = this.state;
        const { building } = this.props;
        let buildingId = null;
        const { pathname } = window.location;
        if (building.length > 0) buildingId = building[0].building_id;
        if (pathname.includes('building_packages')) {
            const { match } = this.props;
            buildingId = match.params.id;
        }
        if (associatesSearch.length > 0) {
            this.setState({ Associatesloading: true, listAssociates: [] });
            searchAssociate(associatesSearch, buildingId)
                .then(associates => {
                    if (this.state.Associatesloading !== false) {
                        this.setState({
                            listAssociates: associates.Users,
                            Associatesloading: false,
                            notFoundAssociates: associates.Users.length === 0,
                        });
                    }
                })
                .catch(error =>
                    this.setState({
                        Associatesloading: false,
                        error: error.response && error.response.data.message,
                    }));
        }
    };

    handleChange = e => {
        this.setState({
            key: e.target.value.trimLeft(),
        });
    };

    handleChangeAssociates = e => {
        this.setState({
            associatesSearch: e.target.value.trimLeft(),
        });
    };

    handleChangeMembers2 = e => {
        this.setState({
            membersSearch2: e.target.value.trimLeft(),
        });
    };

    handleChangeBusinesses = e => {
        this.setState({
            businessesSearch: e.target.value.trimLeft(),
        });
    }

    selectMember = (name, member) => {
        let { selectedMembers } = this.state;
        const index = selectedMembers
            .map(m => m.member_id)
            .indexOf(member.member_id);
        if (index > -1) selectedMembers.splice(index, 1);
        else selectedMembers.push(member);
        this.setState({ package_picked_by: selectedMembers }, () => {
            this.setState(
                {
                    package_picked_by: this.state.selectedMembers.map(m => m.member_id),
                },
                () => {
                    this.handleDisabled();
                }
            );
        });
    };

    selectMember2 = (name, member) => {
        let { selectedMembers2 } = this.state;
        const index = selectedMembers2
            .map(m => m.member_id)
            .indexOf(member.member_id);
        if (index > -1) selectedMembers2.splice(index, 1);
        else selectedMembers2.push(member);
        this.setState({ package_belong_to: selectedMembers2 }, () => {
            this.setState(
                {
                    package_belong_to: this.state.selectedMembers2.map(m => m.member_id),
                },
                () => {
                    this.handleDisabled();
                }
            );
        });
    };

    selectBusinesses = (name, business) => {
        let { selectedBusinesses } = this.state;
        const index = selectedBusinesses
            .map(m => m.business_id)
            .indexOf(business.business_id);
        if (index > -1) selectedBusinesses.splice(index, 1);
        else selectedBusinesses.push(business);
        this.setState({
            package_businesses: selectedBusinesses.map(m => m.business_id),
            selectedMember2: selectedBusinesses.length > 1 ? [] : this.state.selectedMember2,
            package_belong_to: selectedBusinesses.length > 1 ? [] : this.state.package_belong_to
        }, () => {
            this.handleDisabled();
        });
    };

    selectAssociates = (name, username) => {
        const userObject = {
            name: username[1].Value,
            id: username[0].Value,
        };
        let { selectedAssociates } = this.state;
        const index = selectedAssociates.map(ids => ids.id).indexOf(userObject.id);
        if (index > -1) selectedAssociates.splice(index, 1);
        else selectedAssociates.push(userObject);
        this.setState(
            {
                package_scanned_by: [...selectedAssociates]
            },
            () => {
                this.handleDisabled();
            }
        );
        // setTimeout(() => {
        //     this.handleDisabled();
        // }, 30);
    };

    handleClearSearchText = async () => {
        await this.props.onClose();
        this.props.handleCancelSearch();
    }

    handleResetFilters = () => {
        this.setState({
            selectedFilter: 1,
            member_name: '',
            business_name: '',
            filter_option: [],
            action_status: [],
            scan_type: 'All',
            package_courier: 'selectAValue',
            building_id: -1,
            from_date: null,
            to_date: null,
            package_category: [],
            selectedMembers: [],
            selectedMembers2: [],
            selectedAssociates: [],
            selectedBusinesses: [],
            package_tracking_number: '',
            package_scanned_by: [],
            package_belong_to: [],
            package_picked_by: [],
            package_receiver_state: '',
            package_receiver_city: '',
            package_receiver_zip: '',
            package_sender_city: '',
            package_sender_state: '',
            package_sender_zip: '',
            disabled: false,
            showError: false,
            key: '',
            associatesSearch: '',
            list: [],
            listAssociates: [],
            error: null,
            notFound: false,
            notFoundAssociates: false,
            Memberloading: false,
            memberAnchor: null,
            appliedFilters: [],
            package_label_id: [],
            package_location_id: [],
            // new added for bug#20790
            package_businesses: [],
            membersSearch2: '',
            businessesSearch: '',
            listMembers2: [],
            listBusinesses: [],
            notFoundMember2: false,
            notFoundBusinesses: false,
            BusinessesLoading: false,
            loadingLocations: true,
            locations: [],
            errorLocations: null,
            // basicSearch: '',
            // fulltextSearch: ''
        });
    }

    handleSearchChange = () => e => {
        const { filterValue } = this.props;
        const value = e.target.value;
        this.setState({
            basicSearch: filterValue.value === 0 ? value : this.state.basicSearch,
            fulltextSearch: filterValue.value === 1 ? value : this.state.fulltextSearch,
        });
    };

    handleClearSearch = () => {
        this.setState({
            basicSearch: '',
            fulltextSearch: '',
        });
    }

    handleStartSearch = async () => {
        await this.props.onClose();
        this.props.startSearch();
    }

    handleLabel = (name, item) => {
        if (name === 'label') {
            const { package_label_id } = this.state;
            const index = package_label_id.map(ids => ids.id).indexOf(item.id);
            if (index > -1) package_label_id.splice(index, 1);
            else package_label_id.push(item);
            this.setState(
                {
                    package_label_id: [...package_label_id]
                },
                () => {
                    this.handleDisabled();
                }
            );
        } else {
            const { package_location_id } = this.state;
            const index = package_location_id.map(ids => ids.id).indexOf(item.id);
            if (index > -1) package_location_id.splice(index, 1);
            else package_location_id.push(item);
            this.setState(
                {
                    package_location_id: [...package_location_id]
                },
                () => {
                    this.handleDisabled();
                }
            );
        }
    }

    render() {
        let {
            t,
            match,
            style,
            // module,
            classes,
            userRole,
            // loading,
            building,
            buildings,
            // fullScreen,
            // basicSearch,
            filterValue,
            // fulltextSearch,
            advancedSearchAnchor,
            // handleSearchFieldChange,
            dynamicLabelsData
        } = this.props;
        let {
            building_id,
            package_courier,
            member_name,
            scan_type,
            filter_option,
            action_status,
            from_date,
            to_date,
            showError,
            package_category,
            // package_tracking_number,
            package_scanned_by,
            package_belong_to,
            package_picked_by,
            package_businesses,
            package_receiver_state,
            package_receiver_city,
            package_receiver_zip,
            package_sender_city,
            package_sender_state,
            package_sender_zip,
            disabled,
            // showError,
            key,
            associatesSearch,
            membersSearch2,
            businessesSearch,
            list,
            listAssociates,
            listMembers2,
            listBusinesses,
            Associatesloading,
            MembersLoading2,
            notFound,
            notFoundAssociates,
            notFoundMember2,
            notFoundBusinesses,
            error,
            Memberloading,
            BusinessesLoading,
            selectedMembers,
            selectedMembers2,
            selectedBusinesses,
            selectedAssociates,
            selectedFilter,
            appliedFilters,
            basicSearch,
            fulltextSearch,
            package_label_id,
            package_location_id
            // loadingLocations,
            // locations,
            // errorLocations,
        } = this.state;
        // const labelStyle = { fontSize: 13 };
        let advancedSearchFilters = voAdvanceSearchOptions;
        buildings = convertTenantsIntoBuildings(buildings);
        buildings = [{
            building_name: t('associates.selectBuilding'),
            building_id: -1
        }, ...buildings];

        // <MenuItem className="width-170" value="-1">
        //     {t('associates.selectBuilding')}
        // </MenuItem>
        // if (module !== 'member_all_packages' && module !== 'member_packages') {
        //     advancedSearchFilters = voAdvanceSearchOptions.slice(0, voAdvanceSearchOptions.length - 1);
        // }
        const buildingIsSelected = building && building.length !== 0;
        // const filteredBuilding = buildingIsSelected
        //     ? buildings.filter(item => item.building_id === building[0].building_id)
        //     : (building_id && building_id !== -1)
        //         ? buildings.filter(item => item.building_id === building_id)
        //         : [];
        // const selectedBuildingCode = ((buildingIsSelected && filteredBuilding.length !== 0) || (building_id && building_id !== -1))
        //     ? filteredBuilding[0].building_code
        //     : '';
        const membersNames = selectedMembers.map(m => m.name).join(', ');
        const associatesName = selectedAssociates.length > 0 ? selectedAssociates.map(item => item.name).join(', ') : '';
        const membersNames2 = selectedMembers2.map(m => m.name).join(', ');
        const businessesNames = selectedBusinesses.map(m => m.name).join(', ');
        const couriresunsorted = ENV.includes('cn') ? couriersChinese : couriersGlobal;
        const couriersSorted = couriresunsorted.sort((item1, item2) => {
            if (item1.name > item2.name) return 1;
            if (item2.name > item1.name) return -1;
            return 0;
        });
        const couriers = ENV.includes('cn') ? couriersChinese : [...couriersGlobalTop, ...couriersSorted];
        let names = [...PackageLabels];
        // if (selectedBuildingCode === 'DVU01') {
        //     names = [...names, ...PackageLabelsExtended];
        // }
        // const isScanTypeVisible = (buildingIsSelected || building_id) && (selectedBuildingCode === 'GCC01');
        const buildingSelectorValue = buildings.length === 1
            ? buildings[0].building_id
            : building_id;
        const labels = dynamicLabelsData && dynamicLabelsData.length && dynamicLabelsData[0] && dynamicLabelsData[0].label && dynamicLabelsData[0].label;
        const locations = dynamicLabelsData && dynamicLabelsData[0].location && dynamicLabelsData[0].location;
        const selectedLabels = package_label_id.map(m => {
            let value = m.value;
            if (!value) {
                if (labels.length) {
                    value = labels.find(a => a.id === m.id).value;
                }
            }
            return value;
        }).join(', ');
        const selectedLocation = package_location_id.map(m => m.value).join(', ');
        return (
            <Fragment>
                {showError && (
                    <Alert
                        variant="error"
                        open
                        onClose={() => this.setState({ showError: false })}
                        message={t('signin.invalidDates')}
                    />
                )}
                <ClickAwayListener onClickAway={this.handleClickAway}>
                    <Menu
                        anchorEl={advancedSearchAnchor}
                        open={Boolean(advancedSearchAnchor)}
                        onClose={this.handleClickAway}
                        PaperProps={{
                            style: {
                                height: 'auto',
                                width: window.screen.width < 500 ? '90%' : '50%',
                                // width: '100%',
                                overflowY: 'hidden',
                                borderRadius: 10,
                                boxShadow: 'rgba(0, 0, 0, 0) 0px 6px 7px -4px, rgba(0, 0, 0, 0) 0px 11px 15px 1px, rgba(0, 0, 0, 0.12) 0px 4px 20px 3px',
                            },
                        }}
                        style={{ top: -7 }}
                    >
                        <Margin bottom={5} left={10} right={10}>
                            <div
                                className="topbar__search width-100 margin-0px"
                                style={{ display: 'flex', justifyContent: 'space-between' }}
                            >
                                <div
                                    // onSubmit={e => {
                                    //     e.preventDefault();
                                    //     if (!loading) {
                                    //         this.handleStartSearch();
                                    //     }
                                    // }}
                                    style={{
                                        width: '80%',
                                        backgroundColor: '#f7f7f7',
                                        borderRadius: '5px'
                                    }}
                                >
                                    <InputBase
                                        aria-haspopup="true"
                                        className="topbar__search-field topbar__search-field--open search max-height-32"
                                        inputProps={{
                                            style: {
                                                cursor: 'pointer',
                                                width: '100%',
                                                fontSize: 14,
                                                textOverflow:
                                                    window.screen.width < 400
                                                        ? 'ellipsis'
                                                        : 'initial',
                                                overflow: 'hidden',
                                                // backgroundColor: 'rgba(0, 0, 0, 0.67)',
                                            },
                                            maxlength: 100,
                                        }}
                                        placeholder={filterValue.value === 0 ? t('package.enterNameIdStatus') : t('package.enterTrackingId')}
                                        value={filterValue.value === 0 ? basicSearch : fulltextSearch}
                                        onChange={this.handleSearchChange(filterValue === 0 ? 'basicSearch' : 'fulltextSearch')}
                                        // startAdornment={
                                        //     <Tooltip
                                        //         title="Please enter atleast two characters"
                                        //         open={
                                        //             (filter.value === 0 &&
                                        //                 basicSearch.length < 2 &&
                                        //                 basicSearch !== '') ||
                                        //             (filter.value === 1 &&
                                        //                 fulltextSearch.length < 2 &&
                                        //                 fulltextSearch !== '')
                                        //         }
                                        //     />
                                        // }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    style={{
                                                        padding: 14,
                                                        display: (filterValue.value === 0 && basicSearch.length > 0) ||
                                                            (filterValue.value === 1 && fulltextSearch.length > 0) ? 'flex' : 'none',
                                                    }}
                                                    onClick={this.handleClearSearch}
                                                >
                                                    <Cancel className="cancel-icon" />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                <MUIButton
                                    to="/mp-items/all_items"
                                    className="margin-left-10p padding-0 blue"
                                    onClick={() => {
                                        this.setState({
                                            basicSearch: filterValue.value === 0 ? '' : this.state.basicSearch,
                                            fulltextSearch: filterValue.value === 1 ? '' : this.state.fulltextSearch,
                                        }, () => this.handleResetFilters());
                                    }}
                                >
                                    {t('email.reset')}
                                </MUIButton>
                            </div>
                        </Margin>
                        {
                            filterValue.value === 0 &&
                            <Fragment>
                                <div
                                    className="filters-div"
                                    style={{
                                        position: window.screen.width > 1279 ? 'initial' : '',
                                        height: window.screen.width > 1279 ? '70px' : ''
                                    }}>
                                    <div
                                        className={`child-div ${window.screen.width > 1279 && 'childDivWidth'}`}
                                    // style={{
                                    //     width: window.screen.width > 1024 ? '501px' : '',

                                    // }}
                                    >
                                        {
                                            advancedSearchFilters.map((item, index) => {
                                                const disabledElemt =
                                                    index === 1
                                                        ? scan_type === 'SCAN-OUT'
                                                            ? true
                                                            : (filter_option.length !== 0 && filter_option.indexOf('collected') === -1)
                                                        : index === 7
                                                            ? selectedBusinesses && selectedBusinesses.length > 1
                                                            : false;
                                                // const disabledElemt =
                                                //     index === 1
                                                //         ? isScanTypeVisible
                                                //             ? scan_type === 'SCAN-OUT'
                                                //             : (filter_option.length !== 0 && filter_option.indexOf('collected') === -1)
                                                //         : false;
                                                const existsInApplied = [...appliedFilters].filter(tempItem => tempItem === item.label);

                                                const currentElem = (
                                                    window.screen.width > 1279 ? (
                                                        <p
                                                            role="presentation"
                                                            // className={index === 8 ? 'noMarginLeft' : index >= 8 ? 'marginTop' : ''}
                                                            onClick={disabledElemt ? {} : () => this.handleOnFilterClick(index)}
                                                            style={{
                                                                marginTop: 10,
                                                                cursor: disabledElemt ? 'default' : 'pointer',
                                                                minWidth: item.minWidth,
                                                                backgroundColor: existsInApplied.length !== 0
                                                                    ? '#9cefaf70'
                                                                    : selectedFilter === index && 'rgba(0, 139, 255, 0.16)',
                                                                color: existsInApplied.length !== 0
                                                                    ? '#28843d'
                                                                    : selectedFilter === index && '#027aff',
                                                                opacity: disabledElemt && 0.5
                                                            }}> {t(item.label)} </p>
                                                    ) : (
                                                        <p
                                                            role="presentation"
                                                            onClick={disabledElemt ? {} : () => this.handleOnFilterClick(index)}
                                                            style={{
                                                                cursor: disabledElemt ? 'default' : 'pointer',
                                                                minWidth: item.minWidth,
                                                                backgroundColor: existsInApplied.length !== 0
                                                                    ? '#9cefaf70'
                                                                    : selectedFilter === index && 'rgba(0, 139, 255, 0.16)',
                                                                color: existsInApplied.length !== 0
                                                                    ? '#28843d'
                                                                    : selectedFilter === index && '#027aff',
                                                                opacity: disabledElemt && 0.5
                                                            }}> {t(item.label)} </p>
                                                    )
                                                    // <p
                                                    //     role="presentation"
                                                    //     onClick={disabledElemt ? {} : () => this.handleOnFilterClick(index)}
                                                    //     style={{
                                                    //         cursor: disabledElemt ? 'default' : 'pointer',
                                                    //         minWidth: item.minWidth,
                                                    //         backgroundColor: existsInApplied.length !== 0
                                                    //             ? '#9cefaf70'
                                                    //             : selectedFilter === index && 'rgba(0, 139, 255, 0.16)',
                                                    //         color: existsInApplied.length !== 0
                                                    //             ? '#28843d'
                                                    //             : selectedFilter === index && '#027aff',
                                                    //         opacity: disabledElemt && 0.5
                                                    //     }}> {t(item.label)} </p>
                                                );
                                                if (item.display) {
                                                    if (item.label === 'tables.scanType') {
                                                        // if ((buildingIsSelected || building_id) && (selectedBuildingCode === 'GCC01')) {
                                                        //     return currentElem;
                                                        // }
                                                        if (buildingIsSelected || building_id) {
                                                            return currentElem;
                                                        }
                                                        return <Fragment />;
                                                    } else if (item.label === 'package.scannedBy' && userRole === 'mailroom_supervisor') {
                                                        return <Fragment />;
                                                    } else {
                                                        return currentElem;
                                                    }
                                                }
                                                return <Fragment />;
                                            })
                                        }
                                        <p style={{ backgroundColor: 'white' }} />
                                    </div>
                                </div>
                                <div className="filter-view-container" style={{ height: window.screen.width > 1024 ? '80px' : '' }} >
                                    <List>
                                        {
                                            selectedFilter === 100 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{
                                                        ...style.flex.display,
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <CustomInput
                                                        className="select-search"
                                                        placeholder={t('package.typeFullOrPartialName')}
                                                        value={member_name}
                                                        onChange={this.handleInputChange('member_name')}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 0 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{
                                                        ...style.flex.display,
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel
                                                            className="input-label"
                                                            htmlFor="age-simple"
                                                        // shrink={buildings.length === 1
                                                        //     ? buildings[0].building_id
                                                        //     : building.length > 0
                                                        //         ? building[0].building_id
                                                        //         : building_id}
                                                        >
                                                            {buildings.length === 1 || building.length > 0
                                                                ? ''
                                                                : t('package.selectbuilding')}
                                                        </InputLabel>
                                                        <Select
                                                            className="select-search"
                                                            name="building_id"
                                                            value={!buildingSelectorValue ? building_id : buildingSelectorValue}
                                                            // value="-1"
                                                            // value={
                                                            //     buildings.length === 1
                                                            //         ? buildings[0].building_id
                                                            //         : building.length > 0
                                                            //             ? building[0].building_id
                                                            //             : building_id
                                                            // }
                                                            style={{
                                                                // pointerEvents:
                                                                //     match.params.module === 'mailroom_packages' ||
                                                                // match.params.module === 'business_packages' ||
                                                                // match.params.module === 'building_packages' ||
                                                                // match.params.module === 'member_packages' ||
                                                                // buildings.length === 1 ||
                                                                // building.length > 0 ? 'none' : 'auto',
                                                                color: '#a6a6a6',
                                                            }}
                                                            onChange={this.handleBuilding}
                                                            input={
                                                                <CustomInput
                                                                    style={{
                                                                        pointerEvents:
                                                                            match.params.module === 'mailroom_packages' ||
                                                                                // match.params.module === 'business_packages' ||
                                                                                // match.params.module === 'building_packages' ||
                                                                                // match.params.module === 'member_packages' ||
                                                                                buildings.length === 1 ||
                                                                                building.length > 0 ? 'none' : 'auto',
                                                                        color:
                                                                            match.params.module === 'mailroom_packages' ||
                                                                                // match.params.module === 'business_packages' ||
                                                                                // match.params.module === 'building_packages' ||
                                                                                // match.params.module === 'member_packages' ||
                                                                                buildings.length === 1 ||
                                                                                building.length > 0 ? '#a6a6a6' : 'black',
                                                                    }}
                                                                    label={t('associate.selectBuilding')}
                                                                />
                                                            }
                                                        >
                                                            {(() =>
                                                                buildings.map(b => (
                                                                    <MenuItem className="width-170" value={b.building_id}>
                                                                        <Tooltip
                                                                            TransitionComponent={Zoom}
                                                                            title={b.building_name.length > 19 ? b.building_name : ''}
                                                                        >
                                                                            <div className="tooltip" />
                                                                        </Tooltip>
                                                                        <Truncate>{b.building_name}</Truncate>
                                                                    </MenuItem>
                                                                )))()}
                                                        </Select>
                                                    </FormControl>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 1 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{
                                                        ...style.flex.display,
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel className="input-label" htmlFor="age-simple">
                                                            {t('package.pickedupBy')}
                                                        </InputLabel>

                                                        <Select
                                                            value={membersNames}
                                                            renderValue={() => membersNames}
                                                            className="select-search"
                                                            input={
                                                                <CustomInput
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            <Margin bottom={5}>
                                                                <form
                                                                    onSubmit={e => {
                                                                        e.preventDefault();
                                                                        if (!Memberloading) {
                                                                            this.startSearching();
                                                                        }
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            height: 300,
                                                                            overflowY: Memberloading ? 'hidden' : 'auto',
                                                                            padding: 8,
                                                                            overflowX: 'hidden',
                                                                            width: 220,
                                                                        }}
                                                                    >
                                                                        <InputBase
                                                                            autoFocus
                                                                            aria-haspopup="true"
                                                                            className="search topbar__search-field topbar__search-field--open grey font-size-12"
                                                                            value={key}
                                                                            placeholder={t('search.Searchmember')}
                                                                            onChange={this.handleChange}
                                                                            inputProps={{ maxlength: 100 }}
                                                                            startAdornment={<SearchOutlined className="font-size-14" />}
                                                                        />
                                                                        {(Memberloading || error) && (
                                                                            <AlignCenter style={{ height: 320 }}>
                                                                                <VerticallyCenter>
                                                                                    {Memberloading && (
                                                                                        <div>
                                                                                            <CircularProgress className="blue" size={15} />{' '}
                                                                                            {` ${t('search.searching')}`}
                                                                                        </div>
                                                                                    )}
                                                                                    {error && error}
                                                                                </VerticallyCenter>
                                                                            </AlignCenter>
                                                                        )}
                                                                        {notFound && (
                                                                            <AlignCenter style={{ height: 350 }}>
                                                                                <VerticallyCenter>
                                                                                    {t('voItems.noVoFound')}
                                                                                </VerticallyCenter>
                                                                            </AlignCenter>
                                                                        )}
                                                                        {list.map(item => (
                                                                            <MenuItem
                                                                                className="list-item-text height-10px"
                                                                                onClick={() => this.selectMember('package_picked_by', item)}
                                                                            >
                                                                                <Checkbox
                                                                                    className="padding-5px blue"
                                                                                    checked={package_picked_by.indexOf(item.member_id) > -1}
                                                                                />
                                                                                <Tooltip title={item.name.length > 12 ? item.name : ''}>
                                                                                    <ListItemText>
                                                                                        <div className="truncate-option">
                                                                                            {item.name}{' '}
                                                                                        </div>
                                                                                    </ListItemText>
                                                                                </Tooltip>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </div>
                                                                </form>
                                                            </Margin>
                                                            <div className="align-center">
                                                                <BlueButton
                                                                    disabled={
                                                                        Memberloading || selectedMembers.length === 0
                                                                    }
                                                                    onClick={(Memberloading || selectedMembers.length === 0) ? {} : this.closeMemberList}
                                                                    height={35}
                                                                    width={120}
                                                                    autoFocus
                                                                    // type="submit"
                                                                    style={{
                                                                        backgroundColor:
                                                                            Memberloading || selectedMembers.length === 0
                                                                                ? '#c1c1c1'
                                                                                : '#027AFF',
                                                                        color: 'white',
                                                                        marginRight: window.screen.width < 330 ? 2 : 0,
                                                                        pointerEvents: (Memberloading || selectedMembers.length === 0) && 'none'
                                                                    }}
                                                                >
                                                                    {t('package.clearFilter')}
                                                                </BlueButton>
                                                            </div>
                                                        </Select>
                                                    </FormControl>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 2 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{
                                                        ...style.flex.display,
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel className="input-label" htmlFor="age-simple">
                                                            {t('package.scannedBy')}
                                                        </InputLabel>

                                                        <Select
                                                            value={associatesName}
                                                            renderValue={() => associatesName}
                                                            className="select-search"
                                                            input={
                                                                <CustomInput
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            <Margin bottom={5}>
                                                                <form
                                                                    onSubmit={e => {
                                                                        e.preventDefault();
                                                                        if (!Associatesloading) {
                                                                            this.startSearchingAssociates();
                                                                        }
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            height: 300,
                                                                            overflowY: Associatesloading ? 'hidden' : 'auto',
                                                                            padding: 8,
                                                                            overflowX: 'hidden',
                                                                            width: 220,
                                                                        }}
                                                                    >
                                                                        <InputBase
                                                                            autoFocus
                                                                            aria-haspopup="true"
                                                                            className="search topbar__search-field topbar__search-field--open grey font-size-12"
                                                                            value={associatesSearch}
                                                                            placeholder={t('search.SearchUser')}
                                                                            onChange={this.handleChangeAssociates}
                                                                            inputProps={{ maxlength: 100 }}
                                                                            startAdornment={<SearchOutlined className="font-size-14" />}
                                                                        />
                                                                        {(Associatesloading || error) && (
                                                                            <AlignCenter style={{ height: 320 }}>
                                                                                <VerticallyCenter>
                                                                                    {Associatesloading && (
                                                                                        <div>
                                                                                            <CircularProgress className="blue" size={15} />{' '}
                                                                                            {` ${t('search.searching')}`}
                                                                                        </div>
                                                                                    )}
                                                                                    {error && error}
                                                                                </VerticallyCenter>
                                                                            </AlignCenter>
                                                                        )}
                                                                        {notFoundAssociates && (
                                                                            <AlignCenter style={{ height: 350 }}>
                                                                                <VerticallyCenter>
                                                                                    {t('members.noUsersFound')}
                                                                                </VerticallyCenter>
                                                                            </AlignCenter>
                                                                        )}
                                                                        {listAssociates.map(item => (
                                                                            <MenuItem
                                                                                className="list-item-text height-10px"
                                                                                onClick={() => this.selectAssociates('package_selected_by', item.Attributes)}
                                                                            >
                                                                                <Checkbox
                                                                                    className="padding-5px blue"
                                                                                    // checked={[...package_scanned_by].filter(currentItem => currentItem.Attributes[0].Value === item.Attributes[0].Value).length !== 0}
                                                                                    checked={package_scanned_by.map(ids => ids.id).indexOf(item.Attributes[0].Value) > -1}
                                                                                />
                                                                                <Tooltip title={item.Attributes[1].Value.length > 12 ? item.Attributes[1].Value : ''}>
                                                                                    <ListItemText>
                                                                                        <div className="truncate-option">
                                                                                            {item.Attributes[1].Value}
                                                                                        </div>
                                                                                    </ListItemText>
                                                                                </Tooltip>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </div>
                                                                </form>
                                                            </Margin>
                                                            <div className="align-center">
                                                                <BlueButton
                                                                    disabled={
                                                                        Associatesloading || selectedAssociates.length === 0
                                                                    }
                                                                    onClick={this.closeAssociatesList}
                                                                    height={35}
                                                                    width={120}
                                                                    autoFocus
                                                                    type="submit"
                                                                    style={{
                                                                        backgroundColor:
                                                                            Associatesloading || selectedAssociates.length === 0
                                                                                ? '#c1c1c1'
                                                                                : '#027AFF',
                                                                        color: 'white',
                                                                        marginRight: window.screen.width < 330 ? 2 : 0,
                                                                    }}
                                                                >
                                                                    {t('package.clearFilter')}
                                                                </BlueButton>
                                                            </div>
                                                        </Select>
                                                    </FormControl>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 3 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{
                                                        ...style.flex.display,
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel className="input-label" htmlFor="age-simple">
                                                            {t('package.selectScanType')}
                                                        </InputLabel>
                                                        <Select
                                                            name="courier"
                                                            value={scan_type}
                                                            onChange={this.handleScanType}
                                                            className="select-search"
                                                            input={<CustomInput className="black" />}
                                                        >
                                                            <MenuItem value="All">{t('tables.all')}</MenuItem>
                                                            <MenuItem value="SCAN-IN">{t('tables.scanIn')}</MenuItem>
                                                            <MenuItem value="SCAN-OUT">{t('tables.scanOut')}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 4 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{
                                                        ...style.flex.display,
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel className="input-label" htmlFor="age-simple">
                                                            {t('package.selectStatus')}
                                                        </InputLabel>
                                                        <Select
                                                            multiple
                                                            value={filter_option}
                                                            onChange={this.handleStatus}
                                                            className="select-search"
                                                            input={<CustomInput className="black" />}
                                                            renderValue={selected => selected.map(item => t(`tables.${item}`)).join(', ')}
                                                        >
                                                            {/* <MenuItem style={{ display: 'none' }} className="display-none" value="scanned">{t('package.scanned')}</MenuItem>
                                                            <MenuItem style={{ display: 'none' }} className="display-none" value="outstanding">{t('common.outstanding')}</MenuItem> */}
                                                            <MenuItem value="overdue" disabled={package_picked_by.length > 0}>
                                                                <Checkbox className="blue padding-0" checked={filter_option.indexOf('overdue') > -1} />
                                                                {t('tables.overdue')}
                                                            </MenuItem>
                                                            <MenuItem value="holding" disabled={package_picked_by.length > 0}>
                                                                <Checkbox className="blue padding-0" checked={filter_option.indexOf('holding') > -1} />
                                                                {t('tables.holding')}
                                                            </MenuItem>
                                                            <MenuItem value="collected" >
                                                                <Checkbox className="blue padding-0" checked={filter_option.indexOf('collected') > -1} />
                                                                {'Collected'}
                                                            </MenuItem>
                                                            <MenuItem value="dispatched" >
                                                                <Checkbox className="blue padding-0" checked={filter_option.indexOf('dispatched') > -1} />
                                                                {'Dispatched'}
                                                            </MenuItem>
                                                            <MenuItem value="scanned" >
                                                                <Checkbox className="blue padding-0" checked={filter_option.indexOf('scanned') > -1} />
                                                                {t('tables.scanned')}
                                                            </MenuItem>
                                                            <MenuItem value="deleted" >
                                                                <Checkbox className="blue padding-0" checked={filter_option.indexOf('deleted') > -1} />
                                                                {t('tables.deleted')}
                                                            </MenuItem>
                                                            <MenuItem value="discarded" >
                                                                <Checkbox className="blue padding-0" checked={filter_option.indexOf('discarded') > -1} />
                                                                {t('tables.discarded')}
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 9 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{
                                                        ...style.flex.display,
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel className="input-label" htmlFor="age-simple">
                                                            {t('tables.actionStatus')}
                                                        </InputLabel>
                                                        <Select
                                                            multiple
                                                            value={action_status}
                                                            onChange={this.handleActionStatus}
                                                            className="select-search"
                                                            input={<CustomInput className="black" />}
                                                            renderValue={selected => selected.map(item => t(`tables.${item}`)).join(', ')}
                                                        >
                                                            <MenuItem value="scanSend" disabled={package_picked_by.length > 0}>
                                                                <Checkbox className="blue padding-0" checked={action_status.indexOf('scanSend') > -1} />
                                                                {t('tables.scanSend')}
                                                            </MenuItem>
                                                            <MenuItem value="forward">
                                                                <Checkbox className="blue padding-0" checked={action_status.indexOf('forward') > -1} />
                                                                {t('tables.forward')}
                                                            </MenuItem>
                                                            <MenuItem value="hold" >
                                                                <Checkbox className="blue padding-0" checked={action_status.indexOf('hold') > -1} />
                                                                {t('tables.hold')}
                                                            </MenuItem>
                                                            <MenuItem value="destroy" >
                                                                <Checkbox className="blue padding-0" checked={action_status.indexOf('destroy') > -1} />
                                                                {t('tables.destroy')}
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 5 &&
                                            <div className="check-in-dates-calendars">
                                                <ListItem disableGutters>
                                                    <ListItemSecondaryAction
                                                        style={{
                                                            ...style.flex.display,
                                                            position: 'relative',
                                                            width: window.screen.width < 426 ? '70%' : '40%',
                                                        }}
                                                    >
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <MuiThemeProvider theme={materialTheme}>
                                                                <Grid
                                                                    container
                                                                    justify="space-around"
                                                                    style={{
                                                                        borderBottom: '1px solid #a6a6a6',
                                                                    }}
                                                                    className="select-search"
                                                                >
                                                                    <div className="align-items-center margin-m6px">
                                                                        <Calendar
                                                                            style={{
                                                                                fontSize: window.screen.width < 770 ? 12 : 14,
                                                                                color: '#a6a6a6',
                                                                                marginTop: 6,
                                                                                marginRight: 8,
                                                                            }}
                                                                        />
                                                                        <DatePicker
                                                                            clearable
                                                                            margin="normal"
                                                                            format="MM/dd/yyyy"
                                                                            views={['year', 'month', 'day']}
                                                                            value={from_date}
                                                                            onChange={this.handleDateChange('from_date')}
                                                                            placeholder={t('package.selectCheckinDate')}
                                                                            // minDate={getDateInAYearDifference()}
                                                                            maxDate={getTodayEnd()}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </MuiThemeProvider>
                                                        </MuiPickersUtilsProvider>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <div style={{ marginLeft: 20 }} />
                                                <ListItem disableGutters >
                                                    <ListItemSecondaryAction
                                                        style={{
                                                            ...style.flex.display,
                                                            position: 'relative',
                                                            width: window.screen.width < 426 ? '60%' : '33%',
                                                        }}
                                                    >
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <MuiThemeProvider theme={materialTheme}>
                                                                <Grid
                                                                    container
                                                                    style={{ borderBottom: '1px solid #a6a6a6' }}
                                                                    className="select-search"
                                                                    justify="space-around"
                                                                >
                                                                    <div className="align-items-center margin-m6px">
                                                                        <Calendar
                                                                            style={{
                                                                                fontSize: window.screen.width < 770 ? 12 : 14,
                                                                                color: '#a6a6a6',
                                                                                marginTop: 6,
                                                                                marginRight: 8,
                                                                            }}
                                                                        />
                                                                        <DatePicker
                                                                            clearable
                                                                            margin="normal"
                                                                            format="MM/dd/yyyy"
                                                                            views={['year', 'month', 'day']}
                                                                            value={to_date}
                                                                            onChange={this.handleDateChange('to_date')}
                                                                            placeholder={t('package.selectCheckoutDate')}
                                                                            // minDate={moment(from_date)}
                                                                            // minDate={getDateInAYearDifference()}
                                                                            maxDate={getTodayEnd()}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </MuiThemeProvider>
                                                        </MuiPickersUtilsProvider>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </div>
                                        }
                                        {
                                            selectedFilter === 100 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{
                                                        ...style.flex.display,
                                                        position: 'relative',
                                                        width: window.screen.width < 426 ? '60%' : '33%',
                                                    }}
                                                >
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <MuiThemeProvider theme={materialTheme}>
                                                            <Grid
                                                                container
                                                                style={{ borderBottom: '1px solid #a6a6a6' }}
                                                                className="select-search"
                                                                justify="space-around"
                                                            >
                                                                <div className="align-items-center margin-m6px">
                                                                    <Calendar
                                                                        style={{
                                                                            fontSize: window.screen.width < 770 ? 12 : 14,
                                                                            color: '#a6a6a6',
                                                                            marginTop: 6,
                                                                            marginRight: 8,
                                                                        }}
                                                                    />
                                                                    <DatePicker
                                                                        clearable
                                                                        margin="normal"
                                                                        format="MM/dd/yyyy"
                                                                        views={['year', 'month', 'day']}
                                                                        value={to_date}
                                                                        onChange={this.handleDateChange('to_date')}
                                                                        placeholder={t('package.selectCheckoutDate')}
                                                                        minDate={moment(from_date)}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        </MuiThemeProvider>
                                                    </MuiPickersUtilsProvider>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 100 &&
                                            <ListItem
                                                disableGutters
                                                style={{
                                                    paddingLeft: 0,
                                                    paddingTop:
                                                        package_category.length > 0 && package_category.length < 2
                                                            ? window.screen.width > 770 && window.screen.width < 2600
                                                                ? 45
                                                                : 45
                                                            : package_category.length > 1 &&
                                                                package_category.length < 5
                                                                ? window.screen.width > 770 && window.screen.width < 2600
                                                                    ? 40
                                                                    : 100
                                                                : package_category.length > 4
                                                                    ? window.screen.width > 770 && window.screen.width < 2600
                                                                        ? 70
                                                                        : 170
                                                                    : '',
                                                    paddingBottom:
                                                        package_category.length > 0 && package_category.length < 2
                                                            ? window.screen.width > 770 && window.screen.width < 2600
                                                                ? 45
                                                                : 45
                                                            : package_category.length > 1 &&
                                                                package_category.length < 5
                                                                ? window.screen.width > 770 && window.screen.width < 2600
                                                                    ? 40
                                                                    : 100
                                                                : package_category.length > 4
                                                                    ? window.screen.width > 770 && window.screen.width < 2600
                                                                        ? 70
                                                                        : 170
                                                                    : '17',
                                                }}
                                            >
                                                <ListItemSecondaryAction
                                                    style={{ ...style.flex.display, position: 'relative' }}
                                                >
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel className="input-label" htmlFor="age-simple">
                                                            {t('package.selectlabel')}
                                                        </InputLabel>
                                                        <Select
                                                            multiple
                                                            style={{
                                                                minWidth:
                                                                    window.screen.width < 330
                                                                        ? package_category.length > 0
                                                                            ? 185
                                                                            : 135
                                                                        : window.screen.width > 330 &&
                                                                            window.screen.width < 450
                                                                            ? package_category.length > 0
                                                                                ? 175
                                                                                : 135
                                                                            : window.screen.width > 450 &&
                                                                                window.screen.width < 770
                                                                                ? package_category.length > 0
                                                                                    ? 175
                                                                                    : 150
                                                                                : package_category.length > 1 &&
                                                                                    package_category.length < 3
                                                                                    ? window.screen.width > 770 &&
                                                                                        window.screen.width < 2600
                                                                                        ? 300
                                                                                        : 200
                                                                                    : package_category.length > 2 &&
                                                                                        package_category.length < 5
                                                                                        ? window.screen.width > 770 &&
                                                                                            window.screen.width < 2600
                                                                                            ? 400
                                                                                            : 200
                                                                                        : package_category.length > 4
                                                                                            ? window.screen.width > 770 &&
                                                                                                window.screen.width < 2600
                                                                                                ? 450
                                                                                                : 200
                                                                                            : package_category.length < 1
                                                                                                ? window.screen.width > 770 &&
                                                                                                    window.screen.width < 2600
                                                                                                    ? 200
                                                                                                    : 200
                                                                                                : 200,

                                                                maxWidth:
                                                                    window.screen.width < 330
                                                                        ? package_category.length > 0
                                                                            ? 185
                                                                            : 135
                                                                        : window.screen.width > 330 &&
                                                                            window.screen.width < 450
                                                                            ? package_category.length > 0
                                                                                ? 180
                                                                                : 135
                                                                            : window.screen.width > 450 &&
                                                                                window.screen.width < 770
                                                                                ? package_category.length > 0
                                                                                    ? 180
                                                                                    : 150
                                                                                : package_category.length > 1 &&
                                                                                    package_category.length < 3
                                                                                    ? window.screen.width > 770 &&
                                                                                        window.screen.width < 2600
                                                                                        ? 300
                                                                                        : 200
                                                                                    : package_category.length > 2 &&
                                                                                        package_category.length < 5
                                                                                        ? window.screen.width > 770 &&
                                                                                            window.screen.width < 2600
                                                                                            ? 400
                                                                                            : 200
                                                                                        : package_category.length > 4
                                                                                            ? window.screen.width > 770 &&
                                                                                                window.screen.width < 2600
                                                                                                ? 450
                                                                                                : 200
                                                                                            : package_category.length < 1
                                                                                                ? window.screen.width > 770 &&
                                                                                                    window.screen.width < 2600
                                                                                                    ? 200
                                                                                                    : 200
                                                                                                : 200,
                                                            }}
                                                            value={package_category}
                                                            onChange={this.handleInputChange('package_category')}
                                                            input={<CustomInput className="black" />}
                                                            renderValue={selected => (
                                                                <div className={classes.chips}>
                                                                    {selected.map((value, index) => (
                                                                        <Chip
                                                                            key={value.id}
                                                                            label={value.name}
                                                                            className={classes.chip}
                                                                            clickable
                                                                            onDelete={() => this.handleDelete(index)}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            )}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {names.map(name => (
                                                                <MenuItem
                                                                    key={name}
                                                                    value={name}
                                                                    style={{
                                                                        fontWeight:
                                                                            this.state.member_name.indexOf(name) === -1
                                                                                ? 'normal'
                                                                                : 'bold',
                                                                    }}
                                                                >
                                                                    {name.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 6 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{ ...style.flex.display, position: 'relative' }}
                                                >
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel className="input-label" htmlFor="age-simple">
                                                            {t('package.selectcarrier')}
                                                        </InputLabel>
                                                        <Select
                                                            name="courier"
                                                            value={package_courier}
                                                            onChange={this.handleCourier}
                                                            className="select-search"
                                                            input={<CustomInput className="black" />}
                                                        >
                                                            {couriers.map(item => (
                                                                <MenuItem value={item.id}>{item.name === 'Please Select Carrier' ? t('package.selectCourier') : item.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 7 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{
                                                        ...style.flex.display,
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel className="input-label" htmlFor="age-simple">
                                                            {`${t('voItems.voMember')} ${t('sidebar.packages')}`}
                                                        </InputLabel>

                                                        <Select
                                                            value={membersNames2}
                                                            renderValue={() => membersNames2}
                                                            className="select-search"
                                                            input={
                                                                <CustomInput
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            <Margin bottom={5}>
                                                                <form
                                                                    onSubmit={e => {
                                                                        e.preventDefault();
                                                                        if (!MembersLoading2) {
                                                                            this.startSearchingMembers2();
                                                                        }
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            height: 300,
                                                                            overflowY: MembersLoading2 ? 'hidden' : 'auto',
                                                                            padding: 8,
                                                                            overflowX: 'hidden',
                                                                            width: 220,
                                                                        }}
                                                                    >
                                                                        <InputBase
                                                                            autoFocus
                                                                            aria-haspopup="true"
                                                                            className="search topbar__search-field topbar__search-field--open grey font-size-12"
                                                                            value={membersSearch2}
                                                                            placeholder={`${t('members.member')} ${t('sidebar.packages')}`}
                                                                            onChange={this.handleChangeMembers2}
                                                                            inputProps={{ maxlength: 100 }}
                                                                            startAdornment={<SearchOutlined className="font-size-14" />}
                                                                        />
                                                                        {(MembersLoading2 || error) && (
                                                                            <AlignCenter style={{ height: 320 }}>
                                                                                <VerticallyCenter>
                                                                                    {MembersLoading2 && (
                                                                                        <div>
                                                                                            <CircularProgress className="blue" size={15} />{' '}
                                                                                            {` ${t('search.searching')}`}
                                                                                        </div>
                                                                                    )}
                                                                                    {error && error}
                                                                                </VerticallyCenter>
                                                                            </AlignCenter>
                                                                        )}
                                                                        {notFoundMember2 && (
                                                                            <AlignCenter style={{ height: 350 }}>
                                                                                <VerticallyCenter>
                                                                                    {t('members.noMembersFound')}
                                                                                </VerticallyCenter>
                                                                            </AlignCenter>
                                                                        )}
                                                                        {listMembers2.map(item => (
                                                                            <MenuItem
                                                                                className="list-item-text height-10px"
                                                                                onClick={() => this.selectMember2('package_belong_to', item)}
                                                                            >
                                                                                <Checkbox
                                                                                    className="padding-5px blue"
                                                                                    checked={package_belong_to.indexOf(item.member_id) > -1}
                                                                                />
                                                                                <Tooltip title={item.name.length > 12 ? item.name : ''}>
                                                                                    <ListItemText>
                                                                                        <div className="truncate-option">
                                                                                            {item.name}{' '}
                                                                                        </div>
                                                                                    </ListItemText>
                                                                                </Tooltip>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </div>
                                                                </form>
                                                            </Margin>
                                                            <div className="align-center">
                                                                <BlueButton
                                                                    disabled={
                                                                        MembersLoading2 || selectedMembers2.length === 0
                                                                    }
                                                                    onClick={this.closeMember2List}
                                                                    height={35}
                                                                    width={120}
                                                                    autoFocus
                                                                    type="submit"
                                                                    style={{
                                                                        backgroundColor:
                                                                            MembersLoading2 || selectedMembers2.length === 0
                                                                                ? '#c1c1c1'
                                                                                : '#027AFF',
                                                                        color: 'white',
                                                                        marginRight: window.screen.width < 330 ? 2 : 0,
                                                                    }}
                                                                >
                                                                    {t('package.clearFilter')}
                                                                </BlueButton>
                                                            </div>
                                                        </Select>
                                                    </FormControl>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 8 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{
                                                        ...style.flex.display,
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel className="input-label" htmlFor="age-simple">
                                                            {`${t('tables.business')} ${t('sidebar.packages')}`}
                                                        </InputLabel>

                                                        <Select
                                                            value={businessesNames}
                                                            renderValue={() => businessesNames}
                                                            className="select-search"
                                                            input={
                                                                <CustomInput
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            <Margin bottom={5}>
                                                                <form
                                                                    onSubmit={e => {
                                                                        e.preventDefault();
                                                                        if (!BusinessesLoading) {
                                                                            this.startSearchingBusinesses();
                                                                        }
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            height: 300,
                                                                            overflowY: BusinessesLoading ? 'hidden' : 'auto',
                                                                            padding: 8,
                                                                            overflowX: 'hidden',
                                                                            width: 220,
                                                                        }}
                                                                    >
                                                                        <InputBase
                                                                            autoFocus
                                                                            aria-haspopup="true"
                                                                            className="search topbar__search-field topbar__search-field--open grey font-size-12"
                                                                            value={businessesSearch}
                                                                            placeholder={`${t('tables.business')} ${t('sidebar.packages')}`}
                                                                            onChange={this.handleChangeBusinesses}
                                                                            inputProps={{ maxlength: 100 }}
                                                                            startAdornment={<SearchOutlined className="font-size-14" />}
                                                                        />
                                                                        {(BusinessesLoading || error) && (
                                                                            <AlignCenter style={{ height: 320 }}>
                                                                                <VerticallyCenter>
                                                                                    {BusinessesLoading && (
                                                                                        <div>
                                                                                            <CircularProgress className="blue" size={15} />{' '}
                                                                                            {` ${t('search.searching')}`}
                                                                                        </div>
                                                                                    )}
                                                                                    {error && error}
                                                                                </VerticallyCenter>
                                                                            </AlignCenter>
                                                                        )}
                                                                        {notFoundBusinesses && (
                                                                            <AlignCenter style={{ height: 350 }}>
                                                                                <VerticallyCenter>
                                                                                    {t('tables.noBusiness')}
                                                                                </VerticallyCenter>
                                                                            </AlignCenter>
                                                                        )}
                                                                        {listBusinesses.map(item => (
                                                                            <MenuItem
                                                                                className="list-item-text height-10px"
                                                                                onClick={() => this.selectBusinesses('package_businesses', item)}
                                                                            >
                                                                                <Checkbox
                                                                                    className="padding-5px blue"
                                                                                    checked={package_businesses.indexOf(item.business_id) > -1}
                                                                                />
                                                                                <Tooltip title={item.name.length > 12 ? item.name : ''}>
                                                                                    <ListItemText>
                                                                                        <div className="truncate-option">
                                                                                            {item.name}{' '}
                                                                                        </div>
                                                                                    </ListItemText>
                                                                                </Tooltip>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </div>
                                                                </form>
                                                            </Margin>
                                                            <div className="align-center">
                                                                <BlueButton
                                                                    disabled={
                                                                        BusinessesLoading || selectedBusinesses.length === 0
                                                                    }
                                                                    onClick={this.closeBusinessesList}
                                                                    height={35}
                                                                    width={120}
                                                                    autoFocus
                                                                    type="submit"
                                                                    style={{
                                                                        backgroundColor:
                                                                            BusinessesLoading || selectedBusinesses.length === 0
                                                                                ? '#c1c1c1'
                                                                                : '#027AFF',
                                                                        color: 'white',
                                                                        marginRight: window.screen.width < 330 ? 2 : 0,
                                                                    }}
                                                                >
                                                                    {t('package.clearFilter')}
                                                                </BlueButton>
                                                            </div>
                                                        </Select>
                                                    </FormControl>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 100 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{ ...style.flex.display, position: 'relative' }}
                                                >
                                                    <div>
                                                        <Tooltip title={t('package.usOnly')}>
                                                            <InfoOutlined
                                                                style={{ fontSize: 16, marginRight: 8 }}
                                                                className={classes.iconHover}
                                                            />
                                                        </Tooltip>
                                                        <CustomInput
                                                            placeholder={t('package.typeCity')}
                                                            value={package_receiver_city}
                                                            className="select-search-info"
                                                            onChange={this.handleInputChange('package_receiver_city')}
                                                        />
                                                    </div>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 100 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{ ...style.flex.display, position: 'relative' }}
                                                >
                                                    <div>
                                                        <Tooltip title={t('package.usOnly')}>
                                                            <InfoOutlined
                                                                style={{ fontSize: 16, marginRight: 8 }}
                                                                className={classes.iconHover}
                                                            />
                                                        </Tooltip>
                                                        <CustomInput
                                                            placeholder={t('package.typeState')}
                                                            value={package_receiver_state}
                                                            className="select-search-info"
                                                            onChange={this.handleInputChange('package_receiver_state')}
                                                        />
                                                    </div>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 100 &&
                                            <ListItem className="width-190 truncated-heading" disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{ ...style.flex.display, position: 'relative' }}
                                                >
                                                    <div>
                                                        <Tooltip title={t('package.usOnly')}>
                                                            <InfoOutlined
                                                                style={{ fontSize: 16, marginRight: 8 }}
                                                                className={classes.iconHover}
                                                            />
                                                        </Tooltip>
                                                        <CustomInput
                                                            placeholder={t('package.typeZipcode')}
                                                            value={package_receiver_zip}
                                                            className="select-search-info"
                                                            onChange={this.handleInputChange('package_receiver_zip')}
                                                        />
                                                    </div>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 100 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{ ...style.flex.display, position: 'relative' }}
                                                >
                                                    <div>
                                                        <Tooltip title={t('package.usOnly')}>
                                                            <InfoOutlined
                                                                style={{ fontSize: 16, marginRight: 8 }}
                                                                className={classes.iconHover}
                                                            />
                                                        </Tooltip>
                                                        <CustomInput
                                                            placeholder={t('package.typeCity')}
                                                            value={package_sender_city}
                                                            className="select-search-info"
                                                            onChange={this.handleInputChange('package_sender_city')}
                                                        />
                                                    </div>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 100 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{ ...style.flex.display, position: 'relative' }}
                                                >
                                                    <div>
                                                        <Tooltip title={t('package.usOnly')}>
                                                            <InfoOutlined
                                                                style={{ fontSize: 16, marginRight: 8 }}
                                                                className={classes.iconHover}
                                                            />
                                                        </Tooltip>
                                                        <CustomInput
                                                            placeholder={t('package.typeState')}
                                                            value={package_sender_state}
                                                            className="select-search-info"
                                                            onChange={this.handleInputChange('package_sender_state')}
                                                        />
                                                    </div>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 100 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{ ...style.flex.display, position: 'relative' }}
                                                >
                                                    <div>
                                                        <Tooltip title={t('package.usOnly')}>
                                                            <InfoOutlined
                                                                style={{ fontSize: 16, marginRight: 8 }}
                                                                className={classes.iconHover}
                                                            />
                                                        </Tooltip>
                                                        <CustomInput
                                                            placeholder={t('package.typeZipcode')}
                                                            value={package_sender_zip}
                                                            className="select-search-info"
                                                            onChange={this.handleInputChange('package_sender_zip')}
                                                        />
                                                    </div>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 9 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{
                                                        ...style.flex.display,
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel className="input-label" htmlFor="age-simple">
                                                            {t('tables.label')}
                                                        </InputLabel>

                                                        <Select
                                                            value={selectedLabels}
                                                            renderValue={() => selectedLabels}
                                                            className="select-search"
                                                            input={
                                                                <CustomInput className="black" />
                                                            }
                                                        >
                                                            <Margin bottom={5}>
                                                                {labels && labels.length ? (
                                                                    labels.map((item) => (
                                                                        <MenuItem
                                                                            style={
                                                                                package_label_id.map(ids => ids.id).indexOf(item.id) > -1 ? {
                                                                                    backgroundColor: 'rgba(0, 0, 0, 0.14)'
                                                                                } : {}}
                                                                            onClick={() => this.handleLabel('label', { id: item.id, value: item.value })}
                                                                        >
                                                                            <Checkbox
                                                                                className="padding-5px blue"
                                                                                checked={package_label_id.map(ids => ids.id).indexOf(item.id) > -1}
                                                                            />
                                                                            <Tooltip title={item.value.length > 12 ? item.value : ''}>
                                                                                <ListItemText>
                                                                                    <div className="truncate-option">
                                                                                        {item.value}
                                                                                    </div>
                                                                                </ListItemText>
                                                                            </Tooltip>
                                                                        </MenuItem>
                                                                    ))
                                                                ) : (
                                                                    <MenuItem >
                                                                        {t('tables.noLabel')}
                                                                    </MenuItem>
                                                                )}
                                                            </Margin>
                                                        </Select>
                                                    </FormControl>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                        {
                                            selectedFilter === 10 &&
                                            <ListItem disableGutters>
                                                <ListItemSecondaryAction
                                                    style={{
                                                        ...style.flex.display,
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel className="input-label" htmlFor="age-simple">
                                                            {t('tables.locations')}
                                                        </InputLabel>
                                                        <Select
                                                            // multiple
                                                            value={selectedLocation}
                                                            // onChange={this.handleLocation}
                                                            className="select-search"
                                                            input={<CustomInput className="black" />}
                                                            renderValue={() => selectedLocation}
                                                        >
                                                            {locations && locations.length ? (
                                                                locations.map((item) => (
                                                                    <>
                                                                        <MenuItem
                                                                            style={
                                                                                package_location_id.map(ids => ids.id).indexOf(item.id) > -1 ? {
                                                                                    backgroundColor: 'rgba(0, 0, 0, 0.14)'
                                                                                } : {}}
                                                                            onClick={() => this.handleLabel('location', { id: item.id, value: item.value })}
                                                                        >
                                                                            <Checkbox
                                                                                className="padding-5px blue"
                                                                                checked={package_location_id.map(ids => ids.id).indexOf(item.id) > -1}
                                                                            />
                                                                            <Tooltip title={item.value.length > 12 ? item.value : ''}>
                                                                                <ListItemText>
                                                                                    <div className="truncate-option">
                                                                                        {item.value}
                                                                                    </div>
                                                                                </ListItemText>
                                                                            </Tooltip>
                                                                        </MenuItem>
                                                                    </>
                                                                ))
                                                            ) : (
                                                                <MenuItem >
                                                                    {t('tables.noLocation')}
                                                                </MenuItem>

                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        }
                                    </List>
                                </div>
                            </Fragment>
                        }
                        <div className="advnace-search-actions">
                            <BlueButton
                                onClick={this.handleClickAway}
                                height={35}
                                width={120}
                                autoFocus
                                type="submit"
                                style={{
                                    marginRight: window.screen.width < 330 ? 2 : 20,
                                }}
                            >
                                <b>{t('common.cancel')}</b>
                            </BlueButton>
                            <BlueButton
                                disabled={disabled}
                                onClick={this.handleFilter}
                                height={35}
                                width={120}
                                autoFocus
                                type="submit"
                                style={{
                                    backgroundColor: disabled ? '#c1c1c1' : '#027AFF',
                                    color: 'white',
                                    marginRight: window.screen.width < 330 ? 2 : 20,
                                }}
                            >
                                <b>{t('package.filter')}</b>
                            </BlueButton>
                        </div>
                    </Menu>
                </ClickAwayListener>
            </Fragment >
        );
    }
}

AdvancedSearch.propTypes = {
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    buildings: PropTypes.node.isRequired,
    style: PropTypes.node.isRequired,
    classes: PropTypes.node.isRequired,
    match: PropTypes.node.isRequired,
    filter: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    building: PropTypes.node.isRequired,
    clearAll: PropTypes.bool.isRequired,
    startSearch: PropTypes.func.isRequired,
    searchParams: PropTypes.node.isRequired,
    filterValue: PropTypes.string.isRequired,
    handleCancelSearch: PropTypes.func.isRequired,
    advancedSearchAnchor: PropTypes.node.isRequired,
    dynamicLabelsData: PropTypes.node.isRequired
};

const Search = (withStyles(styles, { withTheme: true })(AdvancedSearch));
const withBuildingSearch = connect(state => ({
    buildings: state.allBuildings,
    dynamicLabelsData: state.dynamicLabels
}))(Search);

export default withWidth()(withStyles(styles)(withBuildingSearch));
