/* eslint-disable quotes */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Prompt } from 'react-router';
// import { ArrowDropDown } from "@material-ui/icons";
import {
  // Typography,
  Divider,
  // CircularProgress,
  withWidth,
  withStyles,
  Paper,
  Table,
  Grid,
  Tooltip,
  IconButton,
  CircularProgress,
  // FormHelperText,
} from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  Margin,
  AlignCenter,
  SpaceBetween,
  VerticallyCenter,
} from "../../../UI/ResponsiveRelated";
import Button from "../../../UI/BlueButton";
import Switch from "../../../UI/Switch";
import {
  CustomTextField,
} from '../../../UI/Input';
import { PrefsStyles as styles } from "../../../../util/StyleConsts";
import ConfrimModal from './ConfrimModal';
// import { CustomAdornmentInput } from "../../../UI/Input";
// import Dropdown from "../../../UI/Dropdown/TimeDropdown";
// import ReminderFrequencyDropdown from "../../../UI/Dropdown/Dropdown";
// import { updatePackageNotificationPrefs } from "../../../../server/buildings";
import Alert from "../../../UI/Poper";

class CTAToggle extends Component {
  constructor(props) {
    super(props);
    // Create the ref
    // this.shippingRef = React.createRef();
    this.state = {
      isExpanded: true,
      shippingMethod: [{
        id: 0,
        value: '',
        building_id: this.props.match.params.id,
        type: 'cta-fwd'
      }],
      preferenceCTA: [],
      Lasterror: false,
      NewShipping: '',
      addNew: null,
      deletedShippings: [],
      openModel: false,
      shouldBlockNavigation: false,
      expanded: {
        FORWARD: true,
        DESTROY: true,
        SNAPSEND: true,
        HOLD: true,
      },
      addNewLbl: {
        FORWARD: null,
        DESTROY: null,
        SNAPSEND: null,
        HOLD: null,
      },
      shippinglbl: null,
      newShiplbl: {
        FORWARD: '',
        DESTROY: '',
        SNAPSEND: '',
        HOLD: '',
      },
      Lasterrors: {
        FORWARD: false,
        DESTROY: false,
        SNAPSEND: false,
        HOLD: false,
      }
    };
  }

  componentDidMount() {
    this.setPref([...this.props.preferenceCTA]);
    this.setLabels([...this.props.shippingLabels]);
    this.setShipLabels(this.props.shiplabels);
  }

  componentDidUpdate(prevProps) {
    if (this.props.preferenceCTA !== prevProps.preferenceCTA) this.setPref([...this.props.preferenceCTA]);
    if (this.props.shippingLabels !== prevProps.shippingLabels) this.setLabels([...this.props.shippingLabels]);
    if (this.props.shiplabels !== prevProps.shiplabels) this.setShipLabels(this.props.shiplabels);
    const index = Object.values(this.state.newShiplbl).findIndex(x => x !== '');
    if (index !== -1 && this.state.shouldBlockNavigation !== true) {
      this.setState({ shouldBlockNavigation: true });
    }

    if (prevProps.reload !== this.props.reload) {
      this.setState({
        shouldBlockNavigation: false
      });
      this.setPref([...this.props.preferenceCTA]);
      this.setLabels([...this.props.shippingLabels]);
      this.setShipLabels(this.props.shiplabels);
    }
    // if (prevProps.preferenceCTA !== this.state.preferenceCTA || prevProps.shippingMethod !== this.state.shippingMethod || prevState.deletedShippings !== this.state.deletedShippings) {
    //   if (this.state.shouldBlockNavigation !== true) this.setState({ shouldBlockNavigation: true });
    // } else {
    //   // eslint-disable-next-line
    //   if (this.state.shouldBlockNavigation !== false) this.setState({ shouldBlockNavigation: false });
    // }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
    this.setState({
      shouldBlockNavigation: false
    });
  }

  setPref = (pref) => this.setState({ preferenceCTA: pref });
  setLabels = (lbl) => this.setState({ shippingMethod: lbl });
  setShipLabels = (lbl) => this.setState({ shippinglbl: lbl });

  // setBlock = (lbl) => this.setState({ shouldBlockNavigation: lbl });


  // handleChange = (states, index) => e => {
  //   const { shippingMethod } = this.state;
  //   shippingMethod[index] = states;
  //   shippingMethod[index].value = e.target.value;
  //   this.setState({ shippingMethod });
  // }

  // handleChangeNewShipping = (e) => {
  //   this.setState({}) 
  // }

  handleAddNew = (e, item) => {
    e.preventDefault();
    //  if (this.state.addNew) {
    const {
      shippingMethod,
      // NewShipping,
      // addNew,
      addNewLbl,
      shippinglbl,
      newShiplbl,
      Lasterrors
    } = this.state;
    if (shippinglbl[item].length && (shippinglbl[item].filter(x => x.building_id !== null).length >= 5 || (shippinglbl[item].filter(x => x.building_id !== null).length === 4 && (addNewLbl[item] !== '' && addNewLbl[item] !== null)))) {
      // check for building level 5 label
      this.setState({
        fivelablError: true
      });
    } else {
      // eslint-disable-next-line
      if ((newShiplbl[item] === '' || Lasterrors[item]) && (addNewLbl[item] || (shippinglbl[item].length === 0 && addNewLbl[item] === null))) {
        this.setState(prevState => ({ Lasterrors: { ...prevState.Lasterrors, [item]: prevState.Lasterrors[item] ? prevState.Lasterrors[item] : true } }));
      } else {
        if (newShiplbl[item] !== '') {
          shippinglbl[item][shippinglbl[item].length] = {
            id: 0,
            value: newShiplbl[item],
            building_id: this.props.match.params.id,
            type: item === 'FORWARD' ? 'cta-fwd' : `cta-${item.toLowerCase()}`
          };
        }
        this.setState(prevState => ({
          shippingMethod,
          NewShipping: '',
          shouldBlockNavigation: true,
          addNewLbl: {
            ...prevState.addNewLbl,
            [item]: true
          },
          newShiplbl: {
            ...prevState.newShiplbl,
            [item]: ''
          },
          Lasterrors: {
            ...prevState.Lasterrors,
            [item]: false
          }
        }));
      }
    }
    //  } else this.setState({ addNew: true });
  }

  handleRemove = (index, item) => {
    const { deletedShippings, shippinglbl } = this.state;
    if (shippinglbl[item][index]) {
      const newArray = shippinglbl[item];
      const dellbl = newArray.splice(index, 1);
      if (dellbl[0].id !== 0) {
        deletedShippings.push(dellbl[0].id);
        this.setState({ shouldBlockNavigation: true });
      }
      this.setState(prevState => ({ shippinglbl: { ...prevState.shippinglbl, [item]: newArray }, newShiplbl: { ...prevState.newShiplbl, [item]: '' } }));
    } else {
      this.setState(prevState => ({
        addNewLbl: {
          ...prevState.addNewLbl,
          [item]: null
        },
        newShiplbl: {
          ...prevState.newShiplbl,
          [item]: ''
        },
        Lasterrors: {
          ...prevState.Lasterrors,
          [item]: false
        }
      }));
    }
  }

  handleToggle = (states, index) => {
    const { preferenceCTA } = this.state;
    preferenceCTA[index] = [states[0], !states[1]];
    if (!states[1] === false) {
      if (this.state.newShiplbl[states[0]] !== '') {
        this.state.shippinglbl[states[0]].push({
          id: 0,
          value: this.state.newShiplbl[states[0]],
          building_id: this.props.match.params.id,
          type: states[0] === 'FORWARD' ? 'cta-fwd' : `cta-${states[0].toLowerCase()}`
        });
      }
      this.setState(prevState => ({
        addNewLbl: {
          ...prevState,
          [states[0]]: false
        },
        // addNew: false,
        isExpanded: true,
        Lasterrors: {
          ...prevState.Lasterrors,
          [states[0]]: false
        },
        newShiplbl: {
          ...prevState.newShiplbl,
          [states[0]]: ''
        }
      }));
    }
    this.setState({
      preferenceCTA
    }, () => {
      if (JSON.stringify(this.state.preferenceCTA) !== JSON.stringify(this.props.preferenceCTA) || JSON.stringify(this.state.shippinglbl) !== JSON.stringify(this.props.shiplabels)) {
        this.setState({ shouldBlockNavigation: true });
      } else {
        this.setState({ shouldBlockNavigation: false });
      }
      preferenceCTA.forEach(element => {
        if (element[1] === true) {
          this.setState(prevState => ({ expanded: { ...prevState.expanded, [element[0]]: true } }));
        }
      });
      // if (check) this.setState(prevState => ({ expanded: { ...prevState.expanded, [element[0]]: true } }));
    });
    this.setPref(preferenceCTA);
  }

  saveCTAToggle = () => {
    const delShip = [...this.state.deletedShippings];
    const { newShiplbl } = this.state;
    this.setState({
      openModel: false,
      shouldBlockNavigation: false,
      newShiplbl: {
        FORWARD: '',
        DESTROY: '',
        SNAPSEND: '',
        HOLD: '',
      },
      addNewLbl: {
        FORWARD: null,
        DESTROY: null,
        SNAPSEND: null,
        HOLD: null,
      },
      deletedShippings: []
    });
    // eslint-disable-next-line
    for (let key in newShiplbl) {
      if (newShiplbl[key] !== '') {
        this.state.shippinglbl[key].push({
          id: 0,
          value: newShiplbl[key],
          building_id: this.props.match.params.id,
          type: key === 'FORWARD' ? 'cta-fwd' : `cta-${key.toLowerCase()}`
        });
      }
      //  console.log(key, newShiplbl[key]);
    }

    // if (this.state.NewShipping !== '') {
    //   this.state.shippingMethod.push({
    //     id: 0,
    //     value: this.state.NewShipping,
    //     building_id: this.props.match.params.id,
    //     type: 'cta-fwd'
    //   });
    // } else {
    //   this.setState({ Lasterror: false });
    // }
    let Shippingvalues = [];
    Object.values(this.state.shippinglbl).forEach(ele => {
      if (ele.length) {
        // eslint-disable-next-line
        Shippingvalues.push.apply(Shippingvalues, ele);
        // Shippingvalues.concat(ele);
      }
    });
    this.props.saveCTA(this.state.preferenceCTA, Shippingvalues, delShip);
  }

  handleOpen = () => {
    const {
      preferenceCTA,
      shippinglbl,
      newShiplbl,
      Lasterrors
    } = this.state;
    const error = Object.values(Lasterrors).find(x => x === true || x.length > 0);
    if (error === undefined) {
      const pref = [...preferenceCTA];
      // let check = false;
      const newArray = pref.map(element => {
        if (element[1] === true) {
          // check = true;
          if (element[0].toUpperCase() === "FORWARD" && shippinglbl[element[0]].length === 0 && newShiplbl[element[0]] === '') {
            return [element[0], false];
          }
          return element;
        }
        return element;
      });
      this.setState({ preferenceCTA: [...newArray], openModel: true });
    }
  }

  handleExpanded = (name) => {
    const { expanded } = this.state;
    this.setState(prevState => ({
      expanded: {
        ...prevState.expanded,
        [name]: !expanded[name]
      }
    }));
  }

  handlenewShippingLbl = (e, item) => {
    //  const prevShip = this.state.newShiplbl[item];

    this.setState(prevState => (
      {
        newShiplbl: {
          ...prevState.newShiplbl,
          [item]: e
        },
        Lasterrors: {
          ...prevState.Lasterrors,
          [item]: false
        }
      }
    ), () => {
      const { newShiplbl } = this.state;
      if (newShiplbl[item].length < 2 && newShiplbl[item].length > 0) {
        this.setState(prevState => ({
          Lasterrors: {
            ...prevState.Lasterrors,
            [item]: 'minLengthError'
          },
          // newShiplbl: {
          //   ...prevState.newShiplbl,
          //   [item]: prevShip
          // },
        }));
      }
      if (newShiplbl[item].length > 40) {
        this.setState(prevState => ({
          Lasterrors: {
            ...prevState.Lasterrors,
            [item]: 'maxLengthError'
          },
          // newShiplbl: {
          //   ...prevState.newShiplbl,
          //   [item]: prevShip
          // },
        }));
      }
    });
  }

  render() {
    const {
      t,
      style,
      saving,
      loading,
      loadingLbl,
      userRole,
      shiplabels
    } = this.props;

    const {
      preferenceCTA,
      openModel,
      shouldBlockNavigation,
      expanded,
      addNewLbl,
      newShiplbl,
      Lasterrors,
      fivelablError
    } = this.state;

    const inputStyle = {
      fontSize: window.screen.width < 770 ? 12 : 14,
      color: '#000',
      width: window.screen.width < 450 ? '206px' : '260px'
    };

    // const FormHelperStyle = { color: '#FF1E59' };
    return (
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <ConfrimModal t={t} open={openModel} handleConfrim={this.saveCTAToggle} handleClose={() => this.setState({ openModel: !openModel })} />
        <NavigationBlocker shouldBlockNavigation={shouldBlockNavigation} />
        {(this.props.savingError || this.props.saved || fivelablError) && (
          <Alert
            open
            variant={this.props.savingError ? 'error' : fivelablError ? 'error' : 'success'}
            message={this.props.savingError ? this.props.savingError : fivelablError ? t('buildings.onlyFiveLabel') : t('buildings.ctaSaveSuccess')}
            onClose={() => {
              if (fivelablError) {
                this.setState({ fivelablError: false });
              } else {
                this.props.closeAlert();
              }
            }}
          />
        )}

        <Paper className="just-paper margin-bottom-15">
          <div className="card-body">
            <div className="card__title">
              <h5 className="bold-text">
                {t("buildings.cta")}
              </h5>
              <div style={{ paddingBottom: 20 }}>
                {t("buildings.ctaText")}
              </div>
            </div>
            <Divider />
            {loading || loadingLbl ? (
              <VerticallyCenter>
                <AlignCenter>
                  <CircularProgress className="blue" size={30} />
                </AlignCenter>
              </VerticallyCenter>
            ) : (
              <>
                <Table className="table--bordered overflow-hidden cta-tbl" responsive>
                  <tbody>
                    {preferenceCTA && preferenceCTA.map((item, i) => (
                      <>
                        <tr >
                          <td style={{ ...style.common.p_0, ...style.common.b_2, padding: '5px 30px' }}>
                            <SpaceBetween className="height-50px">
                              <div className="black align-center margin-left-m10p">
                                <Switch
                                  checked={item[1]}
                                  onChange={() => this.handleToggle(item, i)}
                                />
                                {item[0] === 'SNAPSEND' ? 'Scan & Send' : item[0]}
                              </div>
                            </SpaceBetween>

                          </td>
                          <td style={{
                            ...style.common.p_0,
                            ...style.common.b_2,
                            textAlign: 'end',
                            padding: '5px 30px'
                          }}>
                            <>

                              {item[0] === 'FORWARD' ? (
                                <>
                                  <Tooltip enterTouchDelay={0} title={t('buildings.forwardText')} placement="top-end" >
                                    <InfoOutlinedIcon className="dropDownInfoBtn" style={{ cursor: 'pointer', color: 'gray' }} />
                                  </Tooltip>
                                  {!item[1] ? (
                                    <IconButton disabled>
                                      <ArrowDropDownIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton onClick={() => {
                                      this.handleExpanded(item[0]);
                                    }}>
                                      {expanded[item[0]] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                    </IconButton>
                                  )}
                                  {/* <IconButton onClick={() => {
                                    this.setState({ isExpanded: !isExpanded });
                                  }}>
                                    {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                  </IconButton> */}
                                </>
                              ) : (
                                <>
                                  <Tooltip enterTouchDelay={0} title={item[0] === 'SNAPSEND' ? t('buildings.scansendText') : item[0] === 'HOLD' ? t('buildings.holdText') : t('buildings.distoryText')} placement="top-end" >
                                    <InfoOutlinedIcon style={{ cursor: 'pointer', color: 'gray' }} />
                                  </Tooltip>
                                  {!item[1] ? (
                                    <IconButton disabled>
                                      <ArrowDropDownIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton onClick={() => {
                                      this.handleExpanded(item[0]);
                                    }}>
                                      {expanded[item[0]] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                    </IconButton>
                                  )}
                                </>
                              )
                              }
                            </>
                          </td>
                        </tr>
                        {/* {isExpanded && item[0] === 'FORWARD' ? (
                          <>
                            {loadingLbl ? (
                              <tr>
                                <td colSpan="2" style={{ ...style.common.p_0, ...style.common.b_2, padding: '5px 30px' }}>
                                  <VerticallyCenter>
                                    <AlignCenter>
                                      <CircularProgress className="blue" size={30} />
                                    </AlignCenter>
                                  </VerticallyCenter>
                                </td>
                              </tr>
                            ) : (
                              shippingMethod.length !== 0 ? (
                                shippingMethod.map((shipping, index) => {
                                  console.log('');
                                  return (
                                    <tr style={{ backgroundColor: !item[1] ? '#FAFBFB' : '' }}>
                                      <td colSpan="2" style={{ ...style.common.p_0, ...style.common.b_2, padding: '5px 30px' }}>
                                        <SpaceBetween style={{ padding: shipping.error ? '5px 0' : '0' }}>
                                          <div className="black align-center">
                                            {userRole && (userRole === 'admin' || userRole === 'super_admin') ? (
                                              <>
                                                {item[1] ? !saving ? (
                                                  <IconButton style={{ marginRight: 5 }} onClick={() => this.handleRemove(index)}>
                                                    <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton style={{ marginRight: 5 }} disabled >
                                                    <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton style={{ marginRight: 5 }} disabled >
                                                    <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                                  </IconButton>
                                                )
                                                }
                                                <div className="black align-center">
                                                  {shipping.value}
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                {shipping.building_id !== null ? item[1] ? !saving ? (
                                                  <IconButton style={{ marginRight: 5 }} onClick={() => this.handleRemove(index)}>
                                                    <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton style={{ marginRight: 5 }} disabled >
                                                    <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton style={{ marginRight: 5 }} disabled >
                                                    <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                                  </IconButton>
                                                ) : (<div style={{ padding: 20, marginRight: 12 }} />)
                                                }
                                                <div className="black align-center">
                                                  {shipping.value}
                                                </div>
                                              </>
                                            )}

                                          </div>
                                        </SpaceBetween>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : ('')
                            )}

                            {addNew || (!shippingMethod.length && item[1]) ? (
                              <tr style={{ backgroundColor: !item[1] ? '#FAFBFB' : '' }}>
                                <td colSpan="2" style={{ ...style.common.p_0, ...style.common.b_2, padding: '5px 30px' }}>
                                  <form onSubmit={(e) => this.handleAddNew(e)} style={{ display: 'Flex', alignItems: 'center' }}>
                                    <IconButton style={{ marginRight: 5 }} disabled={shippingMethod.length === 0} onClick={() => this.handleRemove(shippingMethod.length)}>
                                      <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                    </IconButton>
                                    <CustomTextField
                                      autoFocus
                                      placeholder={t('buildings.addnewplaceholder')}
                                      name={NewShipping}
                                      value={NewShipping}
                                      onChange={(e) => this.setState({ NewShipping: e.target.value, Lasterror: false })}
                                      InputProps={{ style: inputStyle }}
                                      helperText={Lasterror ? t('buildings.ctalabelEmptyErrorMsg') : ''}
                                      FormHelperTextProps={{ style: { color: '#FF1E59' } }}
                                      className="labelInput"
                                    />
                                  </form>
                                </td>
                              </tr>
                            ) : ('')
                            }
                            {(item[1] || shippingMethod.length) ? (
                              <tr style={{ backgroundColor: !item[1] ? '#FAFBFB' : '' }}>
                                <td colSpan="2" style={{ ...style.common.p_0, ...style.common.b_2, padding: '7px 30px' }}>
                                  <SpaceBetween className="height-40px">
                                    <div className="black align-center">
                                      {item[1] ? (<IconButton style={{ marginRight: 5 }} onClick={(e) => this.handleAddNew(e)}>
                                        <AddCircleOutlineIcon style={{ color: '#16A085' }} />
                                      </IconButton>) : (
                                        <IconButton style={{ marginRight: 5 }} disabled>
                                          <AddCircleOutlineIcon style={{ color: '#16A085' }} />
                                        </IconButton>
                                      )
                                      }

                                      {t('buildings.addnew')}
                                    </div>
                                  </SpaceBetween>
                                </td>
                              </tr>
                            ) : ('')}

                          </>
                        ) : ''} */}


                        {expanded[item[0]] ? (
                          <>
                            {loadingLbl ? (
                              <tr>
                                <td colSpan="2" style={{ ...style.common.p_0, ...style.common.b_2, padding: '5px 30px' }}>
                                  <VerticallyCenter>
                                    <AlignCenter>
                                      <CircularProgress className="blue" size={30} />
                                    </AlignCenter>
                                  </VerticallyCenter>
                                </td>
                              </tr>
                            ) : (
                              shiplabels && shiplabels[item[0]] && shiplabels[item[0]].length !== 0 ? (
                                shiplabels[item[0]].map((shipping, index) => {
                                  console.log('');
                                  return (
                                    <tr style={{ backgroundColor: !item[1] ? '#FAFBFB' : '' }}>
                                      <td colSpan="2" style={{ ...style.common.p_0, ...style.common.b_2, padding: '5px 30px' }}>
                                        <SpaceBetween style={{ padding: shipping.error ? '5px 0' : '0' }}>
                                          <div className="black align-center">
                                            {userRole && (userRole === 'admin' || userRole === 'super_admin') ? (
                                              <>
                                                {item[1] ? !saving ? (
                                                  <>
                                                    {(userRole === 'super_admin' && shipping.building_id === null && shipping.tenant === null) ? (
                                                      <IconButton style={{ marginRight: 5 }} onClick={() => this.handleRemove(index, item[0])}>
                                                        <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                                      </IconButton>
                                                    ) : (shipping.building_id !== null || shipping.tenant !== null) ? (
                                                      <IconButton style={{ marginRight: 5 }} onClick={() => this.handleRemove(index, item[0])}>
                                                        <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                                      </IconButton>
                                                    ) : (
                                                      <div style={{ padding: 20, marginRight: 12 }} />
                                                    )}

                                                  </>
                                                ) : (
                                                  <IconButton style={{ marginRight: 5 }} disabled >
                                                    <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton style={{ marginRight: 5 }} disabled >
                                                    <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                                  </IconButton>
                                                )
                                                }
                                                <div className="black align-center">
                                                  {shipping.value}
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                {shipping.building_id !== null ? item[1] ? !saving ? (
                                                  <IconButton style={{ marginRight: 5 }} onClick={() => this.handleRemove(index, item[0])}>
                                                    <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton style={{ marginRight: 5 }} disabled >
                                                    <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton style={{ marginRight: 5 }} disabled >
                                                    <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                                  </IconButton>
                                                ) : (<div style={{ padding: 20, marginRight: 12 }} />)
                                                }
                                                <div className="black align-center">
                                                  {shipping.value}
                                                </div>
                                              </>
                                            )}

                                          </div>
                                        </SpaceBetween>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : ('')
                            )}

                            {addNewLbl[item[0]] || (!shiplabels[item[0]].length && item[1]) ? (
                              <tr style={{ backgroundColor: !item[1] ? '#FAFBFB' : '' }}>
                                <td colSpan="2" style={{ ...style.common.p_0, ...style.common.b_2, padding: '5px 30px' }}>
                                  <form className="btn-input-group-form" onSubmit={(e) => this.handleAddNew(e, item[0])} style={{ display: 'Flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div className="btn-input-group">
                                      <IconButton style={{ marginRight: 5 }} disabled={shiplabels[item[0]].length === 0} onClick={() => this.handleRemove(shiplabels[item[0]].length, item[0])}>
                                        <HighlightOffIcon style={{ color: '#E74C3C' }} />
                                      </IconButton>
                                      <CustomTextField
                                        // eslint-disable-next-line
                                        // autoFocus={addNewLbl[item[0]] === null ? false : true}
                                        // eslint-disable-next-line
                                        placeholder={t(`buildings.${item[0].toLowerCase()}PlaceHolder`)}
                                        name={newShiplbl[item[0]]}
                                        value={newShiplbl[item[0]]}
                                        onChange={(e) => this.handlenewShippingLbl(e.target.value, item[0])}
                                        InputProps={{ style: inputStyle, maxLength: 12 }}
                                        helperText={Lasterrors[item[0]] === 'maxLengthError' ? t('buildings.maxLengthInput') : Lasterrors[item[0]] === 'minLengthError' ? t('buildings.minLengthInput') : Lasterrors[item[0]] ? t('buildings.ctalabelEmptyErrorMsg') : ''}
                                        FormHelperTextProps={{ style: { color: '#FF1E59' } }}
                                        className="labelInput"
                                      // maxLength="2"
                                      />
                                      {/* {newShiplbl[item[0]].length < 2 && newShiplbl[item[0]].length > 0 &&
                                        <FormHelperText style={FormHelperStyle}>{t('common.error.nameLength')}</FormHelperText>} */}
                                    </div>
                                    <span style={{ marginRight: 20, color: 40 - newShiplbl[item[0]].length <= 5 ? 'red' : '' }}>{(40 - newShiplbl[item[0]].length <= 0 ? 0 : 40 - newShiplbl[item[0]].length)}</span>
                                  </form>

                                </td>
                              </tr>
                            ) : ('')
                            }
                            {(item[1] || shiplabels[item[0]].length) ? (
                              <tr style={{ backgroundColor: !item[1] ? '#FAFBFB' : '' }}>
                                <td colSpan="2" style={{ ...style.common.p_0, ...style.common.b_2, padding: '7px 30px' }}>
                                  <SpaceBetween className="height-40px">
                                    <div className="black align-center">
                                      {item[1] ? (<IconButton style={{ marginRight: 5 }} onClick={(e) => this.handleAddNew(e, item[0])}>
                                        <AddCircleOutlineIcon style={{ color: '#16A085' }} />
                                      </IconButton>) : (
                                        <IconButton style={{ marginRight: 5 }} disabled>
                                          <AddCircleOutlineIcon style={{ color: '#16A085' }} />
                                        </IconButton>
                                      )
                                      }

                                      {t('buildings.addnew')}
                                    </div>
                                  </SpaceBetween>
                                </td>
                              </tr>
                            ) : ('')}

                          </>
                        ) : ''}


                      </>
                    ))}
                  </tbody>
                </Table>
                <Margin top={50}>
                  <div className="space-between-width-center align-center">
                    <div />
                    <Button
                      width={120}
                      loading={saving}
                      disabled={saving}
                      onClick={() => this.handleOpen()}
                    >
                      {t("common.save")}
                    </Button>
                  </div>
                </Margin>
              </>
            )}
          </div>
        </Paper >

      </Grid >
    );
  }
}

CTAToggle.propTypes = {
  t: PropTypes.func.isRequired,
  preferenceCTA: PropTypes.node.isRequired,
  shippingLabels: PropTypes.node.isRequired,
  shiplabels: PropTypes.node.isRequired,
  style: PropTypes.node.isRequired,
  saveCTA: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  savingError: PropTypes.node.isRequired,
  saved: PropTypes.node.isRequired,
  closeAlert: PropTypes.func.isRequired,
  match: PropTypes.string.isRequired,
  loading: PropTypes.string.isRequired,
  loadingLbl: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  reload: PropTypes.bool.isRequired,

};

export default withStyles(styles)(withWidth()(CTAToggle));

const NavigationBlocker = ({ shouldBlockNavigation }) => {
  if (shouldBlockNavigation) {
    window.onbeforeunload = () => true;
  } else {
    window.onbeforeunload = null;
  }
  return (
    <Prompt
      when={shouldBlockNavigation}
      message="Changes that you made may not be saved."
    />
  );
};

NavigationBlocker.propTypes = {
  shouldBlockNavigation: PropTypes.bool.isRequired,
};
