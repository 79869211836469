/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable quotes */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  InputBase,
  Divider,
  CircularProgress,
  FormControl,
  Grid,
  FormHelperText,
  withStyles,
  TextField, MuiThemeProvider,
} from '@material-ui/core';
import {
  AddCircleOutline,
  CancelOutlined,
  Person,
  Search,
} from '@material-ui/icons';
import { AlignCenter, Truncate } from '../../../UI/ResponsiveRelated';
import Switch from '../../../UI/Switch';
import {
  CustomTextField,
  CustomInputLabel,
  CustomInput,
  CustomPhoneNumInput,
} from '../../../UI/Input';
import { AddMemberStyles as styles, MemberDetailTheme as theme } from '../../../../util/StyleConsts';
import SearchDropdown from '../../../UI/SearchDropdown/SearchDropdown';

class AddMember extends Component {
  state = { open: false };
  openDialog = () => this.setState({ open: !this.state.open });
  cancel = () => {
    this.props.emptyBusinesses();
    this.openDialog();
  };

  render() {
    const {
      t,
      name,
      notes,
      email,
      phone,
      phoneError,
      address,
      office,
      businesses,
      handleChange,
      selectBusiness,
      selectedBusiness,
      search,
      handleSearch,
      loadingBus,
      selectedBuilding,
      handlePhone,
      handleNotes,
      emailError,
      nameError,
      addressError,
      officeError,
      alternateName,
      setAlternativeName,
      altPhoneError,
      addInputField,
      removeInputField,
      alternateEmail,
      alternatePhone,
      validateEmail,
      validateName,
      building,
      setAltPhone,
      assignDesignated,
      // assignSuperDesignated,
      classes,
      businessError,
      selectItem,
      selectedTenant,
    } = this.props;

    let { buildings } = this.props;
    if (selectedTenant) {
      let newBuildings = [];
      buildings.map(item => {
        if (item.tenant_id === selectedTenant.tenant_id) {
          newBuildings = [...item.buildings];
        }
        return item;
      });
      buildings = [...newBuildings];
    }

    const inputStyle = {
      fontSize: window.screen.width < 770 ? 12 : 14,
      color: '#000',
    };
    const labelStyle = {
      fontSize: window.screen.width < 770 ? 12 : 14,
      color: {
        '&:after': {
          color: '#027AFF',
        },
      },
    };
    const addIconStyle = {
      height: 14,
      width: 14,
      color: '#16A085',
      cursor: 'pointer',
    };
    const cancelIconStyle = {
      height: 14,
      width: 14,
      color: '#E74C3C',
      cursor: 'pointer',
    };
    const checkboxStyle = { padding: 0, color: 'rgb(2, 122, 255)' };
    const padding = { padding: 0 };
    const paddingRightLeft = {
      paddingTop: 0,
      paddingBottom: 0,
    };
    const displayCenter = { display: 'flex', justifyContent: 'center' };
    const selectedIds = selectedBusiness.map(bus => bus.business_id);
    const designatedIds = selectedBusiness.map(
      bus => ((bus.is_designated && {
        value: 'designated',
        id: bus.business_id
      })),
    );
    // const superDesignatedIds = selectedBusiness.map(
    //   bus => ((bus.is_super_designated && {
    //     value: 'super_designated',
    //     id: bus.business_id
    //   })),
    // );
    const designatedForBusinessPackages = t('tables.designatedForBusinessPackages');
    // const designatedForAllPeerPackages = t('tables.designatedForAllPeerPackages');

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="margin-bottom-20">
          <Paper className="paper card-body margin-right-10px">
            <div className="flex card__title">
              <Person className="height-width-20px color-grey" />
              <h5 className="bold-text">{t('tables.individualInfo')}</h5>
            </div>
            <Grid container direction="row" justify="space-between">
              <Grid
                item
                md={5}
                xs={12}
                className="height-50px"
                style={{ marginTop: 19, marginBottom: 15 }}
              >
                <CustomTextField
                  required
                  fullWidth
                  label={t('tables.name')}
                  value={name}
                  onChange={handleChange('name')}
                  InputProps={{ style: inputStyle }}
                  InputLabelProps={{ style: labelStyle }}
                  helperText={nameError ? t('common.error.nameValidation') : ''}
                  FormHelperTextProps={{ style: { color: '#FF1E59' } }}
                />
              </Grid>
              <Grid
                item
                md={5}
                xs={12}
                className="height-50px"
                style={{ marginTop: 19, marginBottom: 15 }}
              >
                <CustomTextField
                  required
                  fullWidth
                  label={t('tables.email')}
                  value={email}
                  onChange={handleChange('email')}
                  InputProps={{ style: inputStyle }}
                  InputLabelProps={{ style: labelStyle }}
                  helperText={emailError ? t('emailValidation') : ''}
                  FormHelperTextProps={{ style: { color: '#FF1E59' } }}
                />
              </Grid>
              <Grid
                item
                md={5}
                xs={12}
                className="height-50px"
                style={{ marginTop: 19, marginBottom: 15 }}
              >
                <CustomPhoneNumInput
                  placeholder={t('tables.phone')}
                  value={phone}
                  formClass="external-container-phone-input width-100"
                  onChange={value => handlePhone('phone', value)}
                  error={phoneError ? t('phoneValidation') : ''}
                />
              </Grid>
              <Grid
                item
                md={5}
                xs={12}
                className="height-50px"
                style={{ marginTop: 19, marginBottom: 15 }}
              >
                <CustomTextField
                  fullWidth
                  label={t('tables.office')}
                  value={office}
                  onChange={handleChange('office')}
                  InputProps={{ style: inputStyle }}
                  InputLabelProps={{ style: labelStyle }}
                  helperText={officeError ? t('common.error.officeValidation') : ''}
                  FormHelperTextProps={{ style: { color: '#FF1E59' } }}
                />
              </Grid>
              <Grid
                item
                md={5}
                xs={12}
                className="height-50px"
                style={{ marginTop: 19, marginBottom: 15 }}
              >
                <FormControl required fullWidth>
                  <CustomInputLabel
                    shrink={selectedBuilding}
                    style={labelStyle}
                    InputLabelProps={{ style: labelStyle }}
                    InputProps={{
                      inputProps: {
                        style: {
                          inputStyle,
                          textOverflow: 'ellipsis',
                          color: 'black',
                          fontSize: 12,
                        },
                      },
                    }}
                  >
                    {t('tables.buildings')}
                  </CustomInputLabel>
                  {building.length === 0 && (
                    <SearchDropdown
                      list={buildings.map(item => {
                        item.name = item.building_name;
                        item.id = item.building_id;
                        return item;
                      })}
                      selection={selectedBuilding}
                      selectItem={item => selectItem(item)}
                    />
                  )}
                  {building.length > 0 && (
                    <CustomTextField
                      fullWidth
                      disabled
                      style={{ marginTop: 19, marginBottom: 15 }}
                      value={building[0].building_name}
                      InputProps={{
                        inputProps: {
                          style: {
                            inputStyle,
                            textOverflow: 'ellipsis',
                            color: 'black',
                          },
                        },
                      }}
                      InputLabelProps={{ style: labelStyle }}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                md={5}
                xs={12}
                style={{ marginTop: 19, marginBottom: 15 }}
                className="height-50px"
              >
                <CustomTextField
                  fullWidth
                  label={t('buildings.address')}
                  value={address}
                  onChange={handleChange('address')}
                  InputProps={{ style: inputStyle }}
                  InputLabelProps={{ style: labelStyle }}
                  helperText={addressError ? t('common.error.addressValidation') : ''}
                  FormHelperTextProps={{ style: { color: '#FF1E59' } }}
                />
              </Grid>
              {alternateName.map((value, index) => (
                <Grid
                  item
                  md={5}
                  xs={12}
                  style={{ marginTop: 19, marginBottom: 15 }}
                  className="height-50px"
                >
                  <FormControl
                    fullWidth
                    FormLabelClasses={{
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    }}
                  >
                    <CustomInputLabel>
                      {t('members.alternateName')}
                    </CustomInputLabel>
                    <CustomInput
                      style={{ marginTop: 19, ...inputStyle }}
                      label={t('members.alternateName')}
                      value={value}
                      fullWidth
                      onChange={setAlternativeName('alternateName', index)}
                      endAdornment={
                        index === 0 ? (
                          <AddCircleOutline
                            style={addIconStyle}
                            onClick={() => addInputField('alternateName')}
                          />
                        ) : (
                            <CancelOutlined
                              style={cancelIconStyle}
                              onClick={() =>
                                removeInputField('alternateName', index)
                              }
                            />
                          )
                      }
                    />
                    {value.length > 0 && !validateName(value) && (
                      <FormHelperText className="red">
                        {t('common.error.altNameValidation')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ))}
              {alternateEmail.map((value, index) => (
                <Grid
                  item
                  md={5}
                  xs={12}
                  style={{ marginTop: 19, marginBottom: 15 }}
                  className="height-50px"
                >
                  <FormControl fullWidth>
                    <CustomInputLabel style={labelStyle}>
                      {t('members.alternateEmail')}
                    </CustomInputLabel>
                    <CustomInput
                      style={{ marginTop: 19, ...inputStyle }}
                      label={t('members.alternateEmail')}
                      value={value}
                      fullWidth
                      onChange={setAlternativeName('alternateEmail', index)}
                      endAdornment={
                        index === 0 ? (
                          <AddCircleOutline
                            style={addIconStyle}
                            onClick={() => addInputField('alternateEmail')}
                          />
                        ) : (
                            <CancelOutlined
                              style={cancelIconStyle}
                              onClick={() =>
                                removeInputField('alternateEmail', index)
                              }
                            />
                          )
                      }
                    />
                    {value.length > 0 && !validateEmail(value) && (
                      <FormHelperText className="red">
                        {t('emailValidation')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ))}
              {alternatePhone.map((value, index) => (
                <Grid
                  item
                  md={5}
                  xs={12}
                  style={{ marginTop: 19, marginBottom: 15 }}
                  className="height-50px"
                >
                  <div className="flex">
                    <CustomPhoneNumInput
                      placeholder={t('members.alternatePhone')}
                      value={value}
                      formClass="external-container-phone-input width-100"
                      onChange={altPhone =>
                        setAltPhone('alternatePhone', index, altPhone)
                      }
                      error={altPhoneError[index] && t('altPhoneValidation')}
                    />
                    <div style={{ marginTop: '2rem' }}>
                      {index === 0 ? (
                        <AddCircleOutline
                          style={addIconStyle}
                          onClick={() => addInputField('alternatePhone')}
                        />
                      ) : (
                          <CancelOutlined
                            style={cancelIconStyle}
                            onClick={() =>
                              removeInputField('alternatePhone', index)
                            }
                          />
                        )}
                    </div>
                  </div>
                </Grid>
              ))}
              <FormControl fullWidth>
                <Grid item xs={12} md={12} style={{ marginTop: 19, marginBottom: 15 }}>
                  <MuiThemeProvider theme={theme}>
                    <TextField
                      className="font-input"
                      notchedOutline="false"
                      multiline
                      fullWidth
                      rows="5"
                      value={notes}
                      label={t("tables.notes")}
                      variant="outlined"
                      placeholder={t("tables.noNotes")}
                      onChange={e => handleNotes(e.target.value)}
                      margin="normal"
                    />
                  </MuiThemeProvider>
                </Grid>
              </FormControl>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="margin-bottom-20">
          <Paper className="paper card-body margin-right-10px">
            <div className="flex card__title">
              <h5 className="bold-text">{t('members.selectBusiness')}</h5>
            </div>
            {!selectedBuilding && <div className="height-25px font-size-12">{t('members.selectBuildingFirst')}</div>}
            <form
              onSubmit={e => {
                e.preventDefault();
                if (!loadingBus) {
                  if (selectedBuilding) handleSearch();
                }
              }}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div className="before-search">
                <InputBase
                  autoFocus
                  aria-haspopup="true"
                  className="topbar__search-field topbar__search-field--open background-grey search"
                  inputProps={{ style: { cursor: 'pointer' }, maxlength: 100 }}
                  value={search}
                  onChange={handleChange('search')}
                  placeholder={t('members.searchBusiness')}
                  startAdornment={
                    <Search
                      style={{
                        marginRight: window.screen.width < 500 ? 0 : 10,
                        fontSize: 18,
                      }}
                    />
                  }
                />
              </div>
            </form>
            <div className="overflowy-scroll">
              {businesses.length > 0 && <p className="font-size-14">{t('members.designateMessage')}</p>}
              {selectedBusiness.length > 0 && (
                <>
                  {selectedBusiness.map(bus => (
                    <ListItem style={paddingRightLeft}>
                      <div>
                      <div style={{ display: 'flex' }}>
                      <div style={{ width: '10%' }}>
                        <Checkbox
                          checked
                          style={checkboxStyle}
                          onChange={() => selectBusiness(bus)}
                        />
                      </div>
                      <div style={{ ...displayCenter, width: '70%' }}>
                        <ListItemText
                          primary={<Truncate width={200}>{bus.name}</Truncate>}
                        />
                      </div>
                      </div>
                      <div style={{ display: 'block', paddingLeft: 30 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p className="font-size-14 label-width-auto">{designatedForBusinessPackages}</p>
                          <Switch
                            checked={designatedIds.filter(idfy => idfy && (idfy.id === bus.business_id) && idfy.value === "designated").length > 0}
                            onClick={() => assignDesignated(bus)}
                          />
                        </div>
                        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p className="font-size-14 label-width-auto">{designatedForAllPeerPackages}</p>
                          <Switch
                            // checked={designatedIds.indexOf(bus.business_id) > -1}
                            checked={superDesignatedIds.filter(idfy => idfy && (idfy.id === bus.business_id) && idfy.value === "super_designated").length > 0}
                            onClick={() => assignSuperDesignated(bus)}
                          />
                        </div> */}
                      </div>
                      </div>
                    </ListItem>
                  ))}
                  <Divider />
                </>
              )}
              {loadingBus && (
                <AlignCenter style={{ flex: 1, alignItems: 'center' }}>
                  <CircularProgress className="blue" size={20} />
                </AlignCenter>
              )}
              {!loadingBus && businesses.length === 0 && businessError && (
                <AlignCenter style={{ flex: 1, alignItems: 'center' }}>
                  {businessError}
                </AlignCenter>
              )}
              <List style={padding}>
                <div className="overflowy-scroll">
                  {businesses.map(bus => (
                    <ListItem>
                      <Checkbox
                        checked={selectedIds.indexOf(bus.business_id) > -1}
                        style={checkboxStyle}
                        onChange={() => selectBusiness(bus)}
                      />
                      <ListItemText
                        primary={<Truncate width="auto">{bus.name}</Truncate>}
                      />
                    </ListItem>
                  ))}
                </div>
              </List>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

AddMember.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  phoneError: PropTypes.bool.isRequired,
  address: PropTypes.string.isRequired,
  office: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  businesses: PropTypes.node.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectBusiness: PropTypes.func.isRequired,
  selectedBusiness: PropTypes.node.isRequired,
  selectedBuilding: PropTypes.node.isRequired,
  buildings: PropTypes.node.isRequired,
  building: PropTypes.node.isRequired,
  handleSearch: PropTypes.func.isRequired,
  loadingBus: PropTypes.bool.isRequired,
  handlePhone: PropTypes.func.isRequired,
  handleNotes: PropTypes.func.isRequired,
  emailError: PropTypes.string.isRequired,
  nameError: PropTypes.string.isRequired,
  addressError: PropTypes.string.isRequired,
  officeError: PropTypes.string.isRequired,
  alternateName: PropTypes.node.isRequired,
  setAlternativeName: PropTypes.func.isRequired,
  addInputField: PropTypes.func.isRequired,
  removeInputField: PropTypes.func.isRequired,
  altPhoneError: PropTypes.array.isRequired,
  alternateEmail: PropTypes.node.isRequired,
  alternatePhone: PropTypes.node.isRequired,
  validateEmail: PropTypes.func.isRequired,
  validateName: PropTypes.func.isRequired,
  setAltPhone: PropTypes.func.isRequired,
  assignDesignated: PropTypes.func.isRequired,
  // assignSuperDesignated: PropTypes.func.isRequired,
  classes: PropTypes.node.isRequired,
  businessError: PropTypes.string.isRequired,
  emptyBusinesses: PropTypes.func.isRequired,
  selectItem: PropTypes.func.isRequired,
  selectedTenant: PropTypes.node.isRequired,
};

export default withStyles(styles)(AddMember);
