/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CircularProgress, Grid, withWidth } from '@material-ui/core';
import AssociatesTable from './components/Associates';
import BlueButton from '../../UI/BlueButton';
import BuildingToggle from '../BuildingToggle';
import NotAuthorized from '../../UI/403/403';
import browser from '../../../util/getBrowserInfo';
import { resolveSafari } from '../../../util/resolveSafari';
import { AlignCenter, VerticallyCenter } from '../../UI/ResponsiveRelated';
import Footer from '../../UI/Footer/Footer';
import { checkAWSSession, function_checkFreeTrailExpired, function_checkPlanUpgradeNeededUserCheck } from '../../../util/application.utils';
import PlanUpgradeNeeded from '../PaymentModals/PlanUpgradeNeeded';
import FreeTrailExpiredInternal from '../PaymentModals/FreeTrailExpiredInternal';
import { getDaysDifference } from '../../../util/utility.functions';

class Associates extends Component {
  constructor(props) {
    super(props);
    const { match } = props;
    this.state = {
      match,
      encryptedEmail: null,
      showPlanUpgradeNeededModal: false,
      showTrailExpiredModal: false,
    };
  }

  componentDidMount() {
    checkAWSSession((res) => {
      if (res) {
        this.setState({
          encryptedEmail: `/onboarding?email=${res}`,
        });
      }
      if (browser.name === 'Safari') {
        resolveSafari();
      }
      this.props.history.listen(() => {
        const url = window.location.href;
        if (url && url.includes('/mailroom_associates')) {
          const id = url.split('.').pop();
          this.setState({
            match: { params: { id, module: 'mailroom_associates' } },
          });
        } else {
          this.setState({
            match: { params: { id: null, module: null } },
          });
        }
      });
    });
  }

  closePlanUpgradeNeededModal = (callback) =>
    this.setState({ showPlanUpgradeNeededModal: false }, () => {
      callback && callback();
    });
  
    closeFreeTrialExpiredModal = (callback) => {
      this.setState({ showTrailExpiredModal: false }, () => {
        if (callback) {
        callback();
      }
    });
  }

  addAssociate = () => {
    const { buildings, userRole } = this.props;
    if (buildings && buildings.length > 0 && buildings[0].tier && userRole !== 'super_admin') {
      const { showTrailExpiredModal } = this.state;
      const freeTrialExp = function_checkFreeTrailExpired(buildings, showTrailExpiredModal);
      const planUpgradeNeed = function_checkPlanUpgradeNeededUserCheck(buildings);
      if (freeTrialExp) {
        this.setState({
          showTrailExpiredModal: true
        });
        return;
      } else if (planUpgradeNeed) {
        this.setState({
          showPlanUpgradeNeededModal: true,
        });
        return;
      } else {
        this.props.history.push('/add_associate');
      }
    } else {
      this.props.history.push('/add_associate');
    }
  };

  render() {
    const { t, userRole, buildings } = this.props;
    const { match, encryptedEmail, showPlanUpgradeNeededModal, showTrailExpiredModal } = this.state;
    if (encryptedEmail) {
      this.props.history.replace(encryptedEmail);
    }
    return !userRole ? (
      <VerticallyCenter>
        <AlignCenter>
          <CircularProgress className="blue" size={30} />
        </AlignCenter>
      </VerticallyCenter>
    ) : userRole === 'mailroom_supervisor' ? (
      <NotAuthorized />
    ) : (
      <Fragment>
        <div className="dashboard container">
          <Grid container style={{ maxWidth: '100%' }}>
            <Grid item xs={12} md={12}>
              <div className="just-space-between margin-bottom-0 align-center-none">
                <div className="space-between-with-767-block">
                  {match.params.module === 'mailroom_associates' ? (
                    <h3 className="page-title margin-right-10">
                      {t('associates.mailroomAssociates')}
                    </h3>
                  ) : (
                    <h3 className="page-title margin-right-10">
                      {t('associates.associates')}
                    </h3>
                  )}
                  <div style={{ marginTop: 2 }}>
                    {match.params.module === 'mailroom_associates' ? (
                      <React.Fragment />
                    ) : (
                      <BuildingToggle {...this.props} />
                    )}
                  </div>
                </div>
                <React.Fragment>
                  {userRole !== 'mailroom_supervisor' && (
                    // <Link to="/add_associate">
                    <BlueButton width={160} onClick={() => this.addAssociate()}>
                      {t('associates.add')}
                    </BlueButton>
                    // </Link>
                  )}
                </React.Fragment>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <AssociatesTable {...this.state} {...this.props} />
            </Grid>
          </Grid>
        </div>
        <PlanUpgradeNeeded
          type="users"
          open={showPlanUpgradeNeededModal}
          onClose={this.closePlanUpgradeNeededModal}
          t={this.props.t}
        />
        <FreeTrailExpiredInternal 
          type="users"
          buildings={buildings}
          userRole={this.props.userRole}
          open={showTrailExpiredModal}
          onClose={this.closeFreeTrialExpiredModal}
          t={t}
        />
        <Footer />
      </Fragment>
    );
  }
}

Associates.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
  match: PropTypes.node.isRequired,
};

export default withRouter(
  connect((state) => ({
    building: state.locationFilter,
    buildings: state.allBuildings,
    userRole: state.userRole,
    pagination: state.pagination,
  }))(withWidth()(Associates))
);
