/* eslint-disable */

import React from "react";
import { Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import {
  handleSidebarHover,
  handleSidebarHoverAway,
} from "../../../util/utility.functions";
import "./style.css";

const SupportIconActive = require("./SupportIconActive.svg");
const SupportIconInactive = require("./SupportIconInactive.svg");

function SupportIcon(props) {
  const { active, label } = props;
  const imgSrc = active ? SupportIconActive : SupportIconInactive;
  return (
    <Tooltip title={label}>
      <img
        src={imgSrc}
        alt=""
        height="20"
        width="20"
        onMouseOver={(event) => {
          handleSidebarHover(event, SupportIconActive);
        }}
        onFocus={(event) => {
          handleSidebarHover(event, SupportIconActive);
        }}
        onMouseLeave={(event) => {
          handleSidebarHoverAway(event, active, SupportIconInactive);
        }}
      />
    </Tooltip>
  );
}

SupportIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.bool.isRequired,
};

export default SupportIcon;
