import React,{ useState } from 'react';
import { withRouter } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserCardDetail from './UserCardDetail';
import {
  getStripePaymentDetails,
  setPaymentPlan,
} from '../../../server/payment';
import { Button } from './Styled';

function InsufficientFunds(props) {
  const [openAddCardModal, setOpenAddCardModal] = useState(false);
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  const { t, userRole, buildings } = props;

  let message = '';
  if (userRole === 'admin') {
    message = t('paymentModals.insufficientFunds.description');
  } else {
    message =
      'Unfortunately, we were unable to charge your card due to insufficient funds. Please contact your admin to recheck your payment details so that you can continue using Mailroom by PackageX.';
  }

  const onCloseModal = () => {
    setOpenAddCardModal(false);
    props.onClose();
  };

  const setPaymentCheckout = (stripeId, loadingFalse) => {
    setPaymentPlan(stripeId)
      .then(async (res) => {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: res.data.id,
        });

        if (result.error) {
          return res;
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
        return res;
      })
      .then(() => {
        loadingFalse();
      })
      .catch((error) => {
        // this.setState({ error: 'Unable to set payment plan' });
        loadingFalse();
        console.log(error);
      });
  };

  const addPaymentInfo = () => {
    const loadingFalse = () => setCheckoutLoader(false);
    setCheckoutLoader(true);
    getStripePaymentDetails()
          .then((res) => {
            setPaymentCheckout(res.data.stripe_price_id, loadingFalse);
          })
          .catch((err) => {
            console.log(err);
            loadingFalse();
          });
  };

  const getButtonPayment = () => {
    const validBuilding = buildings && buildings.length > 0 && buildings[0]; 
    if (validBuilding && buildings[0].checkout_required && buildings[0].status && buildings[0].status.toLowerCase() === 'open') {
      // directed to checkout screen
      return (
        <Button className="pm-cp-upgrade-btn" onClick={() => addPaymentInfo()}>
              Add Payment {checkoutLoader && <CircularProgress size={15} className="white" />}
        </Button>);
    } else {
      // Add Card
      return (
        <Button className="pm-cp-upgrade-btn" onClick={() => setOpenAddCardModal(true)}>
              Add Card
          </Button>);
    }
  };
  
  return (
    <div>
      <Dialog keepMounted maxWidth="sm" onClose={onCloseModal} {...props}>
        <DialogContent className="pm-modal-container">
          <h3 className="pm-heading">
            {t('paymentModals.insufficientFunds.headingNormal')}{' '}
            <span className="pm-heading-red">
              {t('paymentModals.insufficientFunds.headingColored')}
            </span>
          </h3>
          <p className="pm-description">
            {message}
          </p>
          {userRole === 'admin' ? (<div className="pm-button-section">
            <Button 
            className="pm-cp-add-payment-btn" 
            onClick={() => {
                props.history.push('/manage-plan');
                props.onClose();
              }}>
              View Plan
            </Button>
            {getButtonPayment()}
            {/* <Button className="pm-cp-upgrade-btn" onClick={() => setOpenAddCardModal(true)}>
              Add Card
            </Button> */}
          </div>) :
          (
            <div className="pm-single-button-section">
              <Button
                className="pm-cp-downgrade-btn"
                onClick={() => props.onClose()}
              >
                {t('paymentModals.paymentSuccessful.button')}
              </Button>
            </div>
          )}
        </DialogContent>
        <UserCardDetail 
          t={t}
          userRole={userRole}
          open={openAddCardModal}
          onClose={() => onCloseModal()}
        />
      </Dialog>
    </div>
  );
}

export default withRouter(InsufficientFunds);
