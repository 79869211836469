/* eslint-disable quotes */
/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { ArrowDropDown } from "@material-ui/icons";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  // Typography,
  Divider,
  CircularProgress,
  withWidth,
  withStyles,
  Paper,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Card,
} from '@material-ui/core';
import { AlignCenter, VerticallyCenter } from '../../../UI/ResponsiveRelated';
import VISA from '../../../../assets/Cards/Visa.svg';
import MasterCard from '../../../../assets/Cards/Mastercard.svg';
import Amez from '../../../../assets/Cards/Amez.svg';
import Jcb from '../../../../assets/Cards/Jcb.svg';
import UnionPay from '../../../../assets/Cards/UnionPay.svg';
import Discover from '../../../../assets/Cards/Discover.svg';
import Diners from '../../../../assets/Cards/Diners.svg';
import DummyCard from '../../../../assets/Cards/DummyCard.svg';
import PaymentHistoryModal from './PaymentHistoryModal';
import PaymentHistorySections from './PaymentHistorySections';
import { getPaymentHistory } from '../../../../server/payment';
import { PrefsStyles as styles } from '../../../../util/StyleConsts';
import PaymentHistorySingle from './PaymentHistorySingle';
import UserCardDetail from '../../PaymentModals/UserCardDetail';
import Alert from '../../../UI/Poper';
import CardOptionsDropdown from './CardOptionsDropdown';

class PaymentInformation extends Component {
  state = {
    openPaymentHistory: false,
    paymentHistory: [],
    paymentInformation: {},
    loading: false,
    useCardDetailModal: false,
    error: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        getPaymentHistory()
          .then((res) => {
            this.setState({
              paymentHistory: res.data.paymentHistory,
              paymentInformation: res.data.paymentInformation,
              loading: false,
            });
          })
          .catch((err) => {
            this.setState({ error: 'Unable to fetch payment history!' });
            console.log(JSON.stringify(err));
            this.setState({ loading: false });
          });
      }
    );
  };

  getCardImage = (card) => {
    //card
    switch (card.brand) {
      case 'visa':
        return <img src={VISA} alt="Visa" style={{ marginRight: 15 }} />;
      case 'mastercard':
        return (
          <img src={MasterCard} alt="Mastercard" style={{ marginRight: 15 }} />
        );
      case 'amex':
        return (
          <img src={Amez} alt="American Express" style={{ marginRight: 15 }} />
        );
      case 'jcb':
        return <img src={Jcb} alt="JCB" style={{ marginRight: 15 }} />;
      case 'unionpay':
        return (
          <img src={UnionPay} alt="UnionPay" style={{ marginRight: 15 }} />
        );
      case 'discover':
        return (
          <img src={Discover} alt="Discover" style={{ marginRight: 15 }} />
        );
      case 'diners':
        return <img src={Diners} alt="Diners" style={{ marginRight: 15 }} />;
      default:
        return <img src={DummyCard} alt="Card" style={{ marginRight: 15 }} />;
    }
  };

  render() {
    const { paymentInformation, paymentHistory, loading } = this.state;
    // console.log(
    //   "paymentHistory, paymentInformation >>",
    //   paymentHistory,
    //   JSON.stringify(paymentInformation)
    // );

    const {
      height,
      width,
      name,
      userCards,
      t,
      buildings
      // paymentHistory,
      // style
    } = this.props;

    const singleCard = paymentInformation && paymentInformation.data && paymentInformation.data.length === 1;

    return (
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Paper
          style={{
            height: width === 'sm' || width === 'xs' ? 550 : height,
            marginBottom: 15,
            marginRight: width === 'lg' || width === 'xl' ? 15 : 0,
            //   ...style.common.oy_a,
          }}
          className="just-paper"
        >
          {this.state.error && (
            <Alert
              variant="error"
              open
              message={this.state.error}
              onClose={() => {
                this.setState({
                  error: false,
                });
              }}
            />
          )}
          <div className="card-body">
            <div className="card__title just-space-between">
              <h5 className="bold-text">{t('paymentInfo.heading')}</h5>
              <div
                className="manage-plan-text"
                onClick={() => this.setState({ useCardDetailModal: true })}
              >
                {t('paymentInfo.addNewCard')}
              </div>
            </div>
            <Divider />
            {loading === true ? (
              <VerticallyCenter>
                <AlignCenter>
                  <CircularProgress className="blue" size={30} />
                </AlignCenter>
              </VerticallyCenter>
            ) : (
              <>
                <div
                  style={{
                    marginBottom: 20,
                    height: '180px',
                    overflowY: 'auto',
                    padding: 5,
                  }}
                >
                  {paymentInformation &&
                    paymentInformation.data &&
                    paymentInformation.data.length > 0 &&
                    paymentInformation.data.map((cardInfo, index) => (
                      <>
                        <Grid container direction="row" justify="space-between">
                          <Grid
                            item
                            md={12}
                            xs={12}
                            style={{ marginTop: 8, marginBottom: 8 }}
                          >
                            <div className="pi-card-container">
                              <div className="pi-card-container-internal">
                                {/* <span>Credit Card</span> */}

                                {/* <img
                                  src={VISA}
                                  alt="visa"
                                  style={{ marginRight: 15 }}
                                /> */}

                                {this.getCardImage(cardInfo.card)}

                                <span>{`**** ${cardInfo.card.last4}`}</span>
                                {cardInfo.default_method === true &&
                                  paymentInformation.data.length > 1 && (
                                    <span className="pi-default-card">
                                      Default
                                    </span>
                                  )}
                              </div>
                              <div className="pi-card-container-internal">
                                <span>
                                  Expires{' '}
                                  {`${cardInfo.card.exp_month}/${cardInfo.card.exp_year}`}
                                </span>
                                {!singleCard && <span className="pi-card-settings-icon">
                                  <CardOptionsDropdown
                                    id={cardInfo.id}
                                    loadData={this.loadData}
                                    cardLastDigits={cardInfo.card.last4}
                                    defaultCard={cardInfo.default_method}
                                    showSetDefaultOption={
                                      paymentInformation &&
                                      paymentInformation.data &&
                                      paymentInformation.data.length > 1
                                    }
                                   
                                  />
                                </span>}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        {index + 1 < paymentInformation.length && <Divider />}
                      </>
                    ))}
                </div>
                <div
                  className="card__title payment-history-heading-box"
                  style={{ paddingBottom: 15 }}
                >
                  <h5 className="bold-text">{t('paymentHistory.heading')}</h5>
                  {paymentHistory && paymentHistory.length > 1 && (
                    <div
                      className="payment-history-view-all"
                      onClick={() =>
                        this.setState({ openPaymentHistory: true })
                      }
                    >
                      {t('notifications.viewAll')}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginBottom: 20,
                    height: '180px',
                    overflowY: 'auto',
                    padding: 5,
                  }}
                >
                  {/* Header col */}
                  <PaymentHistorySections t={t} />
                  {/* Rows */}
                  {paymentHistory &&
                    paymentHistory.length > 0 &&
                    paymentHistory
                      .slice(0, 3)
                      .map((record, index) => (
                        <PaymentHistorySingle record={record} key={index} buildings={buildings} />
                      ))}
                </div>
              </>
            )}
          </div>
        </Paper>
        <PaymentHistoryModal
          t={t}
          buildings={buildings}
          open={this.state.openPaymentHistory}
          onClose={() => this.setState({ openPaymentHistory: false })}
          paymentHistory={
            paymentHistory && paymentHistory.length > 0 ? paymentHistory : []
          }
        />
        <UserCardDetail
          t={t}
          open={this.state.useCardDetailModal}
          onClose={() => this.setState({ useCardDetailModal: false })}
          reloadData={this.loadData}
        />
      </Grid>
    );
  }
}

PaymentInformation.propTypes = {
  height: PropTypes.string.isRequired,
  // style: PropTypes.node.isRequired,
  width: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  userCards: PropTypes.array.isRequired,
  paymentHistory: PropTypes.array.isRequired,
  buildings: PropTypes.array.isRequired,
};

export default withStyles(styles)(withWidth()(PaymentInformation));
