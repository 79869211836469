/* eslint-disable brace-style */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
// import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  withWidth,
} from '@material-ui/core';
import { server as api } from '../../../server/server';
import Alert from '../../UI/Poper';
import BlueButton from '../../UI/BlueButton';
import SendTestDialog from './components/SendTestDialog';
import EmailDropdownComponent from './components/EmailDropdown';
import { CustomInput } from '../../UI/Input';

// const env = process.env.REACT_APP_ENV;

const styles = theme => ({
  button: {
    margin: theme.spacing.unit * 2,
  },

  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 15,
  },

  grid: {
    paddingTop: 15,
  },

  gridTitle: {
    fontSize: 12,
    color: 'grey',
    marginBottom: 8,
  },
  gridBody: {
    fontSize: 14,
  },
  parent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  margin: {
    margin: theme.spacing.unit,
    textDecoration: 'none',
  },
  input: {
    width: '100%',
    paddingTop: 0,
    fontSize: 15,
  },

  inputText: {
    paddingTop: 0,
  },
  foot: {
    display: 'flex',
    alignItems: 'center',
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#027AFF',
    },
  },
});

class EmailTemplate extends Component {
  constructor(props) {
    super(props);
    let title = this.props.template.content_json.title || '';
    let subject = this.props.template.content_json.subject || '';
    let body =
      new DOMParser()
        .parseFromString(this.props.template.content_json.body, 'text/html')
        .body.innerHTML.replace(
          new RegExp('<span[^>]+>(.*?)(?:</span>)', 'g'),
          '$1',
        ) || '';

    this.state = {
      successMessage: 'Test email sent to your email',
      open: false,
      back: false,
      openConfirm: false,
      cat: 'normal',
      goBack: false,
      loading: false,
      tag: '',
      error: false,
      bodyTags: [],
      titleTags: [],
      edit: false,
      title: this.handleReplace(title),
      subject: this.handleReplace(subject),
      body: this.handleReplace(body),
      reset: this.props.template,
      recent: 'subject',
      position: 0,
    };
  }

  componentDidMount() {
    const { title, body } = this.state;
    if (document.getElementById('body') !== null) {
      let bodyTag = document.getElementById('body');
      bodyTag.innerText = '';
      bodyTag.style.whiteSpace = 'pre-line';
    }
    this.getReplaced(title, 'title');
    this.getReplaced(body, 'body');
  }

  componentWillReceiveProps(props) {
    if (this.props.template !== props.template) {
      let title = props.template.content_json.title || '';
      let subject = props.template.content_json.subject || '';
      let body =
        new DOMParser()
          .parseFromString(props.template.content_json.body, 'text/html')
          .body.innerHTML.replace(
            new RegExp('<span[^>]+>(.*?)(?:</span>)', 'g'),
            '$1',
          ) || '';
      title = this.handleReplace(title);
      subject = this.handleReplace(subject);
      body = this.handleReplace(body);
      this.setState({
        title,
        subject,
        body,
        reset: props.template,
        cat: props.category,
      });
      if (document.getElementById('body') !== null) {
        let bodyTag = document.getElementById('body');
        bodyTag.innerText = '';
        bodyTag.style.whiteSpace = 'pre-line';
      }
      this.getReplaced(title, 'title');
      this.getReplaced(body, 'body');
    }
  }

  getReplaced = (value, type) => {
    let previewValue = value;
    // let tags = type === 'body' ? this.state.bodyTags : this.state.titleTags;
    for (let i = 0; i < this.tags.length; i++) {
      if (previewValue.includes(this.tags[i].labelItemBraces)) {
        previewValue = previewValue.replace(
          new RegExp(this.tags[i].labelItemBraces, 'g'),
          this.tags[i].name,
        );
      }
    }
    if (type === 'body') {
      if (document.getElementById('body') !== null) {
        document.getElementById('body').innerText = '';
        let bodyTag = document.getElementById('body');
        bodyTag.innerHTML = previewValue;
      }
    }
    if (type === 'title') {
      if (document.getElementById('title') !== null) {
        document.getElementById('title').innerText = '';
        let titleTag = document.getElementById('title');
        titleTag.innerHTML = previewValue;
      }
    }
  };

  setCharAt = (str, index, chr) => {
    if (index > str.length - 1) return str;
    return str.substr(0, index) + chr + str.substr(index + 1);
  };

  setTag = tag => {
    let bodyTags = this.state.bodyTags;
    let titleTags = this.state.titleTags;
    const {
      recent, body, title, subject, cat,
    } = this.state;
    let named =
      recent === 'subject'
        ? subject
        : recent === 'title'
          ? title
          : recent === 'body' && body;
    let position = this.state.position;
    let sub1 = named.substring(position - 20, position - 1);
    let sub2 = named.substring(position - 1, position + 20);
    if (recent === 'body') {
      bodyTags.push(tag);
      this.setState({ bodyTags });
    }
    if (recent === 'title') {
      titleTags.push(tag);
      this.setState({ titleTags });
    }
    let toBeUsedValue = '';
    for (let i = 0; i < this.tags.length; i++) {
      if (this.tags[i].labelItem === tag) {
        toBeUsedValue = this.tags[i].labelItemBraces;
      }
    }
    if (recent === 'body') {
      let value = `${body.slice(0, position)}${toBeUsedValue} ${body.slice(position)}`;
      if (
        sub1.includes('{{{') &&
        !sub1.includes('}}}') &&
        sub2.includes('}}') &&
        !sub2.includes('{{{')
      ) {
        console.log('');
      } else {
        this.getReplaced(value, 'body');
        this.setState({
          tag,
          body: value,
          position: position + tag.length + 7,
        });
      }
    } else if (recent === 'title') {
      if (cat === 'belongingTo' && toBeUsedValue === '{{{Pickup Name}}}') {
        this.setState({
          open: true,
          error: true,
          successMessage: 'Pickup Name is not allowed in Headline!',
        });
      } else {
        let value = `${title.slice(0, position)}${toBeUsedValue} ${title.slice(position)}`;
        if (
          sub1.includes('{{{') &&
          !sub1.includes('}}}') &&
          sub2.includes('}}') &&
          !sub2.includes('{{{')
        ) {
          // console.log('');
        } else {
          this.getReplaced(value, 'title');
          this.setState({ tag, title: value });
        }
      }
    } else if (recent === 'subject') {
      if (cat === 'belongingTo' && toBeUsedValue === '{{{Pickup Name}}}') {
        this.setState({
          open: true,
          error: true,
          successMessage: 'Pickup Name is not allowed in Subject!',
        });
      } else {
        let value = `${subject.slice(
          0,
          position,
        )}${toBeUsedValue} ${subject.slice(position)}`;
        if (
          sub1.includes('{{{') &&
          !sub1.includes('}}}') &&
          sub2.includes('}}') &&
          !sub2.includes('{{{')
        ) {
          // console.log('');
        } else {
          this.setState({ tag, subject: value });
        }
      }
    }
  };

  handleRecent = (name, e) => {
    let position = e.target.selectionStart;

    this.setState({ recent: name });
    if (position) {
      this.setState({ position });
    }
  };

  handleChange = name => e => {
    const { back } = this.state;
    this.setState({ back: false });
    let named =
      name === 'subject'
        ? this.state.subject
        : name === 'title'
          ? this.state.title
          : name === 'body' && this.state.body;
    let pos = e.target.selectionStart;
    if (back === true) pos += 2;
    let sub1 = named.substring(pos - 20, pos - 1);
    let sub2 = named.substring(pos - 1, pos + 20);
    if (
      sub1.includes('{{{') &&
      !sub1.includes('}}}') &&
      sub2.includes('}}') &&
      !sub2.includes('{{{')
    ) {
      // console.log('');
    } else {
      if (sub1.includes('{{{') && sub1.includes('}}}')) {
        if (sub1.indexOf('{{{') > sub1.lastIndexOf('}}}')) {
          return;
        }
      } else if (sub2.includes('}}}') && sub2.includes('{{{')) {
        if (sub2.indexOf('}}}') < sub2.indexOf('{{{')) {
          return;
        }
      }

      let subStartBracketCheck = named.substring(pos - 2, pos - 1);
      let subEndBracketCheck = named.substring(pos - 1, pos);
      if (
        (subStartBracketCheck.includes('{') &&
          subEndBracketCheck.includes('{')) ||
        (subStartBracketCheck.includes('}') && subEndBracketCheck.includes('}'))
      ) {
        return;
      } else if (subEndBracketCheck === '}') {
        let subEndAllBracketCheck = named.substring(pos - 3, pos);
        if (subEndAllBracketCheck.includes('}}}')) {
          return;
        }
      }
      if (
        named.includes('{{{Member Name}}}') &&
        named.charAt(e.target.selectionStart) === '}' &&
        named.charAt(e.target.selectionStart - 3) === 'e' &&
        named.charAt(e.target.selectionStart - 4) === 'm' &&
        named.charAt(e.target.selectionStart - 5) === 'a' &&
        named.charAt(e.target.selectionStart - 6) === 'N' &&
        named.charAt(e.target.selectionStart - 7) === ' ' &&
        named.charAt(e.target.selectionStart - 8) === 'r'
      ) {
        let value = this.deleteTag(
          '{{{Member Name}}}',
          named,
          e.target.selectionStart + 1,
        );
        this.setState({ [name]: value });
        if (name === 'body') {
          this.getReplaced(value, 'body');
        } else if (name === 'title') {
          this.getReplaced(value, 'title');
        }
      } else if (
        named.includes('{{{Building}}}') &&
        named.charAt(e.target.selectionStart) === '}' &&
        named.charAt(e.target.selectionStart - 3) === 'g'
      ) {
        let value = this.deleteTag(
          '{{{Building}}}',
          named,
          e.target.selectionStart + 1,
        );
        this.setState({ [name]: value });
        if (name === 'body') {
          this.getReplaced(value, 'body');
        } else if (name === 'title') {
          this.getReplaced(value, 'title');
        }
      } else if (
        named.includes('{{{Pickup Name}}}') &&
        named.charAt(e.target.selectionStart) === '}' &&
        named.charAt(e.target.selectionStart - 8) === 'p' &&
        named.charAt(e.target.selectionStart - 9) === 'u'
      ) {
        let value = this.deleteTag(
          '{{{Pickup Name}}}',
          named,
          e.target.selectionStart + 1,
        );
        this.setState({ [name]: value });
        if (name === 'body') {
          this.getReplaced(value, 'body');
        } else if (name === 'title') {
          this.getReplaced(value, 'title');
        }
      } else if (
        named.includes('{{{Package count}}}') &&
        named.charAt(e.target.selectionStart) === '}' &&
        named.charAt(e.target.selectionStart - 3) === 't'
      ) {
        let value = this.deleteTag(
          '{{{Package count}}}',
          named,
          e.target.selectionStart + 1,
        );
        this.setState({ [name]: value });
        if (name === 'body') {
          this.getReplaced(value, 'body');
        } else if (name === 'title') {
          this.getReplaced(value, 'title');
        }
      } else if (
        named.includes('{{{Discard Reason}}}') &&
        named.charAt(e.target.selectionStart) === '}' &&
        named.charAt(e.target.selectionStart - 5) === 's'
      ) {
        let value = this.deleteTag(
          '{{{Discard Reason}}}',
          named,
          e.target.selectionStart + 1,
        );
        this.setState({ [name]: value });
        if (name === 'body') {
          this.getReplaced(value, 'body');
        } else if (name === 'title') {
          this.getReplaced(value, 'title');
        }
      } else {
        this.setState({
          [name]: e.target.value,
          position: e.target.selectionStart,
        });
        if (name !== 'subject') {
          let value = e.target.value;
          if (name === 'body') {
            this.getReplaced(value, 'body');
          } else if (name === 'title') {
            this.getReplaced(value, 'title');
          }
        }
      }
    }
  };

  deleteTag = (name, str, position) => {
    let value = str;
    for (let i = name.length; i > 0; i--) {
      value = this.setCharAt(value, position - i, ' ');
    }
    value = value.replace(/ {1,}/g, ' ');
    return value;
  };

  handleClick = state => () => {
    this.setState({ open: true, ...state });
  };

  handleReset = () => {
    const { reset } = this.state;
    let body =
      new DOMParser()
        .parseFromString(reset.content_json.body, 'text/html')
        .body.innerHTML.replace(
          new RegExp('<span[^>]+>(.*?)(?:</span>)', 'g'),
          '$1',
        ) || '';
    let title = reset.content_json.title || '';
    let subject = reset.content_json.subject || '';
    body = this.handleReplace(body);
    title = this.handleReplace(title);
    subject = this.handleReplace(subject);
    this.setState({
      title,
      subject,
      body,
      edit: false,
    });
    this.getReplaced(title, 'title');
    this.getReplaced(body, 'body');
  };

  handleReplace = value => {
    let temp = value;
    for (let i = 0; i < this.tags.length; i++) {
      if (temp.includes(this.tags[i].label)) {
        temp = temp.replace(
          new RegExp(this.tags[i].label, 'g'),
          this.tags[i].labelItemBraces,
        );
      }
    }
    return temp;
  };

  tags = [
    {
      label: '{{{location}}}',
      labelItem: 'Building',
      labelItemBraces: '{{{Building}}}',
      name: 'Front Desk of 500 7th Ave, New York, NY 1234, New York City',
    },
    {
      label: '{{{notification_member_name}}}',
      labelItem: 'Member Name',
      labelItemBraces: '{{{Member Name}}}',
      name: 'Harry Potter',
    },
    {
      label: '{{{pickup_name}}}',
      labelItem: 'Pickup Name',
      labelItemBraces: '{{{Pickup Name}}}',
      name: 'Ron Weasly',
    },
    this.props.type === 'multiple' && {
      label: '{{{package_count}}}',
      labelItem: 'Package count',
      labelItemBraces: '{{{Package count}}}',
      name: '2',
    },
    this.props.topic === 'DISCARD' && {
      label: '{{{discard_reason}}}',
      labelItem: 'Discard Reason',
      labelItemBraces: '{{{Discard Reason}}}',
      name:
        'The package was incorrectly routed to you and has therefore been discarded.',
    },
  ];

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSave = () => {
    const { title, subject, body } = this.state;
    const {
      topic, type, category, t,
    } = this.props;
    let cat = category;
    if (body.includes('{{{Member Name}}}')) {
      if (topic === 'DISCARD' && !body.includes('{{{Discard Reason}}}')) {
        this.setState({
          open: true,
          error: true,
          successMessage: t('email.discardreason'),
        });
      } else if (
        type === 'multiple' &&
        !(
          body.includes('{{{Package count}}}') ||
          title.includes('{{{Package count}}}') ||
          subject.includes('{{{Package count}}}')
        )
      ) {
        this.setState({
          open: true,
          error: true,
          successMessage: t('email.packagecount'),
        });
      } else if (
        (cat === 'someone' || cat === 'belongingTo') &&
        !body.includes('{{{Pickup Name}}}')
      ) {
        this.setState({
          open: true,
          error: true,
          successMessage: t('email.pickupname'),
        });
      } else if (
        cat === 'belongingTo' &&
        (title.includes('{{{Pickup Name}}}') ||
          subject.includes('{{{Pickup Name}}}'))
      ) {
        this.setState({
          open: true,
          error: true,
          successMessage: 'Pickup Name is not allowed in Title/Subject!',
        });
      } else {
        this.setState({ openConfirm: true });
      }
    } else {
      this.setState({
        open: true,
        error: true,
        successMessage: t('email.membername'),
      });
    }
  };

  sendCall = () => {
    const { title, subject, body } = this.state;
    let updatedTitle = this.handleValue(title);

    let updatedSubject = this.handleValue(subject);

    let updatedBody = this.handleValue(body);
    const {
      topic,
      type,
      building_id,
      notification_type,
      t,
      defaultTemplate,
      category,
      template,
      isLgacy_email,
      template_id,
      history,
      tenant_id
    } = this.props;
    let cat = category;
    let content_json = {
      ...template.content_json,
      title: updatedTitle,
      subject: updatedSubject,
      body: updatedBody,
    };
    console.log(notification_type);
    this.setState({ loading: true });
    if (topic === 'PICKUP') {
      content_json = defaultTemplate.content_json;
      if (cat === 'normal') {
        content_json.body = updatedBody;
        content_json.title = updatedTitle;
        content_json.subject = updatedSubject;
      } else if (cat === 'someone') {
        content_json.body_picked_by = updatedBody;
        content_json.someone_title = updatedTitle;
        content_json.someone_subject = updatedSubject;
      } else if (cat === 'belongingTo') {
        content_json.body_belonging_to = updatedBody;
        content_json.title = updatedTitle;
        content_json.subject = updatedSubject;
      }
    }
    axios
      .post(`${api}/settings/notification_template`, {
        content_json,
        topic: topic.toLowerCase(),
        type,
        building_id,
        notification_type: isLgacy_email ? notification_type : 'mailroom_emails'
        ,
      })
      // eslint-disable-next-line no-unused-vars
      .then(res => {
        const updated_id = res.data.template_id;
        if (+template_id !== updated_id) {
          history.replace(`/building_detail/${building_id}/email/${updated_id}/${topic}/${tenant_id}`);
        }
        this.setState({
          open: true,
          loading: false,
          successMessage: t('email.updated'),
          openConfirm: false,
        }, this.props.pickupTemplateUpdateState(content_json));
      })
      .catch(err => {
        this.setState({
          loading: false,
          open: true,
          successMessage: err.message,
          error: true,
        });
      });
  };

  handleExited = () => {
    this.setState({ error: false });
    const { successMessage } = this.state;
    if (successMessage.includes('updated successfully')) {
      this.setState({ goBack: false });
    }
  };

  handleValue = value => {
    let replacedValue = value;
    if (value.includes('{{{Building}}}')) {
      replacedValue = replacedValue.replace(
        /{{{Building}}}/g,
        '{{{location}}}',
      );
    }
    if (value.includes('{{{Member Name}}}')) {
      replacedValue = replacedValue.replace(
        /{{{Member Name}}}/g,
        '{{{notification_member_name}}}',
      );
    }
    if (value.includes('{{{Pickup Name}}}')) {
      replacedValue = replacedValue.replace(
        /{{{Pickup Name}}}/g,
        '{{{pickup_name}}}',
      );
    }
    if (value.includes('{{{Package count}}}')) {
      replacedValue = replacedValue.replace(
        /{{{Package count}}}/g,
        '{{{package_count}}}',
      );
    }
    if (value.includes('{{{Discard Reason}}}')) {
      replacedValue = replacedValue.replace(
        /{{{Discard Reason}}}/g,
        '{{{discard_reason}}}',
      );
    }
    if (value.includes('<br/>')) {
      replacedValue = replacedValue.replace(
        '<br/>',
        '<p style="color:white"><br/>',
      );
      replacedValue += '</p>';
    }
    return replacedValue;
  };

  handleEmailError = message => {
    this.setState({
      error: true,
      successMessage: message,
      open: true,
      openConfirm: false,
    });
  };

  handleEmailSuccess = message => {
    this.setState({ successMessage: message, open: true, openConfirm: false });
  };

  handleConfirmClose = () => {
    this.setState({ openConfirm: false });
  };

  handleEnter = e => {
    let value = e.target.value;
    const { position, recent } = this.state;
    if (e.keyCode === 13 && recent === 'body') {
      value = `${value.slice(0, position)}<br>${value.slice(position)}`;
      this.setState({ body: value });
    }
    if (e.keyCode === 8) {
      this.setState({ back: true });
    }
  };

  render() {
    const {
      successMessage,
      open,
      title,
      subject,
      body,
      loading,
      error,
      goBack,
      openConfirm,
    } = this.state;
    const {
      classes,
      building_id,
      t,
      topic,
      type,
      defaultTemplate,
      category,
      width,
    } = this.props;
    let cat = category;
    // let address;
    // switch (env && env.includes('wework')) {
    //   case true: address = 'WeWork HQ \n115 W. 18th Street \nNew York, NY 10011 \n\nsupport@packagex.io';
    //     break;
    //   default: address = '500 7th Ave, \nNew York, NY 10018 \n\nsupport@packagex.io';
    // }
    return goBack ? (
      <Redirect to={`/building_detail/${building_id}`} />
    ) : (
      <div className={classes.parent}>
        <div>
          <div className={classes.root}>
            <Alert
              open={open}
              message={successMessage}
              onClose={this.handleClose}
              onExited={this.handleExited}
              variant={error ? 'error' : 'success'}
              forceOnExited
            />
            <Dialog
              open={openConfirm}
              onClose={this.handleConfirmClose}
              fullScreen={width === 'sm' || width === 'xs'}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <DialogTitle id="alert-dialog-title">{t('email.areYouSure')}</DialogTitle>
                <IconButton
                  onClick={this.handleConfirmClose}
                  style={{ height: 'max-content' }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t('email.emailDialogueMsg')}
                  <br />
                  {t('email.emailDialogueNote')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <SendTestDialog
                  content_json={{ title, body, subject }}
                  topic={topic}
                  type={type}
                  t={t}
                  onSuccess={this.handleEmailSuccess}
                  onError={this.handleEmailError}
                  cat={cat}
                  defaultTemplate={defaultTemplate}
                  handleValues={this.handleValue}
                  building_id={building_id}
                />
                <BlueButton
                  onClick={this.sendCall}
                  loading={loading}
                  height={50}
                  width="150px"
                >
                  {t('email.save')}
                  {/* &nbsp;{' Anyway'} */}
                  &nbsp;{t('email.Anyway')}
                </BlueButton>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <div className={classes.gridTitle}>{t('email.subject')}</div>
            <CustomInput
              id="sub"
              value={subject}
              className={classes.input}
              multiline
              // autoFocus
              rowsMax={25}
              inputProps={{ style: { paddingTop: 0 } }}
              onChange={this.handleChange('subject')}
              onClick={e => this.handleRecent('subject', e)}
              onKeyDown={e => this.handleEnter(e)}
              endAdornment={
                <EmailDropdownComponent
                  getTag={tagName => this.setTag(tagName)}
                  handleRecent={() => {
                    this.setState({
                      recent: 'subject',
                      position: subject.length,
                    });
                  }}
                  topic={topic}
                  type={type}
                  cat={cat}
                  t={t}
                  recent="subject"
                />
              }
            />
          </div>
          <div style={{ marginTop: 15 }}>
            <div className={classes.gridTitle}>{t('email.headline')}</div>
            <CustomInput
              id="headline"
              value={title}
              className={classes.input}
              multiline
              rowsMax={25}
              inputProps={{ style: { paddingTop: 0 } }}
              onChange={this.handleChange('title')}
              onClick={e => this.handleRecent('title', e)}
              onKeyDown={e => this.handleEnter(e)}
              endAdornment={
                <EmailDropdownComponent
                  getTag={tagName => this.setTag(tagName)}
                  handleRecent={() => {
                    this.setState({
                      recent: 'title',
                      position: title.length,
                    });
                  }}
                  topic={topic}
                  type={type}
                  cat={cat}
                  t={t}
                  recent="title"
                />
              }
            />
          </div>
          <div style={{ marginTop: 15 }}>
            <div className={classes.gridTitle}>{t('email.body')}</div>
            <CustomInput
              id="bod"
              value={body}
              className={classes.inputText}
              multiline
              rowsMax={25}
              inputProps={{ style: { paddingTop: 0 } }}
              fullWidth="true"
              onChange={this.handleChange('body')}
              onClick={e => this.handleRecent('body', e)}
              onKeyDown={e => this.handleEnter(e)}
              endAdornment={
                <EmailDropdownComponent
                  getTag={tagName => this.setTag(tagName)}
                  handleRecent={() => {
                    this.setState({
                      recent: 'body',
                      position: body.length,
                    });
                  }}
                  topic={topic}
                  type={type}
                  cat={cat}
                  t={t}
                  recent="body"
                />
              }
            />
          </div>
          {/* 
            <div style={{ marginTop: 15 }}>
              <div className={classes.gridTitle}>{t('email.footer')}</div>
              <CustomInput
                style={{ color: '#787a79' }}
                defaultValue={address}
                className={classes.input}
                // disableUnderline="true"
                multiline="true"
                fullWidth="true"
                // disabled
                inputProps={{ style: { paddingTop: 0, height: 95 } }}
              />
            </div> //Remove Footer
           */}
        </div>
        <div>
          <Divider />
          <div className={classes.root}>
            <div />
            <div className={classes.foot}>
              <Button
                style={{ fontSize: 16, width: 110 }}
                className={classes.button}
                onClick={this.handleReset}
              >
                {t('email.reset')}
              </Button>
              <BlueButton
                loading={loading}
                height={40}
                width="110px"
                onClick={this.handleSave}
              >
                {t('email.save')}
              </BlueButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmailTemplate.propTypes = {
  t: PropTypes.func.isRequired,
  pickupTemplateUpdateState: PropTypes.func.isRequired,
  classes: PropTypes.node.isRequired,
  width: PropTypes.node.isRequired,
  building_id: PropTypes.string.isRequired,
  template: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  notification_type: PropTypes.string.isRequired,
  defaultTemplate: PropTypes.node.isRequired,
  category: PropTypes.node.isRequired,
  isLgacy_email: PropTypes.bool.isRequired,
  template_id: PropTypes.string.isRequired,
  tenant_id: PropTypes.string.isRequired,
  history: PropTypes.node.isRequired,

};

export default withStyles(styles)(withRouter(withWidth()(EmailTemplate)));

// export default compose(
//   withRouter,
//   connect(mapStateToProps, mapDispatchToProps)
// )(NotificationsPopup);

