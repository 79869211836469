import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyBDbyVvZ1Snop3gJpMPeV7NGGOwybyR9aw',
  authDomain: 'packagex-49308.firebaseapp.com',
  databaseURL: 'https://packagex-49308.firebaseio.com',
  projectId: 'packagex-49308',
  storageBucket: 'packagex-49308.appspot.com',
  messagingSenderId: '1025470808143',
  appId: '1:1025470808143:web:c66435eeeec60477352a3a',
  measurementId: 'G-YFGSZ4Z03X'
};

firebase.initializeApp(firebaseConfig);
export const firebaseAnalytics = firebase.analytics(); // eslint-disable-next-line
