import React from 'react';
import PropTypes from 'prop-types';

const Truncate = ({ children, style, width }) => (
  <div
    style={{
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width,
      ...style,
    }}
  >
    {children}
  </div>
);

Truncate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired,
};

export default Truncate;
