/* eslint-disable quotes */
/* eslint-disable react/no-array-index-key */
import React, { Fragment, Component } from 'react';
import { LinearProgress, withStyles, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import BarCharts from './BarChart';
import CourierBarChart, { color } from './CourierBarChart';
import { Flag } from './Flag';
import { AlignCenter } from '../../../UI/ResponsiveRelated';
import DateRange from './DateRange';

const styles = () => ({
  linearColorBlue: {
    backgroundColor: '#027AFF',
  },
  linearBarColorBlue: {
    backgroundColor: '#027AFF',
  },
  linearColorYellow: {
    backgroundColor: '#FFB02F',
  },
  linearBarColorYellow: {
    backgroundColor: '#FFB02F',
  },
  linearColorRed: {
    backgroundColor: '#FF1E59',
  },
  linearBarColorRed: {
    backgroundColor: '#FF1E59',
  },
  linearColorGreen: {
    backgroundColor: '#02B67C',
  },
  linearBarColorGreen: {
    backgroundColor: '#02B67C',
  },
  linearColorPink: {
    backgroundColor: '#E21AD8',
  },
  linearBarColorPink: {
    backgroundColor: '#E21AD8',
  },
  linearColorPurple: {
    backgroundColor: '#804DDF',
  },
  linearBarColorPurple: {
    backgroundColor: '#804DDF',
  },
});

class CardWithBars extends Component {
  state = {
    completed: 0,
    buffer: 10,
  };

  componentDidMount() {
    this.timer = setInterval(this.progress, 500);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getPackageLabelName = (name) => {
    const { t } = this.props;
    const packageLabels = {
      confidential: t('main.confidential'),
      timesensitive: t('main.timeSensitive'),
      fragile: t('main.fragile'),
      oversized: t('main.oversized'),
      returntosender: t('main.returnToSender'),
      others: t('main.others'),
    };
    if (name.includes('Return To Sender')) {
      return packageLabels.returntosender;
    } else if (name.includes('Time Sensitive')) {
      return packageLabels.timesensitive;
    } else {
      return packageLabels[name];
    }
  };


  progress = () => {
    if (this.props.loading) {
      const { completed } = this.state;
      if (completed > 100) {
        this.setState({ completed: 0, buffer: 10 });
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        this.setState({
          completed: completed + diff,
          buffer: completed + diff + diff2,
        });
      }
    }
  };

  render() {
    const {
      t,
      classes,
      title,
      courierBreakdown,
      loading,
      data,
      error,
      courierPercentages,
      categoryPercentages,
      total,
      categoryTotal,
      courierTotal,
    } = this.props;
    const { completed, buffer } = this.state;
    return (
      <Paper className="barchart-paper just-paper ">
        {/* <div
          style={{
            position: "absolute",
            top: courierBreakdown ? "54.7%" : "54.5%",
            left: courierBreakdown ? 60 : 109,
            right: 0,
            zIndex: 1,
            width: 10,
            height: 2,
            background: "white",
          }}
        /> */}
        {/* <div
          style={{
            ...style.flex.justify_between,
            ...style.flex.display,
            ...style.flex.align_center,
          }}
        >

          <div style={style.common.subheading}>{title}</div>
        </div> */}

        <div className="space-between-with-block">
          <div className="top-building-heading truncated-heading black"> {title}</div>
          <DateRange {...this.props} />
        </div>
        {loading && (
          <div>
            <div
              style={{
                marginTop: 100,
                marginBottom: 25,
              }}
            >
              <LinearProgress
                variant="buffer"
                value={completed}
                valueBuffer={buffer}
                classes={
                  courierBreakdown
                    ? {
                      colorPrimary: classes.linearColorRed,
                      barColorPrimary: classes.linearColorRed,
                    }
                    : {
                      colorPrimary: classes.linearColorRed,
                      barColorPrimary: classes.linearBarColorRed,
                    }
                }
              />
            </div>
            <div
              style={{
                marginTop: 25,
                marginBottom: 25,
              }}
            >
              <LinearProgress
                variant="buffer"
                value={completed}
                valueBuffer={buffer}
                classes={
                  courierBreakdown
                    ? {
                      colorPrimary: classes.linearColorGreen,
                      barColorPrimary: classes.linearColorGreen,
                    }
                    : {
                      colorPrimary: classes.linearColorGreen,
                      barColorPrimary: classes.linearBarColorGreen,
                    }
                }
              />
            </div>
            <div
              style={{
                marginTop: 25,
                marginBottom: 25,
              }}
            >
              <LinearProgress
                variant="buffer"
                value={completed}
                valueBuffer={buffer}
                classes={{
                  colorPrimary: classes.linearColorBlue,
                  barColorPrimary: classes.linearBarColorBlue,
                }}
              />
            </div>
            <div
              style={{
                marginTop: 25,
                marginBottom: 25,
              }}
            >
              <LinearProgress
                variant="buffer"
                value={completed}
                valueBuffer={buffer}
                classes={{
                  colorPrimary: classes.linearColorPurple,
                  barColorPrimary: classes.linearBarColorPurple,
                }}
              />
            </div>
            <div
              style={{
                marginTop: 25,
                marginBottom: 20,
              }}
            >
              <LinearProgress
                variant="buffer"
                value={completed}
                valueBuffer={buffer}
                classes={
                  courierBreakdown
                    ? {
                      colorPrimary: classes.linearColorYellow,
                      barColorPrimary: classes.linearBarColorYellow,
                    }
                    : {
                      colorPrimary: classes.linearColorYellow,
                      barColorPrimary: classes.linearBarColorYellow,
                    }
                }
              />
            </div>
            <div style={{ marginTop: 20, marginBottom: 70 }}>
              <LinearProgress
                variant="buffer"
                value={completed}
                valueBuffer={buffer}
                classes={{
                  colorPrimary: classes.linearColorPink,
                  barColorPrimary: classes.linearBarColorPink,
                }}
              />
            </div>
          </div>
        )}
        {/* {!loading && !error && !courierBreakdown && total !== 0 && (
          <Fragment>
            <BarCharts
              totalValue={courierBreakdown ? courierTotal : categoryTotal}
              data={courierBreakdown ? courierPercentages : categoryPercentages}
              courierBreakdown={courierBreakdown}
            />
          </Fragment>
        )} */}
        {!error && !loading && total === 0 && (
          <div className="align-center height-300px">
            {t('common.noData')}
          </div>
        )}
        {error && <AlignCenter style={{ marginTop: 100 }}>{error}</AlignCenter>}
        {!error && !loading && total !== 0 && (
          <div style={{
            textTransform: "capitalize",
            height: courierBreakdown ? "250px" : "250px",
            maxHeight: "calc(100% - 50px)"
          }}>
            {courierBreakdown ? (
              <div style={{
                height: "100%", width: "100%", justifyContent: "space-around", display: "flex", flexDirection: "column"
              }}>

                {data.map((item, i) => (
                  <div style={{
                    display: "flex", alignItems: "center"
                  }}>
                    <Flag
                      t={t}
                      number={i}
                      data={data}
                      color={color[i]}
                      name={item.name}
                      courierBreakdown={courierBreakdown}
                      value={
                        loading ? (
                          <div className="spinner">
                            <div className="bounce1" />
                            <div className="bounce2" />
                            <div className="bounce3" />
                          </div>
                        ) : (
                            item.val || 0
                          )
                      }
                    />
                    <Fragment>
                      <CourierBarChart
                        index={i}
                        totalValue={courierTotal}
                        data={courierPercentages.filter(cp => cp.name === item.name)}
                      />
                    </Fragment>
                  </div>
                ))}
              </div>
            ) : (
                // <div style={{ position: 'absolute', top: 65, left: 17 }}>
                <div style={{
                  height: "100%",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: categoryPercentages.length > 7 ? "auto" : "hidden"
                }}>

                  {data.map((item, i) => (
                    <div style={{
                      display: "flex", alignItems: "center"
                    }}>
                      <Flag
                        // labels
                        t={t}
                        number={i}
                        data={data}
                        // color="#FF1E59"
                        color={color[i]}
                        // name={t('main.confidential')}
                        // name={this.getPackageLabelName(name.toLowerCase())}
                        name={item.name.toLowerCase()}
                        // label="confidential"
                        label={this.getPackageLabelName[item.name.toLowerCase()]}
                        image={item.image}
                        value={
                          loading ? (
                            <div className="spinner">
                              <div className="bounce1" />
                              <div className="bounce2" />
                              <div className="bounce3" />
                            </div>
                          ) : (
                              item.val || 0
                            )
                        }
                      />
                      <Fragment>
                        <BarCharts
                          index={i}
                          totalValue={categoryTotal}
                          category
                          data={categoryPercentages.filter(cp => cp.name === item.name)}
                        />
                      </Fragment>
                    </div>
                  ))}

                </div>
              )}
          </div>
        )}
      </Paper>
    );
  }
}

CardWithBars.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  courierBreakdown: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.node.isRequired,
  error: PropTypes.string.isRequired,
  classes: PropTypes.node.isRequired,
  total: PropTypes.node.isRequired,
  courierPercentages: PropTypes.node.isRequired,
  categoryPercentages: PropTypes.node.isRequired,
  courierTotal: PropTypes.number.isRequired,
  categoryTotal: PropTypes.number.isRequired,
};
export default withStyles(styles)(CardWithBars);
