/* eslint-disable no-unused-vars */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { withWidth, DialogTitle, IconButton } from '@material-ui/core';
import Alert from '../../../UI/Poper';
import BlueButton from '../../../UI/BlueButton';
import { server as api } from '../../../../server/server';
import { validateEmail } from '../../../../util';
import { CustomInput } from '../../../UI/Input';

class SendTestDialog extends React.Component {
  state = {
    open: false,
    email: '',
    disabled: true,
    loading: false,
    error: false,
    emailSent: '',
  };

  handleInput = e => {
    let errorEmail = !validateEmail(e.target.value);
    this.setState({ disabled: errorEmail, email: e.target.value });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
    // this.props.onClick();
  };

  handleClose = () => {
    this.setState({
      open: false,
      email: '',
      disabled: true,
      emailSent: '',
    });
  };

  handleExited = () => {
    this.setState({
      error: false,
    });
  };

  handleTest = e => {
    e.preventDefault();
    this.setState({ loading: true, disabled: true });
    const { email } = this.state;
    const {
      topic,
      type,
      content_json,
      onSuccess,
      onError,
      defaultTemplate,
      cat,
      handleValues,
      building_id
    } = this.props;
    let pickup_type;
    const { body, title, subject } = content_json;
    let updatedTitle = handleValues(title);

    let updatedSubject = handleValues(subject);

    let updatedBody = handleValues(body);
    let data_json = {
      title: updatedTitle,
      subject: updatedSubject,
      body: updatedBody,
    };
    if (topic === 'PICKUP') {
      data_json = defaultTemplate.content_json;
      if (cat === 'normal') {
        data_json.body = updatedBody;
        data_json.title = updatedTitle;
        data_json.subject = updatedSubject;
        pickup_type = 'pickup_normal';
      } else if (cat === 'someone') {
        data_json.body_picked_by = updatedBody;
        data_json.someone_title = updatedTitle;
        data_json.someone_subject = updatedSubject;
        pickup_type = 'pickup_someone';
      } else if (cat === 'belongingTo') {
        data_json.body_belonging_to = updatedBody;
        data_json.title = updatedTitle;
        data_json.subject = updatedSubject;
        pickup_type = 'pickup_belonging_to';
      }
    }

    Axios.post(`${api}/settings/notification_template/notification_test`, {
      email,
      topic: topic.toLowerCase(),
      type,
      content_json: data_json,
      pickup_type,
      building_id
    })
      .then(res => {
        this.setState({
          loading: false,
          open: false,
          email: '',
          disabled: false,
          emailSent: `Email sent successfully at ${email}!`,
        });
        // onSuccess(`Email sent successfully at ${email}!`);
      })
      .catch(err => {
        this.setState({
          loading: false,
          open: false,
          email: '',
          disabled: false,
        });
        let error = err.response
          ? err.response.data
            ? err.response.data.message
            : err.message
          : err.message;
        if (
          error.includes('subject') ||
          error.includes('body') ||
          error.includes('title')
        ) {
          error = 'missing body/headline/subject';
        }
        onError(`Email not sent at ${email} due to ${error}`);
      });
  };

  render() {
    const {
 email, disabled, loading, emailSent, error 
} = this.state;
    const { classes, t, width } = this.props;
    return (
      <div>
        <Alert
          open={emailSent !== ''}
          message={emailSent}
          onClose={this.handleClose}
          onExited={this.handleExited}
          variant={error ? 'error' : 'success'}
          forceOnExited
        />
        <Button
          style={{
            color: '#3498DB',
            fontSize: 16,
            fontWeight: 'bold',
            marginRight: 16,
          }}
          onClick={this.handleClickOpen}
          id="sendTest"
        >
          {t('email.sendtest')}
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullScreen={width === 'xs' || width === 'sm'}
        >
          <form
            onSubmit={e => this.handleTest(e)}
            style={{ minWidth: width === 'xs' || width === 'sm' ? 0 : 500 }}
          >
            <DialogContent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <DialogTitle style={{ padding: 0 }}>
                  {t('email.sendtest')}
                </DialogTitle>
                <IconButton
                  onClick={this.handleClose}
                  style={{ height: 'max-content' }}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              <CustomInput
                id="email"
                value={email}
                onChange={this.handleInput}
                margin="dense"
                type="email"
                autoFocus
                fullWidth
                style={{ marginTop: 16, flexWrap: 'nowrap' }}
                placeholder="Please enter your email below to send a test email."
              />
            </DialogContent>
            <DialogActions
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>
                <BlueButton
                  onClick={this.handleTest}
                  disabled={disabled}
                  loading={loading}
                  htmlType="submit"
                  width="125px"
                >
                  {t('email.send')}
                </BlueButton>
              </div>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

SendTestDialog.propTypes = {
  t: PropTypes.func.isRequired,
  handleValues: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  classes: PropTypes.node.isRequired,
  width: PropTypes.node.isRequired,
  content_json: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  defaultTemplate: PropTypes.node.isRequired,
  cat: PropTypes.string.isRequired,
  building_id: PropTypes.string.isRequired,
};
export default withWidth()(SendTestDialog);
