import moment from 'moment';
import { SET_SCANFILTER } from '../actions/scanFilterActions';
import { informSlackDBCodeErrs } from '../../util/informSlack';

let initialState = {
  filter: 0,
  graph: 0,
  mapFrom: moment(),
  mapTo: moment(),
  topScanningFrom: moment(),
  topScanningTo: moment(),
  leastScanningFrom: moment(),
  leastScanningTo: moment(),
  courierFrom: moment(),
  courierTo: moment(),
  categoryFrom: moment(),
  categoryTo: moment(),
};

try {
  initialState = localStorage.px_scanFilter ? JSON.parse(localStorage.px_scanFilter) : {
    filter: 0,
    graph: 0,
    mapFrom: moment(),
    mapTo: moment(),
    topScanningFrom: moment(),
    topScanningTo: moment(),
    leastScanningFrom: moment(),
    leastScanningTo: moment(),
    courierFrom: moment(),
    courierTo: moment(),
    categoryFrom: moment(),
    categoryTo: moment(),
  };
} catch (err) {
  informSlackDBCodeErrs(err, 'source > src/redux/reducers/scanFilterReducer', 'line # 36');
}


export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SCANFILTER: {
      const newState = {
        filter: action.payload.filter,
        graph: action.payload.graph,
        mapFrom: action.payload.mapFrom,
        mapTo: action.payload.mapTo,
        topScanningFrom: action.payload.topScanningFrom,
        topScanningTo: action.payload.topScanningTo,
        leastScanningFrom: action.payload.leastScanningFrom,
        leastScanningTo: action.payload.leastScanningTo,
        courierFrom: action.payload.courierFrom,
        courierTo: action.payload.courierTo,
        categoryFrom: action.payload.categoryFrom,
        categoryTo: action.payload.categoryTo
      };
      localStorage.px_scanFilter = JSON.stringify(newState);
      return newState;
    }
    default:
      return state;
  }
}
