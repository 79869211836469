/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  //   FormHelperText,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setUser as setUserDetail } from '../../../../redux/actions/userDeatilsActions';
import notify from '../../../../assets/Onboarding/notify.svg';
import { CallTrigger } from '../../../../util/Triggers/function';
import { CLA } from '../../../../util/Triggers/triggerNames';
import num from '../../../../assets/Onboarding/num.svg';
import pickup from '../../../../assets/Onboarding/pickup-checkout.svg';
import camera from '../../../../assets/Onboarding/Camera-icn.svg';
import pickupNow from '../../../../assets/Onboarding/pickup-now.svg';
import done from '../../../../assets/Onboarding/Done.svg';
import select from '../../../../assets/Onboarding/select.svg';
import Video from '../../../../assets/videos/Pick-up.mp4';
// import Video from '../../../../assets/Onboarding/NewVideos/latest12-06-2021/Pick-up_1.mp4';

class NotifyRecipients extends React.Component {
  componentDidMount() {
    this.props.handleDisablePrev(false);
    this.props.handleDisableNext(false);
    CallTrigger(CLA.DOWNLOAD_APP.Download_App_Screen, { Previous_event: '' });
  }
  handleSubmit = (event) => {
    CallTrigger(CLA.ALL_SET.All_Set_Continue_Bclick, { Previous_event: '' });
    event.preventDefault();
    localStorage.setItem('trialUser', true);
    localStorage.removeItem('building');
    localStorage.removeItem('clientName');
    localStorage.removeItem('onBoarding_email');

    // Set bit in Local Storage that indicates new user has not uploaded CSV
    localStorage.setItem('trialUserUploadCSV', true);
    this.props.setUserDetail(null);
    this.props.next();
    // this.props.history.replace('/dashboard');
  };
  render() {
    const { next, t } = this.props;
    return (
      <div className="onboard-new-outer-container">
        <Grid /*container*/ className="container" style={{ minWidth: '100%' }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              //   setwaiting(true);
              CallTrigger(CLA.DOWNLOAD_APP.Download_App_Continue_Bclick, {
                Previous_event: '',
              });
              next();
            }}
          >
            <Grid item xs={12} sm={12}>
              <h3 className="onboarding-welcome-title">
                {/* {t('onboarding.downloadApp.title')} */}
                Check an item out of Mailroom
              </h3>
              <br />
              <Grid xs={12} sm={12} container>
                <Grid xs={12} md={8}>
                  <p className="onboarding-welcome-description">
                    The last step you’ll perform will to be check the items out
                    of Mailroom once they’ve been handed over to your
                    recipients.
                  </p>
                  <br />
                  <ol className="onboard-new-instructions-list">
                    <li className="onboard-new-mb-15 onboard-new-mt-10">
                      Navigate to the{' '}
                      <img
                        src={pickup}
                        alt="pickup"
                        className="onboard-new-icon onboard-new-width-35px"
                      />
                      tab in the bottom left corner. This will display your
                      recipient directory.
                    </li>
                    <li className="onboard-new-mb-15 onboard-new-mt-10">
                      The
                      <img
                        src={num}
                        alt="num"
                        className="onboard-new-icon onboard-new-width-20px"
                      />
                      icon to the right of the recipient’s name will let you
                      know how many items are pending check out.
                    </li>
                    <li className="onboard-new-mb-15 onboard-new-mt-10">
                      Search for a recipient by typing a few characters into the
                      search bar.
                    </li>
                    <li className="onboard-new-mb-15 onboard-new-mt-10">
                      Select the recipient’s name to see their specific items on
                      hand.
                    </li>
                    <li className="onboard-new-mb-15 onboard-new-mt-10">
                      Click the
                      <img
                        src={select}
                        alt="camera"
                        className="onboard-new-icon onboard-new-width-35px"
                      />
                      button in the top right corner to select multiple items.
                    </li>
                    <li className="onboard-new-mb-15 onboard-new-mt-10">
                      Select the items you want to check out and click the
                      <img
                        src={pickupNow}
                        alt="pickup-now"
                        className="onboard-new-icon onboard-new-width-95px"
                      />{' '}
                      button in the bottom right corner.
                    </li>
                    <li className="onboard-new-mb-15 onboard-new-mt-10">
                      Capture signature or select
                      <img
                        src={camera}
                        alt="camera"
                        className="onboard-new-icon onboard-new-width-20px"
                      />
                      icon to capture a photo.
                    </li>
                    <li className="onboard-new-mb-15 onboard-new-mt-10">
                      Click the{' '}
                      <img
                        src={done}
                        alt="done"
                        className="onboard-new-icon onboard-new-width-30px"
                      />{' '}
                      button to check the item(s) out.
                    </li>
                  </ol>
                </Grid>

                <Grid
                  xs={12}
                  md={3}
                  container
                  style={{
                    padding: '20px',
                    // paddingLeft: '20px',
                  }}
                >
                  <div className="video-section-onboarding-new">
                    <video
                      autoplay="autoplay"
                      muted="muted"
                      loop="loop"
                      className="video-player-new"
                      onLoadStart={(e) => this.setState({ isLoading: true })}
                      onLoadEnd={() => this.setState({ isLoading: false })}
                      preload="none"
                    >
                      <source src={Video} type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button
                type="submit"
                style={{
                  marginTop: 25,
                }}
                disabled={this.props.waiting}
                className={`onBoard-page-btn ${
                  this.props.waiting ? 'onBoard-page-btn-disabled' : ''
                }`}
                onClick={(event) => this.handleSubmit(event)}
              >
                FINISH
                {/* {t('onboarding.downloadApp.button')} */}
                {this.props.waiting && (
                  <div className="ml-2">
                    <CircularProgress size={15} className="white" />
                  </div>
                )}
              </Button>
            </Grid>
          </form>
        </Grid>
      </div>
    );
  }
}

NotifyRecipients.propTypes = {
  next: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  waiting: PropTypes.bool.isRequired,
  handleDisablePrev: PropTypes.func.isRequired,
  handleDisableNext: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setUserDetail: (user) => dispatch(setUserDetail(user)),
});

export default connect(null, mapDispatchToProps)(withRouter(NotifyRecipients));
