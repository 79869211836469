import { SET_BUILDING, GET_BUILDING, REMOVE_BUILDING } from '../actions/locationsFilterActions';

const initialState = localStorage.pxBuilding ? JSON.parse(localStorage.pxBuilding) : [];

export default (building = initialState, action) => {
  switch (action.type) {
    case SET_BUILDING: {
      building = action.payload;
      localStorage.pxBuilding = JSON.stringify(building);
      return building;
    }
    case GET_BUILDING: return building;
    case REMOVE_BUILDING: {
      localStorage.pxBuilding = JSON.stringify([]);
      building = initialState;
      return building;
    }
    default: return building;
  }
};
