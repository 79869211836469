export const SIGNUP = {
    Signin_screen: 'Signin_screen',
    Signin_signin_bclick: 'Signin_signin_bclick',
    Signin_attempt: 'Signin_attempt',
    Signin_success: 'Signin_success',
    Signin_fail: 'Signin_fail',
    Forgot_password_bclick: 'Forgot_password_bclick',
    Forgot_password_screen: 'Forgot_password_screen',
    Forgot_password_send_email_bclick: 'Forgot_password_send_email_bclick',
    Forgot_password_back_to_signin_bclick: 'Forgot_password_back_to_signin_bclick',
    Change_Forgot_password_screen: 'Change_Forgot_password_screen',
    Change_Forgot_password_reset_password_bclick: 'Change_Forgot_password_reset_password_bclick',
    Change_Forgot_password_attempt: 'Change_Forgot_password_attempt',
    Change_Forgot_password_success: 'Change_Forgot_password_success',
    Change_Forgot_password_fail: 'Change_Forgot_password_fail',
    Change_Forgot_password_back_to_signin_bclick: 'Change_Forgot_password_back_to_signin_bclick'
};

export const GENERAL = {
    Privacy_policy_bclick: 'Privacy_policy_bclick',
    Terms_of_service_bclick: 'Terms_of_service_bclick',
    Contact_us_bclick: 'Contact_us_bclick',
    Expand_drawer_bclick: 'Expand_drawer_bclick',
    Dasboard_option_bclick: 'Dasboard_option_bclick',
    Items_option_bclick: 'Items_option_bclick',
    Recipients_option_bclick: 'Recipients_option_bclick',
    Users_option_bclick: 'Users_option_bclick',
    Buildings_option_bclick: 'Buildings_option_bclick',
    Main_Search_attempt: 'Main_Search_attempt',
    Main_Search_success: 'Main_Search_success',
    Main_Search_fail: 'Main_Search_fail',
    Main_search_result_click: 'Main_search_result_click',
    Change_language: 'Change_language',
    Change_password_bclick: 'Change_password_bclick',
    Logout_bclick: 'Logout_bclick',
};

export const CHANGE_PASS_SC = {
    Change_password_screen: 'Change_password_screen',
    Change_password_screen_change_password_bclick: 'Change_password_screen_change_password_bclick',
    Change_password_attempt: 'Change_password_attempt',
    Change_password_success: 'Change_password_success',
    Change_password_fail: 'Change_password_fail,'
};

export const DASHBOARD = {
    dashboard_screen: 'dashboard_screen',
    dashboard_scan_details_scanned_bclick: 'dashboard_scan_details_scanned_bclick',
    dashboard_scan_details_notified_bclick: 'dashboard_scan_details_notified_bclick',
    dashboard_scan_details_picked_bclick: 'dashboard_scan_details_picked_bclick',
    dashboard_scan_details_filter: 'dashboard_scan_details_filter',
    dashboard_scan_details_graph_range_filter: 'dashboard_scan_details_graph_range_filter',
    dashboard_scan_details_graph_view_filter: 'dashboard_scan_details_graph_view_filter',
    dashboard_currently_held_items_last_3_days_bclick: 'dashboard_currently_held_items_last_3_days_bclick',
    dashboard_currently_held_items_last_3_to_10_days_bclick: 'dashboard_currently_held_items_last_3_to_10_days_bclick',
    dashboard_currently_held_items_over_10_days_bclick: 'dashboard_currently_held_items_over_10_days_bclick',
    dashboard_carrier_breakdown_carrier_details_bclick: 'dashboard_carrier_breakdown_carrier_details_bclick',
    dashboard_carrier_breakdown_calender_filter: 'dashboard_carrier_breakdown_calender_filter',
    dashboard_carrier_breakdown_calender_filter_apply_bclick: 'dashboard_carrier_breakdown_calender_filter_apply_bclick',
    dashboard_item_labels_label_details_bclick: 'dashboard_item_labels_label_details_bclick',
    dashboard_item_labels_calender_filter: 'dashboard_item_labels_calender_filter',
    dashboard_item_labels_calender_filter_apply_bclick: 'dashboard_item_labels_calender_filter_apply_bclick',
};

export const ItEMS_SC = {
    Items_screen: 'Items_screen',
    Items_data_fetch_attempt: 'Items_data_fetch_attempt',
    Items_data_fetch_success: 'Items_data_fetch_success',
    Items_data_fetch_fail: 'Items_data_fetch_fail',
    Items_thumbnail_fetch_attempt: 'Items_thumbnail_fetch_attempt',
    Items_thumbnail_fetch_success: 'Items_thumbnail_fetch_success',
    Items_thumbnail_fetch_fail: 'Items_thumbnail_fetch_fail',
    Items_list_view_bclick: 'Items_list_view_bclick',
    Items_grid_view_bclick: 'Items_grid_view_bclick',
    Items_export_data_calender_filter_bclick: 'Items_export_data_calender_filter_bclick',
    Items_export_data_calender_filter_popup_apply_bclick: 'Items_export_data_calender_filter_popup_apply_bclick',
    Items_mark_as_picked_up_bclick: 'Items_mark_as_picked_up_bclick',
    Items_mark_as_picked_up_popup_mark_as_picked_up_bclick: 'Items_mark_as_picked_up_popup_mark_as_picked_up_bclick',
    Items_discard_items_bclick: 'Items_discard_items_bclick',
    Items_discard_items_popup_discard_item_bclick: 'Items_discard_items_popup_discard_item_bclick',
    Items_item_thumbnail_bclick: 'Items_ item_thumbnail_bclick',
    Items_item_recipient_name_bclick: 'Items_item_recipient_name_bclick',
    Items_item_group_name_bclick: 'Items_item_group_name_bclick',
    Items_item_building_name_bclick: 'Items_item_building_name_bclick',
    Items_search_type: 'Items_search_type',
    Items_search_text_box_click: 'Items_search_text_box_click',
    Items_search_reset_bclick: 'Items_search_reset_bclick',
    Items_search_filter_bclick: 'Items_search_filter_bclick',
    Items_search_cancel_bclick: 'Items_search_cancel_bclick',
    Items_search_filter_picked_up_by_search_recipient_attempt: 'Items_search_filter_picked_up_by_search_recipient_attempt',
    Items_search_filter_picked_up_by_search_recipient_success: 'Items_search_filter_picked_up_by_search_recipient_success',
    Items_search_filter_picked_up_by_search_recipient_fail: 'Items_search_filter_picked_up_by_search_recipient_fail',
    Items_search_filter_scanned_by_search_user_attempt: 'Items_search_filter_scanned_by_search_user_attempt',
    Items_search_filter_scanned_by_search_user_success: 'Items_search_filter_scanned_by_search_user_success',
    Items_search_filter_scanned_by_search_user_fail: 'Items_search_filter_scanned_by_search_user_fail',
    Items_search_filter_recipients_search_recipient_attempt: 'Items_search_filter_recipients_search_recipient_attempt',
    Items_search_filter_recipients_search_recipient_success: 'Items_search_filter_recipients_search_recipient_success',
    Items_search_filter_recipients_search_recipient_fail: 'Items_search_filter_recipients_search_recipient_fail',
    Items_search_filter_groups_search_group_attempt: 'Items_search_filter_groups_search_group_attempt',
    Items_search_filter_groups_search_group_success: 'Items_search_filter_groups_search_group_success',
    Items_search_filter_groups_search_group_fail: 'Items_search_filter_groups_search_group_fail',
    Items_per_page: 'Items_per_page',
    Items_page_number_bclick: 'Items_page_number_bclick',
    Items_next_page_bclick: 'Items_next_page_bclick',
    Items_previous_page_bclick: 'Items_previous_page_bclick',
    Items_first_page_bclick: 'Items_first_page_bclick',
    Items_last_page_bclick: 'Items_last_page_bclick',
    Items_sort_by_name_option_click: 'Items_sort_by_name_option_click',
    Items_sort_by_location_option_click: 'Items_sort_by_location_option_click',
    Items_sort_by_status_option_click: 'Items_sort_by_status_option_click',
    Items_sort_by_checked_in_option_click: 'Items_sort_by_checked_in_option_click',
    Items_sort_by_checked_out_option_click: 'Items_sort_by_checked_out_option_click',
};

export const ITEMS_DETAIL = {
    Item_Details_screen: 'Item_Details_screen',
    Item_Detail_data_fetch_attempt: 'Item_Detail_data_fetch_attempt',
    Item_Detail_data_fetch_success: 'Item_Detail_data_fetch_success',
    Item_Detail_data_fetch_fail: 'Item_Detail_data_fetch_fail',
    Item_Detail_package_image_fetch_attempt: 'Item_Detail_package_image_fetch_attempt',
    Item_Detail_package_image_fetch_success: 'Item_Detail_package_image_fetch_success',
    Item_Detail_package_image_fetch_fail: 'Item_Detail_package_image_fetch_fail',
    Item_Detail_signature_image_fetch_attempt: 'Item_Detail_signature_image_fetch_attempt',
    Item_Detail_signature_image_fetch_success: 'Item_Detail_signature_image_fetch_success',
    Item_Detail_signature_image_fetch_fail: 'Item_Detail_signature_image_fetch_fail',
    Item_Details_close_bclick: 'Item_Details_close_bclick',
    Item_Details_next_item_bclick: 'Item_Details_next_item_bclick',
    Item_Details_previous_item_bclick: 'Item_Details_previous_item_bclick',
    Item_Details_recipient_name_bclick: 'Item_Details_recipient_name_bclick',
    Item_Details_tracking_number_bclick: 'Item_Details_tracking_number_bclick',
    Item_Details_scanned_by_bclick: 'Item_Details_scanned_by_bclick',
    Item_Details_delivered_by_bclick: 'Item_Details_delivered_by_bclick',
    Item_Details_received_by_bclick: 'Item_Details_received_by_bclick',
    Item_Details_mark_as_picked_up_bclick: 'Item_Details_mark_as_picked_up_bclick',
    Item_Details_mark_as_picked_up_popup_mark_as_picked_up_bclick: 'Item_Details_mark_as_picked_up_popup_mark_as_picked_up_bclick',
    Item_Details_discard_items_bclick: 'Item_Details_discard_items_bclick',
    Item_Details_discard_item_popup_discard_item_bclick: 'Item_Details_discard_item_popup_discard_item_bclick',
};

export const CLA = {
    WELCOME: {
        Welcome_screen: 'Welcome_screen',
        Welcome_get_started_bclick: 'Welcome_get_started_bclick'
    },
    SET_PASS: {
        Set_Password_screen: 'Set_Password_screen',
        Set_Password_continue_bclick: 'Set_Password_continue_bclick',
        Set_Password_verification_attempt: 'Set_Password_verification_attempt',
        Set_Password_verification_success: 'Set_Password_verification_success',
        Set_Password_verification_fail: 'Set_Password_verification_fail',
    },
    ADD_BUILDING: {
        Add_Building_screen: 'Add_Building_screen',
        Add_Building_continue_bclick: 'Add_Building_continue_bclick',
    },
    ADD_RECP: {
        Add_Recipient_screen: 'Add_Recipient_screen',
        Add_Recipient_Continue_bclick: 'Add_Recipient_Continue_bclick',
    },
    DOWNLOAD_APP: {
        Download_App_Screen: 'Download_App_Screen',
        Download_App_Continue_Bclick: 'Download_App_Continue_Bclick',
    },
    ACCOUNT_READY: {
        Account_Ready_Screen: 'Account_Ready_Screen',
        Account_Ready_Continue_Bclick: 'Account_Ready_Continue_Bclick',
    },
    SIGNIN_APP: {
        Sign_In_On_App_Screen: 'Sign_In_On_App_Screen',
        Sign_In_On_App_Continue_Bclick: 'Sign_In_On_App_Continue_Bclick',
    },
    SCAN_LBL: {
        Scan_Labels_Screen: 'Scan_Labels_Screen',
        Scan_Labels_Continue_Bclick: 'Scan_Labels_Continue_Bclick',
    },
    SEND_NOTIFICATION: {
        Send_Notification_Screen: 'Send_Notification_Screen',
        Send_Notification_Continue_Bclick: 'Send_Notification_Continue_Bclick'
    },
    PICK_PACKAGE: {
        Pickup_Packages_Screen: 'Pickup_Packages_Screen',
        Pickup_Packages_Continue_Bclick: 'Pickup_Packages_Continue_Bclick'
    },
    ALL_SET: {
        All_Set_Screen: 'All_Set_Screen',
        All_Set_Continue_Bclick: 'All_Set_Continue_Bclick'
    },
    DASHBOARD: {
        Dashboard_Screen: 'Dashboard_Screen',
        Dashboard_popup_get_started_bclick: 'Dashboard_popup_get_started_bclick',
        Dashboard_Pending_Tasks_fetch_attempt: 'Dashboard_Pending_Tasks_fetch_attempt',
        Dashboard_Pending_Tasks_fetch_success: 'Dashboard_Pending_Tasks_fetch_success',
        Dashboard_Pending_Tasks_fetch_fail: 'Dashboard_Pending_Tasks_fetch_fail',
        Dashboard_Pending_Task_Drop_Down_bclick: 'Dashboard_Pending_Task_Drop_Down_bclick',
        Dashboard_Pending_Task_bclick: 'Dashboard_Pending_Task_bclick',
    },
    NEXT_BTN: {
        Next_bclick: 'Next_bclick',
    },
    PREV_BTN: {
        Previous_bclick: 'Previous_bclick',
    },
};
