/* eslint-disable quotes */
export const styles = {
  auth: {
    buttonWhite: {
      margin: '10px 0 20px 10px',
      color: '#FFFFFF',
    },
  },
  colors: {
    red: { color: '#FF1E59' },
    blue: { color: '#027AFF' },
    green: { color: '#02B67C' },
    yellow: { color: '#FFB02F' },
    black: { color: '#000000' },
    grey: { color: '#757575' },
  },
  common: {
    w_90: { width: '90%' },
    w_78: { width: '78%' },
    w_60: { width: '60%' },
    w_35: { width: '35%' },
    dotRed: {
      width: 10,
      height: 10,
      borderRadius: 5,
      backgroundColor: '#FF1E59',
      marginRight: 10,
    },
    dotYellow: {
      width: 10,
      height: 10,
      borderRadius: 5,
      backgroundColor: '#FFB02F',
      marginRight: 10,
    },
    dotGreen: {
      width: 10,
      height: 10,
      borderRadius: 5,
      backgroundColor: '#02B67C',
      marginRight: 10,
    },
    d_f: { display: 'flex' },
    subheading: {
      display: 'flex',
      color: "#000000",
      fontSize: 18,
      fontWeight: 500,
      zIndex: 2,
    },
    filterText: {
      color: '#757575',
      fontSize: 16,
      fontWeight: 'bold',
    },
    jc_c: { justifyContent: 'center' },
    w_15: { width: '15%' },
    w_100: { width: '100%' },
    w_15p: { width: 15 },
    w_35p: { width: 35 },
    w_80p: { width: 80 },
    w_100p: { width: 100 },
    w_195: { width: 195 },
    r_30: { right: 30 },
    w_125: { width: 125 },
    w_150: { width: 150 },
    w_200: { width: 200 },
    w_250: { width: 250 },
    w_300: { width: 300 },
    w_500: { width: 500 },
    h_in: { height: 'inherit' },
    h_15p: { height: '15%' },
    h_15: { height: 15 },
    h_18: { height: 18 },
    h_33: { height: 33 },
    h_35: { height: 35 },
    h_50: { height: 50 },
    h_70: { height: 70 },
    h_80: { height: 80 },
    h_85: { height: 85 },
    h_100: { height: '100%' },
    h_40: { height: 40 },
    h_45: { height: 45 },
    h_49: { height: 49 },
    h_260: { height: 260 },
    h_250: { height: 250 },
    h_300: { height: 300 },
    h_360: { height: 360 },
    h_428: { height: 428 },
    h_540: { height: 540 },
    h_480: { height: 480 },
    h_580: { height: 580 },
    h_600: { height: 600 },
    b_n: { border: 'none' },
    mb_0: { marginBottom: 0 },
    bb_1: { borderBottom: '1px solid #000' },
    bb_2: { borderBottom: '1px solid #d9d9d9' },
    b_2: { borderBottom: '1px solid #E0E0E0' },
    br_0: { borderRadius: 0 },
    br_4: { borderRadius: 4 },
    br_6: { borderRadius: 6 },
    mxw_160: { maxWidth: 160 },
    mxw_100: { maxWidth: 100 },
    f_20: { fontSize: 20 },
    p_1020: { padding: '10px 20px' },
    p_0: { padding: 0 },
    p_38: { padding: 38 },
    p_18: { padding: 18 },
    pv_18: { paddingBottom: 18, paddingTop: 18 },
    pv_20: { paddingBottom: 20, paddingTop: 20 },
    pv_35: { paddingBottom: 35, paddingTop: 35 },
    p_72: { padding: 72 },
    p_60: { padding: 60 },
    p_5: { padding: 5 },
    p_10: { padding: 10 },
    p_12: { padding: 12 },
    pr_10: { paddingRight: 10 },
    m_0: { margin: 0 },
    m_4: { margin: 4 },
    mt_4: { marginTop: 4 },
    mb_4: { marginBottom: 4 },
    mb_8: { marginBottom: 8 },
    mb_10: { marginBottom: 10 },
    mb_32: { marginBottom: 32 },
    mb_20: { marginBottom: 20 },
    mw_40: { maxWidth: '40%' },
    mw_60: { maxWidth: '60%' },
    w_70: { width: '70%' },
    w_85: { width: '85%' },
    mw_85: { maxWidth: '85%' },
    mw_30: { maxWidth: '30%' },
    mh_18: { maxHeight: 18 },
    mh_32: { maxHeight: 32 },
    mh_38: { maxHeight: 38 },
    ml_20: { marginLeft: 20 },
    mr_8: { marginRight: 8 },
    mt_auto: { marginTop: 'auto' },
    mg_10: { margin: 10 },
    mh_758: { minHeight: 758 },
    ph_950: { height: 960 },
    mt_16: { marginTop: 16 },
    fs_10: { fontSize: 10 },
    fs_12: { fontSize: 12 },
    f_38: { fontSize: 38 },
    fs_14: { fontSize: 14 },
    fs_18: { fontSize: 18 },
    fs_20: { fontSize: 20 },
    fs_1r: { fontSize: '1rem' },
    fw_bdr: { fontWeight: 'bolder' },
    fw_400: { fontWeight: 400 },
    fw_500: { fontWeight: 500 },
    lh_14: { lineHeight: '14px' },
    lh_12: { lineHeight: '12px' },
    lh_21: { lineHeight: '21px' },
    bs_df: {
      boxShadow: '0 20px 20px 0 rgba(0,0,0,0.1), 0 4px 4px 0 rgba(0,0,0,0.24)',
    },
    o_h: { overflow: 'hidden' },
    oy_s: { overflowY: 'scroll' },
    oy_a: { overflowY: 'auto' },
    c_p: { cursor: 'pointer' },
    uppercase: { textTransform: 'uppercase' },
    capitalize: { textTransform: 'capitalize', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
    mh_615: {
      minHeight: 615,
    },
    hide_text_dots: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 150,
    },
    table_wrapper_column: {
      // minHeight: 480,
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  flex: {
    direction_row: { flexDirection: 'row' },
    direction_column: { flexDirection: 'column' },
    wrap: { flexWrap: 'wrap' },
    align_center: { alignItems: 'center' },
    justify_end: { justifyContent: 'flex-end' },
    justify_between: { justifyContent: 'space-between' },
    display: { display: 'flex' },
    jc_c: { justifyContent: 'center ' },
    flex1: { flex: 1 },
  },
  package_grid_view: {
    height: 209,
    width: 216,
    display: 'flex',
    alignItems: 'flex-end',
    margin: 3,
    backgroundSize: 'cover',
  },
  package_grid_view_name: {
    fontSize: 20,
    background: 'none',
    padding: 8,
    width: '100%',
    fontWeight: 'bold',
    hoverBackgroundColor: 'white',
  },
  package_grid_button: {
    margin: '0px 0 10px 0px',
    height: 32,
    width: 32,
    padding: 0,
    borderRadius: 3,
    boxShadow: `0px 1px 5px 0px rgba(0, 0, 0, 0.2), 
          0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)`,
  },
  packages_area: {
    // minHeight: 558,
    marginBottom: 32,
  },
  packages_area_header: {
    paddingTop: 10,
    paddingLeft: 12,
    paddingRight: 20,
    paddingBottom: 10,
    alignItems: 'center',
  },
  package_search_clear_button: {
    minHeight: 0,
    padding: '4px 10px',
    color: '#027AFF',
    marginLeft: 8,
  },
  package_details_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 10,
    padding: 10,
  },
  package_image: {
    height: 'auto',
    width: '100%',
    // eslint-disable-next-line comma-dangle
    boxShadow: '0 20px 20px 0 rgba(0,0,0,0.25)',
    marginBottom: 20,
  },
  signature_image: { height: 200, width: 290 },
  package_signature_container: {
    height: 130,
    width: 251,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  package_img_height: {
    fontSize: 250,
    marginBottom: -80,
  },
  package_signature_height: {
    fontSize: 60,
  },
};
