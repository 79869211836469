import React from 'react';
import ReactHighmaps from 'react-highcharts/ReactHighmaps.src';
import PropTypes from 'prop-types';
import { Paper, withWidth } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  VerticallyCenter,
  AlignCenter,
  Ripple,
} from '../../../UI/ResponsiveRelated';
import maps from '../../../Maps/VectorMap/components/world';
import DateRange from './DateRange';

const WorldView = props => {
  const {
    t,
    locations,
    style,
    locationsLoading,
    locationsError,
    width,
    sidebar,
  } = props;

  const config = {
    chart: {
      spacingBottom: 20,
      backgroundColor: 'transparent',
      height: width === 'xs' ? 250 : width === 'sm' ? 480 : 600,
      marginTop:
        width === 'xs' ? -30 : width === 'lg' || width === 'xl' ? -20 : -70,
      style: {
        fontFamily: "''Roboto', sans-serif",
        height: width === 'xs' ? 250 : 485,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop:
          width === 'xs'
            ? '-30px'
            : width === 'lg' || width === 'xl'
              ? '-20px'
              : '-70px',
        cursor: 'default',
      },
    },
    mapNavigation: {
      enabled: false,
      buttonOptions: {
        verticalAlign: 'bottom',
        theme: {
          fill: 'transparent',
          'stroke-width': 1,
          r: 0,
        },
        style: {
          color: '#999999',
        },
      },
    },

    exporting: {
      allowHTML: true,
    },

    title: {
      style: { display: 'none' },
    },

    legend: {
      enabled: false,
    },
    credits: false,

    plotOptions: {
      map: {
        joinBy: ['iso-a2', 'code'],
        mapData: maps,
        cursor: 'pointer',
      },
    },

    tooltip: {
      headerFormat: '<b>{point.key}</b>',
      backgroundColor: 'rgba(97,97,97,0.9)',
      style: { color: 'white', cursor: 'pointer' },
      borderWidth: 0,
      shadow: {
        color: 'rgba(0,0,0,0.1)',
        offsetX: 0,
        offsetY: 5,
        width: 10,
      },
      borderRadius: 0,
      useHTML: true,
      pointFormat: `${"<span class='flag {point.flag}'></span> <br>" +
        '<br>'}${t('main.buildingCount')}: <b>{point.count}</b> <br>${t('members.members',)}: <b>{point.members}</b> <br> ${t('main.scannedPackages',)}: <b>{point.packages}</b>`,
    },

    colorAxis: {
      min: 1,
      max: 1000,
      type: 'logarithmic',
    },

    series: [
      {
        data: locations,
        states: {
          hover: {
            color: '#027AFF',
          },
          cursor: 'pointer',
          style: {
            cursor: 'pointer',
          },
        },
        point: {
          events: {
            click() {
              props.history.push(`/buildings/country/${this.id}`);
              // props.selectCountry(this.id);
            },
          },
        },
      },
    ],
  };
  if (sidebar.collapse === false) {
    if (width === 'lg') {
      config.chart.width = 950;
    }
    if (width === 'xl') {
      config.chart.width = 1200;
    }
    if (width === 'md') {
      config.chart.width = 700;
    }
    if (width === 'sm') {
      config.chart.width = 300;
    }
    if (width === 'xs') {
      config.chart.width = 200;
    }
  } else if (sidebar.collapse === true) {
    if (width === 'lg') {
      config.chart.width = 1050;
    }
    if (width === 'xl') {
      config.chart.width = 1100;
    }
    if (width === 'md') {
      config.chart.width = 750;
    }
    if (width === 'sm') {
      config.chart.width = 450;
    }
    if (width === 'xs') {
      config.chart.width = 250;
      config.chart.height = 250;
    }
  }

  return (
    <Paper
      style={{
        padding: 17,
        height:
          width === 'xs'
            ? 220
            : width === 'sm'
              ? 320
              : width === 'md'
                ? 470
                : 600,
        marginTop: 7,
      }}
      className="just-paper"
    >
      <div className="space-between-with-block">
        <div style={style.common.subheading}>{t('main.globalView')}</div>
        <DateRange {...props} loading={locationsLoading} />
      </div>
      {locationsLoading ? (
        <Ripple variant="black" />
      ) : locationsError ? (
        <VerticallyCenter>
          <AlignCenter>{locationsError}</AlignCenter>
        </VerticallyCenter>
      ) : (
            <ReactHighmaps config={config} />
          )}
    </Paper>
  );
};

WorldView.propTypes = {
  t: PropTypes.func.isRequired,
  selectCountry: PropTypes.func.isRequired,
  locations: PropTypes.node.isRequired,
  history: PropTypes.node.isRequired,
  locationsLoading: PropTypes.bool.isRequired,
  locationsError: PropTypes.string.isRequired,
  style: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
};

const WorldMap = connect(state => ({
  sidebar: state.sidebar,
}))(withWidth()(withRouter(WorldView)));
export { WorldMap };
