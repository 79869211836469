import axios from 'axios';
import { server } from './server';

export function validateCSV(validate_rows, file_name, building_id, over_write) {
  const params = {
    file_name,
    building_id,
    validate_rows,
    over_write
  };
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${server}/csv/upload`,
        params,
      ).then(res => resolve(res.data))
      .catch(error => reject(error));
  });
}

export function getUploadUrl(building_id) {
  let url = `${server}/csv/upload`;
  if (building_id) url = `${url}?building_id=${building_id}&signed_url=true`;
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(res => resolve(res.data))
      .catch(error => reject(error));
  });
}

export function getUploadedFiles(building_id, limit, offset,) {
  let url = `${server}/csv/upload`;
  if (building_id) url = `${url}?building_id=${building_id}&signed_url=false&pagination_limit=${limit}&pagination_offset=${offset}`;
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(res => resolve(res.data))
      .catch(error => reject(error));
  });
}

export function postFileToSignedUrl(url, file) {
  return new Promise((resolve, reject) => {
    axios.put(url, file)
      .then(() => resolve())
      .catch(error => reject(error));
  });
}
