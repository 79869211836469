/* eslint-disable */

import React from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import { CircularProgress, Grid, Button } from '@material-ui/core';
import CircleCheck from '../../../shared/img/circlecheck.svg';
import ContactSalesResponse from '../PaymentModals/ContactSalesResponse';
import { getPlanFeatures, getFullTierName } from '../../../util/application.utils';
import { changeUserPlan, contactSalesTeam } from '../../../server/payment';
import { getCommaSeperatedNumber } from '../../../util/utility.functions';
class CustomPlanSBP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadArray: true,
      type: 1,
      plans: [],
      selectedPlan: '',
      newSelectedPlan: '',
      loadingBtns: [],
      loadCustomContactSales: false,
      disableBtns: false,
      starterLoader: false,
      basicLoader: false,
      premiumLoader: false,
      internalLoader: true,
      openPlanChangeRequestModal: false,
      openContactSalesResponseModal: false,
      error: false,
      starterFeatures: [],
      basicFeatures: [],
      premiumFeatures: [],
    };
  }


  closeContactSalesResponseModal = () => {
    this.setState(
      {
        openContactSalesResponseModal: false,
      },
      () => this.props.history.push('/dashboard')
    );
  };

  render() {
    const {openContactSalesResponseModal, loadCustomContactSales} = this.state;
    const { buildings, type } = this.props;
    const hasBuilding = buildings && buildings.length > 0 && buildings[0];
    const hasDiscount = hasBuilding && buildings[0].discount;
    const features = hasBuilding ? getPlanFeatures(buildings[0].features, type) : [];
    const hasBillingInfo =
    hasBuilding && buildings[0].billing_price && buildings[0].billing;
    const getPrice = () => {
      if (hasBillingInfo && buildings[0].billing_price) {
        if (hasDiscount) {
          const discountPrice = parseInt(buildings[0].billing_price,10) * (hasDiscount / 100); 
          return (buildings[0].billing_price - discountPrice).toFixed(2);
        } else {
          return getCommaSeperatedNumber(buildings[0].billing_price);
        }
      } else {
        return '';
      }
    }

      const contactSales = () => {
          const { tenant_name, tier } = buildings[0];
          this.setState(
            {
              loadCustomContactSales: true,
            },
            () => {
              contactSalesTeam(tenant_name)
                .then(() => {
                  this.setState(
                    {
                      loadCustomContactSales: false,
                      openContactSalesResponseModal: true,
                    },
                    () => {}
                  );
                })
                .catch((err) => {
                  this.setState({ loadCustomContactSales: false });
                  console.log(err);
                });
            }
          );
      };

      const getPriceType = () => {
        if (hasBillingInfo) {
          return type === 1 ? 'PER MONTH' : 'PER YEAR';
        } else {
          return '';
        }
      };

    const closeModal = () => {
    this.props.history.goBack();
   } 
   const customPlanSBP =
      buildings &&
      buildings.length > 0 &&
      buildings[0].tier &&
      buildings[0].tier.toLowerCase();
    return (
      <Dialog maxWidth="md" {...this.props} onClose={closeModal}>
        <div className="pm-custom-plan-container">
      <div className="pm-main-heading">
          {getFullTierName(customPlanSBP)}{' '}Plan
      </div>
      <div className="pm-custom-main">
        <p>
        It looks like you are on a {getFullTierName(customPlanSBP)} Plan, you have access to the following features with your plan, you can get in touch with our sales team to customize your plan or request a new bundle.{' '}
        </p>
        <div className="pm-custom-plan-card">
          <div className="pm-custom-plan-header">
            <span className="pm-custom-plan-heading">Features</span>
            <div className="pm-custom-plan-cost-section">
              <span className="pm-custom-currency">
                {hasBillingInfo ? '$' : ''}
              </span>
              <div className="pm-custom-plan-cost-inner">
                <span className="cost">{getPrice()}</span>
                <span className="occurance">{getPriceType()}</span>
              </div>
            </div>
          </div>
          <ul className="pm-custom-plan-features">
            {features &&
              features.length > 0 &&
              features.map((feature, index) => (
                <li key={index} className="pm-custom-plan-list-item">
                  <img src={CircleCheck} alt="" />
                  <span>{feature}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div className="pm-custom-footer">
                <p>
                If you wish to change the features on your current plan or request a new plan altogether. You can contact sales.
                </p>
                <Button
                  disabled={loadCustomContactSales}
                  className="pm-cp-upgrade-btn"
                  onClick={() => contactSales()}
                >
                  <span className="pm-cp-btn-spacer">
                    CONTACT SALES{' '}
                    {loadCustomContactSales && (
                      <CircularProgress size={15} className="white" />
                    )}
                  </span>
                </Button>
                <ContactSalesResponse
                  open={openContactSalesResponseModal}
                  onClose={this.closeContactSalesResponseModal}
            />
      </div>
      </div>
      </Dialog>
    );
  }
}

export default connect((state) => ({
  paymentsDetails: state.paymentsDetails,
  buildings: state.allBuildings,
  buildingLoader: state.loader.buildings,
}))(withRouter(CustomPlanSBP));
