import React from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { handleSidebarHover, handleSidebarHoverAway } from '../../../util/utility.functions';
import './style.css';

const NotificationIconActive = require('../../../assets/BellWhite.svg');
const NotificationIconInactive = require('../../../assets/BellGrey.svg');

function NotificationIcon(props) {
  const { active, label } = props;
  const imgSrc = active ? NotificationIconActive : NotificationIconInactive;
  return (
    <Tooltip title={label}>
      <img
        src={imgSrc}
        alt=""
        height="20"
        width="20"
        onMouseOver={(event) => { handleSidebarHover(event, NotificationIconActive); }}
        onFocus={(event) => { handleSidebarHover(event, NotificationIconActive); }}
        onMouseLeave={(event) => { handleSidebarHoverAway(event, active, NotificationIconInactive); }}
      />
    </Tooltip>
  );
}

NotificationIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.bool.isRequired,
};

export default NotificationIcon;
