import axios from 'axios';
import { server } from './server';

export function getBusinesses(
  limit,
  orderBy,
  asc,
  paginationOffset,
  buildingId,
) {
  const params = {
    pagination_limit: limit,
    include_logo: true,
    order_by: orderBy,
    order_asc: asc,
    include_designated_members: true,
    include_num_active_members: true,
    include_num_business_member_packages: true,
    include_num_pending_packages: true
  };
  if (paginationOffset || paginationOffset !== 0) {
    params.pagination_offset = paginationOffset;
  }
  if (buildingId) params.building_id = buildingId;
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/business`, { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function getBusiness(id) {
  const params = {
    include_logo: true,
    include_notes: true,
    include_pending_packages: true,
  };
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/business/${id}`, { params })
      .then(response => resolve(response.data[0]))
      .catch(error => reject(error));
  });
}

export function searchBusiness(
  key,
  buildingId,
  paginationLimit,
  paginationOffset,
  sortBy,
  sortType,
  isVoBusiness = false,
) {
  return new Promise((resolve, reject) => {
    let params = {
      order_by: sortBy,
      include_profile_pictures: true,
      order_asc: sortType,
      building_id: buildingId,
      pagination_limit: paginationLimit,
      pagination_offset: paginationOffset,
      include_designated_members: true,
      include_num_active_members: true,
      include_num_business_member_packages: true,
      include_num_pending_packages: true,
    };
    if (paginationOffset === 0 || paginationOffset === null) {
      delete params.pagination_offset;
    }
    if (isVoBusiness) {
      params.membership = 'VO_BUSINESS';
    }
    axios
      .get(`${server}/entity/business/search?query=${key}`, { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function addBusiness(business) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/entity/business`, business)
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
}

export function updateBusiness(id, business) {
  return new Promise((resolve, reject) => {
    const modifiedBusiness = {
      building_id: business.building,
      name: business.name,
      email: business.email && business.email.trim(),
      phone: business.phone,
      office: business.office,
      business_name: business.business,
      alternate_name: business.alternate_name,
      note: business.note,
      address: business.address,
    };

    if (modifiedBusiness.phone === '' || !modifiedBusiness.phone) {
      delete modifiedBusiness.phone;
    }
    if (!modifiedBusiness.alternate_name) {
      delete modifiedBusiness.alternate_name;
    }
    if (!modifiedBusiness.alternate_email) {
      delete modifiedBusiness.alternate_email;
    }
    if (!modifiedBusiness.note) delete modifiedBusiness.note;
    if (modifiedBusiness.address && modifiedBusiness.address.length === 0) delete modifiedBusiness.address;
    if (!modifiedBusiness.alternate_phone) {
      delete modifiedBusiness.alternate_phone;
    }
    axios
      .put(`${server}/entity/business/${id}`, modifiedBusiness)
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
}

export function getDesignatedMembers(businessId) {
  const params = {
    include_designated_members_only: true,
    business_id: businessId,
  };
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/member`, { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}
