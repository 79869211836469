/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  //   FormHelperText,
  Button,
  CircularProgress,
} from '@material-ui/core';
// import { getEncryptedEmail } from '../../../../util/application.utils';
import { CallTrigger } from '../../../../../util/Triggers/function';
import { CLA } from '../../../../../util/Triggers/triggerNames';
import FilterIcon from '../../../../../assets/Onboarding/AdditionalDetail/FIlter.svg';
import PendingIcon from '../../../../../assets/Onboarding/AdditionalDetail/Pending.svg';
import TrackingNumberIcon from '../../../../../assets/Onboarding/AdditionalDetail/TrackingNumber.svg';
import AddLabelIcon from '../../../../../assets/Onboarding/AdditionalDetail/ADDLABEL.svg';
import AddLabelIcon2 from '../../../../../assets/Onboarding/AdditionalDetail/ADDLABEL2.svg';
import Video from '../../../../../assets/videos/Onboarding_2_dark.mp4';
import additionalDetailsImage from '../../../../../assets/additionalDetails.svg';

class AdditionalDetail extends React.Component {
  state = {
    disable: false,
  };
  componentDidMount() {}
  render() {
    const { next, getUserCurrentStep, t, waiting } = this.props;
    const { disable } = this.state;
    const moveToNext = () => {
      getUserCurrentStep(() => next());
    };
    return (
      <div className="onboard-new-outer-container">
        <Grid container className="container" style={{ minWidth: '100%' }}>
          {/* <form
              onSubmit={() => next()}
          > */}
          <Grid item xs={12} sm={12}>
            <h3 className="onboarding-welcome-title">
              Adding Additional Details (Optional)
            </h3>
            <p className="onboarding-welcome-description">
            After processing your scan, you will have the option to add additional details to the item before notifying the recipient. We’ll include all these extra details in the notification to give the recipient that extra level of visibility!
            </p>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item xs={12} md={8} className="onboard-new-pl-15">
                <ol className="onboard-new-instructions-list">
                  <li>
                    Click into the item details by selecting an item from the
                    pending tab.
                  </li>
                  <li>
                  You’ll be shown a similar view to the one displayed on the screen. Here you’ll have the ability to: 
                    <ol
                      type="a"
                      className="onboard-new-instructions-list-inner"
                    >
                      <li>
                        Edit carrier information by selecting the carrier
                        dropdown.
                      </li>
                      <li>
                        Rescan or generate a unique tracking code by selecting
                        the{' '}
                        <img
                          src={TrackingNumberIcon}
                          className="onboard-new-icon onboard-new-width-20px"
                          alt="Add label"
                        />{' '}
                        icon.
                      </li>
                      <li>
                        Add additional photos by selecting the
                        <img
                          src={AddLabelIcon}
                          className="onboard-new-icon onboard-new-width-60px"
                          alt="Add label"
                        />{' '}
                        icon.
                      </li>
                      <li>
                        Edit “Item Type” by selecting the scan mode dropdown.
                      </li>
                      <li>
                        Add “item Location” by selecting the location dropdown.
                      </li>
                      <li>
                        Add/Edit item “Labels” by selecting the{' '}
                        <img
                          src={AddLabelIcon2}
                          className="onboard-new-icon onboard-new-width-60px"
                          alt="Add label Icon"
                        />{' '}
                        icon.
                      </li>
                      <li>Add “Item Notes”.</li>
                      <li>
                        Edit the matched recipient by selecting the{' '}
                        <img
                          src={FilterIcon}
                          className="onboard-new-icon onboard-new-width-20px"
                          alt="Add label Icon"
                        />{' '}
                        icon and choosing a new recipient from your directory.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Click the{' '}
                    <img
                      src={PendingIcon}
                      className="onboard-new-icon"
                      alt="Add label Icon"
                    />{' '}
                    button in the top left corner to return the pending tab.
                  </li>
                </ol>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '-0.5rem',
                }}
              >
                <img src={additionalDetailsImage} width="240" />
              </Grid>
            </Grid>
            <div className="onboard-new-end-para-left">
              <p>
              Pro Tip: Admins can add custom Labels and Locations to your dropdown via the mobile app settings. 
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              disabled={waiting || disable}
              className={`onBoard-page-btn ${
                waiting || disable ? 'onBoard-page-btn-disabled' : ''
              }`}
              onClick={() => {
                CallTrigger(CLA.WELCOME.Welcome_get_started_bclick, {
                  Previous_event: '',
                });
                this.setState(
                  {
                    disable: true,
                  },
                  () => moveToNext()
                );
                //   this.setState({ waiting: true });
              }}
            >
              CONTINUE
              {(waiting || disable) && (
                <div className="ml-2">
                  <CircularProgress size={15} className="white" />
                </div>
              )}
            </Button>
          </Grid>
          {/* </form> */}
        </Grid>
      </div>
    );
  }
}

AdditionalDetail.propTypes = {
  next: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  getUserCurrentStep: PropTypes.func.isRequired,
  // handleDisablePrev: PropTypes.func.isRequired,
  // handleDisableNext: PropTypes.func.isRequired,
  waiting: PropTypes.bool.isRequired,
};

export default AdditionalDetail;
