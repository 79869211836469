import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { loadStripe } from '@stripe/stripe-js';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  getStripePaymentDetails,
  setPaymentPlan,
} from '../../../server/payment';
import { Button } from './Styled';


function FreeTrailExpiredInternal(props) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { userRole, t } = props;

  const setPaymentCheckout = (stripeId) => {
    setPaymentPlan(stripeId)
      .then(async (res) => {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: res.data.id,
        });

        if (result.error) {
          return res;
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
        return res;
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setError('Unable to set payment plan');
        setLoading(false);
        console.log(error);
      });
  };

  const addPaymentInfo = () => {
    setLoading(true);
    getStripePaymentDetails()
          .then((res) => {
            setPaymentCheckout(res.data.stripe_price_id);
          })
          .catch((err) => {
            console.log(err);
            setError('Unable to fetch stripe payment details');
            setLoading(false);
          });
  };

  let message = '';
  if (userRole === 'admin') {
    message =
      'Uh oh, looks like your free trial has expired. If you wish to continue using Mailroom you will need to add a payment method. If you choose to purchase a different plan then click the "view plan" button below.';
  } else {
    message =
      'Uh Oh, Looks like your Free Trial has expired. To continue enjoying the PackageX Mailroom experience, please contact your admin.';
  }

  const closeModal = () => {
    props.onClose(null);
  };

  return (
    <div>
      <Dialog keepMounted maxWidth="sm" onClose={closeModal} open={props.open}>
        <DialogContent className="pm-modal-container">
          <h3 className="pm-heading">
            {t('paymentModals.freeTrialExpired.headingNormal')}{' '}
            <span className="pm-heading-red">
              {t('paymentModals.freeTrialExpired.headingColored')}
            </span>
          </h3>
          <p className="pm-description">{message}</p>
          {userRole === 'admin' ? (
            <div className="pm-button-section">
              <Button
                onClick={() => {
                  props.history.push('/manage-plan');
                }}
                className="pm-cp-add-payment-btn"
              >
                {t('paymentModals.freeTrialExpired.viewPlan')}
              </Button>
              <Button
                className="pm-cp-upgrade-btn"
                onClick={() => addPaymentInfo()}
              >
                Add Payment {loading && <CircularProgress size={15} className="white" />}
              </Button>
            </div>
          ) : (
            <div className="pm-single-button-section">
              <Button
                className="pm-cp-upgrade-btn"
                onClick={closeModal}
              >
                {t('paymentModals.paymentSuccessful.button')}
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

FreeTrailExpiredInternal.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  history: PropTypes.node.isRequired,
};

export default withRouter(FreeTrailExpiredInternal);
