/* eslint-disable no-plusplus */
/* eslint-disable quotes */
/* eslint-disable function-paren-newline */
/* eslint-disable object-curly-newline */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Divider,
  Button as MUIButton,
  IconButton,
  InputAdornment,
  Grid,
  withWidth,
  Tooltip,
  withStyles,
  Paper,
  TableCell,
  withMobileDialog,
  Zoom,
  InputBase,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  CircularProgress,
  DialogActions,
  FormControl,
  Button,
  FormControlLabel,
  Chip,
  Checkbox,
  Select as MaterialSelect
} from '@material-ui/core';
import {
  Search,
  Cancel,
  Menu,
  Apps,
  ArrowDropDown,
  // DeleteForeverIcon,
  // AssignmentTurnedInIcon,
  // GetApp
} from '@material-ui/icons';
import Dropzone from 'react-dropzone';
// import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LocalShipping from '@material-ui/icons/LocalShipping';
import DeleteIcon from '@material-ui/icons/Delete';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SupportContactIcon from '@material-ui/icons/ContactSupport';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Label from '@material-ui/core/InputLabel';
import Skeleton from 'react-loading-skeleton';
import Select, { components } from 'react-select';
import { Link, withRouter } from 'react-router-dom';
import BuildingToggle from '../BuildingToggle';
import { CustomInput } from '../../UI/Input';
// import UploadBtn from '../../UI/BlueButton';
// import DateRangePicker from './components/DateRangePicker';
import DateRangeTableDataExport from './components/DateRangeTableDataExport';
import MaterialUITable from '../../../shared/components/MaterialUITable/components/MultiRowSelectMaterialTable';
import PaginationComponent from '../../../shared/components/PaginationWithNumbers/components/paginationComponent';
import {
  SpaceBetween,
  AlignCenter,
  VerticallyCenter,
  Flex,
} from '../../UI/ResponsiveRelated';
import {
  generalPackageSearch,
  TextPackageSearch,
  getPackagesBySearch,
  handleMark,
  handleMarkDispatch
} from '../../../server';
// import AdvancedPackagesSearch from './components/AdvancedPackagesSearch/AdvancedPackagesSearch';
import { setPagination } from '../../../redux/actions/paginationAction';
import { setDynamicPagination } from '../../../redux/actions/dynamicPaginationActions';
import { truncateLabel, appendTimeAtEnd, appendTimeAtStart, getPackageStatus } from '../../../util/utility.functions';
// import { PackagesIcon } from '../../Layout/sidebar/PackagesIcon';
import { voAdvanceSearchOptions, checkAWSSession, voPackagesTableColumnHeader } from '../../../util/application.utils';
// import { EXPORT_BLUE, EXPORT_WHITE } from '../../../util/icons/index';
import './components/grid.css';

import browser from '../../../util/getBrowserInfo';
import { resolveSafari } from '../../../util/resolveSafari';
import Gallery from '../../UI/Gallery/components/Gallery';
import PackageDialog from './components/Dialog';
// import ExportData from './ExportData';
import { PackagesIndexStyles as styles } from '../../../util/StyleConsts';
import { Event } from "../../../util/ga";
import Alert from "../../UI/Poper";
import Footer from "../../UI/Footer/Footer";
import { getDynamicLabels, tableDataExportCSV } from '../../../server/packages';
import { setDynamicLabelsData } from '../../../redux/actions/dynamicLabelsActions';
import { removeDateFilter } from '../../../redux/actions/dateFilterActions';
import DataExportConfirmationModal from './components/DataExportConfirmationModal';
import { tableDataExport } from '../../../redux/actions/tableExportAction';
import AdvancedSearch from './components/AdvancedSearch/advanceSearch';
import ShipImage from '../../../assets/images/ship.png';
import Cloud from '../../../assets/images/cloud.svg';
// import cameraIcon from '../../../assets/images/Icons/cameraIcon.svg';


const env = process.env.REACT_APP_ENV;

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <IconButton className="padding-0" ><ArrowDropDown /></IconButton>
  </components.DropdownIndicator>
);

const BlueCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'rgb(29, 168, 235)',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);


class Packages extends Component {
  constructor(props) {
    super(props);
    const { match, t } = props;
    this.state = {
      totalPackages: '---',
      loading: true,
      packages: [],
      error: null,
      packageId: '',
      open: false,
      packageImage: '',
      signature: '',
      packageDetail: '',
      order_by: 'created_at',
      order_asc: 'DESC',
      firstSearchError: '',
      tableHeads: voPackagesTableColumnHeader(t),
      activePage: 1,
      sortBy: 'created_at',
      sortType: false,
      grid: false,
      applyAdvancedSearch: false,
      searchParams: {},
      view: 'list',
      range: null,
      exportDataRange: null,
      basicSearch: '',
      fulltextSearch: '',
      applyFilter: false,
      filter: { value: 0, label: t('package.basic') },
      statusOption: null,
      dropdown: null,
      columnSwitcher: false,
      member: <Skeleton />,
      searchBtnAlert: false,
      tooltip: false,
      match,
      currentIndex: 0,
      currentId: 0,
      csvLoading: false,
      csvError: null,
      csvSuccess: null,
      scanType: 'All',
      advancedSearchAnchor: null,
      advancedSearchTags: [],
      isSelected: null,
      packagesData: [],
      limit: props.limitPerPage.packagesLimit,
      fromDate: null,
      toDate: null,
      exportTableData: localStorage.getItem('exportStatus') !== null,
      exportCalenderAnchor: null,
      tableDataExportData: null,
      tableDataExportModal: false,
      exportInProgress: localStorage.getItem('exportingData') === "true",
      tableDataExportStatus: null,
      tableDataExportError: null,
      defaultModule: null,
      selectedPakgIds: [],
      loadingPickup: false,
      loadingDiscard: false,
      openPickup: false,
      openDiscard: false,
      errorDiscard: '',
      errorPickup: '',
      reason: '',
      helper: false,
      other: false,
      redirect: false,
      notifyDiscard: false,
      disabled: true,
      showBulkError: false,
      bulkErrorType: '',
      buildingFilterFlag: this.props.building.length > 0
    };

    this.tableRows = this.tableRows.bind(this);
    this.sortData = this.sortData.bind(this);
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidMount() {
    checkAWSSession(res => {
      if (res) {
        this.setState({
          encryptedEmail: `/onboarding?email=${res}`
        });
      }

      if (browser.name === 'Safari') {
        resolveSafari();
      }
      getDynamicLabels(sessionStorage.px_tenant)
        .then(response => {
          if (module !== 'package_label') {
            this.setState({
              applyAdvancedSearch: false
            });
          }
          this.props.dispatch(setDynamicLabelsData(response));
        })
        .catch(err => console.log(err));

      const {
        t,
        getMember,
        getBusiness,
        getBuilding,
        history,
        userRole,
      } = this.props;
      const { match } = this.state;
      const { module, id } = match.params;
      history.listen(location => {
        if (location.pathname !== match.path) {
          this.historyChange(location);
        }
      });
      if (module === 'all_items') {
        this.props.dispatch(removeDateFilter());
        if (userRole) {
          this.getData(this.props);
        }
      }
      if (module === 'currently_held') {
        if (userRole) {
          this.getData(this.props);
        }
      }
      if (module === 'member_items' || module === 'member_all_items') {
        getMember(id) /** ********HERE********* */
          .then(member => {          
            let allFilters = ["voItems.voMembers", "tables.status"];
            if (module === 'member_items') {
              allFilters.push("tables.scanType");
            }
            if (this.props.dateFilter.fromDate || localStorage.px_dateFilter) { // if the parent component has from value and store is having dateFilter.fromDate value then push checkIn to filter array else skip this step. 
              allFilters.push("tables.checkIn");
            }
            const { pathname } = this.props.location;
            // moment.utc(from).format('MMM D, YYYY')
            this.setState({
              buildingFilterFlag: this.props.building.length > 0,
              member:
                module === 'member_items' && pathname.includes('/outstanding')
                  ? `${member.name} ${t('tables.pending_packages')}`
                  : `${member.name} ${t('tables.all')} ${t('tables.packages')}`,
              searchParams: {
                to_date: localStorage.px_dateFilter ? JSON.parse(localStorage.px_dateFilter).toDate : null,
                from_date: localStorage.px_dateFilter ? JSON.parse(localStorage.px_dateFilter).fromDate : null,
                package_belong_to: [{ name: member.name }],
                selectedMembers2: [{ name: member.name, member_id: id }],
                filter_option: module === 'member_items' ? ['holding'] : ['holding', 'collected'],
                scan_type: module === 'member_items' ? 'SCAN-IN' : 'All'
              },
              advancedSearchTags: [...allFilters],
            }, () => {
              if (userRole) {
                this.getData(this.props);
              }
            });
          })
          .catch(error =>
            this.setState({
              member: error.response
                ? error.response.data.message.indexOf('child "id"') !== -1
                  ? t('members.noMemberFound')
                  : t('common.error.something')
                : error.message,
            }),
          ); /** **********TILL HERE************ */
      } else if (
        module === 'business_packages' ||
        module === 'business_member_packages'
      ) {
        getBusiness(id)
          .then(business => {
            let allFilters = ["tables.businesses", "tables.status"];
            this.setState({
              buildingFilterFlag: this.props.building.length > 0,
              member:
                module === 'business_packages'
                  ? `${business.name} ${t('tables.packages')}`
                  : `${business.name} ${t('members.member')} ${t('tables.packages')}`,
              searchParams: {
                package_businesses: [{ name: business.name }],
                selectedBusinesses: [{ name: business.name, business_id: id }],
                filter_option: ['holding', 'collected'],
              },
              advancedSearchTags: [...allFilters],
            }, () => {
              if (userRole) {
                this.getData(this.props);
              }
            });
          })
          .catch(error =>
            this.setState({
              member: error.response
                ? error.response.data.message.indexOf('child "id"') !== -1
                  ? t('members.noBusiness')
                  : t('common.error.something')
                : error.message,
            }),
          );
      } else if (module === 'building_packages') {
        getBuilding(match.params.id)
          .then(building => {
            this.setState({
              buildingFilterFlag: this.props.building.length > 0,
              member: `${building.name} ${t('tables.pending_packages')}`,
              searchParams: {
                filter_option: ["overdue"]
              },
              advancedSearchTags: ["tables.status"],
            }, () => {
              if (userRole) {
                this.getData(this.props);
              }
            });
          })
          .catch(error =>
            this.setState({
              member: error.response
                ? error.response.data.message.indexOf('child "id"') !== -1
                  ? t('members.noBusiness')
                  : t('common.error.something')
                : error.message,
            }),
          );
      } else if (module === 'courier_package') {
        let dateForm = localStorage.px_scanFilter ? JSON.parse(localStorage.getItem('px_scanFilter')).courierFrom : null;
        let dateTo = localStorage.px_scanFilter ? JSON.parse(localStorage.getItem('px_scanFilter')).courierTo : null;
        let allFilters = [];
        allFilters.push("package.courier");
        allFilters.push("tables.checkIn");
        /* eslint-disable react/no-did-mount-set-state */
        this.setState(prevState => ({
          searchParams: {
            ...prevState.searchParams,
            to_date: dateTo,
            from_date: dateForm,
            appliedFilters: ["tables.checkIn", "package.courier"],
            scan_type: "All",
            package_courier: id,
            action_status: [],
          },
          advancedSearchTags: ["tables.checkIn", "package.courier"],
          applyAdvancedSearch: true,
          search_business_member_packages: true,
          buildingFilterFlag: this.props.building.length > 0,
        }), () => {
          this.getData(this.props);
        });
      }
      if (module === 'member_items' || module === 'member_all_items') {
        getMember(id) /** ********HERE********* */
          .then(member => {
            let allFilters = ["voItems.voMembers", "tables.status"];
            if (module === 'member_items') {
              allFilters.push("tables.scanType");
            }
            if (this.props.dateFilter.fromDate || localStorage.px_dateFilter) { // if the parent component has from value and store is having dateFilter.fromDate value then push checkIn to filter array else skip this step. 
              allFilters.push("tables.checkIn");
            }
            const { pathname } = this.props.location;
            // moment.utc(from).format('MMM D, YYYY')
            this.setState({
              buildingFilterFlag: this.props.building.length > 0,
              member:
                module === 'member_items' && pathname.includes('/outstanding')
                  ? `${member.name} ${t('tables.pending_packages')}`
                  : `${member.name} ${t('tables.all')} ${t('tables.packages')}`,
              searchParams: {
                to_date: localStorage.px_dateFilter ? JSON.parse(localStorage.px_dateFilter).toDate : null,
                from_date: localStorage.px_dateFilter ? JSON.parse(localStorage.px_dateFilter).fromDate : null,
                package_belong_to: [{ name: member.name }],
                selectedMembers2: [{ name: member.name, member_id: id }],
                filter_option: module === 'member_items' ? ['holding'] : ['holding', 'collected'],
                scan_type: module === 'member_items' ? 'SCAN-IN' : 'All'
              },
              advancedSearchTags: [...allFilters],
            }, () => {
              if (userRole) {
                this.getData(this.props);
              }
            });
          })
          .catch(error =>
            this.setState({
              member: error.response
                ? error.response.data.message.indexOf('child "id"') !== -1
                  ? t('members.noMemberFound')
                  : t('common.error.something')
                : error.message,
            }),
          ); /** **********TILL HERE************ */
      } else if (
        module === 'business_packages' ||
        module === 'business_member_packages'
      ) {
        getBusiness(id)
          .then(business => {
            let allFilters = ["tables.businesses", "tables.status"];
            this.setState({
              buildingFilterFlag: this.props.building.length > 0,
              member:
                module === 'business_packages'
                  ? `${business.name} ${t('tables.packages')}`
                  : `${business.name} ${t('members.member')} ${t('tables.packages')}`,
              searchParams: {
                package_businesses: [{ name: business.name }],
                selectedBusinesses: [{ name: business.name, business_id: id }],
                filter_option: ['holding', 'collected'],
              },
              advancedSearchTags: [...allFilters],
            }, () => {
              if (userRole) {
                this.getData(this.props);
              }
            });
          })
          .catch(error =>
            this.setState({
              member: error.response
                ? error.response.data.message.indexOf('child "id"') !== -1
                  ? t('members.noBusiness')
                  : t('common.error.something')
                : error.message,
            }),
          );
      } else if (module === 'building_packages') {
        getBuilding(match.params.id)
          .then(building => {
            this.setState({
              buildingFilterFlag: this.props.building.length > 0,
              member: `${building.name} ${t('tables.pending_packages')}`,
              searchParams: {
                filter_option: ["overdue"]
              },
              advancedSearchTags: ["tables.status"],
            }, () => {
              if (userRole) {
                this.getData(this.props);
              }
            });
          })
          .catch(error =>
            this.setState({
              member: error.response
                ? error.response.data.message.indexOf('child "id"') !== -1
                  ? t('members.noBusiness')
                  : t('common.error.something')
                : error.message,
            }),
          );
      } else if (module === 'package_label') {
        let dateForm = localStorage.getItem('categoryFromDate_analytics');
        let dateTo = localStorage.getItem('categoryToDate_analytics');
        let allFilters = [];
        allFilters.push("tables.label");
        allFilters.push("tables.checkIn");
        /* eslint-disable react/no-did-mount-set-state */
        this.setState(prevState => ({
          searchParams: {
            ...prevState.searchParams,
            to_date: dateTo,
            from_date: dateForm,
            appliedFilters: ["tables.checkIn", "tables.label"],
            scan_type: "All",
            package_label_id: [{ id: Number(id) }],
            action_status: [],
          },
          advancedSearchTags: ["tables.checkIn", "tables.label"],
          applyAdvancedSearch: true,
          search_business_member_packages: true,
          buildingFilterFlag: this.props.building.length > 0,
        }), () => {
          this.getData(this.props);
        });
      }
      if (module === 'scanned') {
        if (userRole) {
          this.getData(this.props);
        }
      }

      // if (userRole) {
      //   this.getData(this.props);
      // }
      this.setSortingValues('order_by', this.state.orderBy);
      this.setSortingValues('order_asc', this.state.order_asc);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.building.length !== this.props.building.length) {
      this.setState({ buildingFilterFlag: !this.state.buildingFilterFlag });
    }
    if (prevProps.tableExportStatus !== this.props.tableExportStatus) {
      this.setState({
        exportInProgress: this.props.tableExportStatus
      });
    }
    const { building, userRole, t } = this.props;
    if (!this.state.loading || this.state.search) {
      if (prevProps.building !== this.props.building) {
        this.props.dispatch(setPagination({ route: 'packages', offset: 0 }));
        if (this.props.building.length === 0) {
          this.setState({ filter: { value: 0, label: this.props.t('package.basic') } });
        }
        this.emptySearch();
      }
    }
    if (userRole) {
      if (building !== prevProps.building) {
        this.setState(
          { basicSearch: this.state.searchParams.basicSearch ? this.state.searchParams.basicSearch : "", fulltextSearch: '', filter: { value: 0, label: t('package.basic') }, applyFilter: false },
          () => this.getData(this.props),
        );
      } else if (userRole !== prevProps.userRole) {
        this.getData(this.props);
      }
    }
    if (this.props.lng !== prevProps.lng) {
      const { filter } = this.state;
      this.setState({
        tableHeads: voPackagesTableColumnHeader(t),
        packages: this.createRows(this.state.packagesData),
        filter: { value: filter.value, label: t(filter === 0 ? 'package.basic' : 'package.fullText') },
      });
    }
  }

  onChangePage = (currentActionPage, limit) => {
    let action;
    if (currentActionPage === 'next') {
      action = this.state.activePage + 1;
      this.props.dispatch(setPagination({ route: 'packages', offset: (this.state.activePage * this.state.limit) }));
    } else if (currentActionPage === 'previous') {
      action = this.state.activePage - 1;
      this.props.dispatch(setPagination({ route: 'packages', offset: ((action - 1) * this.state.limit) }));
    } else {
      action = currentActionPage;
      if (limit) {
        let tempLimits = {};
        if (localStorage.px_dynamic_pagination) {
          tempLimits = JSON.parse(localStorage.px_dynamic_pagination);
          tempLimits.packagesLimit = limit;
        } else {
          tempLimits = {
            packagesLimit: limit,
            membersLimit: this.props.limitPerPage.membersLimit,
            businessesLimit: this.props.limitPerPage.businessesLimit,
            associatesLimit: this.props.limitPerPage.associatesLimit,
            buildingsLimit: this.props.limitPerPage.buildingsLimit,
          };
        }
        this.props.dispatch(setDynamicPagination(tempLimits));
        const currentPage = Math.ceil(this.state.totalPackages / this.state.limit);
        if (this.state.activePage === currentPage) {
          action = Math.ceil(this.state.totalPackages / limit);
        }
      }
      this.props.dispatch(setPagination({ route: 'packages', offset: (action - 1) * (limit || this.state.limit) }));
    }

    this.setState({
      limit: limit || this.state.limit,
      loading: true,
      error: null,
      packages: [],
      activePage: action,
      selectedPakgIds: [],
    }, () => this.getData());
  };

  onSearchFieldClick = (e) => {
    this.setState({
      advancedSearchAnchor: e.currentTarget
    });
  }

  onSearchFieldClose = () => {
    this.setState({
      advancedSearchAnchor: null
    });
  }

  onDrop = accepted => {
    if (
      accepted[0].type === 'image/png' || accepted[0].type === 'application/pdf'
    ) {
      if (accepted[0].size > 5000000) {
        this.setState({ warningMsg: 'Selected file is larger than 5 MB.' });
      } else {
        return new Promise(resolve => {
          const reader = new window.FileReader();
          reader.readAsDataURL(accepted[0]);
          reader.onloadend = () => {
            const base64data = reader.result;
            this.setState({
              dispatchBase64: base64data.split(",")[1],
              dispatchFileName: accepted[0].name,
              dispatchFileType: accepted[0].type.split("/")[1]
            });
            resolve(base64data);
          };
        });
      }
    } else {
      const message = 'Please submit valid file type';
      this.setState({ warningMsg: message });
    }
  };

  getData = (props, clear) => {
    this.setState({
      selectedPakgIds: [] // empty checkbox list
    });
    let { getPackages, building, dispatch, pagination, buildings, userRole, t } = props || this.props;
    const newBuildings = [];
    buildings.map(item => {
      item.buildings.map(eachBuilding => {
        newBuildings.push(eachBuilding);
        return eachBuilding;
      });
      return item;
    });
    buildings = [...newBuildings];
    const routes = pagination.map(pg => pg.route);
    const routeIndex = routes.indexOf('packages');
    const offset = routeIndex > -1 ? pagination[routeIndex].offset : 0;
    this.setState({
      loading: true,
      error: null,
      packages: [],
      totalPackages: '---',
      activePage: Math.floor(offset / this.state.limit) + 1,
      order_by: 'created_at',
      order_asc: 'DESC',
      // basicSearch: 
    });
    let {
      sortBy,
      sortType,
      filter,
      range,
      match,
      searchParams,
      applySearch,
      applyAdvancedSearch,
      scanType,
      // basicSearch,
      fulltextSearch,
    } = this.state;
    if (Object.keys(searchParams).length === 0) {
      this.setState({
        basicSearch: ''
      });
    }
    const { basicSearch, from_date, to_date } = searchParams;
    // if (building.length === 0) {
    //   if (searchParams) {
    //     if (searchParams.building_id) {
    //       const selectedBuilding = buildings.filter(item => item.building_id === searchParams.building_id);
    //       if (selectedBuilding[0].building_code !== 'GCC01') {
    //         scanType = 'All';
    //       }
    //     }
    //   } else {
    //     scanType = 'All';F
    //   }
    // } else {
    //   const selectedBuilding = buildings.filter(item => item.building_id === building[0].building_id);
    //   if (selectedBuilding && selectedBuilding.length !== 0) {
    //     if (selectedBuilding[0].building_code !== 'GCC01') {
    //       scanType = 'All';
    //     }
    //   }
    // }
    let buildingId = null;
    let memberId = null;
    let mailroomId = null;
    let fromdate = null;
    let todate = null;
    let businessId = null;
    let graphqfilter = false;
    let dateFiltering = false;
    let onlybusinesspkgs = false;
    let type;
    let filterOption = match.params.filter;
    let searchBusinessMemberPackages = false;
    if (building.length > 0) buildingId = building[0].building_id;
    const { module, id } = match.params;
    if (id === '0_3' && !clear) {
      todate = moment().subtract(1, 'd');
      fromdate = moment().subtract(3, 'd');
    } else if (id === '3_10' && !clear) {
      todate = moment().subtract(4, 'd');
      fromdate = moment().subtract(10, 'd');
    } else if (id === '10' && !clear) {
      todate = moment().subtract(11, 'd');
    } else if (id === 'today' && !clear) {
      todate = moment();
      fromdate = moment();
    } else if (id === '7_days' && !clear) {
      todate = moment();
      fromdate = moment().subtract(6, 'd');
    } else if (id === '4_weeks' && !clear) {
      todate = moment();
      fromdate = moment().subtract(3, 'week').startOf('week').day(1);
    } else if (id === '12_months' && !clear) {
      todate = moment();
      fromdate = moment().subtract(11, 'month');
    } else if (from_date && to_date) {
      todate = to_date;
      fromdate = from_date;
    } else {
      if (range && range.start) fromdate = moment(range.start);
      if (range && range.end) todate = moment(range.end);
    }
    if (module === 'currently_held' || module === 'scanned' || window.location.pathname.includes('currently_held')) {
      // let filter_option = [];
      if (module === 'scanned') {
        graphqfilter = true;
        let { filter: packageStatus } = this.props.match.params;
        if (packageStatus === 'outstanding') packageStatus = 'notified';
        // filter_option.push(packageStatus);
      } else {
        if (window.location.pathname !== "/mp-items/all_items") {
          dateFiltering = true;
        }
        let { filter: packageStatus } = this.props.match.params;
        if (packageStatus === 'outstanding') packageStatus = 'notified';
        // filter_option.push(packageStatus);
      }
      if (Object.keys(this.state.searchParams).length === 0) {
        if (module === 'currently_held') {
          this.setState({
            statusOption: filterOption,
            range: { start: fromdate && fromdate.clone(), end: todate && todate.clone() },
            searchParams: {
              to_date: todate && todate.toISOString(),
              from_date: fromdate && fromdate.toISOString(),
              filter_option: (id === '0_3' || id === '3_10') ? ['holding'] : ['overdue'],
            },
            advancedSearchTags: ["tables.checkIn", "tables.status"],
          });
        } else if (module === 'scanned') {
          let { filter: packageStatus } = this.props.match.params;
          if (packageStatus === 'outstanding') packageStatus = 'notified';
          this.setState({
            statusOption: filterOption,
            range: { start: fromdate && fromdate.clone(), end: todate && todate.clone() },
            searchParams: {
              to_date: todate && todate.toISOString(),
              from_date: fromdate && fromdate.toISOString(),
              filter_option: (packageStatus === 'notified')
                ? ['holding', 'collected', 'overdue', 'discarded']
                : (packageStatus === 'collected') ? ['collected']
                  : ['scanned', 'holding', 'collected', 'overdue', 'deleted', 'discarded'],
            },
            advancedSearchTags: ["tables.checkIn", "tables.status"],
          });
        }
      }
      if (id === '4_weeks' && !clear) fromdate = fromdate.add(1, 'day');
    } else if (match.params.module === 'building_packages') {
      buildingId = match.params.id;
    } else if (building.length > 0) buildingId = building[0].building_id;
    if (match.params.module === 'mailroom_packages') {
      mailroomId = match.params.id;
      type = 'mailroom_packages';
    } else if (match.params.module === 'business_packages') {
      onlybusinesspkgs = true;
      businessId = match.params.id;
      filterOption = match.params.filter;
    } else if (match.params.module === 'business_member_packages') {
      businessId = match.params.id;
      filterOption = match.params.filter;
      searchBusinessMemberPackages = true;
    } else if (match.params.module === 'member_items') {
      memberId = match.params.id;
      filterOption = match.params.filter;
      scanType = 'SCAN-IN';
    } else if (match.params.module === 'member_all_items') {
      memberId = match.params.id;
      filterOption = match.params.filter;
    }
    if (userRole === 'mailroom_supervisor') {
      mailroomId = buildings.length > 0 ? buildings[0].mailrooms.map(m => m.mailroom_id) : '';
      mailroomId = mailroomId.toString();
    }
    if (((applyAdvancedSearch && Object.keys(searchParams).length !== 0) && filter.value === 0)) {
      // if ((filter.value === 0 && basicSearch.length > 1)) {
      if (window.location.pathname === "/mp-items/all_items") {
        // buildingId = null;
        dateFiltering = false;
        filterOption = null;
      }
      if (window.location.pathname === "/mp-items/all_items") {
        memberId = null;
        filterOption = null;
        scanType = 'All';
      }
      if (window.location.pathname === "/packages/courier_package") {
        memberId = null;
        filterOption = null;
        scanType = 'All';
        dateFiltering = false;
        filterOption = null;
      }
      const { advancedSearchTags } = this.state;
      if (advancedSearchTags.includes("tables.checkIn") && module !== 'package_label') {
        dateFiltering = true;
      }
      if (module === 'package_label') {
        searchParams.platform = 'dashboard';
      }
      generalPackageSearch(
        basicSearch,
        offset,
        this.state.limit,
        sortBy,
        sortType ? 'ASC' : 'DESC',
        buildingId,
        fromdate && moment(fromdate).utc().startOf('day').toISOString(),
        todate && moment(todate).utc().endOf('day').toISOString(),
        mailroomId,
        null,
        businessId,
        memberId,
        type,
        onlybusinesspkgs,
        dateFiltering,
        graphqfilter,
        scanType,
        searchParams,
        searchBusinessMemberPackages,
        true,
      )
        .then(packages => {
          if (packages.length === 0 && this.state.activePage > 1) {
            this.onChangePage(1);
          } else {
            this.setState({
              packagesData: packages,
              packages: this.createRows(packages),
              loading: false,
              noMore: packages.length < this.state.limit,
              totalPackages: packages.length > 0 && packages[0].packages_count,
            });
          }
        }).catch(err => {
          let errorMessage =
            err.response &&
              err.response.data.message.indexOf('status code') > -1
              ? t('common.error.networkErrorMessage')
              : t('common.error.networkError');
          this.setState({
            error: errorMessage,
            loading: false,
          });
        });
    } else if (
      fulltextSearch.length > 1 &&
      filter.value === 1 &&
      building.length > 0
    ) {
      TextPackageSearch(
        fulltextSearch,
        offset,
        this.state.limit,
        sortBy,
        sortType ? 'ASC' : 'DESC',
        buildingId,
        fromdate && moment(fromdate).utc().startOf('day').toISOString(),
        todate && moment(todate).utc().endOf('day').toISOString(),
        mailroomId,
        filterOption,
        businessId,
        memberId,
        type,
        onlybusinesspkgs,
        dateFiltering,
        graphqfilter,
        scanType,
        searchBusinessMemberPackages,
        true
      ).then(packages => {
        if (packages.length === 0 && this.state.activePage > 1) {
          this.onChangePage(1);
        } else {
          this.setState({
            packagesData: packages,
            packages: this.createRows(packages),
            loading: false,
            noMore: packages.length < this.state.limit,
            totalPackages: packages.length > 0 && packages[0].packages_count,
          });
        }
      }).catch(err => {
        let errorMessage =
          err.response &&
            err.response.data.message.indexOf('status code') > -1
            ? t('common.error.networkErrorMessage')
            : t('common.error.networkError');
        this.setState({
          error: errorMessage,
          loading: false,
        });
      });
    } else {
      const currentModule = match.params.module === 'business_member_packages';
      const allPackages = (applyAdvancedSearch && Object.keys(searchParams).length !== 0) ? getPackagesBySearch : getPackages;
      if (!applySearch) dispatch(setPagination({ route: 'packages', offset }));
      allPackages(
        this.state.limit,
        sortBy,
        sortType ? 'ASC' : 'DESC',
        offset,
        buildingId,
        searchParams,
        mailroomId,
        fromdate && moment(fromdate).utc().startOf('day').toISOString(),
        todate && moment(todate).utc().endOf('day').toISOString(),
        filterOption,
        businessId,
        memberId,
        type,
        dateFiltering,
        graphqfilter,
        onlybusinesspkgs,
        scanType,
        currentModule,
        true
      ).then(packages => {
        if (packages.length === 0 && this.state.activePage > 1) {
          this.onChangePage(1);
        } else {
          this.setState({
            packagesData: packages,
            packages: this.createRows(packages),
            loading: false,
            totalPackages: packages.length > 0 && packages[0].packages_count,
          });
        }
      }).catch(err => {
        let errorMessage =
          err.message.indexOf('status code') > -1
            ? t('common.error.networkErrorMessage')
            : t('common.error.networkError');
        this.setState({
          error: errorMessage,
          loading: false,
        });
      });
    }
  };


  setSelected = (selected) => {
    this.setState({
      selectedPakgIds: selected
    });
  }

  setSortingValues = name => value => this.setState({ [name]: value });

  handleOther = e => {
    const { reason, other } = this.state;
    if (reason.length > 0 || other) {
      this.setState({
        reason: e.target.value,
        helper: false,
        disabled: e.target.value.length === 0,
      });
    } else {
      this.setState({
        disabled: true,
      });
    }
  };

  historyChange = () => {
    const url = window.location.href;
    if (url && url.includes('/mp-items/all_items')) {
      const m = {
        params: { module: null, id: null, filter: null },
        path: '/mp-items/all_items',
        url: '/mp-items/all_items',
      };
      this.setState(
        { match: m, range: null, searchParams: {}, advancedSearchTags: [] },
        () => this.applyFilter(this.props, true),
      );
    } else if (url && url.includes('/packages/building_packages')) {
      let params = url.split('/');
      const m = {
        params: {
          module: 'building_packages',
          id: params[params.length - 2],
          filter: params.pop(),
        },
        path: '/packages/building_packages',
        url: '/packages/building_packages',
      };
      this.setState(
        { match: m, range: null },
        () => !this.state.loading && this.applyFilter(this.props, true),
      );
    } else if (url && url.includes('/packages/member_all_items')) {
      let params = url.split('/');
      const m = {
        params: {
          module: 'member_all_items',
          id: params.pop(),
          filter: null,
        },
        path: '/packages/member_all_items',
        url: '/packages/member_all_items',
      };
      this.setState(
        { match: m, range: null },
        () => !this.state.loading && this.applyFilter(this.props, true),
      );
    } else if (url && url.includes('/packages/business_packages')) {
      let params = url.split('/');
      const m = {
        params: {
          module: 'business_packages',
          id: params[params.length - 2],
          filter: params.pop(),
        },
        path: '/packages/business_packages',
        url: '/packages/business_packages',
      };
      this.setState(
        { match: m, range: null },
        () => !this.state.loading && this.getData(this.props),
      );
    } else if (url && url.includes('/packages/member_items')) {
      let params = url.split('/');
      const m = {
        params: {
          module: 'member_items',
          id: params[params.length - 2],
          filter: params.pop(),
        },
        path: '/packages/member_items',
        url: '/packages/member_items',
      };
      this.setState(
        { match: m },
        () => !this.state.loading && this.applyFilter(this.props, true),
      );
    } else if (url && url.includes('/packages/business_member_packages')) {
      let params = url.split('/');
      const m = {
        params: {
          module: 'business_member_packages',
          id: params.pop(),
          filter: null,
        },
        path: '/packages/business_member_packages',
        url: '/packages/business_member_packages',
      };
      this.setState(
        { match: m },
        () => !this.state.loading && this.applyFilter(this.props, true),
      );
      /** *********************HERE**** */
    } else if (url && (url.includes('/packages/scanned') || url.includes('/packages/currently_held'))) {
      let params = url.split('/');
      const m = {
        params: {
          module: url.includes('/packages/scanned') ? 'scanned' : 'currently_held',
          id: params[params.length - 2],
          filter: params.pop(),
        },
        path: '/packages/business_member_packages',
        url: '/packages/business_member_packages',
      };
      this.setState(
        { match: m },
        () => !this.state.loading && this.getData(this.props),
      );
    } else if (url && (url.includes('/mp-items'))) {
      const m = {
        params: { module: null, id: null, filter: null },
        path: '/mp-items/all_items',
        url: '/mp-items/all_items',
      };
      this.setState(
        { match: m, range: null, searchParams: {}, advancedSearchTags: [] },
        () => this.applyFilter(this.props, true),
      );
    }
  };

  columnSwitcher = () =>
    this.setState({ columnSwitcher: !this.state.columnSwitcher });

  startSearch = () => {
    const {
      // basicSearch,
      fulltextSearch,
      filter,
    } = this.state;
    // const bSearch = basicSearch.trim();
    const fSearch = fulltextSearch.trim();
    if (
      (filter.value === 0) ||
      (filter.value === 1 && fSearch.length > 1)
    ) {
      if (filter.value === 1) {
        this.setState({
          applyAdvancedSearch: false,
          // searchParams: {},
          loading: true,
          error: null,
          activePage: 1,
          packages: [],
          clearAll: true,
          range: null,
          statusOption: null,
          // appliedFilters: [],
          // advancedSearchTags: [],
          scanType: 'All',
        }, () => this.getData(null));
      } else {
        this.setState({
          loading: true,
          error: null,
          applyAdvancedSearch: false,
          // searchParams: {},
          activePage: 1,
          packages: [],
        }, () => this.getData(this.props));
      }
    }
  };

  handleChange = () => e => {
    const { filter } = this.state;
    const value = e.target.value;
    // this.setState({
    //   basicSearch: filter.value === 0 ? value : this.state.basicSearch,
    //   fulltextSearch: filter.value === 1 ? value : this.state.fulltextSearch,
    // }, () => {
    //   if (value.length === 0) this.getData(this.props);
    // });
    if (value === '') {
      this.setState({
        loading: true,
        error: null,
        activePage: 1,
        basicSearch: '',
        fulltextSearch: '',
      }, () => this.getData(null));
    } else {
      this.setState({
        basicSearch: filter.value === 0 ? value : this.state.basicSearch,
        fulltextSearch: filter.value === 1 ? value : this.state.fulltextSearch,
      });
    }
  };

  validateURLRedirect = (searchParams) => {
    const { pathname } = window.location;
    if (pathname !== '/mp-items/all_items') {
      if (pathname.includes('building_packages')) {
        const buildingId = this.props.match.params.id;
        const tempPath = `/packages/building_packages/${buildingId}/${searchParams.filter_option.length !== 0 ? searchParams.filter_option.join(',') : '------'}`;
        if (tempPath !== pathname) {
          this.redirectToAllPackages();
        }
      } else if (pathname.includes('/member_items')) {
        const currentPath = `${pathname.substr(0, pathname.lastIndexOf('/'))}/holding/SCAN-IN`;
        const selectedMembers = searchParams.selectedMembers2.map(item => item.member_id);
        const tempPath = `/packages/member_items/${selectedMembers.join(',')}/${searchParams.filter_option.length !== 0 ? searchParams.filter_option.join(',') : '------'}/${searchParams.scan_type}`;
        if (currentPath !== tempPath) {
          this.redirectToAllPackages();
        }
      } else if (window.location.pathname.includes('member_all_items')) {
        const { id } = this.props.match.params;
        const selectedMembers = searchParams.selectedMembers2.map(item => item.member_id);
        if (!(selectedMembers.length === 1 && selectedMembers[0] === id)) {
          this.redirectToAllPackages();
        } else {
          const currentPath = `${pathname}/holding,collected`;
          const filterOptions = searchParams.filter_option.length === 2
            ? searchParams.filter_option.join(',')
            : '------';
          const tempPath = `/packages/member_all_items/${selectedMembers.join(',')}/${filterOptions}`;
          if (currentPath !== tempPath) {
            this.redirectToAllPackages();
          }
        }
      } else if (window.location.pathname.includes('business_member_packages')) {
        const { id } = this.props.match.params;
        const selectedBusinesses = searchParams.selectedBusinesses.map(item => item.business_id);
        if (!(selectedBusinesses.length === 1 && selectedBusinesses[0] === id)) {
          this.redirectToAllPackages();
        } else {
          const currentPath = `${pathname}/holding,collected`;
          const tempPath = `/packages/business_member_packages/${selectedBusinesses.join(',')}/${searchParams.filter_option.length !== 0 ? searchParams.filter_option.join(',') : '------'}`;
          if (currentPath !== tempPath) {
            this.redirectToAllPackages();
          }
        }
      } else if (window.location.pathname.includes('currently_held')) {
        const { to_date, from_date } = searchParams;
        if (pathname.includes('/10/') && !(searchParams.to_date)) {
          this.redirectToAllPackages();
        } else if (!(pathname.includes('/10/')) && !(searchParams.to_date && searchParams.from_date)) {
          this.redirectToAllPackages();
        } else {
          const from_date_updated = appendTimeAtStart(from_date.toString().substr(0, 10));
          const to_date_updated = appendTimeAtEnd(to_date.toString().substr(0, 10));
          const date1 = new Date(from_date_updated);
          const date2 = new Date(to_date_updated);
          const diffTime = Math.abs(date2 - date1);
          const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
          const appendText = pathname.includes('3_10')
            ? '3_10'
            : pathname.includes('0_3')
              ? '0_3'
              : '10';

          const currentPath = `${pathname.substr(0, pathname.lastIndexOf('/'))}/holding`;
          const tempPath = `/packages/currently_held/${appendText}/${searchParams.filter_option.length !== 0 ? searchParams.filter_option.join(',') : '------'}`;
          if (!currentPath.includes(tempPath)) {
            this.redirectToAllPackages();
          } else if (
            (appendText === '0_3' && diffDays !== 3)
            || (appendText === '3_10' && (diffDays > 10 || diffDays < 3))
          ) {
            this.redirectToAllPackages();
          }
        }
      }
    }
  }

  redirectToAllPackages = () => {
    window.history.pushState(null, null, '/mp-items/all_items');
    this.setState({
      defaultModule: 'all_packages'
    });
  }

  applySearch = async (allSearchParams, appliedFilters) => {
    if (appliedFilters.length === 0 && allSearchParams.basicSearch === '') {
      this.handleClearButtonAction();
      this.props.history.push('/mp-items/all_items');
    } else {
      let searchParams = { ...allSearchParams };
      if (searchParams.package_courier === 'selectAValue') searchParams.package_courier = '';
      if (searchParams.building_id === -1) searchParams.building_id = null;
      let { match, tableHeads } = this.state;
      tableHeads[3].value = 'building_name';
      tableHeads[4].value = 'status_value';
      await this.validateURLRedirect(searchParams);

      this.setState(
        {
          tableHeads,
          statusOption: searchParams.filter_option,
          scanType: searchParams.scan_type === '' ? 'All' : searchParams.scan_type,
          match: match.params.module === 'scanned' || match.params.module === 'currently_held' ?
            { params: { module: null, id: null, filter: null } } : match,
          range: null,
          applyAdvancedSearch: true,
          sortBy: 'created_at',
          sortType: false,
          searchParams,
          basicSearch: searchParams.basicSearch,
          fulltextSearch: searchParams.fulltextSearch,
          clearAll: false,
          advancedSearchTags: appliedFilters.length === 0 ? [] : [...appliedFilters],
          activePage: 1,
        },
        () => {
          this.getData(this.props);
        },
      );
    }
  };

  applyFilter = range => {
    let { tableHeads } = this.state;
    tableHeads[4].value = 'status';
    tableHeads[3].value = 'buildingName';

    const { match, filter } = this.state;
    this.setState({
      tableHeads,
      loading: true,
      error: null,
      sortBy: 'created_at',
      sortType: false,
      applyAdvancedSearch: filter.value === 0,
      searchParams: {},
      applyFilter: Boolean(range),
      activePage: 1,
      range,
      match: match.params.module === 'scanned' || match.params.module === 'currently_held' ?
        { params: { module: null, id: null, filter: null } } : match,
    }, () => this.getData(this.props, true));
  };

  applyDateRangeFilter = range => {
    const fromdate = moment(range.start);
    const todate = moment(range.end);
    const toDateISO = moment(todate).utc().endOf('day').toISOString();
    const fromDateISO = moment(fromdate).utc().startOf('day').toISOString();
    if (toDateISO === fromDateISO) {
      this.setState({
        tableDataExportError: 'Please select atleast two days.',
      }, () => {
        setTimeout(() => {
          this.closeDataExportError();
        }, 3000);
      });
    } else {
      this.setState({
        tableDataExportData: {
          toDate: toDateISO,
          fromDate: fromDateISO
        },
        tableDataExportModal: true
      });
    }
  };

  clearFilter = () => {
    let { tableHeads } = this.state;
    tableHeads[4].value = 'status';
    tableHeads[3].value = 'buildingName';

    this.setState({
      sortBy: 'created_at',
      sortType: false,
      tableHeads,
      applyFilter: false,
      activePage: 1
    });
  }

  checkStatusForBulkAction = (arr) => arr.packageStatus === "Held" || arr.packageStatus === "Overdue" || arr.packageStatus === "Holding";

  clearSearch = () => {
    // this.setState({
    //   applyAdvancedSearch: false,
    //   searchParams: {},
    //   loading: true,
    //   error: null,
    //   activePage: 1,
    //   packages: [],
    //   clearAll: true,
    //   range: null,
    //   statusOption: null,
    //   basicSearch: '',
    //   fulltextSearch: '',
    // }, () => this.getData(null));
    if (this.state.filter.value === 0) {
      this.setState({
        basicSearch: '',
        fulltextSearch: '',
      });
    } else {
      this.setState({
        applyAdvancedSearch: false,
        searchParams: {},
        loading: true,
        error: null,
        activePage: 1,
        packages: [],
        clearAll: true,
        range: null,
        statusOption: null,
        basicSearch: '',
        fulltextSearch: '',
      }, () => this.getData(null));
    }
  };

  handleView = view => this.setState({ grid: view === 'grid', view });

  emptySearch = () =>
    this.setState({ basicSearch: '', fulltextSearch: '', applyFilter: false });

  sortData = columnName => {
    let { sortBy, sortType } = this.state;
    const { module } = this.props.match.params;
    let checkAdvanceSearch = false;
    if (module === 'courier_package') {
      checkAdvanceSearch = true;
    }
    const columnNames = {
      memberName: 'member_name',
      businessName: 'business_name',
      status: 'status',
      buildingName: 'building_name',
      createdAt: 'created_at',
      updatedAt: 'updated_at',
    };
    if (sortBy === columnNames[columnName]) {
      sortType = !sortType;
    } else {
      sortType = true;
    }
    const ASC = sortType ? 'ASC' : 'DESC';
    if (checkAdvanceSearch) {
      this.setState({
        order_by: columnNames[columnName],
        order_asc: ASC,
        packages: [],
        sortBy: columnNames[columnName],
        sortType,
        loading: true,
        applyAdvancedSearch: true
      }, () => this.getData(this.props));
    } else {
      this.setState({
        order_by: columnNames[columnName],
        order_asc: ASC,
        packages: [],
        sortBy: columnNames[columnName],
        sortType,
        loading: true,
      }, () => this.getData(this.props));
    }
  };

  handleClose = (success) => {
    if (success === 'successDiscard') this.setState({ successDiscard: true });
    else if (success === 'successPickup') this.setState({ successPickup: true });
  };

  createRows = packages => {
    const { t, style, fullScreen, buildings } = this.props;
    const { tenant_id } = buildings[0];
    // const scanSend = t('tables.scanSend');
    // const scannedSent = t('tables.scannedSent');
    // const forward = t('tables.forward');
    // const forwarded = t('tables.forwarded');
    // const destroy = t('tables.destroy');
    // const destroyed = t('tables.destroyed');
    // const hold = t('tables.hold');
    // const held = t('tables.held');
    // const collected = t('tables.collected');
    // const holding = t('tables.holding');
    // const overdue = t('tables.overdue');
    // const discarded = t('tables.discarded');
    // const delivered = t('tables.delivered');
    const data = packages.map((item, j) => {
      const isHolding =
        Math.floor(
          Math.abs(Date.now() - new Date(`${item.created_at || item.check_in}`)) /
          (1000 * 3600 * 24),
        ) < 10;
      const { packageStatus, colour } = getPackageStatus(item.status, item.action_status, isHolding, item.activity_type, t);
      return {
        building_id: item.building_id,
        uuid: item.uuid,
        package_id: item.package_id,
        business_id: item.business_id,
        activity_type: item.activity_type ? item.activity_type : '---',
        name: (
          <div className="truncated-heading width-170">{item.member_name}</div>
        ),
        member_name: !item.member_name ? (
          '---'
        ) : item.member_name.length > 20 ? (
          <Tooltip TransitionComponent={Zoom} title={item.member_name}>
            {
              item.is_active
                ? (
                  <Link
                    className="truncated-heading width-89px"
                    to={`/member_detail/${item.member_id}`}
                  >
                    {truncateLabel(item.member_name)}
                  </Link>
                )
                : <span>{truncateLabel(item.member_name)}</span>
            }
          </Tooltip>
        ) : item.is_active
          ? (
            <Link to={`/member_detail/${item.member_id}`}>
              {truncateLabel(item.member_name)}
            </Link>
          )
          : truncateLabel(item.member_name),
        business_name:
          !item.member_businesses ||
            item.member_businesses.length < 1
            ? '---'
            : item.member_businesses.map((i, index) => (
              <>
                <Link
                  to={`/business_detail/${i.business_id}`}
                  // className="truncated-heading width-89px"
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: 89,
                  }}
                >
                  {`${truncateLabel(i.name)}`}
                </Link>
                {item.member_businesses.length - 1 === index ? '' : ','}
              </>
            )),
        building_name: !item.building_name ? (
          <p> --- </p>
        ) : item.building_name.length > 20 ? (
          <Tooltip TransitionComponent={Zoom} title={item.building_name}>
            <Link
              className={
                this.props.userRole === 'mailroom_supervisor' && 'disabled-link'
              }
              to={`/building_detail/${item.building_id}`}
              key={item.building_name}
              style={{ width: fullScreen ? 60 : 140 }}
            >
              {truncateLabel(item.building_name)}
            </Link>
          </Tooltip>
        ) : (
          <Link
            className={
              this.props.userRole === 'mailroom_supervisor' && 'disabled-link'
            }
            to={`/building_detail/${item.building_id}`}
            key={item.building_name}
            style={{ width: fullScreen ? 60 : 140 }}
          >
            {truncateLabel(item.building_name)}
          </Link>
        ),
        status:
          !item.status ? ('---') : (
            <div className="uppsercase" style={{ color: colour }}>
              {packageStatus}
            </div>
          ),
        created_at: !item.created_at ? '---'
          : `${moment(item.created_at).format('MMM D, YYYY')} @
          ${moment(item.created_at).format('hh:mm A')}`,
        updated_at: !item.updated_at ? '---'
          : `${moment(item.updated_at).format('MMM D, YYYY')} @
          ${moment(item.updated_at).format('hh:mm A')}`,
        thumbnail_string: item.package_thumbnail,
        package_thumbnail: <PackageDialog
          id={item.package_id}
          thumbnail={item.package_thumbnail}
          index={j}
          t={t}
          style={style}
          packages={packages}
          getData={this.getData}
          scanType={item.activity_type}
          handleClose={this.handleClose}
          dynamicLabels={this.props.dynamicLabels}
          tenant_id={tenant_id}
        />,
      };
    });
    return data;
  };

  tableRows(item) {
    const { tableHeads } = this.state;
    const { t } = this.props;
    let tableRowCells = [];
    if (tableHeads[0].expanded) tableRowCells.push(item.package_thumbnail);
    if (tableHeads[1].expanded) {
      tableRowCells.push(
        <TableCell className="padding-right-left truncated-heading width-200px">
          {' '}
          <span style={tableHeads[0].expanded ? { marginLeft: 0 } : {}}>
            {' '}
            {item.member_name}{' '}
          </span>{' '}
        </TableCell>,
      );
    }
    if (tableHeads[2].expanded) {
      tableRowCells.push(
        <TableCell
          className="padding-right-left truncated-heading width-200px"
          style={{ maxWidth: 89 }}
        >
          {' '}
          {item.business_name}{' '}
        </TableCell>,
      );
    }

    if (tableHeads[3].expanded) {
      tableRowCells.push(
        <TableCell className="padding-right-left truncated-heading width-200px">
          {' '}
          {item.building_name}{' '}
        </TableCell>,
      );
    }
    if (tableHeads[4].expanded) {
      tableRowCells.push(
        <TableCell className="padding-right-left truncated-heading width-100px">
          {' '}
          {item.status}{' '}
        </TableCell>,
      );
    }
    if (tableHeads[5].expanded) {
      tableRowCells.push(
        <TableCell className="padding-right-left truncated-heading width-100px">
          {' '}
          {item.created_at}{' '}
        </TableCell>,
      );
    }
    if (tableHeads[6].expanded) {
      tableRowCells.push(
        <TableCell className="padding-right-left truncated-heading width-15p">
          {' '}
          {item.updated_at}{' '}
        </TableCell>,
      );
    }
    if (tableHeads[7].expanded) {
      // item.activity_type = 'SCAN_IN' ? 'Inbound' : 'Outbound';
      tableRowCells.push(
        <TableCell className="padding-right-left truncated-heading width-15p">
          {' '}
          {item.activity_type === 'SCAN-IN' ? t('tables.scanIn') : t('tables.scanOut')}{' '}
        </TableCell>,
      );
    }
    return tableRowCells;
  }

  handleSwitchChange = index => {
    const tempArray = [...this.state.tableHeads];
    tempArray[index].expanded = !tempArray[index].expanded;
    this.setState({
      tableHeads: [...tempArray],
    });
  };

  open = e => {
    const { dropdown } = this.state;
    this.setState({ dropdown: dropdown ? null : e.currentTarget });
  };

  filter = filter => {
    if (filter.value !== this.filter.value) {
      this.setState({
        filter,
        basicSearch: this.state.filter.value === 1 && filter.value === 0 ? '' : this.state.basicSearch,
        fulltextSearch: this.state.filter.value === 0 && filter.value === 1 ? '' : this.state.fulltextSearch
      }, () => {
        if (this.state.filter.value === 1) {
          this.handleClearButtonAction();
          this.props.history.push('/mp-items/all_items');
        }
        this.startSearch();
      });
      // if (filter.value === 1 && this.props.building.length === 0) {
      //   console.log('');
      // } else {
      //   this.setState({
      //     filter,
      //     basicSearch: this.filter.value === 1 && filter.value === 0 ? '' : this.state.basicSearch
      //   }, () => this.startSearch());
      // }
    }
  };

  sendPackagesCSV = () => {
    Event('Packages', 'Export Data', '  Exporting packages data...');
    this.setState({ csvLoading: true, csvError: null, csvSuccess: null });
    let params = {};
    let mailroomId = [];
    const { building, buildings, sendPackagesCSV, userRole, t } = this.props;
    const { applyFilter, match, range, order_by, order_asc } = this.state;
    const { module, id, filter } = match.params;
    params.platform = 'dashboard';
    params.order_by = order_by;
    params.order_asc = order_asc === 'ASC';
    if (module === 'currently_held' || module === 'scanned') {
      params.graph_type_filter = false;
      params.date_filtering = false;
      if (module === 'scanned') params.graph_type_filter = true;
      else params.date_filtering = true;
      if (building.length > 0) params.building_id = building[0].building_id;
      if (userRole === 'mailroom_supervisor') {
        mailroomId = [];
        for (let i = 0; i < buildings[0].mailrooms.length; i++) {
          mailroomId.push(buildings[0].mailrooms[i].mailroom_id);
        }
        params.mailroom_id = mailroomId.toString();
      }
      if (id === '0_3') {
        params.to_date = moment().utc().subtract(1, 'd').toISOString();
        params.from_date = moment().utc().subtract(3, 'd').toISOString();
      } else if (id === '3_10') {
        params.to_date = moment().utc().subtract(4, 'd').toISOString();
        params.from_date = moment().utc().subtract(10, 'd').toISOString();
      } else {
        params.to_date = moment().utc().subtract(10, 'd').toISOString();
      }
      if (id === 'today') {
        params.to_date = moment().utc().toISOString();
        params.from_date = moment().utc().startOf('day').toISOString();
      } else if (id === '7_days') {
        params.to_date = moment().utc().toISOString();
        params.from_date = moment().utc().subtract(6, 'd').startOf('day')
          .toISOString();
      } else if (id === '4_weeks') {
        params.to_date = moment().utc().toISOString();
        params.from_date = moment().utc()
          .subtract(3, 'week')
          .startOf('week')
          .weekday(1)
          .toISOString();
      } else if (id === '12_months') {
        params.to_date = moment().utc().toISOString();
        params.from_date = moment().utc().subtract(11, 'month').startOf('day')
          .toISOString();
      }
    } else if (module === 'mailroom_packages') {
      params.building_id = null;
      params.mailroom_id = match.params.id;
    } else if (userRole === 'mailroom_supervisor') {
      for (let i = 0; i < buildings[0].mailrooms.length; i++) {
        mailroomId.push(buildings[0].mailrooms[i].mailroom_id);
      }
      params.mailroom_id = mailroomId.toString();
    }
    if (module === 'building_packages') {
      params.building_id = id;
      params.filter_option = filter;
    } else if (building.length > 0) {
      params.building_id = building[0].building_id;
    }
    if (
      module === 'business_packages' ||
      module === 'business_member_packages'
    ) {
      params.business_id = id;
      params.building_id = null;
      params.filter_option = filter;
    } else if (module === 'member_items') {
      params.member_id = id;
      params.building_id = null;
      params.filter_option = filter;
    } else if (module === 'member_all_items') {
      params.member_id = id;
      params.filter_option = filter;
    }
    if (range && range.start && applyFilter) {
      params.from_date = moment(range.start._i).utc().startOf('day').toISOString();
    } else {
      params.from_date = moment().utc().subtract(6, 'month').startOf('day')
        .toISOString();
    }
    if (range && range.end && applyFilter) {
      params.to_date = moment(range.end._i).utc().endOf('day').toISOString();
    } else {
      params.to_date = moment().utc().endOf('day').toISOString();
    }
    sendPackagesCSV(params)
      .then(() =>
        this.setState({
          csvLoading: false,
          csvSuccess: t('package.itWillTakeFewMinutes'),
        }),
      )
      .catch(err =>
        this.setState({
          csvLoading: false,
          csvError: err.response
            ? err.response.data.message
            : t('common.error.networkError'),
        }),
      );
  };

  checkPackageStatus = (type) => {
    const { t, userRole } = this.props;
    if (userRole !== 'mailroom_supervisor') {
      const { selectedPakgIds } = this.state;
      const check = selectedPakgIds.every(this.checkStatusForBulkAction);
      if (check) {
        if (type === 'Discard') {
          this.setState({ openDiscard: true }); // eslint-disable-next-line
        } else {
          this.setState({ openPickup: true });
        }
      } else {
        this.setState({ showBulkError: true, bulkErrorType: type === 'Discard' ? t('package.AllDeleteError') : t('package.AllPickupError') });
      }
    } else {
      this.setState({ showBulkError: true, bulkErrorType: t('package.PickUpDeleteRoleError') });
    }
  }

  checkPackageForDispatchStatus = () => {
    //  const { t } = this.props;
    const { selectedPakgIds } = this.state;
    const check = selectedPakgIds.every(this.checkStatusForBulkAction);
    if (check) {
      this.setState({ openDispatch: true });
    } else {
      this.setState({ showBulkError: true, bulkErrorType: "Dispatch action is only supported for items with Holding, Overdue and Held status. Please deselect all other items in order to proceed further." });
    }
  }

  handleClearButtonAction = () => {
    let { tableHeads } = this.state;
    tableHeads[4].value = 'status';
    tableHeads[3].value = 'buildingName';

    this.setState({
      applyAdvancedSearch: false,
      tableHeads,
      searchParams: {},
      error: null,
      activePage: 1,
      packages: [],
      clearAll: true,
      range: null,
      statusOption: null,
      scanType: 'All',
      advancedSearchAnchor: null,
      advancedSearchTags: [],
      basicSearch: '',
      fulltextSearch: '',
    });

    // this.setState({
    //   tableHeads,
    //   searchParams: {},
    //   applyAdvancedSearch: false,
    //   advancedSearchTags: [],
    //   statusOption: null,
    //   error: null,
    //   activePage: 1,
    //   packages: [],
    //   clearAll: true,
    //   range: null,
    //   scanType: 'All',
    //   basicSearch: '',
    //   fulltextSearch: '',
    //   appliedFilters: [],
    // }, () => this.onChangePage(1));
  }

  dismissCsvSnackbar = () =>
    this.setState({ csvSuccess: null, csvError: null });

  applyClearFilter = () => {
    this.setState({
      applyAdvancedSearch: false,
      searchParams: {},
      error: null,
      activePage: 1,
      packages: [],
      clearAll: true,
      range: null,
      statusOption: null,
      scanType: 'All',
      // advancedSearchAnchor: null,
      // advancedSearchTags: []
    });
  }

  handleDownloadData = () => {
    this.setState({
      exportCalenderAnchor: true
    });
  }

  handleTableDataExportModalClose = () => {
    this.setState({
      tableDataExportModal: false,
    });
  }

  handleTableDataExportModalConfirm = () => {
    if ((this.props.userRole === 'super_admin') && env && env.includes('demo')) {
      this.props.dispatch(tableDataExport(true));
      this.setState({
        exportInProgress: true,
        tableDataExportModal: false,
      });
    } else {
      const { building, userRole } = this.props;
      const { tableDataExportData } = this.state;
      const { toDate, fromDate } = tableDataExportData;
      let buildingId = null;
      if (building && building.length === 1) {
        buildingId = building[0].building_id;
      }
      // } else if (buildings.length > 0) {
      //   buildingId = buildings[0].buildings.map(eachBuilding => eachBuilding.building_id);
      // }
      if (userRole === 'mailroom_supervisor') {
        buildingId = null;
      }
      this.setState({
        exportInProgress: true,
        tableDataExportModal: false,
      });
      this.props.dispatch(tableDataExport(true));
      localStorage.setItem('exportingData', true);
      tableDataExportCSV(buildingId, toDate, fromDate, true)
        .then(response => {
          localStorage.setItem('exportingDataId', response[0].export_log_id);
        })
        .catch(() => {
          localStorage.removeItem('exportingData');
          localStorage.removeItem('exportingDataId');
          this.props.dispatch(tableDataExport(false));
          this.setState({
            tableDataExportStatus: 'error'
          });
        });
    }
  }

  closeDataExportError = () => {
    this.setState({ tableDataExportError: null });
  }

  handleAgree = () => {
    const { selectedPakgIds } = this.state;
    const ids = selectedPakgIds.map(packg => packg.pkgId);
    if (this.state.reason.trim().length === 0) {
      this.setState({ helper: true });
    } else if (this.state.openPickup) {
      this.setState({
        loadingPickup: true,
        errorPickup: '',
        errorDiscard: '',
        helper: false,
        disabled: false,
      });
      handleMark('picked', {
        package_ids: ids,
        marked_as_picked_reason: this.state.reason,
      })
        .then(() => {
          this.setState({
            successPickup: true,
            loadingPickup: false,
            openPickup: false,
            selectedPakgIds: [],
            reason: '',
            other: false,
          }, () => {
            this.handleClose('successPickup');
            this.getData();
          });
          // getPackageId(id, currentIndex, true);
        })
        .catch(err => {
          this.setState({
            errorPickup: err.response
              ? err.response.data
                ? err.response.data.message
                  ? err.response.data.message.message
                  : err.response.data.message
                : err.message
              : err.message,
            loadingPickup: false,
          });
        });
    } else {
      this.setState({
        errorPickup: '',
        loadingDiscard: true,
        errorDiscard: '',
        helper: false,
        disabled: false,
      });
      handleMark('discard', {
        package_ids: ids,
        discard_reason: this.state.reason,
        send_notification: this.state.notifyDiscard,
      })
        .then(() => {
          this.setState({
            loadingDiscard: false,
            openDiscard: false,
            selectedPakgIds: [],
            reason: '',
            notifyDiscard: false,
            other: false,
          }, () => {
            this.handleClose('successDiscard');
            this.getData();
          }
          );
          // handleClose('successDiscard');
          // // getPackageId(id, currentIndex, true);
          // getData();
        })
        .catch(err => {
          this.setState({
            errorDiscard: err.response
              ? err.response.data
                ? err.response.data.message
                : err.response.data.message
              : err.message,
            loadingDiscard: false,
          });
        });
    }
  };

  handleDispatch = () => {
    const { selectedPakgIds, dispatchBase64, dispatchFileType } = this.state;
    const { buildings } = this.props;
    const { tenant_id } = buildings[0];

    const ids = selectedPakgIds.map(packg => packg.pkgId);
    this.setState({
      loadingPickup: true,
      errorPickup: '',
      errorDiscard: '',
      helper: false,
      disabled: false,
    });
    handleMarkDispatch({
      package_ids: ids,
      tenant: tenant_id,
      building_id: selectedPakgIds[0] && selectedPakgIds[0].locId,
      label_file: dispatchBase64 && dispatchBase64,
      file_type: dispatchFileType && dispatchFileType
    })
      .then(() => {
        this.setState({
          successPickup: true,
          loadingPickup: false,
          openDispatch: false,
          selectedPakgIds: [],
          reason: '',
          other: false,
        }, () => {
          this.handleClose('successPickup');
          this.getData();
        });
        // getPackageId(id, currentIndex, true);
      })
      .catch(err => {
        this.setState({
          tableDataExportError: err.response
            ? err.response.data
              ? err.response.data.message
                ? err.response.data.message.message
                : err.response.data.message
              : err.message
            : err.message,
          loadingPickup: false,
        });
      });
  };


  handleReason = e => {
    const { t } = this.props;
    if (e.target.value === t('package.other')) {
      this.setState({
        other: true,
        reason: '',
        helper: false,
        disabled: true,
      });
    } else {
      this.setState({
        reason: e.target.value,
        other: false,
        helper: false,
        disabled: false,
      });
    }
  };

  handleOperatorErrorMsg = () => {
    const { t } = this.props;
    this.setState({ showBulkError: true, bulkErrorType: t('package.PickUpDeleteRoleError') });
  }

  handleDispatchImageChange = (files) => {
    this.setState({
      Dispatchfiles: files
    });
  }


  render() {
    const {
      t,
      style,
      width,
      //  userRole,
      redirect,
      building,
      buildings,
      dynamicLabels,
    } = this.props;
    const {
      basicSearch,
      filter,
      grid,
      applySearch,
      view,
      fulltextSearch,
      member,
      loading,
      packages,
      error,
      columnSwitcher,
      totalPackages,
      tableHeads,
      sortType,
      sortBy,
      firstSearchError,
      activePage,
      match,
      // csvLoading,
      // csvError,
      // csvSuccess,
      successDiscard,
      successPickup,
      range,
      searchParams,
      advancedSearchAnchor,
      advancedSearchTags,
      exportDataRange,
      tableDataExportModal,
      exportInProgress,
      tableDataExportStatus,
      tableDataExportError,
      defaultModule,
      selectedPakgIds,
      loadingPickup,
      loadingDiscard,
      openPickup,
      openDiscard,
      errorDiscard,
      errorPickup,
      reason,
      helper,
      other,
      notifyDiscard,
      disabled,
      // exportTableData,
      // exportCalenderAnchor,
      packagesData,
      showBulkError,
      bulkErrorType,
      buildingFilterFlag,
      encryptedEmail,
      openDispatch,
      dispatchFileName
    } = this.state;

    if (encryptedEmail) {
      this.props.history.replace(encryptedEmail);
    }

    const { userRole } = this.props;
    let reasons = openDiscard
      ? [
        {
          label: t('package.label1'),
        },
        {
          label: t('package.label2'),
        },
        {
          label: t('package.label3'),
        },
      ]
      : [
        {
          label: t('package.label4'),
        },
      ];

    const tableMessage = !error
      ? packages.length > 0
        ? ''
        : t('voItems.noVoitemsToShow')
      : error;
    if (window.history && window.history.pushState) {
      window.onpopstate = () => {
        this.historyChange();
      };
    }
    let showClearBtn = false;
    let disableRanges = false;
    if (!defaultModule) {
      switch (match.params.module) {
        case 'mailroom_packages': showClearBtn = true; break;
        case 'building_packages': showClearBtn = true; break;
        case 'business_packages': showClearBtn = true; break;
        case 'member_items': showClearBtn = true; break;
        case 'member_all_items': showClearBtn = true; break;
        case 'currently_held': {
          showClearBtn = true;
          disableRanges = true;
          break;
        }
        case 'scanned': {
          showClearBtn = true;
          disableRanges = true;
          break;
        }
        case 'business_member_packages': showClearBtn = true; break;
        default: showClearBtn = false;
      }
    }

    let sampleTagsData;
    if (advancedSearchTags.length !== 0) {
      sampleTagsData = advancedSearchTags.map(item => {
        const temp = voAdvanceSearchOptions.find(tempItem => tempItem.label === item);
        return temp;
      });
    }
    return (
      <Fragment>
        <div className="dashboard container" style={{ marginTop: -10 }}>
          {
            tableDataExportError &&
            <Alert
              variant="error"
              open={tableDataExportError !== null}
              message={tableDataExportError}
              onClose={this.closeDataExportError}
            />
          }
          {(successDiscard || successPickup) &&
            <Alert
              variant="success"
              open={successDiscard || successPickup}
              message={successPickup ? 'Your item was marked as dispatched successfully.' : ''}
              onClose={() => this.setState({ successDiscard: false, successPickup: false })}
            />}
          <Grid item md={12} style={{ marginBottom: width !== ('xs' || 'sm') && -20 }}>
            <div
              className={
                width === ('xs' || 'sm' || 'md') ? 'block' : 'space-between'
              }
              style={{ marginTop: -3 }}
            >
              <div className={`packages-heading-height ${width === 'xs' && 'height-32px'}`}>
                <h3 className="page-title">{t('voItems.voItems')}</h3>
                {(() => {
                  if (defaultModule) {
                    return (
                      <div style={{ marginLeft: 10, marginTop: 3 }}>
                        <BuildingToggle {...this.props} />{' '}
                      </div>
                    );
                  } else {
                    switch (match.params.module) {
                      case 'building_packages':
                        return null;
                      default:
                        return (
                          <div style={{ marginLeft: 10, marginTop: 3 }}>
                            <BuildingToggle {...this.props} />{' '}
                          </div>
                        );
                    }
                    // switch (match.params.module) {
                    //   case 'mailroom_packages':
                    //     return null;
                    //   case 'building_packages':
                    //     return null;
                    //   case 'business_packages':
                    //     return null;
                    //   case 'member_items':
                    //     return null;
                    //   case 'member_all_items':
                    //     return null;
                    //   case 'currently_held':
                    //     return null;
                    //   case 'scanned':
                    //     return null;
                    //   case 'business_member_packages':
                    //     return null;
                    //   default:
                    //     return (
                    //       <div style={{ marginLeft: 10, marginTop: 3 }}>
                    //         <BuildingToggle {...this.props} />{' '}
                    //       </div>
                    //     );
                    // }
                  }
                })()}
              </div>
              <div className={`packages-heading-height ${width === 'xs' && 'height-32px'}`}>
                {/* <ExportData
                disabled={
                  loading ||
                  csvLoading ||
                  error ||
                  applyAdvancedSearch ||
                  packages.length === 0 ||
                  (filter.value === 0 && basicSearch.length > 0) ||
                  (filter.value === 1 && fulltextSearch.length > 0) ||
                  moment(range && range.end).diff(moment(range && range.start), 'months', true) > 6
                }
                loading={csvLoading}
                error={csvError}
                success={csvSuccess}
                confirm={this.sendPackagesCSV}
                onClose={this.dismissCsvSnackbar}
                t={t}
                {...this.props}
              /> */}
                {/* <GetApp
                  style={{ 
                    cursor: 'pointer',
                    opacity: exportTableData && '0.5'
                  }}
                  onClick={this.handleDownloadData}
                /> */}
                {
                  tableDataExportStatus &&
                  <Alert
                    variant={tableDataExportStatus === "error" ? "error" : "success"}
                    open={tableDataExportStatus}
                    message={tableDataExportStatus === "error" ? t('common.error.networkError') : t('package.tableDataExportInProgress')}
                    onClose={() => this.setState({ tableDataExportStatus: null })}
                  />
                }
                {
                  tableDataExportModal &&
                  <DataExportConfirmationModal
                    t={t}
                    tableDataExportModal={tableDataExportModal}
                    handleTableDataExportModalClose={this.handleTableDataExportModalClose}
                    handleTableDataExportModalConfirm={this.handleTableDataExportModalConfirm}
                  />
                }

                {
                  <Button
                    fullWidth
                    className="width-100-160-130 fs-14-11 black bg-white height-40"
                    style={{
                      boxShadow: "0px 6px 9px rgba(144, 164, 183, 0.22)",
                      borderRadius: "3px",
                      background: "#FFFFFF",
                      marginRight: 20
                    }}
                    onClick={() => {
                      window.open('https://cloud.packagex.io/ship/shipments', "_blank");
                    }}
                  >
                    {/* {t('package.markaspicked')} */}
                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      fontWeight: 700,
                      color: "#530EC1",
                      textTransform: "none",
                    }}>
                      <img height="15px" src={ShipImage} alt="ship items" />   Ship Items
                    </div>
                  </Button>
                }


                {
                  // userRole && (
                  //   ((userRole !== 'admin' && userRole !== 'Admin' && userRole !== 'super_admin')
                  //     || ((userRole === 'super_admin') && env && env.includes('demo')))
                  // ) &&
                  <div style={{ marginTop: 3 }}>
                    <DateRangeTableDataExport
                      t={t}
                      match={match}
                      range={exportDataRange}
                      disabled={loading}
                      exportInProgress={exportInProgress}
                      getDates={(start, end) => this.setDates(start, end)}
                      clearFilter={this.clearFilter}
                      applyFilter={this.applyDateRangeFilter}
                      hideButton={range}
                      isOperator={userRole === 'mailroom_supervisor'}
                      handleOperatorErrorMsg={this.handleOperatorErrorMsg}
                    />
                  </div>
                }
                {/* {
                  //This is the default normal flow for user below admin roles.
                  (userRole && userRole !== 'admin' && userRole !== 'Admin' && userRole !== 'super_admin') &&
                  <div style={{ marginRight: 20 }}>
                    <DateRangeTableDataExport
                      t={t}
                      match={match}
                      range={exportDataRange}
                      disabled={loading}
                      exportInProgress={exportInProgress}
                      getDates={(start, end) => this.setDates(start, end)}
                      clearFilter={this.clearFilter}
                      applyFilter={this.applyDateRangeFilter}
                      hideButton={range}
                    />
                  </div>
                } */}
                {view !== 'grid' ? selectedPakgIds.length ?
                  (
                    <>
                      {/* <Tooltip title={t('package.markaspicked')}>  
                        <IconButton
                          className="grid-switch-buttons"
                          style={{
                            marginTop: '2px',
                            background: '#58D05D',
                          }}
                          onClick={() => this.checkPackageStatus('Pickup')}
                        >
                          <AssignmentTurnedInIcon style={{ color: "#FFFFFF" }} />
                        
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('package.markasdelete')}>
                        <IconButton
                          className="grid-switch-buttons"
                          style={{
                            marginTop: '2px',
                            background: '#E74B3D',
                          }}
                          onClick={() => this.checkPackageStatus('Discard')}
                        >
                          <DeleteForeverIcon style={{ color: "#FFFFFF" }} />
                       
                        </IconButton>
                      </Tooltip> */}
                      <Tooltip title={t('Mark as Dispatched')}>
                        <IconButton
                          className="grid-switch-buttons"
                          style={{
                            marginTop: '2px',
                            background: '#58D05D',
                          }}
                          onClick={() => this.checkPackageForDispatchStatus()}
                        >
                          <LocalShipping style={{ color: "#FFFFFF" }} />
                          {/* <img alt="mark as pick icon" src={MarkPickup} height={32} width={32} /> */}
                        </IconButton>
                      </Tooltip>
                    </>
                  )
                  : "" : ""}

                <IconButton
                  className="grid-switch-buttons"
                  style={{
                    color: view === 'grid' ? '#FFFFFF' : '#027AFF',
                    background: view === 'grid' ? '#027AFF' : '#FFFFFF',
                  }}
                  onClick={() => this.handleView('grid')}
                >
                  <Apps />
                </IconButton>
                <IconButton
                  className="grid-switch-buttons"
                  style={{
                    color: view === 'list' ? '#FFFFFF' : '#027AFF',
                    background: view === 'list' ? '#027AFF' : '#FFFFFF',
                  }}
                  onClick={() => this.handleView('list')}
                >
                  <Menu />
                </IconButton>
              </div>
            </div>
            {openDispatch && <Dialog
              onClose={() =>
                this.setState({
                  openDispatch: false,
                  dispatchFileName: null,
                  dispatchBase64: null,
                  dispatchFileType: null
                })
              }
              height={400}
              open={openDispatch}
              PaperProps={{ style: { overflow: 'hidden', margin: 20 } }}
            >
              <DialogTitle>

                <Flex
                  style={{
                    fontSize: 22,
                    fontWeight: 'bold',
                    paddingTop: 10,
                    letterSpacing: 1,
                  }}
                >
                  {/* eslint-disable-next-line */}
                  {selectedPakgIds.length < 10 ? ('0' + selectedPakgIds.length + ' ' + 'Dispatch Items') : (selectedPakgIds.length + ' ' + "Dispatch Items")}
                  {''}
                  <Tooltip title="dispatch these items....">
                    <SupportContactIcon />
                  </Tooltip>
                </Flex>
              </DialogTitle>
              <DialogContent style={{ minWidth: 600 }}>

                <Dropzone
                  accept="image/png, application/pdf"
                  multiple={false}
                  onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                  style={{ width: '100%', height: 'auto', cursor: "pointer" }}
                >
                  <div className="csv-upload-modal">
                    <>
                      <div className="csv-upload-modal-svg">
                        <img src={Cloud} alt="Upload CSV file" />
                        <h3>Upload PNG or PDF file</h3>
                        <p style={{ fontSize: "16px" }}>Upload your shipping label as proof of Dispatch </p>
                        {/* <p>Upload CSV after adding recipient details</p> */}
                      </div>
                      <div className="csv-upload-modal-button">
                        <h4 className="margin-20">{t('members.csvMaxSize')}</h4>
                        {/* <UploadBtn
                            // component={Dropzone}
                            // disabled={loading}
                            multiple={false}
                            // accept="text/csv, application/xls, application/excel, application/vnd.ms-excel, application/vnd.ms-excel, charset=binary, application/msexcel, application/x-excel, application/x-msexcel, application/x-ms-excel, application/x-dos_ms_excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/csv, application/x-csv, text/csv, text/comma-separated-values, text/x-comma-separated-values, text/tab-separated-values"
                            onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                            capitalize
                            width={180}
                            accept="image/png, image/jpg, image/jpeg"
                          >
                            Upload Label
                          </UploadBtn> */}

                        {dispatchFileName && (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <h4 className="margin-20">{dispatchFileName}</h4>
                            <DeleteIcon onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              this.setState({
                                dispatchFileName: null,
                                dispatchBase64: null,
                                dispatchFileType: null
                              });
                            }} />
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                </Dropzone>


              </DialogContent>
              {<DialogActions style={{
                justifyContent: "center"
              }}>
                <Flex>
                  <Button
                    onClick={() => {
                      this.handleDispatch();
                    }
                    }
                    disabled={loadingPickup}
                    style={{
                      color: 'white',
                      display: 'flex',
                      width:
                        window.screen.width < 400 && window.screen.width > 300
                          ? 175
                          : window.screen.width < 450 && window.screen.width > 400
                            ? 190
                            : 270,
                      height: 35,
                      alignItem: 'center',
                      justifyContent: 'center',
                      marginRight: window.screen.width < 500 ? 20 : 10,
                      backgroundColor:
                        loadingPickup
                          ? '#E5E5E5'
                          : openDispatch
                            ? '#027AFF'
                            : openDiscard
                              ? '#E74C3C'
                              : '#E5E5E5',
                      marginBottom: 20,
                      marginTop: openPickup ? 35 : 10,
                    }}
                  >
                    {"Mark as Dispatched"}
                    {(loadingPickup) && (
                      <CircularProgress
                        size={25}
                        style={{ marginLeft: 10, color: 'white' }}
                      />
                    )}
                  </Button>
                </Flex>
              </DialogActions>}
            </Dialog>}

            <Dialog
              // onClose={this.handleClose}
              onClose={() =>
                this.setState({
                  openDiscard: false,
                  openPickup: false,
                  openDispatch: false,
                  disabled: true,
                  other: false,
                  errorDiscard: '',
                  errorPickup: '',
                  reason: '',
                })
              }
              open={openDiscard || openPickup}
              PaperProps={{ style: { overflow: 'hidden', margin: 20 } }}
            >
              <DialogTitle>
                {openDiscard ? (
                  <Flex
                    style={{
                      fontSize: 22,
                      fontWeight: 'bold',
                      paddingTop: 10,
                      letterSpacing: 1,
                    }}
                  >
                    {/* eslint-disable-next-line */}
                    {selectedPakgIds.length < 10 ? ('0' + selectedPakgIds.length + ' ' + t('package.deletePackage')) : (selectedPakgIds.length + ' ' + t('package.deletePackage'))}
                    {''}
                    <Tooltip title={t('package.disclaimerdefault')}>
                      <SupportContactIcon />
                    </Tooltip>
                  </Flex>
                ) :
                  openDispatch ? (
                    <Flex
                      style={{
                        fontSize: 22,
                        fontWeight: 'bold',
                        paddingTop: 10,
                        letterSpacing: 1,
                      }}
                    >
                      {/* eslint-disable-next-line */}
                      {selectedPakgIds.length < 10 ? ('0' + selectedPakgIds.length + ' ' + 'Dispatch Items') : (selectedPakgIds.length + ' ' + "Dispatch Items")}
                      {''}
                      <Tooltip title={t('package.disclaimerdefault')}>
                        <SupportContactIcon />
                      </Tooltip>
                    </Flex>
                  ) :
                    (
                      <Flex
                        style={{
                          fontSize: 22,
                          fontWeight: 'bold',
                          paddingTop: 10,
                          letterSpacing: 1,
                        }}
                      >
                        {/* eslint-disable-next-line */}
                        {selectedPakgIds.length < 10 ? ('0' + selectedPakgIds.length + ' ' + t('package.pickUpPackage')) : (selectedPakgIds.length + ' ' + t('package.pickUpPackage'))}

                        <Tooltip title={t('package.disclaimer')}>
                          <SupportContactIcon />
                        </Tooltip>
                      </Flex>
                    )}
              </DialogTitle>

              <DialogContent style={{ minWidth: 600 }}>
                <FormControl>
                  <Label>
                    <Flex style={{ fontSize: 16, color: '#027AFF' }}>
                      {t('package.whyDo')}{' '}
                      {`${openDiscard ? t('package.discardItems') : openDispatch ? 'You want to dispatch items' : t('package.pickUpItems')}`}
                      <span className="red">*</span>
                    </Flex>
                  </Label>
                  {
                    (openDiscard || openPickup) && (
                      <MaterialSelect
                        name="building_id"
                        value={other ? t('package.other') : reason}
                        onChange={this.handleReason}
                        input={
                          <CustomInput
                            style={{
                              maxWidth:
                                window.screen.width < 400 && window.screen.width > 300
                                  ? 210
                                  : window.screen.width > 400 &&
                                    window.screen.width < 500
                                    ? 260
                                    : window.screen.width > 300 &&
                                      window.screen.width < 350
                                      ? 190
                                      : 550,
                              minWidth:
                                window.screen.width < 400 && window.screen.width > 300
                                  ? 210
                                  : window.screen.width > 400 &&
                                    window.screen.width < 500
                                    ? 260
                                    : window.screen.width > 300 &&
                                      window.screen.width < 350
                                      ? 190
                                      : 550,
                              marginTop: window.screen.width < 500 ? 30 : 20,
                              fontSize: 14,
                            }}
                          />
                        }
                        MenuProps={{ style: { width: 210 } }}
                      >
                        <MenuItem
                          value="building"
                          disabled
                          style={{
                            whiteSpace: 'normal',
                            flexWrap: 'wrap',
                            height: 30,
                            fontSize: 14,
                          }}
                        >
                          {t('package.select')}
                        </MenuItem>
                        {reasons.map(cause => (
                          <MenuItem
                            value={cause.label}
                            key={cause.label}
                            style={{
                              whiteSpace: 'normal',
                              flexWrap: 'wrap',
                              height: 35,
                              fontSize: 14,
                            }}
                          >
                            {cause.label}
                          </MenuItem>
                        ))}
                        <MenuItem
                          value={t('package.other')}
                          style={{
                            whiteSpace: 'normal',
                            flexWrap: 'wrap',
                            height: 30,
                            fontSize: 14,
                          }}
                        >
                          {t('package.other')}
                        </MenuItem>
                      </MaterialSelect>
                    )
                  }

                </FormControl>
              </DialogContent>

              {(errorDiscard.length > 0 || errorPickup.length > 0) && (
                <AlignCenter>
                  <Chip
                    label={errorDiscard.length > 0 ? errorDiscard : errorPickup}
                    color="secondary"
                  />
                </AlignCenter>
              )}
              {other && (
                <DialogContent style={{ overflowX: 'hidden' }}>
                  <FormControl>
                    <Label>
                      <Flex style={{ fontSize: 16, color: '#027AFF' }}>
                        {t("package.packageWhyOther")}
                        <span className="red">*</span>
                      </Flex></Label>
                    <CustomInput
                      style={{
                        overflowX: 'hidden',
                        maxWidth:
                          window.screen.width < 400 && window.screen.width > 300
                            ? 210
                            : window.screen.width > 400 && window.screen.width < 500
                              ? 260
                              : window.screen.width > 300 && window.screen.width < 350
                                ? 190
                                : 550,
                        minWidth:
                          window.screen.width < 400 && window.screen.width > 300
                            ? 210
                            : window.screen.width > 400 && window.screen.width < 500
                              ? 260
                              : window.screen.width > 300 && window.screen.width < 350
                                ? 190
                                : 550,
                        fontSize: 14,
                      }}
                      value={reason}
                      onChange={e => this.handleOther(e)}
                      required
                      rowsMax={5}
                    />
                  </FormControl>
                </DialogContent>
              )}
              {helper && (
                <DialogContent style={{ marginTop: -22, width: 400 }}>
                  <p style={{ fontSize: 12, color: '#FF1E59' }}>
                    {t('package.action')}
                  </p>
                </DialogContent>
              )}
              {openDiscard && (
                <DialogContent>
                  <FormControlLabel
                    control={
                      <BlueCheckbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 16 }}
                            color="#fdb61e"
                          />
                        }
                        checkedIcon={<CheckBoxIcon className="blue" />}
                        checked={notifyDiscard}
                        onChange={e =>
                          this.setState({ notifyDiscard: e.target.checked })
                        }
                      />
                    }
                    label={t('package.notify')}
                  />
                </DialogContent>
              )}
              <DialogActions>
                <Button
                  onClick={() => {
                    if (openDiscard) {
                      this.handleDispatch();
                    } else {
                      this.handleAgree();
                    }
                  }
                  }
                  disabled={loadingPickup || loadingDiscard || disabled}
                  style={{
                    color: 'white',
                    display: 'flex',
                    width:
                      window.screen.width < 400 && window.screen.width > 300
                        ? 175
                        : window.screen.width < 450 && window.screen.width > 400
                          ? 190
                          : 270,
                    height: 35,
                    alignItem: 'center',
                    justifyContent: 'center',
                    marginRight: window.screen.width < 500 ? 20 : 10,
                    backgroundColor:
                      loadingPickup || loadingDiscard || disabled
                        ? '#E5E5E5'
                        : openPickup
                          ? '#027AFF'
                          : openDiscard
                            ? '#E74C3C'
                            : '#E5E5E5',
                    marginBottom: 20,
                    marginTop: openPickup ? 35 : 10,
                  }}
                >
                  {openPickup ? t('package.markaspicked') : openDispatch ? "Mark as Dispatched" : t('package.markasdelete')}
                  {(loadingPickup || loadingDiscard) && (
                    <CircularProgress
                      size={25}
                      style={{ marginLeft: 10, color: 'white' }}
                    />
                  )}
                </Button>
              </DialogActions>
            </Dialog>


            {
              showBulkError &&
              <Alert
                variant="error"
                open={showBulkError}
                message={bulkErrorType}
                onClose={() => this.setState({ showBulkError: false })}
              />
            }
          </Grid>
          <Grid item md={12}>
            <Paper className="paper">
              <Grid container className="padding-10px">
                <Grid xs={12} sm={12} md={12} lg={4} xl={3} className="align-items-center">
                  <div className="align-items-center">
                    <p className="heading fs-14px">
                      {(() => {
                        if (defaultModule) {
                          return t('main.recentPackages');
                        } else {
                          switch (match.params.module) {
                            case 'member_items':
                              return member;
                            case 'member_all_items':
                              return member;
                            case 'business_member_packages':
                              return member;
                            case 'business_packages':
                              return member;
                            case 'building_packages':
                              return member;
                            case 'scanned': {
                              switch (match.params.id) {
                                case 'today': {
                                  switch (match.params.filter) {
                                    case 'scanned':
                                      return t('package.ScannedToday');
                                    case 'outstanding':
                                      return t('package.notifiedToday');
                                    default:
                                      return t('package.collectedToday');
                                  }
                                }
                                case '7_days': {
                                  switch (match.params.filter) {
                                    case 'scanned':
                                      return t('package.ScannedInLastSevenDays');
                                    case 'outstanding':
                                      return t('package.notifiedInLastSevenDays');
                                    default:
                                      return t('package.collectedInLastSevenDays');
                                  }
                                }
                                case '4_weeks': {
                                  switch (match.params.filter) {
                                    case 'scanned':
                                      return t('package.ScannedInLastFourWeeks');
                                    case 'outstanding':
                                      return t('package.notifiedInLastFourWeeks');
                                    default:
                                      return t('package.collectedInLastFourWeeks');
                                  }
                                }
                                default: {
                                  switch (match.params.filter) {
                                    case 'scanned':
                                      return t('package.ScannedInLastTwelveMonths');
                                    case 'outstanding':
                                      return t('package.notifiedInLastTwelveMonths');
                                    default:
                                      return t('package.collectedInLastTwelveMonths');
                                  }
                                }
                              }
                            }
                            case 'currently_held': {
                              switch (match.params.id) {
                                case '0_3':
                                  return t('package.heldSinceThreeDays');
                                case '3_10':
                                  return t('package.heldThreeToTenDays');
                                default:
                                  return t('package.overTenDays');
                              }
                            }
                            case 'mailroom_packages':
                              return t('package.mailroomPendingPackages');
                            default:
                              return t('main.recentPackages');
                          }
                        }
                      })()}
                    </p>
                    {
                      (showClearBtn || (Object.keys(searchParams).length !== 0)) && (
                        <MUIButton
                          to="/mp-items/all_items"
                          component={Link}
                          onClick={this.handleClearButtonAction}
                          // onClick={() => this.setState({
                          //   searchParams: {},
                          //   error: null,
                          //   activePage: 1,
                          //   packages: [],
                          //   clearAll: true,
                          //   range: null,
                          //   statusOption: null,
                          //   scanType: 'All',
                          // })}
                          className="margin-left-10p padding-0 blue"
                          redirect={redirect}
                        >
                          {t('package.clearFilter')}
                        </MUIButton>
                      )}
                    {/* {
                      (Object.keys(searchParams).length !== 0) && (
                      <MUIButton
                        onClick={this.handleClearButtonAction}
                        className="margin-left-10p padding-0 blue"
                      >
                        {t('package.clearFilter')}
                      </MUIButton>
                    )
                    } */}
                  </div>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={8} xl={9}>
                  <div className="space-between-with-block-768 margin-0px">
                    <div />
                    <div className="space-between space-between-with-block-768 margin-0px">
                      <div
                        style={{
                          display: width === 'xs' ? 'block' : 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <div className="topbar__search width-100 margin-0px">
                          {
                            filter.value === 0 &&
                            <form
                              style={{ width: '100%' }}
                            >
                              <SpaceBetween style={{ alignItems: 'center' }}>
                                <InputBase
                                  disabled
                                  aria-haspopup="true"
                                  className="topbar__search-field topbar__search-field--open search max-height-32"
                                  inputProps={{
                                    style: {
                                      cursor: 'pointer',
                                      width: '100%',
                                      fontSize: 14,
                                      textOverflow:
                                        window.screen.width < 400
                                          ? 'ellipsis'
                                          : 'initial',
                                      overflow: 'hidden',
                                    },
                                    maxlength: 100,
                                  }}
                                  placeholder={filter.value === 0 ? t('package.enterNameIdStatus') : t('package.enterTrackingId')}
                                  value={filter.value === 0 ? basicSearch : fulltextSearch}
                                // onChange={this.handleChange(filter === 0 ? 'basicSearch' : 'fulltextSearch')}
                                // startAdornment={
                                //   <Tooltip
                                //     title="Please enter atleast two characters"
                                //     open={
                                //       (filter.value === 0 &&
                                //         basicSearch.length < 2 &&
                                //         basicSearch !== '') ||
                                //       (filter.value === 1 &&
                                //         fulltextSearch.length < 2 &&
                                //         fulltextSearch !== '')
                                //     }
                                //   >
                                //     <Search style={{
                                //       marginRight: window.screen.width < 500 ? 0 : 10,
                                //       fontSize: 14,
                                //       paddingBottom: 1,
                                //     }}
                                //     />
                                //   </Tooltip>
                                // }
                                // endAdornment={
                                //   <InputAdornment position="end">
                                //     <IconButton
                                //       style={{
                                //         padding: 14,
                                //         display: (filter.value === 0 && basicSearch.length > 0) ||
                                //           (filter.value === 1 && fulltextSearch.length > 0) ? 'flex' : 'none',
                                //       }}
                                //       onClick={() => {
                                //         this.setState({
                                //           basicSearch: filter.value === 0 ? '' : this.state.basicSearch,
                                //           fulltextSearch: filter.value === 1 ? '' : this.state.fulltextSearch,
                                //         }, () => this.clearSearch());
                                //       }}
                                //     >
                                //       <Cancel className="cancel-icon" />
                                //     </IconButton>
                                //   </InputAdornment>
                                // }
                                />
                                <div
                                  style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer'
                                  }}
                                  role="presentation"
                                  onClick={loading ? {} : e => this.onSearchFieldClick(e)}
                                />
                                {
                                  advancedSearchAnchor !== null &&
                                  <AdvancedSearch
                                    t={t}
                                    match={match}
                                    style={style}
                                    {...this.state}
                                    loading={loading}
                                    building={building}
                                    buildings={buildings}
                                    filter={this.applySearch}
                                    disableRanges={disableRanges}
                                    userRole={this.props.userRole}
                                    startSearch={this.startSearch}
                                    filterValue={this.state.filter}
                                    onClose={this.onSearchFieldClose}
                                    handleCancelSearch={this.clearSearch}
                                    statusFilter={this.state.statusOption}
                                    searchParams={this.state.searchParams}
                                    module={this.props.match.params.module}
                                    handleSearchFieldChange={this.handleChange}
                                  />
                                }
                              </SpaceBetween>
                            </form>
                          }
                          {
                            (filter.value === 1) &&
                            <form
                              onSubmit={e => {
                                e.preventDefault();
                                if (!loading) {
                                  this.startSearch();
                                }
                              }}
                              style={{ width: '100%' }
                              }
                            >
                              <SpaceBetween style={{ alignItems: 'center' }}>
                                <InputBase
                                  aria-haspopup="true"
                                  className="topbar__search-field topbar__search-field--open search max-height-32"
                                  inputProps={{
                                    style: {
                                      width: '100%',
                                      fontSize: 14,
                                      textOverflow:
                                        window.screen.width < 400
                                          ? 'ellipsis'
                                          : 'initial',
                                      overflow: 'hidden',
                                    },
                                    maxlength: 100,
                                  }}
                                  placeholder={filter.value === 0 ? t('package.enterNameIdStatus') : t('package.enterTrackingId')}
                                  value={filter.value === 0 ? basicSearch : fulltextSearch}
                                  onChange={this.handleChange('fulltextSearch')}
                                  startAdornment={
                                    <Tooltip
                                      title="Please enter atleast two characters"
                                      open={
                                        (filter.value === 0 &&
                                          basicSearch.length < 2 &&
                                          basicSearch !== '') ||
                                        (filter.value === 1 &&
                                          fulltextSearch.length < 2 &&
                                          fulltextSearch !== '')
                                      }
                                    >
                                      <Search style={{
                                        marginRight: window.screen.width < 500 ? 0 : 10,
                                        fontSize: 14,
                                        paddingBottom: 1,
                                      }}
                                      />
                                    </Tooltip>
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        style={{
                                          padding: 14,
                                          display: (filter.value === 0 && basicSearch.length > 0) ||
                                            (filter.value === 1 && fulltextSearch.length > 0) ? 'flex' : 'none',
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            basicSearch: filter.value === 0 ? '' : this.state.basicSearch,
                                            fulltextSearch: filter.value === 1 ? '' : this.state.fulltextSearch,
                                          }, () => this.clearSearch());
                                        }}
                                      >
                                        <Cancel className="cancel-icon" />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </SpaceBetween>
                            </form>
                          }
                          {/* {
                            (filter.value === 0 || filter.value === 1) &&
                            <form
                              onSubmit={e => {
                                e.preventDefault();
                                if (!loading) {
                                  this.startSearch();
                                }
                              }}
                              style={{ width: '100%' }
                              }
                            >
                              <SpaceBetween style={{ alignItems: 'center' }}>
                                <InputBase
                                  aria-haspopup="true"
                                  className="topbar__search-field topbar__search-field--open search max-height-32"
                                  inputProps={{
                                    style: {
                                      width: '100%',
                                      fontSize: 14,
                                      textOverflow:
                                        window.screen.width < 400
                                          ? 'ellipsis'
                                          : 'initial',
                                      overflow: 'hidden',
                                    },
                                    maxlength: 100,
                                  }}
                                  placeholder={filter.value === 0 ? t('package.enterNameIdStatus') : t('package.enterTrackingId')}
                                  value={filter.value === 0 ? basicSearch : fulltextSearch}
                                  onChange={this.handleChange('fulltextSearch')}
                                  startAdornment={
                                    <Tooltip
                                      title="Please enter atleast two characters"
                                      open={
                                        (filter.value === 0 &&
                                          basicSearch.length < 2 &&
                                          basicSearch !== '') ||
                                        (filter.value === 1 &&
                                          fulltextSearch.length < 2 &&
                                          fulltextSearch !== '')
                                      }
                                    >
                                      <Search style={{
                                        marginRight: window.screen.width < 500 ? 0 : 10,
                                        fontSize: 14,
                                        paddingBottom: 1,
                                      }}
                                      />
                                    </Tooltip>
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        style={{
                                          padding: 14,
                                          display: (filter.value === 0 && basicSearch.length > 0) ||
                                            (filter.value === 1 && fulltextSearch.length > 0) ? 'flex' : 'none',
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            basicSearch: filter.value === 0 ? '' : this.state.basicSearch,
                                            fulltextSearch: filter.value === 1 ? '' : this.state.fulltextSearch,
                                          }, () => this.clearSearch());
                                        }}
                                      >
                                        <Cancel className="cancel-icon" />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </SpaceBetween>
                            </form>
                          } */}
                        </div>
                        {/* { */}
                        {/* (env && (env.includes('wework') || env.includes('demo'))) && */}
                        <Select
                          // isDisabled
                          isSearchable={false}
                          value={filter}
                          onChange={this.filter}
                          components={{ DropdownIndicator }}
                          className="min-width-150px margin-4px"
                          styles={{
                            input: (provided) => ({ ...provided, textShadow: '0 0 0 #2196f3' }),
                            dropdownIndicator: (provided) => ({ ...provided, padding: 0 }),
                            control: (provided) => ({ ...provided, height: 32, minHeight: 32 }),
                            option: (provided, state) => ({ ...provided, backgroundColor: state.isSelected ? '#318CE8' : 'white', color: state.isSelected ? 'white' : 'black', cursor: 'pointer', ':hover': { backgroundColor: '#DEEBFF' } })
                          }}
                          options={[
                            { value: 0, label: t('package.basic') },
                            {
                              value: 1,
                              // label: <Tooltip title="Coming Soon"><p>{t('package.fullText')}</p></Tooltip>,
                              label: building.length === 0 ? <Tooltip title={t('buildingValidation4')}><p>{t('package.fullText')}</p></Tooltip> : t('package.fullText'),
                              isDisabled: building.length === 0,
                            },
                          ]}
                        />
                      </div>
                      <div className="flex width-auto" style={{ alignItems: 'center' }}>
                        {/* <div
                          style={{ cursor: 'pointer', padding: '5px' }}
                          role="presentation"
                          onClick={loading ? {} : e => this.onSearchFieldClick(e)}
                        >
                          <Search
                            className="font-size-18 margin-right-5"
                          />
                        </div> */}

                        {/* <DateRangePicker
                          t={t}
                          match={match}
                          range={range}
                          disabled={loading}
                          getDates={(start, end) => this.setDates(start, end)}
                          clearFilter={this.clearFilter}
                          applyFilter={this.applyFilter}
                          hideButton={range}
                        /> */}

                        {/* <AdvancedPackagesSearch
                          {...this.state}
                          t={t}
                          statusFilter={this.state.statusOption}
                          disableRanges={disableRanges}
                          match={match}
                          style={style}
                          loading={loading}
                          building={building}
                          buildings={buildings}
                          filter={this.applySearch}
                          dynamicLabels={dynamicLabels[0]}
                        /> */}
                      </div>
                    </div>
                    {/* <IconButton onClick={this.columnSwitcher}>
                      <span
                        className="fa fa-cog"
                        style={
                          columnSwitcher
                            ? {
                              ...style.common.fs_14,
                              ...style.colors.blue,
                              fontSize: 20,
                            }
                            : {
                              ...style.common.fs_14,
                              fontSize: 20,
                            }
                        }
                      />
                    </IconButton> */}
                  </div>
                </Grid>
              </Grid>
              {
                filter.value === 0 && advancedSearchTags.length !== 0 &&
                <div style={{ display: 'flex', width: '100%', padding: '10 10 10 0', marginBottom: 10, alignItems: 'baseline' }}>
                  <p style={{ marginLeft: 10 }}> {t('package.activeFilter')}: </p>
                  {
                    sampleTagsData.map(item => {
                      const elem = (
                        <p
                          style={{
                            marginLeft: 10,
                            padding: '1px 5px 1px 5px',
                            height: 25,
                            borderRadius: 5,
                            backgroundColor: '#9cefaf70',
                            color: 'rgb(40, 132, 61)',
                            fontSize: 14
                          }}> {t(item.label)} </p>
                      );
                      return elem;
                    })
                  }
                </div>
              }
              <Divider />
              <Grid className="container" md={12} lg={12}>
                {!grid ? (
                  <MaterialUITable
                    loading={loading}
                    t={t}
                    view={columnSwitcher ? 'filter' : 'list'}
                    tableHeads={tableHeads}
                    tableData={packages}
                    handleSwitchChange={this.handleSwitchChange}
                    handleSortByColumn={this.sortData}
                    tableRows={this.tableRows}
                    sortType={sortType}
                    sortBy={sortBy}
                    message={tableMessage}
                    error={error}
                    setSelected={this.setSelected}
                    selected={selectedPakgIds}
                    packageData={packagesData}
                    buildingFilterFlag={buildingFilterFlag}
                    isVoTable
                  />
                ) : (
                  <Gallery
                    {...this.props}
                    loading={loading}
                    error={error}
                    display={grid}
                    getData={this.getData}
                    handleClose={this.handleClose}
                    images={packages.map((pkg, index) => ({
                      thumbnail: packagesData[index].package_image,
                      package_id: pkg.package_id,
                      name: pkg.member_name,
                      scanType: pkg.activity_type,
                    }))}
                    dynamicLabels={dynamicLabels}
                    packages={this.state.packagesData}
                  />
                )}
                {!loading && false && (
                  <AlignCenter>
                    <VerticallyCenter style={{ color: '#646777' }}>
                      {applySearch
                        ? firstSearchError.length > 0
                          ? firstSearchError
                          : t('tables.noMorePackages')
                        : t('tables.noMorePackages')}
                    </VerticallyCenter>
                  </AlignCenter>
                )}
                {!error &&
                  totalPackages &&
                  totalPackages !== '---' &&
                  packages.length > 0 ? (
                  <div className="table-pagination">
                    <PaginationComponent
                      width={width}
                      loading={loading}
                      totalItems={totalPackages}
                      activePage={activePage}
                      itemsPerPage={this.state.limit}
                      availableItems={packages.length}
                      onChange={this.onChangePage}
                      showLimit
                    />
                  </div>
                ) : (
                  <div />
                )}
              </Grid>
            </Paper>
          </Grid>
        </div>
        <Footer />
      </Fragment >
    );
  }
}

Packages.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  match: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
  buildings: PropTypes.node.isRequired,
  generalPackageSearch: PropTypes.func.isRequired,
  TextPackageSearch: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  limitPerPage: PropTypes.number.isRequired,
  removeDateFilter: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  limitPerPage: state.limitPerPage,
  building: state.locationFilter,
  pagination: state.pagination,
  buildings: state.allBuildings,
  userRole: state.userRole,
  dynamicLabels: state.dynamicLabels,
  tableExportStatus: state.tableExportStatus,
  dateFilter: state.dateFilter
});

export default withMobileDialog()(
  withWidth()(
    connect(mapStateToProps)(withRouter(withStyles(styles)(Packages))),
  ),
);
