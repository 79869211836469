/* eslint-disable quotes */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Menu,
  Paper,
  MenuItem,
  withWidth,
  IconButton,
  // Button as MUIButton,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Filter from './Filter';
import LineChart from './LineChart';
import { AlignCenter, VerticallyCenter } from '../../../UI/ResponsiveRelated';
import { busySlotsOptions, scanDetailsDropdownOptions } from '../../../../util/application.utils';
// import LineChartBusySlots from './LineChartBusySlots';

class ScanDetailsGraph extends PureComponent {
  constructor(props) {
    super(props);
    const { t, chartFilter } = props;
    this.state = {
      busySlotsAnchor: null,
      selectedOptionBusySlots: t('main.select'),
      selectedOptionBusySlotsIndex: 0,
      scanDetailsOptionAnchor: null,
      selectedOptionScanDetails: t(scanDetailsDropdownOptions[chartFilter]),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.building !== this.props.building) {
      this.setState({
        selectedOptionBusySlots: this.props.t('main.select'),
        selectedOptionBusySlotsIndex: 0,
        selectedOptionScanDetails: this.props.t(scanDetailsDropdownOptions[this.props.chartFilter]),
      });
    }
    if (prevProps.lng !== this.props.lng) {
      this.setState({
        selectedOptionScanDetails: this.props.t(scanDetailsDropdownOptions[this.props.chartFilter]),
        selectedOptionBusySlots: this.state.selectedOptionBusySlotsIndex === 0 ? this.props.t('main.select') : this.props.t(busySlotsOptions[this.state.selectedOptionBusySlotsIndex].label),
      });
    }
  }

  toggleBusySlotsAnchor = event => {
    this.setState({
      busySlotsAnchor: event.currentTarget
    });
  }

  handleCloseBusySlots = () => {
    this.setState({
      busySlotsAnchor: null
    });
  }

  togglescanDetailsOptionsAnchor = event => {
    this.setState({
      scanDetailsOptionAnchor: event.currentTarget
    });
  }

  handleCloseScanDetailsOptions = () => {
    this.setState({
      scanDetailsOptionAnchor: null
    });
  }

  handleSelectedOptionBusySlots = (value) => {
    const { t } = this.props;
    this.setState({
      busySlotsAnchor: null,
      selectedOptionBusySlots: value === 'none'
        ? t('main.select')
        : value === 'hourly'
          ? t('main.hourly')
          : t('package.weekly'),
      selectedOptionBusySlotsIndex: value === 'none'
        ? 0
        : value === 'hourly'
          ? 1
          : 2,
    }, () => {
      this.props.handleSelectedOptionBusySlots(value);
    });
  }

  handleSelectedOptionScanDetails = (value) => {
    const { t, changeFilter } = this.props;
    this.setState({
      scanDetailsOptionAnchor: null,
      selectedOptionScanDetails: t(scanDetailsDropdownOptions[value]),
      selectedOptionBusySlots: t('main.select'),
    }, () => {
      changeFilter(value);
    });
    // this.setState({
    //   busySlotsAnchor: null,
    //   selectedOptionBusySlots: value === 'none'
    //     ? 'Select'
    //     : value === 'hourly'
    //       ? 'Hourly'
    //       : 'Weekly',
    // }, () => {
    //   this.props.handleSelectedOptionBusySlots(value);
    // });
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    const {
      t,
      style,
      width,
      charts,
      loading,
      error,
      scanDetailsGraph,
      changeFilter,
      chartFilter,
      sidebar,
      busySlotsData,
      // fetchBusySlotsData,
    } = this.props;
    const {
      busySlotsAnchor,
      selectedOptionBusySlots,
      scanDetailsOptionAnchor,
      selectedOptionScanDetails,
      selectedOptionBusySlotsIndex
    } = this.state;

    return (
      <Paper
        style={{
          padding: 17,
          height: width === 'xs' && !charts ? 350 : 220
        }}
        className="just-paper"
      >
        <div
          style={{
            ...style.flex.justify_between,
            ...style.flex.display,
            ...style.flex.align_center,
          }}
        >
          <div className="truncated-heading" style={style.common.subheading}>
            {t('package.scanDetails')}
            {
              (!loading && this.props.chartFilter > 0) &&
              <React.Fragment>
                <div
                  role="presentation"
                  onClick={this.toggleBusySlotsAnchor}
                  style={{ display: 'flex', marginLeft: 10, marginTop: 2 }}
                >
                  <p
                    style={{
                      cursor: 'pointer',
                      fontSize: 16,
                      color: 'rgb(117, 117, 117)',
                      fontWeight: 'bold'
                    }}
                  >
                    {selectedOptionBusySlots}
                  </p>
                  <IconButton style={{ padding: 2 }}>
                    <ArrowDropDownIcon />
                  </IconButton>
                  {/* <ArrowDropDownIcon style={{ cursor: 'pointer' }} /> */}
                </div>
                <Menu
                  anchorEl={busySlotsAnchor}
                  open={Boolean(busySlotsAnchor)}
                  onClose={this.handleCloseBusySlots}
                >
                  {
                    busySlotsOptions.slice(0, (this.props.chartFilter + 1)).map((eachOption, index) => (
                      <MenuItem
                        value={eachOption.value}
                        onClick={() => this.handleSelectedOptionBusySlots(eachOption.value)}
                        style={style.common.capitalize}
                        selected={index === selectedOptionBusySlotsIndex}
                      >
                        {t(eachOption.label)}
                      </MenuItem>
                    ))
                  }
                </Menu>
              </React.Fragment>
            }
          </div>
          {
            !loading &&
            <React.Fragment>
              <div
                role="presentation"
                onClick={this.togglescanDetailsOptionsAnchor}
                style={{ display: 'flex', marginLeft: 10, marginTop: 2 }}
              >
                <p
                  style={{
                    cursor: 'pointer',
                    fontSize: 16,
                    color: 'rgb(117, 117, 117)',
                    fontWeight: 'bold'
                  }}
                >
                  {selectedOptionScanDetails}
                </p>
                <IconButton style={{ padding: 2 }}>
                  <ArrowDropDownIcon />
                </IconButton>
              </div>
              <Menu
                anchorEl={scanDetailsOptionAnchor}
                open={Boolean(scanDetailsOptionAnchor)}
                onClose={this.handleCloseScanDetailsOptions}
              >
                {
                  scanDetailsDropdownOptions.map((eachOption, index) => (
                    <MenuItem
                      onClick={() => this.handleSelectedOptionScanDetails(index)}
                      style={style.common.capitalize}
                      selected={index === this.props.chartFilter}

                    >
                      {t(eachOption)}
                    </MenuItem>
                  ))
                }
              </Menu>
            </React.Fragment>
          }
          {loading && false && (
            <Filter
              disabled={loading || error}
              default={chartFilter}
              list={[
                t('main.filterHourly'),
                t('main.filterDaily'),
                t('main.filterWeekly'),
                t('main.filterMonthly')
              ]}
              onChange={changeFilter}
              {...this.props}
            />
          )}
        </div>
        {error && (
          <VerticallyCenter>
            <AlignCenter>{error}</AlignCenter>
          </VerticallyCenter>
        )}
        {
          charts &&
          <LineChart
            t={t}
            style={style}
            width={width}
            sidebar={sidebar}
            scanDetailLoading={loading}
            scanDetails={scanDetailsGraph}
            scanDetailError={error}
            chartFilter={chartFilter}
            data={selectedOptionBusySlots === t('main.select') ? scanDetailsGraph : busySlotsData}
          />
        }
      </Paper>
    );
  }
}

ScanDetailsGraph.propTypes = {
  sidebar: PropTypes.node.isRequired,
  style: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
};

export default connect(state => ({
  sidebar: state.sidebar,
}))(withWidth()(ScanDetailsGraph));
