/* eslint-disable curly */
/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from 'react-router-dom';
import {
  Dialog, CircularProgress,
  Grid, Divider, IconButton, TableCell, InputBase, InputAdornment, Paper, Button,
} from '@material-ui/core';
import { Cancel, Search, Settings } from '@material-ui/icons';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Alert from '../../../UI/Poper';

import {
  SpaceBetween,
  Margin
} from '../../../UI/ResponsiveRelated';
import MaterialUITable from '../../../../shared/components/MaterialUITable/components/materialUITableComponent';
import { setTableColumn } from '../../../../util/application.utils';
import { createRows } from '../../../../util/createRows';
import PaginationComponent from '../../../../shared/components/PaginationWithNumbers/components/paginationComponent';
import { setPagination } from '../../../../redux/actions/paginationAction';
import { setDynamicPagination } from '../../../../redux/actions/dynamicPaginationActions';
import { getAssociates, searchAssociate, deleteUserAssociate } from '../../../../server'; // deleteUserAssociate
import { setTableColumnFilter } from '../../../../redux/actions/tableColumnActions';
import { sortByAssociate } from '../../../../util/sortBy';
import { validateSearchCharacter } from '../../../../util';

import '../styles/associates.style.css';

class AssociatesTable extends Component {
  constructor(props) {
    super(props);
    const {
      t
    } = props;
    const { module } = props.match.params;
    this.state = {
      limit: props.limitPerPage.associatesLimit,
      startFilter: module && module.includes('mailroom_associates'),
      loading: true,
      pageOfItems: [],
      associates: [],
      associatesAllRecord: [],
      error: null,
      associateId: null,
      changing: false,
      paginationToken: '',
      searchedText: '',
      view: 'list',
      sortBy: 'name',
      sortType: true,
      tableHeads: setTableColumn(t, props.tableColumn.associates),
      activePage: 1,
      oneTableColumn: '',
      openConfirmation: false,
      selectedAssociateNameToDelete: '',
      selectedAssociateIdToDelete: null,
      successDeletingAssociate: false,
      errorDeletingAssociate: false,
      deleteMemberLoader: false,
      openAlertError: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    this.getUsers(this.props);
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidUpdate(prevProps) {
    const {
      t
    } = this.props;
    if (this.props.width !== prevProps.width) {
      return null;
    } else if (prevProps.building !== this.props.building) {
      this.onChangePage(1);
    } else if (
      this.props.buildings.length > 0 &&
      prevProps.userRole !== this.props.userRole &&
      !this.state.loading
    ) {
      this.getUsers(this.props);
    }
    // if (this.props.match !== prevProps.match) {
    //   this.getUsers(this.props);
    // }
    if (prevProps.lng !== this.props.lng) {
      // const { tableHeads } = this.state;
      let tableHeads = setTableColumn(t, prevProps.tableColumn.associates);
      this.setState({ tableHeads });
    }
  }

  onChangePage = (currentActionPage, limit) => {
    let action;
    if (currentActionPage === 'next') {
      action = this.state.activePage + 1;
    } else if (currentActionPage === 'previous') {
      action = this.state.activePage - 1;
    } else {
      action = currentActionPage;
      if (limit) {
        let tempLimits = {};
        if (localStorage.px_dynamic_pagination) {
          tempLimits = JSON.parse(localStorage.px_dynamic_pagination);
          tempLimits.associatesLimit = limit;
        } else {
          tempLimits = {
            membersLimit: this.props.limitPerPage.membersLimit,
            packagesLimit: this.props.limitPerPage.packagesLimit,
            businessesLimit: this.props.limitPerPage.businessesLimit,
            associatesLimit: limit,
            buildingsLimit: this.props.limitPerPage.buildingsLimit,
          };
        }
        this.props.dispatch(setDynamicPagination(tempLimits));
        const currentPage = Math.ceil(this.state.associates[0].associatesCount / this.state.limit);
        if (this.state.activePage === currentPage) {
          action = Math.ceil(this.state.associates[0].associatesCount / limit);
        }
      }
    }
    this.props.dispatch(setPagination({ route: 'associates', offset: (action - 1) * (limit || this.state.limit) }));
    this.setState({
      limit: limit || this.state.limit,
      loading: true,
      activePage: action,
      error: null,
    }, () => this.getUsers(this.props));
  };

  getUsers = (props, prevProps) => {
    const { sortBy, sortType, searchedText, activePage } = this.state;
    const {
      match,
      userRole,
      buildings,
      pagination,
      t,
      building,
      dispatch,
    } = props || this.props;
    const { module, id } = match.params;
    this.setState({
      startFilter: module && module.includes('mailroom_associates'),
      loading: true,
      error: null,
    });
    if (userRole && buildings.length > 0) {
      let buildingId = null;
      let mailroomId = null;
      const routes = pagination.map(pg => pg.route);
      const routeIndex = routes.indexOf('associates');
      let offset = routeIndex > -1 ? pagination[routeIndex].offset : null;
      if (userRole === 'mailroom_supervisor')
        mailroomId = buildings[0].mailrooms[0].mailroom_id;
      else if (module === 'mailroom_associates') {
        mailroomId = id;
        buildingId = null;
      } else if (building.length > 0) buildingId = building[0].building_id;
      this.setState({ activePage: Math.floor(offset / this.state.limit) + 1, loading: true });
      if (searchedText.length > 0) {
        // if (prevProps.searchedText !== this.props.searchedText) {
        //   dispatch(setPagination({ route: 'associates', offset: 0 }));
        // }
        searchAssociate(
          searchedText,
          buildingId,
          this.state.limit,
          (activePage - 1) * (this.state.limit),
          sortBy,
          sortType,
          mailroomId,
        )
          .then(associates => {
            if (prevProps && prevProps.building !== props.building) {
              offset = 0;
              dispatch(setPagination({ route: 'associates', offset: 0 }));
            }
            this.setState({
              associates: createRows(associates.Users, this.props, 'associates'),
              associatesAllRecord: associates.Users,
              loading: false,
            });
          })
          .catch(err => {
            let errorMessage =
              err.message.indexOf('status code') > -1
                ? t('common.error.networkErrorMessage')
                : t('common.error.networkError');
            this.setState({
              error: errorMessage,
              loading: false,
            });
          });
      } else {
        getAssociates(this.state.limit, offset, sortBy, sortType, buildingId, mailroomId)
          .then(associates => {
            if (associates.length === 0 && this.state.activePage > 1) {
              this.onChangePage(1);
            } else {
              this.setState({
                loading: false,
                associates: createRows(associates, this.props, 'associates'),
                associatesAllRecord: associates,
              });
            }
          }).catch(() =>
            this.setState({
              error: t('common.error.networkError'),
              loading: false,
            }),
          );
      }
    }
  };

  getDeleteColumn = deleteAssociate => (this.props.userRole === 'mailroom_supervisor' ? (
    <p style={{ padding: 8 }} > --- </p>
  ) : this.props.userRole === 'building_manager' && deleteAssociate.role === 'mailroom_supervisor' ? (
    <IconButton
      style={{ padding: 14 }}
      onClick={() => {
        this.setState({ openConfirmation: true, selectedAssociateNameToDelete: deleteAssociate.name, selectedAssociateIdToDelete: deleteAssociate.id });
      }}
    >
      <DeleteForeverIcon className="bin-btn-delete-associates fs-16px" />
    </IconButton>
  ) : this.props.userRole === 'admin' && (deleteAssociate.role === 'mailroom_supervisor' || deleteAssociate.role === 'building_manager' || (deleteAssociate.role === 'admin' && deleteAssociate.id !== this.props.userDetail.Username)) ? (
    <IconButton
      style={{ padding: 14 }}
      onClick={() => {
        this.setState({ openConfirmation: true, selectedAssociateNameToDelete: deleteAssociate.name, selectedAssociateIdToDelete: deleteAssociate.id });
      }}
    >
      <DeleteForeverIcon className="bin-btn-delete-associates fs-16px" />
    </IconButton>
  ) : this.props.userRole === 'super_admin' && (deleteAssociate.role === 'mailroom_supervisor' || deleteAssociate.role === 'building_manager' || deleteAssociate.role === 'admin') ? (
    <IconButton
      style={{ padding: 14 }}
      onClick={() => {
        this.setState({ openConfirmation: true, selectedAssociateNameToDelete: deleteAssociate.name, selectedAssociateIdToDelete: deleteAssociate.id });
      }}
    >
      <DeleteForeverIcon className="bin-btn-delete-associates fs-16px" />
    </IconButton>
  ) : <p style={{ padding: 8, paddingLeft: 10 }} > --- </p>);

  deleteAssociate = () => {
    const { associatesAllRecord, selectedAssociateIdToDelete, successDeletingAssociate, deleteMemberLoader } = this.state;
    if (successDeletingAssociate || deleteMemberLoader) return;
    const recordToDelete = associatesAllRecord.filter(obj => {
      if (obj.Username === selectedAssociateIdToDelete) {
        return obj;
      } else return null;
    });
    if (recordToDelete.length === 0) return;
    this.setState({ deleteMemberLoader: true }, () => {
      deleteUserAssociate(selectedAssociateIdToDelete, recordToDelete[0].role_data.role.tenant_id).then(() => {
        this.setState({
          associatesAllRecord: [],
          selectedAssociateIdToDelete: null,
          // selectedAssociateNameToDelete: '',
          // openConfirmation: false,
          openConfirmation: false,
          successDeletingAssociate: true,
          errorDeletingAssociate: false,
          loading: false,
          deleteMemberLoader: false
        });
      })
        .catch(() => {
          this.setState({
            errorDeletingAssociate: true,
            successDeletingAssociate: false,
            deleteMemberLoader: false,
            openConfirmation: false
          });
        });
    });
  }

  closeAlert = () => {
    this.setState({
      successDeletingAssociate: false,
      errorDeletingAssociate: false,
      openConfirmation: false,
    });
  }
  updateCloseAlert = () => {
    this.closeAlert();
    this.getUsers(this.props);
  }

  closepopUp = () => {
    this.setState({ openConfirmation: false, selectedAssociateNameToDelete: '', selectedAssociateIdToDelete: null, errorDeletingAssociate: false, successDeletingAssociate: false });
  }

  filterView = (name, saveTableColumn) => {
    this.setState({ [name]: this.state.view === 'list' ? 'filter' : 'list' });
    if (saveTableColumn) {
      const { tableHeads } = this.state;
      const originalColumn = this.props.tableColumn.associates;
      const updatedAssociatesArray = tableHeads.map((item, index) => {
        let tempColumn = originalColumn[index];
        tempColumn.expanded = item.expanded;
        return tempColumn;
      });
      const tempTableColumn = {
        associates: updatedAssociatesArray,
        buildings: this.props.tableColumn.buildings,
        bussiness: this.props.tableColumn.bussiness,
        members: this.props.tableColumn.members,
        packages: this.props.tableColumn.packages,
        voMembers: this.props.tableColumn.voMembers && this.props.tableColumn.voMembers,
        voBuilding: this.props.tableColumn.voBuilding && this.props.tableColumn.voBuilding,
      };
      this.props.dispatch(setTableColumnFilter(tempTableColumn));
    }
  }

  tableRows = item => {
    const { tableHeads } = this.state;
    let tableRowCells = [];
    if (tableHeads[0] && tableHeads[0].expanded) {
      tableRowCells.push(
        <TableCell
          className="thumbnail-cell width-60px"
          style={{ cursor: 'default' }}
        >
          {' '}
          {item.associate_thumbnail}{' '}
        </TableCell>,
      );
    }
    if (tableHeads[1] && tableHeads[1].expanded) {
      tableRowCells.push(
        <TableCell className="padding-right-left truncated-heading width-200px">
          {' '}
          <span> {item.name} </span>{' '}
        </TableCell>,
      );
    }
    if (tableHeads[2] && tableHeads[2].expanded) {
      tableRowCells.push(
        <TableCell className="padding-right-left truncated-heading width-200px">
          {' '}
          {item.email}{' '}
        </TableCell>,
      );
    }
    if (tableHeads[3] && tableHeads[3].expanded) {
      tableRowCells.push(
        <TableCell className="padding-right-left width-200px overflow-hidden">
          {' '}
          {item.location}{' '}
        </TableCell>,
      );
    }
    if (tableHeads[4] && tableHeads[4].expanded) {
      tableRowCells.push(
        <TableCell className="padding-right-left truncated-heading width-200px">
          {' '}
          {item.role}{' '}
        </TableCell>,
      );
    }
    if (tableHeads[5] && tableHeads[5].expanded) {
      tableRowCells.push(
        <TableCell className="padding-right-left truncated-heading width-200px">
          {' '}
          {item.status}{' '}
        </TableCell>,
      );
    }
    if (tableHeads[6] && tableHeads[6].expanded) {
      tableRowCells.push(
        <TableCell className="padding-right-left truncated-heading width-200px">
          {' '}
          {this.getDeleteColumn(item.deleteAssociate)}
          {' '}
        </TableCell>,
      );
    }

    return tableRowCells;
  };

  handleSwitchChange = (index) => {
    if (this.state.tableHeads[index].expanded) {
      const totalActive = [...this.state.tableHeads].filter(
        item => item.expanded === true,
      ).length;
      if (totalActive > 1) {
        const tempArray = [...this.state.tableHeads];
        tempArray[index].expanded = !tempArray[index].expanded;
        this.setState({
          tableHeads: [...tempArray],
        });
      } else {
        this.setState({
          oneTableColumn: this.props.t('common.error.atleastOneActiveColumn'),
        });
      }
    } else {
      const tempArray = [...this.state.tableHeads];
      tempArray[index].expanded = !tempArray[index].expanded;
      this.setState({
        tableHeads: [...tempArray],
      });
    }
  };

  handleSortByColumn = (columnName) => {
    let { sortBy, sortType } = this.state;
    if (columnName === 'user_status') sortType = !sortType;
    else if (sortBy === sortByAssociate[columnName]) sortType = !sortType;
    else if (!sortByAssociate[columnName]) sortType = false;
    else sortType = true;
    this.setState({
      loading: true,
      sortBy: columnName === 'user_status' ? 'user_status' : sortByAssociate[columnName],
      sortType,
    }, () => this.getUsers());
  };

  handleStopSearching = () => {
    this.setState({
      startFilter: false,
      searchedText: '',
    }, () => this.getUsers(this.props));
  };

  handleSearchBarChange = event => {
    this.setState({
      searchedText: event.target.value.trimLeft(),
    }, () => {
      if (this.state.searchedText.length === 0) {
        this.onChangePage(1);
      }
    });
  };

  render() {
    const {
      loading,
      error,
      associateId,
      view,
      sortBy,
      sortType,
      tableHeads,
      associates,
      activePage,
      oneTableColumn,
      searchedText,
      errorDeletingAssociate,
      successDeletingAssociate,
      deleteMemberLoader,
    } = this.state;

    const { style, t, width } = this.props;
    const showingColumns = [...tableHeads].filter(
      item => item.expanded === true,
    );
    const activeColumns =
      showingColumns.length === tableHeads.length
        ? t('associates.showing')
        : `Showing ${showingColumns.length} of ${tableHeads.length} columns`;

    const tableMessage =
      associates.length > 0 ? '' : t('associates.noAssociates');
    const totalItems = associates.length > 0 && associates[0].associatesCount;
    if (associateId) {
      return <Redirect to={`/associate_detail/${associateId}`} />;
    }
    return (
      <Paper className="paper">
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="space-between-with-767-block align-items-center margin-0px padding-0-14px padding-0-15px">
            <div className="align-items-center font-size-14 black font-weight-bold width-100">
              {width !== 'xs' && <div className="font-size-14 black font-weight-bold">
                {t('tables.associates')}
              </div>}
              {this.state.startFilter &&
                <Button
                  to="/associates"
                  component={Link}
                  onClick={this.handleStopSearching}
                  className="fs-14px blue"
                >
                  {t('package.clearFilter')}
                </Button>}
            </div>
            <div className="topbar__search width-100 margin-0px">
              <form
                onSubmit={e => {
                  e.preventDefault();
                  if (!loading && searchedText.length !== 0) {
                    if (!validateSearchCharacter(searchedText)) {
                      this.setState({
                        oneTableColumn: this.props.t('common.error.notAllowSpecialCharacters'),
                      });
                    } else {
                      this.setState({ startFilter: true });
                      this.onChangePage(1);
                    }
                  }
                }}
                disabled={searchedText.length > 0}
                className="width-100"
              >
                <InputBase
                  aria-haspopup="true"
                  className="topbar__search-field topbar__search-field--open search margin-4px fs-14px"
                  inputProps={{ style: { cursor: 'pointer' }, mexlength: 100 }}
                  value={searchedText}
                  onChange={this.handleSearchBarChange}
                  placeholder={t('members.searchAssociate')}
                  startAdornment={<Search
                    style={{
                      marginRight: window.screen.width < 500 ? 0 : 10,
                      fontSize: 18,
                    }}
                  />}
                  endAdornment={
                    <InputAdornment position="end">
                      {searchedText.length > 0 ? (
                        <IconButton
                          style={{ padding: 14 }}
                          onClick={this.handleStopSearching}
                        >
                          <Cancel className="cross-btn fs-14px" />
                        </IconButton>
                      ) : (
                        <div />
                      )}
                    </InputAdornment>
                  }
                />
              </form>
            </div>
          </div>
          <Divider />
        </Grid>
        <Grid item md={12} lg={12} className="container" style={{ paddingBottom: -32 }}>
          {oneTableColumn && (
            <Alert
              variant="error"
              open
              message={oneTableColumn}
              onClose={() => this.setState({ oneTableColumn: '' })}
            />
          )}
          <SpaceBetween>
            <div className="justify-center">
              <p className="font-in-bold">{activeColumns}</p>
            </div>
            <IconButton
              variant
              disabled={loading}
              onClick={() => this.filterView('view')}
            >
              <Settings
                style={
                  view === 'filter'
                    ? {
                      ...style.common.fs_14,
                      ...style.colors.blue,
                      fontSize: 20,
                    }
                    : {
                      ...style.common.fs_14,
                      fontSize: 20,
                    }
                }
              />
            </IconButton>
          </SpaceBetween>
          <Divider />
          {
            <MaterialUITable
              t={t}
              loading={loading}
              view={view}
              tableHeads={tableHeads}
              tableData={associates}
              handleSwitchChange={this.handleSwitchChange}
              handleSortByColumn={this.handleSortByColumn}
              tableRows={this.tableRows}
              sortBy={sortBy}
              sortType={sortType}
              message={tableMessage}
              error={error}
              changeView={this.filterView}
              sortDataColumns={sortByAssociate}
            />
          }
          {!error && associates.length > 0 && view === 'list' && (
            <div className="table-pagination">
              <PaginationComponent
                width={width}
                loading={loading}
                totalItems={totalItems}
                activePage={activePage}
                itemsPerPage={this.state.limit}
                availableItems={associates.length}
                onChange={this.onChangePage}
                showLimit
              />
            </div>
          )}
        </Grid>
        <Dialog
          open={this.state.openConfirmation}
          onClose={this.closepopUp}
          disableBackdropClick={false}
          onBackdropClick={this.closepopUp}
        >
          {' '}
          <div
            style={{
              width:
                window.screen.width < 450 &&
                  window.screen.width > 365
                  ? 278
                  : window.screen.width < 365
                    ? 220
                    : 400,
              overflowX: 'hidden',
            }}
          >
            <Margin
              style={{
                padding: 20,
                paddingBottom: window.screen.width < 400 ? 10 : 0,
              }}
            >
              <p
                style={{
                  fontSize: window.screen.width < 400 ? 20 : 22,
                  fontWeight: 500,
                }}
              >
                {t('associates.deleteAssociate')}
              </p>
              <p
                style={{
                  fontSize: 14,
                  color: '#898b8a',
                }}
              >
                {/* {`${t('associates.confirmationOnDeleteAssociate')} ${this.state.selectedAssociateNameToDelete} ?`} */}
                <span>{t('associates.confirmationOnDeleteAssociate')}</span>
                <span style={{ color: '#ff1e59' }}>{` ${this.state.selectedAssociateNameToDelete}`}</span>
                <span>?</span>
              </p>
            </Margin>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: window.screen.width < 400 ? 10 : 20,
              }}
            >
              <Button
                // className={classes.button}
                style={{
                  width: window.screen.width < 400 ? 40 : 90,
                  height: 34,
                  margin: 2,
                }}
                onClick={this.closepopUp}
              >
                {t('associates.no')}
              </Button>
              <Button
                style={{
                  width: window.screen.width < 350 ? 40 : 90,
                  height: 33,
                  backgroundColor: '#027AFF',
                  color: 'white',
                  margin: 2,
                }}
                variant="contained"
                // className={classes.button}
                onClick={() => {
                  this.deleteAssociate();
                }}
              >
                {t('email.yes')} {deleteMemberLoader && <CircularProgress className="white" style={{ marginLeft: 5 }} size={15} />}
              </Button>
            </div>
          </div>
        </Dialog>
        {errorDeletingAssociate ? (
          <Alert
            variant="error"
            open
            message={t("associates.associateDeletedError")}
            onClose={this.closeAlert}
          />
        ) : ' '}
        {successDeletingAssociate ? (
          <Alert
            variant="success"
            open
            message={t("associates.associateDeletedSuccess")}
            onClose={this.updateCloseAlert}
          />
        ) : ' '}
      </Paper>
    );
  }
}

AssociatesTable.propTypes = {
  t: PropTypes.func.isRequired,
  lng: PropTypes.string.isRequired,
  style: PropTypes.node.isRequired,
  match: PropTypes.node.isRequired,
  buildings: PropTypes.node.isRequired,
  userRole: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  building: PropTypes.node.isRequired,
  limitPerPage: PropTypes.number.isRequired,
  tableColumn: PropTypes.func.isRequired,
  userDetail: PropTypes.node.isRequired
};

const mapStateToProps = (state) => ({
  tableColumn: state.tableColumn,
  limitPerPage: state.limitPerPage,
  userDetail: state.userDetails
  // userRole: state.userRole
});

export default withRouter(connect(mapStateToProps)(AssociatesTable));
