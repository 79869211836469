import { SAVE_SEARCH } from '../actions/searchAction';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_SEARCH: {
      state = action.search;
      return state;
    }
    default: return state;
  }
}
