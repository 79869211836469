import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Alert from '../../UI/Poper';
import WarningAlert from '../../../shared/components/Alert';
import AccountSettings from './components/accountSettings';
import DeleteAccount from './components/deleteAccount';
import { Margin } from '../../UI/ResponsiveRelated';
import Footer from '../../UI/Footer/Footer';

class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      updating: false,
      updated: false,
      errorUpdating: null,
      warning: null,
    };
  }

  // componentDidUpdate(prevProps) {
  //   if (!prevProps.location.pathname.includes('change_password') && this.props.location.pathname.includes('change_password')) {
  //     if (!prevProps.location.pathname.includes('settings')) {
  //         this.setState({ changePassRoute: true });
  //       } else {
  //         this.setState({ changePassRoute: false });
  //       }
  //   }
  // }

  handleChange = (name) => (event) => {
    this.resetWarning();
    this.setState({ [name]: event.target.value });
  };

  resetWarning = () => this.setState({ warning: null });

  closeAlert = () => {
    this.setState({ warning: null, errorUpdating: false });
    // this.setState({ open: false });
  };

  render() {
    const { updated, errorUpdating, warning } = this.state;
    const { t, userRole } = this.props;
    // const { changePassRoute } = this.props;
    return (
      <Fragment>
        <Grid container className="dashboard container height-auto">
          {errorUpdating && (
            <Alert
              variant="error"
              open
              message={errorUpdating}
              onClose={() => this.setState({ errorUpdating: false })}
            />
          )}
          {updated && (
            <Alert
              variant="success"
              open
              onClose={() => this.setState({ updated: false })}
              message={t('preferencesPortal.updated')}
            />
          )}
          <Grid item md={12} lg={12} xl={12}>
            <h3 className="page-title margin-bottom-10">
              {/* {changePassRoute
                ? t('changePassword.changePassword')
                : t('settings.accountSettings')} */}
              {t('main.accountSettings')}
            </h3>
            {warning && (
              <Margin right={20} left={20}>
                <WarningAlert
                  variant="error"
                  onClose={this.closeAlert}
                  message={warning}
                  open
                />
              </Margin>
            )}
          </Grid>
          <AccountSettings
            {...this.state}
            {...this.props}
            resetWarning={this.resetWarning}
          />
          {userRole === 'admin' && <DeleteAccount {...this.state} {...this.props} />}
        </Grid>
        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(connect(state => ({
    // buildings: state.allBuildings,
    userRole: state.userRole,
  }))(UserSettings));
