/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import moment from 'moment';
import {
  Divider,
  // IconButton,
  TableCell,
  Grid,
  // InputBase, InputAdornment, 
  Button,
} from "@material-ui/core";
// import GetAppIcon from '@material-ui/icons/GetApp';
import { translate } from 'react-i18next';
// import { Cancel, Search } from "@material-ui/icons";
import Alert from "../../../UI/Poper";
import PaginationComponent from "../../../../shared/components/PaginationWithNumbers/components/paginationComponent";
import MaterialUITable from "../../../../shared/components/MaterialUITable/components/materialUITableComponent";
import { setPagination } from "../../../../redux/actions/paginationAction";
import { setTableColumn, recentHistoryTableColumnHeader } from "../../../../util/application.utils";
import { truncateLabel } from "../../../../util/utility.functions";
import { setDynamicPagination } from '../../../../redux/actions/dynamicPaginationActions';
import { getUploadedFiles } from '../../../../server';
import ErrorDialog from './errorDialog';
import DummyData from './dummyData';
import csv_download from '../../../../assets/csv_download.svg';


class RecentHistorySection extends Component {
  constructor(props) {
    super(props);
    const {
      match,
      t
    } = props;
    this.state = {
      csvData: DummyData,
      limit: 12,
      match,
      loading: false,
      // members: [],
      totalPages: 0,
      activePage: 1,
      error: null,
      noMore: false,
      buildingId: null,
      view: "list",
      sortBy: "name",
      sortType: true,
      searchedText: '',
      tableHeads: setTableColumn(t, recentHistoryTableColumnHeader),
      oneTableColumn: "",
      buildingsData: [],
      uploadedFiles: [],
      selectedCsvError: null,
      openErrorDialog: false,
    };
    this.tableRows = this.tableRows.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleSortByColumn = this.handleSortByColumn.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentDidMount() {
    this.getFiles();
  }

  componentDidUpdate(prevProps) {
    const { refreshHistory } = prevProps;
    if ((refreshHistory !== this.props.refreshHistory && this.props.refreshHistory) || prevProps.building !== this.props.building) {
      this.setState({
        activePage: 1,
        uploadedFiles: []
      }, () => {
        this.getFiles();
        this.props.handleRefreshHistory(false);
      });
    }
  }

  static getDerivedStateFromProps() {
    return true;
  }

  onPageChange = (currentActionPage, limit) => {
    let action;
    if (currentActionPage === "next") {
      action = this.state.activePage + 1;
    } else if (currentActionPage === "previous") {
      action = this.state.activePage - 1;
    } else {
      action = currentActionPage;
      if (limit) {
        let tempLimits = {};
        if (localStorage.px_dynamic_pagination) {
          tempLimits = JSON.parse(localStorage.px_dynamic_pagination);
          tempLimits.buildingsLimit = limit;
        } else {
          tempLimits = {
            membersLimit: this.props.limitPerPage.membersLimit,
            packagesLimit: this.props.limitPerPage.packagesLimit,
            businessesLimit: this.props.limitPerPage.businessesLimit,
            associatesLimit: this.props.limitPerPage.associatesLimit,
            buildingsLimit: limit,
          };
        }
        this.props.dispatch(setDynamicPagination(tempLimits));
        const currentPage = Math.ceil(this.state.buildings[0].buildingsCount / this.state.limit);
        if (this.state.activePage === currentPage) {
          action = Math.ceil(this.state.buildings[0].buildingsCount / limit);
        }
      }
    }
    this.props.dispatch(setPagination({ route: 'csv', offset: (action - 1) * (limit || this.state.limit) }));
    this.setState({
      limit: limit || this.state.limit,
      loading: true,
      activePage: action,
      error: null,
      noMore: false,
    }, () => this.getFiles());
  };

  getFiles = () => {
    let { building, buildings, pagination } = this.props;
    const routes = pagination.map(pg => pg.route);
    const routeIndex = routes.indexOf("csv");
    const offset = routeIndex > -1 ? pagination[routeIndex].offset : 0;
    this.setState({ activePage: Math.floor(offset / this.state.limit) + 1, loading: true, error: null });
    const buildId = building.length > 0 ? building[0].building_id : buildings[0].building_id;
    // eslint-disable-next-line
    buildId ? (
      getUploadedFiles(
        buildId,
        this.state.limit,
        offset,
      )
        .then(response => {
          let tempFile = [];
          response.forEach(file => {
            const obj = {
              updated_by: file.uploaded_by,
              time: moment(file.updated_at).format('MMMM Do, YYYY @ h:mm a'),
              status: file.status,
              link: `${file.base_path}/${file.file_name}`,
              errorLog: file.log_json,
              count: file.log_count,
              totalrowsCount: (file.log_json && file.log_json.total_data_length && file.log_json.success_data_length) ? `${file.log_json.success_data_length}/${file.log_json.total_data_length - 1}` : (file.log_json && file.log_json.total_data_length && !file.error_data_length) ? `${file.log_json.total_data_length - 1}/${file.log_json.total_data_length - 1}` : '',
              type: file.type,
              overWrite: file.action ? file.action === 'over_write' ? 'Overwrite' : 'Merge' : 'Overwrite'
            };
            let check = true;
            if (file.log_json && file.log_json.total_data_length && file.log_json.success_data_length === 0) {
              check = false;
            }
            if (check) tempFile.push(obj);
            if (file.status === 'SUCCESS' && file.log_json) {
              if (file.log_json.errors) {
                tempFile.push({
                  updated_by: file.uploaded_by,
                  time: moment(file.updated_at).format('MMMM Do, YYYY @ h:mm a'),
                  status: 'Failure',
                  link: `${file.base_path}/${file.log_json.errors.error_file}`,
                  errorLog: file.log_json,
                  count: file.log_count,
                  totalrowsCount: (file.log_json && file.log_json.total_data_length && file.log_json.error_data_length) ? `${file.log_json.error_data_length}/${file.log_json.total_data_length - 1}` : '',
                  type: file.type,
                  overWrite: file.action ? file.action === 'over_write' ? 'Overwrite' : 'Merge' : 'Overwrite'
                });
              }
            }
          });

          // const tempFile = response.map(file => (
          //   {
          //     updated_by: file.uploaded_by,
          //     time: moment(file.updated_at).format('MMMM Do, YYYY @ h:mm a'),
          //     status: file.status,
          //     link: `${file.base_path}/${file.file_name}`,
          //     errorLog: file.log_json,
          //     count: file.log_count,
          //     type: file.type
          //   }
          // ));
          if (response.length === 0 && this.state.activePage > 1) {
            this.onPageChange(1);
          }
          // this.setState({ uploadedFiles: tempFile.length ? [tempFile[0]] : tempFile, loading: false, });
          this.setState({ uploadedFiles: tempFile, loading: false, });
        })
        .catch(err => {
          console.log(err);
          // let errorMessage =
          //   err.message.indexOf("status code") > -1
          //     ? t("common.error.networkErrorMessage")
          //     : t("common.error.networkError");
          this.setState({
            // uploadedFiles:[],
            // error: errorMessage,
            loading: false,
            uploadedFiles: []
          });
        })
    ) : (
      this.setState({
        loading: false,
        uploadedFiles: []
      })
    );
  };

  handleSearchBarChange = event => {
    this.setState({
      searchedText: event.target.value.trimLeft(),
    }, () => {
      if (this.state.searchedText.length === 0) {
        this.handleClearSearchBar();
      }
    });
  };

  createRows = csvData =>
    csvData.map(data => ({
      uploadedBy: (
        <p> {truncateLabel(data.updated_by)} </p>
      ),
      id: data.id || "---",
      time:
        (<p> { `${moment(data.time).format('MMM D, YYYY')} @
        ${moment(data.time).format('hh:mm A')}`} </p>),
      type: <p>{data.overWrite}</p>,
      status: data.status === 'Success' ? (
        <p style={{ color: 'green' }}> {data.status} </p>
      ) : (<p style={{ color: 'red' }}> {data.status} </p>),
      download: (
        <Link to={data.link}>
          Download CSV
        </Link>
      ),
    }));

  tableRows(item) {
    const { tableHeads } = this.state;
    let tableRowCells = [];
    if (tableHeads[0] && tableHeads[0].expanded) {
      tableRowCells.push(<TableCell className="padding-right-left truncated-heading width-200px">
        {" "}
        <span className="recent-history-uploaded-by"> {item.updated_by} </span>{" "}
      </TableCell>);
    }
    if (tableHeads[1] && tableHeads[1].expanded) {
      tableRowCells.push(<TableCell className="padding-right-left truncated-heading width-200px">
        {" "}
        <span className="recent-history-time">{item.time}</span>{" "}
      </TableCell>);
    }
    if (tableHeads[2] && tableHeads[2].expanded) {
      tableRowCells.push(<TableCell className="padding-right-left truncated-heading width-200px">
        {" "}
        <span className="recent-history-time">{item.overWrite}</span>{" "}
      </TableCell>);
    }
    if (tableHeads[3] && tableHeads[3].expanded) {
      tableRowCells.push(<TableCell className="padding-right-left truncated-heading width-200px recent-history-status">
        {" "}
        {item.status === 'SUCCESS' ? (
          <div style={{ color: '#02B67C' }}> {item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()} {item.totalrowsCount ? `(${item.totalrowsCount})` : ''} </div>
        ) : item.status === 'PENDING' ? (<div style={{ color: '#ffb02f' }}> In Progress {item.totalrowsCount ? `(${item.totalrowsCount})` : ''}</div>
        ) : item.status === 'Failure' ? (<div style={{ color: '#FF1E59' }}>Failure {item.totalrowsCount ? `(${item.totalrowsCount})` : ''}</div>) : (<div style={{ color: '#FF1E59' }}> <button className="csv-pending-status-error-check" onClick={() => this.openErrorDialog(item.errorLog, true)} >View Errors</button> </div>)
        }
      </TableCell>);
    }
    if (tableHeads[4] && tableHeads[4].expanded) {
      tableRowCells.push(<TableCell className="padding-right-left truncated-heading width-200px recent-history-download">
        {" "}
        <a href={item.link} style={{ display: 'flex' }}>
          <img src={csv_download} alt="download" />
        </a>
      </TableCell>);
    }
    return tableRowCells;
  }

  openErrorDialog = (errorLog, open) => this.setState({ selectedCsvError: errorLog, openErrorDialog: open });

  closeErrorDialog = () => this.setState({ openErrorDialog: false });

  handleSwitchChange(index) {
    if (this.state.tableHeads[index].expanded) {
      const totalActive = [...this.state.tableHeads].filter(item => item.expanded === true).length;
      if (totalActive > 1) {
        const tempArray = [...this.state.tableHeads];
        tempArray[index].expanded = !tempArray[index].expanded;
        this.setState({
          tableHeads: [...tempArray],
        });
      } else {
        this.setState({
          oneTableColumn: this.props.t("common.error.atleastOneActiveColumn"),
        });
      }
    } else {
      const tempArray = [...this.state.tableHeads];
      tempArray[index].expanded = !tempArray[index].expanded;
      this.setState({
        tableHeads: [...tempArray],
      });
    }
  }

  handleSearchBarSubmit = () => {
    let { tableHeads } = this.state;
    tableHeads[2].value = '_';
    this.setState({
      tableHeads
    }, () => this.onPageChange(1));
  }

  handleSortByColumn(columnName) {
    let { sortBy, sortType } = this.state;

    if (sortBy === columnName) {
      sortType = !sortType;
    } else {
      sortType = true;
    }

    this.setState({
      loading: true,
      sortBy: columnName,
      sortType,
    }, () => this.getBuildings());
  }

  render() {
    const {
      loading,
      error,
      view,
      tableHeads,
      sortBy,
      sortType,
      activePage,
      oneTableColumn,
      // searchedText,
      uploadedFiles,
      selectedCsvError,
      openErrorDialog
    } = this.state;
    const { t, width } = this.props;
    return (
      <>
        <div className="space-between-with-767-block align-items-center margin-0px">
          <div className="align-items-center font-size-14 black font-weight-bold width-100">
            {/* <h3 className="recent-history-heading">Member Snapshot</h3> */}
            <h3 className="recent-history-heading">{t('members.recentHistory')}</h3>
            {this.state.match.params.id === 'country' && <Button
              to="/buildings"
              component={Link}
              onClick={() => this.props.changeRoute({ params: {} })}
              className="fs-14px blue"
            >
              {t('package.clearFilter')}
            </Button>}
          </div>
          {/* <div className="topbar__search width-100 margin-bottom-20">
            <form
              onSubmit={e => {
                e.preventDefault();
                if (!loading && searchedText.length !== 0) {
                  this.handleSearchBarSubmit();
                }
              }}
              className="width-100"
            >
              <InputBase
                aria-haspopup="true"
                className="topbar__search-field topbar__search-field--open search margin-4px fs-14px"
                inputProps={{ style: { cursor: 'pointer', textOverflow: 'ellipsis' }, maxlength: 100 }}
                value={searchedText}
                onChange={this.handleSearchBarChange}
                placeholder="Search CSV"
                startAdornment={
                  <Search
                    style={{
                      marginRight: window.screen.width < 500 ? 0 : 10,
                      fontSize: 18,
                    }}
                  />
                }
                endAdornment={
                  <InputAdornment position="end">
                    {this.state.searchedText.length > 0 ? (
                      <IconButton
                        style={{ padding: 14 }}
                        onClick={this.handleClearSearchBar}
                      >
                        <Cancel className="cross-btn" />
                      </IconButton>
                    ) : (
                        <div />
                      )}
                  </InputAdornment>
                }
              />
            </form>
         
          </div>
         */}
        </div>
        <Grid item className="container padding-15-0" xs={12} sm={12} md={12} lg={12} xl={12}>
          {oneTableColumn && (
            <Alert
              variant="error"
              open
              message={oneTableColumn}
              onClose={() => this.setState({ oneTableColumn: "" })}
            />
          )}
          <Divider />
          {
            <MaterialUITable
              t={t}
              loading={loading}
              view={view}
              tableHeads={tableHeads}
              tableData={uploadedFiles}
              handleSwitchChange={this.handleSwitchChange}
              handleSortByColumn={this.handleSortByColumn}
              tableRows={this.tableRows}
              sortBy={sortBy}
              sortType={sortType}
              message={!loading && !error && uploadedFiles.length === 0 && t("members.noRecentHistory")}
              error={error}
              changeView={this.filterView}
            />
          }
          {view === "list" && !loading && uploadedFiles && uploadedFiles.length > 0 && !error && (
            <div className="table-pagination csvUploadtbl">
              <PaginationComponent
                width={width}
                loading={loading}
                totalItems={uploadedFiles.length ? uploadedFiles[0].count : 0}
                activePage={activePage}
                itemsPerPage={this.state.limit}
                availableItems={uploadedFiles.length}
                onChange={this.onPageChange}
              // showLimit
              />
            </div>
          )}
          <ErrorDialog t={t} open={openErrorDialog} handleClose={this.closeErrorDialog} error={selectedCsvError} style={this.props.style} />
        </Grid>
      </>
    );
  }
}

RecentHistorySection.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.node.isRequired,
  pagination: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  changeRoute: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  limitPerPage: PropTypes.number.isRequired,
  building: PropTypes.node.isRequired,
  buildings: PropTypes.node.isRequired,
  style: PropTypes.node.isRequired,
  handleRefreshHistory: PropTypes.func.isRequired,
  refreshHistory: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  tableColumn: state.tableColumn,
  limitPerPage: state.limitPerPage,
  allCountries: state.countries,
  pagination: state.pagination,
});

export default translate('common')(withRouter(connect(mapStateToProps)(RecentHistorySection)));
