/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import timeZones from '../../../../util/timeZones';
import {
  FormControl,
  Grid,
  FormHelperText,
  Button,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import { CustomInput, CustomInputLabel } from '../../../UI/Input';
import SearchDropdownTimeZone from '../../../UI/SearchDropdown/SearchDropdownTimeZone';
import SearchDropdown from '../../../UI/SearchDropdown/SearchDropdown';
import countriesData from '../../../../util/country.json';
import statesData from '../../../../util/states.json';
import citiesData from '../../../../util/cities.json';
import { timeZonesFormat } from '../../../../util/utility.functions';
import {
  setUserTenantOnboarding,
  getStepOnboarding,
} from '../../../../server/auth';
import { validateZipCode } from '../../../../util/index';
import { CallTrigger } from '../../../../util/Triggers/function';
import { CLA } from '../../../../util/Triggers/triggerNames';

class AddBuilding extends React.Component {
  constructor() {
    super();
    this.state = {
      buildingName: '',
      buildingAddress: '',
      zipCode: '',
      country: '',
      state: '',
      city: '',
      timeZone: '',
      statesToShow: [],
      citiesToShow: [],
      nameError: false,
      addressError: false,
      disableSubmit: true,
      waiting: false,
      zipCodeError: false,
    };
  }

  componentDidMount() {
    this.props.handleDisableNext(true);
    CallTrigger(CLA.ADD_BUILDING.Add_Building_screen, { Previous_event: '' });
  }
  componentDidUpdate(prevProps, prevState) {
    // if (this.state.disableSubmit !== prevState.disableSubmit) {
    //   this.props.handleDisableNext(this.state.disableSubmit);
    // }
    if (
      this.props.actionBit === true &&
      this.props.actionBit !== prevProps.actionBit
    ) {
      document.getElementById('addBuildingSubmit').click();
      this.props.unsetActionBit();
    }
    if (
      (this.state.addressError !== prevState.addressError &&
        this.state.addressError) ||
      (this.state.nameError !== prevState.nameError && this.state.nameError) ||
      (this.state.zipCodeError !== prevState.zipCodeError &&
        this.state.zipCodeError)
    ) {
      this.props.handleDisableNext(true);
    } else if (this.state.disableSubmit !== prevState.disableSubmit) {
      this.props.handleDisableNext(this.state.disableSubmit);
    }
  }

  onBoardingStep = (callback) => {
    getStepOnboarding()
      .then((res) => {
        localStorage.setItem('clientName', res.data.tenant_info.client_name);
        localStorage.setItem('building', res.data.building_info.building_id);
        // localStorage.setItem('pxBuilding', res.data.building_info);
        // this.props.next();
        callback();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setCountry = (countrySelected) => {
    this.setState({
      country: countrySelected,
      state: '',
      city: '',
      statesToShow: statesData.filter(
        (obj) => obj.country_id === countrySelected.country_id
      ),
      citiesToShow: [],
    });
  };

  setCity = (selectedCity) => this.setState({ city: selectedCity });

  setCountryState = (stateSelected) => {
    this.setState({
      state: stateSelected,
      city: '',
      citiesToShow: citiesData.filter(
        (obj) => obj.state_id === stateSelected.state_id
      ),
      // errorState: false,
      // errorCity: false
    });
  };

  setTimeZone = (timeZoneSelected) => {
    this.setState({
      timeZone: timeZoneSelected
    }, () => this.checkValidator());
  };

  validateAddress = (address) => {
    if (address.length > 100)
      return 'Address must be less than 100 characters long.';
    if (!/^[a-zA-Z 0-9\.\,\+\=\%\$\&\#\'\:\_\/\\[\]\-\@]*$/.test(address)) {
      return 'Invalid address';
    }
    return false;
  };

  checkValidator = () => {
    const { 
      state, 
      country, 
      city, 
      zipCode, 
      buildingAddress, 
      buildingName, 
      timeZone 
    } = this.state;
    if (
      buildingName.length < 2 ||
      state.length < 1 ||
      city.length < 1 ||
      country.length < 1 ||
      buildingAddress.length < 1 ||
      zipCode.length < 1 ||
      timeZone === ''
    ) {
      this.setState({ disableSubmit: true });
    } else {
      this.setState({ disableSubmit: false });
    }
  }

  handleState = (name) => (event) => {
    const value = event.target.value;
    this.setState({ [name]: value }, () => {
      const {
        validateBuildingAndAssociateName,
        generalValidation,
      } = this.props;
      const { state, country, city, zipCode, buildingAddress } = this.state;
      if (name === 'buildingName') {
        this.setState({
          nameError:
            value.length > 0
              ? !validateBuildingAndAssociateName(value.trim())
              : false,
        });
      } else if (name === 'buildingAddress') {
        this.setState({
          addressError: value.length > 0 ? this.validateAddress(value) : false,
          // addressError: value.length > 0 ? !generalValidation(value) : false,
        });
      } else if (name === 'zipCode') {
        this.setState({
          zipCodeError: value.length > 0 ? !validateZipCode(value) : false,
        });
      } 
      // else if (name === 'timeZone') {
      //   this.setState({
      //     timeZone: value
      //   });
      // }
      // } else if (
      //   this.state.buildingName.length < 2 ||
      //   state.length < 1 ||
      //   city.length < 1 ||
      //   country.length < 1 ||
      //   buildingAddress.length < 1 ||
      //   zipCode.length < 1
      // ) {
      //   this.setState({ disableSubmit: true });
      // }
      this.checkValidator();
    });
  };

  handleSubmit = () => {
    const {
      buildingName,
      city,
      zipCode,
      buildingAddress,
      country,
      state,
      timeZone
    } = this.state;
    CallTrigger(CLA.ADD_BUILDING.Add_Building_continue_bclick, {
      Previous_event: '',
      Building_name: buildingName,
      Building_city: city.name,
      Building_state: state.name,
      Building_country: country.name,
    });
    this.setState(
      {
        waiting: true,
      },
      () => {
        this.onBoardingStep(() => {
          setUserTenantOnboarding(
            localStorage.getItem('clientName'),
            buildingName,
            buildingAddress,
            city.city_id,
            zipCode,
            timeZone
          )
            .then(() => {
              localStorage.setItem('trialUserUploadCSV', true);
              this.props.next();
            })
            .catch((err) => {
              this.props.setError(err.message);
              this.setState({
                waiting: false,
              });
              console.log(err);
            });
        });
      }
    );
  };

  render() {
    const {
      buildingName,
      buildingAddress,
      country,
      state,
      city,
      timeZone,
      statesToShow,
      citiesToShow,
      zipCode,
      nameError,
      addressError,
      disableSubmit,
      waiting,
      zipCodeError,
    } = this.state;
    const { t } = this.props;
    const btnClass = !disableSubmit
      ? 'onBoard-page-btn margin-top-24px'
      : 'onBoard-page-btn-disabled margin-top-24px';
    //  const btnEnabled = buildingName && buildingName !== '';
    const labelStyle = { fontSize: 13 };
    const FormHelperStyle = { color: '#FF1E59' };
    const newTimeZoneToSelect = timeZones.map(item => timeZonesFormat(item));
    return (
      <div className="onBoard-page-container-welcome">
        <Grid container className="container" style={{ minWidth: '100%' }}>
          <Grid item xs={12} sm={12}>
            <h3 className="onboarding-welcome-title">
              {t('onboarding.addBuilding.title')}
            </h3>
            <p className="onboarding-welcome-description">
              {t('onboarding.addBuilding.description')}
            </p>
          </Grid>
          <form
            style={{ width: '100%' }}
            // onSubmit={this.handleSubmit}
          >
            <Grid item xs={12} sm={12}>
              <FormControl required fullWidth className="height-75">
                <CustomInputLabel
                  htmlFor="adornment-password"
                  shrink
                  className="font-size-14"
                >
                  {t('onboarding.addBuilding.buildingName')}
                </CustomInputLabel>
                <CustomInput
                  value={buildingName}
                  onChange={this.handleState('buildingName')}
                />
                {buildingName.length > 1 && nameError && (
                  <FormHelperText style={FormHelperStyle}>
                    {nameError ? t('common.error.nameValidation') : ''}
                  </FormHelperText>
                )}
                {buildingName.length < 2 && buildingName.length > 0 && (
                  <FormHelperText style={FormHelperStyle}>
                    {t('common.error.nameLength')}
                  </FormHelperText>
                )}
                {buildingName.length > 40 && buildingName.length > 0 && (
                  <FormHelperText style={FormHelperStyle}>
                    {t('common.error.nameMaxLength')}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl required fullWidth className="height-75">
                <CustomInputLabel
                  htmlFor="adornment-password"
                  shrink
                  className="font-size-14"
                >
                  {t('onboarding.addBuilding.buildingAddress')}
                </CustomInputLabel>
                <CustomInput
                  value={buildingAddress}
                  onChange={this.handleState('buildingAddress')}
                  FormHelperTextProps={{ style: FormHelperStyle }}
                  helperText={
                    // addressError ? t('common.error.addressValidation') : ''
                    addressError
                  }
                />
                {addressError && (
                  <FormHelperText style={FormHelperStyle}>
                    {/* {t('common.error.addressValidation')} */}
                    {addressError}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="left-input-onboarding">
                    <FormControl
                    style={labelStyle}
                    shrink={country instanceof Object}
                    className="height-75 border-solid"
                    required
                    fullWidth
                    >
                      <CustomInputLabel
                        htmlFor="adornment-password"
                        shrink
                        className="font-size-14"
                      >
                        {t('onboarding.addBuilding.country')}
                      </CustomInputLabel>
                      <SearchDropdown
                        loading={countriesData.length === 0}
                        list={countriesData}
                        selection={country}
                        type="getUserDetails"
                        selectItem={this.setCountry}
                      />
                    </FormControl>
                </div>               
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="left-input-onboarding right-input-onboarding remove-padding-timezone">
                    <FormControl
                    style={labelStyle}
                    shrink={timeZone instanceof Object}
                    className="height-75 border-solid"
                    required
                    fullWidth
                    >
                      <CustomInputLabel
                        htmlFor="adornment-password"
                        shrink
                        className="font-size-14"
                      >
                        {t('buildings.timeZone')}{' '}
                      </CustomInputLabel>
                      <SearchDropdownTimeZone
                        loading={newTimeZoneToSelect.length === 0}
                        list={newTimeZoneToSelect}
                        selection={timeZone}
                        type="getUserDetails"
                        selectItem={this.setTimeZone}
                      />
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <div className="left-input-onboarding">
                    <FormControl
                      style={labelStyle}
                      shrink={state instanceof Object}
                      className="height-75 border-solid"
                      required
                      fullWidth
                    >
                      <CustomInputLabel
                        htmlFor="adornment-password"
                        shrink
                        className="font-size-14"
                      >
                        {t('onboarding.addBuilding.state')}
                      </CustomInputLabel>
                      <SearchDropdown
                        // loading={statesToShow.length === 0}
                        preSelection="Country"
                        type="getUserDetails"
                        list={statesToShow}
                        selection={state}
                        selectItem={this.setCountryState}
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <div className="left-input-onboarding right-input-onboarding">
                    <FormControl
                      style={labelStyle}
                      shrink={city instanceof Object}
                      className="height-75 border-solid"
                      required
                      fullWidth
                    >
                      <CustomInputLabel
                        htmlFor="adornment-password"
                        shrink
                        className="font-size-14"
                      >
                        {t('onboarding.addBuilding.city')}
                      </CustomInputLabel>
                      <SearchDropdown
                        // loading={citiesToShow.length === 0}
                        preSelection="State"
                        type="getUserDetails"
                        list={citiesToShow}
                        selection={city}
                        selectItem={this.setCity}
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <div className="left-input-onboarding right-input-onboarding onboard-dropdown-next-input">
                    <FormControl required fullWidth className="height-75">
                      <CustomInputLabel
                        htmlFor="adornment-password"
                        shrink
                        className="font-size-14"
                      >
                        {t('onboarding.addBuilding.zipCode')}
                      </CustomInputLabel>
                      <CustomInput
                        value={zipCode}
                        onChange={this.handleState('zipCode')}
                        helperText={
                          zipCodeError ? t('common.error.zipCodeError') : ''
                        }
                      />
                      {zipCodeError && (
                        <FormHelperText style={FormHelperStyle}>
                          {t('common.error.zipCodeError')}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              id="addBuildingSubmit"
              className={`${btnClass} ${
                waiting ||
                addressError ||
                zipCodeError ||
                nameError ||
                buildingName.length > 40 ||
                buildingAddress.length > 100
                  ? 'onBoard-page-btn-disabled'
                  : ''
              }  `}
              disabled={
                disableSubmit ||
                waiting ||
                addressError ||
                zipCodeError ||
                nameError ||
                buildingName.length > 40 ||
                buildingAddress.length > 100
              }
              onClick={this.handleSubmit}
            >
              {t('onboarding.addBuilding.button')}
              {waiting && (
                <div className="ml-2">
                  <CircularProgress size={15} className="white" />
                </div>
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

AddBuilding.propTypes = {
  next: PropTypes.func.isRequired,
  validateBuildingAndAssociateName: PropTypes.func.isRequired,
  generalValidation: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleDisableNext: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  actionBit: PropTypes.bool.isRequired,
  unsetActionBit: PropTypes.func.isRequired,
};

export default AddBuilding;
