/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { connect } from 'react-redux';
import {
  List,
  ListItem,
  ListItemText,
  // ListItemIcon,
  Divider,
  Paper,
  Grid,
  Button,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  AlignCenter,
  VerticallyCenter,
  SpaceBetween,
} from '../../../UI/ResponsiveRelated';
import PlanUpgradeNeeded from '../../PaymentModals/PlanUpgradeNeeded';
import { getDaysDifference } from '../../../../util/utility.functions';
import { function_checkFreeTrailExpired, function_checkPlanUpgradeNeededUserCheck } from '../../../../util/application.utils';
import FreeTrailExpiredInternal from '../../PaymentModals/FreeTrailExpiredInternal';

const AssociatesIconInactive = require('../../../Layout/sidebar/AssociatesIconInactive.png');

class Associatelist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPlanUpgradeNeededModal: false,
    };
  }
  closePlanUpgradeNeededModal = callback =>
    this.setState({ showPlanUpgradeNeededModal: false }, () => {
      callback && callback();
    });


    closeFreeTrialExpiredModal = (callback) => {
      this.setState({ showTrailExpiredModal: false }, () => {
        if (callback) {
        callback();
      }
    });
  }

  addAssociate = () => {
    const { buildings } = this.props;
    if (buildings && buildings.length > 0 && buildings[0].tier) {
      const { showTrailExpiredModal } = this.state;
      const freeTrialExp = function_checkFreeTrailExpired(buildings, showTrailExpiredModal);
      const planUpgradNeed = function_checkPlanUpgradeNeededUserCheck(buildings);
      if (freeTrialExp) {
        this.setState({
          showTrailExpiredModal: true
        });
        return;
      } else if (planUpgradNeed) {
        this.setState({
          showPlanUpgradeNeededModal: true,
        });
        return;
      }
    }
    this.props.openCreateAssociateForm();
  };

  render() {
    const {
      t,
      width,
      style,
      height,
      maxHeight,
      users,
      usersLoading,
      error,
      loading,
      usersError,
      buildingDetail,
      userRole,
      setDataForDeleteUser,
    } = this.props;
    const { showPlanUpgradeNeededModal, showTrailExpiredModal } = this.state;
    const getDeleteColumn = (deleteAssociate) =>
      userRole === 'mailroom_supervisor' ? (
        <p style={{ padding: 8 }}> --- </p>
      ) : userRole === 'building_manager' &&
        deleteAssociate.role.toLowerCase() === 'operator' ? (
        <IconButton
          style={{ padding: 14, marginLeft: 12 }}
          onClick={() => {
            setDataForDeleteUser('openConfirmation', true);
            setDataForDeleteUser(
              'selectedAssociateNameToDelete',
              deleteAssociate.name.props.children
            );
            setDataForDeleteUser(
              'selectedAssociateIdToDelete',
              deleteAssociate.uuid
            );
          }}
        >
          <DeleteForeverIcon className="bin-btn-delete-associates fs-16px" />
        </IconButton>
      ) : userRole === 'admin' &&
        (deleteAssociate.role.toLowerCase() === 'operator' ||
          deleteAssociate.role.toLowerCase() === 'manager') ? (
        <IconButton
          style={{ padding: 14, marginLeft: 12 }}
          onClick={() => {
            setDataForDeleteUser('openConfirmation', true);
            setDataForDeleteUser(
              'selectedAssociateNameToDelete',
              deleteAssociate.name.props.children
            );
            setDataForDeleteUser(
              'selectedAssociateIdToDelete',
              deleteAssociate.uuid
            );
          }}
        >
          <DeleteForeverIcon className="bin-btn-delete-associates fs-16px" />
        </IconButton>
      ) : userRole === 'super_admin' &&
        (deleteAssociate.role.toLowerCase() === 'operator' ||
          deleteAssociate.role.toLowerCase() === 'manager' ||
          deleteAssociate.role.toLowerCase() === 'admin') ? (
        <IconButton
          style={{ padding: 14, marginLeft: 12 }}
          onClick={() => {
            setDataForDeleteUser('openConfirmation', true);
            setDataForDeleteUser(
              'selectedAssociateNameToDelete',
              deleteAssociate.name.props.children
            );
            setDataForDeleteUser(
              'selectedAssociateIdToDelete',
              deleteAssociate.uuid
            );
          }}
        >
          <DeleteForeverIcon className="bin-btn-delete-associates fs-16px" />
        </IconButton>
      ) : (
        <p style={{ padding: 8, paddingLeft: 20 }}> --- </p>
      );

    return (
      <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper
          style={{
            height,
            maxHeight,
            ...style.common.oy_a,
            marginBottom: 15,
          }}
          className="just-paper"
        >
          <div className="card-body">
            <SpaceBetween style={style.common.mb_4}>
              <h5 className="bold-text">{t('tables.associates')}</h5>
              {!loading &&
                !error &&
                (!buildingDetail ? (
                  <Tooltip title={t('buildings.saveBuildingFirst')}>
                    <Button className="mailroom-btn">
                      {t('associates.add')}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="mailroom-btn blue"
                    onClick={() => this.addAssociate()}
                  >
                    {t('associates.add')}
                  </Button>
                ))}
            </SpaceBetween>
            <Divider />
            {usersError && (
              <VerticallyCenter>
                <AlignCenter>
                  <p>
                    {' '}
                    <b> {t('common.error.networkError')} </b>{' '}
                  </p>
                </AlignCenter>
              </VerticallyCenter>
            )}
            {!usersError && (
              <>
                <List
                  style={{
                    ...style.common.o_h,
                    ...style.common.w_100,
                    ...style.common.p_0,
                  }}
                >
                  <ListItem>
                    <div>&nbsp; &nbsp;&nbsp; &nbsp;</div>
                    <ListItemText className=" uppsercase">
                      <div className="table-heading">{t('tables.name')}</div>
                    </ListItemText>
                    <ListItemText className=" uppsercase">
                      <div className="table-heading">{t('tables.email')}</div>
                    </ListItemText>
                    <ListItemText className="uppsercase">
                      <div className="table-heading">{t('tables.role')}</div>
                    </ListItemText>
                    <ListItemText className=" uppsercase">
                      <div className="table-heading">{t('tables.status')}</div>
                    </ListItemText>
                    <ListItemText className=" uppsercase">
                      <div className="table-heading">{t('tables.delete')}</div>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </List>
                {usersLoading && (
                  <List style={{ ...style.common.o_h, width: '100%' }}>
                    {[1, 2, 3].map(() => (
                      <div>
                        <ListItem style={style.common.h_49}>
                          <ListItemText style={{ width: 85 }}>
                            <div
                              style={{
                                ...style.common.fs_12,
                                ...style.common.fs_14,
                                ...style.common.lh_14,
                                ...style.colors.grey,
                              }}
                            >
                              <Skeleton width={100} />
                            </div>
                          </ListItemText>
                          <ListItemText style={{ width: 85 }}>
                            <div
                              style={{
                                ...style.common.fs_12,
                                ...style.common.fs_14,
                                ...style.common.lh_14,
                                ...style.colors.grey,
                              }}
                            >
                              <Skeleton width={100} />
                            </div>
                          </ListItemText>
                          <ListItemText style={{ width: 85 }}>
                            <div
                              style={{
                                ...style.common.fs_12,
                                ...style.common.fs_14,
                                ...style.common.lh_14,
                                ...style.colors.grey,
                              }}
                            >
                              <Skeleton width={100} />
                            </div>
                          </ListItemText>
                          <ListItemText style={{ width: 85 }}>
                            <div
                              style={{
                                ...style.common.fs_12,
                                ...style.common.fs_14,
                                ...style.common.lh_14,
                                ...style.colors.grey,
                              }}
                            >
                              <Skeleton width={100} />
                            </div>
                          </ListItemText>
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                  </List>
                )}
                {!usersLoading && users.length > 0 && (
                  <List className="mailrooms-list">
                    {users.map((user) => (
                      <div>
                        <ListItem style={style.common.h_49}>
                          <Link to={`/associate_detail/${user.uuid}`}>
                            <img
                              style={{ height: 26, width: 26 }}
                              src={AssociatesIconInactive}
                              alt=""
                            />
                          </Link>

                          <ListItemText className="mailroom-cells">
                            <div
                              className={
                                window.screen.width < 1200
                                  ? 'padding-left-10px mailroom-data width-100'
                                  : 'associate-data'
                              }
                            >
                              <Link
                                // className={
                                //   window.screen.width < 1200
                                //     ? 'padding-left-10px mailroom-data width-100'
                                //     : 'associate-data'
                                // }
                                to={`/associate_detail/${user.uuid}`}
                              >
                                {user.name}
                              </Link>
                            </div>
                          </ListItemText>
                          <ListItemText className="mailroom-cells">
                            <div
                              className={
                                window.screen.width < 1200
                                  ? 'padding-left-10px mailroom-data width-100'
                                  : 'associate-data'
                              }
                            >
                              {user.email}
                            </div>
                          </ListItemText>
                          <ListItemText className="mailroom-cells">
                            <div
                              className={
                                window.screen.width < 1200
                                  ? 'padding-left-10px mailroom-data width-100'
                                  : 'associate-data'
                              }
                            >
                              {user.role}
                            </div>
                          </ListItemText>
                          <ListItemText className="mailroom-cells">
                            <div className="padding-left-5 mailroom-data">
                              {/* <div
                            className={
                              window.screen.width < 1200
                                ? 'padding-left-5 mailroom-data width-100'
                                : 'associate-data'
                            }
                          > */}
                              {user.status}
                            </div>
                          </ListItemText>
                          <ListItemText className="mailroom-cells">
                            {getDeleteColumn(user)}
                          </ListItemText>
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                  </List>
                )}
              </>
            )}

            {!usersLoading && !usersError && users.length === 0 && (
              <VerticallyCenter
                style={{
                  height:
                    !buildingDetail && width === 'lg' && width === 'xl' && 380,
                }}
              >
                <AlignCenter>
                  <p>{t('associates.noAssociates')}</p>
                </AlignCenter>
              </VerticallyCenter>
            )}
          </div>
        </Paper>
        <PlanUpgradeNeeded
          type="users"
          open={showPlanUpgradeNeededModal}
          onClose={this.closePlanUpgradeNeededModal}
          t={t}
        />
        <FreeTrailExpiredInternal 
          type="users"
          userRole={this.props.userRole}
          open={showTrailExpiredModal}
          onClose={this.closeFreeTrialExpiredModal}
          t={t}
        />
      </Grid>
    );
  }
}

Associatelist.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  users: PropTypes.node.isRequired,
  usersLoading: PropTypes.bool.isRequired,
  usersError: PropTypes.bool.isRequired,
  openCreateAssociateForm: PropTypes.func.isRequired,
  buildingDetail: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  maxHeight: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.node.isRequired,
  userRole: PropTypes.string.isRequired,
  setDataForDeleteUser: PropTypes.func.isRequired,
  buildings: PropTypes.func.isRequired,
};

export default withRouter(
  connect((state) => ({
    buildings: state.allBuildings,
  }))(Associatelist)
);
