import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Paper,
  IconButton,
  CircularProgress,
  withMobileDialog,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Popover,
  InputBase,
  ClickAwayListener,
} from '@material-ui/core';
import { ArrowDropDown, ArrowRight, Check, Search, ArrowBack } from '@material-ui/icons';
import {
  Flex,
  AlignCenter,
  SpaceBetween,
  VerticallyCenter,
} from '../../UI/ResponsiveRelated';
import BlueButton from '../../UI/BlueButton';
import { setBuilding } from '../../../redux/actions/locationsFilterActions';
import { truncateLabelFromLeft } from '../../../util/utility.functions';
import { setCountries } from '../../../redux/actions/countriesActions';
import { informSlackDBCodeErrs } from '../../../util/informSlack';

class BuildingToggle extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      anchor: null,
      activeStep: 0,
      list: [],
      filter: [],
      buildingName: props.t('common.subtitle'),
      countryIndex: 0,
      countryName: null,
      stateIndex: 0,
      stateName: null,
      cityIndex: 0,
      cityName: null,
      loading: true,
      loadingLocations: props.allCountries.length === 0,
      error: '',
      searchKey: '',
      dropdown: null,
      selectedOption: 0,
      searchList: [],
      searching: false,
      suggestions: [],
      selected: [],
      options: [t('common.all'), t('buildings.country'), t('buildings.state'), t('buildings.city'), t('email.building')],
    };
  }

  componentDidMount() {
    const { allCountries } = this.props;
    if (allCountries.length === 0) {
      this.setDefaultData();
    }
    this.setBuilding(this.props.building);
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lng !== this.props.lng) {
      this.setBuilding(this.props.building);
    }
  }

  setBuilding = building => {
    const { buildings } = this.props;
    if (building) {
      const { t } = this.props;
      let buildingName = null;
      if (building[0] && building[0].building_name) {
        buildingName = building[0].building_name;
      }
      if (building[0] && building[0].building_city) {
        const city = building[0].building_city;
        buildingName = `${buildingName}, ${city}`;
      }
      if (building[0] && building[0].building_state) {
        const state = building[0].building_state;
        buildingName = `${buildingName}, ${state}`;
      }
      if (building[0] && building[0].building_country) {
        const country = building[0].building_country;
        buildingName = `${buildingName}, ${country}`;
      } else {
        buildingName = this.props.pxBuilding !== null ? 'Select Building' : t('common.subtitle');
      }
      if (building[0] && building[0].building_name && building[0].building_name && buildings.length === 1) {
        buildingName = building[0] && building[0].building_name && building[0].building_name;
      }
      this.setState({
        buildingName,
        options: [t('common.all'), t('buildings.country'), t('buildings.state'), t('buildings.city'), t('email.building')],
      });
    }
  };

  setDefaultData = () => {
    let {
      building, getBuildingHierarchy, dispatch,
    } = this.props;
    this.setBuilding(building);
    getBuildingHierarchy()
      .then(locations => {
        const { countries } = locations;
        dispatch(setCountries(countries));
        this.setState({
          loading: false,
          loadingLocations: false,
        }, () => this.populateData());
      })
      .catch(error => {
        this.setState({
          loading: false,
          loadingLocations: false,
          error: error.response && error.response.data.message,
        });
      });
  }

  jumpStep = activeStep => {
    const {
      loading, countryIndex, stateIndex, cityIndex, filter,
    } = this.state;
    if (activeStep === 0 && !loading) {
      this.setState({
        countryName: null,
        stateName: null,
        cityName: null,
        list: filter,
        activeStep,
      });
    } else if (activeStep === 1 && !loading) {
      this.setState({
        stateName: null,
        cityName: null,
        list: filter[countryIndex].states,
        activeStep,
      });
    } else if (activeStep === 2 && !loading) {
      this.setState({
        cityName: null,
        list: filter[countryIndex].states[stateIndex].cities,
        activeStep,
      });
    } else if (activeStep === 3 && !loading) {
      this.setState({
        list: filter[countryIndex].states[stateIndex].cities[cityIndex].buildings,
        activeStep,
      });
    } else if (activeStep === 4) {
      const { selected } = this.state;
      const selectedBuilding = {
        building_country: selected[0].country || selected[0].building_country,
        building_state: selected[0].state || selected[0].building_state,
        building_city: selected[0].city || selected[0].building_city,
        building_id: selected[0].building_id,
        building_name: selected[0].name || selected[0].building_name,
      };
      let suggestions = localStorage.px_search_suggestions || [];
      try {
        suggestions = JSON.parse(suggestions);
        suggestions.forEach((sugg, index) => {
          if (sugg.building_id === selectedBuilding.building_id) {
            suggestions.splice(index, 1);
          }
        });
        if (suggestions.length > 4) {
          suggestions.pop();
        }
        suggestions.unshift(selectedBuilding);
      } catch (e) {
        informSlackDBCodeErrs(e, 'source > src/containers/Dashboard/BuildingToggle/index.js', 'Line # 182');
        suggestions = [selectedBuilding];
      }
      localStorage.px_search_suggestions = JSON.stringify(suggestions);
      this.props.dispatch(setBuilding([selectedBuilding]));
      this.setBuilding([selectedBuilding]);
      this.handleClose();
    }
  };

  populateData = () => {
    let { building, buildings, t } = this.props;
    const { buildingName } = this.state;
    let arrayedBuilding =
      buildingName !== t('common.subtitle') ? buildingName.split(',') : [];
    this.setState({
      activeStep: arrayedBuilding.length === 4 ? 3 : 0,
      loading: true
    });
    this.setBuilding(this.props.building);
    const { allCountries } = this.props;
    const sortedLocations = allCountries.sort((a, b) =>
      (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    let foundCountry = [];
    let foundCity = {};

    if (arrayedBuilding.length === 4) {
      foundCountry = Object.keys(sortedLocations).filter(key =>
        sortedLocations[key].name.trim() === arrayedBuilding[3].trim());
      let foundState = {};
      if (foundCountry.length) {
        for (
          let i = 0;
          i < sortedLocations[foundCountry[0]].states.length;
          i += 1
        ) {
          if (
            sortedLocations[foundCountry[0]].states[i].state ===
            arrayedBuilding[2].trim()
          ) {
            foundState = sortedLocations[foundCountry[0]].states[i];
            this.setState({
              stateIndex: i,
              countryName: sortedLocations[foundCountry[0]].name,
              stateName: sortedLocations[foundCountry[0]].states[i].state,
            });
          }
        }
        for (let i = 0; i < foundState.cities.length; i += 1) {
          if (foundState.cities[i].city === arrayedBuilding[1].trim()) {
            foundCity = foundState.cities[i];
            this.setState({ cityIndex: i, cityName: foundState.cities[i].city });
          }
        }
      }
    }

    let suggestions = localStorage.px_search_suggestions || [];
    try {
      suggestions = JSON.parse(suggestions);
    } catch (err) {
      suggestions = [];
    }

    const buildingIds = buildings.map(b => b.building_id);

    suggestions = suggestions.filter(s => buildingIds.indexOf(s.building_id) > -1);

    suggestions = suggestions.filter(sugg => {
      sugg.country = sugg.building_country || sugg.country;
      sugg.state = sugg.building_state || sugg.state;
      sugg.city = sugg.building_city || sugg.city;
      sugg.name = sugg.building_name || sugg.name;
      return sugg;
    });
    this.setState({
      suggestions,
      loading: false,
      filter: sortedLocations,
      countryIndex: foundCountry && foundCountry[0],
      list: arrayedBuilding.length === 4 ? foundCity.buildings : sortedLocations,
      selected: building,
    });
  }

  handleClick = e => {
    this.setState({ anchor: e.currentTarget });
    if (!this.state.loadingLocations) {
      this.populateData();
    }
  };

  handleNext = (event, item, index) => {
    let {
      activeStep,
      list,
      countryName,
      stateName,
      cityName,
    } = this.state;
    const { dispatch } = this.props;

    if (index === -1) {
      this.setState({
        countryIndex: null,
        countryName: null,
        stateName: null,
        cityName: null,
        activeStep: 0,
      });
      this.setBuilding([]);
      dispatch(setBuilding([]));
      this.handleClose();
    } else if (activeStep === 0) {
      this.setState({
        selected: [],
        countryIndex: index,
        countryName: list[index].name,
        stateName: null,
        cityName: null,
        list: item.states,
        activeStep: 1,
      });
    } else if (activeStep === 1) {
      this.setState({
        selected: [],
        stateIndex: index,
        stateName: list[index].state,
        cityName: null,
        list: item.cities,
        activeStep: 2,
      });
    } else if (activeStep === 2) {
      this.setState({
        selected: [],
        cityIndex: index,
        cityName: list[index].city,
        list: item.buildings,
        activeStep: 3,
      });
    } else {
      item.country = countryName;
      item.state = stateName;
      item.city = cityName;
      this.setState({ selected: [item] });
    }
  };

  handleClose = () => {
    this.setState({
      anchor: null, activeStep: 3, countryName: null, stateName: null, cityName: null,
    });
    this.stopSearch();
  };

  handleSearch = e => {
    const key = e.target.value;
    this.setState({ searchKey: key }, () => this.search());
  };

  search = () => {
    const { selectedOption, filter, searchKey } = this.state;
    if (searchKey.length === 0) this.setState({ searchList: [] });
    else if (selectedOption === 0 && searchKey.length > 0) {
      let searchList = [];
      filter.forEach((country, cIndex) => {
        country.country_index = cIndex;
        if (country.name.toLowerCase().includes(searchKey.toLowerCase())) searchList.push(country);
        country.states.forEach((state, sIndex) => {
          state.country_index = cIndex;
          state.country = country.name;
          state.state_index = sIndex;
          if (state.state.toLowerCase().includes(searchKey.toLowerCase())) searchList.push(state);
          state.cities.forEach((city, cityIndex) => {
            city.country_index = cIndex;
            city.country = country.name;
            city.state_index = sIndex;
            city.state = state.state;
            city.city_index = cityIndex;
            if (city.city.toLowerCase().includes(searchKey.toLowerCase())) searchList.push(city);
            city.buildings.forEach((building, bIndex) => {
              building.country_index = cIndex;
              building.country = country.name;
              building.state_index = sIndex;
              building.state = state.state;
              building.city_index = cityIndex;
              building.city = city.city;
              building.building_index = bIndex;
              if (building.name.toLowerCase().includes(searchKey.toLowerCase())) searchList.push(building);
            });
          });
        });
      });
      this.setState({ searchList });
    } else if (selectedOption === 1 && searchKey.length > 0) {
      const searchList = filter.filter((country, index) => {
        country.country_index = index;
        return country.name.toLowerCase().includes(searchKey.toLowerCase()) && country;
      });
      this.setState({ searchList });
    } else if (selectedOption === 2) {
      let searchList = [];
      filter.forEach((country, cIndex) => {
        country.states.forEach((state, sIndex) => {
          state.country_index = cIndex;
          state.country = country.name;
          state.state_index = sIndex;
          if (state.state.toLowerCase().includes(searchKey.toLowerCase())) searchList.push(state);
        });
      });
      this.setState({ searchList });
    } else if (selectedOption === 3 && searchKey.length > 0) {
      let searchList = [];
      filter.forEach((country, cIndex) => {
        country.states.forEach((state, sIndex) => {
          state.cities.forEach((city, cityIndex) => {
            city.country_index = cIndex;
            city.country = country.name;
            city.state_index = sIndex;
            city.state = state.state;
            city.city_index = cityIndex;
            if (city.city.toLowerCase().includes(searchKey.toLowerCase())) searchList.push(city);
          });
        });
      });
      this.setState({ searchList });
    } else if (selectedOption === 4 && searchKey.length > 0) {
      let searchList = [];
      filter.forEach((country, cIndex) => {
        country.states.forEach((state, sIndex) => {
          state.cities.forEach((city, cityIndex) => {
            city.buildings.forEach((building, bIndex) => {
              building.country_index = cIndex;
              building.country = country.name;
              building.state_index = sIndex;
              building.state = state.state;
              building.city_index = cityIndex;
              building.city = city.city;
              building.building_index = bIndex;
              if (building.name.toLowerCase().includes(searchKey.toLowerCase())) searchList.push(building);
            });
          });
        });
      });
      this.setState({ searchList });
    }
  };

  openDropdown = e => this.setState({ dropdown: e.currentTarget });

  selectOption = selectedOption => this.setState({ selectedOption, dropdown: null }, () => this.search());

  stopSearch = () => this.setState({ searchList: [], searchKey: '', searching: false });

  selectFromSearch = item => {
    if (item.name && !item.building_id) {
      this.setState({
        countryIndex: item.country_index,
        countryName: item.name,
        stateName: null,
        cityName: null,
        list: item.states.sort((a, b) =>
          (a.state > b.state ? 1 : b.state > a.state ? -1 : 0)),
        activeStep: 1,
        searching: false,
        searchKey: '',
      });
    } else if (item.state && !item.city) {
      this.setState({
        countryIndex: item.country_index,
        countryName: item.country,
        stateName: item.state,
        cityName: null,
        list: item.cities.sort((a, b) =>
          (a.city > b.city ? 1 : b.city > a.city ? -1 : 0)),
        activeStep: 2,
        searching: false,
        searchKey: '',
      });
    } else if (item.city && !item.building_id) {
      this.setState({
        countryIndex: item.country_index,
        countryName: item.country,
        stateIndex: item.state_index,
        stateName: item.state,
        cityName: item.city,
        list: item.buildings.sort((a, b) =>
          (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
        activeStep: 3,
        searching: false,
        searchKey: '',
      });
    } else if (item.building_id) {
      let suggestions = localStorage.px_search_suggestions || [];
      try {
        suggestions = suggestions.length ? JSON.parse(suggestions) : [];
        suggestions.forEach((sugg, index) => {
          if (sugg.building_id === item.building_id) {
            suggestions.splice(index, 1);
          }
        });
        if (suggestions.length > 4) {
          suggestions.pop();
        }
        suggestions.unshift(item);
      } catch (e) {
        informSlackDBCodeErrs(e, 'source > src/containers/Dashboard/BuildingToggle/index.js', 'Line # 488');
        suggestions = [item];
      }
      localStorage.px_search_suggestions = JSON.stringify(suggestions);
      this.setState({ searching: false });
      const selectedBuilding = [
        {
          building_country: item.country,
          building_city: item.city,
          building_id: item.building_id,
          building_name: item.name,
          building_state: item.state,
        },
      ];
      this.props.dispatch(setBuilding(selectedBuilding));
      this.setBuilding(selectedBuilding);
      this.handleClose();
    }
  };

  render() {
    const {
      anchor,
      loading,
      error,
      list,
      activeStep,
      buildingName,
      searchKey,
      dropdown,
      options,
      countryName,
      stateName,
      cityName,
      searchList,
      selectedOption,
      searching,
      selected,
      suggestions,
    } = this.state;
    const {
      t,
      width,
      userRole,
    } = this.props;

    let { buildings } = this.props;
    const newBuildings = [];
    buildings.map(item => {
      item.buildings.map(eachBuilding => {
        newBuildings.push(eachBuilding);
        return eachBuilding;
      });
      return item;
    });
    buildings = [...newBuildings];
    const selectedIds = selected.map(b => b.building_id);
    return (
      <React.Fragment>
        <div
          className="align-center"
          onClick={
            (userRole === 'admin' || userRole === 'super_admin' || userRole === 'building_manager')
            && buildings.length > 1 && !this.props.loading &&
            this.handleClick
          }
          role="presentation"
        >
          {width === 'xs' || width === 'sm' ? (
            <h2 className="page-subhead subhead cursor-pointer">
              {userRole === 'mailroom_supervisor'
                ? buildings[0] &&
                truncateLabelFromLeft(buildings[0].building_name)
                : userRole === 'building_manager'
                  ? buildings.length === 1
                    ? truncateLabelFromLeft(buildings[0].building_name)
                    : truncateLabelFromLeft(buildingName)
                  : truncateLabelFromLeft(buildingName)}
            </h2>
          ) : (
              <h2
                className="page-subhead subhead"
                style={{
                  cursor: (userRole === 'admin' || userRole === 'super_admin') ? 'pointer' : '',
                  textOverflow: 'ellipsis',
                  fontSize: 'inherit',
                  maxWidth: 750,
                  wordWrap: 'break-word',
                  whiteSpace: 'nowrap',
                  overflowX: 'hidden'
                }}
              >
                {userRole === 'mailroom_supervisor'
                  ? buildings[0] && buildings[0].building_name
                  : userRole === 'building_manager'
                    ? buildings.length === 1
                      ? buildings[0].building_name
                      : buildingName
                    : buildingName}
              </h2>
            )}
          {buildings.length > 1 &&
            <IconButton
              className="page-subhead subhead padding-0"
              disabled={
                userRole === 'mailroom_supervisor' ||
                (userRole === 'building_manager' && buildings.length === 1)
              }
            >
              <ArrowDropDown />
            </IconButton>}
        </div>
        <Popover
          open={anchor}
          anchorEl={anchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ClickAwayListener onClickAway={this.handleClose}>
            <Paper className="just-paper width-350px padding-15px height-400p">
              <div className="topbar__search toggle-search flex">
                {searching ?
                  <IconButton className="padding-0 z-index-2" onClick={this.stopSearch}>
                    <ArrowBack className="font-size-14 color-grey z-index-2" />
                  </IconButton> :
                  <IconButton className="padding-0 z-index-2 disabled-link">
                    <Search className="font-size-14 z-index-2" />
                  </IconButton>
                }
                <InputBase
                  aria-haspopup="true"
                  className="search topbar__search-field topbar__search-field--open grey building-toggle-search"
                  value={searchKey}
                  disabled={loading}
                  onChange={this.handleSearch}
                  onClick={() => !loading && this.setState({ searching: true })}
                  placeholder={t('buildingToggle.search')}
                />
                <div className="width-75px flex-end">
                  <div className="align-center">
                    <div className="font-weight-bolder font-size-12">{options[selectedOption]}</div>
                    <IconButton
                      className="padding-0"
                      disabled={dropdown}
                      onClick={!loading && this.openDropdown}
                    >
                      <ArrowDropDown className="color-grey" />
                    </IconButton>
                  </div>
                </div>
              </div>
              {dropdown &&
                <div>
                  <ClickAwayListener onClickAway={() => this.setState({ dropdown: null })}>
                    <Paper className="just-paper building-options-dropdown">
                      {options.map((option, index) => (
                        <ListItem onClick={() => this.selectOption(index)} className="options-item font-size-12 space-between">
                          <div>{option}</div>
                          {selectedOption === index && <Check className="font-size-14" />}
                        </ListItem>))}
                    </Paper>
                  </ClickAwayListener>
                </div>
              }
              {(loading || error) && (
                <div className="align-center-column">
                  {loading && (
                    <React.Fragment>
                      <CircularProgress className="blue margin-right-5" size={15} />
                      {t('buildingToggle.loadingLocations')}
                    </React.Fragment>
                  )}
                  {error && error}
                </div>
              )}
              {searching && searchKey.length === 0 &&
                <div className="margin-top-bottom-10p height-90p overflowy-scroll">
                  <p className="color-grey font-size-12">{t('buildingToggle.recent')}</p>
                  {suggestions.map(item =>
                  (<ListItem button className="padding-0" onClick={() => this.selectFromSearch(item)}>
                    <ListItemText className="padding-0">
                      <SpaceBetween className="width-100" >
                        <div className="font-size-14 top-bottom truncated-heading">{item.name || item.state || item.city}</div>
                        <div className="align-center">
                          <div className="font-size-12 color-grey">{
                            item.building_id ? t('email.building') :
                              item.city ? t('buildings.city') :
                                item.state ? t('buildings.state') :
                                  t('buildings.country')
                          }
                          </div>

                          {item.building_id ? <div className="width-18px" /> : <ArrowRight />}
                        </div>
                      </SpaceBetween>
                    </ListItemText>
                  </ListItem>))}
                </div>}
              {searching && searchKey.length > 0 && searchList.length === 0
                //  && <p className="align-center">{t('common.noData')}</p>
              }
              {searching && searchList.length > 0 &&
                <div className="margin-top-bottom-10p height-90p overflowy-scroll">
                  {searchList.map(item =>
                  (<ListItem
                    button
                    className="padding-0"
                    onClick={() => this.selectFromSearch(item)}
                  >
                    <ListItemText className="padding-0">
                      <Flex>
                        <SpaceBetween className="width-100" >
                          <div className="font-size-14 top-bottom truncated-heading">
                            {item.building_id ? item.name : item.city || item.state || item.name}
                          </div>
                          <div className="align-center">
                            <div className="font-size-12 color-grey">{
                              item.building_id ? t('email.building') :
                                item.city ? t('buildings.city') :
                                  item.state ? t('buildings.state') :
                                    t('buildings.country')
                            }
                            </div>
                            {item.building_id ? <div className="width-18px" /> : <ArrowRight />}
                          </div>
                        </SpaceBetween>
                      </Flex>
                    </ListItemText>
                  </ListItem>))}
                </div>}
              {searching && searchKey.length > 0 && searchList.length === 0 && <p className="align-center">{t('common.noData')}</p>}
              {!loading && !error && !searching && (
                <div>
                  <List>
                    <ListItem className="top-bottom padding-0">
                      <p role="presentation" className="cursor-pointer font-weight-bolder font-size-14" onClick={() => this.jumpStep(0)}>
                        {t('common.world')}
                      </p>
                      {countryName && <div className="flex truncated-heading width-auto">
                        <ArrowRight />
                        <VerticallyCenter>
                          <div className="cursor-pointer font-size-14 truncated-heading" role="presentation" onClick={() => this.jumpStep(1)}>
                            {countryName}
                          </div>
                        </VerticallyCenter>
                      </div>
                      }
                      {stateName && <div className="flex truncated-heading width-auto">
                        <ArrowRight />
                        <VerticallyCenter>
                          <div className="cursor-pointer font-size-14 truncated-heading" role="presentation" onClick={() => this.jumpStep(2)}>
                            {stateName}
                          </div>
                        </VerticallyCenter>
                      </div>
                      }
                      {cityName && <div className="flex truncated-heading width-auto">
                        <ArrowRight />
                        <VerticallyCenter>
                          <div className="cursor-pointer font-size-14 truncated-heading" role="presentation" onClick={() => this.jumpStep(3)}>
                            {cityName}
                          </div>
                        </VerticallyCenter>
                      </div>
                      }
                    </ListItem>
                    <Divider />
                    {
                      this.props.pxBuilding !== null ? '' :
                        <ListItem button onClick={e => this.handleNext(e, {}, -1)} className="padding-0">
                          <ListItemText>
                            <p className="font-size-14 top-bottom">{t('common.subtitle')}</p>
                          </ListItemText>
                        </ListItem>
                    }

                    <Divider />
                    <div className="max-height-215px overflow-auto grey">
                      {list && list.map((item, index) => (
                        <ListItem
                          button
                          key={item.building_id}
                          selected={selectedIds.indexOf(item.building_id) > -1}
                          style={{ padding: 0 }}
                        >
                          <ListItemText style={{ padding: 0 }}>
                            <Flex className="padding-left-10">
                              <SpaceBetween className="width-100" onClick={e => this.handleNext(e, item, index)}>
                                <div className="font-size-14 top-bottom truncated-heading">
                                  {item.building_id ? item.name : item.city || item.state || item.name}
                                </div>
                                {item.building_id ? <div className="width-18px" /> : <ArrowRight className="color-grey" />}
                              </SpaceBetween>
                            </Flex>
                          </ListItemText>
                        </ListItem>
                      ))}
                    </div>
                    {list && list.length === 0 && error && (
                      <VerticallyCenter>
                        <AlignCenter>{t('common.error.networkError')}</AlignCenter>
                      </VerticallyCenter>
                    )}
                  </List>
                  {list && list.length > 0 &&
                    <div className="absolute-bottom-0 width-91">
                      <div className="space-between-width-center">
                        <div />
                        <div className="flex">
                          <Button
                            className="font-weight-bolder color-grey font-size-14"
                            onClick={e => this.handleNext(e, {}, -1)}
                            disabled={!countryName && !stateName && !cityName}
                          >
                            {t('email.reset')}
                          </Button>
                          <BlueButton
                            className="font-size-14"
                            disabled={selected.length === 0 || activeStep < 3}
                            width={120}
                            left={10}
                            onClick={() => this.jumpStep(4)}
                          >
                            {t('package.apply')}
                          </BlueButton>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              )}
            </Paper>
          </ClickAwayListener>
        </Popover>
      </React.Fragment>
    );
  }
}

BuildingToggle.propTypes = {
  t: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
  building: PropTypes.node.isRequired,
  buildings: PropTypes.node.isRequired,
  allCountries: PropTypes.node.isRequired,
  userRole: PropTypes.string.isRequired,
  getBuildingHierarchy: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pxBuilding: PropTypes.bool
};


BuildingToggle.defaultProps = {
  pxBuilding: null, // for CSV upload Check if true rename All Buildings to Select Buildings
};

export default connect(state => ({
  userRole: state.userRole,
  allCountries: state.countries,
  buildings: state.allBuildings,
}))(withMobileDialog()(BuildingToggle));
