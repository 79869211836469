export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const REFRESH_NOTIFICATIONSPOPUP = 'REFRESH_NOTIFICATIONSPOPUP';
export const REFRESH_NOTIFICATIONCENTER = 'REFRESH_NOTIFICATIONCENTER';

export function updateNotificationsAction(flag) {
  return {
    type: UPDATE_NOTIFICATIONS,
    flag,
  };
}

export function refreshNotificationsPopupAction(flag) {
  return {
    type: REFRESH_NOTIFICATIONSPOPUP,
    flag,
  };
}


export function refreshNotificationsCenterAction(flag) {
  return {
    type: REFRESH_NOTIFICATIONCENTER,
    flag,
  };
}
