/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React, { Fragment } from 'react';
// import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import ReactHighcharts from 'react-highcharts';
import chartConfig from './DonutChartsConfig';

class APIQuotaComponent extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (JSON.stringify(nextProps) === JSON.stringify(this.props)) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        const { t, data, error } = this.props;
        const statsColors = ['#ffb02f', '#ff1e59', '#02b67c'];

        return (
            <div className="quota-information">
                <h3 className="container-heading">{t('apiexpose.apiQuota')}</h3>
                <Divider />
                {
                    error
                        ? <p className="error-message"> {t('common.error.networkError')} </p>
                        : <React.Fragment>
                            <div className="quota-information-container">
                                <div className="calls-stats">
                                    {
                                        data.length === 0 &&
                                        <Fragment>
                                            <Skeleton width={150} />
                                            <Skeleton width={150} />
                                            <Skeleton width={150} />
                                        </Fragment>
                                    }
                                    {
                                        data.length !== 0 &&
                                        data.map((item, index) => {
                                            const color = statsColors[index];
                                            return (
                                                <div className="each-stat">
                                                    <p> {item.label} </p>
                                                    <p style={{ color, marginRight: index === 0 && 5 }}>
                                                        {item.data}{index > 0 && '%'}
                                                    </p>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                <div className="quota-donut-chart">
                                    {
                                        data.length === 0
                                            ? (
                                                <Fragment>
                                                    <Skeleton width={150} />
                                                    <Skeleton width={150} />
                                                    <Skeleton width={150} />
                                                </Fragment>
                                            )
                                            : <ReactHighcharts config={chartConfig(data)} style={{ marginTop: -20 }} />
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                }
            </div>
        );
    }
}
// function APIQuotaComponent(props) {
//     const { t, data, error } = props;
//     const statsColors = ['#ffb02f', '#ff1e59', '#02b67c'];

//     return (
//         <div className="quota-information">
//             <h3 className="container-heading">{t('apiexpose.apiQuota')}</h3>
//             <Divider />
//             {
//                 error
//                     ? <p className="error-message"> {t('common.error.networkError')} </p>
//                     : <React.Fragment>
//                         <div className="quota-information-container">
//                             <div className="calls-stats">
//                                 {
//                                     data.length === 0 &&
//                                     <Fragment>
//                                         <Skeleton width={150} />
//                                         <Skeleton width={150} />
//                                         <Skeleton width={150} />
//                                     </Fragment>
//                                 }
//                                 {
//                                     data.length !== 0 &&
//                                     data.map((item, index) => {
//                                         const color = statsColors[index];
//                                         return (
//                                             <div className="each-stat">
//                                                 <p> {item.label} </p>
//                                                 <p style={{ color, marginRight: index === 0 && 5 }}>
//                                                     {item.data}{index > 0 && '%'}
//                                                 </p>
//                                             </div>
//                                         );
//                                     })
//                                 }
//                             </div>
//                             <div className="quota-donut-chart">
//                                 {
//                                     data.length === 0
//                                         ? (
//                                             <Fragment>
//                                                 <Skeleton width={150} />
//                                                 <Skeleton width={150} />
//                                                 <Skeleton width={150} />
//                                             </Fragment>
//                                         )
//                                         : <ReactHighcharts config={chartConfig(data)} style={{ marginTop: -20 }} />
//                                 }
//                             </div>
//                         </div>
//                     </React.Fragment>
//             }
//         </div>
//     );
// }

APIQuotaComponent.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.node.isRequired,
    error: PropTypes.bool.isRequired,
};

export default APIQuotaComponent;
