const variable =
  process.env.REACT_APP_ENV === undefined
    ? process.env.NODE_ENV
    : process.env.REACT_APP_ENV;
export const env =
  variable === 'development'
    ? 'dev'
    : variable === 'production'
    ? 'prod'
    : variable;
export const server =
  env === 'uat_cn'
    ? 'https://ahf6hby5q5.execute-api.cn-north-1.amazonaws.com.cn/UAT'
    : env === 'wework_cn'
    ? 'https://ne0fanmrjb.execute-api.cn-north-1.amazonaws.com.cn/wework_v3'
    : env === 'wework'
      ? 'https://mailroomapi.packagex.io/4'
      : env === 'pxbusiness'
        ? 'https://api.packagex.app/v4'
        : env
          ? `https://api.packagex.app/${env}`
          : 'https://api.packagex.app/dev';
export const chineseAuth =
  env === 'qa_cn'
    ? 'https://authqa.packagex.app'
    : env === 'dev_cn'
    ? 'https://authdev.packagex.app'
    : env === 'uat_cn'
      ? 'https://auth-uat.packagexai.cn'
      : env === 'wework_cn'
        ? 'https://auth-wework.packagexai.cn'
        : 'https://authdev.packagex.app';

export const usersnapKey = env.includes('dev') ? '634b5fca-ce15-429a-a03b-c9e0880be577'
  : env.includes('qa') ? '0d0faa39-4445-4e55-89a0-4c81dea69c88'
    : env.includes('wework') ? '368872bc-f8c5-4e55-ab37-6edeb3eb2c47'
      : env.includes('pxbusiness') ? 'dc35bc26-4faa-4ae6-a61e-e877aa077c68'
        : env.includes('uat') ? '34066e87-dcaa-4dc7-9a5f-3ff9e19ea432' : '634b5fca-ce15-429a-a03b-c9e0880be577';
