/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import {
  Grid,
  Dialog,
  Button,
} from '@material-ui/core';
import BoxPerson from '../../../../assets/images/box-person.svg';
import { CallTrigger } from '../../../../util/Triggers/function';
import { CLA } from '../../../../util/Triggers/triggerNames';
import { getFullTierName } from '../../../../util/application.utils'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TrialModal({ open, close, t, tier }) {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => close()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className="trial-modal-dialog"
      >
        <DialogContent className="trial-modal-dialog">
          <Grid container className="container trial-modal-container" style={{ minWidth: '100%' }} >
            <Grid item xs={12} sm={5} className="container trial-modal-container-inner">
              <img src={BoxPerson} alt="trial" className="trial-modal-image" />
            </Grid>
            <Grid item xs={12} sm={7} className="container trial-modal-container-inner">
              <h2 className="trial-modal-heading">{t('onboarding.trailModel.title')}</h2>
             <p className="trial-modal-paragraph">
              {t('onboarding.trailModel.TrialModal1')} {getFullTierName(tier)} {t('onboarding.trailModel.TrialModal2')} {getFullTierName(tier)} {t('onboarding.trailModel.TrialModal3')}
              </p>
              <Button
                onClick={() => {
                  close();
                  CallTrigger(CLA.DASHBOARD.Dashboard_popup_get_started_bclick, { Previous_event: '' });
                }}
                className="trial-modal-button">{t('onboarding.trailModel.button')}</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

TrialModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withRouter(TrialModal);
