import React from 'react';
// import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { IconButton } from '@material-ui/core';

class Filter extends React.Component {
  state = {
    anchorEl: null,
    selected: this.props.default,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    const { onChange, top } = this.props;
    this.setState({
      anchorEl: null,
    });
    if (event.target.textContent) {
      this.setState(
        {
          selected: event.target.value,
        },
        () => onChange(this.state.selected, top ? 'top' : 'bottom'),
      );
    }
  };

  render() {
    const { anchorEl, selected } = this.state;
    const {
      style, list, disabled, containerStyle
    } = this.props;

    return (
      <div style={containerStyle}>
        <div
          disabled={disabled}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          disableRipple
        >
          <div
            className="truncated-heading"
            style={{
              ...style.flex.display,
              ...style.common.filterText,
              ...style.common.capitalize,
            }}
          >
            {list[selected]}
            <IconButton onClick={this.handleClick} style={{ padding: 2 }}>
              <ArrowDropDownIcon />
            </IconButton>
          </div>
        </div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {list.map((item, index) => (
            <MenuItem
              value={index}
              onClick={this.handleClose}
              selected={index === selected}
              style={style.common.capitalize}
            >
              {item}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

Filter.propTypes = {
  top: PropTypes.bool.isRequired,
  // chart: PropTypes.bool.isRequired,
  style: PropTypes.node.isRequired,
  containerStyle: PropTypes.node.isRequired,
  default: PropTypes.string.isRequired,
  list: PropTypes.node.isRequired,
  onChange: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
};
export default Filter;
