import { CSV_UPLOAD_LAYOUT_FLAG } from '../actions/csvFlagAction';

let initialState = false;


export default function (state = initialState, action) {
  switch (action.type) {
    case CSV_UPLOAD_LAYOUT_FLAG: {
      state = action.flag;
      return state;
    }
    default: return state;
  }
}
