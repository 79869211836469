/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
/* eslint-disable */
import * as React from 'react';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import PropTypes from 'prop-types';
import {
  AlignCenter,
  VerticallyCenter,
} from '../../../../containers/UI/ResponsiveRelated';
import Switch from '../../../../containers/UI/Switch';
import BlueButton from '../../../../containers/UI/BlueButton';
import '../styles/material-ui-table.style.css';

function MaterialUITable(props) {
  const {
    t,
    loading,
    view,
    tableHeads,
    tableData,
    handleSwitchChange,
    handleSortByColumn,
    tableRows,
    sortType,
    sortBy,
    message,
    error,
    changeView,
    withoutSorting,
    sortDataColumns,
  } = props;
  const overflow = !loading && (!message || !error) ? 'auto' : 'hidden';
  const showSortIcon = (TempName, TempSecondaryValue) => {
    const sortData = (data, name, secondaryValue) => {
      if (name !== undefined && name !== null) {
        return data.trim().toLowerCase() === name.trim().toLowerCase();
      } else {
        return (
          data.trim().toLowerCase() === secondaryValue.trim().toLowerCase()
        );
      }
    };
    if (
      Object.values(sortDataColumns).find((data) =>
        sortData(data, TempName, TempSecondaryValue)
      )
    )
      return true;
    else return false;
    // if (TempSecondaryValue === 'deleteAssociate') return false;
    // let flag = false;
    // if (TempSortedBy && TempName && tableData && tableData.length > 1) {
    //   if (TempSortedBy.trim().toLowerCase() === TempName.trim().toLowerCase()) {
    //     flag = true;
    //   }
    // }
    // if (!flag) {
    //   if (TempSortBy && TempSecondaryValue && tableData && tableData.length > 1) {
    //     if (TempSortBy.trim().toLowerCase() === TempSecondaryValue.trim().toLowerCase()) {
    //       flag = true;
    //     }
    //   }
    // }
    // if (flag && !loading && tableData.length > 1) {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  return (
    <div>
      {view === 'filter' ? (
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <BlueButton
              top={20}
              width={100}
              onClick={() => changeView('view', true)}
            >
              {t('email.save')}
            </BlueButton>
          </div>
          <Table>
            <TableBody>
              {tableHeads.map((item, index) => (
                <TableRow key={'table-header-'.concat(index)}>
                  <Switch
                    checked={item.expanded !== undefined && item.expanded}
                    value={item.name}
                    onChange={() => handleSwitchChange(index)}
                  />
                  {item.name}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <React.Fragment>
          <div style={{ overflowX: overflow, whiteSpace: 'nowrap' }}>
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    pointerEvents:
                      (loading || (tableData && tableData.length < 2)) &&
                      'none',
                    height: 45,
                  }}
                >
                  {tableHeads.map((item, index) =>
                    item.expanded !== undefined && item.expanded ? (
                      (item.name.indexOf(' ') && item.value.indexOf('_')) ===
                      -1 ? (
                        <TableCell
                          key={'table-header-'.concat(index)}
                          classes={{ root: 'table-header' }}
                          className={`padding-right-left cursor-none ${
                            item.name.includes('Thumbnail')
                              ? 'width-60px'
                              : item.name.includes('Status')
                              ? 'width-100px'
                              : 'width-200px'
                          }`}
                        >
                          <div
                            role="presentation"
                            style={
                              tableHeads[0].expanded !== undefined &&
                              tableHeads[0].expanded
                                ? index === 1
                                  ? {
                                      display: 'flex',
                                      alignItems: 'center',
                                      cursor:
                                        props.buildingDetail ||
                                        !showSortIcon(
                                          item.name,
                                          item.secondaryValue
                                        )
                                          ? 'default'
                                          : 'pointer',
                                      width: 'fit-content',
                                    }
                                  : {
                                      display: 'flex',
                                      alignItems: 'center',
                                      cursor:
                                        props.buildingDetail ||
                                        !showSortIcon(
                                          item.name,
                                          item.secondaryValue
                                        )
                                          ? 'default'
                                          : 'pointer',
                                      width: 'fit-content',
                                    }
                                : {}
                            }
                            onClick={
                              !loading &&
                              (!error ||
                                (message &&
                                  message
                                    .toLowerCase()
                                    .includes('Associates'))) &&
                              (() =>
                                showSortIcon(item.name, item.secondaryValue)
                                  ? handleSortByColumn(item.value)
                                  : null)
                            }
                            onKeyDown={
                              !loading &&
                              (!error ||
                                (message &&
                                  message
                                    .toLowerCase()
                                    .includes('Associates'))) &&
                              (() => handleSortByColumn(item.value))
                            }
                          >
                            {' '}
                            {item.name !== 'Thumbnail' && item.name}
                            {withoutSorting ? (
                              <div />
                            ) : showSortIcon(item.name, item.secondaryValue) ? (
                              tableData &&
                              tableData.length > 1 &&
                              !loading &&
                              ((item.name &&
                                item.name.trim().toLowerCase() ===
                                  sortBy.trim().toLowerCase()) ||
                                (item.secondaryValue &&
                                  item.secondaryValue.trim().toLowerCase() ===
                                    sortBy.trim().toLowerCase())) ? (
                                sortType ? (
                                  <ArrowDropUp
                                    active
                                    style={{ cursor: 'pointer' }}
                                  />
                                ) : (
                                  <ArrowDropDown
                                    active
                                    style={{ cursor: 'pointer' }}
                                  />
                                )
                              ) : (
                                <ArrowDropDown
                                  style={{
                                    color: '#dddcdc',
                                    cursor: 'pointer',
                                  }}
                                />
                              )
                            ) : null}
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell
                          key={'table-header-'.concat(index)}
                          style={{
                            fontWeight: 'bold',
                            paddingLeft: '10px',
                            cursor: showSortIcon(item.name, item.secondaryValue)
                              ? 'pointer'
                              : 'text',
                          }}
                          className={`padding-right-left ${
                            item.name.includes('Thumbnail')
                              ? 'width-60px'
                              : item.name.includes('Status') ||
                                item.name.includes('Checked')
                              ? 'width-200px'
                              : 'width-200px'
                          }`}
                          onClick={() =>
                            showSortIcon(item.name, item.secondaryValue)
                              ? handleSortByColumn(item.value)
                              : null
                          }
                        >
                          <span>
                            {item.name !== 'Thumbnail' ? item.name : ''}
                            {showSortIcon(item.name, item.secondaryValue) ? (
                              (item.name &&
                                item.name.trim().toLowerCase() ===
                                  sortBy.trim().toLowerCase()) ||
                              (item.secondaryValue &&
                                item.secondaryValue.trim().toLowerCase() ===
                                  sortBy.trim().toLowerCase()) ? (
                                sortType ? (
                                  <ArrowDropUp active />
                                ) : (
                                  <ArrowDropDown active />
                                )
                              ) : (
                                <ArrowDropDown
                                  style={{
                                    color: '#dddcdc',
                                    cursor: 'pointer',
                                  }}
                                />
                              )
                            ) : null}
                          </span>
                        </TableCell>
                      )
                    ) : (
                      <React.Fragment />
                    )
                  )}
                </TableRow>
              </TableHead>
              {tableData && !loading && !error && (
                <TableBody>
                  {tableData.map((item, index) => (
                    <TableRow
                      className="padding-right-left"
                      key={'table-rows-'.concat(index)}
                    >
                      {tableRows(item, tableHeads).map((element) => (
                        <React.Fragment key={'table-elements-'.concat(index)}>
                          {element}
                        </React.Fragment>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </div>
        </React.Fragment>
      )}
      {!loading &&
        tableData &&
        (tableData.length > 0 || tableData.length === 0) &&
        !error &&
        message &&
        view !== 'filter' && (
          <AlignCenter>
            <VerticallyCenter
              style={{ color: '#646777', paddingTop: 10, paddingBottom: 10 }}
            >
              <p>{message}</p>
            </VerticallyCenter>
          </AlignCenter>
        )}
      {loading && (
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          <AlignCenter>
            <VerticallyCenter>
              <CircularProgress className="blue" size={30} />
            </VerticallyCenter>
          </AlignCenter>
        </div>
      )}
      {(!tableData || error) && (
        <AlignCenter>
          <VerticallyCenter style={{ minHeight: 'auto' }}>
            {error && <div>{error}</div>}
          </VerticallyCenter>
        </AlignCenter>
      )}
    </div>
  );
}

MaterialUITable.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  tableHeads: PropTypes.arrayOf.isRequired,
  tableData: PropTypes.arrayOf.isRequired,
  handleSwitchChange: PropTypes.func.isRequired,
  handleSortByColumn: PropTypes.func.isRequired,
  tableRows: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortType: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired,
  withoutSorting: PropTypes.bool.isRequired,
  buildingDetail: PropTypes.bool.isRequired,
  sortDataColumns: PropTypes.array,
};

MaterialUITable.defaultProps = {
  sortDataColumns: [],
};

export default MaterialUITable;
