/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { withRouter } from 'react-router-dom';
import { CircularProgress, Grid } from '@material-ui/core';
import { AlignCenter, VerticallyCenter } from '../../UI/ResponsiveRelated';
import Preferences from '../BuildingDetail/components/Preferences';
import Alert from '../../UI/Poper';
import WarningAlert from '../../../shared/components/Alert';
import NotAuthorized from '../../UI/403/403';
import browser from '../../../util/getBrowserInfo';
import { resolveSafari } from '../../../util/resolveSafari';
import Footer from '../../UI/Footer/Footer';
import TenantSelector from '../TenantSelector/TenantSelector';
import { getPrefs } from '../../../server/buildings';
import { getTanentBranding, setTanentBranding } from '../../../server';
import CompanyAsssets from './components/CompanyAsssets';
import CompanyProfile from './components/CompanyProfile';
import PaymentInformation from './components/PaymentInformation';
import { checkAWSSession } from '../../../util/application.utils';
import ManagePlan from './components/ManagePlan';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preferences: {},
      reminderPerPkg: 1,
      timeBetweenReminders: 24,
      mondaySwitch: false,
      tuesdaySwitch: false,
      wednesdaySwitch: false,
      thursdaySwitch: false,
      fridaySwitch: false,
      mondayTime: '12:00',
      tuesdayTime: '12:00',
      wednesdayTime: '12:00',
      thursdayTime: '12:00',
      fridayTime: '12:00',
      prefsLoading: true,
      prefsError: null,
      editing: false,
      updating: false,
      updated: false,
      errorUpdating: null,
      warning: null,
      mondayErr: false,
      tuesdayErr: false,
      wednesdayErr: false,
      thursdayErr: false,
      fridayErr: false,
      selectedTenant: {
        tenant_id: null,
        tenant_name: null,
      },
      loadingBrandings: false,
      brandingError: null,
      name: null,
      email: null,
      phone: null,
      coverImage: null,
      profileImage: null,
      loadingImages: false,
      loadingcover: true,
      loadingprofile: true,
      successmsg: null,
      successfail: null,
      warningerror: null,
      encryptedEmail: null,
      paymentRenewDate: null,
      paymentHistory: [
        {
          date: '2021-03-10',
          time: '4:22',
          amount: '100$',
          description: 'Gold Plan Fee',
        },
        {
          date: '2021-03-10',
          time: '4:22',
          amount: '100$',
          description: 'Silver Plan Fee',
        },
        {
          date: '2021-03-10',
          time: '4:22',
          amount: '100$',
          description: 'Gold Plan Fee',
        },
      ],
      userCards: [
        {
          number: '********3928',
          expiry: '04/23',
          defaultSelected: false,
        },
        {
          number: '********1212',
          expiry: '02/21',
          defaultSelected: true,
        },
        {
          number: '********1212',
          expiry: '07/24',
          defaultSelected: false,
        },
      ],
    };
  }

  componentDidMount() {
    checkAWSSession((res) => {
      if (res) {
        this.setState({
          encryptedEmail: `/onboarding?email=${res}`,
        });
      }
      if (browser.name === 'Safari') {
        resolveSafari();
      }
      this.interval = setInterval(() => {
        const { buildings } = this.props;
        if (buildings.length !== 0) {
          const tenant = JSON.parse(localStorage.getItem('selectedTanent'));
          const { tenant_name, tenant_id } = tenant || buildings[0];
          this.setState(
            {
              selectedTenant: {
                tenant_name,
                tenant_id,
              },
            },
            () => this.getBrandings()
          );
          // this.getPrefs(this.props);
          clearInterval(this.interval);
        }
      }, 50);
      this.getPrefs(this.props);
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps !== prevState.props) {
      return true;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps) {
      if (prevProps !== this.props) {
        this.getPrefs(this.props);
      }
    }

    if (prevProps.lng !== this.props.lng) {
      this.setState({
        timeBetweenReminders: {
          value: this.state.timeBetweenReminders.value,
          label: `${this.state.timeBetweenReminders.value} ${prevProps.t(
            'common.hours'
          )}`,
        },
      });
    }
    // if(this.props.buildings.length !== prevProps.buildings.length && this.props.buildings.length > 0) {
    //   this.setState({
    //     paymentRenewDate: this.props.buildings[0].valid_until
    //   })
    // }
    // if (prevState.selectedTenant.tenant_id !== this.state.selectedTenant.tenant_id) {
    //   this.getBrandings();
    // }
  }
  getBrandings = () => {
    const { selectedTenant } = this.state;
    const s3BaseURL = process.env.CSV_DOWNLOAD || process.env.BaseUrlS3;
    this.setState({ loadingcover: true, loadingprofile: true }, () => {
      getTanentBranding(selectedTenant.tenant_id)
        .then((resp) => {
          this.setState({
            name: resp.tenant_name,
            email: resp.tenant_email,
            phone: resp.tenant_phone,
          });
          if (resp.meta_data) {
            this.setState({
              coverImage: resp.meta_data.cover_photo
                ? resp.meta_data.cover_photo
                : null,
              profileImage: resp.meta_data.profile_photo
                ? resp.meta_data.profile_photo
                : `${s3BaseURL}/profile_photo/mailroom_logo.png`,
              loadingcover: false,
              loadingprofile: false,
            });
          } else {
            this.setState({
              coverImage: null,
              profileImage: `${s3BaseURL}/profile_photo/mailroom_logo.png`,
              loadingcover: false,
              loadingprofile: false,
            });
          }
        })
        .catch((error) =>
          this.setState({
            brandingError: error.response
              ? error.response.data.message
              : error.message,
            loadingBrandings: false,
            loadingCover: false,
            loadingDp: false,
          })
        );
    });
  };

  getPrefs = (props) => {
    const { t, userRole } = props;
    if (userRole === 'admin' || userRole === 'super_admin') {
      getPrefs()
        .then((preferences) => {
          if (preferences && Object.keys(preferences.reminders).length > 0) {
            this.setState({
              preferences,
              prefsLoading: false,
              reminderPerPkg: {
                value: preferences.reminder_per_package,
                label: preferences.reminder_per_package,
              },
              timeBetweenReminders: {
                value: preferences.time_automated_reminders,
                label: `${preferences.time_automated_reminders} ${t(
                  'common.hours'
                )}`,
              },
              mondaySwitch: preferences.reminders.monday.active,
              tuesdaySwitch: preferences.reminders.tuesday.active,
              wednesdaySwitch: preferences.reminders.wednesday.active,
              thursdaySwitch: preferences.reminders.thursday.active,
              fridaySwitch: preferences.reminders.friday.active,
              mondayTime: preferences.reminders.monday.time,
              tuesdayTime: preferences.reminders.tuesday.time,
              wednesdayTime: preferences.reminders.wednesday.time,
              thursdayTime: preferences.reminders.thursday.time,
              fridayTime: preferences.reminders.friday.time,
            });
          } else {
            this.setState({
              prefsLoading: false,
              prefsError: t('common.noData'),
            });
          }
        })
        .catch((error) => {
          this.setState({
            prefsLoading: false,
            successfail:
              (error.response && error.response.data.message) ||
              t('common.error.networkError'),
          });
        });
    }
  };

  setCoverPic = (image) => this.setState({ coverImage: image });
  setProfilePic = (image) => this.setState({ profileImage: image });

  setImage = (image, key) => {
    if (key === 'cover') {
      this.setCoverPic(image);
    } else {
      this.setProfilePic(image);
    }
    const {
      selectedTenant: { tenant_id },
    } = this.state;
    this.setState(
      {
        [`loading${key}`]: true,
        loadingImages: true,
      },
      () => {
        setTanentBranding(tenant_id, image, key)
          .then(() => {
            this.setState({
              loadingImages: false,
              successmsg: `${
                key === 'cover'
                  ? this.props.t('settings.coverUploadSucces')
                  : this.props.t('settings.profileUpload')
              }`,
              [`loading${key}`]: false,
            });
          })
          .catch((error) => {
            this.setWarningError(
              (error.response && error.response.data.message) ||
                this.props.t('common.error.networkError')
            );
            this.setState({
              [`loading${key}`]: false,
              loadingImages: false,
              // warningerror:
              //   (error.response && error.response.data.message) ||
              //   this.props.t('common.error.networkError'),
            });
          });
      }
    );
  };

  setWarningError = (error) => {
    if (error === undefined) {
      this.setState({
        warningerror: null,
      });
    } else {
      this.setState({ warningerror: error });
    }
  };

  handleChange = (name) => (event) =>
    this.setState({ [name]: event.target.value });

  handleSelect = (name, value) => this.setState({ [name]: value });

  handleDateChange = (name) => (event) => {
    this.resetWarning();
    let hours = event.getHours();
    let minutes = event.getMinutes();

    if (minutes === 0) {
      minutes = `${minutes}0`;
    }
    let time = `${hours}:${minutes}`;
    if (name === 'mondayTime') {
      if (minutes === '00' || minutes === 30) {
        this.setState({ [name]: time, mondayErr: false });
      } else {
        this.setState({ [name]: time, mondayErr: true });
      }
    } else if (name === 'tuesdayTime') {
      if (minutes === '00' || minutes === 30) {
        this.setState({ [name]: time, tuesdayErr: false });
      } else {
        this.setState({ [name]: time, tuesdayErr: true });
      }
    } else if (name === 'wednesdayTime') {
      if (minutes === '00' || minutes === 30) {
        this.setState({ [name]: time, wednesdayErr: false });
      } else {
        this.setState({ [name]: time, wednesdayErr: true });
      }
    } else if (name === 'thursdayTime') {
      if (minutes === '00' || minutes === 30) {
        this.setState({ [name]: time, thursdayErr: false });
      } else {
        this.setState({ [name]: time, thursdayErr: true });
      }
    } else if (name === 'fridayTime') {
      if (minutes === '00' || minutes === 30) {
        this.setState({ [name]: time, fridayErr: false });
      } else {
        this.setState({ [name]: time, fridayErr: true });
      }
    }
  };

  handleSwitching = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  resetWarning = () => this.setState({ warning: null });
  savePrefs = () => {
    const {
      mondayTime,
      mondaySwitch,
      tuesdayTime,
      tuesdaySwitch,
      wednesdayTime,
      wednesdaySwitch,
      thursdayTime,
      thursdaySwitch,
      fridayTime,
      fridaySwitch,
      reminderPerPkg,
      timeBetweenReminders,
    } = this.state;
    const { t, updatePreferences } = this.props;
    const nineAm = Date.parse('01/01/2011 09:00:00');
    const sixPm = Date.parse('01/01/2011 18:30:00');
    if (mondayTime.includes(':00') || mondayTime.includes(':30')) {
      if (tuesdayTime.includes(':00') || tuesdayTime.includes(':30')) {
        if (wednesdayTime.includes(':00') || wednesdayTime.includes(':30')) {
          if (thursdayTime.includes(':00') || thursdayTime.includes(':30')) {
            if (fridayTime.includes(':00') || fridayTime.includes(':30')) {
              if (
                Date.parse(`01/01/2011 ${mondayTime}:00`) < nineAm ||
                Date.parse(`01/01/2011 ${mondayTime}:00`) > sixPm ||
                Date.parse(`01/01/2011 ${tuesdayTime}:00`) < nineAm ||
                Date.parse(`01/01/2011 ${tuesdayTime}:00`) > sixPm ||
                Date.parse(`01/01/2011 ${wednesdayTime}:00`) < nineAm ||
                Date.parse(`01/01/2011 ${wednesdayTime}:00`) > sixPm ||
                Date.parse(`01/01/2011 ${thursdayTime}:00`) < nineAm ||
                Date.parse(`01/01/2011 ${thursdayTime}:00`) > sixPm ||
                Date.parse(`01/01/2011 ${fridayTime}:00`) < nineAm ||
                Date.parse(`01/01/2011 ${fridayTime}:00`) > sixPm
              ) {
                this.setState({
                  warning: `${t('settings.chooseBetweenTime')} ${t(
                    'settings.chooseBetweenMinute'
                  )}`,
                });
              } else {
                const pref = {
                  mondaySwitch,
                  tuesdaySwitch,
                  wednesdaySwitch,
                  thursdaySwitch,
                  fridaySwitch,
                  mondayTime,
                  tuesdayTime,
                  wednesdayTime,
                  thursdayTime,
                  fridayTime,
                  reminderPerPkg: reminderPerPkg.value,
                  timeBetweenReminders: timeBetweenReminders.value,
                };
                this.setState({ updating: true });
                updatePreferences(pref)
                  .then(() => this.setState({ updating: false, updated: true }))
                  .catch((error) =>
                    this.setState({
                      updating: false,
                      errorUpdateing:
                        error.response && error.response.data.message,
                    })
                  );
              }
            } else {
              this.setState({ warning: t('settings.chooseBetweenMinute') });
            }
          } else this.setState({ warning: t('settings.chooseBetweenMinute') });
        } else this.setState({ warning: t('settings.chooseBetweenMinute') });
      } else this.setState({ warning: t('settings.chooseBetweenMinute') });
    } else this.setState({ warning: t('settings.chooseBetweenMinute') });
  };

  closeAlert = () => this.setState({ warning: null, errorUpdating: false });

  hourSelection = (state, h) => {
    let prevState = this.state[state];
    prevState = `${h}:${prevState.split(':')[1]}`;
    this.setState({ [state]: prevState });
  };

  minSelection = (state, m) => {
    let prevState = this.state[state];
    prevState = `${prevState.split(':')[0]}:${m}`;
    this.setState({ [state]: prevState });
  };

  handleTenantChange = (tanent) => {
    // const value = JSON.parse(e.target.value);
    localStorage.setItem('selectedTanent', JSON.stringify(tanent));
    this.setState(
      {
        selectedTenant: {
          tenant_id: tanent.tenant_id,
          tenant_name: tanent.tenant_name,
        },
      },
      () => this.getBrandings()
    );
  };

  closeAlertImage = () =>
    this.setState({ successmsg: null, successfail: null });

  render() {
    const {
      updated,
      errorUpdating,
      warning,
      selectedTenant,
      name,
      email,
      phone,
      coverImage,
      profileImage,
      successfail,
      successmsg,
      loadingImages,
      loadingprofile,
      loadingcover,
      warningerror,
      encryptedEmail,
      userCards,
      paymentHistory,
    } = this.state;
    const {
      t,
      userRole,
      // building,
      buildings,
    } = this.props;

    const legacy =
      this.props.buildings &&
      this.props.buildings[0] &&
      this.props.buildings[0].legacy;

    const showCLASection =
      buildings && buildings.length > 0 && buildings[0].tier;

    const notTrail =
      buildings &&
      buildings.length > 0 &&
      buildings[0].status &&
      buildings[0].status !== 'TRIAL';

    let enableLogoBranding = false;
    if (!showCLASection) {
      if (buildings && buildings.length > 0) {
        enableLogoBranding = true;
      }
    } else {
      enableLogoBranding =
        showCLASection && buildings[0].features.LOGO_BRANDING;
    }

    if (encryptedEmail) {
      this.props.history.replace(encryptedEmail);
    }

    const tenants = buildings.map((eachTenant) => {
      const { tenant_name, contact_email, tenant_id } = eachTenant;
      return {
        contact_email,
        tenant_id,
        tenant_name,
      };
    });

    let isStripeCustomer = null;
    if (buildings && buildings.length > 0) {
      const { billing_method } = buildings[0];
      isStripeCustomer =
        billing_method && billing_method.toLowerCase() === 'stripe';
    }

    return !userRole ? (
      <VerticallyCenter>
        <AlignCenter>
          <CircularProgress className="blue" size={30} />
        </AlignCenter>
      </VerticallyCenter>
    ) : userRole === 'mailroom_supervisor' ||
      userRole === 'building_manager' ? (
      <NotAuthorized />
    ) : (
      <Fragment>
        <Grid container className="dashboard container height-auto">
          {errorUpdating && (
            <Alert
              variant="error"
              open
              message={errorUpdating}
              onClose={() => this.setState({ errorUpdating: false })}
            />
          )}
          {updated && (
            <Alert
              variant="success"
              open
              onClose={() => this.setState({ updated: false })}
              message={t('preferencesPortal.updated')}
            />
          )}
          {warning && (
            <WarningAlert
              variant="error"
              onClose={this.closeAlert}
              message={warning}
              open
            />
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div style={{ display: 'flex' }}>
              <h3
                style={{ marginRight: 10, marginBottom: 5 }}
                className="page-title"
              >
                {t('settings.settings')}
              </h3>
              {userRole === 'super_admin' && (
                <TenantSelector
                  t={t}
                  tenants={tenants}
                  selectedTenant={selectedTenant}
                  handleOnchange={this.handleTenantChange}
                  handleReset={() => {
                    localStorage.removeItem('selectedTanent');
                    this.setState(
                      {
                        selectedTenant: {
                          tenant_id: this.props.buildings[0].tenant_id,
                          tenant_name: this.props.buildings[0].tenant_name,
                        },
                      },
                      () => this.getBrandings()
                    );
                  }}
                />
              )}
            </div>
            {/* <h3 className="page-title margin-bottom-10">{t('settings.settings')}</h3> */}
          </Grid>
          {userRole === 'admin' && showCLASection && !legacy && (
            <ManagePlan t={this.props.t} />
          )}

          {(userRole === 'admin' || userRole === 'super_admin') && (
            <>
              {(successfail || successmsg) && (
                <Alert
                  open
                  variant={successfail ? 'error' : 'success'}
                  message={
                    successfail ? t('common.error.networkError') : successmsg
                  }
                  onClose={this.closeAlertImage}
                />
              )}
              <NavigationBlocker shouldBlockNavigation={loadingImages} />
              {/* {enableLogoBranding && <CompanyAsssets
                t={this.props.t}
                height={502}
                setImage={this.setImage}
                profileImage={profileImage}
                coverImage={coverImage}
                loadingCover={loadingcover}
                loadingDp={loadingprofile}
                warningerror={warningerror}
                setWarningError={this.setWarningError}
              />} */}
            </>
          )}
          {(userRole === 'admin' || userRole === 'super_admin') && (
            <CompanyProfile
              t={this.props.t}
              height={502}
              name={name}
              email={email}
              phone={phone}
            />
          )}
          {/* {userRole === 'super_admin' && (
            <Preferences
              globalSettings
              {...this.props}
              {...this.state}
              height={570}
              hourSelection={this.hourSelection}
              minSelection={this.minSelection}
              savePrefs={this.savePrefs}
              handleDateChange={this.handleDateChange}
              handleChange={this.handleChange}
              handleSwitching={this.handleSwitching}
              handleSelect={(nam, value) => this.handleSelect(nam, value)}
              isSettings
            />
          )} */}
          {isStripeCustomer &&
            userRole === 'admin' &&
            notTrail &&
            showCLASection && (
              <PaymentInformation
                t={this.props.t}
                height={502}
                name={name}
                email={email}
                phone={phone}
                userCards={userCards}
                paymentHistory={paymentHistory}
                buildings={this.props.buildings}
              />
            )}
        </Grid>
        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(
  connect((state) => ({
    userRole: state.userRole,
    buildings: state.allBuildings,
    // building: state.locationFilter,
  }))(Settings)
);

const NavigationBlocker = ({ shouldBlockNavigation }) => {
  if (shouldBlockNavigation) {
    window.onbeforeunload = () => true;
  } else {
    window.onbeforeunload = null;
  }
  return (
    <Prompt
      when={shouldBlockNavigation}
      message="Changes that you made may not be saved."
    />
  );
};

NavigationBlocker.propTypes = {
  shouldBlockNavigation: PropTypes.bool.isRequired,
};
