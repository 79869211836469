/* eslint-disable function-paren-newline */
/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
/* eslint-disable no-plusplus */
import React from "react";
import { withRouter } from "react-router-dom";
import ReactHighcharts from "react-highcharts";
import PropTypes from "prop-types";
import { withWidth } from "@material-ui/core";
import { connect } from "react-redux";

export const color = [
  "#FF1E59",
  "#02B67C",
  "#027AFF",
  "#804DDF",
  "#FFB02F",
  "#E21AD8",
  "#e43ce7f0",
  "#34108bf0",
];
function CourierBarChart(props) {
  const { data, width, sidebar, index, totalValue } = props;
  let total = 0;
  let config = {
    chart: {
      type: "bar",
      fill: "transparent",
      fontSize: 10,
    },
    title: {
      text: "",
    },
    dataLabels: {
      enabled: true,
      crop: false,
      overflow: "none",
    },
    yAxis: {
      max: 101,
      allowDecimals: false,
      labels: {
        enabled: false,
      },
      minTickInterval: 25,
      min: 0,
      title: {
        text: "",
      },
      opposite: true,
      gridLineWidth: 0,
    },

    xAxis: {
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      showEmpty: false,
    },
    tooltip: {
      // Tooltips display informative text when users hover over.
      textTransform: "capitalize",
      valueSuffix: "",
      backgroundColor: "rgba(97,97,97,0.9)",
      opacity: 2,
      borderWidth: 0,
      borderRadius: 0,
      headerFormat: "",
      followPointer: true,
      formatter() {
        return ` <b> ${Intl.NumberFormat("en").format(
          this.series.userOptions.count
        )}</b>`;
      },
      style: {
        color: "white",
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        zIndex: 2,
      },
    },
    plotOptions: {
      // The plotOptions is a wrapper object for config objects for each series type.
      series: {
        data: totalValue,
        dataLabels: {
          enabled: true,
          crop: false,
          overflow: "none",
        },
        events: {
          click: () => {
            if (data[0].name !== "other-carrier") {
              props.history.push(`/packages/courier_package/${data[0].name}/`);
            }
          },
        },
        cursor: data[0].name !== "other-carrier" && "pointer",
        pointPadding: 0.05,
        borderWidth: 0,
        marginLeft: 0,
        shadow: {
          color: "rgba(0,0,0,0.1)",
          offsetX: 0,
          offsetY: 5,
          width: 10,
        },
      },
      bar: {
        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter() {
            return `${this.y.toFixed(2)}%`;
          },
          style: {
            color: "#31394D",
            fontSize: 16,
            textTransform: "capitalize",
            height: 20,
          },
        },
      },

      labels: {
        style: {
          left: 30,
          marginLeft: 30,
          align: "left",
        },
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor: "#red",
      shadow: true,
      enabled: false,
      textTransform: "capitalize",
    },
    credits: {
      enabled: false,
    },
  };

  if (!sidebar.collapse) {
    // if user expand the sidebar
    if (width === "xl") {
      // set width of courier barChart
      config.chart.width = 800;
    } else if (width === "lg") {
      // set width of courier barChart which devices width is greater than or equal to 992px.
      config.chart.width = 450;
    } else if (width === "md") {
      // set width of courier barChart which devices width is greater than or equal to 768px.
      config.chart.width = 250;
    } else if (width === "sm") {
      // set width of courier barChart which devices width is greater than or equal to 576px.
      config.chart.width = 380;
    } else if (width === "xs") {
      // set width of courier barChart which devices width is less than to 576px.
      config.chart.width = 180;
    }
  } else if (sidebar.collapse) {
    // if user doesn't expand the sidebar
    if (width === "xl") {
      config.chart.width = 700;
    } else if (width === "lg") {
      // set width of courier barChart which devices width is greater than or equal to 992px.
      config.chart.width = 470;
    } else if (width === "md") {
      // set width of courier barChart which devices width is greater than or equal to 768px.
      config.chart.width = 300;
    } else if (width === "sm") {
      // set width of courier barChart which devices width is greater than or equal to 576px.
      config.chart.width = 410;
    } else if (width === "xs") {
      // set width of courier barChart which devices width is less than to 576px.
      config.chart.width = 180;
    }
  }
  config.series = [];

  for (let i = 0; i < data.length; i++) {
    config.series.push({
      name: data[i].name,
      data: data[i].val,
      count: data[i].count,
      color: color[index],
    });
    total = Number(total) + Number(data[i].val);
  }

  return (
    <div className="barchart-div1">
      <ReactHighcharts config={config} />
    </div>
  );
}

CourierBarChart.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
  totalValue: PropTypes.number.isRequired,
  history: PropTypes.node.isRequired,
};

export default withRouter(
  connect((state) => ({
    sidebar: state.sidebar,
  }))(withWidth()(CourierBarChart))
);
