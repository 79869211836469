/* eslint-disable quotes */
import React from "react";
import { Dialog, DialogContent, withWidth, ClickAwayListener } from "@material-ui/core";
import AddAssociate from "../../AddAssociate/components/AddAssociate";
import { AlignCenter } from "../../../UI/ResponsiveRelated";
import BlueButton from "../../../UI/BlueButton";

function ModalComponent(props) {
  const {
    t,
    style,
    toggle,
    onClose,
    handleChange,
    mailroomName,
    building,
    mailrooms,
    incrementLast,
    disableAssociateBtn,
    creatingAssociate,
    createAssociate,
    notitle,
    width,
    onCloseAddAssociate,
    ...other
  } = props;

  return (
    <ClickAwayListener onClickAway={toggle}>
    <Dialog
      onClose={onCloseAddAssociate}
      {...other}
      fullScreen={width === "sm" || width === "xs" || width === "md"}
      PaperProps={{ style: style.common.w_100, elevation: 11 }}
    >
      <DialogContent>
        <form onSubmit={e => e.preventDefault()}>
          <AddAssociate {...props} notitle toggle={toggle} />
          <AlignCenter>
            <BlueButton
              top={30}
              bottom={30}
              right={10}
              left={10}
              width={160}
              loading={creatingAssociate}
              disabled={disableAssociateBtn}
              onClick={createAssociate}
            >
              {t("associates.add")}
            </BlueButton>
          </AlignCenter>
        </form>
      </DialogContent>
    </Dialog>
    </ClickAwayListener>
  );
}

export default withWidth()(ModalComponent);
