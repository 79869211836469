/* eslint-disable quotes */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Paper,
  withWidth,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Filter from './Filter';
import { AlignCenter, VerticallyCenter } from '../../../UI/ResponsiveRelated';

class ScanDetailsStats extends PureComponent {
  // eslint-disable-next-line class-methods-use-this
  render() {
    const {
      t,
      style,
      // width,
      loading,
      error,
      scanDetails,
      scanFilter,
      changeFilter,
    } = this.props;
    return (
      <Paper
        className="just-paper scan-details-stats"
      >
        <div
          style={{
            ...style.flex.justify_between,
            ...style.flex.display,
            ...style.flex.align_center,
          }}
        >
          <div className="truncated-heading" style={style.common.subheading}>
            {t('package.scanDetails')}
          </div>
          {!loading && (
            <Filter
              disabled={loading || error}
              default={scanFilter}
              list={[
                t('main.filterHourly'),
                t('main.filterDaily'),
                t('main.filterWeekly'),
                t('main.filterMonthly')
              ]}
              onChange={changeFilter}
              {...this.props}
            />
          )}
        </div>
        {error && (
          <VerticallyCenter>
            <AlignCenter>{error}</AlignCenter>
          </VerticallyCenter>
        )}
        <div className="flex-center-16px-top">
          <div className="scan-detail-div2">
            <div className="flex-column-center" >
              <div className="scan-detail-label">
                {t("tables.scanned")}
              </div>
              <div>
                {loading && (
                  <div className="spinner-grow text-dark" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {!loading && !error &&
                  scanDetails.scanned &&
                  (scanFilter === 0
                    ? 
                    // <p className="scan-detail-counts black">
                    //   {scanDetails.scanned.today_count ? scanDetails.scanned.today_count.toLocaleString() : 0}
                    // </p>
                    <Link
                      to="/packages/scanned/today/scanned"
                      className="scan-detail-counts black"
                    >{scanDetails.scanned.today_count ? scanDetails.scanned.today_count.toLocaleString() : 0}
                    </Link>
                    : scanFilter === 1
                      ? 
                      // <p className="scan-detail-counts black">
                      //   {scanDetails.scanned.last_7_days_count ? scanDetails.scanned.last_7_days_count.toLocaleString() : 0}
                      // </p>
                      <Link
                        to="/packages/scanned/7_days/scanned"
                        className="scan-detail-counts black"
                      >{scanDetails.scanned.last_7_days_count ? scanDetails.scanned.last_7_days_count.toLocaleString() : 0}
                      </Link>
                      : scanFilter === 2
                        ? 
                        // <p className="scan-detail-counts black">
                        //   {scanDetails.scanned.last_4_weeks_count ? scanDetails.scanned.last_4_weeks_count.toLocaleString() : 0}
                        // </p>
                        <Link
                          to="/packages/scanned/4_weeks/scanned"
                          className="scan-detail-counts black"
                        >{scanDetails.scanned.last_4_weeks_count ? scanDetails.scanned.last_4_weeks_count.toLocaleString() : 0}
                        </Link>
                        : scanFilter === 3
                          ? 
                          // <p className="scan-detail-counts black">
                          //   {scanDetails.scanned.last_12_months_count ? scanDetails.scanned.last_12_months_count.toLocaleString() : 0}
                          // </p>
                          <Link
                            to="/packages/scanned/12_months/scanned"
                            className="scan-detail-counts black"
                          >{scanDetails.scanned.last_12_months_count ? scanDetails.scanned.last_12_months_count.toLocaleString() : 0}
                          </Link>
                          : 0)}
              </div>
            </div>
            <div className="flex-column-center">
              <div className="scan-detail-label">
                {t("tables.notified")}
              </div>
              <div>
                {loading && (
                  <div className="spinner-grow text-warning" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {!loading && !error &&
                  scanDetails.notified &&
                  ((scanFilter === 0 &&
                  //   <p className="scan-detail-counts yellow">
                  //     {scanDetails.notified.today_count ? scanDetails.notified.today_count.toLocaleString() : 0}
                  //   </p>
                  // ) ||
                    <Link
                      to="/packages/scanned/today/outstanding"
                      className="scan-detail-counts yellow"
                    >
                      {scanDetails.notified.today_count ? scanDetails.notified.today_count.toLocaleString() : 0}
                    </Link>) ||
                    (scanFilter === 1 &&
                    //   <p className="scan-detail-counts yellow">
                    //     {scanDetails.notified.last_7_days_count ? scanDetails.notified.last_7_days_count.toLocaleString() : 0}
                    //   </p>
                    // ) ||
                    <Link
                      to="/packages/scanned/7_days/outstanding"
                      className="scan-detail-counts yellow"
                    >
                      {scanDetails.notified.last_7_days_count ? scanDetails.notified.last_7_days_count.toLocaleString() : 0}
                    </Link>) ||
                    (scanFilter === 2 &&
                    //   <p className="scan-detail-counts yellow">
                    //     {scanDetails.notified.last_4_weeks_count ? scanDetails.notified.last_4_weeks_count.toLocaleString() : 0}
                    //   </p>
                    // ) ||
                    <Link
                      to="/packages/scanned/4_weeks/outstanding"
                      className="scan-detail-counts yellow"
                    >
                      {scanDetails.notified.last_4_weeks_count ? scanDetails.notified.last_4_weeks_count.toLocaleString() : 0}
                    </Link>) ||
                    (scanFilter === 3 &&
                    //   <p className="scan-detail-counts yellow">
                    //     {scanDetails.notified.last_12_months_count ? scanDetails.notified.last_12_months_count.toLocaleString() : 0}
                    //   </p>
                    // ))}
                 <Link
                      to="/packages/scanned/12_months/outstanding"
                      className="scan-detail-counts yellow"
                      >
                      {scanDetails.notified.last_12_months_count ? scanDetails.notified.last_12_months_count.toLocaleString() : 0}
                      </Link>))} 
              </div>
            </div>
            <div className="flex-column-center">
              <div className="scan-detail-label">
                {t("tables.pickedup")}
              </div>
              <div>
                {loading && (
                  <div className="spinner-grow text-success" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {!loading && !error &&
                  scanDetails.picked &&
                  ((scanFilter === 0 &&
                  //   <p className="scan-detail-counts green">
                  //     {scanDetails.picked.today_count ? scanDetails.picked.today_count.toLocaleString() : 0}
                  //   </p>
                  // ) ||
                    <Link
                      to="/packages/scanned/today/collected"
                      className="scan-detail-counts green"
                    >{scanDetails.picked.today_count ? scanDetails.picked.today_count.toLocaleString() : 0}
                    </Link>) ||
                    (scanFilter === 1 &&
                    //   <p className="scan-detail-counts green">
                    //     {scanDetails.picked.last_7_days_count ? scanDetails.picked.last_7_days_count.toLocaleString() : 0}
                    //   </p>
                    // ) ||
                    <Link
                      to="/packages/scanned/7_days/collected"
                      className="scan-detail-counts green"
                    >{scanDetails.picked.last_7_days_count ? scanDetails.picked.last_7_days_count.toLocaleString() : 0}
                    </Link>) ||
                    (scanFilter === 2 &&
                    //   <p className="scan-detail-counts green">
                    //     {scanDetails.picked.last_4_weeks_count ? scanDetails.picked.last_4_weeks_count.toLocaleString() : 0}
                    //   </p>
                    // ) ||
                    <Link
                      to="/packages/scanned/4_weeks/collected"
                      className="scan-detail-counts green"
                    >{scanDetails.picked.last_4_weeks_count ? scanDetails.picked.last_4_weeks_count.toLocaleString() : 0}
                    </Link>) ||
                    (scanFilter === 3 &&
                    //   <p className="scan-detail-counts green">
                    //     {scanDetails.picked.last_12_months_count ? scanDetails.picked.last_12_months_count.toLocaleString() : 0}
                    //   </p>
                    // )
                    <Link
                      to="/packages/scanned/12_months/collected"
                      className="scan-detail-counts green"
                    >{scanDetails.picked.last_12_months_count ? scanDetails.picked.last_12_months_count.toLocaleString() : 0}
                    </Link>)
                  )}
              </div>
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}

ScanDetailsStats.propTypes = {
  sidebar: PropTypes.node.isRequired,
  style: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
};

export default connect(state => ({
  sidebar: state.sidebar,
}))(withWidth()(ScanDetailsStats));
