import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { handleSidebarHover, handleSidebarHoverAway } from '../../../util/utility.functions';
import './style.css';

const APIExposeIconActive = require('./APIExposeIconActive.png');
const APIExposeIconInactive = require('./APIExposeIconInactive.png');

function APIExposeIcon(props) {
    const { active, label } = props;
    const imgSrc = active ? APIExposeIconActive : APIExposeIconInactive;
    return (
        <Tooltip title={label}>
            <img
                src={imgSrc}
                alt=""
                height="24"
                width="24"
                onMouseOver={(event) => { handleSidebarHover(event, APIExposeIconActive); }}
                onFocus={(event) => { handleSidebarHover(event, APIExposeIconActive); }}
                onMouseLeave={(event) => { handleSidebarHoverAway(event, active, APIExposeIconInactive); }}
            />
        </Tooltip>
    );
}
APIExposeIcon.propTypes = {
    active: PropTypes.bool.isRequired,
    label: PropTypes.bool.isRequired,
};

export default APIExposeIcon;
