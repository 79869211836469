/* eslint-disable curly */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import axios from "axios";
import moment from "moment-timezone";
import { server } from "./server";
import { spaceOnly } from "../util";

export function getBuildings(
  limit,
  orderBy,
  asc,
  paginationOffset,
  filterAttribute,
  filterValue,
  countryId,
) {
  const params = {
    pagination_limit: limit,
    order_by: orderBy,
    order_asc: asc,
    include_mailroom_count: true,
    include_active_members_count: true,
    include_active_business_count: true,
    include_recent_scans_count: true,
    include_overdue_packages_count: true,
    include_recent_members_added_count: true,
  };
  
  if (paginationOffset || paginationOffset !== 0)
    params.pagination_offset = paginationOffset;
  if (filterAttribute) {
    params.filter_attribute = filterAttribute;
    params.filter_value = filterValue;
  }
  if (countryId) params.country_id = countryId;
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/building`, { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function searchBuilding(
  key,
  paginationLimit,
  paginationOffset,
  sortBy,
  sortType,
  countryId,
) {
  return new Promise((resolve, reject) => {
    let params = {
      order_by: sortBy,
      order_asc: sortType,
      pagination_limit: paginationLimit,
      pagination_offset: paginationOffset,
      include_inactive: false,
      include_mailroom_count: true,
      include_active_business_count: true,
      include_active_members_count: true,
      include_recent_members_added_count: true,
      include_recent_scans_count: true,
      include_scans_count: true,
      include_overdue_packages_count: true,
    };
    if (countryId) params.country_id = countryId;
    if (paginationOffset === 0 || paginationOffset === null) {
      delete params.pagination_offset;
    }
    axios
      .get(`${server}/entity/building/search?query=${key}`, { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function getBuildingsEmailTemplates(building_id, order_by, order_asc) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/settings/notification_template`, {
        params: { building_id, order_by, order_asc },
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function addBuilding(building) {
  if (building.email === "" || spaceOnly(building.email)) {
    delete building.email;
  }
  if (building.name === "" || spaceOnly(building.name)) {
    delete building.name;
  }
  if (building.code === "" || spaceOnly(building.code)) {
    delete building.code;
  }
  if (building.address === "" || spaceOnly(building.address)) {
    delete building.address;
  }
  if (!building.tenant) {
    delete building.tenant;
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/entity/building`, building)
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
}

export function updatePreferences(preferences, id) {
  let modifiedPrefs = {
    building_id: id,
    preference_json: {
      reminder_per_package: preferences.reminderPerPkg,
      time_automated_reminders: preferences.timeBetweenReminders,
      reminders: {
        monday: {
          active: preferences.mondaySwitch,
          time: preferences.mondayTime,
        },
        tuesday: {
          active: preferences.tuesdaySwitch,
          time: preferences.tuesdayTime,
        },
        wednesday: {
          active: preferences.wednesdaySwitch,
          time: preferences.wednesdayTime,
        },
        thursday: {
          active: preferences.thursdaySwitch,
          time: preferences.thursdayTime,
        },
        friday: {
          active: preferences.fridaySwitch,
          time: preferences.fridayTime,
        },
      },
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .put(`${server}/settings/reminder_conf`, modifiedPrefs)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export function getBuilding(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/building/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}
export function getBuildingScanCount(bottom, from, to) {
  let params = {};
  params.from_date = from;
  params.to_date = to;
  params.order_asc = bottom;

  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/analytics/dashboard/building`, { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function getBuildingUsers(id, order_by, order_asc, pagination_limit) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/user`, {
        params: {
          building_id: id,
          order_by,
          order_asc,
          pagination_limit
        },
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function getBuildingMailrooms(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/mailroom`, {
        params: {
          building_id: id,
          include_packages_count: true,
          include_pending_packages_count: true,
        },
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function getPrefs(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/settings/reminder_conf`, { params: { building_id: id } })
      .then(response => {
        resolve(response.data.preferences ? response.data.preferences : response.data);
      }).catch(error => reject(error));
  });
}

export function createMailroom(mailroom) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/entity/mailroom`, mailroom)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export function updateBuilding(id, building) {
  if (!building.address) delete building.address;
  return new Promise((resolve, reject) => {
    axios
      .put(`${server}/entity/building/${id}`, building)
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
}

export function getBuildingHierarchy() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/building/hierarchy`, { order_by: "name" })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function getLocations(from, to) {
  let params = {};
  if (from) params.from_date = from;
  if (to) params.to_date = to;
  let timezone = moment.tz.guess(true) || "America/New_York";
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/analytics/dashboard/map?time_zone=${timezone}`, { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function getCountryStateCities(country, state) {
  let url = `${server}/entity/building/location`;
  if (country) url = `${url}?country_id=${country}`;
  else if (state) url = `${url}?state_id=${state}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url).then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function getNotificationPrefs(linked_id, linked_type) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/settings/preference`, { params: { linked_id, linked_type } })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function updateNotificationPrefs(linked_id, linked_type, preference_json, isCta = false) {
  return new Promise((resolve, reject) => {
    if (isCta) {
      axios
        .put(`${server}/settings/preference`,
          {
            linked_id,
            linked_type,
            cta_preference: true,
            preference_json
          })
        .then(response => resolve(response.data.preferences))
        .catch(error => reject(error));
    } else {
      axios
        .put(`${server}/settings/preference`,
          { linked_id, linked_type, preference_json })
        .then(response => resolve(response.data.preferences))
        .catch(error => reject(error));
    }
  });
}

export function updatePackageNotificationPrefs(building_id, preference_json) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${server}/settings/reminder_conf`,
        { building_id, ...preference_json })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function addLables(lbls) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/entity/label`, lbls)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export function deleteLabels(lbs) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${server}/entity/label`, { data: lbs })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export function getTanentBranding(tanentId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/upload-image/logobranding?tenant_id=${tanentId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}
export function setTanentBranding(tanentId, image, type) {
  let params = {
    tenant_id: tanentId,
    imagetype: type
  };
  if (type === 'cover') params.cover_photo = image.split('base64,')[1];
  else params.profile_photo = image.split('base64,')[1];
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/upload-image/logobranding`, params)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

