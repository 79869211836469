/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { CircularProgress, Grid, withWidth } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddBuildingComp from './components/AddBuilding';
import { AlignCenter, VerticallyCenter } from '../../UI/ResponsiveRelated';
import Alert from '../../UI/Poper';
import BlueButton from '../../UI/BlueButton';
import WarningAlert from '../../../shared/components/Alert';
import timeZones from '../../../util/timeZones';
import NotAuthorized from '../../UI/403/403';
import Footer from "../../UI/Footer/Footer";
import TenantSelector from '../TenantSelector';
import { setCountries } from '../../../redux/actions/countriesActions';
import { checkAWSSession } from '../../../util/application.utils';

class AddBuilding extends Component {
  state = {
    name: '',
    code: '',
    address: '',
    city: '',
    state: '',
    country: '',
    desks: '',
    floors: '',
    selectedTimeZone: '(GMT +05:00) Pakistan Standard Time',
    selectedTimeZoneObject: {
      label: 'Pakistan Standard Time',
      name: 'Asia/Karachi',
      offset: 5,
    },
    reminderPerPkg: { value: 1, label: 1 },
    timeBetweenReminders: { value: 24, label: `${24} ${this.props.t('common.hours')}` },
    mondaySwitch: false,
    tuesdaySwitch: false,
    wednesdaySwitch: false,
    thursdaySwitch: false,
    fridaySwitch: false,
    mondayTime: '12:00',
    tuesdayTime: '12:00',
    wednesdayTime: '12:00',
    thursdayTime: '12:00',
    fridayTime: '12:00',
    redirect: false,
    buildingCreated: false,
    buildingCreating: false,
    errorCreating: null,
    nameError: false,
    addressError: false,
    disableSubmit: true,
    prefsCreated: false,
    buildingId: null,
    warning: null,
    loadingLocations: true,
    loadingStates: false,
    loadingCities: false,
    errorLocations: null,
    errorStates: null,
    errorCities: null,
    locations: [],
    states: [],
    cities: [],
    selectedTenant: null,
    csv_upload: { label: this.props.t('email.yes'), value: true },
    reply_to: '',
    repltyToErrors: false,
    encryptedEmail: null,
  };

  componentDidMount() {
    checkAWSSession(res => {
      if (res) {
        this.setState({
          encryptedEmail: `/onboarding?email=${res}`,
        });
      }
      const { getCountryStateCities, t } = this.props;
    getCountryStateCities()
      .then(locations => {
        const env = process.env.REACT_APP_ENV;
        this.setState({
          locations: env && env.includes('cn') ? locations.filter(c => { if (c.code === 'CN') return c; return null; }) : locations,
          loadingLocations: false,
        });
      }).catch(err => {
        this.setState({
          loadingLocations: false,
          errorLocations: err.response ? err.response.data.message
            : err.message && err.message.includes('Network') ? t('common.error.networkError') : err.message,
        });
      });
    this.interval = setInterval(() => {
      const { buildings } = this.props;
      if (buildings.length !== 0) {
        const { tenant_name, tenant_id } = buildings[0];
        this.setState({
          selectedTenant: {
            tenant_name,
            tenant_id,
          },
        });
        clearInterval(this.interval);
      }
    }, 50);
    });
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidUpdate(props) {
    const { csv_upload } = this.state;
    if (props.lng !== this.props.lng) {
      this.setState({
        timeBetweenReminders: {
          value: this.state.timeBetweenReminders.value,
          label: `${this.state.timeBetweenReminders.value} ${this.props.t('common.hours')}`,
        },
        csv_upload: { label: csv_upload.value === true ? this.props.t('email.yes') : this.props.t('associates.no'), value: csv_upload.value },
      });
    }
  }

  handleSwitching = name => event => this.setState({ [name]: event.target.checked });

  handleSelect = (name, value) => this.setState({ [name]: value });

  handleChangeTimeZone = () => event => {
    const value = event.target.value;
    const indexOf = value.indexOf(')');
    const temp = timeZones.filter(
      item => value.substr(indexOf + 2, value.length) === item.label,
    );
    this.setState({
      selectedTimeZone: value,
      selectedTimeZoneObject: temp,
    });
  };

  selectItem = (name, item) => {
    const { t } = this.props;
    const { getCountryStateCities } = this.props;
    if (name === 'country') {
      this.setState({
        loadingStates: true,
        country: item,
        states: [],
        cities: [],
        state: '',
        city: '',
      });
      getCountryStateCities(item.country_id)
        .then(states => this.setState({ states, loadingStates: false }))
        .catch(err =>
          this.setState({
            loadingStates: false,
            errorStates: err.response
              ? err.response.data.message
              : err.message && err.message.includes('Network')
                ? t('common.error.networkError')
                : err.message,
          }),
        );
    } else if (name === 'state') {
      this.setState({
        loadingCities: true,
        state: item,
        cities: [],
        city: '',
      });
      getCountryStateCities(null, item.state_id)
        .then(cities => this.setState({ cities, loadingCities: false }))
        .catch(err =>
          this.setState({
            loadingCities: false,
            errorCities: err.response
              ? err.response.data.message
              : err.message && err.message.includes('Network')
                ? t('common.error.networkError')
                : err.message,
          }),
        );
    } else if (name === 'city') {
      this.setState({ city: item });
    }
  };

  handleChange = name => event => {
    const { t } = this.props;
    const value = event.target.value;
    if (name === 'reminderPerPkg' || name === 'timeBetweenReminders') {
      this.setState({ [name]: event.target.value });
    } else if (name === 'csv_upload') {
      this.setState({
        csv_upload: { label: event.target.value === true ? t('email.yes') : t('associates.no'), value: event.target.value },
      });
    } else {
      this.setState({ [name]: value.trimLeft() }, () => {
        const { validateEmail, validateBuildingAndAssociateName, generalValidation } = this.props;
        const { code, state, country, city, reply_to } = this.state;
        this.setState({
          repltyToErrors: reply_to.length > 0 ? !validateEmail(reply_to) : false,
        });
        if (name === 'name') {
          this.setState({
            nameError: value.length > 0 ? !validateBuildingAndAssociateName(value.trim()) : false,
          });
        } else if (name === 'address') {
          this.setState({
            addressError: value.length > 0 ? !generalValidation(value) : false,
          });
        } else if (
          this.state.name.length < 2 ||
          code.length === 0 ||
          // address.length < 1 ||
          state.length < 1 ||
          city.length < 1 ||
          country.length < 1
        ) {
          this.setState({ disableSubmit: true });
        }
        if (
          this.state.name.length > 0 &&
          code.length > 0 &&
          // address.length > 0 &&
          state.length > 0 &&
          city.length > 0 &&
          country.length > 0
        ) {
          this.setState({ disableSubmit: false });
        }
      });
    }
  };

  saveBuilding = () => {
    const {
      name,
      code,
      address,
      city,
      state,
      country,
      nameError,
      addressError,
      mondaySwitch,
      tuesdaySwitch,
      wednesdaySwitch,
      thursdaySwitch,
      fridaySwitch,
      mondayTime,
      tuesdayTime,
      wednesdayTime,
      thursdayTime,
      fridayTime,
      reminderPerPkg,
      timeBetweenReminders,
      selectedTimeZone,
      selectedTenant,
      csv_upload,
      reply_to,
      repltyToErrors,
    } = this.state;

    const { t, userRole, addBuilding, updatePreferences } = this.props;

    const nineAm = Date.parse('01/01/2011 09:00');
    const sixPm = Date.parse('01/01/2011 18:30');
    if (nameError) this.setState({ warning: t('common.error.nameValidation') });
    else if (addressError) this.setState({ warning: t('common.error.addressValidation') });
    else if (name.length === 0) {
      this.setState({ warning: t('buildings.nameNotEmpty') });
    } else if (name.length < 2) {
      this.setState({ warning: t('buildings.nameMinimumCharacter') });
    } else if (code.length === 0) {
      this.setState({ warning: t('buildings.codeNotEmpty') });
    } else if (!(country instanceof Object)) {
      this.setState({ warning: t('buildings.countryNotEmpty') });
    } else if (!(state instanceof Object)) {
      this.setState({ warning: t('buildings.stateNotEmpty') });
    } else if (!(city instanceof Object)) {
      this.setState({ warning: t('buildings.cityNotEmpty') });
    } else if (
      Date.parse(`01/01/2011 ${mondayTime}`) < nineAm ||
      Date.parse(`01/01/2011 ${mondayTime}`) > sixPm ||
      Date.parse(`01/01/2011 ${tuesdayTime}`) < nineAm ||
      Date.parse(`01/01/2011 ${tuesdayTime}`) > sixPm ||
      Date.parse(`01/01/2011 ${wednesdayTime}`) < nineAm ||
      Date.parse(`01/01/2011 ${wednesdayTime}`) > sixPm ||
      Date.parse(`01/01/2011 ${thursdayTime}`) < nineAm ||
      Date.parse(`01/01/2011 ${thursdayTime}`) > sixPm ||
      Date.parse(`01/01/2011 ${fridayTime}`) < nineAm ||
      Date.parse(`01/01/2011 ${fridayTime}`) > sixPm
    ) {
      this.setState({ warning: t('settings.chooseBetweenTime') });
    } else if (
      name.length > 1 &&
      code.length > 0 &&
      country instanceof Object &&
      state instanceof Object &&
      city instanceof Object &&
      !nameError &&
      !addressError
    ) {
      const building = {
        name,
        code,
        address,
        city: city.city_id,
        state: state.state_id,
        country: country.country_id,
        time_zone: selectedTimeZone.substr(5, 6),
        tenant: userRole === 'super_admin' ? selectedTenant.tenant_id : null,
        csv_upload: csv_upload.value,
      };
      if (reply_to.length && !repltyToErrors) {
        building.reply_to = reply_to;
      }
      this.setState({ buildingCreating: true, errorCreating: null });
      addBuilding(building)
        .then(response => {
          const pref = {
            mondaySwitch,
            tuesdaySwitch,
            wednesdaySwitch,
            thursdaySwitch,
            fridaySwitch,
            mondayTime,
            tuesdayTime,
            wednesdayTime,
            thursdayTime,
            fridayTime,
            reminderPerPkg: reminderPerPkg.value,
            timeBetweenReminders: timeBetweenReminders.value,
          };
          this.setState({
            buildingCreated: true,
            buildingId: response.data.building_id,
          });
          let {
            getBuildingHierarchy, dispatch,
          } = this.props;
          getBuildingHierarchy()
            .then(locations => {
              const { countries } = locations;
              dispatch(setCountries(countries));
            });
          // .catch(error => {
          // });
          updatePreferences(pref, response.data.building_id)
            .then(() =>
              this.setState({
                buildingCreated: false,
                prefsCreated: true,
                buildingCreating: false,
              }),
            )
            .catch(error =>
              this.setState({
                errorCreating:
                  error.message || this.props.t('common.error.something'),
                buildingCreating: false,
              }),
            );
        })
        .catch(err =>
          this.setState({
            errorCreating: err.message.includes('Building code already exists.')
              ? this.props.t('common.error.buildindCodeAlreadyExists')
              : err.message || this.props.t('common.error.something'),
            buildingCreating: false,
          }),
        );
    }
  };

  closeAlert = () => {
    if (this.state.buildingCreated) this.setState({ redirect: true });
    this.setState({
      warning: null,
      errorCreating: null,
      prefsCreated: false,
      buildingCreated: false,
    });
  };

  hourSelection = (state, h) => {
    let prevState = this.state[state];
    prevState = `${h}:${prevState.split(':')[1]}`;
    this.setState({ [state]: prevState });
  };

  minSelection = (state, m) => {
    let prevState = this.state[state];
    prevState = `${prevState.split(':')[0]}:${m}`;
    this.setState({ [state]: prevState });
  };

  handleTenantChange = e => {
    const value = JSON.parse(e.target.value);
    this.setState({
      selectedTenant: {
        tenant_name: value.tenant_name,
        tenant_id: value.tenant_id,
      },
    });
  }

  render() {
    const {
      code,
      country,
      state,
      city,
      loadingLocations,
      loadingStates,
      loadingCities,
      buildingCreating,
      errorCreating,
      buildingCreated,
      prefsCreated,
      buildingId,
      warning,
      nameError,
      addressError,
      selectedTenant,
      repltyToErrors,
      encryptedEmail,
    } = this.state;
    if (encryptedEmail) {
      this.props.history.replace(encryptedEmail);
    }
    const { t, userRole, buildings } = this.props;
    const tenants = buildings.map(eachTenant => {
      const { tenant_name, contact_email, tenant_id } = eachTenant;
      return {
        contact_email,
        tenant_id,
        tenant_name,
      };
    });

    if (prefsCreated) return <Redirect to={`/building_detail/${buildingId}`} />;
    return !userRole ? (
      <VerticallyCenter>
        <AlignCenter>
          <CircularProgress className="blue" size={30} />
        </AlignCenter>
      </VerticallyCenter>
    ) : (userRole !== 'admin' && userRole !== 'super_admin') ? (
      <NotAuthorized />
    ) : (
      <Fragment>
        <Grid container className="container min-width-100p">
          {errorCreating && (
          <Alert
            variant="error"
            open
            message={errorCreating}
            onClose={this.closeAlert}
          />
              )}
          {(buildingCreated || prefsCreated) && (
          <Alert
            variant="success"
            open
            message={
                    (buildingCreating && t('buildings.buildingPrefCreated')) ||
                    (prefsCreated && t('buildings.created'))
                  }
            onClose={this.closeAlert}
          />
              )}
          <Grid item xs={12} sm={12} md={12}>
            <div
              style={
                    this.props.width === 'xs'
                      ? {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }
                      : { display: 'flex', justifyContent: 'space-between' }
                  }
            >

              <div>
                <div style={
                      this.props.width === 'xs'
                        ? {
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '12px 0px',
                          // justifyContent: 'space-between',
                        } : { display: 'flex' }
                    }
                >
                  <h3 style={{ marginRight: 10, width: 140 }} className="page-title">{t('buildings.add')}</h3>
                  {
                        userRole === 'super_admin' &&
                        <TenantSelector
                          t={t}
                          tenants={tenants}
                          selectedTenant={selectedTenant}
                          handleOnchange={this.handleTenantChange}
                        />
                      }
                </div>
                <h3 className="page-subhead subhead">
                  <Link to="/buildings">{t('tables.building')}</Link>
                  <ArrowRightIcon />
                  {t('buildings.add')}
                </h3>
              </div>
              <BlueButton
                width={160}
                disabled={
                      buildingCreating ||
                      this.state.name.length < 2 ||
                      nameError ||
                      addressError ||
                      loadingLocations ||
                      loadingStates ||
                      loadingCities ||
                      country.length === 0 ||
                      state.length === 0 ||
                      city.length === 0 ||
                      code.length === 0 ||
                      repltyToErrors
                    }
                style={
                      this.props.width === 'xs' ?
                        {
                          margin: '12px 0px',
                        } : {}
                    }
                loading={buildingCreating}
                onClick={this.saveBuilding}
              >
                {t('buildings.saveBuilding')}
              </BlueButton>

              {warning && (
              <WarningAlert
                variant="error"
                onClose={this.closeAlert}
                message={warning}
                open
              />
                  )}
            </div>
          </Grid>
          <AddBuildingComp
            {...this.state}
            {...this.props}
            hourSelection={this.hourSelection}
            minSelection={this.minSelection}
            handleChange={this.handleChange}
            handleChangeTimeZone={this.handleChangeTimeZone}
            handleSwitching={this.handleSwitching}
            selectItem={(name, item) => this.selectItem(name, item)}
            handleSelect={(name, value) => this.handleSelect(name, value)}
          />
        </Grid>
        <Footer />
      </Fragment>
        );
  }
}

export default connect(state => ({
  buildings: state.allBuildings,
  countries: state.countries,
  userRole: state.userRole,
}))(withWidth()(withRouter(AddBuilding)));
