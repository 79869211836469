import { EXPORT_BUILDINGS } from '../actions/tableExportAction';

const initialState = false;

export default function (state = initialState, action) {
  switch (action.type) {
    case EXPORT_BUILDINGS: {
      state = action.exportStatus;
      return state;
    }
    default:
      return state;
  }
}

