/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  Grid,
  Paper,
  CircularProgress,
  FormHelperText,
  MenuItem,
  withStyles,
  Tooltip
} from '@material-ui/core';
import { CardBody } from 'reactstrap';
import { Margin } from '../../../UI/ResponsiveRelated';
import './buildings.css';
import MailroomList from '../../BuildingDetail/components/MailroomList';
import AssociateList from '../../BuildingDetail/components/AssociateList';
import Alert from '../../../UI/Poper';
import { timeZonesFormat } from '../../../../util/utility.functions';
import timeZones from '../../../../util/timeZones';
import Preferences from '../../BuildingDetail/components/Preferences';
import SearchDropdown from '../../../UI/SearchDropdown/SearchDropdown';
import { buildingStyles as styles } from '../../../../util/StyleConsts';
import {
  CustomTextField,
  CustomInput,
  CustomInputLabel,
} from '../../../UI/Input';

const BuildingsIconInactive = require('../../../Layout/sidebar/BuildingsIconInactive.png');

class AddBuilding extends Component {
  state = { open: false, addBuildingFirst: false };

  onClose = () => this.setState({ open: false });

  openDialog = () => this.setState({ open: !this.state.open });

  doNothing = () =>
    this.setState({ addBuildingFirst: !this.state.addBuildingFirst });

  render() {
    const {
      t,
      name,
      code,
      address,
      city,
      state,
      country,
      handleChange,
      nameError,
      addressError,
      style,
      selectedTimeZone,
      handleChangeTimeZone,
      loadingLocations,
      loadingStates,
      loadingCities,
      errorLocations,
      errorStates,
      errorCities,
      locations,
      states,
      cities,
      width,
      selectItem,
      csv_upload,
      reply_to,
      repltyToErrors
    } = this.props;
    const { addBuildingFirst } = this.state;
    const inputStyle = { fontSize: 13, color: '#000' };
    const labelStyle = { fontSize: 13 };
    const FormHelperStyle = { color: '#FF1E59' };
    let csvOptions =
      [
        {
          label: t('email.yes'),
          value: true
        },
        {
          label: t('associates.no'),
          value: false
        },
      ];


    return (
      <Fragment>
        <Grid
          style={{ paddingRight: width === 'lg' || width === 'xl' ? 15 : 0 }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
        >
          <Paper
            style={{
              ...style.common.oy_a,
              height: width === 'sm' || width === 'xs' ? 710 : 490,
              marginBottom: 15,
            }}
            className="just-paper"
          >
            <form onSubmit={e => e.preventDefault()}>
              {addBuildingFirst && (
                <Alert
                  variant="error"
                  open
                  message={t('buildings.addBuildingFirst')}
                  onClose={this.doNothing}
                />
              )}
              <CardBody>
                <div className="flex card__title">
                  <img style={{ borderRadius: 5, height: 20, width: 20 }} src={BuildingsIconInactive} alt="" />
                  &nbsp;
                  <h5 className="bold-text">{t('buildings.info')}</h5>
                </div>
                <Grid
                  container
                  direction="row"
                  jusify="space-between"
                  style={{ justifyContent: 'space-between' }}
                >
                  <Grid item md={5} xs={12} className="height-60" style={{ marginTop: 19 }}>
                    <CustomTextField
                      fullWidth
                      required
                      label={t('tables.name')}
                      value={name}
                      onChange={handleChange('name')}
                      InputProps={{ style: inputStyle }}
                      InputLabelProps={{ style: labelStyle }}
                      className="height-50px"
                    />
                    {name.length > 1 &&
                      <FormHelperText style={FormHelperStyle}>{nameError ? t('common.error.nameValidation') : ''}</FormHelperText>}
                    {name.length < 2 && name.length > 0 &&
                      <FormHelperText style={FormHelperStyle}>{t('common.error.nameLength')}</FormHelperText>}
                  </Grid>
                  {/* <Grid item md={5} xs={12} className="height-60" style={{ marginTop: 19 }}>
                    <CustomTextField
                      fullWidth
                      label={t('tables.email')}
                      value={email}
                      onChange={handleChange('email')}
                      InputProps={{ style: inputStyle }}
                      InputLabelProps={{ style: labelStyle }}
                      FormHelperTextProps={{ style: FormHelperStyle }}
                      helperText={emailError ? t('emailValidation') : ''}
                      className="height-50px"
                    />
                  </Grid> */}
                  <Grid item md={5} xs={12} className="height-60" style={{ marginTop: 19 }}>
                    <CustomTextField
                      fullWidth
                      required
                      label={t('tables.code')}
                      value={code}
                      onChange={handleChange('code')}
                      InputProps={{ style: inputStyle }}
                      InputLabelProps={{ style: labelStyle }}
                    />
                  </Grid>
                  <Grid item md={5} xs={12} className="height-60" style={{ marginTop: 19 }}>
                    <CustomTextField
                      fullWidth
                      label={t('tables.address')}
                      value={address}
                      onChange={handleChange('address')}
                      InputProps={{ style: inputStyle }}
                      InputLabelProps={{ style: labelStyle }}
                      FormHelperTextProps={{ style: FormHelperStyle }}
                      helperText={addressError ? t('common.error.addressValidation') : ''}
                    />
                  </Grid>
                  <Grid item md={5} xs={12} style={{ marginTop: 19, position: 'relative' }}>
                    {loadingLocations ? (
                      <Margin top={20}>
                        <CircularProgress className="blue" size={15} />{' '}
                        {t('tables.loadingCountries')}
                      </Margin>
                    ) : (
                        <FormControl className="height-60" required fullWidth>
                          <CustomInputLabel
                            style={labelStyle}
                            shrink={country instanceof Object}
                          >
                            {t('buildings.country')}
                          </CustomInputLabel>
                          <SearchDropdown
                            loading={locations.length === 0}
                            list={locations}
                            selection={country}
                            selectItem={item => selectItem('country', item)}
                          />
                          {errorLocations && (
                            <FormHelperText>{errorLocations}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                  </Grid>
                  <Grid item md={5} xs={12} style={{ marginTop: 19 }}>
                    {loadingStates ? (
                      <Margin top={20}>
                        <CircularProgress className="blue" size={15} />{' '}
                        {t('buildings.loadingStates')}
                      </Margin>
                    ) : (
                        <FormControl className="height-60" required fullWidth>
                          <CustomInputLabel
                            style={labelStyle}
                            shrink={state instanceof Object}
                          >
                            {t('buildings.state')}
                          </CustomInputLabel>
                          <SearchDropdown
                            loading={states.length === 0}
                            list={states}
                            selection={state}
                            selectItem={item => selectItem('state', item)}
                          />
                          <FormHelperText>
                            {country instanceof Object
                              ? errorStates
                              : t('tables.selectCountryFirst')}
                          </FormHelperText>
                        </FormControl>
                      )}
                  </Grid>
                  <Grid item md={5} xs={12} style={{ marginTop: 19 }}>
                    {loadingCities ? (
                      <Margin top={20}>
                        <CircularProgress className="blue" size={15} />{' '}
                        {t('buildings.loadingCities')}
                      </Margin>
                    ) : (
                        <FormControl className="height-60" required fullWidth>
                          <CustomInputLabel
                            style={labelStyle}
                            shrink={city instanceof Object}
                          >
                            {t('buildings.city')}
                          </CustomInputLabel>
                          <SearchDropdown
                            loading={cities.length === 0}
                            list={cities}
                            selection={city}
                            selectItem={item => selectItem('city', item)}
                          />
                          <FormHelperText>
                            {state instanceof Object
                              ? errorCities
                              : t('tables.selectStateFirst')}
                          </FormHelperText>
                        </FormControl>
                      )}
                  </Grid>
                  <Grid item md={5} xs={12} style={{ marginTop: 19 }}>
                    <FormControl className="height-60" required fullWidth>
                      <CustomInputLabel>
                        {' '}
                        {t('buildings.timeZone')}{' '}
                      </CustomInputLabel>
                      <Select
                        value={selectedTimeZone}
                        onChange={handleChangeTimeZone('timeZone')}
                        style={{ font: 'inherit' }}
                        input={<CustomInput />}
                      >
                        {timeZones.map(item => (
                          <MenuItem value={timeZonesFormat(item)}>
                            {timeZonesFormat(item)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12} style={{ marginTop: 19 }}>
                    <FormControl className="height-60" required fullWidth>
                      <CustomInputLabel style={{ display: "flex" }}>
                        {' '}
                        {t('common.csvUpload')}{' '}
                      </CustomInputLabel>
                      <Select
                        value={csv_upload.value}
                        onChange={handleChange('csv_upload')}
                        style={{ font: 'inherit' }}
                        input={<CustomInput />}
                      >
                        {csvOptions.map(item => (
                          <MenuItem value={item.value} key={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12} className="height-60" style={{ marginTop: 24 }}>
                    <Tooltip title={t('buildings.replyToTooltip')}>
                      <CustomTextField
                        fullWidth
                        label={t('buildings.replyToFiled')}
                        value={reply_to}
                        onChange={handleChange('reply_to')}
                        InputProps={{ style: inputStyle }}
                        InputLabelProps={{ style: labelStyle }}
                        FormHelperTextProps={{ style: FormHelperStyle }}
                        helperText={repltyToErrors ? t('emailValidation') : ''}
                        className="height-50px"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </CardBody>
            </form>
          </Paper>
        </Grid>
        <MailroomList
          t={t}
          width={width}
          style={style}
          height={490}
          maxHeight={width === 'xs' && 405}
          mailrooms={[]}
          mailroomsLoading={false}
          mailroomsError={null}
          openCreateMailroomForm={this.doNothing}
        />
        <AssociateList
          t={t}
          width={width}
          style={style}
          users={[]}
          loading={false}
          usersLoading={false}
          usersError={null}
          openCreateAssociateForm={this.doNothing}
        />
        <Preferences {...this.props} />
      </Fragment>
    );
  }
}

AddBuilding.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  locations: PropTypes.node.isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  errorLocations: PropTypes.string.isRequired,
  loadingStates: PropTypes.bool.isRequired,
  loadingCities: PropTypes.bool.isRequired,
  errorStates: PropTypes.string.isRequired,
  errorCities: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  selectedTimeZone: PropTypes.node.isRequired,
  reminderPerPkg: PropTypes.number.isRequired,
  timeBetweenReminders: PropTypes.number.isRequired,
  mondaySwitch: PropTypes.bool.isRequired,
  tuesdaySwitch: PropTypes.bool.isRequired,
  wednesdaySwitch: PropTypes.bool.isRequired,
  thursdaySwitch: PropTypes.bool.isRequired,
  fridaySwitch: PropTypes.bool.isRequired,
  mondayTime: PropTypes.string.isRequired,
  tuesdayTime: PropTypes.string.isRequired,
  wednesdayTime: PropTypes.string.isRequired,
  thursdayTime: PropTypes.string.isRequired,
  fridayTime: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSwitching: PropTypes.func.isRequired,
  nameError: PropTypes.string.isRequired,
  addressError: PropTypes.string.isRequired,
  handleChangeTimeZone: PropTypes.func.isRequired,
  states: PropTypes.node.isRequired,
  cities: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
  classes: PropTypes.node.isRequired,
  mondayErr: PropTypes.bool.isRequired,
  tuesdayErr: PropTypes.bool.isRequired,
  wednesdayErr: PropTypes.bool.isRequired,
  thursdayErr: PropTypes.bool.isRequired,
  fridayErr: PropTypes.bool.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  selectItem: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddBuilding);
