import React from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from './Styled';

function ContactSalesResponse(props) {
  return (
    <div>
      <Dialog keepMounted maxWidth="md" {...props}>
        <DialogContent className="pm-modal-container">
          <h3 className="pm-heading">
            Contact {' '}
            <span className="pm-heading-green">
              Sales
            </span>
          </h3>
          <p className="pm-description">
          Someone from our team shall be in touch with you shortly.
          </p>
          <div className="pm-single-button-section">
            <Button
              className="pm-cp-upgrade-btn"
              onClick={() => props.onClose()}
            >
              Okay
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withRouter(ContactSalesResponse);
