/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  FormControl,
  Grid,
  FormHelperText,
  Button,
  IconButton,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { CustomInput, CustomInputLabel } from '../../../UI/Input';
import { getUserEmailURL, getDataFromAWSAttributes } from '../../../../util/application.utils';
import { validateEmail } from '../../../../util/index';
import { decryptEmailWithAES } from '../../../../util/application.utils';
import {
  signin,
  confirmUserForceReset,
  stepUpdateOnboarding,
} from '../../../../server/auth';
import { CallTrigger } from '../../../../util/Triggers/function';
import { CLA } from '../../../../util/Triggers/triggerNames';

class SetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: getUserEmailURL(),
      tempPass: '',
      showTempPass: false,
      newPass: '',
      showNewPass: false,
      confirmPass: '',
      showConfirmPass: false,
      error: '',
      wrongCreds: false,
      passwordError: null,
      confirmPassError: false,
      waiting: false,
      clickContinue: false,
      errors: {
        number: false,
        specialCharacter: false,
        upperCase: false,
        lowerCase: false,
        eightCharacter: false,
      },
      otpError: false,
    };
  }

  async componentDidMount() {
    let email = null;
    try {
      email = await decryptEmailWithAES(getUserEmailURL());
      this.setState({ email });
    } catch (error) {
      console.error(error);
    }
    if (!validateEmail(email)) {
      this.props.history.replace('/404');
    } else {
      CallTrigger(CLA.SET_PASS.Set_Password_screen, { Previous_event: '' });
    }
    this.props.handleDisableNext(true);
    this.props.handleDisablePrev(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.disableSubmit !== prevState.disableSubmit) {
      this.props.handleDisableNext(this.state.disableSubmit);
    }
    if (
      this.props.actionBit === true &&
      this.props.actionBit !== prevProps.actionBit
    ) {
      this.props.unsetActionBit();
      document.getElementById('setPasswordSubmit').click();
    }
  }

  handleState = (state) => (e) => {
    // this.setState({ [state]: event.target.value });
    if (state === 'newPass') {
      const error = this.validatePass(e.target.value);
      this.setState({
        errors: error,
      });
    }
    if (state === 'tempPass') {
      this.setState({ otpError: false });
    }
    this.setState(
      {
        [state]: e.target.value,
        error: '',
        wrongCreds: false,
        passwordError:
          state === 'confirmPass' ? this.state.passwordError : null,
      },
      () => {
        const {
          newPass,
          confirmPass,
          error,
          passwordError,
          showConfirmPass,
          waiting,
          confirmPassError,
          tempPass,
        } = this.state;
        if (
          newPass.length > 0 &&
          confirmPass.length > 0 &&
          newPass !== confirmPass
        ) {
          this.setState({ confirmPassError: true }, () => {
            this.props.handleDisableNext(
              waiting ||
                error ||
                passwordError ||
                newPass.length === 0 ||
                confirmPass.length === 0 ||
                showConfirmPass.length === 0 ||
                this.state.confirmPassError ||
                tempPass.length === 0
            );
          });
        } else {
          this.setState({ confirmPassError: false }, () => {
            this.props.handleDisableNext(
              waiting ||
                error ||
                passwordError ||
                newPass.length === 0 ||
                confirmPass.length === 0 ||
                showConfirmPass.length === 0 ||
                this.state.confirmPassError ||
                tempPass.length === 0
            );
          });
        }
        this.props.handleDisableNext(
          waiting ||
            error ||
            passwordError ||
            newPass.length === 0 ||
            confirmPass.length === 0 ||
            showConfirmPass.length === 0 ||
            confirmPassError ||
            tempPass.length === 0
        );
      }
    );
  };

  validatePass = (newPassword) => {
    return {
      number: this.hasDigits(newPassword),
      specialCharacter: this.hasSpecialCharacter(newPassword),
      upperCase: this.hasUppercase(newPassword),
      lowerCase: this.hasLowercase(newPassword),
      eightCharacter: this.hasEightLength(newPassword),
    };
  };

  togglePassword = (state, value) => {
    this.setState({ [state]: value });
  };

  handleSubmit = () => {
    CallTrigger(CLA.SET_PASS.Set_Password_continue_bclick, {
      Previous_event: '',
    });
    const { tempPass, newPass, email } = this.state;
    this.setState(
      {
        waiting: true,
        clickContinue: true,
      },
      () => {
        CallTrigger(CLA.SET_PASS.Set_Password_verification_attempt, {
          Previous_event: '',
        });
        confirmUserForceReset(email, tempPass, newPass, 'WEBSITE', true)
          .then((res) => {
            const userData = getDataFromAWSAttributes(res.UserAttributes);
            const recipientName = new Array(userData[0].name);
            const recipientEmail = new Array(userData[1].email);
            localStorage.setItem('userName', userData[0].name);
            localStorage.setItem(
              'onBoarding_email',
              userData[1].email
            );
            localStorage.setItem('onBoardingId', res.Username);
            localStorage.setItem('recipientsNames', JSON.stringify(recipientName));
            localStorage.setItem('recipientsEmails', JSON.stringify(recipientEmail));
            CallTrigger(CLA.SET_PASS.Set_Password_verification_success, {
              Previous_event: '',
            });
            signin(email, newPass)
              .then((resp) => {
                stepUpdateOnboarding('1')
                  .then(() => {
                    this.props.next();
                  })
                  .catch(() => {
                    this.setState({
                      error: 'Unable to continue',
                      waiting: false,
                    });
                  });
              })
              .catch(() => {
                this.setState({
                  error: 'Unable to make session',
                });
              });
          })
          .catch((err) => {
            const { setError } = this.props;
            if (err.message === '/dashboard') {
              this.props.history.replace('/dashboard');
            }
            setError(err.message.split(': ')[1]);

            this.setState({
              waiting: false,
              wrongCreds: false,
              error:
                err.code === 'NotAuthorizedException'
                  ? 'Wrong Temporary Password'
                  : err.response
                  ? err.response.data.message
                  : err.message,
            });
          });
      }
    );
  };

  hasDigits = (string) => /\d/.test(string);
  hasUppercase = (string) => /[A-Z]/.test(string);
  hasLowercase = (string) => /[a-z]/.test(string);
  hasEightLength = (string) => (string.length >= 6 ? true : false);
  hasSpecialCharacter = (string) =>
    /.*[/!`~_;'+=@#$%^&*()\[\]\\,.?":{}|<>-]/.test(string);

  render() {
    const {
      tempPass,
      showTempPass,
      newPass,
      showNewPass,
      confirmPass,
      showConfirmPass,
      passwordError,
      confirmPassError,
      wrongCreds,
      error,
      waiting,
      email,
      clickContinue,
      errors,
      otpError,
    } = this.state;
    const index = Object.values(errors).indexOf(false);
    const disabled =
      waiting ||
      passwordError ||
      newPass.length === 0 ||
      confirmPass.length === 0 ||
      otpError ||
      showConfirmPass.length === 0 ||
      confirmPassError ||
      tempPass.length === 0 ||
      newPass !== confirmPass ||
      index !== -1;
    const { t } = this.props;
    const btnClass = !disabled
      ? 'onBoard-page-btn margin-top-24px'
      : 'onBoard-page-btn-disabled margin-top-24px';
    const btnEnabled = tempPass && tempPass !== '';

    return (
      <div className="onBoard-page-container-welcome">
        <Grid container className="container" style={{ minWidth: '100%' }}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <h3 className="onboarding-welcome-title">
              {t('onboarding.setPassword.title')}
            </h3>
            <p className="onboarding-welcome-description"></p>
          </Grid>
          <form
            style={{ width: '100%', marginTop: 50 }}
            onSubmit={() => this.handleSubmit()}
          >
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormControl
                required
                fullWidth
                className="height-75"
                onBlur={() => {
                  if (tempPass.length > 0) {
                    this.setState({ otpError: tempPass.length < 6 });
                  }
                }}
              >
                <CustomInputLabel
                  htmlFor="adornment-password"
                  shrink
                  className="font-size-14"
                >
                  {t('onboarding.setPassword.tempPass')}
                </CustomInputLabel>
                <CustomInput
                  type={showTempPass ? 'text' : 'password'}
                  value={tempPass}
                  onChange={this.handleState('tempPass')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.togglePassword('showTempPass', !showTempPass)
                        }
                      >
                        {showTempPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {otpError && (
                  <FormHelperText className="font-size-10 red">
                    {t('onboarding.setPassword.tempPassError')}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormControl required fullWidth className="height-75 mr-2">
                    <CustomInputLabel
                      htmlFor="adornment-password"
                      shrink
                      className="font-size-14"
                    >
                      {t('onboarding.setPassword.newPass')}
                    </CustomInputLabel>
                    <CustomInput
                      type={showNewPass ? 'text' : 'password'}
                      value={newPass}
                      onChange={this.handleState('newPass')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              this.togglePassword('showNewPass', !showNewPass)
                            }
                          >
                            {showNewPass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {passwordError && (
                      <FormHelperText className="font-size-10 red">
                        {t('passwordValidation')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormControl required fullWidth className="height-75">
                    <CustomInputLabel
                      htmlFor="adornment-password"
                      shrink
                      className="font-size-14"
                    >
                      {t('onboarding.setPassword.confirmPass')}
                    </CustomInputLabel>
                    <CustomInput
                      type={showConfirmPass ? 'text' : 'password'}
                      value={confirmPass}
                      onChange={this.handleState('confirmPass')}
                      danger={confirmPassError || wrongCreds}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              this.togglePassword(
                                'showConfirmPass',
                                !showConfirmPass
                              )
                            }
                          >
                            {showConfirmPass ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {confirmPassError && (
                      <FormHelperText className="font-size-10 red">
                        {t('passwordValidation2')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <PasswordValidator
                  password={newPass}
                  errors={errors}
                  clickContinue={clickContinue}
                  t={t}
                />
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Button
              type="submit"
              id="setPasswordSubmit"
              className={btnClass}
              disabled={disabled}
              onClick={this.handleSubmit}
            >
              {t('onboarding.setPassword.button')}
              {waiting && (
                <div className="ml-2">
                  <CircularProgress size={15} className="white" />
                </div>
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SetPassword.propTypes = {
  next: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleDisableNext: PropTypes.func.isRequired,
  handleDisablePrev: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  actionBit: PropTypes.bool.isRequired,
  unsetActionBit: PropTypes.func.isRequired,
  // history: PropTypes.node.isRequired,
};

export default withRouter(connect()(SetPassword));

const PasswordValidator = ({ password, errors, t }) => {
  return (
    <div className="reset-check-conditions-section ">
      {password.length ? (
        <ul className="">
          <li className="reset-check-list-item">
            {t('onboarding.setPassword.passMustContain')}
          </li>
          <li className="reset-check-list-item">
            {!errors.eightCharacter ? (
              <CancelIcon
                fontSizeSmall
                style={{ color: '#DE350B', marginRight: 8 }}
              />
            ) : (
              <CheckCircleIcon style={{ color: '#00875A', marginRight: 8 }} />
            )}
            <span>{t('onboarding.setPassword.passeight')}</span>
          </li>
          <li className="reset-check-list-item">
            {!errors.number ? (
              <CancelIcon
                fontSizeSmall
                style={{ color: '#DE350B', marginRight: 8 }}
              />
            ) : (
              <CheckCircleIcon style={{ color: '#00875A', marginRight: 8 }} />
            )}
            <span>{t('onboarding.setPassword.passOneNum')}</span>
          </li>
          <li className="reset-check-list-item">
            {!errors.specialCharacter ? (
              <CancelIcon
                fontSizeSmall
                style={{ color: '#DE350B', marginRight: 8 }}
              />
            ) : (
              <CheckCircleIcon style={{ color: '#00875A', marginRight: 8 }} />
            )}
            <span>{t('onboarding.setPassword.passOneSp')}</span>
          </li>
          <li className="reset-check-list-item">
            {!errors.lowerCase || !errors.upperCase ? (
              <CancelIcon
                fontSizeSmall
                style={{ color: '#DE350B', marginRight: 8 }}
              />
            ) : (
              <CheckCircleIcon style={{ color: '#00875A', marginRight: 8 }} />
            )}
            <span>{t('onboarding.setPassword.passMix')}</span>
          </li>
        </ul>
      ) : (
        <ul className="">
          <li className="reset-check-list-item">
            {t('onboarding.setPassword.passMustContain')}
          </li>
          <li
            className="reset-check-list-item"
            style={{ color: errors.eightCharacter ? 'green' : '' }}
          >
            <RadioButtonUncheckedIcon style={{ marginRight: 8 }} />
            <span>{t('onboarding.setPassword.passeight')}</span>
          </li>
          <li
            className="reset-check-list-item"
            style={{ color: errors.number ? 'green' : '' }}
          >
            <RadioButtonUncheckedIcon style={{ marginRight: 8 }} />
            <span>{t('onboarding.setPassword.passOneNum')}</span>
          </li>
          <li
            className="reset-check-list-item"
            style={{ color: errors.specialCharacter ? 'green' : '' }}
          >
            <RadioButtonUncheckedIcon style={{ marginRight: 8 }} />
            <span>{t('onboarding.setPassword.passOneSp')}</span>
          </li>
          <li
            className="reset-check-list-item"
            style={{
              color: errors.lowerCase || errors.upperCase ? 'green' : '',
            }}
          >
            <RadioButtonUncheckedIcon style={{ marginRight: 8 }} />
            <span>{t('onboarding.setPassword.passMix')}</span>
          </li>
        </ul>
      )}
    </div>
  );
};
