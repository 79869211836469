/* eslint-disable camelcase */
import common_en from './en/common.json';
import common_de from './de/common.json';
import common_id from './id/common.json';
import common_ja from './ja/common.json';
import common_ko from './ko/common.json';
import common_ne from './ne/common.json';
import common_po from './po/common.json';
import common_pt from './pt/common.json';
import common_ru from './ru/common.json';
import common_sp from './sp/common.json';
import common_zh from './zh/common.json';

export default {
  en: {
    common: common_en,
  },
  de: {
    common: common_de,
  },
  id: {
    common: common_id,
  },
  ko: {
    common: common_ko,
  },
  ja: {
    common: common_ja,
  },
  ne: {
    common: common_ne,
  },
  po: {
    common: common_po,
  },
  pt: {
    common: common_pt,
  },
  ru: {
    common: common_ru,
  },
  sp: {
    common: common_sp,
  },
  zh: {
    common: common_zh,
  },
};
