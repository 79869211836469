import { SET_PAYMENT_DETAILS, SET_PAYMENT_DETAILS_LOADING } from '../actions/paymentsAction';

const initialState = {
  details: {},
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENT_DETAILS: {
      return {
        ...state,
        details: action.paymentsDetails.data,
        loading: action.paymentsDetails.loading
      }; 
    }
    case SET_PAYMENT_DETAILS_LOADING: {
      return {
        ...state,
        loading: action.loading
      }; 
    }
    default: return state;
  }
}
