import React from 'react';
// import { Link } from 'react-router-dom';
// import csv from '../../../../assets/images/sample-members.png';
// import Button from '../../../UI/BlueButton';
import CarousalImages from './CarousalImages';

const DownloadCSVSection = ({ t, downloadLink, samplecsv }) => (
  <>
    <div className="top-header-btn-section mb-3 ">
      <h4 className="m-0">{t('members.instructions')}</h4>
      <a
        style={{ color: '#1DA8EB', fontWeight: 600 }}
        component={downloadLink}
        href={samplecsv}
        download
        capitalize
        width={180}
      >
        {t('members.downloadCSV')}
      </a>
    </div>
    {/* <p>CSV template provides the format in which recipient information has to be uploaded.</p>
        <img className="csvImage" src={csv} alt="csv" /> */}
    <CarousalImages />
    <ul style={{ listStyleType: 'none', marginTop: 24 }}>
      <li>
        <p>{t('members.downloadCSVText1')}</p>
      </li>
      <li>
        <p>
          <strong>{t('members.group')}</strong> -{' '}
          {t('members.downloadCSVText2')}
        </p>
      </li>
      <li>
        <p>
          <strong>{t('members.unit')}</strong> - {t('members.downloadCSVText3')}
        </p>
      </li>
      <li>
        <p>
          <strong>Home Address</strong> - Can be the home address for your
          recipient, this is an optional field.
        </p>
      </li>
      <li>
        <p>
          <strong>Phone Number</strong> - Can be the phone number for your
          recipient, this is an optional field.
        </p>
      </li>
    </ul>
  </>
);
export default DownloadCSVSection;
