import { SET_DATEFILTER, CLEAR_DATEFILTER } from '../actions/dateFilterActions';

const initialState = { fromDate: undefined, toDate: undefined };

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_DATEFILTER: {
            let dates = action.payload;
            const filterDates = {
                ...state,
                fromDate: dates.fromDate,
                toDate: dates.toDate
            };
            localStorage.px_dateFilter = JSON.stringify(filterDates);
            return filterDates;
        }
        case CLEAR_DATEFILTER: {
            let dates = initialState;
            localStorage.removeItem('px_dateFilter');
            return {
                ...state,
                fromDate: dates.fromDate,
                toDate: dates.toDate
            };  
        }
        default: return state;
    }
};
