import React from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from './Styled';

function PaymentSuccessful(props) {
  const { loader, makeCardDefault, cardLastDigits } = props;
  return (
    <div>
      <Dialog
        keepMounted
        maxWidth="sm"
        onClose={() => props.onClose()}
        {...props}
      >
        <DialogContent className="pm-modal-container">
          <h3 className="pm-heading">
            <span className="pm-heading-green">Confirmation</span> Required
          </h3>
          <p className="pm-description">
            Are you sure you want to set default the requested card{' '}
            <b className="pm-heading-green">**** {cardLastDigits}</b>?
          </p>
          <div className="pm-single-button-section">
            <Button
              className="pm-cp-upgrade-btn"
              onClick={() => !loader && makeCardDefault()}
              disabled={loader}
            >
              Set Default{' '}
              {loader && <CircularProgress size={15} className="white" />}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withRouter(PaymentSuccessful);
