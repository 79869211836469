/* eslint-disable */

import React from 'react';
import { withRouter } from 'react-router-dom'; 
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
// import { CircularProgress } from '@material-ui/core';
import { Menu, Fade, IconButton } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import { AlignCenter, VerticallyCenter, Ripple } from '../../../UI/ResponsiveRelated';
import { getNotifications } from '../../../../server/notifications';
import BoxSvg from '../../../../assets/images/box.svg';
// import ButterflyImg from '../../../../assets/butterfly.jpeg';
import NotificationsPagination from './NotificationsPagination';
import { serializeURL } from "../../../../util/application.utils";
import CachedIcon from '@material-ui/icons/Cached';
import { refreshNotificationsCenterAction, refreshNotificationsPopupAction } from '../../../../redux/actions/notificationAction';

class NotificationsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingLogs: true,
            firstLoading: true,
            logs: [],
            offset: 0,
            paginationLimit: 10,
            totalRecords: 0
        };
    }


    componentDidMount() {
        this.refreshNotificationLogs();
    }

    componentWillUnmount() {
        this.setState({
            loadingLogs: true,
            firstLoading: true,
            logs: [],
            offset: 0,
            paginationLimit: 10,
            totalRecords: 0
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.offset !== this.state.offset) {
            const { offset, paginationLimit } = this.state;
            this.getLogs(offset * paginationLimit , paginationLimit);
        }
        if (prevState.paginationLimit !== this.state.paginationLimit) {
            const { offset, paginationLimit, totalRecords } = this.state;
            if (totalRecords > 5) {
                this.getLogs(offset * paginationLimit, paginationLimit);
            }
        }
        if(this.props.refreshPopup !== prevProps.refreshPopup && this.props.refreshPopup === true) {
            const { offset, paginationLimit } = this.state;
            this.getLogs(offset, paginationLimit);
            this.props.refreshNotificationsPopupAction(false);
        }
    }

    getLogs = (offset, limit) => {
        this.setState({
          loadingLogs: true
        }, () => {
          getNotifications({
            pagination_limit: limit,
            pagination_offset: offset,
            order_by: 'created_at',
            order_asc: false
          })
          .then(res => {
            this.setState({
              loadingLogs: false,
              firstLoading: false,
              logs: res.data,
              totalRecords: res.data.length > 0 && res.data[0] ? res.data[0].total_count : 0
            });
          })
          .catch(err => {
            console.log(err);
            this.setState({
              loadingLogs: false,
              firstLoading: false
            });
          });
        });
      }

    setPageOffset = offset => this.setState({ offset });

    setPagePaginationLimit = paginationLimit => this.setState({ paginationLimit });

    redirectToDetail = (notificationId,packageId,checked) => {
        this.props.history.push({
          pathname: '/notification-center/package-detail/',
          search: serializeURL({
            notificationId,
            packageId,
            checked
          })
        });
      }

      refreshNotificationLogs = () => {
        const { offset, paginationLimit } = this.state;
        this.getLogs(offset, paginationLimit);
        this.props.refreshNotificationsCenterAction(true);
      }

    render() {
        const { 
            loadingLogs, logs, offset, paginationLimit, totalRecords, firstLoading 
        } = this.state;
        const { t } = this.props;
        return (
            <>
                <div className="notification-center-heading">
                    <div>
                        <span>{t('notifications.recent')}</span>
                        <Tooltip title="Refresh">
                            <IconButton
                            className="icon-btn-refresh"
                            onClick={() => !loadingLogs? this.refreshNotificationLogs() : {}}>
                                <span className={!loadingLogs ? "refresh-notifications" : "refresh-notifications-circular"}><CachedIcon fontSize="small" /></span>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className={loadingLogs ? 'notification-loader-align-center notification-center-container' : 'notification-center-container'} >
                            <ul className={!loadingLogs && logs.length === 0 ? "no-new-notification" : "notification-center-item-list"}>
                                <>
                                {
                                    loadingLogs ? <VerticallyCenter>
                                        <AlignCenter>
                                            <Ripple variant="pink" />
                                        </AlignCenter>
                                    </VerticallyCenter> : 

                                    logs.length > 0 ? logs.map((log,index) => (
                                    <li 
                                        className="notification-center-item-container notification-center-border-top" 
                                        onClick={() => this.redirectToDetail(log.app_notification_id, log.content.data.package_id, !log.is_read)}
                                    >
                                        <div className="notification-center-unread-container ">
                                            {!log.is_read && <div className="unread-notification">.</div>}
                                        </div>
                                        <div className="notification-center-item-picture">
                                            <img src={log.content.data.package_image || BoxSvg} alt="Notification snap" />
                                        </div>
                                        <div className="notification-center-item-info">
                                            <div>{moment(log.created_at).format('DD/MM/YYYY, hh:mm A')}</div>
                                            <p>{log.content.notification.body}</p>
                                        </div>
                                    </li>
                                    )) : <span>{t('notifications.noNew')}</span>
                                }
                                </>
                            </ul>
                    </div>
                    {!firstLoading && <div className="notification-center-pagination-section">
                                <NotificationsPagination totalRecords={totalRecords} offset={offset} paginationLimit={paginationLimit} setOffset={this.setPageOffset} setPaginationLimit={this.setPagePaginationLimit} />
                            </div>}

            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    refreshNotificationsCenterAction: flag => dispatch(refreshNotificationsCenterAction(flag)),
    refreshNotificationsPopupAction: flag => dispatch(refreshNotificationsPopupAction(flag)),
});
  
  const mapStateToProps = state => ({
    refreshPopup: state.notificationPrompt.refreshPopup,
  });
  
  
  export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(NotificationsList);