/* eslint-disable brace-style */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
    withWidth,
    Button as MUIButton,
} from '@material-ui/core';
import BlueButton from '../../../UI/BlueButton';

function DataExportConfirmationModal(props) {
    const {
        t,
        tableDataExportModal,
        handleTableDataExportModalClose,
        handleTableDataExportModalConfirm,
    } = props;
    return (
        <Dialog
            open={tableDataExportModal}
            onClose={handleTableDataExportModalClose}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                <IconButton
                    onClick={handleTableDataExportModalClose}
                    style={{ height: 'max-content' }}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to export the table data for the selected date range?
                <br />

                    {/* You would not be able to cancel the download, change language and logout from the dashboard. */}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <MUIButton
                    onClick={handleTableDataExportModalClose}
                    className="margin-left-10p padding-0 blue"
                >
                    {t('email.cancel')}
                </MUIButton>
                <BlueButton
                    onClick={handleTableDataExportModalConfirm}
                    height={35}
                    // className="grid-switch-buttons"
                    width={80}
                    style={{
                        backgroundColor: '#027AFF',
                        color: 'white',
                        // marginRight: 24
                    }}
                >
                    <b>EXPORT</b>
                </BlueButton>
                {/* <MUIButton
                    onClick={handleTableDataExportModalConfirm}
                    className="margin-left-10p padding-0 blue"
                >
                    EXPORT
                </MUIButton> */}
                {/* <BlueButton
                    onClick={this.sendCall}
                    loading={loading}
                    height={40}
                    width="150px"
                >
                    {t('email.save')}
                    &nbsp;{' Anyway'}
                </BlueButton> */}
            </DialogActions>
        </Dialog>
    );
}

DataExportConfirmationModal.propTypes = {
    t: PropTypes.func.isRequired,
    tableDataExportModal: PropTypes.func.isRequired,
    handleTableDataExportModalClose: PropTypes.func.isRequired,
    handleTableDataExportModalConfirm: PropTypes.func.isRequired,
};

export default (withWidth()(DataExportConfirmationModal));
