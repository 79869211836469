/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  //   FormHelperText,
  CircularProgress,
  Button,
} from '@material-ui/core';
import notify from '../../../../assets/Onboarding/notify.svg';
import { CallTrigger } from '../../../../util/Triggers/function';
import { CLA } from '../../../../util/Triggers/triggerNames';
import pending from '../../../../assets/Onboarding/pendingNotify.svg';
import address from '../../../../assets/Onboarding/Address.svg';
import Video from '../../../../assets/videos/Notify.mp4';
// import Video from '../../../../assets/Onboarding/NewVideos/latest12-06-2021/NotifyVideo.mp4';
// import Video from '../../../../assets/videos/Onboarding_2_dark.mp4';

class NotifyRecipients extends React.Component {
  componentDidMount() {
    this.props.handleDisablePrev(false);
    this.props.handleDisableNext(false);
    CallTrigger(CLA.DOWNLOAD_APP.Download_App_Screen, { Previous_event: '' });
  }
  render() {
    const { next, t } = this.props;
    return (
      <div className="onboard-new-outer-container">
        <Grid /*container*/ className="container" style={{ minWidth: '100%' }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              //   setwaiting(true);
              CallTrigger(CLA.DOWNLOAD_APP.Download_App_Continue_Bclick, {
                Previous_event: '',
              });
              next();
            }}
          >
            <Grid item xs={12} sm={12}>
              <h3 className="onboarding-welcome-title">
                {/* {t('onboarding.downloadApp.title')} */}
                Notify Recipients of Items Received
              </h3>
              <br />
              <Grid xs={12} sm={12} container>
                <Grid xs={12} md={9} style={{}}>
                  <p className="onboarding-welcome-description">
                    Once you have finished adding the additional details, you’ll
                    need to notify each recipient of the items you received. If
                    there’s a recipient with multiple items, we’ll consolidate
                    the items into a single notification!
                  </p>
                  <ol
                    className="onboard-new-instructions-list"
                    style={{ width: '80%' }}
                  >
                    <li className="onboard-new-mt-60 onboard-new-mb-60">
                      Select the{' '}
                      {/* <img
                        src={pending}
                        className="onboard-new-icon"
                        alt="pending"
                      />{' '} */}
                      <img
                        src={address}
                        className="onboard-new-icon onboard-new-width-20px"
                        alt="address"
                      />{' '}
                      icon in the top right corner of the pending tab to send a
                      notification email to each of your recipients. You can
                      notify up to 50 items in a single click!
                    </li>
                    {/* <li className="onboard-new-mt-60 onboard-new-mb-60">
                      Select the{' '}
                      
                      icon in the top right corner to notify each of your
                      recipients. If there is a recipient with multiple items,
                      we'll consolidate the items into a single notification.
                    </li> */}
                    <li className="onboard-new-mt-60">
                      Your recipients will receive a notification like the one
                      displayed on the screen. Admins can edit these
                      notification templates from the “Building” tab on the web
                      portal.
                    </li>
                  </ol>
                  <div className="onboard-new-end-para-left">
                    <p style={{ marginTop: '4rem', width: '75%' }}>
                      Pro Tip: While on the pending tab, long-press on an item
                      with your thumb to toggle a quick menu. Here you can
                      notify a single item and perform additional actions!
                    </p>
                  </div>
                </Grid>

                <Grid
                  xs={12}
                  md={3}
                  container
                  style={
                    {
                      // padding: '10px',
                    }
                  }
                >
                  <div className="video-section-onboarding-new">
                    <video
                      autoplay="autoplay"
                      muted="muted"
                      loop="loop"
                      className="video-player-new"
                      onLoadStart={(e) => this.setState({ isLoading: true })}
                      onLoadEnd={() => this.setState({ isLoading: false })}
                      preload="none"
                    >
                      <source src={Video} type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button
                type="submit"
                style={{
                  marginTop: 25,
                }}
                disabled={this.props.waiting}
                className={`onBoard-page-btn ${
                  this.props.waiting ? 'onBoard-page-btn-disabled' : ''
                }`}
                onClick={() => next()}
              >
                {t('onboarding.downloadApp.button')}
                {this.props.waiting && (
                  <div className="ml-2">
                    <CircularProgress size={15} className="white" />
                  </div>
                )}
              </Button>
            </Grid>
          </form>
        </Grid>
      </div>
    );
  }
}

NotifyRecipients.propTypes = {
  next: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  waiting: PropTypes.bool.isRequired,
  handleDisablePrev: PropTypes.func.isRequired,
  handleDisableNext: PropTypes.func.isRequired,
};

export default NotifyRecipients;
