/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
import * as React from "react";
import PropTypes from "prop-types";
import { translate } from 'react-i18next';
import withWidth from "@material-ui/core/withWidth";
import { Tooltip, Button } from "@material-ui/core";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  FirstPageOutlined,
  LastPageOutlined,
} from "@material-ui/icons";
import "../styles/paginationComponent.style.css";
import Select from '../../../../containers/UI/Dropdown/Dropdown';

function PaginationComponent(props) {
  const {
    itemsPerPage,
    totalItems,
    activePage,
    showLimit,
    onChange,
    loading,
    width,
    t,
  } = props;
  const [limit, setLimit] = React.useState({ value: itemsPerPage, label: itemsPerPage });
  const totalPagesCount = Math.ceil(totalItems / itemsPerPage);
  const arrayOfPages = Array(totalPagesCount)
    .fill(0)
    .map((item, index) => index + 1);
  const firstPageActive = activePage === 1;
  const lastPageActive = activePage === totalPagesCount;
  const screenSize = props.width && props.width;
  const sliceNormal = screenSize === "xs"
  ? 3
    : screenSize === "sm"
      ? 4
      : 5;
  const activePageSlicing = screenSize === "xs" ? 2 : 3;
  const labelClassName = screenSize === "xs" ? "in-center" : "showing-records";
  const arrowStyles = {
    height: 15,
    width: 11,
    color: "#646777",
  };
  const arrowStylesFirstAndLastPage = {
    height: 23,
    width: 17,
    color: "#646777",
  };
  const handleLimit = value => setLimit(value);
  return (
    <div
      className={width !== "xs" ? "display-flex" : "display-flex-column"}
      style={{ marginBottom: width === "xs" && 5 }}
    >
      {lastPageActive ? (
        <p
          className={labelClassName}
          style={totalPagesCount < 5 ? { display: 'flex', alignItems: 'center', flexWrap: 'wrap' } : { display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
        >
          {
            showLimit &&
            <Select
              width={50}
              interval={[{ value: 10, label: 10 }, { value: 20, label: 20 }, { value: 30, label: 30 }]}
              value={limit}
              onChange={value => {
                onChange(activePage, value.value);
                handleLimit(value);
              }}
            />
          }
          <b>
            {t('common.showing')} {(activePage - 1) * itemsPerPage + 1} - {totalItems} {t('common.of')} {totalItems}
          </b>
        </p>
      ) : (
          <p
            className={labelClassName}
            style={totalPagesCount <= 5 ? { display: 'flex', alignItems: 'center' } : { display: 'flex', alignItems: 'center' }}
          >
            {totalItems === "1" ? (
              ""
            ) : (
                <div className="align-center">
                  {
                    showLimit &&
                    <Select
                      width={50}
                      interval={[{ value: 10, label: 10 }, { value: 20, label: 20 }, { value: 30, label: 30 }]}
                      value={limit}
                      onChange={value => {
                        onChange(activePage, value.value);
                        handleLimit(value);
                      }}
                    />
                  }
                  <b>
                    {t('common.showing')} {(activePage - 1) * itemsPerPage + 1} -
                {(activePage - 1) * itemsPerPage + itemsPerPage} {t('common.of')} {totalItems}
                  </b>
                </div>
              )}
          </p>
        )}
      {totalPagesCount < 5 ? (
        <div className="align-center">
          {firstPageActive ? (
            <div className="flex">
              <Button style={{ minWidth: 36 }} disabled>
                <FirstPageOutlined style={arrowStylesFirstAndLastPage} />
              </Button>
              <Button style={{ minWidth: 36 }} disabled>
                <ArrowBackIosRounded style={arrowStyles} />
              </Button>
            </div>
          ) : (
              <div className="flex">
                <Tooltip title="First Page">
                  <Button
                    style={{ minWidth: 36 }}
                    onClick={!loading && (() => onChange(1))}
                  >
                    <FirstPageOutlined style={arrowStylesFirstAndLastPage} />
                  </Button>
                </Tooltip>
                <Tooltip title="Previous Page">
                  <Button
                    style={{ minWidth: 36 }}
                    onClick={!loading && (() => onChange("previous"))}
                  >
                    <ArrowBackIosRounded style={arrowStyles} />
                  </Button>
                </Tooltip>
              </div>
            )}
          {arrayOfPages.map((item, index) => (
            <div key={"Array Of Pages".concat(index)}>
              {index + 1 === activePage ? (
                <Tooltip title={"Page ".concat(index + 1)}>
                  <Button
                    classes={{ root: "button-width" }}
                    variant=""
                    style={{
                      backgroundColor: "rgb(2, 122, 255)",
                      color: "white",
                    }}
                  >
                    {index + 1}
                  </Button>
                </Tooltip>
              ) : (
                  <Tooltip title={"Page ".concat(index + 1)}>
                    <Button
                      classes={{ root: "button-width" }}
                      variant=""
                      onClick={!loading && (() => onChange(index + 1))}
                    >
                      {index + 1}
                    </Button>
                  </Tooltip>
                )}
            </div>
          ))}
          {lastPageActive ? (
            <div className="flex">
              <Button style={{ minWidth: 36 }} disabled>
                <ArrowForwardIosRounded style={arrowStyles} />
              </Button>
              <Button style={{ minWidth: 36 }} disabled>
                <LastPageOutlined style={arrowStylesFirstAndLastPage} />
              </Button>
            </div>
          ) : (
              <div className="flex">
                <Tooltip title="Next Page">
                  <Button
                    style={{ minWidth: 36 }}
                    onClick={!loading && (() => onChange("next"))}
                  >
                    <ArrowForwardIosRounded style={arrowStyles} />
                  </Button>
                </Tooltip>
                <Tooltip title="Last Page">
                  <Button
                    style={{ minWidth: 36 }}
                    onClick={!loading && (() => onChange(arrayOfPages.length))}
                  >
                    <LastPageOutlined style={arrowStylesFirstAndLastPage} />
                  </Button>
                </Tooltip>
              </div>
            )}
        </div>
      ) : (
          <table>
            <tbody>
              <tr>
                <td className="table-row table-previous-page" style={{ backgroundColor: "transparent" }}>
                  {firstPageActive ? (
                    <div style={{ display: "flex" }}>
                      <Button style={{ minWidth: 36 }} disabled>
                        <FirstPageOutlined style={arrowStylesFirstAndLastPage} />
                      </Button>
                      <Button style={{ minWidth: 36 }} disabled>
                        <ArrowBackIosRounded style={arrowStyles} />
                      </Button>
                    </div>
                  ) : (
                      <div className="flex">
                        <Tooltip title="First Page">
                          <Button
                            style={{ minWidth: 36 }}
                            onClick={!loading && (() => onChange(1))}
                          >
                            <FirstPageOutlined
                              style={arrowStylesFirstAndLastPage}
                            />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Previous Page">
                          <Button
                            style={{ minWidth: 36 }}
                            onClick={!loading && (() => onChange("previous"))}
                          >
                            <ArrowBackIosRounded style={arrowStyles} />
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                </td>
                <div>
                  {activePage > activePageSlicing
                    ? screenSize === "xs"
                      ? arrayOfPages
                        .slice(activePage - 2, activePage + 1)
                        .map((item, index) => (
                          <td key={"Array Of Pages sliced".concat(index)}>
                            {item === activePage ? (
                              <Tooltip title={"Page ".concat(item)}>
                                <Button
                                  classes={{ root: "button-width" }}
                                  variant=""
                                  style={{
                                    backgroundColor: "rgb(2, 122, 255)",
                                    color: "white",
                                  }}
                                >
                                  {item}
                                </Button>
                              </Tooltip>
                            ) : (
                                <Tooltip title={"Page ".concat(item)}>
                                  <Button
                                    classes={{ root: "button-width" }}
                                    variant=""
                                    onClick={!loading && (() => onChange(item))}
                                  >
                                    {item}
                                  </Button>
                                </Tooltip>
                              )}
                          </td>
                        ))
                      : arrayOfPages
                        .slice(activePage - 2, activePage + 2)
                        .map((item, index) => (
                          <td key={"Array Of Pages sliced".concat(index)}>
                            {item === activePage ? (
                              <Tooltip title={"Page ".concat(item)}>
                                <Button classes={{ root: "button-width" }} className="width-38px bg-blue white">
                                  {item}
                                </Button>
                              </Tooltip>
                            ) : (
                                <Tooltip title={"Page ".concat(item)}>
                                  <Button
                                    classes={{ root: "button-width" }}
                                    onClick={!loading && (() => onChange(item))}
                                  >
                                    {item}
                                  </Button>
                                </Tooltip>
                              )}
                          </td>
                        ))
                    : arrayOfPages.length > 5 ?
                      arrayOfPages
                        .slice(activePage, activePage + sliceNormal)
                        .map((item, index) => (
                          <td key={"Array Of Pages sliced".concat(index)}>
                            {index + 1 === activePage ? (
                              <Tooltip title={"Page ".concat(index + 1)}>
                                <Button classes={{ root: "button-width" }} className="width-38px bg-blue white">
                                  {index + 1}
                                </Button>
                              </Tooltip>
                            ) : (
                                <Tooltip title={"Page ".concat(index + 1)}>
                                  <Button
                                    classes={{ root: "button-width" }}
                                    onClick={
                                      !loading && (() => onChange(index + 1))
                                    }
                                  >
                                    {index + 1}
                                  </Button>
                                </Tooltip>
                              )}
                          </td>
                        )) 
                        : arrayOfPages
                        .map((item, index) => (
                          <td key={"Array Of Pages sliced".concat(index)}>
                            {index + 1 === activePage ? (
                              <Tooltip title={"Page ".concat(index + 1)}>
                                <Button classes={{ root: "button-width" }} className="width-38px bg-blue white">
                                  {index + 1}
                                </Button>
                              </Tooltip>
                            ) : (
                                <Tooltip title={"Page ".concat(index + 1)}>
                                  <Button
                                    classes={{ root: "button-width" }}
                                    onClick={
                                      !loading && (() => onChange(index + 1))
                                    }
                                  >
                                    {index + 1}
                                  </Button>
                                </Tooltip>
                              )}
                          </td>
                        ))}
                </div>
                {lastPageActive ? (
                  <td>
                    <div className="align-items-center">
                      <Button style={{ minWidth: 36, height: 36 }} disabled>
                        <ArrowForwardIosRounded style={arrowStyles} />
                      </Button>
                      <Button style={{ minWidth: 36, height: 36 }} disabled>
                        <LastPageOutlined style={arrowStylesFirstAndLastPage} />
                      </Button>
                    </div>
                  </td>
                ) : (
                    <td>
                      <div className="padding-top-3px align-items-center">
                        <Tooltip title="Next Page">
                          <Button
                            style={{ minWidth: 36, height: 36 }}
                            onClick={!loading && (() => onChange("next"))}
                          >
                            <ArrowForwardIosRounded style={arrowStyles} />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Last Page">
                          <Button
                            style={{ minWidth: 36, height: 36 }}
                            onClick={
                              !loading && (() => onChange(arrayOfPages.length))
                            }
                          >
                            <LastPageOutlined style={arrowStylesFirstAndLastPage} />
                          </Button>
                        </Tooltip>
                      </div>
                    </td>
                  )}
              </tr>
            </tbody>
          </table>
        )}
    </div>
  );
}

PaginationComponent.propTypes = {
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  showLimit: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(withWidth()(PaginationComponent));
