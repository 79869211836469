import React from 'react';
import PropTypes from 'prop-types';

const VerticallyCenterLogin = ({ children, style }) => (
  <div
    style={{
    //   display: 'flex',
    //   justifyContent: 'center',
    //   height: 'inherit',
    //   alignItems: 'center',
      ...style,
    }}
  >
    {children}
  </div>
);

VerticallyCenterLogin.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.node.isRequired,
};

export default VerticallyCenterLogin;
