import { SET_USER } from '../actions/userActions';

const initialState = null;

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER: {
      state = action.user;
      localStorage.px_user = state;
      return state;
    }
    default: return state;
  }
}
