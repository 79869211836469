/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Grid,
  FormHelperText,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Close, Add } from '@material-ui/icons';
import { CustomInput, CustomInputLabel } from '../../../UI/Input';
import {
  setMemberOnboarding,
  getStepOnboarding,
} from '../../../../server/auth';
import { CallTrigger } from '../../../../util/Triggers/function';
import { CLA } from '../../../../util/Triggers/triggerNames';

class AddRecipients extends React.Component {
  constructor() {
    super();
    this.state = {
      hoverAddNewRecipient: false,
      didUpdateName: false,
      didUpdateEmail: false,
      recipientArray: [],
      disableSubmit: false,
      waiting: false,
    };
  }

  componentDidMount() {
    const tempRecipientsNames = JSON.parse(
      localStorage.getItem('recipientsNames')
    );
    const tempRecipientsEmails = JSON.parse(
      localStorage.getItem('recipientsEmails')
    );
    const addedRecipients =
      tempRecipientsNames &&
      tempRecipientsEmails &&
      tempRecipientsNames.length > 0 &&
      tempRecipientsEmails.length > 0
        ? tempRecipientsNames.map((rec, index) => ({
            name: rec,
            email: tempRecipientsEmails[index],
            nameError: false,
            nameErrorMessage: '',
            emailError: false,
            emailErrorMessage: '',
          }))
        : [];
    this.setState(
      {
        disableSubmit: false,
        recipientArray: addedRecipients,
      },
      () => {
        // this.props.handleDisableNext(true);
        this.props.handleDisablePrev(true);
      }
    );
    CallTrigger(CLA.ADD_RECP.Add_Recipient_screen, { Previous_event: '' });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.disableSubmit !== prevState.disableSubmit) {
      this.props.handleDisableNext(this.state.disableSubmit);
    }
    if (
      this.props.actionBit === true &&
      this.props.actionBit !== prevProps.actionBit
    ) {
      this.props.unsetActionBit();
      document.getElementById('addRecipientSubmit').click();
    }
  }

  componentWillUnmount() {
    this.props.handleDisablePrev(false);
  }

  onBoardingStep = (callback) => {
    getStepOnboarding()
      .then((res) => {
        localStorage.setItem('clientName', res.data.tenant_info.client_name);
        localStorage.setItem('building', res.data.building_info.building_id);
        // localStorage.setItem('pxBuilding', res.data.building_info);
        // this.props.next();
        callback();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  UNSAFE_componentWillUpdate(prevProps, prevState) {
    // if (prevState.didUpdateEmail !== this.state.didUpdateEmail || prevState.didUpdateName !== this.state.didUpdateName) {
    this.updateValidation();
    // }
  }

  updateValidation = () => {
    const { validateEmail, t, validateName } = this.props;
    this.state.recipientArray.forEach((recp) => {
      if (
        recp.email === '' ||
        recp.email === null ||
        recp.email === undefined
      ) {
        // recp.emailError = validateEmail(recp.email);
        recp.emailError = this.state.didUpdateEmail;
        recp.emailErrorMessage = t(
          'onboarding.addRecipients.userEmailEmptyError'
        );
      } else {
        if (!validateEmail(recp.email)) {
          recp.emailError = true;
          recp.emailErrorMessage = t('emailValidation');
        } else {
          recp.emailError = false;
          recp.emailErrorMessage = '';
        }
      }

      if (recp.name === '' || recp.name === null || recp.name === undefined) {
        recp.nameError = this.state.didUpdateName;
        recp.nameErrorMessage = t(
          'onboarding.addRecipients.userNameEmptyError'
        );
      } else {
        if (!validateName(recp.name.trim())) {
          recp.nameError = true;
          recp.nameErrorMessage = t('common.error.nameValidation');
        } else {
          recp.nameError = false;
          recp.nameErrorMessage = '';
        }
      }
    });
  };

  handleState = (state, index) => (event) => {
    const { validateEmail, t, validateName } = this.props;
    const value = event.target.value;
    const { recipientArray } = this.state;
    const newRecp = recipientArray[index];
    newRecp[state] = value;

    if (state === 'email') this.setState({ didUpdateEmail: true });
    else this.setState({ didUpdateName: true });

    if (value === '' || value === null || value === undefined) {
      if (state === 'email') {
        newRecp.emailError = validateEmail(value);
        newRecp.emailErrorMessage = t(
          'onboarding.addRecipients.userEmailEmptyError'
        );
      } else if (state === 'name') {
        newRecp.nameError = true;
        newRecp.nameErrorMessage = t('common.error.nameValidation');
      }
    } else {
      if (state === 'email' && !validateEmail(value)) {
        newRecp.emailError = true;
        newRecp.emailErrorMessage = t('emailValidation');
      } else {
        newRecp.emailError = false;
        newRecp.emailErrorMessage = '';
      }
      //  nameError: name.length ? !validateName(name.trim()) : false,
      if (state === 'name' && !validateName(value.trim())) {
        newRecp.nameError = true;
        newRecp.nameErrorMessage = t('common.error.nameValidation');
      } else {
        newRecp.nameError = false;
        newRecp.nameErrorMessage = '';
      }
    }
    recipientArray[index] = newRecp;
    this.setState({ recipientArray }, () => {
      const lastArray = this.state.recipientArray[recipientArray.length - 1];
      if (
        lastArray.name !== '' &&
        lastArray.email !== '' &&
        !lastArray.nameError &&
        !lastArray.emailError
      ) {
        this.setState({
          disableSubmit: false,
        });
      } else {
        this.setState({
          disableSubmit: true,
        });
      }
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { recipientArray } = this.state;
    CallTrigger(CLA.ADD_RECP.Add_Recipient_Continue_bclick, {
      Previous_event: '',
      recipients_count: recipientArray.length,
    });
    const members = recipientArray.map((mem) => ({
      name: mem.name,
      email: mem.email,
    }));
    this.setState({ waiting: true }, () => {
      this.onBoardingStep(() => {
        setMemberOnboarding(localStorage.getItem('building'), members)
          .then(() => {
            const recipientName = members.map((mem) => mem.name);
            const recipientEmail = members.map((mem) => mem.email);
            localStorage.setItem(
              'recipientsNames',
              JSON.stringify(recipientName)
            );
            localStorage.setItem(
              'recipientsEmails',
              JSON.stringify(recipientEmail)
            );
            this.props.next();
          })
          .catch((err) => {
            this.props.setError(err.message);
            this.setState({ waiting: false });
          });
      });
    });
  };
  switchAddRecipientHover = () => {
    this.setState({ hoverAddNewRecipient: !this.state.hoverAddNewRecipient });
  };

  addRecipient = () => {
    if (!this.state.waiting) {
      this.setState({ didUpdateEmail: true, didUpdateName: true }, () => {
        this.updateValidation();
      });
      const { recipientArray } = this.state;
      const { t } = this.props;
      if (recipientArray.length > 0) {
        const lastArray = recipientArray[recipientArray.length - 1];
        if (
          lastArray.name !== '' &&
          lastArray.email !== '' &&
          !lastArray.nameError &&
          !lastArray.emailError
        ) {
          this.setState({
            recipientArray: [
              ...this.state.recipientArray,
              { name: '', email: '' },
            ],
            disableSubmit: true,
            didUpdateEmail: false,
            didUpdateName: false,
          });
        } else {
          if (
            lastArray.name === '' ||
            lastArray.name === null ||
            lastArray.name === undefined
          ) {
            lastArray.nameError = true;
            lastArray.nameErrorMessage = t(
              'onboarding.addRecipients.userNameEmptyError'
            );
          }
          if (
            lastArray.email === '' ||
            lastArray.email === null ||
            lastArray.email === undefined
          ) {
            lastArray.emailError = true;
            lastArray.emailErrorMessage = t(
              'onboarding.addRecipients.userEmailEmptyError'
            );
          }
        }
      } else {
        this.setState({
          recipientArray: [
            ...this.state.recipientArray,
            { name: '', email: '' },
          ],
          disableSubmit: true,
          didUpdateEmail: false,
          didUpdateName: false,
        });
      }
    }
  };

  deleteRecipient = (index) => {
    let recipients = this.state.recipientArray;
    if (recipients.length > 1) {
      recipients.splice(index, 1);
      this.setState(
        {
          recipientArray: recipients,
        },
        () => {
          const lastArray = this.state.recipientArray[
            this.state.recipientArray.length - 1
          ];
          if (
            lastArray.name !== '' &&
            lastArray.email !== '' &&
            !lastArray.nameError &&
            !lastArray.emailError
          ) {
            this.setState({
              // recipientArray: [...this.state.recipientArray, { name: '', email: '' }],
              disableSubmit: false,
            });
          }
        }
      );
    }
  };

  render() {
    const {
      hoverAddNewRecipient,
      recipientArray,
      disableSubmit,
      waiting,
    } = this.state;
    const { t } = this.props;
    const btnClass = !disableSubmit
      ? 'onBoard-page-btn margin-top-60px'
      : 'onBoard-page-btn-disabled margin-top-60px';
    const FormHelperStyle = { color: '#FF1E59' };

    const getRecipientForm = (recp, index) => (
      <Grid container className="container-onboarding">
        <Grid item xs={12} sm={6}>
          <div className="left-input-onboarding">
            <FormControl required fullWidth className="height-75">
              <CustomInputLabel
                htmlFor="adornment-password"
                shrink
                className="font-size-14"
              >
                {t('onboarding.addRecipients.userName')}
              </CustomInputLabel>
              <CustomInput
                autoFocus
                value={recp.name}
                disabled={index === 0}
                onChange={this.handleState('name', index)}
                helperText={recp.nameError ? recp.nameErrorMessage : ''}
                FormHelperTextProps={FormHelperStyle}
              />
              {recp.nameError && (
                <FormHelperText style={FormHelperStyle}>
                  {recp.nameErrorMessage}
                </FormHelperText>
              )}
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className="right-input-onboarding">
          <div className="right-input-onboarding">
            <FormControl required fullWidth className="height-75">
              <CustomInputLabel
                htmlFor="adornment-password"
                shrink
                className="font-size-14"
              >
                {t('onboarding.addRecipients.userEmail')}
              </CustomInputLabel>
              <CustomInput
                value={recp.email}
                disabled={index === 0}
                onChange={this.handleState('email', index)}
                helperText={recp.emailError ? recp.emailErrorMessage : ''}
                FormHelperTextProps={FormHelperStyle}
              />
              {recp.emailError && (
                <FormHelperText style={FormHelperStyle}>
                  {recp.emailErrorMessage}
                </FormHelperText>
              )}
            </FormControl>
          </div>
        </Grid>
      </Grid>
    );

    return (
      <div className="onBoard-page-container-welcome">
        <Grid container className="container" style={{ minWidth: '100%' }}>
          <Grid item xs={12} sm={12}>
            <h3 className="onboarding-welcome-title">
              {t('onboarding.addRecipients.title')}
            </h3>
            {/* <p className="onboarding-welcome-description">{t('onboarding.addRecipients.description')}</p>
             */}
            <p className="onboarding-add-rec-description">
              The first step to setting up your Mailroom account is adding
              recipients to your directory. We’ve already gone ahead and added
              you as a recipient for the tutorial. You may choose to add
              additional recipients on this page, or you wait to bulk upload
              recipients via a .CSV on the “Recipients” tab within the
              dashboard.
            </p>
          </Grid>
          <form
            style={{ width: '100%' }}
            // onSubmit={this.handleSubmit}
          >
            <Grid item xs={12} sm={12}>
              {recipientArray &&
                recipientArray.length > 0 &&
                recipientArray.map((recp, index) => (
                  <Grid container>
                    <Grid item xs={9} sm={10} md={11}>
                      {getRecipientForm(recp, index)}
                    </Grid>

                    {recipientArray.length > 1 && index > 0 && (
                      <Grid
                        item
                        xs={3}
                        sm={2}
                        md={1}
                        className="container-onboarding-recipient-section"
                        onClick={() => this.deleteRecipient(index)}
                      >
                        <div className="container-onboarding-recipient-btn">
                          <Close />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                ))}
            </Grid>
            <Grid item xs={12} sm={12}>
              <div
                role="button"
                tabIndex={0}
                className="container-onboarding-add-new-recipient"
                onMouseEnter={this.switchAddRecipientHover}
                onMouseLeave={this.switchAddRecipientHover}
                onClick={() => this.addRecipient()}
                onKeyDown={() => this.addRecipient()}
              >
                <div
                  className={
                    hoverAddNewRecipient
                      ? 'container-onboarding-add-recipient-btn-hover'
                      : 'container-onboarding-add-recipient-btn'
                  }
                >
                  <Add
                    className={
                      hoverAddNewRecipient
                        ? 'container-onboarding-add-recipient-icon'
                        : 'container-onboarding-add-recipient-icon-hover'
                    }
                  />
                </div>
                <span>{t('onboarding.addRecipients.addRow')} ....</span>
              </div>
            </Grid>
          </form>
          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              id="addRecipientSubmit"
              className={`${btnClass} ${
                waiting ? 'onBoard-page-btn-disabled' : ''
              }  `}
              disabled={disableSubmit || waiting}
              onClick={this.handleSubmit}
            >
              {t('onboarding.addRecipients.button')}
              {waiting && (
                <div className="ml-2">
                  <CircularProgress size={15} className="white" />
                </div>
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

AddRecipients.propTypes = {
  next: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  validateEmail: PropTypes.func.isRequired,
  // validateBuildingAndAssociateName: PropTypes.func.isRequired,
  handleDisableNext: PropTypes.func.isRequired,
  handleDisablePrev: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  actionBit: PropTypes.bool.isRequired,
  unsetActionBit: PropTypes.func.isRequired,
};

export default AddRecipients;
