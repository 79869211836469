/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { BusinessCenter, Settings } from '@material-ui/icons';
import {
  withMobileDialog,
  IconButton,
  TableCell,
  Divider,
  Tooltip,
  Zoom,
  // Button,
  Grid,
  Badge,
} from '@material-ui/core';
import Alert from '../../../UI/Poper';
import { SpaceBetween } from '../../../UI/ResponsiveRelated';
import { setTableColumn } from '../../../../util/application.utils';
import { truncateLabel, getBuildingFromCountries } from '../../../../util/utility.functions';
import MaterialUITable from '../../../../shared/components/MaterialUITable/components/materialUITableComponent';
import PaginationComponent from '../../../../shared/components/PaginationWithNumbers/components/paginationComponent';
import { setPagination } from '../../../../redux/actions/paginationAction';
import { setDynamicPagination } from '../../../../redux/actions/dynamicPaginationActions';
import { getBusinesses, searchBusiness } from '../../../../server';
import { setBuilding } from '../../../../redux/actions/locationsFilterActions';
import { setTableColumnFilter } from '../../../../redux/actions/tableColumnActions';
import { sortByBusiness } from '../../../../util/sortBy';

class BusinessesTable extends Component {
  constructor(props) {
    super(props);
    const {
      t
    } = props;
    this.state = {
      limit: props.limitPerPage.businessesLimit,
      businesses: [],
      error: null,
      loading: true,
      noMore: false,
      businessId: null,
      totalBusinesses: '---',
      order_by: 'name',
      order_asc: 'ASC',
      view: 'list',
      tableHeads: setTableColumn(t, props.tableColumn.bussiness),
      activePage: 1,
      sortBy: 'name',
      sortType: true,
      oneTableColumn: '',
      businessData: [],
    };

    this.tableRows = this.tableRows.bind(this);
    // this.onPageChange = this.onPageChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleSortByColumn = this.handleSortByColumn.bind(this);
  }

  componentDidMount() {
    this.getBusinessessData();
  }

  componentDidUpdate(props) {
    const {
      t
    } = this.props;
    if (props.tab !== this.props.tab) {
      this.getData(this.props);
    } else if (props.tab !== this.props.tab) {
      this.onChangePage(1);
    } else if (props.building !== this.props.building) {
      this.onChangePage(1);
    } else if (props.searchedBusiness.length > 0 && this.props.startSearch && !this.state.loading) {
      this.onChangePage(1);
    } else if (props.businessTempSearch !== this.props.businessTempSearch && !this.state.loading) {
      this.onChangePage(1);
    }
    if (this.props.lng !== props.lng) {
      // const { tableHeads } = this.state;
      let tableHeads = setTableColumn(t, props.tableColumn.bussiness);
      this.setState({
        tableHeads,
        businesses: this.createRows(this.state.businessData),
      });
    }
  }

  static getDerivedStateFromProps() {
    return true;
  }

  onChangePage = (currentActionPage, limit) => {
    let action;
    if (currentActionPage === 'next') {
      action = this.state.activePage + 1;
    } else if (currentActionPage === 'previous') {
      action = this.state.activePage - 1;
    } else if (!currentActionPage) {
      action = this.state.activePage;
    } else {
      action = currentActionPage;
      if (limit) {
        let tempLimits = {};
        if (localStorage.px_dynamic_pagination) {
          tempLimits = JSON.parse(localStorage.px_dynamic_pagination);
          tempLimits.businessesLimit = limit;
        } else {
          tempLimits = {
            businessesLimit: limit,
            associatesLimit: this.props.limitPerPage.associatesLimit,
            buildingsLimit: this.props.limitPerPage.buildingsLimit,
            membersLimit: this.props.limitPerPage.membersLimit,
            packagesLimit: this.props.limitPerPage.packagesLimit,
          };
        }
        this.props.dispatch(setDynamicPagination(tempLimits));
        const currentPage = Math.ceil(this.state.totalBusinesses / this.state.limit);
        if (this.state.activePage === currentPage) {
          action = Math.ceil(this.state.totalBusinesses / limit);
        }
      }
    }
    const paginationObj = { route: 'businesses', offset: (action - 1) * (limit || this.state.limit) };
    this.props.dispatch(setPagination(paginationObj));
    this.setState({
      limit: limit || this.state.limit,
      loading: true,
      activePage: action,
      error: null,
      noMore: false,
    }, () => this.getBusinessessData());
  };

  getBusinessessData = () => {
    const { sortBy, sortType, activePage } = this.state;
    const { tab, id, building, t, pagination } = this.props;
    const routes = pagination.map(pg => pg.route);
    const routeIndex = routes.indexOf("businesses");
    const offset = routeIndex > -1 ? pagination[routeIndex].offset : 0;
    this.setState({ activePage: Math.floor(offset / this.state.limit) + 1, loading: true, error: null });
    let buildingId = null;
    if (tab === 'building_businesses') buildingId = id;
    else if (building.length > 0) buildingId = building[0].building_id;
    if (tab === 'business') {
      getBusinesses(this.state.limit, sortBy, sortType, offset, buildingId, id)
        .then(async (businesses) => {
          if (businesses.length === 0 && this.state.activePage > 1) {
            this.setState({ activePage: 1 });
            this.onChangePage(1);
          } else {
            const businessTempData = await this.createRows(businesses);
            this.setState({
              businessData: businesses,
              businesses: businessTempData,
              loading: false,
              noMore: businesses.length < (this.state.limit),
              totalBusinesses:
                businesses.length > 0 && businesses[0].business_count,
            });
          }
        })
        .catch(() =>
          this.setState({ error: t('common.error.networkError'), loading: false }),
        );
    } else if (tab === 'building_businesses') {
      if (this.props.searchedBusiness) {
        this.props.stopSearch();
        searchBusiness(
          this.props.searchedBusiness,
          buildingId,
          this.state.limit,
          (activePage - 1) * (this.state.limit),
          this.state.sortBy,
          sortType,
        ).then(businesses => {
          this.setState({
            loading: false,
            businessData: businesses,
            businesses: this.createRows(businesses),
            totalBusinesses:
              businesses.length > 0 && businesses[0].business_count,
          });
        })
          .catch(err => {
            let error =
              err.message.indexOf('status code') > -1
                ? t('common.error.networkErrorMessage')
                : t('common.error.networkError');
            this.setState({ error, loading: false });
          });
      } else {
        getBusinesses(this.state.limit, sortBy, sortType, offset, id)
          .then(businesses => {
            if (businesses.length === 0 && this.state.activePage > 1) {
              this.setState({ activePage: 1 });
              this.onChangePage(1);
            } else {
              this.setState({
                businessData: businesses,
                businesses: this.createRows(businesses),
                loading: false,
                noMore: businesses.length < (this.state.limit),
                totalBusinesses:
                  businesses.length > 0 && businesses[0].business_count,
              });
            }
          })
          .catch(() =>
            this.setState({ error: t('common.error.networkError'), loading: false }),
          );
      }
    } else if (this.props.searchedBusiness) {
      this.props.stopSearch();
      searchBusiness(
        this.props.searchedBusiness,
        buildingId,
        this.state.limit,
        (activePage - 1) * (this.state.limit),
        this.state.sortBy,
        sortType,
      ).then(businesses => {
        this.setState({
          loading: false,
          businessData: businesses,
          businesses: this.createRows(businesses),
          totalBusinesses:
            businesses.length > 0 && businesses[0].business_count,
        });
      })
        .catch(err => {
          let error =
            err.message.indexOf('status code') > -1
              ? t('common.error.networkErrorMessage')
              : t('common.error.networkError');
          this.setState({ error, loading: false });
        });
    } else {
      getBusinesses(this.state.limit, sortBy, sortType, offset, buildingId, id)
        .then(businesses => {
          if (businesses.length === 0 && this.state.activePage > 1) {
            this.setState({ activePage: 1 });
            this.onChangePage(1);
          } else {
            this.setState({
              businessData: businesses,
              businesses: this.createRows(businesses),
              loading: false,
              noMore: businesses.length < (this.state.limit),
              totalBusinesses:
                businesses.length > 0 && businesses[0].business_count,
            });
          }
          this.props.stopSearch();
        }).catch(() =>
          this.setState({
            error: t('common.error.networkError'),
            loading: false,
          }),
        );
    }
  };

  getData = (sorter) => {
    if (!sorter) {
      this.setState({
        loading: true,
        error: null,
        noMore: false,
        businesses: [],
        activePage: 1,
        sortBy: 'name',
        sortType: true,
      }, () => this.onChangePage(1));
    }
    const {
      tab,
      id,
      building,
      pagination,
      t,
      searchedBusiness,
    } = this.props;
    const { sortBy, sortType } = this.state;
    let buildingId = null;
    const routes = pagination.map(pg => pg.route);
    const routeIndex = routes.indexOf('businesses');
    const offset = routeIndex > -1 ? pagination[routeIndex].offset : 0;
    if (tab === 'building_businesses') buildingId = id;
    else if (building.length > 0) buildingId = building[0].building_id;
    if (searchedBusiness) {
      this.props.stopSearch();
      if (this.state.loading) {
        searchBusiness(
          this.props.searchedBusiness,
          buildingId,
          this.state.limit,
          0,
          this.state.sortBy,
          sortType,
        )
          .then(businesses => {
            this.setState({
              businessData: businesses,
              businesses: this.createRows(businesses),
              loading: false,
              totalBusinesses:
                businesses.length > 0 && businesses[0].business_count,
            });
          })
          .catch(err => {
            let errorMessage =
              err.message.indexOf('status code') > -1
                ? t('common.error.networkErrorMessage')
                : t('common.error.networkError');
            this.setState({
              error: errorMessage,
              loading: false,
            });
          });
      }
    } else {
      this.props.stopSearch();
      this.setState({ activePage: Math.floor(offset / this.state.limit) + 1 });
      getBusinesses(this.state.limit, sortBy, sortType, offset, buildingId)
        .then(businesses => {
          if (businesses.length === 0 && this.state.activePage > 1) {
            this.setState({ activePage: 1 });
            this.onChangePage(1);
          } else {
            this.setState({
              businessData: businesses,
              businesses: this.createRows(businesses),
              loading: false,
              noMore: businesses.length < (this.state.limit),
              totalBusinesses:
                businesses.length > 0 && businesses[0].business_count,
            });
          }
        })
        .catch(err => {
          let errorMessage =
            err.message.indexOf('status code') > -1
              ? t('common.error.networkErrorMessage')
              : t('common.error.networkError');
          this.setState({
            error: errorMessage,
            loading: false,
          });
        });
    }
  };

  createRows = businessess => {
    const { fullScreen } = this.props;

    return businessess.map(business => ({
      id: business.business_id,
      logo: (
        <Link to={`/business_detail/${business.business_id}`}>
          {business.logo ? (
            <img
              style={{ height: 32, width: 32 }}
              src={`data:image/jpg;base64,${business.logo}`}
              alt="members"
            />
          ) : <BusinessCenter className="icon" />}
        </Link>
      ),
      name:
        business.name.length > 20 ? (
          <Tooltip TransitionComponent={Zoom} title={business.name}>
            <Link
              to={`/business_detail/${business.business_id}`}
              style={{
                display: "flex",
                gap: "10px"
              }}
            >
              {truncateLabel(business.name) || '  ---'}

              {business.membership && business.membership === 'VO_BUSINESS' && (<Badge className="badge fs-14px white bg-black" style={{ marginRight: "10px" }}>
                {'MP'}
              </Badge>)}
            </Link>

          </Tooltip>
        ) : (
          <Link
            to={`/business_detail/${business.business_id}`}
            style={{
              display: "flex",
              gap: "10px"
            }}>
            {truncateLabel(business.name) || '  ---'}
            {business.membership && business.membership === 'VO_BUSINESS' && (<Badge className="badge fs-14px white bg-black" style={{ marginRight: "10px" }}>
              {'MP'}
            </Badge>)}
          </Link>
        ),
      designated_members: (
        <Link
          to={`/member/business_designated_members/${business.business_id}`}
          // component={Button}
          onClick={() =>
            this.props.rerender('business_designated_members', business.business_id)
          }
        >
          {business.designated_members ? business.designated_members.length : 0}
        </Link>
      ),
      building_name:
        !business.building_name || business.building_name === null ? (
          '  ---'
        ) : business.building_name.length > 20 ? (
          <Tooltip TransitionComponent={Zoom} title={business.building_name}>
            <Link
              className={
                this.props.userRole === 'mailroom_supervisor' && 'disabled-link'
              }
              to={`/building_detail/${business.building_id}`}
              key={business.building_name}
              style={{ width: fullScreen ? 60 : 140 }}
            >
              {truncateLabel(business.building_name)}
            </Link>
          </Tooltip>
        ) : (
          <Link
            className={
              this.props.userRole === 'mailroom_supervisor' && 'disabled-link'
            }
            to={`/building_detail/${business.building_id}`}
            key={business.building_name}
            style={{ width: fullScreen ? 60 : 140 }}
          >
            {truncateLabel(business.building_name)}
          </Link>
        ),
      total_members: business.active_member_counts ? (
        <Link
          to={business.membership === 'VO_BUSINESS' ? `/member/mp_members/total_business_members/${business.business_id}` : `/member/total_business_members/${business.business_id}`}
          onClick={() =>
            this.props.rerender('total_business_members', business.business_id)
          }
        >
          {business.active_member_counts || '0'}
        </Link>
      ) : '0',
      pending_packages: (
        <Link
          to={`/packages/business_packages/${business.business_id}/outstanding`}
          onClick={() => this.handleRedirectToPackages(business.building_id)}
        >
          {business.business_packages_count || '0'}
        </Link>
      ),
      total_packages: (
        <div className="mailroom-data">
          <Link
            to={business.membership === 'VO_BUSINESS' ? `/mp-items/business_member_packages/${business.business_id}` : `/packages/business_member_packages/${business.business_id}`}
            onClick={() => this.handleRedirectToPackages(business.building_id)}
          >
            {this.props.t('associates.viewList')}
          </Link>
        </div>
      ),
    }));
  };

  handleRedirectToPackages = (buildingID) => {
    const { allCountries } = this.props;
    const currentBuilding = getBuildingFromCountries(allCountries, buildingID);
    this.props.dispatch(setBuilding([currentBuilding[0]]));
  }

  handleSwitchChange(index) {
    if (this.state.tableHeads[index].expanded) {
      const totalActive = [...this.state.tableHeads].filter(
        item => item.expanded === true,
      ).length;
      if (totalActive > 1) {
        const tempArray = [...this.state.tableHeads];
        tempArray[index].expanded = !tempArray[index].expanded;
        this.setState({ tableHeads: [...tempArray] });
      } else {
        this.setState({
          oneTableColumn: this.props.t('common.error.atleastOneActiveColumn'),
        });
      }
    } else {
      const tempArray = [...this.state.tableHeads];
      tempArray[index].expanded = !tempArray[index].expanded;
      this.setState({
        tableHeads: [...tempArray],
      });
    }
  }

  handleSortByColumn = columnName => {
    let { sortBy, sortType } = this.state;
    if (sortBy === sortByBusiness[columnName]) sortType = !sortType;
    else if (!sortByBusiness[columnName]) sortType = false;
    else sortType = true;
    const ASC = sortType ? 'ASC' : 'DESC';
    this.setState({
      loading: true,
      sortBy: sortByBusiness[columnName],
      sortType,
      businessData: [],
      businesses: [],
      order_asc: ASC,
    }, () => this.getData(true));
  };

  filterView = (name, saveTableColumn) => {
    this.setState({ [name]: this.state.view === 'list' ? 'filter' : 'list' });
    this.props.getFilterValue(this.state.view === 'list' ? 'filter' : 'list');
    if (saveTableColumn) {
      const { tableHeads } = this.state;
      const originalColumn = this.props.tableColumn.bussiness;
      const updatedBussinessesArray = tableHeads.map((item, index) => {
        let tempColumn = originalColumn[index];
        tempColumn.expanded = item.expanded;
        return tempColumn;
      });
      const tempTableColumn = {
        bussiness: updatedBussinessesArray,
        associates: this.props.tableColumn.associates,
        buildings: this.props.tableColumn.buildings,
        members: this.props.tableColumn.members,
        packages: this.props.tableColumn.packages,
        voMembers: this.props.tableColumn.voMembers && this.props.tableColumn.voMembers,
        voBuilding: this.props.tableColumn.voBuilding && this.props.tableColumn.voBuilding,
      };
      this.props.dispatch(setTableColumnFilter(tempTableColumn));
    }
  };

  tableRows(item) {
    const { tableHeads } = this.state;
    let tableRowCells = [];
    if (tableHeads[0].expanded) {
      tableRowCells.push(
        <TableCell
          className="thumbnail-cell width-60px"
          style={{ cursor: 'default' }}
        >
          {' '}
          {item.logo}{' '}
        </TableCell>,
      );
    }
    if (tableHeads[1].expanded) {
      tableRowCells.push(
        <TableCell className="padding-right-left truncated-heading width-200px">
          <span>{item.name}</span>
        </TableCell>,
      );
    }
    if (tableHeads[2].expanded) {
      tableRowCells.push(
        <TableCell className="padding-right-left truncated-heading width-200px">
          {item.building_name}
        </TableCell>,
      );
    }
    if (tableHeads[3].expanded) {
      tableRowCells.push(
        <TableCell className="padding-left-45px truncated-heading width-100px">
          {item.total_members}
        </TableCell>,
      );
    }
    if (tableHeads[4].expanded) {
      tableRowCells.push(
        <TableCell className="padding-left-45px truncated-heading width-100px">
          {item.designated_members}
        </TableCell>,
      );
    }
    // if (tableHeads[5] && tableHeads[5].expanded) {
    //   tableRowCells.push(
    //     <TableCell className="padding-left-45px truncated-heading width-100px">
    //       {item.pending_packages}
    //     </TableCell>,
    //   );
    // }
    if (tableHeads[5] && tableHeads[5].expanded) {
      tableRowCells.push(
        <TableCell className="padding-left-28px truncated-heading width-100px">
          {item.total_packages}
        </TableCell>,
      );
    }
    return tableRowCells;
  }

  render() {
    const {
      businesses,
      loading,
      error,
      businessId,
      totalBusinesses,
      sortBy,
      sortType,
      tableHeads,
      activePage,
      view,
      oneTableColumn,
    } = this.state;

    const { style, t } = this.props;
    const showingColumns = [...tableHeads].filter(item => item.expanded === true);
    const activeColumns =
      showingColumns.length === tableHeads.length ? t('associates.showing')
        : `Showing ${showingColumns.length} of ${tableHeads.length} columns`;
    const tableMessage = businesses.length > 0 ? '' : t('tables.noBusiness');

    if (businessId) return <Redirect to={`/business_detail/${businessId}`} />;
    return (
      <Fragment>
        <Grid item md={12} lg={12} className="container">
          {oneTableColumn && (
            <Alert
              variant="error"
              open
              message={oneTableColumn}
              onClose={() => this.setState({ oneTableColumn: '' })}
            />
          )}
          <div className="members-business-table">
            <SpaceBetween>
              <div
                className="justify-center"
                style={{ paddingTop: '10px', paddingBottom: '10px' }}
              >
                <p className="font-in-bold">{activeColumns}</p>
              </div>
              <IconButton onClick={() => this.filterView('view')}>
                <Settings
                  style={
                    view === 'filter'
                      ? {
                        ...style.common.fs_14,
                        ...style.colors.blue,
                        fontSize: 20,
                      }
                      : {
                        ...style.common.fs_14,
                        fontSize: 20,
                      }
                  }
                />
              </IconButton>
            </SpaceBetween>
            <Divider />
            {
              <React.Fragment>
                <MaterialUITable
                  t={t}
                  loading={loading}
                  view={view}
                  tableHeads={tableHeads}
                  tableData={businesses}
                  handleSwitchChange={this.handleSwitchChange}
                  handleSortByColumn={this.handleSortByColumn}
                  tableRows={this.tableRows}
                  sortBy={sortBy}
                  sortType={sortType}
                  message={tableMessage}
                  error={error}
                  changeView={this.filterView}
                  sortDataColumns={sortByBusiness}
                />
              </React.Fragment>
            }
          </div>
        </Grid>
        {businesses && businesses.length > 0 && view === 'list' && !error && (
          <div className="table-pagination">
            <PaginationComponent
              loading={loading}
              totalItems={totalBusinesses === '---' ? 0 : totalBusinesses}
              activePage={activePage}
              itemsPerPage={this.state.limit}
              availableItems={businesses.length}
              onChange={this.onChangePage}
              showLimit
            />
          </div>
        )}
      </Fragment>
    );
  }
}

BusinessesTable.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  tab: PropTypes.string.isRequired,
  building: PropTypes.node.isRequired,
  id: PropTypes.number.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  rerender: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  searchedBusiness: PropTypes.node.isRequired,
  startSearch: PropTypes.node.isRequired,
  getFilterValue: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
  stopSearch: PropTypes.func.isRequired,
  pagination: PropTypes.array.isRequired,
  businessTempSearch: PropTypes.string.isRequired,
  limitPerPage: PropTypes.number.isRequired,
  allCountries: PropTypes.node.isRequired,
  tableColumn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tableColumn: state.tableColumn,
  limitPerPage: state.limitPerPage,
  allCountries: state.countries,
});

export default withMobileDialog()(connect(mapStateToProps)(BusinessesTable));
