/* eslint-disable space-before-function-paren */
/* eslint-disable quotes */
/* eslint-disable func-names */
import { hot } from 'react-hot-loader';
import React, { Component } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';

import { Auth } from 'aws-amplify';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { refreshToken, signout } from '../../server/auth';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import { informSlackDBNetworkAPIErrs } from '../../util/informSlack';
import Router from './Router';
import theme from '../../server/theme';
import { usersnapKey } from '../../server/server';
import jsonPKG from '../../../package.json';
import '../../scss/app.scss';

const env = process.env.REACT_APP_ENV;

i18next.init(i18nextConfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: true,
      logout: false,
    };
    theme();
    this.instantiateEnv();
    if (env && env.includes('cn')) {
      refreshToken()
        .then(() => {})
        .catch(() => {
          signout()
            .then(() => this.setState({ logout: true }))
            .catch(() => this.setState({ logout: true }));
          this.setState({ logout: true });
        });
    }
    this.initializeReactGA();
    this.initiateUserSnap();
  }

  componentDidMount() {
    console.log(jsonPKG.version);
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  onUnload = () => {
    // the method that will be used for both add and remove event
    if (localStorage.exportingData && JSON.parse(localStorage.exportingData)) {
      localStorage.removeItem('exportingData');
      localStorage.removeItem('exportingDataId');
    }
  };

  initializeReactGA = () => {
    ReactGA.initialize('UA-145339592-1');
    ReactGA.pageview('/dashboard');
  };

  instantiateEnv = () => {
    axios.defaults.params = {};
    axios.interceptors.request.use((config) => {
      // config.params.tenant = sessionStorage.px_tenant;
      if (config && !config.url.includes('hooks.slack')) {
        if (env && env.includes('cn')) {
          let token = localStorage.px_auth;
          if (token) {
            try {
              token = JSON.parse(window.atob(token)).access_token;
            } catch (err) {
              informSlackDBNetworkAPIErrs(err, config.url);
            }
          }
          if (
            config.url.includes('login') ||
            config.url.includes('generate_code') ||
            config.url.includes('forgot_password')
          ) {
            if (config.url.includes('first_login')) {
              config.headers.common.Authorization = `Bearer ${token}`;
              config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
              return Promise.resolve(config);
            } else {
              config.headers.common.Authorization = token;
              return Promise.resolve(config);
            }
          } else if (config.url.includes('refresh')) {
            config.headers.common.Authorization = token;
            return Promise.resolve(config);
          } else {
            return new Promise((resolve) => {
              let refreshing = false;
              try {
                refreshing = JSON.parse(localStorage.px_refreshing);
              } catch (e) {
                informSlackDBNetworkAPIErrs(e, config.url);
              }
              if (refreshing) {
                const interval = setInterval(() => {
                  const pxrefreshing = JSON.parse(localStorage.px_refreshing);
                  if (!pxrefreshing) {
                    clearInterval(interval);
                    resolve();
                  }
                }, 1000);
              } else resolve();
            }).then(() => {
              try {
                token = JSON.parse(window.atob(localStorage.px_auth))
                  .access_token;
              } catch (e) {
                informSlackDBNetworkAPIErrs(e, config.url);
              }
              config.headers.common.Authorization = token;
              return Promise.resolve(config);
            });
          }
        } else if (config.url.includes('amazonaws.com')) {
          delete config.headers;
          config.headers = {};
          config.headers['Content-Type'] = 'text/csv';
          return Promise.resolve(config);
        } else {
          return Auth.currentSession()
            .then((session) => {
              config.headers.common.Authorization = session.idToken.jwtToken;
              
              return Promise.resolve(config);
            })
            .catch((err) => {
              informSlackDBNetworkAPIErrs(err);
              if (!localStorage.getItem('onboarding')) {
                window.location.reload();
              }
            });
        }
      }
      return Promise.resolve(config);
    });

    axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (error.config && !error.config.url.includes('hooks.slack')) {
          informSlackDBNetworkAPIErrs(error);
        }
        if (error.response && error.response.status === 401) {
          signout();
        }
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.message.message
        ) {
          error.response.data.message = error.response.data.message.message;
          return Promise.reject(error.response.data);
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error);
      }
    );
  };

  initiateUserSnap = () => {
    const s = document.createElement('script');
    s.async = true;
    s.src = `//api.usersnap.com/load/${usersnapKey}.js`;
    // document.getElementsByTagName('body')[0].appendChild(s);
  };

  render() {
    const { loaded, loading, logout } = this.state;
    return (
      <I18nextProvider i18n={i18next}>
        <ScrollToTop>
          {logout && <Redirect to="/" />}
          {!loaded && (
            <div className={`load${loading ? '' : ' loaded'}`}>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path
                    fill="rgb(29, 168, 235)"
                    d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                  />
                </svg>
              </div>
            </div>
          )}
          <Router />
        </ScrollToTop>
      </I18nextProvider>
    );
  }
}

export default hot(module)(App);
