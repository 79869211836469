import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Dialog from './components/Dialog';
import { updateNotifications } from '../../../server/notifications';
import { getURLParamsNotification } from '../../../util/application.utils';
import { updateNotificationsAction } from '../../../redux/actions/notificationAction';

class NotificationItemDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            packageId: undefined
        };
    }


    componentDidMount() {
        const params = getURLParamsNotification();
        if (params === null || !/^-?[0-9]+$/.test(params[0]) || !/^-?[0-9]+$/.test(params[1]) || (params[2] !== 'true' && params[2] !== 'false')) {
            this.props.history.push('/404');
        }
        if (params[2] === 'true') {
            updateNotifications({
                notification_id: [params[0]],
                is_read: true
            })
            .then(() => {
                this.props.updateNotificationsAction(true);
            })
            .catch(err => console.log(err));
        }
        this.setPackageId(params[1]);
    }

    setPackageId = id => this.setState({ packageId: id });


    render() {
        const { packageId } = this.state;
        const { t, fullScreen } = this.props;
        return (
            <div className="dashboard container">
                {packageId && <Dialog packageId={packageId} t={t} fullScreen={fullScreen} />}
            </div>
        );
    }
}

NotificationItemDetail.propTypes = {
    t: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    updateNotificationsAction: PropTypes.func.isRequired
  };
  

  const mapDispatchToProps = dispatch => ({
    updateNotificationsAction: flag => dispatch(updateNotificationsAction(flag)),
  });

export default compose(
    withRouter,
    connect(null,mapDispatchToProps)
  )(NotificationItemDetail);
