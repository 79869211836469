/* eslint-disable quotes */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import { translate } from 'react-i18next';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import OnBoardingLayout from '../Layout/OnBoarding/index';

import Login from '../Dashboard/Login/';

import Members from '../Dashboard/Members/index';
import MemberDetail from '../Dashboard/MemberDetail/index';
import AddMember from '../Dashboard/AddMember/index';

import Packages from '../Dashboard/Packages/index';
import VoItems from '../Dashboard/VoItems/index';

import BusinessDetail from '../Dashboard/BusinessDetail/index';
import AddBusiness from '../Dashboard/AddBusiness/index';

import Buildings from '../Dashboard/Buildings/index';
import AddBuilding from '../Dashboard/AddBuilding/index';
import BuildingDetail from '../Dashboard/BuildingDetail/index';

import Associates from '../Dashboard/Associates/index';
import AssociateDetail from '../Dashboard/AssociateDetail/index';
import AddAssociate from '../Dashboard/AddAssociate/index';

import Settings from '../Dashboard/Settings/index';
import UserSettings from '../Dashboard/UserAccountSettings/index';

import UploadCSV from '../Dashboard/UploadCSV/index';

import Profile from '../Dashboard/Profile/index';

import OnBoarding from '../Dashboard/OnBoarding/index';
import PaymentModals from '../Dashboard/PaymentModals/index';

import ManagePlans from '../Dashboard/ManagePlans/index';

import NotificationCenter from '../Dashboard/NotificationCenter/index';
import NotificationItemDetail from '../Dashboard/NotificationItemDetail/index';

import {
  signout,
  getProfile,
  getRoles,
  changePassword,
  getMember,
  getPackages,
  getPackagesBySearch,
  getBusiness,
  addMember,
  updateMember,
  deleteMember,
  searchBusiness,
  addBusiness,
  getPackageDetail,
  getPackageImage,
  updateBusiness,
  getDesignatedMembers,
  getBuildings,
  addBuilding,
  updatePreferences,
  getBuilding,
  getBuildingUsers,
  getBuildingMailrooms,
  getPrefs,
  createMailroom,
  createAssociate,
  getAssociate,
  updateAssociate,
  updateBuilding,
  getBuildingHierarchy,
  searchMember,
  searchBuilding,
  searchAssociate,
  searchPackage,
  getLocations,
  generalPackageSearch,
  TextPackageSearch,
  getBuildingsEmailTemplates,
  handleMark,
  validateCSV,
  getBuildingScanCount,
  deleteAssociate,
  getCountryStateCities,
  sendPackagesCSV,
  getNotificationPrefs,
  updateNotificationPrefs,
} from '../../server';
import {
  validateEmail,
  validateName,
  validateBusinessName,
  validateBuildingAndAssociateName,
  generalValidation,
  incrementLast,
} from '../../util';

import { styles as style } from '../../common/style';
import Main from '../Dashboard/Main';
import EmailTemplate from '../Dashboard/EmailTemplatePage';
import { formatPhoneNumber } from '../../util/utility.functions';
import NotFound from '../Dashboard/NoRoute/notAuth';
import APIExpose from '../Dashboard/APIExpose';

const serverprops = {
  signout,
  getProfile,
  getRoles,
  changePassword,
  getMember,
  addMember,
  updateMember,
  deleteMember,
  getBuildingScanCount,
  style,
  validateEmail,
  validateName,
  validateBusinessName,
  validateBuildingAndAssociateName,
  generalValidation,
  incrementLast,
  getPackages,
  getPackagesBySearch,
  getBusiness,
  searchBusiness,
  addBusiness,
  updateBusiness,
  getDesignatedMembers,
  getBuildings,
  getPackageDetail,
  getPackageImage,
  addBuilding,
  updatePreferences,
  getBuilding,
  getBuildingUsers,
  getBuildingMailrooms,
  getPrefs,
  createMailroom,
  createAssociate,
  getAssociate,
  updateAssociate,
  updateBuilding,
  getBuildingHierarchy,
  searchMember,
  searchBuilding,
  searchAssociate,
  searchPackage,
  getLocations,
  generalPackageSearch,
  TextPackageSearch,
  getBuildingsEmailTemplates,
  handleMark,
  validateCSV,
  formatPhoneNumber,
  deleteAssociate,
  getCountryStateCities,
  sendPackagesCSV,
  getNotificationPrefs,
  updateNotificationPrefs,
};
const changePassRoute = true;

let env = process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV
  : "development";
const OnBoardRoutes = (props) => (
  <div>
    <OnBoardingLayout {...props} {...serverprops} />
    <div className="absolute-height-width-100pc">
      <Route
        exact
        path="/onBoarding"
        render={() => <OnBoarding {...props} {...serverprops} />}
      />
    </div>
  </div>
);

const WrappedRoutes = (props) => (
  <div>
    <Layout {...props} {...serverprops} />
    <div className="container__wrap absolute-height-width-100pc">
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Main {...props} {...serverprops} />}
        />
        <ApmRoute
          path="/dashboard"
          render={() => <Main {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/packages/:module?/:id?/:filter?"
          render={() => <Packages {...props} {...serverprops} />}
        />
        {(env.includes('wework') || env.includes('uat')) &&
          <Route
            exact
            path="/mp-items/:module?/:id?/:filter?"
            render={() => <VoItems {...props} {...serverprops} />}
          />
        }
        <Route
          exact
          path="/members/all_members"
          render={() => <Members {...props} {...serverprops} />}
        />
        {(env.includes('wework') || env.includes('uat')) &&
          <Route
            exact
            path="/members/mp_members"
            render={() => <Members {...props} {...serverprops} />}
          />
        }
           {(env.includes('wework') || env.includes('uat')) &&
          <Route
            exact
            path="/member/mp_members/:tab?/:id?/:filter?"
            render={() => <Members {...props} {...serverprops} />}
          />
        }
        <Route
          exact
          path="/members/businesses"
          render={() => <Members {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/member/:tab?/:id?/:filter?"
          render={() => <Members {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/member_detail/:id"
          render={() => <MemberDetail {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/business_detail/:id"
          render={() => <BusinessDetail {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/add_member"
          render={() => <AddMember {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/add_business"
          render={() => <AddBusiness {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/buildings/:id?/:country?"
          render={() => <Buildings {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/add_building"
          render={() => <AddBuilding {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/building_detail/:id"
          render={() => <BuildingDetail {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/associates/:module?/:id?"
          render={() => <Associates {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/associate_detail/:id"
          render={() => <AssociateDetail {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/add_associate"
          render={() => <AddAssociate {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/notification-center"
          render={() => <NotificationCenter {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/notification-center/package-detail/"
          render={() => <NotificationItemDetail {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/settings"
          render={() => <Settings {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/api"
          render={() => <APIExpose {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/account_settings"
          render={() => (
            <UserSettings
              {...props}
              {...serverprops}
              changePassRoute={changePassRoute}
            />
          )}
        />
        <Route
          exact
          path="/change_password"
          render={() => <UserSettings {...props} {...serverprops} changePassRoute={changePassRoute} />}
        />
        <Route
          exact
          path="/upload_csv"
          render={() => <UploadCSV {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/building_detail/:id/email/:template_id/:topic/:tenant_id"
          render={() => <EmailTemplate {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/profile"
          render={() => <Profile {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/manage-plan"
          render={() => <ManagePlans {...props} />}
        />
        <Route
          exact
          path="/payment-successful"
          render={() => <PaymentModals {...props} />}
        />
        <Route
          exact
          path="/payment-failed"
          render={() => <PaymentModals {...props} />} // hide CLA SECTION
        />
        <Route render={() => <NotFound />} />
      </Switch>
    </div>
  </div>
);

// OnBoarding

const Router = (props) => (
  <MainWrapper>
    <main>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Login {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/login/:detail?/:key?"
          render={() => <Login {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/forgot_password"
          render={() => <Login {...props} {...serverprops} />}
        />

        <Route
          exact
          path="/reset_password/:email"
          render={() => <Login {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/force_reset_password/:email"
          render={() => <Login {...props} {...serverprops} />}
        />
        <Route
          exact
          path="/user_initial_details/:email"
          render={() => <Login {...props} {...serverprops} />}
        />
        {/* CLA hide Section */}
        <Route
          exact
          path="/onboarding"
          render={() => <OnBoardRoutes {...props} />}
        />
        <Route render={() => <WrappedRoutes {...props} />} />
      </Switch>
    </main>
  </MainWrapper>
);

export default translate('common')(Router);
