/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Paper, withWidth } from '@material-ui/core';
import { withRouter, Redirect, Link } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CreateAssociate from './components/AddAssociate';
import BuildingSelector from './components/BuildingSelector';
import { AlignCenter, VerticallyCenter } from '../../UI/ResponsiveRelated';
import BlueButton from '../../UI/BlueButton';
import Alert from '../../UI/Poper';
import WarningAlert from '../../../shared/components/Alert';
import NotAuthorized from '../../UI/403/403';
import Footer from "../../UI/Footer/Footer";
import TenantSelector from '../TenantSelector';
import { checkAWSSession } from '../../../util/application.utils';

class AddAssociate extends Component {
  constructor(props) {
    super(props);

    let selectedTenantTemp = null;
    let newBuildings = [];
    if (props.buildings) {
      const { buildings } = props;
      if (buildings.length !== 0) {
        selectedTenantTemp = {
          tenant_name: buildings[0].tenant_name,
          tenant_id: buildings[0].tenant_id,
        };

        buildings.map(item => {
          if (item.tenant_id === buildings[0].tenant_id) {
            newBuildings = [...item.buildings];
          }
          return item;
        });
      }
    }

    let selectedMailroomsData = [];
    checkAWSSession(res => {
      if (res) {
        this.setState({
          encryptedEmail: `/onboarding?email=${res}`
        });
      } else {
        selectedMailroomsData = (
          newBuildings
          && newBuildings.length === 1
          && newBuildings[0].mailrooms
          && newBuildings[0].mailrooms.length === 1)
          ? newBuildings[0].mailrooms
          : (newBuildings[0] && newBuildings[0].building_id && newBuildings[0].mailrooms.length === 1)
            ? newBuildings[0].mailrooms
            : [];
      }
    });
    this.state = {
      anchorEl: null,
      mailroomAnchor: null,
      roleDropdownAnchor: null,
      roleDropdown: false,
      picture: null,
      associateName: '',
      associateEmail: '',
      associateEmailError: false,
      associateNameError: false,
      buildingSearching: false,
      selectedBuildings: newBuildings && newBuildings.length === 1 ? newBuildings : [],
      searchedBuildings: newBuildings ? [...newBuildings] : [],
       // selectedMailrooms: selectedMailroomsData,
       selectedMailrooms: newBuildings.length === 1 && newBuildings[0].mailrooms.length === 1
       ? newBuildings[0].mailrooms : selectedMailroomsData,
      roles: [],
      loadingRoles: true,
      rolesError: null,
      selectedRole: '',
      disableAssociateBtn: true,
      creatingAssociate: false,
      associateCreated: false,
      error: null,
      redirect: false,
      selectedTenant: selectedTenantTemp,
      encryptedEmail: null
    };
  }

  componentDidMount() {
    this.props
      .getRoles()
      .then(roles => {
        const userIsAdmin = [...roles].filter(item => item.name === 'admin').length === 0;
        this.setState({
          roles:
            roles.length === 1
              ? roles
              : userIsAdmin
                ? roles.slice(roles.length - 2, roles.length)
                : roles.slice(roles.length - 3, roles.length),
          loadingRoles: false,
          selectedRole:
            roles.length === 1
              ? roles[0].value
              : userIsAdmin
                ? roles.slice(roles.length - 2, roles.length)[0].value
                : roles.slice(roles.length - 3, roles.length)[0].value,
        });
      })
      .catch(error =>
        this.setState({
          rolesError:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message,
          loadingRoles: false,
        }),
      );
    this.interval = setInterval(() => {
      const { buildings } = this.props;

      if (buildings.length !== 0) {
        const { tenant_name, tenant_id } = buildings[0];
        let newBuildings = [];

        buildings.map(item => {
          if (item.tenant_id === tenant_id) {
            newBuildings = [...item.buildings];
          }
          return item;
        });
        this.setState({
          selectedTenant: {
            tenant_name,
            tenant_id,
          },
          searchedBuildings: newBuildings,
        });
        clearInterval(this.interval);
      }
    }, 50);
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.searchedBuilding) {
      if (this.state.searchedBuildings !== prevState.searchedBuildings) {
        if (this.state.searchedBuildings.length === 0) {
          this.componentUpdateState();
        }
      }
    }
    if (prevProps.buildings !== this.props.buildings) {
      const { selectedTenant } = this.state;
      if (selectedTenant) {
        let { buildings } = this.props;
        let newBuildings = [];

        buildings.map(item => {
          if (item.tenant_id === selectedTenant.tenant_id) {
            newBuildings = [...item.buildings];
          }
          return item;
        });
        this.setState({
          searchedBuildings: [...newBuildings],
          selectedBuildings: newBuildings.length === 1 ? newBuildings.buildings : [],
        });
      }
    }
    if (prevState.selectedTenant !== this.state.selectedTenant) {
      this.componentUpdateState();
    }
  }

  componentUpdateState = () => {
    const { selectedTenant } = this.state;

    let { buildings } = this.props;
    if (selectedTenant) {
      let newBuildings = [];
      buildings.map(item => {
        if (item.tenant_id === selectedTenant.tenant_id) {
          newBuildings = [...item.buildings];
        }
        return item;
      });
      this.setState({ searchedBuildings: newBuildings });
    }
  };

  handleChange = name => event => {
    let valueOfField = event.target.value;
    this.resetWarning();
    if (typeof valueOfField === 'string') {
      valueOfField = valueOfField.trimLeft();
    }
    if (name === 'selectedRole' && valueOfField !== this.state.selectedRole) this.setState({ selectedBuildings: [], selectedMailrooms: [] });
    this.setState({ [name]: valueOfField }, () => {
      const { validateEmail, validateBuildingAndAssociateName } = this.props;
      const { associateEmail } = this.state;
      this.setState({
        associateEmailError: associateEmail.length
          ? !validateEmail(associateEmail)
          : false,
      });
      if (name === 'associateName') {
        this.setState({
          associateNameError: valueOfField.length > 0 ? !validateBuildingAndAssociateName(valueOfField.trim()) : false,
        });
      }
    });
  };

  handleClickAway = () => {
    const { selectedTenant } = this.state;
    let { buildings } = this.props;
    let newBuildings = [];

    buildings.map(item => {
      if (item.tenant_id === selectedTenant.tenant_id) {
        newBuildings = [...item.buildings];
      }
      return item;
    });
    this.setState({
      anchorEl: null,
      searchedBuildings: [...newBuildings],
    });
  }

  handleSearch = event => {
    const { buildings } = this.props;
    const { selectedTenant } = this.state;
    let newBuildings = [];
    if (selectedTenant) {
      buildings.map(item => {
        if (item.tenant_id === selectedTenant.tenant_id) {
          newBuildings = [...item.buildings];
        }
        return item;
      });
    }

    if (event && event.target.value.length === 0) {
      this.setState({ searchedBuildings: newBuildings });
    } else {
      let searchedBuildings = newBuildings.map(building => {
        if (
          building.building_name
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        ) {
          return building;
        }
        return null;
      });
      searchedBuildings = searchedBuildings.filter(obj => obj);
      this.setState({ searchedBuildings });
    }
  };

  createAssociate = () => {
    const {
      associateName,
      associateEmail,
      associateEmailError,
      associateNameError,
      roles,
      selectedRole,
      selectedBuildings,
      selectedMailrooms,
      picture,
    } = this.state;
    const { t, createAssociate, userRole } = this.props;
    if (associateName.length === 0) {
      this.setState({ warning: t('buildings.nameNotEmpty') });
    } else if (associateEmail.length === 0) {
      this.setState({ warning: t('emailEmpty') });
    } else if (associateEmailError) {
      this.setState({ warning: t('emailValidation') });
    } else if (associateNameError) {
      this.setState({ warning: t('common.error.nameValidation') });
    } else if (
      selectedRole.toLowerCase() === 'operator' &&
      selectedMailrooms.length === 0
    ) {
      this.setState({ warning: t('associates.mailroomMustBeSelected') });
    } else if (
      selectedRole.toLowerCase() === 'manager' &&
      selectedBuildings.length === 0
    ) {
      this.setState({ warning: t('buildingValidation4') });
    } else {
      this.setState({
        creatingAssociate: true,
        disableAssociateBtn: true,
        error: null,
      });
      const onlyRoles = roles.map(role => role.value);
      const roleIndex = onlyRoles.indexOf(selectedRole);
      let associate = {
        user_name: associateName.trim(),
        user_email: associateEmail.trim(),
        role_id: roles[roleIndex].role_id,
      };
      if (picture) associate.user_image = picture.split('base64,')[1];
      if (selectedRole.toLowerCase() === 'operator') {
        associate.access_policy = {
          mailroom_id: selectedMailrooms.map(mlrm => String(mlrm.mailroom_id)),
        };
      } else {
        associate.access_policy = {
          building_id: selectedBuildings.map(building =>
            String(building.building_id),
          ),
        };
      }
      if (userRole === 'super_admin' || userRole === 'super_admin') {
        const { tenant_id } = this.state.selectedTenant;
        associate.tenant = tenant_id;
      }
      if (selectedRole === 'Admin') {
        associate.access_policy = {};
      }
      createAssociate(associate)
        .then(asoci => {
          const env = process.env.REACT_APP_ENV;
          if (env && env.includes('cn')) {
            this.setState({
              associateId: asoci.data.user_data.success.uuid,
              associateCreated: true,
              creatingAssociate: false,
              disableAssociateBtn: false,
            });
          } else {
            this.setState({
              associateId: asoci.data.user_data.User.Username,
              associateCreated: true,
              creatingAssociate: false,
              disableAssociateBtn: false,
            });
          }
        })
        .catch(error => {
          this.setState({
            error: error.message.includes(
              'An account with the given email already exists.'
            )
              ? t('common.error.ExistsEmailForAssociates')
              : error.message || t('common.error.something'),
            associateCreated: false,
            creatingAssociate: false,
            disableAssociateBtn: false,
          });
        });
    }
  };

  resetWarning = () => this.setState({ warning: null });

  removeBuilding = index => {
    const selectedBuildings = this.state.selectedBuildings.filter(
      (value, indx) => {
        if (indx !== index) return value;
        return null;
      },
    );
    this.setState({ selectedBuildings, selectedMailrooms: [] });
  };

  redirect = () => this.setState({ redirect: true });

  closeAlert = () => this.setState({ warning: null });

  openBuildingList = e => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : e.currentTarget,
    });
  };

  closeBuildingList = () => this.setState({ anchorEl: null });

  closeMailroomList = () => this.setState({ mailroomAnchor: null });

  openMailroomList = e => {
    this.setState({
      mailroomAnchor: this.state.mailroomAnchor ? null : e.currentTarget,
    });
  };

  selectBuilding = (name, building) => {
    if (this.state.selectedRole.toLowerCase() === "manager") {
      let tempBuildings = this.state.selectedBuildings;
      if (tempBuildings.length > 0) {
        let tempSelected = tempBuildings.findIndex((element) => element.building_id === building.building_id && element);
        if (tempSelected > -1) {
          tempBuildings = tempBuildings.splice(tempSelected, 1);
        } else {
          tempBuildings.push(building);
          this.setState({ [name]: tempBuildings });
        }
      } else this.setState({ [name]: [building] });
    } else {
      const { buildings } = this.props;
      const { selectedTenant } = this.state;
      let newBuildings = [];
      if (selectedTenant) {
        buildings.map(item => {
          if (item.tenant_id === selectedTenant.tenant_id) {
            newBuildings = [...item.buildings];
          }
          return item;
        });
      }
      this.setState({ [name]: [building], anchorEl: null, searchedBuildings: newBuildings });
    }
    if (
      this.state.selectedRole.toLowerCase() === 'operator' &&
      building.mailrooms.length === 1
    ) {
      this.setState({
        selectedMailrooms: building.mailrooms,
      });
    } else this.setState({ selectedMailrooms: [] });
  };

  selectMailroom = (name, mailroom) => {
    let selectedMailrooms = this.state.selectedMailrooms;
    const index = selectedMailrooms
      .map(mlrm => mlrm.mailroom_id)
      .indexOf(mailroom.mailroom_id);
    if (index > -1) selectedMailrooms.splice(index, 1);
    else selectedMailrooms.push(mailroom);
    this.setState({ selectedMailrooms });
  };

  openRoleDropdown = e => {
    this.setState({
      roleDropdown: !this.state.roleDropdown,
      roleDropdownAnchor: this.state.roleDropdownAnchor ? null : e.currentTarget,
    });
  };

  selectRole = selectedRole => {
    this.resetWarning();
    this.setState({
      selectedRole,
      selectedBuildings: [],
      selectedMailrooms: [],
      roleDropdownAnchor: null,
    });
  };

  loadimage = picture => this.setState({ picture });

  handleTenantChange = e => {
    const value = JSON.parse(e.target.value);
    this.setState({
      selectedTenant: {
        tenant_name: value.tenant_name,
        tenant_id: value.tenant_id,
      },
      selectedBuildings: [],
      selectedMailrooms: [],
    });
  }

  render() {
    const {
      warning,
      creatingAssociate,
      associateCreated,
      error,
      redirect,
      associateName,
      associateEmail,
      selectedRole,
      associateEmailError,
      associateNameError,
      selectedBuildings,
      selectedTenant,
      encryptedEmail,
    } = this.state;
    if (encryptedEmail) {
      this.props.history.replace(encryptedEmail);
    }
    const { t, userRole, buildings } = this.props;
    const tenants = buildings.map(eachTenant => {
      const { tenant_name, contact_email, tenant_id } = eachTenant;
      return {
        contact_email,
        tenant_id,
        tenant_name
      };
    });

    if (redirect) return <Redirect to="/associates" />;
    return !userRole ? (
      <VerticallyCenter>
        <AlignCenter>
          <CircularProgress className="blue" size={30} />
        </AlignCenter>
      </VerticallyCenter>
    ) : userRole === 'mailroom_supervisor' ? (
      <NotAuthorized />
    ) : (
      <Fragment>
        <Grid container className="container min-width-100p">
          {error && (
            <Alert
              variant="error"
              open
              message={error}
              onClose={() => this.setState({ error: null })}
            />
          )}
          {associateCreated && (
            <Alert
              variant="success"
              open
              message={t("associates.created")}
              onClose={this.redirect}
            />
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="space-between-with-block">
              <div>
                <div style={{ display: 'flex' }}>
                  <h3 style={{ marginRight: 10, width: 140 }} className="page-title">{t('associates.add')}</h3>
                  {
                    userRole === 'super_admin' &&
                    <TenantSelector
                      t={t}
                      tenants={tenants}
                      selectedTenant={selectedTenant}
                      handleOnchange={this.handleTenantChange}
                    />
                  }
                </div>
                <h3 className="page-subhead subhead">
                  <Link to="/associates/">{t("associates.associates")}</Link>
                  <ArrowRightIcon />
                  {t("associates.add")}
                </h3>
              </div>

              <BlueButton
                width={160}
                disabled={
                  creatingAssociate ||
                  associateName.length === 0 ||
                  associateEmail.length === 0 ||
                  associateEmailError ||
                  associateNameError ||
                  selectedRole.length === 0 ||
                  (selectedRole === 'Admin'
                    ? false
                    : selectedBuildings.length === 0)
                }
                loading={creatingAssociate}
                onClick={this.createAssociate}
              >
                {t("associates.saveAssociate")}
              </BlueButton>
            </div>
            {warning && (
              <WarningAlert
                variant="error"
                onClose={this.closeAlert}
                message={warning}
                open
              />
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Paper className="just-paper height-400-margin-right-bottom-10 overflowx-hidden padding-20px">
                <CreateAssociate
                  {...this.state}
                  {...this.props}
                  loadimage={image => this.loadimage(image)}
                  selectRole={role => this.selectRole(role)}
                  handleChange={this.handleChange}
                  openRoleDropdown={this.openRoleDropdown}
                />
              </Paper>
            </Grid>
            <BuildingSelector
              {...this.state}
              {...this.props}
              height={400}
              selectedTenant={selectedTenant}
              selectBuilding={(name, building) => this.selectBuilding(name, building)}
              selectMailroom={(name, mailroom) => this.selectMailroom(name, mailroom)}
              openMailroomList={this.openMailroomList}
              closeMailroomList={this.closeMailroomList}
              openBuildingList={this.openBuildingList}
              closeBuildingList={this.closeBuildingList}
              handleChange={this.handleChange}
              handleSearch={this.handleSearch}
              removeBuilding={this.removeBuilding}
              handleClickAway={this.handleClickAway}
            />
          </Grid>
        </Grid>
        <Footer />
      </Fragment>
    );
  }
}

AddAssociate.propTypes = {
  t: PropTypes.func.isRequired,
  getBuilding: PropTypes.func.isRequired,
  getBuildingMailrooms: PropTypes.func.isRequired,
};

export default withRouter(
  connect(state => ({
    building: state.locationFilter,
    buildings: state.allBuildings,
    userRole: state.userRole,
  }))(withWidth()(AddAssociate)),
);
