/* eslint-disable */
/* eslint-disable function-paren-newline */
import axios from "axios";
import moment from "moment";
import { server } from "./server";
import { appendTimeAtEnd, appendTimeAtStart } from "../util/utility.functions";

const CancelToken = axios.CancelToken;
let cancel1;
let cancel2;
let cancel3;
let cancel4;
let cancelPkgDetail;
let cancelPkgImg;

export function getTimezone() {
  return new Promise((resolve) => {
    let timezone = moment.tz.guess(true);
    if (timezone === undefined) {
      timezone = "America/New_York";
    }
    resolve(timezone);
  });
}

export function tableDataExportCSV(buildingId, toDate, fromDate, voItems = false) {
  const params = {
    to_date: toDate,
    from_date: fromDate,
    filter_option:
      `scanned,deleted,outstanding,collected,hold,destroy,forward,discarded${voItems ? ',dispatched' : ''}`,
    membership: voItems ? "VO_MEMBER" : "MEMBER"
  };
  if (buildingId !== null) params.building_id = buildingId;
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/csv/download`, {
        params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error));
  });
}

export function tableExportStatus(statusId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/csv/download/${statusId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error));
  });
}

export function downloadCSVFile(path, fileName) {
  const baseURL = process.env.BaseUrlS3;
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseURL}/${path}/${fileName}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
}

export function getBusySlotsData(
  buildingId,
  toDate,
  fromDate,
  status,
  status_filter
) {
  const params = {
    to_date: toDate,
    from_date: fromDate,
    status_value: status,
    status_filter,
    // legacy_graph: false,
  };
  if (buildingId) params.building_id = buildingId;
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/analytics/dashboard/busy-slot`, {
        params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error));
  });
}

export function getPackages(
  limit,
  orderBy,
  asc,
  paginationOffset,
  buildingId,
  searchParams,
  mailroomId,
  fromDate,
  toDate,
  filter,
  businessId,
  memberId,
  type,
  dateFiltering,
  graphFilter,
  onlyBusinessPkgs,
  scanType = "All",
  currentModule = false,
  voPackages = false
) {
  const params = {
    pagination_limit: limit,
    order_by: orderBy,
    order_asc: asc === "ASC",
    include_package_thumbnail: true,
    include_member_businesses: true,
    include_business_member_packages: !onlyBusinessPkgs,
    include_image_links: true,
    platform: "dashboard",
    filter_type: scanType.length === 0 ? "All" : scanType,
    filter_option: "outstanding,collected,hold,destroy,forward,discarded",
    membership: voPackages ? 'VO_MEMBER' : "MEMBER"
  };
  if (paginationOffset || paginationOffset !== 0) {
    params.pagination_offset = paginationOffset;
  }
  if (buildingId) {
    params.building_id = buildingId;
  }
  if (type === "mailroom_packages") {
    params.filter_option = "outstanding";
  }
  if (mailroomId) params.mailroom_id = mailroomId;
  if (fromDate) params.from_date = fromDate;
  if (toDate) params.to_date = toDate;
  if (filter) params.filter_option = filter;
  if (businessId) params.business_id = businessId;
  if (memberId) params.member_id = memberId;
  if (dateFiltering) {
    params.date_filtering = true;
  }
  if (graphFilter) {
    params.graph_type_filter = true;
  }
  // if (currentModule) params.search_business_member_packages = true;
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/package`, {
        params,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getDynamicLabels(tenantId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/label`, {
        tenant_id: tenantId,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error));
  });
}

export function getLabelsByBuildingId(buildingId, tntId) {
  const params = {
    building_id: buildingId,
    tenant_id: tntId,
  };
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/label`, {
        params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error));
  });
}

export function generalPackageSearch(
  query,
  offset,
  limit,
  orderBy,
  asc,
  buildingId,
  fromdate,
  todate,
  mailroomId,
  filter,
  businessId,
  memberId,
  type,
  onlyBusinessPkgs,
  dateFiltering,
  graphFilter,
  scanType = "All",
  searchParams,
  searchBusinessMemberPackages = false,
  voPackages = false,
) {
  const params = {
    include_image_links: true,
    include_package_thumbnail: true,
    include_member_businesses: true,
    include_business_member_packages: !onlyBusinessPkgs,
    filter_type: scanType.length === 0 ? "All" : scanType,
    membership: voPackages ? "VO_MEMBER" : "MEMBER",
    platform: "dashboard"
  };
  let {
    to_date,
    from_date,
    building_id,
    scan_type,
    filter_option,
    action_status,
    package_scanned_by,
    package_belong_to,
    package_businesses,
    selectedMembers2,
    selectedBusinesses,
    package_picked_by,
    package_courier,
    package_label_id,
    package_location_id,
    platform,
  } = searchParams;
  let tempActionStatus = [];
  // let platform = null;
  if (action_status.length !== 0) {
    action_status.forEach((statusObj) => {
      // if (tempActionStatus.indexOf("scanSend") > -1) {
      if (statusObj === "scanSend") {
        platform = "dashboard";
        tempActionStatus.push("snapsend", "request_snapsend");
      }
      // if (tempActionStatus.indexOf("forward") > -1) {
      if (statusObj === "forward") {
        platform = "dashboard";
        tempActionStatus.push("forward", "request_forward");
      }
      // if (tempActionStatus.indexOf("destroy") > -1) {
      if (statusObj === "destroy") {
        platform = "dashboard";
        tempActionStatus.push("destroy", "request_destroy");
      }
      // if (tempActionStatus.indexOf("hold") > -1) {
      if (statusObj === "hold") {
        platform = "dashboard";
        tempActionStatus.push("hold", "request_hold");
      }
    });
  }
  if (platform) {
    params.platform = platform;
  }
  if (type === "mailroom_packages") {
    params.filter_option = "outstanding";
  }
  if (offset || offset !== 0) params.pagination_offset = offset;
  if (limit || limit !== 0) params.pagination_limit = limit;
  if (buildingId) params.building_id = buildingId;
  if (orderBy) params.order_by = orderBy;
  if (asc) params.order_asc = asc === "ASC";
  if (fromdate) params.from_date = fromdate;
  if (todate) params.to_date = todate;
  if (mailroomId) params.mailroom_id = mailroomId;
  if (filter) params.filter_option = filter;
  // if (businessId) params.business_id = businessId;

  // if (memberId) params.member_id = memberId;
  if (selectedBusinesses) {
    params.search_business_member_packages = true;
    if (selectedBusinesses.length > 1) {
      params.business_id = package_businesses.join(",");
    } else if (selectedBusinesses.length === 1) {
      if (Object.keys(selectedBusinesses[0]).length === 2) {
        params.business_id = selectedBusinesses[0].business_id;
      } else {
        params.business_id = package_businesses.join(",");
      }
    }
  }

  if (selectedMembers2 && package_belong_to) {
    if (selectedMembers2.length === package_belong_to.length) {
      if (selectedMembers2.length > 1) {
        params.member_id = package_belong_to.join(",");
      } else if (selectedMembers2.length === 1) {
        if (Object.keys(selectedMembers2[0]).length === 2) {
          params.member_id = selectedMembers2[0].member_id;
        } else {
          params.member_id = package_belong_to.join(",");
        }
      }
    }
  }

  if (dateFiltering) {
    params.date_filtering = true;
  }
  if (to_date)
    params.to_date = appendTimeAtEnd(to_date.toString().substr(0, 10));
  if (from_date)
    params.from_date = appendTimeAtStart(from_date.toString().substr(0, 10));
  // if (searchBusinessMemberPackages) params.search_business_member_packages = true;
  if (from_date) {
    params.from_date = moment(from_date)
      .utc()
      .startOf("day")
      .toISOString();
  }
  if (to_date) {
    params.to_date = moment(to_date)
      .utc()
      .endOf("day")
      .toISOString();
  }

  if (building_id) params.building_id = building_id;
  if (scan_type && scan_type.length !== 0) params.filter_type = scan_type;
  if (filter_option && filter_option.length !== 0) {
    filter_option = filter_option.map((option) =>
      option === "holding" ? "outstanding" : option
    );
    params.filter_option = filter_option.toString();
  }
  if (tempActionStatus && tempActionStatus.length !== 0) {
    params.action_status = tempActionStatus.toString();
  }
  if (package_scanned_by && package_scanned_by.length !== 0)
    params.package_scanned_by = package_scanned_by
      .map((item) => item.id)
      .join(",");
  if (package_scanned_by && package_picked_by.length !== 0)
    params.package_picked_by = package_picked_by.join(",");
  if (package_scanned_by && package_courier && package_courier.length !== 0)
    params.courier = package_courier;
  if (package_courier && package_courier.length !== 0)
    params.courier = package_courier;
  if (graphFilter) {
    params.graph_type_filter = true;
  }
  if (package_location_id && package_location_id.length !== 0)
    params.package_location_id = package_location_id
      .map((item) => item.id)
      .join(",");
  if (package_label_id && package_label_id.length !== 0)
    params.package_label_id = package_label_id.map((item) => item.id).join(",");
  return new Promise((resolve, reject) => {
    getTimezone()
      .then((timezone) => {
        const isQuery =
          query && query.length !== 0
            ? `query=${query}&time_zone=${timezone}`
            : `time_zone=${timezone}`;
        axios
          .get(`${server}/package/search?${isQuery}`, { params })
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
}

export function TextPackageSearch(
  query,
  offset,
  limit,
  orderBy,
  asc,
  buildingId,
  fromdate,
  todate,
  mailroomId,
  filter,
  businessId,
  memberId,
  type,
  onlyBusinessPkgs,
  dateFiltering,
  graphFilter,
  scanType = "All",
  searchBusinessMemberPackages = false,
  voPackages = false
) {
  const params = {
    include_package_thumbnail: true,
    include_image_links: true,
    include_member_businesses: true,
    include_business_member_packages: !onlyBusinessPkgs,
    filter_type: scanType.length === 0 ? "All" : scanType,
    filter_option: "outstanding,collected,hold,destroy,forward,discarded",
    membership: voPackages ? "VO_MEMBER" : "MEMBER",
  };
  if (offset || offset !== 0) params.pagination_offset = offset;
  if (type === "mailroom_packages") {
    params.filter_option = "outstanding";
  }
  if (limit || limit !== 0) params.pagination_limit = limit;
  if (buildingId) params.building_id = buildingId;
  if (orderBy) params.order_by = orderBy;
  if (asc) params.order_asc = asc === "ASC";
  if (fromdate) params.from_date = fromdate;
  if (todate) params.to_date = todate;
  if (mailroomId) params.mailroom_id = mailroomId;
  if (filter) params.filter_option = filter;
  if (businessId) params.business_id = businessId;
  if (memberId) params.member_id = memberId;
  if (dateFiltering) {
    params.date_filtering = true;
  }
  if (graphFilter) {
    params.graph_type_filter = true;
  }
  if (searchBusinessMemberPackages)
    params.search_business_member_packages = true;
  return new Promise((resolve, reject) => {
    getTimezone()
      .then((timezone) => {
        axios
          .get(
            `${server}/package/search/full_text?query=${query}&time_zone=${timezone}`,
            { params }
          )
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
}

export function getPackagesBySearch(
  limit,
  orderBy,
  asc,
  paginationOffset,
  buildingId,
  searchParams,
  mailroomId,
  fromdate,
  todate,
  filter,
  businessId,
  memberId,
  type,
  onlyBusinessPkgs,
  dateFiltering,
  graphFilter,
  scanType = "All",
  currentModule,
  voPackages = false
) {
  let {
    building_id,
    package_courier,
    member_name,
    business_name,
    filter_option,
    from_date,
    to_date,
    package_category,
    package_tracking_number,
    // package_scanned_by,
    package_picked_by,
    package_receiver_state,
    package_receiver_city,
    package_receiver_zip,
    package_sender_city,
    package_sender_state,
    package_sender_zip,
  } = searchParams;
  const category = package_category
    ? package_category.map((item) => item.id)
    : [];
  if (filter_option === "holding") {
    filter_option = "outstanding";
  }
  // filter_option = filter_option.map(option => (option === 'holding' ? 'outstanding' : option));
  // filter_option = filter_option.toString();
  if (from_date) {
    from_date = moment(from_date)
      .utc()
      .toISOString();
  }
  if (to_date) {
    to_date = moment(to_date)
      .utc()
      .toISOString();
  }
  const advanceFilterValues = {
    building_id,
    package_courier,
    member_name,
    business_name,
    filter_option,
    from_date,
    to_date,
    package_category: category.toString(),
    package_tracking_number,
    // package_scanned_by: package_scanned_by ? package_scanned_by.map(item => item.id).toString() : [],
    package_picked_by: package_picked_by ? package_picked_by.toString() : "",
    package_receiver_state,
    package_receiver_city,
    package_receiver_zip,
    package_sender_city,
    package_sender_state,
    package_sender_zip,
    include_image_links: true,
    include_business_member_packages: !onlyBusinessPkgs,
    filter_type: scanType.length === 0 ? "All" : scanType,
  };
  for (const key of Object.keys(advanceFilterValues)) {
    if (!advanceFilterValues[key] || advanceFilterValues[key].length === 0) {
      delete advanceFilterValues[key];
    }
  }
  advanceFilterValues.pagination_limit = limit;
  advanceFilterValues.order_by = orderBy;
  advanceFilterValues.order_asc = asc === "ASC";
  advanceFilterValues.include_package_thumbnail = true;
  advanceFilterValues.include_member_businesses = true;
  if (mailroomId) advanceFilterValues.mailroom_id = mailroomId;
  if (filter) advanceFilterValues.filter_option = filter;
  if (type === "mailroom_packages") {
    advanceFilterValues.filter_option = "outstanding";
  }
  if (businessId) advanceFilterValues.business_id = businessId;
  if (buildingId) advanceFilterValues.building_id = buildingId;
  if (memberId) advanceFilterValues.member_id = memberId;
  if (paginationOffset || paginationOffset !== 0) {
    advanceFilterValues.pagination_offset = paginationOffset;
  }
  if (dateFiltering) {
    advanceFilterValues.filter_type = "ALL";
    advanceFilterValues.date_filtering = true;
  }
  if (graphFilter) {
    advanceFilterValues.filter_type = "ALL";
    advanceFilterValues.graph_type_filter = true;
  }
  if (currentModule) advanceFilterValues.search_business_member_packages = true;
  advanceFilterValues.membership = voPackages ? "VO_MEMBER" : "MEMBER";



  return new Promise((resolve, reject) => {
    getTimezone()
      .then((timezone) => {
        axios
          .get(`${server}/package/search/advance?time_zone=${timezone}`, {
            params: advanceFilterValues,
          })
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
}

export function getPackageDetail(package_id) {
  if (cancelPkgDetail) cancelPkgDetail();
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/package/${package_id}?platform=dashboard`, {
        cancelToken: new CancelToken((c) => {
          cancelPkgDetail = c;
        }),
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (!axios.isCancel(error)) reject(error);
      });
  });
}

export function handleMark(option, params) {
  let api =
    option === "discard"
      ? `${server}/package/discard`
      : `${server}/package/pickup/mark_picked`;
  return new Promise((resolve, reject) => {
    axios
      .post(api, params)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getPackageImage(package_id, status, allowCancel) {
  if (cancelPkgImg) cancelPkgImg();
  return new Promise((resolve, reject) => {
    let params = {};
    if (allowCancel) {
      params = {
        cancelToken: new CancelToken((c) => {
          cancelPkgImg = c;
        }),
      };
    }
    axios
      .get(
        `${server}/package/${package_id}/image${status === "PICKED" ? "?package_pickup=true" : ""
        }`,
        params
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getPackageScanDetail(buildingId, status, statusValue) {
  if (cancel1) cancel1();
  return new Promise((resolve, reject) => {
    getTimezone().then((timezone) => {
      const params = {};
      if (status) params.status_filter = status;
      if (statusValue) params.status_value = statusValue;
      let url = `${server}/analytics/dashboard/graphs?legacy_graph=false&time_zone=${timezone}`;
      if (buildingId) {
        url = `${server}/analytics/dashboard/graphs?legacy_graph=false&building_id=${buildingId}&time_zone=${timezone}`;
      }
      if (statusValue) url = `${url}&status_value=${statusValue}`;
      axios
        .get(url, {
          cancelToken: new CancelToken((c) => {
            cancel1 = c;
          }),
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          if (!axios.isCancel(error)) reject(error);
        });
    });
  });
}

export function getPackageAnalytics(buildingId) {
  if (cancel2) cancel2();
  return new Promise((resolve, reject) => {
    getTimezone().then((timezone) => {
      let url = `${server}/analytics/dashboard/packages?graph_type=held&time_zone=${timezone}`;
      if (buildingId) url = `${url}&building_id=${buildingId}`;
      axios
        .get(url, {
          cancelToken: new CancelToken((c) => {
            cancel2 = c;
          }),
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          if (!axios.isCancel(error)) reject(error);
        });
    });
  });
}

export function getCourierAnalytics(buildingId, filter, fromDate, toDate) {
  if (cancel3) cancel3();
  return new Promise((resolve, reject) => {
    const params = {
      from_date: fromDate,
      to_date: toDate,
    };
    getTimezone().then((timezone) => {
      if (filter) params.filter_attr = filter;
      let url = `${server}/analytics/dashboard/packages?graph_type=courier&time_zone=${timezone}`;
      if (buildingId) {
        url = `${url}&building_id=${buildingId}`;
      }
      axios
        .get(url, {
          params,
          cancelToken: new CancelToken((c) => {
            cancel3 = c;
          }),
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          if (!axios.isCancel(error)) reject(error);
        });
    });
  });
}

export function getCategoryAnalytics(buildingId, filter, fromDate, toDate) {
  if (cancel4) cancel4();
  return new Promise((resolve, reject) => {
    const params = {
      from_date: fromDate,
      to_date: toDate,
    };
    getTimezone().then((timezone) => {
      if (filter) params.filter_attr = filter;
      let url = `${server}/analytics/dashboard/packages?graph_type=category&time_zone=${timezone}`;
      if (buildingId) {
        url = `${server}/analytics/dashboard/packages?graph_type=category&time_zone=${timezone}&building_id=${buildingId}`;
      }
      axios
        .get(url, {
          params,
          cancelToken: new CancelToken((c) => {
            cancel4 = c;
          }),
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          if (!axios.isCancel(error)) reject(error);
        });
    });
  });
}

export function sendPackagesCSV(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/csv/download`, { params })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function handleMarkDispatch(params) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/package/mark_as_dispatched`, params)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
