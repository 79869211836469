/* eslint-disable function-paren-newline */
import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Zoom } from '@material-ui/core';
import { truncateLabel } from './utility.functions';
import placeholder from '../assets/images/dashboard icons/associates_icon_dark.svg';

const AssociatesIconInactive = require('../../src/containers/Layout/sidebar/AssociatesIconInactive.png');

export function createRows(associates, props, associateModule) {
  return associates.map((associate, i) => {
    window.URL.revokeObjectURL(associate.user_image_link);
    if (associateModule === 'associates') {
      return {
        id: associate.Attributes[0].Value || (
          <p style={{ paddingLeft: 5 }}> --- </p>
        ),
        associate_thumbnail:
          <Link to={`/associate_detail/${associate.Attributes[0].Value}`}>
            {associate.user_image_link ? (
              <img
                className="package-thumbnail"
                // src={associate.user_image_link}
                src={placeholder}
                alt=""
                id={`associate-${i}`}
                style={{ boxShadow: 'none' }}
                onError={() => {
                  const elem = document.getElementById(`associate-${i}`);
                  elem.style.boxShadow = 'none';
                  elem.src = placeholder;
                }}
              />
            ) : (
                <img style={{ height: 32, width: 32, borderRadius: 5 }} src={AssociatesIconInactive} alt="" />
              )}</Link>,
        // name: associate.Attributes[1].Value.length > 20
        //   ? truncateLabel(associate.Attributes[1].Value)
        //   : associate.Attributes[1].Value,
        // email:
        //   associate.Attributes[2].Value.length > 20
        //     ? truncateLabel(associate.Attributes[2].Value)
        //     : associate.Attributes[2].Value,
        name:
          associate.Attributes[1].Value.length > 20 ? (
            <Tooltip
              TransitionComponent={Zoom}
              title={associate.Attributes[1].Value}
            >
              <Link to={`/associate_detail/${associate.Attributes[0].Value}`}>
                {truncateLabel(associate.Attributes[1].Value)}
              </Link>
            </Tooltip>
          ) :
            (
              <Link to={`/associate_detail/${associate.Attributes[0].Value}`}>
                {truncateLabel(associate.Attributes[1].Value)}
              </Link>
            ),
        email:
          associate.Attributes[2].Value.length > 20 ? (
            <Tooltip
              TransitionComponent={Zoom}
              title={associate.Attributes[2].Value}
            >
              <a href={`mailto:${associate.Attributes[2].Value}`}>
                {truncateLabel(associate.Attributes[2].Value)}
              </a>
            </Tooltip>
          ) : (
              (
                <a href={`mailto:${associate.Attributes[2].Value}`}>
                  {truncateLabel(associate.Attributes[2].Value)}
                </a>
              ) || <p style={{ paddingLeft: 5 }}> --- </p>
            ),
        location:
          associate.role_data && associate.role_data.buildings.length ? (
            <div className="flex width-200px">
              {associate.role_data.buildings.map(
                (building, index) => (
                  <>
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={building.building_name.length > 28 ? building.building_name : ''}
                    >
                      <Link
                        to={`/building_detail/${building.building_id}`}
                        key={building.building_id}
                      >
                        {building.building_name}
                      </Link>
                    </Tooltip>
                    {index < (associate.role_data.buildings.length - 1) ? <div className="margin-right-5">, </div> : ''}
                  </>
                ),
              )}
            </div>
          ) : (
              <p style={{ paddingLeft: 5 }}> --- </p>
            ),

        role: associate.role_data ? associate.role_data.role.value : <p> --- </p>,
        status: associate.UserStatus ? (
          <div
            style={{
              color: associate.UserStatus === 'CONFIRMED' ? '#02B67C' : '#FF1E59',
            }}
          >
            {associate.UserStatus === 'CONFIRMED'
              ? props.t('associates.active')
              : props.t('associates.inactive')}
          </div>
        ) : (
            <div style={props.style.colors.red}>{props.t('associates.inactive')}</div>
          ),
        associatesCount: associate.user_count,
        deleteAssociate: { id: associate.Username, role: associate.role_data.role.name, name: associate.Attributes[1].Value },
      };
    } else {
      return {
        id: associate.Attributes[0].Value || (
          <p style={{ paddingLeft: 5 }}> --- </p>
        ),
        associate_thumbnail:
          <Link to={`/associate_detail/${associate.Attributes[0].Value}`}>
            {associate.user_image_link ? (
              <img
                className="package-thumbnail"
                src={associate.user_image_link}
                alt=""
                id={`associate-${i}`}
                onError={() => {
                  const elem = document.getElementById(`associate-${i}`);
                  elem.style.boxShadow = 'none';
                  elem.src = placeholder;
                }}
              />
            ) : (
                <img style={{ height: 32, width: 32, borderRadius: 5 }} src={AssociatesIconInactive} alt="" />
              )}</Link>,
        name: associate.Attributes[1].Value.length > 20
          ? truncateLabel(associate.Attributes[1].Value)
          : associate.Attributes[1].Value,
        email:
          associate.Attributes[2].Value.length > 20
            ? truncateLabel(associate.Attributes[2].Value)
            : associate.Attributes[2].Value,
        location:
          associate.role_data && associate.role_data.buildings.length ? (
            <div className="flex width-200px">
              {associate.role_data.buildings.map(
                (building, index) => (
                  <>
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={building.building_name.length > 28 ? building.building_name : ''}
                    >
                      <Link
                        to={`/building_detail/${building.building_id}`}
                        key={building.building_id}
                      >
                        {building.building_name}
                      </Link>
                    </Tooltip>
                    {index < (associate.role_data.buildings.length - 1) ? <div className="margin-right-5">, </div> : ''}
                  </>
                ),
              )}
            </div>
          ) : (
              <p style={{ paddingLeft: 5 }}> --- </p>
            ),

        role: associate.role_data ? associate.role_data.role.value : <p> --- </p>,
        status: associate.UserStatus ? (
          <div
            style={{
              color: associate.UserStatus === 'CONFIRMED' ? '#02B67C' : '#FF1E59',
            }}
          >
            {associate.UserStatus === 'CONFIRMED'
              ? props.t('associates.active')
              : props.t('associates.inactive')}
          </div>
        ) : (
            <div style={props.style.colors.red}>{props.t('associates.inactive')}</div>
          ),
        associatesCount: associate.user_count,
        deleteAssociate: { id: associate.Username, role: associate.role_data.role.name, name: associate.Attributes[1].Value },
      };
    }
  });
}
