/* eslint-disable */

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { List, ListItem } from '@material-ui/core';
import SidebarLink from './SidebarLink';
import { getDaysDifference } from '../../../util/utility.functions';
import FreeTrailExpired from '../../Dashboard/PaymentModals/FreeTrailExpired';

class SidebarContent extends Component {
  state = {
    showFreeTrialExpiredModal: false,
  };
  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    location: PropTypes.node.isRequired,
    sidebar: PropTypes.node.isRequired,
    userRole: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    buildings: PropTypes.node.isRequired,
  };

  checkUserType = () => {
    const { userRole, buildings } = this.props;
    if (userRole === 'admin') {
      this.props.history.push('/manage-plan');
    } else {
      this.setState({
        showFreeTrialExpiredModal: true,
      });
    }
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  closeFreeTrailExpiredModal = (callback) =>
    this.setState({ showFreeTrialExpiredModal: false }, () => {
      if (callback) {
        callback();
      }
    });

  render() {
    const { t, location, sidebar, userRole, buildings } = this.props;
    const { showFreeTrialExpiredModal } = this.state;
    const path = location.pathname;
    const customBgColor = '#FF1E59';
    const premiumBgColor = '#FFB02F';
    const basicBgColor = '#804DDF';
    const starterBgColor = '#00B67B';
    const textColor = '#fff';

    const { billing_method } = buildings;
    // const isStripeCustomer = billing_method && billing_method.tolowercase() === 'stripe';

    const getPlanDays = () => {
      // if(!isStripeCustomer) return '';
      const validUntilCondition =
        buildings && buildings[0] && buildings[0].tier
          ? getDaysDifference(buildings[0].valid_until)
          : null;
      if (validUntilCondition && billing_method) {
        if (buildings[0].status && buildings[0].status === 'TRIAL') {
          if (validUntilCondition > 1) {
            return `${validUntilCondition} ${t(
              'onboarding.dashboard.daysLeft'
            )}`;
          } else if (validUntilCondition === 1) {
            return `${validUntilCondition} ${t(
              'onboarding.dashboard.dayLeft'
            )}`;
          } else if (validUntilCondition <= 0) {
            return (
              <span
                style={{ cursor: 'pointer', color: 'white' }}
                onClick={() => this.checkUserType()}
              >
                Trial Expired
              </span>
            );
          }
        } else {
          // if (validUntilCondition > 1) {
          //   return `${validUntilCondition} ${t(
          //     'onboarding.dashboard.daysLeft'
          //   )}`;
          // } else if (validUntilCondition === 1) {
          //   return `${validUntilCondition} ${t(
          //     'onboarding.dashboard.dayLeft'
          //   )}`;
          // }
          return '';
        }
      } else {
        return '';
      }
    };
    const hasBuilding = buildings && buildings.length > 0 && buildings[0].tier;
    let enableExposeApi = false;
    if (!hasBuilding) {
      if (buildings && buildings.length > 0) {
        enableExposeApi = true;
      }
    } else {
      enableExposeApi = hasBuilding && buildings[0].features.EXPOSE_API;
    }

    let env = process.env.REACT_APP_ENV
      ? process.env.REACT_APP_ENV
      : "development";
    return (
      <Fragment>
        <div className="sidebar__content">
          <List className="sidebar__block">
            {/* hide CLA Section */}
            {sidebar.collapse && !sidebar.show ? (
              <>
                {userRole &&
                  userRole !== 'super_admin' &&
                  buildings &&
                  buildings[0] &&
                  !buildings[0].legacy &&
                  buildings[0].tier ? (
                  <>
                    {buildings[0].tier.toLowerCase() === 'custom' ? (
                      <ListItem
                        className="sidebar__link"
                        style={{
                          padding: sidebar.collapse ? '14px 17px' : '15px 28px',
                          backgroundColor: customBgColor,
                          color: textColor,
                          fontWeight: 'bold',
                          justifyContent: 'space-between',
                          marginBottom: 27,
                          height: 48,
                          cursor: 'default',
                        }}
                      >
                        <span style={{ paddingLeft: 5 }} className="">
                          E
                        </span>
                      </ListItem>
                    ) : buildings[0].tier.toLowerCase() === 'enterprise' ? (
                      <ListItem
                        className="sidebar__link"
                        style={{
                          padding: sidebar.collapse ? '14px 17px' : '15px 28px',
                          backgroundColor: customBgColor,
                          color: textColor,
                          fontWeight: 'bold',
                          justifyContent: 'space-between',
                          marginBottom: 27,
                          height: 48,
                          cursor: 'default',
                        }}
                      >
                        <span style={{ paddingLeft: 5 }} className="">
                          E
                        </span>
                      </ListItem>
                    ) : buildings[0].tier.toLowerCase() === 'premium' ||
                      buildings[0].tier.toLowerCase() === 'premium_custom' ? (
                      <ListItem
                        className="sidebar__link"
                        style={{
                          padding: sidebar.collapse ? '14px 17px' : '15px 28px',
                          backgroundColor: premiumBgColor,
                          color: textColor,
                          fontWeight: 'bold',
                          justifyContent: 'space-between',
                          marginBottom: 27,
                          height: 48,
                          cursor: 'default',
                        }}
                      >
                        <span style={{ paddingLeft: 5 }} className="">
                          P
                        </span>
                      </ListItem>
                    ) : buildings[0].tier.toLowerCase() === 'basic' ||
                      buildings[0].tier.toLowerCase() === 'basic_custom' ? (
                      <ListItem
                        className="sidebar__link"
                        style={{
                          padding: sidebar.collapse ? '14px 17px' : '15px 28px',
                          backgroundColor: basicBgColor,
                          color: textColor,
                          fontWeight: 'bold',
                          justifyContent: 'space-between',
                          marginBottom: 27,
                          height: 48,
                          cursor: 'default',
                        }}
                      >
                        <span style={{ paddingLeft: 5 }} className="">
                          B
                        </span>
                      </ListItem>
                    ) : buildings[0].tier.toLowerCase() === 'starter' ||
                      buildings[0].tier.toLowerCase() === 'starter_custom' ? (
                      <ListItem
                        className="sidebar__link"
                        style={{
                          padding: sidebar.collapse ? '14px 17px' : '15px 28px',
                          backgroundColor: starterBgColor,
                          color: textColor,
                          fontWeight: 'bold',
                          justifyContent: 'space-between',
                          marginBottom: 27,
                          height: 48,
                          cursor: 'default',
                        }}
                      >
                        <span style={{ paddingLeft: 5 }} className="">
                          S
                        </span>
                      </ListItem>
                    ) : buildings[0].tier.toLowerCase() === 'enterprise' ? (
                      <ListItem
                        className="sidebar__link"
                        style={{
                          padding: sidebar.collapse ? '14px 17px' : '15px 28px',
                          backgroundColor: customBgColor,
                          color: textColor,
                          fontWeight: 'bold',
                          justifyContent: 'space-between',
                          marginBottom: 27,
                          height: 48,
                          cursor: 'default',
                        }}
                      >
                        <span style={{ paddingLeft: 5 }} className="">
                          {t()}
                        </span>
                      </ListItem>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}
              </>
            ) : userRole &&
              userRole !== 'super_admin' &&
              buildings &&
              buildings[0] &&
              !buildings[0].legacy &&
              buildings[0].tier ? (
              <>
                {buildings[0].tier.toLowerCase() === 'custom' ? (
                  <ListItem
                    className="sidebar__link"
                    style={{
                      padding: sidebar.collapse ? '14px 17px' : '15px 28px',
                      backgroundColor: customBgColor,
                      color: textColor,
                      fontWeight: 'bold',
                      justifyContent: 'space-between',
                      marginBottom: 27,
                      height: 48,
                      cursor: 'default',
                    }}
                  >
                    <span style={{ paddingRight: 20 }} className="">
                      Enterprise Plan
                    </span>
                    <span>{getPlanDays()}</span>
                  </ListItem>
                ) : buildings[0].tier.toLowerCase() === 'enterprise' ? (
                  <ListItem
                    className="sidebar__link"
                    style={{
                      padding: sidebar.collapse ? '14px 17px' : '15px 28px',
                      backgroundColor: customBgColor,
                      color: textColor,
                      fontWeight: 'bold',
                      justifyContent: 'space-between',
                      marginBottom: 27,
                      height: 48,
                      cursor: 'default',
                    }}
                  >
                    <span style={{ paddingRight: 20 }} className="">
                      Enterprise Plan
                    </span>
                    <span>{getPlanDays()}</span>
                  </ListItem>
                ) : buildings[0].tier.toLowerCase() === 'premium' ||
                  buildings[0].tier.toLowerCase() === 'premium_custom' ? (
                  <ListItem
                    className="sidebar__link"
                    style={{
                      padding: sidebar.collapse ? '14px 17px' : '15px 28px',
                      backgroundColor: premiumBgColor,
                      color: textColor,
                      fontWeight: 'bold',
                      justifyContent: 'space-between',
                      marginBottom: 27,
                      height: 48,
                      cursor: 'default',
                    }}
                  >
                    <span style={{ paddingRight: 20 }} className="">
                      Premium Plan
                    </span>
                    <span>{getPlanDays()}</span>
                  </ListItem>
                ) : buildings[0].tier.toLowerCase() === 'basic' ||
                  buildings[0].tier.toLowerCase() === 'basic_custom' ? (
                  <ListItem
                    className="sidebar__link"
                    style={{
                      padding: sidebar.collapse ? '14px 17px' : '15px 28px',
                      backgroundColor: basicBgColor,
                      color: textColor,
                      fontWeight: 'bold',
                      justifyContent: 'space-between',
                      marginBottom: 27,
                      height: 48,
                      cursor: 'default',
                    }}
                  >
                    <span style={{ paddingRight: 20 }} className="">
                      Basic Plan
                    </span>
                    <span>{getPlanDays()}</span>
                  </ListItem>
                ) : buildings[0].tier.toLowerCase() === 'starter' ||
                  buildings[0].tier.toLowerCase() === 'starter_custom' ? (
                  <ListItem
                    className="sidebar__link"
                    style={{
                      padding: sidebar.collapse ? '14px 17px' : '15px 28px',
                      backgroundColor: starterBgColor,
                      color: textColor,
                      fontWeight: 'bold',
                      justifyContent: 'space-between',
                      marginBottom: 27,
                      height: 48,
                      cursor: 'default',
                    }}
                  >
                    <span style={{ paddingRight: 20 }} className="">
                      Starter Plan
                    </span>
                    <span>{getPlanDays()}</span>
                  </ListItem>
                ) : buildings[0].tier.toLowerCase() === 'enterprise' ? (
                  <ListItem
                    className="sidebar__link"
                    style={{
                      padding: sidebar.collapse ? '14px 17px' : '15px 28px',
                      backgroundColor: customBgColor,
                      color: textColor,
                      fontWeight: 'bold',
                      justifyContent: 'space-between',
                      marginBottom: 27,
                      height: 48,
                      cursor: 'default',
                    }}
                  >
                    <span style={{ paddingRight: 20 }} className="">
                      {t('onboarding.dashboard.enterprisePlan')}
                    </span>
                    <span>{getPlanDays()}</span>
                  </ListItem>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}

            <SidebarLink
              t={t}
              disabled
              sidebar={sidebar.collapse}
              title={t('main.title')}
              icon="home"
              route="/dashboard"
              onClick={this.hideSidebar}
              isActive={path.includes('dashboard')}
            />
            <SidebarLink
              t={t}
              sidebar={sidebar.collapse}
              title={t('tables.packages')}
              icon="inbox"
              route="/packages/all_packages"
              onClick={() => {
                this.hideSidebar();
              }}
              isActive={
                path === '/packages' ||
                (!path.includes('mp-items') && path.includes('packages')) ||
                (!path.includes('mp-items') && path.includes('member_packages'))
              }
            />
            {/* need to add weWork check here */}
            {(env.includes('wework') || env.includes('uat')) &&
              <SidebarLink
                t={t}
                sidebar={sidebar.collapse}
                title={t('voItems.voItems')}
                icon="voItems"
                route="/mp-items/all_items"
                onClick={() => {
                  this.hideSidebar();
                }}
                isActive={
                  path === '/mp-items' ||
                  path.includes('mp-items')
                }
              />
            }
            <SidebarLink
              t={t}
              sidebar={sidebar.collapse}
              title={t('members.members')}
              icon="users"
              route="/members/all_members"
              onClick={() => {
                this.hideSidebar();
              }}
              isActive={
                path === '/members/all_members' ||
                path.includes('business_member/') ||
                path.includes('building_members') ||
                path.includes('business_member/') ||
                path.includes('businesses') ||
                path.includes('member/') ||
                path.includes('members/') ||
                path.includes('member_detail/') ||
                path.includes('business_detail/') ||
                path.includes('add_member') ||
                path.includes('add_business') ||
                path.includes('upload_csv')
              }
            />
            {userRole && userRole !== 'mailroom_supervisor' && (
              <SidebarLink
                t={t}
                sidebar={sidebar.collapse}
                title={t('associates.associates')}
                icon="briefcase"
                route="/associates"
                onClick={() => {
                  this.hideSidebar();
                }}
                isActive={
                  path === '/associate' ||
                  path.includes('associate') ||
                  path.includes('associates_detail')
                }
              />
            )}
            {userRole && userRole !== 'mailroom_supervisor' && (
              <SidebarLink
                t={t}
                sidebar={sidebar.collapse}
                title={t('tables.buildings')}
                icon="apartment"
                route="/buildings"
                onClick={() => {
                  this.hideSidebar();
                }}
                isActive={
                  path === '/buildings' ||
                  path.includes('building_detail') ||
                  path.includes('buildings/') ||
                  path.includes('add_building')
                }
              />
            )}
            {userRole && userRole === 'mailroom_supervisor' && (
              <SidebarLink
                t={t}
                sidebar={sidebar.collapse}
                title={t('notifications.title')}
                icon="notification"
                route="/notification-center"
                onClick={this.hideSidebar}
                isActive={path.includes('notification-center')}
              />
            )}
            {userRole &&
              (userRole === 'admin' || userRole === 'super_admin') && (
                <SidebarLink
                  t={t}
                  sidebar={sidebar.collapse}
                  title={t('settings.settings')}
                  icon="cog"
                  route="/settings"
                  onClick={this.hideSidebar}
                  isActive={path === '/settings'}
                />
              )}
            {userRole && (
              <SidebarLink
                t={t}
                external={true}
                sidebar={sidebar.collapse}
                title={t('sidebar.support')}
                icon="support"
                onClick={this.hideSidebar}
              />
            )}
            {userRole &&
              userRole !== 'mailroom_supervisor' &&
              userRole !== 'building_manager' &&
              enableExposeApi && (
                <SidebarLink
                  t={t}
                  sidebar={sidebar.collapse}
                  title={t('apiexpose.apiInformation')}
                  icon="api"
                  route="/api"
                  onClick={this.hideSidebar}
                  isActive={path.includes('api')}
                />
              )}
          </List>
          <FreeTrailExpired
            t={t}
            userRole={userRole}
            open={showFreeTrialExpiredModal}
            onClose={this.closeFreeTrailExpiredModal}
            buildings={this.props.buildings}
          />
        </div>
      </Fragment>
    );
  }
}

export default withRouter(SidebarContent);
