/* eslint-disable quotes */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Dialog,
  IconButton,
  withWidth,
  Grid, FormHelperText,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";

import { AlignCenter } from "../../../UI/ResponsiveRelated";
import BlueButton from "../../../UI/BlueButton";
import { CustomTextField } from "../../../UI/Input";

class ModalComponent extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    incrementLast: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    style: PropTypes.node.isRequired,
    mailroomName: PropTypes.string.isRequired,
    building: PropTypes.node.isRequired,
    width: PropTypes.string.isRequired,
  };

  render() {
    const {
      t,
      style,
      toggle,
      handleChange,
      mailroomName,
      building,
      mailrooms,
      incrementLast,
      creatingMailroom,
      createMailroom,
      width,
      ...other
    } = this.props;
    const inputStyle = { fontSize: 13, color: "#000" };

    return (
      <form onSubmit={e => e.preventDefault()}>
        <Dialog
          style={{
            overflow: "hidden",
            overflowX: "hidden",
          }}
          onClose={toggle}
          fullScreen={width === "sm" || width === "xs" || width === "md"}
          PaperProps={{
            style: { ...style.common.w_100, ...style.common.p_18 },
          }}
          {...other}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              overflowY: "none",
              marginBottom: 5,
            }}
          >
            <h4
              className="bold-text"
              style={{ paddingLeft: 30, marginBottom: 0 }}
            >
              {t("buildings.addMailroom")}
            </h4>
            <IconButton
              style={{ padding: 10 }}
              aria-label="Close"
              onClick={toggle}
            >
              <Clear />
            </IconButton>
          </div>
          <Divider />
          <Grid container direction="row" justify="space-between">
            <Grid
              item
              xs={12}
              md={12}
              style={{
                marginTop: 19, paddingLeft: 30, paddingRight: 30, height: 60,
              }}
            >
              <CustomTextField
                autoFocus
                style={style.common.w_100}
                label={t("tables.name")}
                value={mailroomName}
                InputProps={{ style: inputStyle }}
                onChange={handleChange("mailroomName")}
              />
              {mailroomName.length < 4 && mailroomName.length > 0 &&
              <FormHelperText className="red">{t('common.error.createMailroom')}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: 19 }}>
              <AlignCenter>
                <BlueButton
                  width={180}
                  loading={creatingMailroom}
                  disabled={creatingMailroom || mailroomName.length < 4}
                  onClick={createMailroom}
                >
                  {t("buildings.addMailroom")}
                </BlueButton>
              </AlignCenter>
            </Grid>
          </Grid>
        </Dialog>
      </form>
    );
  }
}

export default withWidth()(ModalComponent);
