export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_TIMEZONE = 'SET_TIMEZONE';

export function setCountryCode(countryCode) {
  return { type: SET_COUNTRY_CODE, countryCode };
}

export function setTimezone(timezone) {
  return { type: SET_TIMEZONE, timezone };
}
