import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const styles = () => ({
  yellowSwitch: {
    color: '#D8D8D8',
    '&$colorChecked': {
      color: '#FFD16D',
      '& + $colorBar': {
        backgroundColor: '#FFD16D',
      },
    },
  },
  greenSwitch: {
    color: '#D8D8D8',
    '&$colorChecked': {
      color: '#02B67C',
      '& + $colorBar': {
        backgroundColor: '#02B67C',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

const CustomSwitch = props => (
  <Switch
    {...props}
    classes={{
      switchBase: props.classes.greenSwitch,
      checked: props.classes.colorChecked,
      bar: props.classes.colorBar,
    }}
  />
);

CustomSwitch.propTypes = {
  classes: PropTypes.node.isRequired,
};

export default withStyles(styles)(CustomSwitch);
