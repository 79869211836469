/* eslint-disable function-paren-newline */

/* eslint-disable quotes */
/* eslint-disable */

import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  Tooltip,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  DialogActions,
  FormControlLabel,
  Checkbox,
  withStyles,
  Divider,
  // ClickAwayListener
} from '@material-ui/core';
import { connect } from 'react-redux';
import Label from '@material-ui/core/InputLabel';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import SupportContactIcon from '@material-ui/icons/ContactSupport';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styles as style } from '../../../../common/style';
import Alert from '../../../UI/Poper';
import {
  VerticallyCenter,
  AlignCenter,
  Flex,
} from '../../../UI/ResponsiveRelated';
import { CustomInput } from '../../../UI/Input';
import { handleMark } from '../../../../server/packages';
import {
  couriersChinese,
  couriersGlobal,
  couriersGlobalTop,
} from '../../../../util';
import {
  getPackageStatus,
  truncateLabel,
} from '../../../../util/utility.functions';
import { outboundLink } from 'react-ga';

const BlueCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'rgb(29, 168, 235)',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class PackageDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingPickup: false,
      loadingDiscard: false,
      openPickup: false,
      openDiscard: false,
      errorDiscard: '',
      errorPickup: '',
      reason: '',
      helper: false,
      other: false,
      redirect: false,
      notifyDiscard: false,
      disabled: true,
      successPickup: false,
      openRoleError: false,
      // currentIndex: props.index,
      // openPickupTooltip: false,
      // openDeleteTooltip: false
      // openPackageLocation: true
    };
  }

  getLabelsTiles = (tags) => {
    const chips = [];
    try {
      tags.map((assignedLabel) => {
        //   const tempData = [...labelsData].filter(item => item.key === assignedLabel);
        if (tags !== 0) {
          chips.push(
            assignedLabel.value.length > 20 ? (
              <Tooltip enterTouchDelay={0} title={assignedLabel.value}>
                <Chip
                  color="default"
                  className="height-25px capitalize margin-4px"
                  label={truncateLabel(assignedLabel.value)}
                />
              </Tooltip>
            ) : (
              <Tooltip enterTouchDelay={0} title={assignedLabel.value}>
                <Chip
                  color="default"
                  className="height-25px capitalize margin-4px"
                  label={assignedLabel.value}
                />
              </Tooltip>
            )
          );
        }
        return '';
      });
      return chips;
    } catch (err) {
      return [];
    }
  };

  // handleClickForPackageLocation = () => {
  //   this.setState({ openPackageLocation: !this.state.openPackageLocation });
  // };

  // handlePickupTooltipClose = () => {
  //   this.setState({ openPickupTooltip: false });
  // }

  // handlePickupTooltipOpen = () => {
  //   this.setState({ openPickupTooltip: true });
  // }

  // handleDeleteTooltipClose = () => {
  //   this.setState({ openDeleteTooltip: false });
  // }

  // handleDeleteTooltipOpen = () => {
  //   this.setState({ openDeleteTooltip: true });
  // }

  handleClose = () => {
    this.setState({
      openPickup: false,
      openDiscard: false,
      loadingDiscard: false,
      loadingPickup: false,
      errorDiscard: '',
      errorPickup: '',
      reason: '',
      helper: false,
      notifyDiscard: false,
      other: false,
      disabled: true,
      successPickup: false,
    });
  };
  redirect = () => {
    this.setState({ redirect: true });
  };

  handleOther = (e) => {
    const { reason, other } = this.state;
    if (reason.length > 0 || other) {
      this.setState({
        reason: e.target.value,
        helper: false,
        disabled: e.target.value.length === 0,
      });
    } else {
      this.setState({
        disabled: true,
      });
    }
  };

  handleAgree = (id) => {
    const { getData, handleClose } = this.props;
    if (this.state.reason.trim().length === 0) {
      this.setState({ helper: true });
    } else if (this.state.openPickup) {
      this.setState({
        loadingPickup: true,
        errorPickup: '',
        errorDiscard: '',
        helper: false,
        disabled: false,
      });
      handleMark('picked', {
        package_ids: [id],
        marked_as_picked_reason: this.state.reason,
      })
        .then(() => {
          this.setState({
            successPickup: true,
            loadingPickup: false,
            openPickup: false,
          });
          // getPackageId(id, currentIndex, true);
          handleClose('successPickup');
          getData();
        })
        .catch((err) => {
          this.setState({
            errorPickup: err.response
              ? err.response.data
                ? err.response.data.message
                  ? err.response.data.message.message
                  : err.response.data.message
                : err.message
              : err.message,
            loadingPickup: false,
          });
        });
    } else {
      this.setState({
        errorPickup: '',
        loadingDiscard: true,
        errorDiscard: '',
        helper: false,
        disabled: false,
      });
      handleMark('discard', {
        package_ids: [id],
        discard_reason: this.state.reason,
        send_notification: this.state.notifyDiscard,
      })
        .then(() => {
          this.setState({
            loadingDiscard: false,
            openDiscard: false,
          });
          handleClose('successDiscard');
          // getPackageId(id, currentIndex, true);
          getData();
          // console.log(getData());
        })
        .catch((err) => {
          this.setState({
            errorDiscard: err.response
              ? err.response.data
                ? err.response.data.message
                : err.response.data.message
              : err.message,
            loadingDiscard: false,
          });
        });
    }
  };

  handleReason = (e) => {
    const { t } = this.props;
    if (e.target.value === t('package.other')) {
      this.setState({
        other: true,
        reason: '',
        helper: false,
        disabled: true,
      });
    } else {
      this.setState({
        reason: e.target.value,
        other: false,
        helper: false,
        disabled: false,
      });
    }
  };

  showChips = (tags) => {
    let chips = [];
    if (tags && tags !== '---') {
      chips = this.getLabelsTiles(tags);
      return chips;
      // for (const key of Object.keys(tags)) {
      //   if (tags[key] === true) {
      //     const label = key.replace(/_/g, ' ');
      //     console.log(label);
      //     chips.push(
      //       <Chip
      //         color="default"
      //         className="height-25px capitalize margin-4px"
      //         label={label.replace('flag', '')}
      //       />,
      //     );
      //   }
      // }
    }
  };

  render() {
    const {
      loadingDiscard,
      loadingPickup,
      openDiscard,
      openPickup,
      errorDiscard,
      errorPickup,
      reason,
      helper,
      other,
      notifyDiscard,
      successPickup,
      disabled,
      openRoleError,
      // openPackageLocation
    } = this.state;
    const couriers = [
      ...couriersGlobalTop,
      ...couriersGlobal,
      ...couriersChinese,
    ];
    const {
      details,
      t,
      packages,
      userRole,
      currentIndex,
      getPackage,
      forwarded,
    } = this.props;
    // console.log(this.props);
    let { activity_type } = this.props;
    let reasons = openDiscard
      ? [
        {
          label: t('package.label1'),
        },
        {
          label: t('package.label2'),
        },
        {
          label: t('package.label3'),
        },
      ]
      : [
        {
          label: t('package.label4'),
        },
      ];
    if (this.state.redirect) {
      window.location.reload();
      // return <Redirect to="/packages" />;
    }
    const isUndefined = details.length === 0;
    const { json_final } = details;
    const hasError =
      json_final === null ||
      JSON.stringify(json_final) === '{}' ||
      details === 'no details found' ||
      details === t('common.error.networkError');
    const member = isUndefined
      ? { id: '', name: <Skeleton width={50} /> }
      : { id: details.member_id, name: details.member_name || '  ---' };

    let carrierTo = '#';
    let carer = '';
    if (!hasError && !isUndefined) {
      const carier =
        details.courier_name &&
        details.courier_name.length &&
        couriers.find(
          (x) =>
            x.id === details.courier_name || x.name === details.courier_name
        );
      carer =
        carier !== null && carier !== undefined
          ? carier.name
          : details.courier_name;
    }
    const carrier = !hasError && !isUndefined ? carer : <Skeleton width={50} />;
    const getCarrier = (tempCarrier) => {
      if (!tempCarrier) return '';
      if (typeof tempCarrier !== 'string') return '';
      if (tempCarrier.length === 0) return '';
      return tempCarrier.charAt(0).toUpperCase() + tempCarrier.slice(1);
    };
    const tracking_number =
      !hasError && !isUndefined ? (
        details.tracking_number
      ) : (
        <Skeleton width={50} />
      );
    let tags =
      !hasError && !isUndefined ? (
        json_final.call_type !== 'scan' ? (
          '  ---'
        ) : json_final.output ? (
          json_final.output.scan_output.courier_info ? (
            json_final.output.scan_output.courier_info.miscellaneous || '  ---'
          ) : (
            '  ---'
          )
        ) : (
          '  ---'
        )
      ) : (
        <Skeleton width={50} />
      );
    let loc =
      !hasError && !isUndefined ? (
        json_final.call_type !== 'scan' ? (
          '  ---'
        ) : json_final.output ? (
          json_final.output.scan_output.courier_info ? (
            json_final.output.scan_output.courier_info.miscellaneous || '  ---'
          ) : (
            '  ---'
          )
        ) : (
          '  ---'
        )
      ) : (
        <Skeleton width={50} />
      );

    if (details.package_labels || (details.category && details.category.length)) {
      tags = details.package_labels || [];
      const otherLabels = details.category && details.category.length && details.category.map(item => {
        if (tags.findIndex(x => x.value === item) === -1) {
          tags.push({ id: 0, value: item })
        }
        return ''
      }

      )
    }


    if (details.package_location) {
      loc = details.package_location;
    }
    if (carrier === 'amazon-fba-us') {
      carrierTo = 'https://www.amazon.com/gp/css/order-history';
    } else if (carrier === 'ups') {
      carrierTo = `https://wwwapps.ups.com/WebTracking/processInputRequest?AgreeToTermsAndConditions=yes&Requester=trkinppg&WT.trk_id=XXXX&WT.sys_id=XXXX&tracknum=${tracking_number}&loc=en_US`;
    } else if (carrier === 'fedex') {
      carrierTo = `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${tracking_number}&cntry_code=us`;
    } else if (carrier === 'dhl') {
      carrierTo = `http://www.dhl.com/en/express/tracking.html?AWB=${tracking_number}&brand=DHL`;
    } else if (carrier === 'lasership') {
      carrierTo = `https://www.lasership.com/track.php?track_number_input=${tracking_number}`;
    } else if (carrier === 'usps') {
      carrierTo = `https://tools.usps.com/go/TrackConfirmAction?tLabels=${tracking_number}`;
    } else {
      carrierTo = `https://track.aftership.com/${tracking_number}`;
    }
    const status =
      !hasError && !isUndefined ? details.status : <Skeleton width={50} />;
    const action_status =
      !hasError && !isUndefined ? (
        details.action_status
      ) : (
        <Skeleton width={50} />
      );

    const check_in =
      !hasError && !isUndefined ? (
        `${moment(details.created_at || details.check_in).format(
          'MMM Do, YYYY'
        )} @ ${moment(details.created_at || details.check_in).format(
          'hh:mm A'
        )}`
      ) : (
        <Skeleton width={50} />
      );
    const check_out =
      !hasError && !isUndefined ? (
        details.status === 'PICKED' ? (
          `${moment(details.updated_at || details.check_out).format(
            'MMM Do, YYYY'
          )} @ ${moment(details.updated_at || details.check_out).format(
            'hh:mm A'
          )}`
        ) : (
          '  ---'
        )
      ) : (
        <Skeleton width={50} />
      );
    const time_held =
      !hasError && !isUndefined ? (
        details.status === 'PICKED' ? (
          `${Math.floor(
            Math.abs(
              new Date(`${details.updated_at || details.check_in}`) -
              new Date(`${details.created_at || details.check_in}`)
            ) /
            (1000 * 3600 * 24)
          )} ${t(
            Math.floor(
              Math.abs(
                new Date(`${details.updated_at || details.check_out}`) -
                new Date(`${details.created_at || details.check_in}`)
              ) /
              (1000 * 3600 * 24)
            ) > 1
              ? 'common.days'
              : 'common.day'
          )}, ${Math.floor(
            Math.abs(
              (new Date(`${details.created_at || details.check_in}`) -
                new Date(`${details.updated_at || details.check_out}`)) /
              (1000 * 3600)
            )
          ) % 24} ${Math.floor(
            Math.abs(
              (new Date(`${details.updated_at || details.check_out}`) -
                new Date(`${details.created_at || details.check_in}`)) /
              (1000 * 3600)
            )
          ) %
            24 >
            1
            ? t('common.hours')
            : t('common.hour')
          }`
        ) : (
          `${Math.floor(
            Math.abs(
              Date.now() - new Date(`${details.created_at || details.check_in}`)
            ) /
            (1000 * 3600 * 24)
          )} ${t(
            Math.floor(
              Math.abs(
                Date.now() -
                new Date(`${details.created_at || details.check_in}`)
              ) /
              (1000 * 3600 * 24)
            ) > 1
              ? 'common.days'
              : 'common.day'
          )}, ${Math.floor(
            Math.abs(
              (Date.now() -
                new Date(`${details.updated_at || details.check_in}`)) /
              (1000 * 3600)
            )
          ) % 24} ${Math.floor(
            Math.abs(
              (Date.now() -
                new Date(`${details.updated_at || details.check_in}`)) /
              (1000 * 3600)
            )
          ) %
            24 >
            1
            ? t('common.hours')
            : t('common.hour')
          }`
        )
      ) : (
        <Skeleton width={50} />
      );
    const reminders =
      !hasError && !isUndefined ? (
        details.reminders === null ? (
          '  ---'
        ) : (
          details.reminders
        )
      ) : (
        <Skeleton width={50} />
      );
    const isHolding =
      Math.floor(
        Math.abs(
          Date.now() - new Date(`${details.created_at || details.check_in}`)
        ) /
        (1000 * 3600 * 24)
      ) < 10;

    const { packageStatus, colour } = getPackageStatus(
      status,
      action_status,
      isHolding,
      activity_type,
      t
    );
    const picked_by = !isUndefined
      ? packageStatus === t('tables.collected')
        ? details.checkout_by_name
        : ''
      : { name: <Skeleton width={50} /> };
    const member_name_scanout_by = !isUndefined
      ? packageStatus === t('tables.collected')
        ? details.member_name_scanout
        : ''
      : { name: <Skeleton width={50} /> };
    const delivered_by = !isUndefined
      ? details.delivered_by_name
      : { name: <Skeleton width={50} /> };

    const lastActionPerformedBy = !isUndefined
      ? details.last_action_by
      : { name: <Skeleton width={50} /> };

    const scanned_by_id = details.scanned_by;
    const scanned_by = !isUndefined
      ? details.scanned_by_name
      : { name: <Skeleton width={50} /> };
    const note = !isUndefined ? details.package_note : <Skeleton width={50} />;
    const pickupNote = !isUndefined ? (
      details.package_picked_reason
    ) : (
      <Skeleton width={50} />
    );
    const deleteNote = !isUndefined ? (
      details.package_discard_reason
    ) : (
      <Skeleton width={50} />
    );
    const forwardName = t('email.name');
    const forwardShippingMethod = t('package.shippingMethod');
    const forwardAddress = t('buildings.address');
    const forwardingDetails = t('package.forwardingDetails');
    // const specialCaseHold = (status === 'NOTIFIED' && action_status === 'HOLD') ? t('tables.held') : '';

    // console.log(forwarded);
    // const forwardActionContainerVisible = forwarded
    // ?
    const forwardActionContainerVisible =
      (details.action_status === 'FORWARD' &&
        details.status === 'NOTIFIED' &&
        details.activity_type === 'SCAN-OUT' &&
        details.to_address) ||
      (details.action_status === 'PICKED' &&
        details.status === 'PICKED' &&
        details.activity_type === 'SCAN-OUT' &&
        details.to_address) ||
      (details.action_status === 'DISCARDED' &&
        details.status === 'DISCARDED' &&
        details.activity_type === 'SCAN-OUT' &&
        details.to_address);
    // : packages[currentIndex].action_status === 'FORWARD' &&
    //   packages[currentIndex].status === 'NOTIFIED' &&
    //   packages[currentIndex].activity_type === 'SCAN-OUT' &&
    //   packages[currentIndex].to_address !== null;
    // console.log(forwardActionContainerVisible)
    // console.log(details.action_status, details.status, details.activity_type, details.to_address)
    const lastActionCondition = function () {
      if (
        activity_type === 'SCAN-OUT' &&
        packageStatus === t('tables.collected')
      )
        return true;
      if (
        activity_type === 'SCAN-IN' &&
        packageStatus === t('tables.collected')
      )
        return true;
      if (
        activity_type === 'SCAN-IN' &&
        packageStatus === t('tables.destroyed')
      )
        return true;
      if (
        activity_type === 'SCAN-IN' &&
        packageStatus === t('tables.discarded')
      )
        return true;
      if (
        activity_type === 'SCAN-OUT' &&
        packageStatus === t('tables.discarded')
      )
        return true;
      if (
        activity_type === 'SCAN-IN' &&
        (packageStatus === 'DELETED' || packageStatus === t('tables.deleted'))
      )
        return true;
      if (
        activity_type === 'SCAN-OUT' &&
        (packageStatus === 'DELETED' || packageStatus === t('tables.deleted'))
      )
        return true;
      if (
        activity_type === 'SCAN-IN' &&
        packageStatus === t('tables.forwarded')
      )
        return true;
      if (
        activity_type === 'SCAN-IN' &&
        packageStatus === t('tables.heldMailbox')
      )
        return true;

      // if no case matches, return false
      return false;
    };

    return details === 'no details found' ? (
      <VerticallyCenter style={{ minHeight: 400 }}>
        <AlignCenter>{t('common.noData')}</AlignCenter>
      </VerticallyCenter>
    ) : details === 'Something went wrong!' ? (
      <VerticallyCenter style={{ minHeight: 400 }}>
        <AlignCenter>{t('common.error.networkError')}</AlignCenter>
      </VerticallyCenter>
    ) : (
      <React.Fragment>
        <Alert
          variant="success"
          open={successPickup}
          message={t('package.pickedsuccessfully')}
          onClose={this.handleClose}
        />
        <List style={{ flex: 1 }}>
          {forwardActionContainerVisible ? (
            <ListItem divider>
              <div className="forward-action-container-outer-header">
                <p style={{ fontWeight: 600, marginBottom: 5 }}>
                  {forwardingDetails}
                </p>
                <table
                  className="forward-action-container-header"
                  align="center"
                >
                  <tr>
                    <td className="forward-action-container-body-data-row-1a">
                      {forwardName}
                    </td>
                    <td
                      width="3px"
                      height="18px"
                      className="forward-action-container-body-data-row-1b"
                    >
                      :
                    </td>
                    <td
                      width="103px"
                      align="center"
                      height="19px"
                      className="forward-action-container-body-data-row-1c"
                    >
                      {packages[currentIndex].to_address.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="forward-action-container-body-data-row-2a">
                      {forwardShippingMethod}
                    </td>
                    <td
                      width="3px"
                      height="18px"
                      className="forward-action-container-body-data-row-2b"
                    >
                      :
                    </td>
                    <td
                      width="103px"
                      align="center"
                      height="19px"
                      className="forward-action-container-body-data-row-2c"
                    >
                      {packages[currentIndex].to_address.shipping_method}
                    </td>
                  </tr>

                  <tr>
                    <td className="forward-action-container-body-data-row-3a">
                      {forwardAddress}
                    </td>
                    <td
                      width="3px"
                      height="18px"
                      className="forward-action-container-body-data-row-3b"
                    >
                      :
                    </td>
                    <td
                      width="130px"
                      align="center"
                      height="19px"
                      className="forward-action-container-body-data-row-3c"
                    >
                      {packages[currentIndex].to_address.address}
                    </td>
                  </tr>
                </table>
              </div>
            </ListItem>
          ) : (
            ''
          )}
          {/* Reciepient */}
          <ListItem divider>
            <ListItemText
              primary={
                activity_type === 'SCAN-IN'
                  ? t('package.recipient')
                  : t('package.sender')
              }
            />
            <Tooltip title={member.name || member}>
              <ListItemSecondaryAction
                className="detailKeyWidth"
                style={style.common.r_30}
              >
                {member && member.id !== '---' ? (
                  details.member_active ? (
                    <Link
                      style={{
                        ...style.colors.blue,
                        pointerEvents:
                          typeof member.name !== 'string' && 'none',
                      }}
                      to={`/member_detail/${details.member_id}`}
                    >
                      {member.name || member}
                    </Link>
                  ) : (
                    member.name || member
                  )
                ) : (
                  ' --- '
                )}
              </ListItemSecondaryAction>
            </Tooltip>
          </ListItem>
          {/* Carrier */}
          <ListItem divider>
            <ListItemText primary={t('package.carrier')} />
            <Tooltip title={getCarrier(carrier)}>
              <ListItemSecondaryAction
                className="detailKeyWidth"
                style={style.common.r_30}
              >
                {carrier ? (
                  getCarrier(carrier)
                ) : (
                  <div style={style.colors.black}>---</div>
                )}
              </ListItemSecondaryAction>
            </Tooltip>
          </ListItem>
          {/* Tracking Number */}
          <ListItem divider>
            <ListItemText primary={t('package.trackingNo')} />
            <ListItemSecondaryAction
              className="detailKeyWidth"
              style={style.common.r_30}
            >
              {isUndefined ? (
                tracking_number
              ) : tracking_number !== '  ---' ? (
                <Tooltip title={tracking_number}>
                  <a target="_blank" rel="noopener noreferrer" href={carrierTo}>
                    {tracking_number}
                  </a>
                </Tooltip>
              ) : (
                <div style={style.colors.black}>---</div>
              )}
            </ListItemSecondaryAction>
          </ListItem>
          {/* Status */}
          <ListItem divider>
            <ListItemText primary={t('tables.status')} />
            <ListItemSecondaryAction
              className="detailKeyWidth"
              style={style.common.r_30}
            >
              {(
                <Tooltip title={packageStatus}>
                  <div
                    style={{
                      color: colour,
                      fontWeight: 600,
                    }}
                  >
                    {packageStatus}
                  </div>
                </Tooltip>
              ) || <div style={style.colors.black}>---</div>}
            </ListItemSecondaryAction>
          </ListItem>

          {/* New/Original Item Link */}
          {!hasError &&
            !isUndefined &&
            details.forwarded_detail &&
            details.forwarded_detail.length > 0 && (
              <>
                <ListItem
                  divider={details.forwarded_detail.length === 1 ? true : false}
                >
                  <ListItemText
                    primary={
                      packageStatus === t('tables.forwarded')
                        ? t('tables.forwarded')
                        : t('tables.originalItem')
                    }
                  />
                  <Tooltip
                    title={
                      packageStatus === t('tables.forwarded')
                        ? t('tables.newItem')
                        : t('tables.originalItem')
                    }
                  >
                    <ListItemSecondaryAction
                      className="detailKeyWidth"
                      style={style.common.r_30}
                    >
                      <span
                        onClick={() => {
                          details.forwarded_detail[0].package_id &&
                            getPackage(details.forwarded_detail[0].package_id);
                        }}
                        style={{
                          ...style.colors.blue,
                          cursor: 'pointer',
                        }}
                      >
                        {packageStatus === t('tables.forwarded')
                          ? t('tables.newItem')
                          : details.forwarded_detail.length > 1
                            ? `${t('tables.originalItem')} 1`
                            : t('tables.originalItem')}
                      </span>
                    </ListItemSecondaryAction>
                  </Tooltip>
                </ListItem>
                {details.forwarded_detail.length > 1 &&
                  details.forwarded_detail.slice(1).map((el, index) => (
                    <ListItem
                      divider={index === details.forwarded_detail.length - 2}
                    >
                      <ListItemText primary="---" style={{ opacity: '0' }} />
                      <Tooltip title={t('tables.originalItem')}>
                        <ListItemSecondaryAction
                          className="detailKeyWidth"
                          style={style.common.r_30}
                        >
                          <span
                            onClick={() => {
                              el.package_id && getPackage(el.package_id);
                            }}
                            style={{
                              ...style.colors.blue,
                              cursor: 'pointer',
                            }}
                          >
                            {t('tables.originalItem')} {index + 2}
                          </span>
                        </ListItemSecondaryAction>
                      </Tooltip>
                    </ListItem>
                  ))}
              </>
            )}

          {/* {packages[currentIndex].action_status === 'FORWARD' && packages[currentIndex].status === 'FORWARD' ?
                <ListItem divider>
                  <ListItemText primary="Package Location" />
                  <ListItem button className="detailKeyWidth" style={{ justifyContent: 'flex-end' }} onClick={this.handleClickForPackageLocation}>
                    {openPackageLocation ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                </ListItem>
                : ''
              } */}
          {/* Check-in Checkout */}
          <ListItem divider>
            <ListItemText primary={t('tables.checkIn')} />
            <Tooltip title={check_in}>
              <ListItemSecondaryAction
                className="detailKeyWidth"
                style={style.common.r_30}
              >
                {check_in || <div style={style.colors.black}>---</div>}
              </ListItemSecondaryAction>
            </Tooltip>
          </ListItem>
          <ListItem divider>
            <ListItemText primary={t('tables.checkOut')} />
            <Tooltip title={check_out}>
              <ListItemSecondaryAction
                className="detailKeyWidth"
                style={style.common.r_30}
              >
                {check_out || <div style={style.colors.black}>---</div>}
              </ListItemSecondaryAction>
            </Tooltip>
          </ListItem>
          {/* Time Held */}
          <ListItem divider>
            <ListItemText primary={t('package.timeHeld')} />
            <ListItemSecondaryAction
              className="detailKeyWidth"
              style={style.common.r_30}
            >
              {time_held === '  ---' ? (
                <div style={style.colors.black}>---</div>
              ) : (
                (
                  <Tooltip title={time_held}>
                    <div
                      style={
                        packageStatus === t('tables.collected')
                          ? style.colors.green
                          : packageStatus === t('tables.overdue')
                            ? style.colors.red
                            : packageStatus === t('tables.holding')
                              ? style.colors.yellow
                              : style.colors.blue
                      }
                    >
                      {time_held}
                    </div>
                  </Tooltip>
                ) || <div style={style.colors.black}>---</div>
              )}
            </ListItemSecondaryAction>
          </ListItem>
          {activity_type === 'SCAN-IN' && (
            <ListItem divider>
              <ListItemText primary={t('package.remindersSent')} />
              <Tooltip title={reminders}>
                <ListItemSecondaryAction
                  className="detailKeyWidth"
                  style={style.common.r_30}
                >
                  {reminders || <div style={style.colors.black}>---</div>}
                </ListItemSecondaryAction>
              </Tooltip>
            </ListItem>
          )}
          <div className="space-between padding-11-16 margin-0px">
            <div className="width-15p fs-1rem">{t('package.tags')}</div>
            <div
              className={
                this.showChips(tags).length === 0 && 'padding-right-14px'
              }
            >
              {isUndefined
                ? tags
                : tags !== '  ---'
                  ? this.showChips(tags).length === 0
                    ? '  ---'
                    : this.showChips(tags)
                  : tags}
            </div>
          </div>
          <Divider />
          {/* Location */}
          <div className="space-between padding-11-16 margin-0px">
            <div className="width-15p fs-1rem">{t('tables.location')}</div>
            <div
              className={
                this.showChips(loc).length === 0 && 'padding-right-14px'
              }
            >
              {isUndefined
                ? loc
                : loc !== '  ---'
                  ? this.showChips(loc, 'location').length === 0
                    ? '  ---'
                    : this.showChips(loc, 'location')
                  : loc}
            </div>
          </div>
          <Divider />
          <ListItem divider>
            <ListItemText
              primary={
                activity_type === 'SCAN-IN'
                  ? t('package.receivedBy')
                  : t('package.collectedBy')
              }
            />

            <ListItemSecondaryAction
              className="detailKeyWidth"
              style={style.common.r_30}
            >
              {/* {isUndefined && picked_by.name !== "---" ? (
                  picked_by.name
                ) : !picked_by || picked_by === "" ? (
                  "  ---"
                ) : (
                  <Link
                    style={{
                      ...style.colors.blue
                    }}
                    to={`/member_detail/${picked_by.member_id}`}
                  >
                    {picked_by}
                  </Link>
                )} */}
              {activity_type === 'SCAN-OUT' ? (
                member_name_scanout_by && member_name_scanout_by !== '---' ? (
                  member_name_scanout_by.name || member_name_scanout_by
                ) : (
                  ' --- '
                )
              ) : picked_by && picked_by !== '---' ? (
                <Tooltip title={picked_by.name || picked_by}>
                  {details.member_active ? (
                    <Link
                      style={{
                        ...style.colors.blue,
                      }}
                      to={`/member_detail/${details.checkout_by_member_id}`}
                    >
                      {picked_by.name || picked_by}
                    </Link>
                  ) : (
                    <span>{picked_by.name || picked_by}</span>
                  )}
                </Tooltip>
              ) : (
                <span>{picked_by.name || picked_by}</span>
              )}
            </ListItemSecondaryAction>
          </ListItem>
          {/* delivered by / last action performed by */}
          {!hasError && !isUndefined && (
            <ListItem divider>
              <ListItemText
                primary={
                  // activity_type === 'SCAN-IN'
                  //   ? t('package.deliveredBy')
                  //   : t('package.lastActionPerformedBy')
                  lastActionCondition()
                    ? t('package.lastActionPerformedBy')
                    : t('package.deliveredBy')
                }
              />
              <Tooltip
                title={
                  // activity_type === 'SCAN-IN'
                  //   ? delivered_by && delivered_by !== '---'
                  //     ? delivered_by.name || delivered_by
                  //     : null
                  //   : lastActionPerformedBy && lastActionPerformedBy !== '---'
                  //   ? lastActionPerformedBy
                  //   : null
                  lastActionCondition()
                    ? lastActionPerformedBy && lastActionPerformedBy !== '---'
                      ? lastActionPerformedBy
                      : null
                    : delivered_by && delivered_by !== '---'
                      ? delivered_by.name || delivered_by
                      : null
                }
              >
                <ListItemSecondaryAction
                  className="detailKeyWidth"
                  style={style.common.r_30}
                >
                  {lastActionCondition() ? (
                    lastActionPerformedBy && lastActionPerformedBy !== '---' ? (
                      lastActionPerformedBy
                    ) : (
                      '---'
                    )
                  ) : delivered_by && delivered_by !== '---' ? (
                    userRole !== 'mailroom_supervisor' ? (
                      <Link
                        style={{
                          ...style.colors.blue,
                        }}
                        to={`/associate_detail/${details &&
                          details.delivered_by_user_id}`}
                      >
                        {delivered_by.name || delivered_by}
                      </Link>
                    ) : (
                      <span>{delivered_by.name || delivered_by}</span>
                    )
                  ) : (
                    ' --- '
                  )}
                  {/* {activity_type === 'SCAN-IN' ? (
                    delivered_by && delivered_by !== '---' ? (
                      userRole !== 'mailroom_supervisor' ? (
                        <Link
                          style={{
                            ...style.colors.blue,
                          }}
                          to={`/associate_detail/${details &&
                            details.delivered_by_user_id}`}
                        >
                          {delivered_by.name || delivered_by}
                        </Link>
                      ) : (
                        <span>{delivered_by.name || delivered_by}</span>
                      )
                    ) : (
                      ' --- '
                    )
                  ) : lastActionPerformedBy &&
                    lastActionPerformedBy !== '---' ? (
                    lastActionPerformedBy
                  ) : (
                    '---'
                  )} */}
                </ListItemSecondaryAction>
              </Tooltip>
            </ListItem>
          )}

          {/* Scanned By */}
          <ListItem divider>
            <ListItemText primary={t('package.scannedBy')} />
            <Tooltip title={scanned_by && (scanned_by.name || scanned_by)}>
              <ListItemSecondaryAction
                className="detailKeyWidth"
                style={style.common.r_30}
              >
                {scanned_by ? (
                  userRole !== 'mailroom_supervisor' ? (
                    <Link
                      className="blue"
                      to={`/associate_detail/${scanned_by_id}`}
                    >
                      {(scanned_by && (scanned_by.name || scanned_by)) ||
                        '  ---'}
                    </Link>
                  ) : (
                    <span>
                      {(scanned_by && (scanned_by.name || scanned_by)) ||
                        '  ---'}
                    </span>
                  )
                ) : (
                  '  ---'
                )}
              </ListItemSecondaryAction>
            </Tooltip>
          </ListItem>
          <ListItem>
            {/* <ListItemText primary={t('tables.note')} /> */}
            <ListItemText primary={t('package.packageNote')} />
            <Tooltip title={note}>
              <ListItemSecondaryAction
                className="detailKeyWidth"
                style={style.common.r_30}
              >
                {note || '  ---'}
              </ListItemSecondaryAction>
            </Tooltip>
          </ListItem>
          <ListItem>
            {/* <ListItemText primary={t('tables.note')} /> */}
            <ListItemText primary={t('package.pickupNote')} />
            <Tooltip enterTouchDelay={0} title={pickupNote}>
              <ListItemSecondaryAction
                className="detailKeyWidth"
                style={style.common.r_30}
              >
                {/* <ClickAwayListener onClickAway={this.handlePickupTooltipClose}>
                <Tooltip 
                open={this.state.openPickupTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={pickupNote || '  ---'}
                >
                  <ListItemSecondaryAction 
                  className="detailKeyWidth" 
                  style={style.common.r_30}
                  onClick={this.handlePickupTooltipOpen}
                  > */}
                {pickupNote || '  ---'}
              </ListItemSecondaryAction>
            </Tooltip>
            {/* </ClickAwayListener> */}
          </ListItem>
          {/* <ListItemText primary={t('tables.note')} /> */}

          {/* 
                {this.props.width !== 'xs' ? (
                  <>
                    <ListItemText primary={t('package.deleteNote')} />
                    <Tooltip title={deleteNote}>
                      <ListItemSecondaryAction className="detailKeyWidth" style={style.common.r_30}>

                        {deleteNote || '  ---'}
                      </ListItemSecondaryAction>
                    </Tooltip>
                  </>) : (
                    <div className="mobileView">
                      <ListItemText className="del-label" primary={t('package.deleteNote')} />
                      <ListItemSecondaryAction className="detailKeyWidthMob" >

                        {deleteNote || '  ---'}
                      </ListItemSecondaryAction>
                    </div>
                  )} */}
          {/* Discard Note */}
          <ListItem divider>
            <ListItemText primary={t('package.deleteNote')} />
            <Tooltip enterTouchDelay={0} title={deleteNote}>
              <ListItemSecondaryAction
                className="detailKeyWidth"
                style={style.common.r_30}
              >
                {deleteNote || '  ---'}
              </ListItemSecondaryAction>
            </Tooltip>
            {/* </ClickAwayListener> */}
          </ListItem>
          {/* Scan Type */}
          <ListItem divider>
            <ListItemText primary={t('package.scanType')} />
            <Tooltip
              title={
                activity_type === 'SCAN-IN'
                  ? t('tables.scanIn')
                  : t('tables.scanOut')
              }
            >
              <ListItemSecondaryAction style={style.common.r_30}>
                {activity_type === 'SCAN-IN'
                  ? t('tables.scanIn')
                  : t('tables.scanOut') || '  ---'}
              </ListItemSecondaryAction>
            </Tooltip>
          </ListItem>
        </List>

        {!hasError &&
          !isUndefined &&
          packageStatus !== t('tables.collected') &&
          packageStatus !== t('tables.delivered') &&
          packageStatus !== t('tables.destroy') &&
          packageStatus !== t('tables.destroyed') &&
          packageStatus !== t('tables.hold') &&
          packageStatus !== t('tables.scanSend') &&
          packageStatus !== t('tables.forward') &&
          packageStatus !== t('tables.forwarded') &&
          packageStatus !== t('tables.scannedSent') &&
          packageStatus !== t('tables.heldMailbox') &&
          // !specialCaseHold &&
          packageStatus !== t('tables.discarded') &&
          packageStatus !== 'DELETED' &&
          packageStatus !== 'SCANNED' && (
            <div className="space-between-with-block padding-20px">
              <div />
              {
                // !(details.status === 'NOTIFIED' && details && details.activity_type === 'SCAN-OUT') &&
                <Button
                  fullWidth
                  className="width-200-160-130 margin-right-10 bg-blue fs-14-11 white height-40px"
                  onClick={() => {
                    if (userRole !== 'mailroom_supervisor') {
                      this.setState({ openPickup: true });
                    } else {
                      this.setState({ openRoleError: true });
                    }
                  }}
                >
                  {t('package.markaspicked')}
                </Button>
              }
              {
                // (details && details.activity_type !== 'SCAN-OUT') &&
                <Button
                  fullWidth
                  danger
                  className="width-200-160-130 bg-red fs-14-11 white height-40px"
                  onClick={() => {
                    if (userRole !== 'mailroom_supervisor') {
                      this.setState({ openDiscard: true });
                    } else {
                      this.setState({ openRoleError: true });
                    }
                  }}
                >
                  {t('package.markasdelete')}
                </Button>
              }
              <div />
            </div>
          )}
        {openRoleError && (
          <Alert
            variant="error"
            open={openRoleError}
            message={t('package.PickUpDeleteRoleError')}
            onClose={() => this.setState({ openRoleError: false })}
          />
        )}
        <Dialog
          // onClose={this.handleClose}
          onClose={() =>
            this.setState({
              openDiscard: false,
              openPickup: false,
              disabled: true,
              other: false,
              errorDiscard: '',
              errorPickup: '',
              reason: '',
            })
          }
          open={openDiscard || openPickup}
          PaperProps={{ style: { overflow: 'hidden', margin: 20 } }}
        >
          <DialogTitle>
            {openDiscard ? (
              <Flex
                style={{
                  fontSize: 22,
                  fontWeight: 'bold',
                  paddingTop: 10,
                  letterSpacing: 1,
                }}
              >
                {t('package.markasdelete')}
                {''}
                <Tooltip title={t('package.disclaimerdefault')}>
                  <SupportContactIcon />
                </Tooltip>
              </Flex>
            ) : (
              <Flex
                style={{
                  fontSize: 22,
                  fontWeight: 'bold',
                  paddingTop: 10,
                  letterSpacing: 1,
                }}
              >
                {t('package.markaspicked')}
                {''}
                <Tooltip title={t('package.disclaimer')}>
                  <SupportContactIcon />
                </Tooltip>
              </Flex>
            )}
          </DialogTitle>

          <DialogContent style={{ minWidth: 600 }}>
            <FormControl>
              <Label>
                <Flex style={{ fontSize: 16, color: '#027AFF' }}>
                  {t('package.whyDo')}{' '}
                  {`${openDiscard
                    ? t('package.deleteThis')
                    : t('package.markThis')
                    }`}
                  <span className="red">*</span>
                </Flex>
              </Label>
              <Select
                name="building_id"
                value={other ? t('package.other') : reason}
                onChange={this.handleReason}
                input={
                  <CustomInput
                    style={{
                      maxWidth:
                        window.screen.width < 400 && window.screen.width > 300
                          ? 210
                          : window.screen.width > 400 &&
                            window.screen.width < 500
                            ? 260
                            : window.screen.width > 300 &&
                              window.screen.width < 350
                              ? 190
                              : 550,
                      minWidth:
                        window.screen.width < 400 && window.screen.width > 300
                          ? 210
                          : window.screen.width > 400 &&
                            window.screen.width < 500
                            ? 260
                            : window.screen.width > 300 &&
                              window.screen.width < 350
                              ? 190
                              : 550,
                      marginTop: window.screen.width < 500 ? 30 : 20,
                      fontSize: 14,
                    }}
                  />
                }
                MenuProps={{ style: { width: 210 } }}
              >
                <MenuItem
                  value="building"
                  disabled
                  style={{
                    whiteSpace: 'normal',
                    flexWrap: 'wrap',
                    height: 30,
                    fontSize: 14,
                  }}
                >
                  {t('package.select')}
                </MenuItem>
                {reasons.map((cause) => (
                  <MenuItem
                    value={cause.label}
                    key={cause.label}
                    style={{
                      whiteSpace: 'normal',
                      flexWrap: 'wrap',
                      height: 35,
                      fontSize: 14,
                    }}
                  >
                    {cause.label}
                  </MenuItem>
                ))}
                <MenuItem
                  value={t('package.other')}
                  style={{
                    whiteSpace: 'normal',
                    flexWrap: 'wrap',
                    height: 30,
                    fontSize: 14,
                  }}
                >
                  {t('package.other')}
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>

          {(errorDiscard.length > 0 || errorPickup.length > 0) && (
            <AlignCenter>
              <Chip
                label={errorDiscard.length > 0 ? errorDiscard : errorPickup}
                color="secondary"
              />
            </AlignCenter>
          )}
          {other && (
            <DialogContent style={{ overflowX: 'hidden' }}>
              <FormControl>
                <Label>
                  <Flex style={{ fontSize: 16, color: '#027AFF' }}>
                    {t('package.packageWhyOther')}
                    <span className="red">*</span>
                  </Flex>
                </Label>
                <CustomInput
                  style={{
                    overflowX: 'hidden',
                    maxWidth:
                      window.screen.width < 400 && window.screen.width > 300
                        ? 210
                        : window.screen.width > 400 && window.screen.width < 500
                          ? 260
                          : window.screen.width > 300 && window.screen.width < 350
                            ? 190
                            : 550,
                    minWidth:
                      window.screen.width < 400 && window.screen.width > 300
                        ? 210
                        : window.screen.width > 400 && window.screen.width < 500
                          ? 260
                          : window.screen.width > 300 && window.screen.width < 350
                            ? 190
                            : 550,
                    fontSize: 14,
                  }}
                  value={reason}
                  onChange={(e) => this.handleOther(e)}
                  required
                  rowsMax={5}
                />
              </FormControl>
            </DialogContent>
          )}
          {helper && (
            <DialogContent style={{ marginTop: -22, width: 400 }}>
              <p style={{ fontSize: 12, color: '#FF1E59' }}>
                {t('package.action')}
              </p>
            </DialogContent>
          )}
          {openDiscard && (
            <DialogContent>
              <FormControlLabel
                control={
                  <BlueCheckbox
                    icon={
                      <CheckBoxOutlineBlankIcon
                        style={{ fontSize: 16 }}
                        color="#fdb61e"
                      />
                    }
                    checkedIcon={<CheckBoxIcon className="blue" />}
                    checked={notifyDiscard}
                    onChange={(e) =>
                      this.setState({ notifyDiscard: e.target.checked })
                    }
                  />
                }
                label={t('package.notify')}
              />
            </DialogContent>
          )}
          <DialogActions>
            <Button
              onClick={() => this.handleAgree(details.package_id)}
              disabled={loadingPickup || loadingDiscard || disabled}
              style={{
                color: 'white',
                display: 'flex',
                width:
                  window.screen.width < 400 && window.screen.width > 300
                    ? 175
                    : window.screen.width < 450 && window.screen.width > 400
                      ? 190
                      : 270,
                height: 35,
                alignItem: 'center',
                justifyContent: 'center',
                marginRight: window.screen.width < 500 ? 20 : 10,
                backgroundColor:
                  loadingPickup || loadingDiscard || disabled
                    ? '#E5E5E5'
                    : openPickup
                      ? '#027AFF'
                      : openDiscard
                        ? '#E74C3C'
                        : '#E5E5E5',
                marginBottom: 20,
                marginTop: openPickup ? 35 : 10,
              }}
            >
              {openPickup
                ? t('package.markaspicked')
                : t('package.markasdelete')}
              {(loadingPickup || loadingDiscard) && (
                <CircularProgress
                  size={25}
                  style={{ marginLeft: 10, color: 'white' }}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

PackageDetails.propTypes = {
  t: PropTypes.func.isRequired,
  details: PropTypes.node.isRequired,
  getData: PropTypes.func.isRequired,
  // dynamicLabels: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  activity_type: PropTypes.string.isRequired,
  packages: PropTypes.object.isRequired,
  // index: PropTypes.number.isRequired,
  userRole: PropTypes.string.isRequired,
  currentIndex: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  userRole: state.userRole,
});

export default connect(mapStateToProps, null)(PackageDetails);
