import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Paper,
    IconButton,
    withMobileDialog,
    List,
    ListItem,
    ListItemText,
    Divider,
    Button,
    Popover,
    InputBase,
    ClickAwayListener,
} from '@material-ui/core';
import { ArrowDropDown, ArrowRight, Search, ArrowBack } from '@material-ui/icons';
import {
    Flex,
    AlignCenter,
    SpaceBetween,
    VerticallyCenter,
} from '../../UI/ResponsiveRelated';
import BlueButton from '../../UI/BlueButton';
import { truncateLabelFromLeft } from '../../../util/utility.functions';

class TenantSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchor: null,
            searchKey: '',
            searching: false,
            suggestions: [],
            searchList: [],
            selected: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedTenant !== this.props.selectedTenant) {
            this.setState({
                selected: [this.props.selectedTenant],
            });
        }
    }

    handleClick = e => {
        this.setState({ anchor: e.currentTarget });
    };

    handleClose = () => {
        this.setState({
            anchor: null,
            selected: [this.props.selectedTenant]
        });
        this.stopSearch();
    };

    handleSearch = e => {
        const key = e.target.value;
        this.setState({
            searchKey: key,
            selected: []
        }, () => this.search());
    };

    search = () => {
        const { searchKey } = this.state;
        if (searchKey.length === 0) this.setState({ searchList: [] });
        else if (searchKey.length > 0) {
            let searchList = [];
            this.props.tenants.forEach((tanent) => {
                if (tanent.tenant_name && tanent.tenant_name.toLowerCase().includes(searchKey.toLowerCase())) searchList.push(tanent);
            });
            this.setState({ searchList });
        }
    }

    stopSearch = () => this.setState({ searchList: [], searchKey: '', searching: false });

    render() {
        const {
            anchor,
            searching,
            searchKey,
            suggestions,
            searchList,
            selected
        } = this.state;
        const {
            width,
            userRole,
            t,
            tenants,
            handleOnchange,
            selectedTenant,
            handleReset
        } = this.props;
        return (
            <React.Fragment>
                <div
                    className="align-center"
                    onClick={
                        (userRole === 'admin' || userRole === 'super_admin' || userRole === 'building_manager')
                        && tenants.length > 1 &&
                        this.handleClick
                    }
                    role="presentation"
                >
                    {width === 'xs' || width === 'sm' ? (
                        <h2 className="page-subhead subhead cursor-pointer">
                            {selectedTenant && truncateLabelFromLeft(selectedTenant.tenant_name)}
                        </h2>
                    ) : (
                        <h2
                            className="page-subhead subhead"
                            style={{
                                cursor: 'pointer',
                                textOverflow: 'ellipsis',
                                fontSize: 'inherit',
                                maxWidth: 750,
                                wordWrap: 'break-word',
                                whiteSpace: 'nowrap',
                                overflowX: 'hidden'
                            }}
                        >
                            {selectedTenant && selectedTenant.tenant_name}
                        </h2>
                    )}
                    {tenants.length > 1 &&
                        <IconButton
                            className="page-subhead subhead padding-0"
                        >
                            <ArrowDropDown />
                        </IconButton>}
                </div>
                <Popover
                    open={anchor}
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <ClickAwayListener onClickAway={this.handleClose}>
                        <Paper className="just-paper width-350px padding-15px height-400p">
                            <div className="topbar__search toggle-search flex grey" style={{ width: '100% !important' }}>
                                {searching ?
                                    <IconButton className="padding-0 z-index-2" onClick={this.stopSearch}>
                                        <ArrowBack className="font-size-14 color-grey z-index-2" />
                                    </IconButton> :
                                    <IconButton className="padding-0 z-index-2 disabled-link">
                                        <Search className="font-size-14 z-index-2" />
                                    </IconButton>
                                }
                                <InputBase
                                    aria-haspopup="true"
                                    className="search topbar__search-field topbar__search-field--open grey building-toggle-search"
                                    value={searchKey}
                                    // disabled={loading}
                                    onChange={this.handleSearch}
                                    onClick={() => this.setState({ searching: true })}
                                    placeholder={t('settings.searchTanent')}
                                />
                            </div>
                            {searching && searchKey.length === 0 &&
                                <div className="max-height-289px overflow-auto grey">
                                    {suggestions.map(item =>
                                    (<ListItem button className="padding-0" onClick={() => this.selectFromSearch(item)}>
                                        <ListItemText className="padding-0">
                                            <SpaceBetween className="width-100" >
                                                <div className="font-size-14 top-bottom truncated-heading">{item.name || item.state || item.city}</div>
                                                <div className="align-center">
                                                    <div className="font-size-12 color-grey">{
                                                        item.building_id ? t('email.building') :
                                                            item.city ? t('buildings.city') :
                                                                item.state ? t('buildings.state') :
                                                                    t('buildings.country')
                                                    }
                                                    </div>

                                                    {item.building_id ? <div className="width-18px" /> : <ArrowRight />}
                                                </div>
                                            </SpaceBetween>
                                        </ListItemText>
                                    </ListItem>))}
                                </div>}
                            {searching && searchList.length > 0 &&
                                <div className="max-height-289px overflow-auto grey">
                                    {searchList.map(item =>
                                    (<ListItem
                                        button
                                        key={item.tenant_id}
                                        selected={selected.length ? selected[0].tenant_id === item.tenant_id : selectedTenant && selectedTenant.tenant_id === item.tenant_id}
                                        style={{ padding: 0 }}
                                    >
                                        <ListItemText style={{ padding: 0 }}>
                                            <Flex className="padding-left-10">
                                                <SpaceBetween
                                                    className="width-100"
                                                    onClick={() => {
                                                        this.setState({
                                                            selected: [item]
                                                        });
                                                    }}>
                                                    <div className="font-size-14 top-bottom truncated-heading">
                                                        {item.tenant_name}
                                                    </div>
                                                    {/* {item.tenant_name} */}
                                                </SpaceBetween>
                                            </Flex>
                                        </ListItemText>
                                    </ListItem>))}
                                </div>}
                            {searching && searchKey.length > 0 && searchList.length === 0 && <p className="align-center">{t('common.noData')}</p>}
                            {!searching && (
                                <div>
                                    <List>
                                        <Divider />
                                        <div className="max-height-289px overflow-auto grey">
                                            {tenants && tenants.map((item) => (
                                                <ListItem
                                                    button
                                                    key={item.tenant_id}
                                                    selected={selected.length ? selected[0].tenant_id === item.tenant_id : selectedTenant && selectedTenant.tenant_id === item.tenant_id}
                                                    style={{ padding: 0 }}
                                                >
                                                    <ListItemText style={{ padding: 0 }}>
                                                        <Flex className="padding-left-10">
                                                            <SpaceBetween
                                                                className="width-100"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        selected: [item]
                                                                    });
                                                                }}>
                                                                <div className="font-size-14 top-bottom truncated-heading">
                                                                    {item.tenant_name}
                                                                </div>
                                                            </SpaceBetween>
                                                        </Flex>
                                                    </ListItemText>
                                                </ListItem>
                                            ))}
                                        </div>
                                        {tenants.length === 0 && (
                                            <VerticallyCenter>
                                                <AlignCenter>{t('common.error.networkError')}</AlignCenter>
                                            </VerticallyCenter>
                                        )}
                                    </List>

                                </div>)}
                            {tenants.length > 0 &&
                                <div className="absolute-bottom-0 width-91">
                                    <div className="space-between-width-center">
                                        <div />
                                        <div className="flex">
                                            <Button
                                                className="font-weight-bolder color-grey font-size-14"
                                                onClick={() => {
                                                    this.handleClose();
                                                    handleReset();
                                                }}
                                            >
                                                {t('email.reset')}
                                            </Button>
                                            <BlueButton
                                                className="font-size-14"
                                                disabled={selected.length === 0}
                                                // minWidth={120}
                                                style={{ minWidth: '120px' }}
                                                left={10}
                                                onClick={() => {
                                                    this.handleClose();
                                                    handleOnchange(selected[0]);
                                                }
                                                }
                                            >
                                                {t('package.apply')}
                                            </BlueButton>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Paper>
                    </ClickAwayListener>
                </Popover>
            </React.Fragment>
        );
    }
}

TenantSelector.propTypes = {
    t: PropTypes.func.isRequired,
    tenants: PropTypes.array.isRequired,
    handleOnchange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    selectedTenant: PropTypes.node.isRequired,
    userRole: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
};

export default connect(state => ({
    userRole: state.userRole,
    allCountries: state.countries,
    buildings: state.allBuildings,
}))(withMobileDialog()(TenantSelector));
