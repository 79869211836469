/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable quotes */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Popover,
  InputAdornment,
  ListItem,
  ListItemText,
  InputBase,
  ClickAwayListener,
  Paper,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';

export default function SearchDropdown({
  list, selection, selectItem, loading, type, preSelection
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  // const inputEl1 = useRef(null);
  // const inputEl2 = useRef(null);
  const [key, setKey] = useState('');
  const [menuList, setMenuList] = useState(list);

  // React.useEffect(() => {
  //   inputEl1.current.focus();
  //   inputEl2.current.focus();
  // }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setMenuList(list);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = event => {
    const newKey = event.target.value.trimLeft();
    setKey(key);
    let searchedList = [];
    list.filter(item => {
      if (item.name.toLowerCase().includes(newKey.toLowerCase())) {
        searchedList.push(item);
      }
      return item;
    });
    setMenuList(searchedList);
  };

  const enableAutoFocus = true;
  return (
    <Fragment>
      <Input
        disabled
        value={selection && selection.name}
      />
      <div
         style={{
           position: 'absolute',
           cursor: 'pointer',
           height: '100%',
           width: '100%'
         }}
         onClick={!loading && handleClick}
         role="presentation" />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper className="just-paper width-350px padding-15px max-height-400px overflowy-hidden">
            {type === 'getUserDetails' ? list.length !== 0 ? 
              <InputBase
              className="search topbar__search-field topbar__search-field--open search-bottom grey"
              // value={key}
              autoFocus={enableAutoFocus}
              onChange={handleChange}
              // inputRef={inputEl1}
              startAdornment={
                <InputAdornment position="start" className="start-adorment">
                  <Search className="start-adorment font-size-18" />
                </InputAdornment>
              }
            /> : null
            : <InputBase
              className="search topbar__search-field topbar__search-field--open search-bottom grey"
              // value={key}
              autoFocus={enableAutoFocus}
              onChange={handleChange}
              // inputRef={inputEl2}
              startAdornment={
                <InputAdornment position="start" className="start-adorment">
                  <Search className="start-adorment font-size-18" />
                </InputAdornment>
              }
            />
            }
            <div className="margin-top-bottom-10p max-height-315px overflowy-scroll grey padding-left-12">
              {menuList.map(item => (
                <ListItem
                  button
                  className="padding-0"
                  onClick={() => {
                    handleClose();
                    selectItem(item);
                  }}
                >
                  <ListItemText className="padding-0">
                    <div className="font-size-14 top-bottom truncated-heading">
                      {item.name}
                    </div>
                  </ListItemText>
                </ListItem>
              ))}
              {type === 'getUserDetails' && list.length === 0 && 
              <div className="font-size-14 top-bottom truncated-heading">
                Please select the {preSelection} first.
              </div>
              }
               {type === 'getUserDetails' && menuList.length === 0 && list.length !== 0 && 
              <div className="font-size-14 top-bottom truncated-heading">
                No result found.
              </div>
              }
            </div>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </Fragment>
  );
}

SearchDropdown.propTypes = {
  selectItem: PropTypes.func,
  list: PropTypes.array,
  selection: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
  preSelection: PropTypes.string
};

SearchDropdown.defaultProps = {
  selectItem: () => { },
  list: [],
  selection: '',
  loading: false,
  type: 'other',
  preSelection: ''
};
