/* eslint-disable prefer-template */
/* eslint-disable comma-dangle */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { informSlackDBNetworkAPIErrs } from './informSlack';

/* eslint-disable no-prototype-builtins */

export const couriersGlobalTop = [
  {
    id: 'selectAValue',
    name: 'Please Select Carrier'
  },
  {
    id: 'ups',
    name: 'UPS'
  },
  {
    id: 'usps',
    name: 'USPS'
  },
  {
    id: 'fedex',
    name: 'FedEx'
  },
];

export const couriersGlobal = [
  {
    id: 'amazon-fba-us',
    name: 'Amazon'
  },
  {
    id: 'canada-post',
    name: 'Canada Post'
  },
  {
    id: 'canpar',
    name: 'Canpar'
  },
  {
    id: 'dhl',
    name: 'DHL'
  },
  {
    id: 'hong-kong-post',
    name: 'Hong Kong Post'
  },
  {
    id: 'india-post',
    name: 'India Post'
  },
  {
    id: 'japan-post',
    name: 'Japan Post'
  },
  {
    id: 'lasership',
    name: 'LaserShip'
  },
  {
    id: 'ontrac',
    name: 'OnTrac'
  },
  {
    id: 'purolator',
    name: 'Purolator'
  },
  {
    id: 'royal-mail',
    name: 'Royal Mail'
  },
  {
    id: 'singapore-post',
    name: 'Singapore Post'
  },
  {
    id: 'tnt',
    name: 'TNT'
  },
  {
    id: 'cdl',
    name: 'CDL'
  },
  {
    id: 'dynamex',
    name: 'Dynamex'
  },
  {
    id: 'mark3',
    name: 'Mark3'
  },
  {
    id: 'maxx',
    name: 'Maxx'
  },
  {
    id: 'fastway',
    name: 'Fastway'
  },
  {
    id: 'australia-post',
    name: 'Australia Post'
  },
  {
    id: 'star-track',
    name: 'StarTrack'
  },
  {
    id: 'aramex',
    name: 'Aramex'
  },
  {
    id: 'toll',
    name: 'Toll Holdings'
  },
  {
    id: 'qantas',
    name: 'Qantas Freight'
  },
  {
    id: 'dfe',
    name: 'Direct Freight Express'
  },
  {
    id: 'dx-mail',
    name: 'DX Mail'
  },
  {
    id: 'smart-freight',
    name: 'Smart Freight'
  },
  {
    id: 'dpd',
    name: 'DPD Courier'
  },
  {
    id: 'couriersplease',
    name: 'Couriers Please'
  },
  {
    id: 'an-post',
    name: 'An Post'
  },
  {
    id: 'lone-star-overnight',
    name: 'Lone Star Overnight'
  },
  {
    id: 'sagawa-express',
    name: 'Sagawa Express'
  },
  {
    id: 'stat',
    name: 'STAT'
  },
  {
    id: 'yamato',
    name: 'Yamato'
  },
  {
    id: 'china-ems',
    name: 'China EMS'
  },
  {
    id: 'china-post',
    name: 'China Post'
  },
  {
    id: 'sr-group',
    name: 'SR Group'
  },
  {
    id: 'pentagon-freight',
    name: 'Pentagon Freight'
  },
  {
    id: 'merkesdal',
    name: 'Merkesdal'
  },
  {
    id: 'logi-trans',
    name: 'Logi Trans'
  }, {
    id: 'postnord',
    name: 'Postnord'
  }, {
    id: 'db-schenker',
    name: 'DB Schenker'
  }, {
    id: 'transferd',
    name: 'Transferd'
  }, {
    id: 'kuehne-nagel',
    name: 'Kuehne+Nagel'
  }, {
    id: 'nor-lines',
    name: 'Nor Lines'
  }, {
    id: 'jetpak',
    name: 'Jetpak'
  }, {
    id: 'bring',
    name: 'Bring'
  }, {
    id: 'loomis-express',
    name: 'Loomis Express'
  }, {
    id: 'dicom',
    name: 'Dicom'
  },
  {
    id: 'ctt',
    name: 'CTT'
  }, {
    id: 'correos',
    name: 'Correos'
  }, {
    id: 'correos-express',
    name: 'Correos Express'
  }, {
    id: 'envialia',
    name: 'Envialia'
  }, {
    id: 'gls',
    name: 'GLS'
  }, {
    id: 'nacex',
    name: 'Nacex'
  }, {
    id: 'seur',
    name: 'Seur'
  }, {
    id: 'mrw',
    name: 'MRW'
  }, {
    id: 'sending',
    name: 'Sending '
  }, {
    id: 'redur',
    name: 'Redur'
  },
  {
    id: 'swiss-post',
    name: 'Swiss Post'
  },
  {
    id: 'bluedart',
    name: 'Bluedart'
  },
  {
    id: 'chronopost',
    name: 'Chronopost'
  },
  {
    id: 'colissimo',
    name: 'Colissimo'
  },
  {
    id: 'dtdc',
    name: 'DTDC'
  },
  {
    id: 'shree-maruti',
    name: 'Shree Maruti'
  },
  {
    id: 'parcel-force',
    name: 'Parcel Force'
  },
  { id: 'rl-carrier', name: 'R+L Carriers' },
  { id: 'pitt-ohio-express', name: 'Pitt Ohio Express' },
  {
    id: 'other',
    name: 'Other'
  }
];
export const couriersChinese = [
  {
    id: 'selectAValue',
    name: 'Please Select Carrier'
  },
  {
    id: 'jd',
    name: '京东'
  },
  {
    id: 'china-ems',
    name: 'China EMS'
  },
  {
    id: 'china-post',
    name: 'China Post'
  },
  {
    id: 'pakistan-post',
    name: 'Pakistan Post'
  },
  {
    id: 'yunexpress',
    name: 'YunExpress'
  },
  {
    id: 'yundaex',
    name: 'Yunda Express'
  },
  {
    id: 'yto',
    name: 'YTO Express'
  },
  {
    id: 'yto-',
    name: 'YTO'
  },
  {
    id: 'sf-express',
    name: 'SF Express'
  },
  {
    id: 'zto-express',
    name: 'ZTO Express'
  },
  {
    id: 'nanjingwoyuan',
    name: 'Nanjing Woyuan'
  },
  { id: 'yanwen', name: 'Yanwen' },
  { id: 'szdpex', name: 'DPEX China' },
  { id: '800bestex', name: 'Best Express' },
  { id: 'zjs-express', name: 'ZJS Express' },
  { id: 'sto', name: 'STO' },
  { id: 'sto-express', name: 'STO Express' },
  { id: 'equick-cn', name: 'Equick China' },

];
export const spaceOnly = atr => atr && atr.replace(/^\s+|\s+$/g, '').trim() === '';

export function incrementLast(v) {
  return v && v.replace(/[0-9]+(?!.*[0-9])/, function (match) {
    return parseInt(match, 10) + 1;
  });
}

const setChinaHeader = auth =>
  new Promise((resolve, reject) => {
    axios.defaults.headers.common.Authorization =
      auth !== null
        ? auth.login_details
          ? auth.login_details.access_token
          : auth.access_token
        : 'Not defined';
    resolve(null);
  }).catch(err => {
    axios.defaults.headers.common.Authorization = 'Not defined';
    informSlackDBNetworkAPIErrs(err, 'setChinaHeader - src/util/index / line # 222');
    throw err;
  });

// export function validateEmail(email) {
//   const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return emailRegex.test(String(email).toLowerCase());
// }

export function validateEmail(email) {
  const emailRegex = /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  return emailRegex.test(String(email).toLowerCase());
}

export function validateName(name) {
  const nameRegex = /[`!@#$%^*_+\=\[\]{};:"\\|<>\/?~]/;
  return !nameRegex.test(String(name).toLowerCase());
}

export function validateBusinessName(name) {
  const nameRegex = /[`!@#$%^*_+\=\[\]{};:"\\|<>?~]/;
  return !nameRegex.test(String(name).toLowerCase());
}

export function validateBuildingAndAssociateName(name) {
  const nameRegex = /[`!$%^*_+\=\[\]{};:"\\|<>?~]/;
  return !nameRegex.test(String(name).toLowerCase());
}

export function generalValidation(name) {
  const nameRegex = /[`!@$%^*_+\=\[\]{};':"\\<>\?~]/;
  return !nameRegex.test(String(name).toLowerCase());
}

export function formatPhoneNumber(phoneNumberString) {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

export function validateSearchCharacter(input) {
  const nameRegex = /[`#!$%^*+\=\[\]{};':"\\<>\?~|]/;
  return !nameRegex.test(String(input).toLowerCase());
}

export function validateZipCode(name) {
  const nameRegex = /.*[/!`~_;'+=@#$%^&*()\[\]\\,.?":{}|<>]/;
  return !nameRegex.test(String(name).toLowerCase());
}
