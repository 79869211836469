import React from 'react';
import PropTypes from 'prop-types';
// import { Prompt } from 'react-router';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CircularProgress } from '@material-ui/core';
import SetPassword from './component/SetPassword';
import Alert from '../../UI/Poper';
import Welcome from './component/Welcome';
import AddBuilding from './component/AddBuilding';
import AddRecipients from './component/AddRecipients';
// import DownloadApp from './component/DowloadApp';
import AdditionalDetails from './component/newScreens/AdditionalDetails';
// import AccountReady from './component/AccountReady';
import ScanItems from './component/newScreens/ScanItems';
// import SendNotifications from './component/SendNotifications';
// import PickupPackages from './component/PickupPackages';
// import AllSet from './component/AllSet';
import CustomSteps from './component/CustomSteps';
import { getStepOnboarding, stepUpdateOnboarding } from '../../../server/auth';
import { setStep } from '../../../redux/actions/userStepAction';
import { setUser } from '../../../redux/actions/userDeatilsActions';
import { showSkipTutorial } from '../../../redux/actions/sidebarActions';
import { AlignCenter, VerticallyCenter } from '../../UI/ResponsiveRelated';
import {
  getUserEmailURL,
  decryptEmailWithAES,
} from './../../../util/application.utils';
import { validateEmail } from './../../../util/index';
import { CallTrigger } from '../../../util/Triggers/function';
import { CLA } from '../../../util/Triggers/triggerNames';

import AccountReadyNew from './newComponents/AccountReady';
import NotifyNew from './newComponents/NotifyRecipients';
import Checkout from './newComponents/Checkout';

class OnBoarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionBitSetPass: false,
      actionBitSetbuilding: false,
      actionBitSetRecipients: false,
      // actionBitAllSet: false,
      stepNumber: null,
      startingStep: 1,
      steps: [],
      isStartOnboarding: false,
      error: null,
      disableNext: false,
      disablePrev: false,
      loading: false,
      waiting: false,
    };
  }

  async componentDidMount() {
    let email = null;
    try {
      email = await decryptEmailWithAES(getUserEmailURL());
    } catch (error) {
      console.error(error);
    }
    if (!validateEmail(email)) {
      this.props.history.replace('/404');
    }
    localStorage.setItem('onboarding', true);
    const tempUserName = localStorage.getItem('userName');
    const tempUserEmail = localStorage.getItem('onBoarding_email');
    const originalUserName = localStorage.getItem('recipientsNames');
    const originalUserEmail = localStorage.getItem('recipientsEmails');
    if (
      (!originalUserName || !originalUserEmail) &&
      tempUserName &&
      tempUserEmail
    ) {
      const recipientName = new Array(tempUserName);
      const recipientEmail = new Array(tempUserEmail);
      localStorage.setItem('recipientsNames', JSON.stringify(recipientName));
      localStorage.setItem('recipientsEmails', JSON.stringify(recipientEmail));
    }
    this.props.setStep(1);
    const { t } = this.props;
    // eslint-disable-next-line
    this.setState(
      {
        loading: true,
        steps: [
          <SetPassword
            handleDisableNext={this.handleDisableNext}
            unsetActionBit={this.unsetActionBitSetPass}
            actionBit={this.state.actionBitSetPass}
            setUser={this.props.setUser}
            setError={this.setError}
            next={this.next}
            back={this.back}
            t={t}
          />,
          <Welcome
            handleDisablePrev={this.handleDisablePrev}
            getUserCurrentStep={this.getUserCurrentStep}
            next={this.next}
            back={this.back}
            t={t}
          />,
          <AddBuilding
            {...this.props}
            clientName={null}
            unsetActionBit={this.unsetActionBitSetbuilding}
            actionBit={this.state.actionBitSetbuilding}
            setError={this.setError}
            handleDisableNext={this.handleDisableNext}
            next={this.next}
            back={this.back}
          />,
          <AddRecipients
            {...this.props}
            handleDisablePrev={this.handleDisablePrev}
            unsetActionBit={this.unsetActionBitSetRecipients}
            actionBit={this.state.actionBitSetRecipients}
            setError={this.setError}
            handleDisableNext={() => {}}
            next={this.next}
            back={this.back}
          />,
          // <DownloadApp next={this.next} back={this.back} t={t} />,
          <AccountReadyNew next={this.next} back={this.back} t={t} />,
          <ScanItems
            getUserCurrentStep={this.getUserCurrentStep}
            userDetail={this.props.userDetail}
            next={this.next}
            back={this.back}
            t={t}
          />,
          <AdditionalDetails next={this.next} back={this.back} t={t} getUserCurrentStep={this.getUserCurrentStep} />,
          <NotifyNew next={this.next} back={this.back} t={t} />,
          <Checkout
            userDetail={this.props.userDetail}
            next={this.next}
            back={this.back}
            t={t}
          />,
          // <AllSet next={this.next} back={this.back} t={t} />
        ],
      },
      () => {
        Auth.currentSession()
          .then(() => {
            this.getUserCurrentStep((obj) => {
              if (isNaN(obj.stepNumber)) return;
              if (
                obj.stepNumber === 10 ||
                obj.stepNumber === 12 ||
                obj.stepNumber === 11 ||
                obj.stepNumber === -1
              ) {
                this.props.setStep(10);
                localStorage.removeItem('onboarding');
                return this.props.history.replace('/dashboard');
              }
              if (obj.stepNumber !== -1) {
                this.props.setStep(obj.stepNumber);
                this.setState({
                  stepNumber: obj.stepNumber,
                  isStartOnboarding: obj.isStartOnboarding,
                });
              }
              if (obj.stepNumber >= 3) {
                this.props.showSkipTutorial(true);
              }
              // eslint-disable-next-line
              this.setState({ loading: false });
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false, stepNumber: 1 });
          });
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.stepNumber !== prevState.stepNumber) {
      this.props.setStep(this.state.stepNumber);
      if (this.state.stepNumber === 5) {
        this.props.showSkipTutorial(true);
      }
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('onboarding');
  }

  getCurrentScreen = () => {
    const { stepNumber, waiting } = this.state;
    const { t } = this.props;
    switch (stepNumber) {
      case 1:
        return (
          <SetPassword
            handleDisableNext={this.handleDisableNext}
            handleDisablePrev={this.handleDisablePrev}
            unsetActionBit={this.unsetActionBitSetPass}
            actionBit={this.state.actionBitSetPass}
            setUser={this.props.setUser}
            setError={this.setError}
            next={this.next}
            back={this.back}
            t={t}
          />
        );
      case 2:
        return (
          <Welcome
            waiting={waiting}
            handleDisableNext={this.handleDisableNext}
            handleDisablePrev={this.handleDisablePrev}
            getUserCurrentStep={this.getUserCurrentStep}
            next={this.next}
            back={this.back}
            t={t}
          />
        );
      case 3:
        return (
          <AddBuilding
            {...this.props}
            clientName={null}
            unsetActionBit={this.unsetActionBitSetbuilding}
            actionBit={this.state.actionBitSetbuilding}
            setError={this.setError}
            handleDisableNext={this.handleDisableNext}
            next={this.next}
            back={this.back}
          />
        );
      case 4:
        return (
          <AddRecipients
            {...this.props}
            handleDisablePrev={this.handleDisablePrev}
            unsetActionBit={this.unsetActionBitSetRecipients}
            actionBit={this.state.actionBitSetRecipients}
            setError={this.setError}
            handleDisableNext={() => {}}
            next={this.next}
            back={this.back}
          />
        );
      case 5:
        return (
          <AccountReadyNew
            handleDisableNext={this.handleDisableNext}
            handleDisablePrev={this.handleDisablePrev}
            waiting={waiting}
            next={this.next}
            back={this.back}
            t={t}
          />
        );
      // return <DownloadApp handleDisableNext={this.handleDisableNext} handleDisablePrev={this.handleDisablePrev} waiting={waiting} next={this.next} back={this.back} t={t} />;
      // case 6:
      //   return (
      //     <AccountReady
      //       handleDisableNext={this.handleDisableNext}
      //       handleDisablePrev={this.handleDisablePrev}
      //       waiting={waiting}
      //       next={this.next}
      //       back={this.back}
      //       t={t}
      //     />
      //   );
      case 6:
        return (<ScanItems
            handleDisableNext={this.handleDisableNext}
            handleDisablePrev={this.handleDisablePrev}
            waiting={waiting}
            userDetail={this.props.userDetail}
            getUserCurrentStep={this.getUserCurrentStep}
            next={this.next}
            back={this.back}
            t={t}
          />);
      case 7:
        return (
          <AdditionalDetails
              handleDisableNext={this.handleDisableNext}
              handleDisablePrev={this.handleDisablePrev}
              getUserCurrentStep={this.getUserCurrentStep}
              waiting={waiting}
              next={this.next}
              back={this.back}
              t={t}
            /> 
            );
          
          //   <ScanLabel
          //     handleDisableNext={this.handleDisableNext}
          //     handleDisablePrev={this.handleDisablePrev}
          //     waiting={waiting}
          //     userDetail={this.props.userDetail}
          //     next={this.next}
          //     back={this.back}
          //     t={t}
          //   />
      case 8:
          return (
            <NotifyNew
            handleDisableNext={this.handleDisableNext}
            handleDisablePrev={this.handleDisablePrev}
            getUserCurrentStep={this.getUserCurrentStep}
            waiting={waiting}
            next={this.next}
            back={this.back}
            t={t}
          />
          );
          //   <SendNotifications
          //     handleDisableNext={this.handleDisableNext}
          //     handleDisablePrev={this.handleDisablePrev}
          //     waiting={waiting}
          //     next={this.next}
          //     back={this.back}
          //     t={t}
          //   />
      case 9:
        return (
          <Checkout
            handleDisableNext={this.handleDisableNext}
            handleDisablePrev={this.handleDisablePrev}
            getUserCurrentStep={this.getUserCurrentStep}
            waiting={waiting}
            next={this.next}
            back={this.back}
            t={t}
          />
        );
      // case 11:
      //     return <AllSet actionBit={this.state.actionBitAllSet} unsetActionBit={this.unsetActionBitSetRecipients} handleDisableNext={this.handleDisableNext} handleDisablePrev={this.handleDisablePrev} waiting={waiting} next={this.next} back={this.back} t={t} />;
      default:
        return <></>;
    }
  };

  getUserCurrentStep = (callback) => {
    getStepOnboarding()
      .then((res) => {
        if (res.data.last_step === 'start_onboarding') {
          const obj = {
            stepNumber: 2,
            isStartOnboarding: true,
          };
          if (res.data.tenant_info.client_name) {
            localStorage.setItem(
              'clientName',
              res.data.tenant_info.client_name
            );
          }
          callback(obj);
        }
        if (res.data.last_step === null) {
          const obj = {
            stepNumber: -1,
            isStartOnboarding: false,
          };
          callback(obj);
        } else {
          const obj = {
            stepNumber: parseInt(res.data.last_step, 10) + 1,
            isStartOnboarding: false,
          };
          callback(obj);
        }
      })
      .catch((err) => {
        const obj = {
          stepNumber: -1,
          isStartOnboarding: false,
        };
        callback(obj);
        console.log(err);
      });
  };

  // getStep = () => this.state.steps[this.state.stepNumber - 1];
  getStep = () => this.getCurrentScreen();

  setError = (err) => {
    this.setState({ error: err });
  };

  // handleUnload = (e) => {
  //     const message = '\o/';
  //     (e || window.event).returnValue = message; // Gecko + IE
  //     return message;
  // }

  unsetActionBitSetPass = () => this.setState({ actionBitSetPass: false });

  unsetActionBitSetbuilding = () =>
    this.setState({ actionBitSetbuilding: false });

  unsetActionBitSetRecipients = () =>
    this.setState({ actionBitSetRecipients: false });

  // unsetActionBitAllSet = () => this.setState({ actionBitAllSet: false });

  incrementStep = () => {
    const stepNumber = this.state;
    let stepNum = parseInt(stepNumber, 10);
    this.setState({ stepNumber: stepNum + 1 });
  };

  updateUserStep = (stepNumber, stepsLength) => {
    this.setState({ waiting: true }, () => {
      stepUpdateOnboarding(stepNumber.toString())
        .then(() => {
          if (parseInt(stepNumber, 10) + 1 <= stepsLength) {
            this.setState({ stepNumber: parseInt(stepNumber, 10) + 1 });
          } else {
            this.props.showSkipTutorial(false);
            localStorage.setItem('trialUser', true);
            localStorage.removeItem('building');
            localStorage.removeItem('clientName');
            localStorage.removeItem('onBoarding_email');
            localStorage.removeItem('recipientsNames');
            localStorage.removeItem('recipientsEmails');
            // Set bit in Local Storage that indicates new user has not uploaded CSV
            localStorage.setItem('trialUserUploadCSV', true);
            this.props.history.replace('/dashboard');
          }
          this.setState({ waiting: false });
        })
        .catch(() => {
          this.setState({ waiting: false });
        });
    });
  };

  next = () => {
    CallTrigger(CLA.NEXT_BTN.Next_bclick, { Previous_event: '' });
    const {
      steps,
      stepNumber,
      isStartOnboarding,
      actionBitSetPass,
      actionBitSetbuilding,
      actionBitSetRecipients,
    } = this.state;
    if (actionBitSetPass || actionBitSetbuilding || actionBitSetRecipients) {
      this.setState(
        {
          actionBitSetPass: false,
          actionBitSetbuilding: false,
          actionBitSetRecipients: false,
          isStartOnboarding: false,
        },
        () => {
          this.updateUserStep(stepNumber.toString(), steps.length);
        }
      );
    } else if (!isStartOnboarding) {
      this.updateUserStep(stepNumber.toString(), steps.length);
    } else if (stepNumber === 3) {
      this.setState({ isStartOnboarding: false }, () => {
        this.updateUserStep(stepNumber.toString(), steps.length);
      });
    } else if (stepNumber + 1 <= steps.length) {
      this.setState({ isStartOnboarding: false }, () => {
        this.updateUserStep(stepNumber.toString(), steps.length);
      });
    }
  };

  back = () => {
    CallTrigger(CLA.PREV_BTN.Previous_bclick, { Previous_event: '' });
    if (this.state.stepNumber - 1 >= this.state.startingStep) {
      this.setState({ stepNumber: parseInt(this.state.stepNumber, 10) - 1 });
    }
  };

  nextButton = () => {
    const { stepNumber, steps } = this.state;
    if (stepNumber === 1) {
      this.setState({
        actionBitSetPass: true,
      });
    } else if (stepNumber === 3) {
      this.setState({
        actionBitSetbuilding: true,
      });
    } else if (stepNumber === 4) {
      this.setState({
        actionBitSetRecipients: true,
      });
    } else if (stepNumber + 1 <= steps.length) {
      this.setState({ isStartOnboarding: false }, () => {
        this.updateUserStep(stepNumber.toString(), steps.length);
      });
    }
  };

  handleDisableNext = (check) => {
    this.setState({ disableNext: check });
  };

  handleDisablePrev = (check) => this.setState({ disablePrev: check });

  render() {
    const {
      stepNumber,
      steps,
      error,
      disablePrev,
      disableNext,
      loading,
    } = this.state;
    return (
      <div className="onBoard-page-container">
        {/* <NavigationBlocker shouldBlockNavigation={shouldBlockNavigation} /> */}
        {error && (
          <Alert
            variant="error"
            open
            message={error}
            onClose={() => this.setState({ error: null })}
          />
        )}

        {loading ? (
          <VerticallyCenter>
            <AlignCenter>
              <CircularProgress />
            </AlignCenter>
          </VerticallyCenter>
        ) : (
          <div className="onBoard-page-container-top">{this.getStep()}</div>
        )}

        <div className="onBoard-page-container-bottom">
          <CustomSteps
            disablePrev={disablePrev}
            disableNext={disableNext}
            totalSteps={steps.length}
            currentStep={stepNumber}
            next={this.nextButton}
            back={this.back}
            t={this.props.t}
          />
        </div>
      </div>
    );
  }
}

OnBoarding.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => ({
  setStep: (steps) => dispatch(setStep(steps)),
  setUser: (user) => dispatch(setUser(user)),
  showSkipTutorial: (status) => dispatch(showSkipTutorial(status)),
});

const mapStateToProps = (state) => ({
  userDetail: state.userDetails,
  user: state.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OnBoarding));

// const NavigationBlocker = ({ shouldBlockNavigation }) => {
//     if (shouldBlockNavigation) {
//         window.onbeforeunload = () => true;
//     } else {
//         window.onbeforeunload = null;
//     }
//     return (
//         <Prompt
//             when={shouldBlockNavigation}
//             message="Are you sure you want to leave?"
//         />
//     );
// };

// NavigationBlocker.propTypes = {
//     shouldBlockNavigation: PropTypes.bool.isRequired,
// };
