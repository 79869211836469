import axios from 'axios';
import { server } from './server';
import { getPaymentSuccessURL, getPaymentCancelURL } from '../util/application.utils';

export function getPaymentPlans() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${server}/sales/profile?notCLA=true`)
        .then(response => {
          resolve(response);
        }).catch(error => reject(error));
    });
}

export function getStripePaymentDetails() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/stripe/payment`)
      .then(response => {
        resolve(response);
      }).catch(error => reject(error));
  });
}

export function setPaymentPlan(selectedPlan) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${server}/stripe/payment`, {
            price_id: selectedPlan,
            success_url: getPaymentSuccessURL(),
            cancel_url: getPaymentCancelURL(),
        })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
}

export function updatedUserCard(paymentMethod) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${server}/stripe/payment`, {
        payment_method: paymentMethod,
      })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export function deleteUserCard(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${server}/stripe/payment`, {
        data: {
          payment_method: id,
        },
      })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export function getPaymentHistory() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/payment/history`)
      .then(response => {
        resolve(response);
      }).catch(error => reject(error));
  });
}

export function changeUserPlan(tenantName, existingPlan, newPlan) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/entity/user/changeplan`, {
        tenant_name: tenantName,
        existing_plan: existingPlan,
        new_plan: newPlan,
    })
      .then(response => {
        resolve(response);
      }).catch(error => reject(error));
  });
}

export function contactSalesTeam(tenantName) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/entity/user/changeplan`, {
        tenant_name: tenantName,
        contact_sales_flag: true,
    })
      .then(response => {
        resolve(response);
      }).catch(error => reject(error));
  });
}


export function deleteUser(tenantId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${server}/sales/tenant`, {
        data: {
          tenant_id: tenantId,
        },
      })
      .then(response => {
        resolve(response);
      }).catch(error => reject(error));
  });
}
