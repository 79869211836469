/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
import React from 'react';
import {
  Input,
  TextField,
  withStyles,
  InputLabel,
  InputAdornment,
  IconButton,
  FormLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import ReactPhoneInput from 'react-phone-input-2';
import './style.css';

const inputStyle = {
  fontSize: window.screen.width < 770 ? 12 : 14,
  fontWeight: 400,
  color: '#000 !important',
};
const labelStyle = {
  fontSize: window.screen.width < 770 ? 12 : 14,
  fontWeight: 400,
};

const styles = () => ({
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#027AFF',
    },
  },
  cssUnderlineRed: {
    '&:after': {
      borderBottomColor: '#FF1E59',
    },
    '&:before': {
      borderBottomColor: '#FF1E59',
    },
    '&:hover': {
      borderBottomColor: '#FF1E59',
    },
  },
  cssFocused: {},
  cssLabel: {
    '&$cssFocused': {
      color: '#027AFF',
    },
  },
});

const adornmentInputField = props => {
  const { classes, style, danger, ...other } = props;
  let newStyle = inputStyle;
  let readOnly_Flag = true;
  if (style) {
    newStyle = { ...style, ...inputStyle };
  }
  return (
    <Input
      classes={{
        underline: danger ? classes.cssUnderlineRed : classes.cssUnderline,
        ...classes,
      }}
      readOnly={readOnly_Flag}
      autoFocus={props.autoFocus}
      style={newStyle}
      endAdornment={
        <InputAdornment position="end" style={{ padding: 0 }}>
          <IconButton onClick={props.onClick} style={{ padding: 0 }}>
            {props.children}
          </IconButton>
        </InputAdornment>
      }
      {...other}
    />
  );
};

const CustomAdornmentInput = withStyles(styles)(adornmentInputField);

const InputField = props => {
  const { classes, style, danger, ...other } = props;
  let newStyle = inputStyle;
  if (style) {
    newStyle = { ...style, ...inputStyle };
  }
  return (
    <Input
      classes={{
        underline: danger ? classes.cssUnderlineRed : classes.cssUnderline,
        ...classes,
      }}
      style={newStyle}
      inputProps={!other.inputProps ? { style: { fontSize: 14 } } : {}}
      {...other}
    />
  );
};

const CustomInput = withStyles(styles)(InputField);


const InputArea = props => (
    <TextField
          id="outlined-multiline-static"
          label="Multiline"
          multiline
          rows={4}
          defaultValue="Default Value"
          variant="outlined"
          {...props}
      />
  );

const CustomTextArea = withStyles(styles)(InputArea);

const TextInputField = props => {
  const { InputLabelProps, InputProps, ...other } = props;
  let classes = {
    underline: props.classes.cssUnderline,
  };
  let labelClasses = {
    root: props.classes.cssLabel,
    focused: props.classes.cssFocused,
  };
  if (InputProps && InputProps.classes) {
    classes = {
      underline: props.classes.cssUnderline,
      ...InputProps.classes,
    };
  }
  let newInputStyle = inputStyle;
  if (InputProps && InputProps.style) {
    newInputStyle = { ...inputStyle, ...InputProps.style };
  }
  let newInputLabelStyle = labelStyle;
  if (InputLabelProps && InputLabelProps.style) {
    newInputLabelStyle = { ...newInputLabelStyle, ...InputLabelProps.style };
  }
  if (InputLabelProps && InputLabelProps.classes) {
    labelClasses = {
      root: props.classes.cssLabel,
      focused: props.classes.cssFocused,
      ...InputLabelProps.classes,
    };
  }
  return (
    <TextField
      InputProps={{
        classes,
        style: newInputStyle,
        ...InputProps,
      }}
      InputLabelProps={{
        style: newInputLabelStyle,
        classes: labelClasses,
        ...InputLabelProps,
      }}
      {...other}
    />
  );
};

const CustomTextField = withStyles(styles)(TextInputField);

const Label = props => {
  const { classes, style, ...other } = props;
  let newStyle = labelStyle;
  if (style) {
    newStyle = { ...style, ...labelStyle };
  }
  return (
    <InputLabel
      style={newStyle}
      classes={{
        root: classes.cssLabel,
        focused: classes.cssFocused,
        ...classes,
      }}
      {...other}
    />
  );
};

const CustomInputLabel = withStyles(styles)(Label);

const CustomPhoneNumInput = props => {
  const {
    t,
    value,
    onChange,
    placeholder,
    error,
    formStyles,
    formClass,
    ...other
  } = props;
  return (
    <FormControl className={formClass} fullWidth>
      <FormLabel className="label-phone-input font-size-11 color-grey">
        {placeholder}
      </FormLabel>
      <ReactPhoneInput
        placeholder={placeholder}
        enableSearch
        enableSearchField
        disableSearchIcon
        autoFormat={false}
        containerStyle={{ marginBottom: '15px' }}
        defaultCountry="us"
        country="us"
        value={value}
        onChange={onChange}
        inputClass="phone-input"
        buttonClass="phone-input-flag"
        searchStyle={{ margin: '0', width: '97%', height: '30px' }}
        inputExtraProps={{ name: 'tel' }}
        {...other}
      />
      {error !== '' ? (
        <FormHelperText className="margin-top-m10px phone-input-error red">
          {error}
        </FormHelperText>
      ) : (
        ''
      )}
    </FormControl>
  );
};

export {
  CustomInput,
  CustomTextField,
  CustomInputLabel,
  CustomAdornmentInput,
  CustomPhoneNumInput,
  CustomTextArea,
};
