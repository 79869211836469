/* eslint-disable react/no-array-index-key */
import React, { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import PackageDialog from '../../../Dashboard/Packages/components/Dialog';
// import { getPackageImage } from '../../../../server';

const Gallery = (props) => {
  Gallery.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    display: PropTypes.bool.isRequired,
    getData: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    style: PropTypes.object.isRequired,
    t: PropTypes.bool.isRequired,
    images: PropTypes.arrayOf(PropTypes.shape({
      src: PropTypes.string,
      type: PropTypes.string,
      alt: PropTypes.string,
    })).isRequired,
    dynamicLabels: PropTypes.node.isRequired,
    packages: PropTypes.object.isRequired
  };
  const {
    loading, error, display, t, style, getData, handleClose, packages
  } = props;
  const [images, setImages] = useState([]);
  // const [show, setShow] = useState(true); 
  const show = false;
  useEffect(() => {
    setImages(props.images);
  },[props.images]);
  // useEffect(() => {
  //   images.filter((img, index) => getPackageImage(img.package_id)
  //   .then(pkg => {
  //     images[index] = { 
  //       thumbnail: `data:image/jpg;base64,${pkg.package_image}`, 
  //       package_id: img.package_id, 
  //       name: img.name, 
  //       scanType: img.scanType
  //     };
  //     setShow(prevShow => !prevShow);
  //   }));
  // }, [loading, images]);
  return (
    <div className={`gallery ${!display && 'display-none'}`}>
      {(loading || error) && (
        <div className="align-center-100 padding-20px">
          {loading ? <CircularProgress className="blue" size={30} /> : <>{error}</>}
        </div>
      )}
      {show && <div />}
      {!loading && !error && images.map((img, i) => (
        <PackageDialog
          fullSize
          id={img.package_id}
          thumbnail={img.thumbnail}
          index={i}
          t={t}
          style={style}
          packages={packages}
          getData={getData}
          handleClose={handleClose}
          scanType={img.scanType}
          dynamicLabels={props.dynamicLabels}
        />

        ))}
      {!loading && !error && images.length === 0 && <div className="align-center-100 padding-20px grey-clr">{t('tables.noMorePackages')}</div>}
    </div>
  );
};
export default Gallery;

// /* eslint-disable react/no-array-index-key */
// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { CircularProgress } from '@material-ui/core';
// import PackageDialog from '../../../Dashboard/Packages/components/Dialog';
// import { getPackageImage } from '../../../../server';

// export default class Gallery extends Component {
//   static propTypes = {
//     loading: PropTypes.bool.isRequired,
//     error: PropTypes.string.isRequired,
//     display: PropTypes.bool.isRequired,
//     getData: PropTypes.func.isRequired,
//     handleClose: PropTypes.func.isRequired,
//     style: PropTypes.object.isRequired,
//     t: PropTypes.bool.isRequired,
//     images: PropTypes.arrayOf(PropTypes.shape({
//       src: PropTypes.string,
//       type: PropTypes.string,
//       alt: PropTypes.string,
//     })).isRequired,
//   };

//   constructor(props) {
//     super(props);
//     this.state = {
//       images: this.props.images,
//       show: true,
//     };
//   }

//   static getDerivedStateFromProps() {
//     return true;
//   }

//   componentDidUpdate(props) {
//     const { loading, display } = this.props;
//     if ((display && loading !== props.loading) || !props.display && display) {
//       this.setState({ images: this.props.images }, () => {
//         this.state.images.filter((img, index) => getPackageImage(img.package_id)
//           .then(pkg => {
//             const { images } = this.state;
//             images[index] = { thumbnail: `data:image/jpg;base64,${pkg.package_image}`, package_id: img.package_id, name: img.name };
//             this.setState({ images, show: !this.state.show });
//           }));
//       });
//     }
//   }

//   render() {
//     const {
//       loading, error, display, t, style, getData, handleClose,
//     } = this.props;
//     const { images, show } = this.state;
//     return (
//       <div className={`gallery ${!display && 'display-none'}`}>
//         {(loading || error) && (
//           <div className="align-center-100 padding-20px">
//             {loading ? <CircularProgress className="blue" size={30} /> : <>{error}</>}
//           </div>
//         )}
//         {show && <div />}
//         {!loading && !error && images.map((img, i) => (
//           <PackageDialog
//             fullSize
//             id={img.package_id}
//             thumbnail={img.thumbnail}
//             index={i}
//             t={t}
//             style={style}
//             packages={images}
//             getData={getData}
//             handleClose={handleClose}
//           />
//           ))}
//         {!loading && !error && images.length === 0 && <div className="align-center-100 padding-20px grey-clr">{t('tables.noMorePackages')}</div>}
//       </div>
//     );
//   }
// }
