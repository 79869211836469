import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, CircularProgress, Button } from '@material-ui/core';
import { Margin } from '../../../UI/ResponsiveRelated';
// import Alert from '../../../UI/Poper';

const ConfirmationModal = (props) => {
  const {
    openConfirmation,
    selectedAssociateNameToDelete,
    // // successDeletingAssociate,
    // errorDeletingAssociate,
    deleteMemberLoader,
//    updateCloseAlert,
    deleteAssociate,
    closepopUp,
    // closeAlert,
    t,
  } = props;

  return (
      <Dialog
        open={openConfirmation}
        onClose={closepopUp}
        disableBackdropClick={false}
        onBackdropClick={closepopUp}
      >
        {' '}
        <div
          style={{
            width:
              window.screen.width < 450 && window.screen.width > 365
                ? 278
                : window.screen.width < 365
                  ? 220
                  : 400,
            overflowX: 'hidden',
          }}
        >
          <Margin
            style={{
              padding: 20,
              paddingBottom: window.screen.width < 400 ? 10 : 0,
            }}
          >
            <p
              style={{
                fontSize: window.screen.width < 400 ? 20 : 22,
                fontWeight: 500,
              }}
            >
              {t('associates.deleteAssociate')}
            </p>
            <p
              style={{
                fontSize: 14,
                color: '#898b8a',
              }}
            >
              {/* {`${t('associates.confirmationOnDeleteAssociate')} ${selectedAssociateNameToDelete} ?`} */}
              <span>{t('associates.confirmationOnDeleteAssociate')}</span>
              <span
                style={{ color: '#ff1e59' }}
              >{` ${selectedAssociateNameToDelete}`}</span>
              <span>?</span>
            </p>
          </Margin>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: window.screen.width < 400 ? 10 : 20,
            }}
          >
            <Button
              // className={classes.button}
              style={{
                width: window.screen.width < 400 ? 40 : 90,
                height: 34,
                margin: 2,
              }}
              onClick={closepopUp}
            >
              {t('associates.no')}
            </Button>
            <Button
              style={{
                width: window.screen.width < 350 ? 40 : 90,
                height: 33,
                backgroundColor: '#027AFF',
                color: 'white',
                margin: 2,
              }}
              variant="contained"
              // className={classes.button}
              onClick={() => {
                deleteAssociate();
              }}
            >
              {t('email.yes')}{' '}
              {deleteMemberLoader && (
                <CircularProgress
                  className="white"
                  style={{ marginLeft: 5 }}
                  size={15}
                />
              )}
            </Button>
          </div>
        </div>
     
      </Dialog>
  );
};

ConfirmationModal.propTypes = {
  openConfirmation: PropTypes.bool.isRequired,
  selectedAssociateNameToDelete: PropTypes.string.isRequired,
//  successDeletingAssociate: PropTypes.bool.isRequired,
 // errorDeletingAssociate: PropTypes.bool.isRequired,
  deleteMemberLoader: PropTypes.bool.isRequired,
 // updateCloseAlert: PropTypes.func.isRequired,
  deleteAssociate: PropTypes.func.isRequired,
  closepopUp: PropTypes.func.isRequired,
 // closeAlert: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ConfirmationModal;
