import { SET_PAGINATION } from '../actions/paginationAction';
import { informSlackDBCodeErrs } from '../../util/informSlack';

let initialState = [];

try {
  initialState = localStorage.px_pagination ? JSON.parse(localStorage.px_pagination) : [];
} catch (err) {
  informSlackDBCodeErrs(err,'source > src/redux/reducers/paginationReducer', 'line # 9');
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PAGINATION: {
      const routes = state.map(obj => obj.route);
      const routeIndex = routes.indexOf(action.pagination.route);
      if (routeIndex > -1) state[routeIndex].offset = action.pagination.offset;
      else state.push(action.pagination);
      localStorage.px_pagination = JSON.stringify(state);
      return state;
    }
    default: return state;
  }
}
