/* eslint-disable */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ModalImage from 'react-modal-image';
import NoImage from '../../../../../../assets/images/no-image.jpg';
import { getPackageStatus } from '../../../../../../util/utility.functions';

class ActionItemImageConatiner extends React.Component {
  render() {
    const {
      // packageImage,
      packages,
      currentIndex,
      t,
      forwarded,
      packageDetail,
    } = this.props;
    // console.log('ActionItemImageConatiner >',currentIndex, packages, packages[currentIndex]);
    const isHolding =
      Math.floor(
        Math.abs(
          Date.now() -
            new Date(
              `${packages[currentIndex].created_at ||
                packages[currentIndex].check_in}`
            )
        ) /
          (1000 * 3600 * 24)
      ) < 10;
    const { packageStatus, colour } = getPackageStatus(
      // packages[currentIndex].status,
      packageDetail.status,
      packageDetail.action_status,
      // packages[currentIndex].action_status,
      isHolding,
      // packages[currentIndex].activity_type,
      packageDetail.activity_type,
      t
    );

    return (
      <>
        <div style={{ display: 'block' }}>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              style={{
                position: 'relative',
                width: '85%',
              }}
            >
              {/* <img
                                style={{ display: 'absolute' }}
                                className="package-image"
                                src={packageImage === 'noImage' ? NoImage : `data:image/jpg;base64,${packageImage}`}
                                alt="Package"
                            /> */}
              <img
                style={{ display: 'absolute' }}
                id={`pkgImage-${currentIndex}`}
                className="package-image"
                src={
                  forwarded
                    ? packageDetail.package_image
                    : packages[currentIndex].package_image === ''
                    ? NoImage
                    : packages[currentIndex].package_image
                }
                alt="Package"
                onError={() => {
                  const elem = document.getElementById(
                    `pkgImage-${currentIndex}`
                  );
                  elem.style.boxShadow = 'none';
                  elem.src = NoImage;
                  return elem;
                }}
              />
              <div className="status-action-header">
                <div
                  className="status-action-arrow"
                  style={{ borderRight: `7px solid ${colour}` }}
                />
                <div
                  className="status-action-inner"
                  style={{ backgroundColor: `${colour}` }}
                >
                  {packageStatus}
                </div>
              </div>

              {/* {packages[currentIndex].status === 'FORWARD' ?
                                <div className="forward-action-header">
                                    <div className="forward-action-arrow" />
                                    <div className="forward-action-inner">
                                        Forwarded
                                    </div>
                                </div>
                                : packages[currentIndex].action_status === 'REQUEST_FORWARD' ?
                                    <div className="forward-action-header">
                                        <div className="forward-action-arrow" />
                                        <div className="forward-action-inner">
                                            Forward
                                    </div>
                                    </div>
                                    : packages[currentIndex].action_status === 'REQUEST_SNAPSEND' ?
                                        <div className="scan-send-action-header">
                                            <div className="scan-send-action-arrow" />
                                            <div className="scan-send-action-inner">
                                                Scan & Send
                                    </div>
                                        </div>
                                        : packages[currentIndex].action_status === 'SNAPSEND' ?
                                            <div className="scan-send-action-header">
                                                <div className="scan-send-action-arrow" />
                                                <div className="scan-send-action-inner">
                                                    Scanned & Sent
                                        </div>
                                            </div>
                                            : packages[currentIndex].action_status === 'DESTROY' ?
                                                <div className="destroy-action-header">
                                                    <div className="destroy-action-arrow" />
                                                    <div className="destroy-action-inner">
                                                        Destroyed
                                        </div>
                                                </div>
                                                : packages[currentIndex].action_status === 'REQUEST_DESTROY' ?
                                                    <div className="destroy-action-header">
                                                        <div className="destroy-action-arrow" />
                                                        <div className="destroy-action-inner">
                                                            Destroy
                                            </div>
                                                    </div>
                                                    : packages[currentIndex].action_status === 'HOLD' ?
                                                        <div className="hold-action-header">
                                                            <div className="hold-action-arrow" />
                                                            <div className="hold-action-inner">
                                                                Held
                                            </div>
                                                        </div>
                                                        : packages[currentIndex].action_status === 'REQUEST_HOLD' ?
                                                            <div className="hold-action-header">
                                                                <div className="hold-action-arrow" />
                                                                <div className="hold-action-inner">
                                                                    Hold
                                                </div>
                                                            </div>
                                                            : ''
                            } */}
            </div>
          </div>
          <Fragment>
            <div
              style={{
                display: 'flex',
                marginLeft: 40,
                marginBottom: 25,
              }}
            >
              {packages[currentIndex] && packages[currentIndex].images && packages[currentIndex].images !== null &&
                packages[currentIndex].images.package_images &&
                packages[currentIndex].images.package_images.map((a) => (
                  <ModalImage
                    small={a}
                    large={a}
                    alt=""
                    className="small-view-snapSend-image"
                    hideDownload="false"
                    hideZoom="false"
                  />
                ))}
            </div>
            {packages[currentIndex].images !== null &&
              packages[currentIndex].images.images && (
                <div
                  style={{
                    marginLeft: 40,
                    marginBottom: 15,
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  {t('tables.scanSend')}:
                </div>
              )}
            <div
              style={{
                display: 'flex',
                marginLeft: 40,
                marginBottom: 30,
              }}
            >
              {packages[currentIndex].images !== null &&
                packages[currentIndex].images.images &&
                packages[currentIndex].images.images.map((a) => (
                  <>
                    <ModalImage
                      small={a}
                      large={a}
                      alt=""
                      className="small-view-snapSend-image"
                      hideDownload="false"
                      hideZoom="false"
                    />
                  </>
                ))}
            </div>
          </Fragment>
        </div>
      </>
    );
  }
}

ActionItemImageConatiner.propTypes = {
  // packageImage: PropTypes.string.isRequired,
  packages: PropTypes.object.isRequired,
  currentIndex: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default ActionItemImageConatiner;
