/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withRouter, Redirect, Link } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddMemberComp from './components/AddMember';
import Alert from '../../UI/Poper';
import WarningAlert from '../../../shared/components/Alert';
import Footer from "../../UI/Footer/Footer";
import BlueButton from "../../UI/BlueButton";
import TenantSelector from '../TenantSelector';
import { checkAWSSession } from '../../../util/application.utils';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

class AddMember extends Component {
  constructor(props) {
    const { building } = props;
    super(props);
    this.state = {
      name: '',
      email: '',
      notes: '',
      phone: '',
      address: '',
      office: '',
      business: '',
      businesses: [],
      alternateName: [''],
      alternateEmail: [''],
      alternatePhone: [''],
      isDesignated: false,
      isSuperDesignated: false,
      isHotdesk: false,
      loadingBus: false,
      selectedBusiness: [],
      businessError: null,
      search: '',
      selectedBuilding: building.length > 0 ? building[0] : null,
      redirect: false,
      memberCreated: false,
      memberCreating: false,
      errorCreating: null,
      phoneError: false,
      emailError: false,
      nameError: false,
      addressError: false,
      officeError: false,
      disableSubmit: true,
      warning: null,
      addingAltName: true,
      altPhoneError: [false],
      selectedTenant: null
    };
  }

  componentDidMount() {
    checkAWSSession(res => {
      if (res) {
        this.setState({
          encryptedEmail: `/onboarding?email=${res}`
        });
      }
      this.interval = setInterval(() => {
        const { buildings } = this.props;
        if (buildings.length !== 0) {
          const { tenant_name, tenant_id } = buildings[0];
          this.setState({
            selectedTenant: {
              tenant_name,
              tenant_id,
            }
          });
          clearInterval(this.interval);
        }
      }, 50);
    });
  }

  componentWillReceiveProps(props) {
    const { building } = props;
    this.setState({
      selectedBuilding: building.length > 0 ? building[0] : null,
    });
  }

  setAlternativeName = (name, index) => event => {
    const selectedState = this.state[name];
    selectedState[index] = event.target.value.trimLeft();
    this.setState({
      [name]: selectedState,
      addingAltName: !this.state.addingAltName,
    });
  };

  setAltPhone = (name, index, phone) => {
    let { altPhoneError, alternatePhone } = this.state;
    alternatePhone[index] = phone;
    if (phone.length < 3) {
      altPhoneError[index] = false;
      this.setState({ alternatePhone, altPhoneError });
    } else {
      try {
        const phoneObj = phoneUtil.parse(`+${phone}`);
        altPhoneError[index] = !phoneUtil.isValidNumber(phoneObj);
      } catch (err) {
        altPhoneError[index] = true;
      }
      this.setState({
        alternatePhone,
        altPhoneError,
        addingAltName: !this.state.addingAltName,
      });
    }
    this.setState({
      alternatePhone,
      altPhoneError,
      addingAltName: !this.state.addingAltName,
    });
  };

  handleNotes = note => this.setState({ notes: note });

  disableSubmit = () => {
    const { validateEmail, validateName, generalValidation } = this.props;
    const {
      email,
      name,
      selectedBuilding,
      selectedBusiness,
      phoneError,
      phone,
      address,
      office
    } = this.state;
    this.setState({
      emailError: email.length ? !validateEmail(email) : false,
      nameError: name.length ? !validateName(name.trim()) : false,
      addressError: address.length ? !generalValidation(address) : false,
      officeError: office.length ? !generalValidation(office) : false,
    });
    if (phone && phone.length === 0) this.setState({ phoneError: false });
    if (
      name.length > 0 &&
      email.length > 0 &&
      selectedBuilding &&
      selectedBusiness.name &&
      !phoneError &&
      validateEmail(email) &&
      validateName(name) &&
      generalValidation(address) &&
      generalValidation(office)
    ) {
      this.setState({ disableSubmit: false });
    } else this.setState({ disableSubmit: true });
  };

  handleChange = name => event => {
    let value = event.target.value;
    if (typeof value === 'string') {
      value = value.trimLeft();
    }
    if (name !== 'clearBusiness') {
      this.setState({ [name]: value, warning: null }, () =>
        this.disableSubmit(),
      );
    } else {
      this.setState({
        selectedBusiness: {},
        isDesignated: false,
        isSuperDesignated: false,
      });
    }
  };

  selectBuilding = selectedBuilding => {
    this.setState({
      selectedBuilding,
      selectedBusiness: [],
      search: '',
      businesses: [],
      businessError: null
    });
  };

  handlePhone = (name, phone) => {
    if (phone !== '') {
      if (phone.length < 8 && phone.length > 2) {
        this.setState({ phone, phoneError: true });
       } else if (phone.length < 3) {
          this.setState({ phone, phoneError: false });
      } else {
        try {
          this.closeAlert();
          const phoneObj = phoneUtil.parse(`+${phone}`); // fix issue for bug#21219
          const phoneError = !phoneUtil.isValidNumber(phoneObj);
          this.setState({ phone, phoneError }, () => this.disableSubmit());
        } catch (err) {
          console.log(err);
          this.setState({ phone, phoneError: true }, () =>
            this.disableSubmit(),
          );
        }
      }
    } else {
      this.setState({ phone, phoneError: false });
    }
  };

  selectBusiness = business => {
    const { selectedBusiness, businesses } = this.state;
    const onlyIndices = selectedBusiness.map(bus => bus.business_id);
    const selectedIndex = onlyIndices.indexOf(business.business_id);
    const busIndex = businesses.map(bus => bus.business_id);
    if (selectedIndex > -1) {
      selectedBusiness.splice(selectedIndex, 1);
      businesses.push(business);
      this.setState({
        selectedBusiness,
        businesses,
        addingAltName: !this.state.addingAltName,
      });
    } else {
      selectedBusiness.push(business);
      businesses.splice(busIndex.indexOf(business.business_id), 1);
      this.setState({
        selectedBusiness,
        businesses,
        addingAltName: !this.state.addingAltName,
      });
    }
  };

  handleSearch = () => {
    const { search, selectedBuilding } = this.state;
    if (search.trimRight().length >= 1) {
      this.setState({ businessError: null, loadingBus: true, businesses: [] });
      const { t, searchBusiness } = this.props;
      searchBusiness(search, selectedBuilding.building_id, 10, 0, 'name', true)
        .then(businesses =>
          this.setState({
            businesses,
            loadingBus: false,
            businessError:
              businesses.length === 0 ? t('members.noBusinessFound') : null,
          }),
        )
        .catch(error =>
          this.setState({ businessError: error.message, loadingBus: false }),
        );
    }
  };

  saveMember = () => {
    let {
      name,
      email,
      phone,
      office,
      address,
      notes,
      selectedBusiness,
      selectedBuilding,
      alternateName,
      alternateEmail,
      alternatePhone,
      isHotdesk,
      phoneError,
      emailError,
      nameError,
      addressError,
      officeError,
      warning,
      altPhoneError,
    } = this.state;
    let call = true;
    const { t, validateEmail, addMember, validateName } = this.props;

    const filteredAltEmail = alternateEmail.filter(
      val => val !== (null || undefined || ''),
    );

    const alternateEmailExists = filteredAltEmail.filter(
      item => item.toLowerCase() === email.toLowerCase(),
    );

    const alternateFilteredAltEmailExists = filteredAltEmail.filter(item => {
      const altData = filteredAltEmail.map(tempItem => tempItem.toLowerCase());
      return altData.indexOf(item.toLowerCase()) !== altData.lastIndexOf(item.toLowerCase());
    });

    const filteredAltNamed = alternateName.filter(
      val => val !== (null || undefined || ''),
    );

    const alternateNameExists = filteredAltNamed.filter(
      item => item.trim().toLowerCase() === name.trim().toLowerCase(),
    );

    const alternateFilteredAltNamedExists = filteredAltNamed.filter(item => {
      const altData = filteredAltNamed.map(tempItem => tempItem.toLowerCase());
      return altData.indexOf(item.toLowerCase()) !== altData.lastIndexOf(item.toLowerCase());
    });

    let filteredAltPhone = alternatePhone.filter(
      val => val !== (null || undefined || '') && val.length > 2,
    );

    filteredAltPhone = filteredAltPhone.map(val =>
      '+'.concat(val.replace(/[^\d]/g, '')),
    );

    let alternatePhoneExists = filteredAltPhone.filter(
      item => item === phone,
    );

    let alternateFilteredAltPhoneExists = filteredAltPhone.filter(
      item => filteredAltPhone.indexOf(item) !== filteredAltPhone.lastIndexOf(item),
    );

    altPhoneError.map(altPhone => {
      if (altPhone && altPhoneError) {
        call = false;
        this.setState({ warning: t('altPhoneValidation') });
        return t('altPhoneValidation');
      }
      return null;
    });
    let warningText = null;
    if (call) {
      filteredAltEmail.map(altEmail => {
        if (!validateEmail(altEmail)) {
          call = false;
          this.setState({
            warning: t('common.error.enterValidAlterEmail'),
            altEmailErr: true,
          });
        }
        return null;
      });
    }
    if (call) {
      filteredAltNamed.map(altName => {
        if (!validateName(altName)) {
          call = false;
          this.setState({
            warning: t('common.error.altNameValidation'),
            altNameErr: true,
          });
        }
        return null;
      });
    }
    if (call) {
      warningText =
        name.length === 0
          ? 'common.error.noEmptyName'
          : name.length < 1
            ? 'buildings.nameMinimumCharacter'
            : email.length === 0
              ? 'common.error.noEmptyEmail'
              : emailError
                ? 'emailValidation'
                : nameError
                  ? t('common.error.nameValidation')
                  : addressError
                    ? t('common.error.addressValidation')
                    : officeError
                      ? t('common.error.officeValidation')
                      : phone.length !== 0 && phoneError
                        ? 'phoneValidation'
                        : !selectedBuilding
                          ? 'buildingValidation4'
                          : '';
    }
    if (alternateEmailExists.length !== 0) {
      this.setState({
        warning: t('common.error.emailAndAltEmail'),
      });
    } else if (alternateFilteredAltEmailExists.length !== 0) {
      this.setState({
        warning: t('common.error.altEmailDuplicate'),
      });
    } else if (alternateNameExists.length !== 0) {
      this.setState({
        warning: t('common.error.nameAndAltname'),
      });
    } else if (alternateFilteredAltNamedExists.length !== 0) {
      this.setState({
        warning: t('common.error.altNameDuplicate'),
      });
    } else if (alternatePhoneExists.length !== 0) {
      this.setState({
        warning: t('common.error.phoneAndAltphone'),
      });
    } else if (alternateFilteredAltPhoneExists.length !== 0) {
      this.setState({
        warning: t('common.error.altPhoneDuplicate'),
      });
    } else if (
      name.length > 0 &&
      email.length > 0 &&
      selectedBuilding &&
      !phoneError &&
      !emailError &&
      !nameError &&
      !addressError &&
      !officeError &&
      !warning &&
      call
    ) {
      const filteredAltName = alternateName.filter(
        val => val !== (null || undefined || ''),
      );
      phone = '+'.concat(phone.replace(/[^\d]/g, ''));
      const member = {
        name,
        email,
        phone: phone.length > 5 ? phone : '',
        office,
        address,
        note: notes,
        alternateName: filteredAltName,
        alternateEmail: filteredAltEmail,
        alternatePhone: filteredAltPhone,
        isHotdesk,
        business: selectedBusiness,
        building: parseInt(selectedBuilding.building_id, 10),
      };
      if (notes.length === 0) delete member.note;
      this.setState({ memberCreating: true, errorCreating: null });
      addMember(member)
        .then(() =>
          this.setState({ memberCreated: true, memberCreating: false }),
        )
        .catch(err => {
          let errorMessage =
            (err.response ? err.response.data.message : err.message) ||
            this.props.t('common.error.something');
          // const errStr1 = errorMessage.indexOf('\"');
          // const errStr2 = errorMessage.lastIndexOf('\"');
          // const errStr3 = errorMessage.substr(errStr1 + 1, (errStr2 - errStr1 - 1));
          // const Str1 = errorMessage.includes('Conflict: Member') ? t('common.error.conflictMember') : "";
          // const Str2 = errorMessage.includes('already has this email address.') ? t('common.error.alreadyHasEmailAddress') : "";
          // const Str3 = `${Str1}"${errStr3}"${Str2}`;
          // console.log(errorMessage.err_code);
          // let error =
          //  ((errorMessage.err_code === 'C10') || (errorMessage.err_code === 'C12'))
          // ? 'tyb'
          // : errorMessage;

          let error = errorMessage.includes('office')
            ? t('common.error.officeNotEmpty')
            : errorMessage.includes('permanent_address')
              ? t('common.error.addressNotEmpty')
              : errorMessage.includes(
                'Conflict: Email/Alternate Email cannot be same.',
              )
                ? t('common.error.emailAndAltEmail')
                : errorMessage.includes(
                  'Conflict: Phone/Alternate Phone cannot be same.',
                )
                  ? t('common.error.phoneAndAltphone')
                  : errorMessage.includes(
                    'child "alternate_name" fails because ["alternate_name" position 1 contains a duplicate value',
                  )
                    ? t('common.error.duplicate')
                    : errorMessage.includes(
                      'child "alternate_email" fails because ["alternate_email" position 1 contains a duplicate value',
                    )
                      ? t('common.error.alternatePosition')
                      : errorMessage.includes('already has this email address')
                        ? t('common.error.ExistsEmailForMembers')
                        : errorMessage.includes(
                          'child "alternate_phone" fails because ["alternate_phone" position 1 contains a duplicate value]',
                        )
                          ? t('common.error.phoneAndAltphone')
                          : errorMessage.includes(
                            'Alternate phone contains duplicate entries',
                          )
                            ? errorMessage
                            : errorMessage.includes('Name/Alternate Name cannot be same')
                              ? t('common.error.nameAndAltname')
                              : errorMessage;
          // ? Str3
          // : errorMessage;
          this.setState({ errorCreating: error, memberCreating: false });
        });
    } else this.setState({ warning: t(warningText) });
  };

  closeAlert = () => {
    if (this.state.memberCreated) this.setState({ redirect: true });
    this.setState({ warning: null, errorCreating: null, altEmailErr: false });
  };

  addInputField = name => {
    const addedState = this.state[name];
    const { altPhoneError } = this.state;
    addedState.push('');
    if (name === 'alternatePhone') altPhoneError.push('');
    this.setState({
      [name]: addedState,
      altPhoneError,
      addingAltName: !this.state.addingAltName,
    });
  };

  removeInputField = (name, index) => {
    const removedState = this.state[name];
    const { altPhoneError } = this.state;
    removedState.splice(index, 1);
    if (name === 'alternatePhone') altPhoneError.splice(index, 1);
    this.setState({
      [name]: removedState,
      altPhoneError,
      addingAltName: !this.state.addingAltName,
    });
  };

  assignDesignated = business => {
    const { selectedBusiness } = this.state;
    const selectedIds = selectedBusiness.map(bus => bus.business_id);
    const index = selectedIds.indexOf(business.business_id);
    if (index > -1) {
      selectedBusiness[index].is_designated = !selectedBusiness[index].is_designated;
      this.setState({
        selectedBusiness,
        addingAltName: !this.state.addingAltName,
      });
    } else {
      selectedBusiness.is_designated = true;
      selectedBusiness.push(business);
      this.setState({
        selectedBusiness,
        addingAltName: !this.state.addingAltName,
      });
    }
  };

  assignSuperDesignated = business => {
    const { selectedBusiness } = this.state;
    const selectedIds = selectedBusiness.map(bus => bus.business_id);
    const index = selectedIds.indexOf(business.business_id);
    if (index > -1) {
      selectedBusiness[index].is_super_designated = !selectedBusiness[index].is_super_designated;
      this.setState({
        selectedBusiness,
        addingAltName: !this.state.addingAltName,
      });
    } else {
      selectedBusiness.is_super_designated = true;
      selectedBusiness.push(business);
      this.setState({
        selectedBusiness,
        addingAltName: !this.state.addingAltName,
      });
    }
  };

  emptyBusinesses = () => {
    let { selectedBusiness, businesses } = this.state;
    businesses = [...businesses, ...selectedBusiness];
    this.setState({ selectedBusiness: [], businesses });
  };

  handleTenantChange = e => {
    const value = JSON.parse(e.target.value);
    this.setState({
      selectedTenant: {
        tenant_name: value.tenant_name,
        tenant_id: value.tenant_id,
      }
    });
  }

  render() {
    const {
      redirect,
      errorCreating,
      memberCreated,
      warning,
      altEmailErr,
      selectedTenant,
      encryptedEmail
    } = this.state;
    if (encryptedEmail) {
      this.props.history.replace(encryptedEmail);
    }
    const { t, validateEmail, building, buildings, userRole, validateName } = this.props;
    const tenants = buildings.map(eachTenant => {
      const { tenant_name, contact_email, tenant_id } = eachTenant;
      return {
        contact_email,
        tenant_id,
        tenant_name
      };
    });
    if (redirect) return <Redirect to="/members/all_members" />;
    return (
      <Fragment>
        <div>
          <Grid container className="container min-width-100p">
            {altEmailErr && (
              <Alert
                open={altEmailErr}
                variant="error"
                title="Stop!"
                btn="Danger"
                message={t('common.error.enterValidAlterEmail')}
                onClose={this.closeAlert}
              />
            )}
            {errorCreating && (
              <Alert
                open={errorCreating}
                variant="error"
                title="Stop!"
                btn="Danger"
                message={errorCreating}
                onClose={this.closeAlert}
              />
            )}
            {memberCreated && (
              <Alert
                open={memberCreated}
                variant="success"
                title="Success!"
                btn="success"
                message={t('members.created')}
                onClose={this.closeAlert}
              />
            )}
            <Grid item sm={12} md={12}>
              <div className="space-between-with-block">
                <div>
                  <div style={{ display: 'flex' }}>
                    <h3 style={{ marginRight: 10, width: 140 }} className="page-title">{t('common.addIndividual')}</h3>
                    {
                      (userRole === 'super_admin' && building.length === 0) &&
                      <TenantSelector
                        t={t}
                        tenants={tenants}
                        selectedTenant={selectedTenant}
                        handleOnchange={this.handleTenantChange}
                      />
                    }
                  </div>
                  <h3 className="page-subhead subhead">
                    <Link to="/members/all_members">{t('members.members')}</Link>
                    <ArrowRightIcon />
                    {t('common.addIndividual')}
                  </h3>
                </div>
                <BlueButton
                  width={180}
                  disabled={
                    !this.state.selectedBuilding ||
                    this.state.memberCreating ||
                    this.state.name.length === 0 ||
                    this.state.emailError ||
                    this.state.nameError ||
                    this.state.addressError ||
                    this.state.officeError ||
                    this.state.email.length === 0 ||
                    this.state.phoneError ||
                    this.state.alternateEmail.map(value => value.length !== 0 && !validateEmail(value)).indexOf(true) > -1 ||
                    this.state.alternateName.map(value => value.length !== 0 && !validateName(value)).indexOf(true) > -1 ||
                    this.state.altPhoneError.indexOf(true) > -1
                  }
                  loading={this.state.memberCreating}
                  onClick={this.saveMember}
                >
                  {t('common.saveMember')}
                </BlueButton>
              </div>
              {warning && (
                <WarningAlert
                  variant="error"
                  onClose={this.closeAlert}
                  message={warning}
                  open={warning}
                />
              )}
            </Grid>
            <AddMemberComp
              {...this.state}
              {...this.props}
              selectedTenant={selectedTenant}
              handleChange={this.handleChange}
              selectBusiness={this.selectBusiness}
              handleSearch={this.handleSearch}
              handlePhone={this.handlePhone}
              addInputField={this.addInputField}
              emptyBusinesses={this.emptyBusinesses}
              setAlternativeName={this.setAlternativeName}
              setAltPhone={this.setAltPhone}
              removeInputField={this.removeInputField}
              assignDesignated={this.assignDesignated}
              assignSuperDesignated={this.assignSuperDesignated}
              saveMember={this.saveMember}
              selectItem={item => this.selectBuilding(item)}
              handleNotes={this.handleNotes}
            />
          </Grid>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default connect(state => ({
  buildings: state.allBuildings,
  building: state.locationFilter,
  userRole: state.userRole,
}))(withRouter(AddMember));
