/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
//  import Skeleton from 'react-loading-skeleton';
import NoImage from '../../../../../assets/images/no-image.jpg';
import ActionItemImageConatiner from './components/ActionItemImageContainer';
// import { getPackageStatus } from '../../../../../util/utility.functions';


class ActionItemPackageImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const {
      style,
      t,
      packages,
      currentIndex,
      
    } = this.props;

    let signatureImage = 'noImage';
    if (packages[currentIndex].status === 'PICKED') {
      const imageLik = packages[currentIndex].package_image.split('.png');
      signatureImage = imageLik.length === 2 ? imageLik[0].concat('_PICKED.png') : 'noImage';
    }
    
    return (
      <>
        <ActionItemImageConatiner
          {...this.props}
          {...this.state}
          t={t}
          style={style}
        />
        {/* {packageImage === '' ? (
          <div style={style.package_img_height}>
            <Skeleton width={251} />
          </div>
        ) : (
            <ActionItemImageConatiner
              {...this.props}
              {...this.state}
              t={t}
              style={style}
            />
          )} */}
        {signatureImage !== 'noImage' && (
            <div style={{ display: 'block', width: '100%' }} >
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  width: '100%'
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    width: '85%'
                  }}
                >
                  <img
                    src={signatureImage}
                    alt="Signature"
                    className="just-paper package-image"
                    id={`signImage-${packages[currentIndex]}`}
                    onError={() => {
                      const elem = document.getElementById(`signImage-${packages[currentIndex]}`);
                      elem.style.display = 'none';
                      elem.src = NoImage;
                      return elem;
                    }}
                  />
                </div>
              </div>
            </div>
         ) }
      </>
    );
  }
}

ActionItemPackageImage.propTypes = {
  style: PropTypes.object.isRequired,
  //  packageImage: PropTypes.string.isRequired,
  signature: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default ActionItemPackageImage;
