import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import { withWidth } from '@material-ui/core';

// const icon = `${process.env.PUBLIC_URL}/img/burger.svg`;

class TopbarSidebarButton extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    width: PropTypes.string.isRequired,
  };

  render() {
    const {
      changeMobileSidebarVisibility,
      changeSidebarVisibility,
      width,
    } = this.props;

    return (
      <div style={width === 'xs' ? { marginLeft: 7 } : {}}>
        <button
          className="topbar__button topbar__button--desktop"
          onClick={changeSidebarVisibility}
        >
          <MenuIcon />
        </button>
        <button
          className="topbar__button topbar__button--mobile"
          onClick={changeMobileSidebarVisibility}
        >
          <MenuIcon />
        </button>
      </div>
    );
  }
}

export default withWidth()(TopbarSidebarButton);
