import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from './Styled';

function ScanLimitReached(props) {
  const { userRole, t, buildings } = props;
  const { billing_method } = buildings;
  const isStripeCustomer = billing_method && billing_method.toLowerCase() === 'stripe';

  const forStripeCustomers = () => {
    let message = '';
    if (userRole === 'admin') {
      message =
        'It looks like you have exhausted the monthly scan limit associated with your plan. You can either upgrade your plan to meet your scanning needs or wait till the limit resets at the end of the month.';
    } else {
      message =
        'It looks like you have exhausted the monthly scan limit associated with your plan. You can either upgrade your plan to meet your scanning needs or wait till the limit resets at the end of the month. Please contact your admin.';
    }
    return (
      <>
        <h3 className="pm-heading">
          {t('paymentModals.upgradePlan.headingNormal')}{' '}
          <span className="pm-heading-red">
            {t('paymentModals.upgradePlan.headingColored')}
          </span>
        </h3>
        <p className="pm-description">{message}</p>
        {userRole === 'admin' ? (
          <div className="pm-button-section">
            <Button
              className="pm-cp-upgrade-btn"
              onClick={() => {
                props.onClose(props.history.push('/manage-plan'));
              }}
            >
              {t('paymentModals.upgradePlan.upgradeBtn')}
            </Button>
            <Button className="pm-cp-downgrade-btn" onClick={closeModal}>
              {t('common.continue')}
            </Button>
          </div>
          ) : (
            <div className="pm-single-button-section">
              <Button
                className="pm-cp-upgrade-btn"
                onClick={closeModal}
              >
                {t('paymentModals.paymentSuccessful.button')}
              </Button>
            </div>
          )}
      </>
    );
  };


  const forNonStripeCustomers = () => {
    let message = '';
    if (userRole === 'admin') {
      message =
        'It looks like you have exhausted the scan limit associated with your account. You can upgrade your plan to meet your scanning needs or wait till your scan limit resets.';
    } else {
      message =
        'It looks like you have exhausted the scan limit associated with your account. You can contact your admin to upgrade your plan to meet your scanning needs or wait till your scan limit resets.';
    }
    return (
      <>
        <h3 className="pm-heading">
          {t('paymentModals.upgradePlan.headingNormal')}{' '}
          <span className="pm-heading-red">
            {t('paymentModals.upgradePlan.headingColored')}
          </span>
        </h3>
        <p className="pm-description">{message}</p>
        {userRole === 'admin' ? (
          <div className="pm-button-section">
            <Button
              className="pm-cp-upgrade-btn"
              onClick={() => {
                props.onClose(props.history.push('/manage-plan'));
              }}
            >
              {t('paymentModals.upgradePlan.upgradeBtn')}
            </Button>
            <Button className="pm-cp-downgrade-btn" onClick={closeModal}>
              {t('common.continue')}
            </Button>
          </div>
          ) : (
            <div className="pm-single-button-section">
              <Button
                className="pm-cp-upgrade-btn"
                onClick={closeModal}
              >
                {t('paymentModals.paymentSuccessful.button')}
              </Button>
            </div>
          )}
      </>
    );
  };

  const closeModal = () => {
    props.onClose(null);
  };

  return (
    <div>
      <Dialog keepMounted maxWidth="sm" onClose={closeModal} open={props.open}>
        <DialogContent className="pm-modal-container">
          {isStripeCustomer ? forStripeCustomers() : forNonStripeCustomers()}
        </DialogContent>
      </Dialog>
    </div>
  );
}

ScanLimitReached.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  buildings: PropTypes.array.isRequired,
  history: PropTypes.node.isRequired,
};

export default withRouter(ScanLimitReached);
