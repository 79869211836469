/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  CircularProgress,
  Grid,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import Dropzone from 'react-dropzone';
import { Margin } from "../../../UI/ResponsiveRelated";
import WarningAlert from "../../../../shared/components/Alert";
import {
  CustomTextField,
  CustomInputLabel,
  CustomInput,
} from "../../../UI/Input";
import newuser from '../../../../assets/images/new-user.svg';

const AssociatesIconInactive = require('../../../Layout/sidebar/AssociatesIconInactive.png');

function AddAssociate(props) {
  const [fileSizeWarning, setValue] = React.useState(null);
  const {
    t,
    associateName,
    associateEmail,
    handleChange,
    associateEmailError,
    associateNameError,
    building,
    roles,
    mailrooms,
    loadingRoles,
    rolesError,
    selectedRole,
    selectedMailroom,
    createAssociateError,
    toggle,
    notitle,
    picture,
    // loadimage,
  } = props;
  const inputStyle = { fontSize: 13, color: "#000" };
  const labelStyle = { fontSize: 13 };
  const FormHelperStyle = { color: "#FF1E59" };

  // const loadingimage = async accepted => {
  //   if (accepted[0].size > 1000000) {
  //     setValue(t('common.fileLargerThan1Mb'));
  //   } else {
  //     const reader = new window.FileReader();
  //     reader.readAsDataURL(accepted[0]);
  //     reader.onloadend = () => {
  //       loadimage(reader.result);
  //     };
  //   }
  // };

  return (
    <React.Fragment>
      <div className="card__title space-between">
        {" "}
        <div className="flex card__title">
          <img style={{ height: 20, width: 20 }} src={AssociatesIconInactive} alt="" />
          <h5 className="bold-text">{t("associates.information")}</h5>
        </div>
        {notitle && (
          <IconButton
            style={{ bottom: 7 }}
            aria-label="Close"
            onClick={toggle}
          >
            <Clear className="font-size-22" />
          </IconButton>
        )}
      </div>
      {createAssociateError && <WarningAlert variant="error" message={createAssociateError} open />}
      {fileSizeWarning && <WarningAlert variant="error" message={fileSizeWarning} open onClose={() => setValue(null)} />}
      <Grid container direction="row" justify="space-between">
        <Grid item justify="center" className="flex" xs={12} sm={12} md={12}>
          <FormControl>
            <Dropzone
              disabled
              className="height-width-80px"
              accept="image/png, image/jpg, image/jpeg"
              multiple={false}
              // onDrop={accepted => loadingimage(accepted)}
            >
              <Avatar
                src={picture || newuser}
                className={`height-width-80px margin-bottom-20 ${!picture && 'padding-10px'} ${picture && 'paper'}`}
              />
            </Dropzone>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
          <CustomTextField
            style={{ marginBottom: window.screen.width < 800 ? 15 : 0 }}
            required
            fullWidth
            label={t("tables.name")}
            value={associateName}
            onChange={handleChange("associateName")}
            InputProps={{
              style: inputStyle,
            }}
            helperText={associateNameError ? t('common.error.nameValidation') : ""}
            FormHelperTextProps={{ style: { color: '#FF1E59' } }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
          <CustomTextField
            style={{
            marginBottom: window.screen.width < 800 ? 15 : 0,
          }}
            required
            fullWidth
            label={t("tables.email")}
            value={associateEmail}
            onChange={handleChange("associateEmail")}
            InputProps={{ style: inputStyle }}
            helperText={associateEmailError ? t("emailValidation") : ""}
            FormHelperTextProps={{ style: FormHelperStyle }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={5} style={{ marginTop: 19 }}>
          {loadingRoles ? (
            <Margin top={20}>
              <CircularProgress size={15} className="blue" />{" "}
              {t("tables.loadingRoles")}
            </Margin>
        ) : (
          rolesError || (
            <FormControl fullWidth>
              <CustomInputLabel style={labelStyle} shrink>
                {t("tables.role")}
              </CustomInputLabel>
              <Select
                value={selectedRole}
                onChange={handleChange("selectedRole")}
                input={
                  <CustomInput required name="selectedRole" style={inputStyle} />
                }
              >
                {roles.map(role => (
                  <MenuItem value={role.value}>{role.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        )}
        </Grid>
        <Grid item xs={12} md={12} lg={5} style={{ marginTop: 19 }}>
          {building && building.name &&
            selectedRole.toLowerCase() === "operator" && (
              <FormControl required fullWidth>
                <CustomInputLabel htmlFor="select-multiple-checkbox">
                  {t("tables.mailrooms")}
                </CustomInputLabel>
                <Select
                  multiple
                  displayEmpty
                  value={selectedMailroom}
                  onChange={handleChange("selectedMailroom")}
                  input={<CustomInput style={inputStyle} />}
                  renderValue={selected => selected.map(item => item.name).join(', ')}
                >
                  {mailrooms.map(mailroom => (
                    <MenuItem className="padding-0" key={mailroom} value={mailroom}>
                      <Checkbox className="blue padding-5px" checked={selectedMailroom.map(item => item.mailroom_id).indexOf(mailroom.mailroom_id) > -1} />
                      <ListItemText primary={mailroom.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

AddAssociate.propTypes = {
  t: PropTypes.func.isRequired,
  picture: PropTypes.string.isRequired,
  associateName: PropTypes.string.isRequired,
  associateEmail: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  building: PropTypes.node.isRequired,
  associateEmailError: PropTypes.string.isRequired,
  associateNameError: PropTypes.string.isRequired,
  roles: PropTypes.node.isRequired,
  mailrooms: PropTypes.node.isRequired,
  loadingRoles: PropTypes.bool.isRequired,
  rolesError: PropTypes.string.isRequired,
  selectedRole: PropTypes.number.isRequired,
  selectedMailroom: PropTypes.node.isRequired,
  createAssociateError: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  // loadimage: PropTypes.func.isRequired,
  notitle: PropTypes.bool.isRequired,
};

export default AddAssociate;
