import React from 'react';
import PropTypes from 'prop-types';

const Margin = ({
 children, right, left, top, bottom, width, style,
}) => (
  <div style={{
    marginRight: right,
    marginLeft: left,
    marginTop: top,
    marginBottom: bottom,
    width,
    ...style,
  }}
  >
    {children}
  </div>
);

Margin.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  right: PropTypes.number,
  left: PropTypes.number,
  top: PropTypes.number,
  bottom: PropTypes.number,
  width: PropTypes.string,
  style: PropTypes.node,
};

Margin.defaultProps = {
  right: 0,
  left: 0,
  top: 0,
  bottom: 0,
  width: 'inherit',
  style: {},
};

export default Margin;
