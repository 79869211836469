/* eslint-disable */

import { Divider, Grid } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getPackageHistoryMessage } from '../../../../util/application.utils';

class HistoryItemSingle extends React.Component {
  state = {};
  render() {
    const { data, activity_type, pickup_info, t } = this.props;

    const dateCreatedAt = `${moment(data.created_at).format(
      'MMM Do, YYYY'
    )} @ ${moment(data.created_at).format('hh:mm A')} `;

    return (
      <>
        <Grid
          container
          justify="space-between"
          style={{
            padding: '16px',
          }}
        >
          <Grid xs={12} sm={6} style={{ fontSize: '14px' }}>
            {dateCreatedAt}
          </Grid>
          <Grid
            xs={12}
            sm={6}
            style={{ fontSize: '14px', opacity: '0.6', textAlign: 'right' }}
          >
            {getPackageHistoryMessage(
              data.status,
              activity_type,
              t,
              pickup_info
            )}
          </Grid>
        </Grid>
        <Divider />
      </>
    );
  }
}

HistoryItemSingle.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HistoryItemSingle;
