import axios from 'axios';
import moment from 'moment';
import Browser from './getBrowserInfo';
import { ENV } from '../env';
import { v as version } from '../util/version';

/*
Old hooks:
qa: https://hooks.slack.com/services/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
uat: https://hooks.slack.com/services/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
wework: https://hooks.slack.com/services/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
dev_cn: https://hooks.slack.com/services/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
qa_cn: https://hooks.slack.com/services/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
uat_cn: https://hooks.slack.com/services/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
wework_cn: https://hooks.slack.com/services/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
demo: https://hooks.slack.com/services/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
px_business: https://hooks.slack.com/services/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
*/
const slackAPI =
  ENV === 'dev'
    ? 'https://hooks.slack.com/services/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'
    : ENV === 'qa'
      ? 'https://hooks.slack.com/services/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'
      : ENV === 'uat'
        ? 'https://hooks.slack.com/services/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'
        : ENV === 'wework'
          ? 'https://hooks.slack.com/services/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'
                    : ENV === 'pxbusiness' &&
                    'https://hooks.slack.com/services/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';

const informSlackDBCodeAPI =
  'https://hooks.slack.com/services/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';

const informSlackDBNetworkAPI = 
  'https://hooks.slack.com/services/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';

const informSlack = (err, parrentUrl = null) => {
  const message = err.response
    ? err.response.data
      ? err.response.data.message && err.response.data.message.message
        ? err.response.data.message.message
        : err.response.data.message
      : err.response.message
    : err.message
      ? err.message
      : err;
  const statusCode = err.response
    ? err.response.status
      ? err.response.status
      : err.response.data
        ? err.response.data.status
        : 'Not Available'
    : 'Not Available';
  const endpoint = (err.config && err.config.url) || parrentUrl || 'Not available';
  let now = moment().unix();
  let axiosConfig = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };
  const username = localStorage.px_user;
  if (err.config && err.config.headers) {
    delete err.config.headers.Authorization;
  }
  
  axios
    .post(
      `${slackAPI}`,
      {
        text: message,
        attachments: [
          {
            color: '#439FE0',
            fields: [
              {
                title: 'Browser Name',
                value: Browser.name,
                short: true,
                color: '',
              },
              {
                title: 'Browser Version',
                value: Browser.version,
                short: true,
              },
              {
                title: 'Dashboard Version',
                value: version,
                short: true,
              },
              {
                title: 'Error Message',
                value: message,
                short: true,
              },
              {
                title: 'Error Object',
                value: endpoint && endpoint.includes('auth') ? 'Confidential' : String(err),
                short: true,
              },
              {
                title: 'Endpoint',
                value: endpoint,
                short: true,
              },
              {
                title: 'Username',
                value: username,
                short: true,
              },
              {
                title: 'Status code',
                value: statusCode,
                short: true,
              },
            ],
            ts: now,
          },
        ],
      },

      axiosConfig,
    );
};

const informSlackDBCodeErrs = (err, fileName, codeLine) => {
  let now = moment().unix();
  let axiosConfig = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };
  const username = localStorage.px_user;
  if (err.config && err.config.headers) {
    delete err.config.headers.Authorization;
  }
  
  axios
    .post(
      `${informSlackDBCodeAPI}`,
      {
        text: err,
        attachments: [
          {
            color: '#439FE0',
            fields: [
              {
                title: 'Environment',
                value: ENV,
                short: true,
                color: '',
              },
              {
                title: 'Browser Name',
                value: Browser.name,
                short: true,
                color: '',
              },
              {
                title: 'Browser Version',
                value: Browser.version,
                short: true,
              },
              {
                title: 'Dashboard Version',
                value: version,
                short: true,
              },
              {
                title: 'Error Object',
                value: String(err),
                short: true,
              },
              {
                title: 'File Name',
                value: fileName,
                short: true,
              },
              {
                title: 'Code Line',
                value: codeLine,
                short: true,
              },
              {
                title: 'Username',
                value: username,
                short: true,
              },
            ],
            ts: now,
          },
        ],
      },

      axiosConfig,
    );
};

const informSlackDBNetworkAPIErrs = (err, parrentUrl = null) => {
  const message = err.response
    ? err.response.data
      ? err.response.data.message && err.response.data.message.message
        ? err.response.data.message.message
        : err.response.data.message
      : err.response.message
    : err.message
      ? err.message
      : err;
  const statusCode = err.response
    ? err.response.status
      ? err.response.status
      : err.response.data
        ? err.response.data.status
        : 'Not Available'
    : 'Not Available';
  const endpoint = (err.config && err.config.url) || parrentUrl || 'Not available';
  let now = moment().unix();
  let axiosConfig = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };
  const username = localStorage.px_user;
  if (err.config && err.config.headers) {
    delete err.config.headers.Authorization;
  }
  
  axios
    .post(
      `${informSlackDBNetworkAPI}`,
      {
        text: message,
        attachments: [
          {
            color: '#439FE0',
            fields: [
              {
                title: 'Browser Name',
                value: Browser.name,
                short: true,
                color: '',
              },
              {
                title: 'Browser Version',
                value: Browser.version,
                short: true,
              },
              {
                title: 'Dashboard Version',
                value: version,
                short: true,
              },
              {
                title: 'Error Message',
                value: message,
                short: true,
              },
              {
                title: 'Error Object',
                value: endpoint && endpoint.includes('auth') ? 'Confidential' : String(err),
                short: true,
              },
              {
                title: 'Endpoint',
                value: endpoint,
                short: true,
              },
              {
                title: 'Username',
                value: username,
                short: true,
              },
              {
                title: 'Status code',
                value: statusCode,
                short: true,
              },
            ],
            ts: now,
          },
        ],
      },

      axiosConfig,
    );
};
export { informSlackDBNetworkAPIErrs, informSlackDBCodeErrs };
export default informSlack;
