/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  withWidth,
  Grid,
  Paper,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { SpaceBetween } from '../../../UI/ResponsiveRelated';
import Button from '../../../UI/BlueButton';
import Alert from '../../../UI/Poper';
import { CustomInputLabel, CustomInput } from '../../../UI/Input';

class AccountSettings extends Component {
  state = {
    password: '',
    showPassword: false,
    passwordError: null,
    newPassword: '',
    showNewPass: false,
    newPassError: '',
    confirmPassword: '',
    showConfirmPass: false,
    confirmPassError: false,
    changingPass: false,
    passChanged: false,
    error: null,
  };

  onClose = () => {
    this.setState({ error: null, passChanged: false });
  };

  handleChange = (state) => (e) => {
    const { t, resetWarning } = this.props;
    resetWarning();
    this.setState({ [state]: e.target.value }, () => {
      let regex = /^(?=.{8,32}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).*/;
      const { password, newPassword, confirmPassword } = this.state;
      if (password.length === 0) {
        this.setState({
          passwordError: t('changePassword.currentPassIsRequired'),
        });
      } else this.setState({ passwordError: null });
      if (newPassword.length > 0) {
        this.setState({ newPassError: !regex.test(newPassword) });
      } else this.setState({ newPassError: null });
      if (confirmPassword.length > 0 && confirmPassword !== newPassword) {
        this.setState({
          confirmPassError: t('common.error.passwordValidation2'),
        });
      } else this.setState({ confirmPassError: null });
    });
  };

  showPassword = (state) => () => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  changePassword = () => {
    const {
      password,
      newPassword,
      confirmPassword,
      passwordError,
      newPassError,
      confirmPassError,
    } = this.state;
    const { t, changePassword } = this.props;
    if (passwordError || newPassError || confirmPassError) {
      this.setState({ error: t('common.error.removeWarnings') });
    } else if (
      password.length === 0 ||
      newPassword.length === 0 ||
      confirmPassword.length === 0
    ) {
      this.setState({ error: t('changePassword.allFieldsMustBeFilled') });
    } else if (password === newPassword && newPassword === confirmPassword) {
      this.setState({ error: t('common.error.oldAndNewPassword') });
    } else {
      this.setState({ changingPass: true, error: null });
      changePassword(password, newPassword)
        .then(() =>
          this.setState({
            changingPass: false,
            passChanged: true,
            newPassword: '',
            confirmPassword: '',
            password: '',
          })
        )
        .catch(() =>
          this.setState({
            changingPass: false,
            error: t('common.error.existingPasswordError'),
          })
        );
    }
  };

  render() {
    const { t } = this.props;
    const {
      password,
      showPassword,
      passwordError,
      newPassword,
      showNewPass,
      newPassError,
      confirmPassword,
      showConfirmPass,
      confirmPassError,
      changingPass,
      passChanged,
      error,
    } = this.state;

    return (
      <Grid item md={12} lg={6} xl={6}>
        {(error || passChanged) && (
          <Alert
            variant={error ? 'error' : 'success'}
            open
            message={error || t('signin.changeSuccessMessage1')}
            onClose={this.onClose}
          />
        )}
        <Paper className="just-paper margin-bottom-15 margin-right-10px account-settings-card">
          <div className="account-settings-card-header">
            {t('changePassword.changePassword')}
          </div>
          <div className="flex-container-account-settings">
            <div>
            <div className="font-size-12 red font-weight-bold">
              {t('common.passwordPolicy')}
            </div>
            <Grid container direction="row" justify="space-between">
              <Grid item md={12} xs={12}>
                <SpaceBetween>
                  <FormControl fullWidth>
                    <CustomInputLabel htmlFor="adornment-password">
                      {t('changePassword.currentPassword')}
                    </CustomInputLabel>
                    <CustomInput
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={this.handleChange('password')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={this.showPassword('showPassword')}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {passwordError && (
                      <FormHelperText className="red">
                        {t('changePassword.currentPassIsRequired')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </SpaceBetween>
              </Grid>
              <Grid item md={12} xs={12} style={{ marginTop: 20 }}>
                <SpaceBetween>
                  <FormControl fullWidth>
                    <CustomInputLabel htmlFor="adornment-password">
                      {t('changePassword.newPassword')}
                    </CustomInputLabel>
                    <CustomInput
                      type={showNewPass ? 'text' : 'password'}
                      value={newPassword}
                      onChange={this.handleChange('newPassword')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={this.showPassword('showNewPass')}
                          >
                            {showNewPass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {newPassError && (
                      <FormHelperText className="red">
                        {t('passwordValidation')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </SpaceBetween>
              </Grid>
              <Grid item md={12} xs={12} style={{ marginTop: 20 }}>
                <SpaceBetween>
                  <FormControl fullWidth>
                    <CustomInputLabel htmlFor="adornment-password">
                      {t('changePassword.confirmPassword')}
                    </CustomInputLabel>
                    <CustomInput
                      type={showConfirmPass ? 'text' : 'password'}
                      value={confirmPassword}
                      onChange={this.handleChange('confirmPassword')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={this.showPassword('showConfirmPass')}
                          >
                            {showConfirmPass ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {confirmPassError && (
                      <FormHelperText className="red">
                        {t('passwordValidation2')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </SpaceBetween>
              </Grid>
            </Grid>
            </div>
            <div className="space-between-width-center align-center margin-top-24px">
              <div />
              <Button
                width={180}
                height={45}
                disabled={
                  changingPass ||
                  password.length === 0 ||
                  newPassword.length === 0 ||
                  confirmPassword.length === 0 ||
                  passwordError ||
                  newPassError ||
                  confirmPassError
                }
                loading={changingPass}
                onClick={() => this.changePassword()}
              >
                {t('changePassword.changePassword')}
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>
    );
  }
}

AccountSettings.propTypes = {
  t: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  resetWarning: PropTypes.func.isRequired,
};

export default withWidth()(AccountSettings);
