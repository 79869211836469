/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React, { Component, Fragment } from 'react';
import { Auth } from 'aws-amplify';
import Skeleton from 'react-loading-skeleton';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { CircularProgress, withWidth } from '@material-ui/core';
import BuildingDetailComp from './components/BuildingDetail';
import CreateMailroom from './components/CreateMailroom';
import CreateAssociate from './components/CreateAssociate';
import WarningAlert from '../../../shared/components/Alert';
import BlueButton from '../../UI/BlueButton';
import { AlignCenter, VerticallyCenter } from '../../UI/ResponsiveRelated';
import Alert from '../../UI/Poper';
import { truncateLabel } from '../../../util/utility.functions';
import NotAuthorized from '../../UI/403/403';
import { setBuilding } from '../../../redux/actions/locationsFilterActions';
import { setBuildings } from '../../../redux/actions/allBuildingsActions';
import { setRole } from '../../../redux/actions/userRoleActions';
import {
  createAssociate,
  getBuilding,
  getBuildingUsers,
  getBuildingMailrooms,
  getPrefs,
  updatePreferences,
  updateBuilding,
  getProfile,
  getBuildingsEmailTemplates,
  getCountryStateCities,
  getRoles,
  createMailroom,
  deleteUserAssociate,
  getLabelsByBuildingId,
  updateNotificationPrefs,
  addLables,
  deleteLabels,
} from '../../../server';
import ConfirmationModal from './components/ConfirmationModal';
import Footer from '../../UI/Footer/Footer';

const env = process.env.REACT_APP_ENV;

class BuildingDetail extends Component {
  state = {
    loading: true,
    building: {},
    error: null,
    editing: true,
    updating: false,
    name: '',
    code: '',
    address: '',
    city: '',
    state: '',
    country: '',
    timeZone: '',
    hubspot_building_id: null,
    users: [],
    usersLoading: true,
    usersError: null,
    mailrooms: [],
    mailroomsLoading: true,
    mailroomsError: null,
    preferences: {},
    reminderPerPkg: 1,
    timeBetweenReminders: 24,
    mondaySwitch: false,
    tuesdaySwitch: false,
    wednesdaySwitch: false,
    thursdaySwitch: false,
    fridaySwitch: false,
    mondayTime: '12:00',
    tuesdayTime: '12:00',
    wednesdayTime: '12:00',
    thursdayTime: '12:00',
    fridayTime: '12:00',
    prefsLoading: true,
    prefsError: null,
    businessUpdated: false,
    mailroomForm: false,
    mailroomName: '',
    disableMailroomBtn: true,
    creatingMailroom: false,
    associateForm: false,
    roles: [],
    loadingRoles: true,
    rolesError: null,
    associateName: '',
    associateEmail: '',
    associateEmailError: '',
    disableAssociateBtn: true,
    creatingAssociate: false,
    createAssociateError: null,
    associateCreated: false,
    selectedRole: '',
    selectedMailroom: [],
    buildingUpdating: false,
    buildingUpdated: false,
    emails: [],
    emailError: '',
    nameError: false,
    addressError: false,
    associateNameError: false,
    loadingEmails: true,
    mondayErr: false,
    tuesdayErr: false,
    wednesdayErr: false,
    thursdayErr: false,
    fridayErr: false,
    loadingLocations: true,
    loadingStates: false,
    loadingCities: false,
    errorLocations: null,
    errorStates: null,
    errorCities: null,
    locations: [],
    states: [],
    cities: [],
    roleDropdown: false,
    roleDropdownAnchor: null,

    openConfirmation: false,
    selectedAssociateNameToDelete: '',
    selectedAssociateIdToDelete: null,
    successDeletingAssociate: false,
    errorDeletingAssociate: false,
    deleteMemberLoader: false,
    csv_upload: { label: this.props.t('email.yes'), value: true },
    reply_to: '',
    replyToErrors: false,
    preferenceCTA: [],
    shippingLabels: [],
    prefJson: null,
    loadingLbl: true,
    emailPref: [],
    shiplabels: {
      HOLD: [],
      SNAPSEND: [],
      DESTROY: [],
      FORWARD: [],
    },
    reload: false,
  };

  componentDidMount() {
    this.getData();
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidUpdate(props) {
    const { match, t } = props;
    const { csv_upload } = this.state;
    if (match.params.id !== this.props.match.params.id) {
      this.getData(this.props);
    }
    if (props.userRole !== this.props.userRole) {
      this.getData();
    }
    if (props.lng !== this.props.lng) {
      this.setState({
        timeBetweenReminders: {
          value: this.state.timeBetweenReminders.value,
          label: `${this.state.timeBetweenReminders.value} ${t(
            'common.hours'
          )}`,
        },
        csv_upload: {
          label:
            csv_upload.value === true
              ? this.props.t('email.yes')
              : this.props.t('associates.no'),
          value: csv_upload.value,
        },
      });
    }
  }

  onCloseAddAssociate = () => {
    this.setState({
      associateForm: false,
      createAssociate: false,
      associateName: '',
      associateEmail: '',
      selectedMailroom: [],
      disableAssociateBtn: true,
      createAssociateError: false,
    });
  };

  getData = () => {
    const { t, match, userRole } = this.props;
    this.setState({
      loading: true,
      building: {},
      error: null,
      prefsLoading: true,
      preferences: {},
      prefsError: null,
      loadingEmails: true,
      emails: [],
      emailError: null,
      nameError: null,
      addressError: null,
    });
    if (userRole && userRole !== 'mailroom_supervisor') {
      getBuildingsEmailTemplates(match.params.id, 'topic', true)
        .then((res) => {
          this.setState({
            emails: res,
            loadingEmails: false,
            emailError: res.data === '' ? '404' : '',
          });
        })
        .catch((error) => {
          if (env && env.includes('cn')) {
            this.setState({
              emailError: error.message.message,
              loadingEmails: false,
            });
          } else {
            this.setState({
              emailError: error.message,
              loadingEmails: false,
            });
          }
        });
      getBuilding(match.params.id)
        .then((building) => {
          this.setState({ tenant_id: building.tenant_id });
          this.getShippingMethodLabels(building.tenant_id);
          getCountryStateCities()
            .then((locations) => {
              let countryIndex = null;
              let states = [];
              let cities = [];
              locations.filter((c) => {
                if (c.name === building.country) {
                  countryIndex = c;
                  this.setState({ loadingStates: true });
                  getCountryStateCities(c.country_id)
                    .then((allStates) => {
                      states = allStates;
                      this.setState({ states, loadingStates: false });
                      states.filter((s) => {
                        if (s.name === building.state) {
                          this.setState({ state: s, loadingCities: true });
                          getCountryStateCities(null, s.state_id)
                            .then((allCities) => {
                              cities = allCities;
                              this.setState({ cities, loadingCities: false });
                              allCities.filter((city) => {
                                if (building.city === city.name) {
                                  this.setState({ city });
                                }
                                return city;
                              });
                            })
                            .catch((err) =>
                              this.setState({
                                loadingCities: false,
                                errorCities: err.response
                                  ? err.response.data.message
                                  : t('common.error.networkError'),
                              })
                            );
                        }
                        return s;
                      });
                    })
                    .catch((err) =>
                      this.setState({
                        loadingStates: false,
                        errorStates: err.response
                          ? err.response.data.message
                          : t('common.error.networkError'),
                      })
                    );
                }
                return c;
              });
              this.setState({
                locations:
                  env && env.includes('cn')
                    ? locations.filter((c) => {
                      if (c.code === 'CN') return c;
                      return null;
                    })
                    : locations,
                country: countryIndex,
                loadingLocations: false,
                building,
              });
            })
            .catch((err) =>
              this.setState({
                loadingLocations: false,
                errorLocations: err.response
                  ? err.response.data.message
                  : err.message,
              })
            );
          this.setState({
            building,
            hubspot_building_id: building.hubspot_building_id,
            name: building.name,
            code: building.code,
            address: building.address,
            loading: false,
            timeZone: building.time_zone ? building.time_zone : '  ---',
            reply_to: building.reply_to ? building.reply_to : '',
            csv_upload: {
              label:
                building.details.csv_upload !== undefined
                  ? building.details.csv_upload === true
                    ? t('email.yes')
                    : t('associates.no')
                  : t('associates.no'),
              value:
                building.details.csv_upload !== undefined
                  ? building.details.csv_upload
                  : false,
            },
          });
        })
        .catch((error) =>
          this.setState({
            error: error.response
              ? error.response.data.message
              : t('common.error.something'),
            loading: false,
          })
        );
      getPrefs(match.params.id)
        .then((preferences) => {
          this.setState({
            preferences,
            reminderPerPkg: {
              value: preferences.reminder_per_package,
              label: preferences.reminder_per_package,
            },
            timeBetweenReminders: {
              value: preferences.time_automated_reminders,
              label: `${preferences.time_automated_reminders} ${t(
                'common.hours'
              )}`,
            },
            mondaySwitch: preferences.reminders.monday.active,
            tuesdaySwitch: preferences.reminders.tuesday.active,
            wednesdaySwitch: preferences.reminders.wednesday.active,
            thursdaySwitch: preferences.reminders.thursday.active,
            fridaySwitch: preferences.reminders.friday.active,
            mondayTime: preferences.reminders.monday.time,
            tuesdayTime: preferences.reminders.tuesday.time,
            wednesdayTime: preferences.reminders.wednesday.time,
            thursdayTime: preferences.reminders.thursday.time,
            fridayTime: preferences.reminders.friday.time,
            prefsLoading: false,
          });
        })
        .catch((error) =>
          this.setState({
            errorPrefs: error.response
              ? error.response.data.message
              : t('common.error.something'),
            prefsLoading: false,
          })
        );
      this.getMailrooms();
      this.getBuildingUsers();
      //  this.getShippingMethodLabels();
    }
  };

  getMailrooms = () => {
    const { t, match } = this.props;
    this.setState({ mailroomsLoading: true, mailroomsError: null });
    getBuildingMailrooms(match.params.id)
      .then((mailrooms) =>
        this.setState({
          mailrooms,
          mailroomsLoading: false,
        })
      )
      .catch((error) =>
        this.setState({
          mailroomsError: error.response
            ? error.response.data.message
            : t('common.error.something'),
          mailroomsLoading: false,
        })
      );
  };

  getBuildingUsers = () => {
    const { t, match } = this.props;
    this.setState({ usersLoading: true });
    getBuildingUsers(match.params.id, 'name', true, 200)
      .then((res) =>
        this.setState({
          users: this.createRows(res.Users),
          usersLoading: false,
        })
      )
      .catch((error) =>
        this.setState({
          usersError: error.response
            ? error.response.data.message
            : t('common.error.something'),
          usersLoading: false,
        })
      );
  };

  getShippingMethodLabels = (tenant_id, save = true) => {
    const { t, match } = this.props;
    // const { tenant_id } = build;
    // const { tenant_id } = buildings.filter(x => x.building_id === match.params.id);
    this.setState({ loadingLbl: save }, () => {
      getLabelsByBuildingId(match.params.id, tenant_id)
        .then((lables) =>
          this.setState({
            // shippingLabels: lables['cta-fwd'] ? lables['cta-fwd'].filter(x => x.tenant !== null || x.building_id === +match.params.id).sort((a, b) => a.id - b.id) : [],
            shiplabels: {
              HOLD: lables['cta-hold']
                ? lables['cta-hold']
                  .filter(
                    (x) =>
                      x.tenant !== null ||
                      (x.tenant === null && x.building_id === null) ||
                      x.building_id === +match.params.id
                  )
                  .sort((a, b) => a.id - b.id)
                : [],
              SNAPSEND: lables['cta-snapsend']
                ? lables['cta-snapsend']
                  .filter(
                    (x) =>
                      x.tenant !== null ||
                      (x.tenant === null && x.building_id === null) ||
                      x.building_id === +match.params.id
                  )
                  .sort((a, b) => a.id - b.id)
                : [],
              DESTROY: lables['cta-destroy']
                ? lables['cta-destroy']
                  .filter(
                    (x) =>
                      x.tenant !== null ||
                      (x.tenant === null && x.building_id === null) ||
                      x.building_id === +match.params.id
                  )
                  .sort((a, b) => a.id - b.id)
                : [],
              FORWARD: lables['cta-fwd']
                ? lables['cta-fwd']
                  .filter(
                    (x) =>
                      x.tenant !== null ||
                      (x.tenant === null && x.building_id === null) ||
                      x.building_id === +match.params.id
                  )
                  .sort((a, b) => a.id - b.id)
                : [],
            },
            loadingLbl: false,
          })
        )
        .catch((error) =>
          this.setState({
            savingError: error.message
              ? error.message
              : t('common.error.networkError'),
            loadingLbl: false,
          })
        );
    });
  };

  setpPreferenceCTA = (pref, prefCTA) =>
    this.setState({ preferenceCTA: prefCTA, prefJson: pref });
  setEmailPref = (emailPref) => this.setState({ emailPref });

  setDataForDeleteUser = (type, value) => {
    this.setState({ [type]: value });
  };

  updateCloseAlert = () => {
    this.closeAlert();
    this.getData();
  };

  closepopUp = () => {
    this.setState({
      openConfirmation: false,
      selectedAssociateNameToDelete: '',
      selectedAssociateIdToDelete: null,
      errorDeletingAssociate: false,
      successDeletingAssociate: false,
    });
  };

  closeAlert = () => {
    this.setState({
      successDeletingAssociate: null,
      errorDeletingAssociate: false,
      openConfirmation: false,
      // successDeletingAssociate: false
    });
  };

  closeAlert = () => this.setState({ saved: false, savingError: null });
  saveCTA = (pref, labels, dellabl) => {
    const { match } = this.props;
    const { prefJson } = this.state;
    const promises = [];
    prefJson.ACTION_ITEMS = Object.fromEntries(pref);
    prefJson.EMAIL = this.state.emailPref;
    if (
      JSON.stringify(Object.fromEntries(pref)) !==
      JSON.stringify(Object.fromEntries(this.state.preferenceCTA))
    ) {
      promises.push(
        updateNotificationPrefs(match.params.id, 'BUILDING', prefJson, true)
      );
      this.setState({ preferenceCTA: pref });
    }
    const newLbls = [];
    if (labels.length) {
      labels.forEach((element) => {
        if (element.id === 0) {
          delete element.id;
          newLbls.push(element);
        }
      });
    }
    if (dellabl.length) {
      this.setState(
        {
          saving: true,
          // loadingLbl: newLbls.length
        },
        () => {
          deleteLabels({ label_id: dellabl })
            .then(() => {
              if (newLbls.length) promises.push(addLables({ labels: newLbls }));
              Promise.all(promises)
                .then(() => {
                  this.setState({ saving: false, saved: true });
                  const { tenant_id } = this.state;
                  if (newLbls.length) {
                    this.getShippingMethodLabels(tenant_id, false);
                  }
                })
                .catch((err) => {
                  const { tenant_id } = this.state;
                  if (newLbls.length) {
                    this.getShippingMethodLabels(tenant_id, false);
                  }
                  this.setState({
                    saving: false,
                    savingError:
                      err.message && err.message.includes('duplicate')
                        ? this.props.t('common.error.duplicatelabels')
                        : err.message
                          ? err.message
                          : this.props.t('common.error.networkError'),
                    //  loadingLbl: false,
                  });
                });
            })
            .catch((err) => {
              // const { tenant_id } = this.state;
              // if (newLbls.length) this.getShippingMethodLabels(tenant_id, false);
              this.setState({
                saving: false,
                savingError:
                  err.message && err.message.includes('duplicate')
                    ? this.props.t('common.error.duplicatelabels')
                    : err.message
                      ? err.message
                      : this.props.t('common.error.networkError'),
                //  loadingLbl: false,
              });
            });
        }
      );
    } else {
      // eslint-disable-next-line
      if (newLbls.length) promises.push(addLables({ labels: newLbls }));
      this.setState(
        {
          saving: true,
          // loadingLbl: newLbls.length
        },
        () => {
          Promise.all(promises)
            .then(() => {
              this.setState({ saving: false, saved: true });
              const { tenant_id } = this.state;
              if (newLbls.length) {
                this.getShippingMethodLabels(tenant_id, false);
              }
            })
            .catch((err) => {
              const { tenant_id } = this.state;
              if (newLbls.length) {
                this.getShippingMethodLabels(tenant_id, false);
              }
              this.setState({
                saving: false,
                savingError:
                  err.message && err.message.includes('duplicate')
                    ? this.props.t('common.error.duplicatelabels')
                    : err.message
                      ? err.message
                      : this.props.t('common.error.networkError'),
                //  loadingLbl: false,
              });
            });
        }
      );
    }
    // prefJson.Action
  };

  deleteAssociate = () => {
    const {
      selectedAssociateIdToDelete,
      successDeletingAssociate,
      deleteMemberLoader,
      building,
    } = this.state;
    if (successDeletingAssociate || deleteMemberLoader) return;
    this.setState({ deleteMemberLoader: true }, () => {
      deleteUserAssociate(selectedAssociateIdToDelete, building.tenant_id)
        .then(() => {
          this.setState({
            associatesAllRecord: [],
            selectedAssociateIdToDelete: null,
            successDeletingAssociate: true,
            errorDeletingAssociate: false,
            loading: false,
            deleteMemberLoader: false,
            openConfirmation: false
          });
        })
        .catch(() => {
          this.setState({
            errorDeletingAssociate: true,
            successDeletingAssociate: false,
            deleteMemberLoader: false,
            openConfirmation: false
          });
        });
    });
  };

  selectItem = (name, item) => {
    const { t } = this.props;
    if (name === 'country') {
      this.setState({
        loadingStates: true,
        country: item,
        states: [],
        cities: [],
        state: '',
        city: '',
      });
      getCountryStateCities(item.country_id)
        .then((states) => this.setState({ states, loadingStates: false }))
        .catch((err) =>
          this.setState({
            loadingStates: false,
            errorStates: err.response
              ? err.response.data.message
              : err.message && err.message.includes('Network')
                ? t('common.error.networkError')
                : err.message,
          })
        );
    } else if (name === 'state') {
      this.setState({
        loadingCities: true,
        state: item,
        cities: [],
        city: '',
      });
      getCountryStateCities(null, item.state_id)
        .then((cities) => this.setState({ cities, loadingCities: false }))
        .catch((err) =>
          this.setState({
            loadingCities: false,
            errorCities: err.response
              ? err.response.data.message
              : err.message && err.message.includes('Network')
                ? t('common.error.networkError')
                : err.message,
          })
        );
    } else if (name === 'city') {
      this.setState({ city: item });
    }
  };

  closeDeleteUserAlert = () => {
    this.setState({
      warning: null,
      errorUpdating: false,
      createMailrromError: false,
      createAssociateError: false,
      successDeletingAssociate: false
    });
  };

  handleChange = (name) => (event) => {
    const {
      validateEmail,
      validateBuildingAndAssociateName,
      generalValidation,
      t,
    } = this.props;
    const value = event.target.value;
    if (
      name !== 'associateName' ||
      name !== 'associateEmail' ||
      name !== 'selectedRole' ||
      name !== 'selectedMailroom' ||
      name !== 'csv_upload' ||
      name !== 'reply_to'
    ) {
      this.resetWarning();
    }
    if (name === 'name') {
      this.setState({
        nameError:
          value.trim().length > 0
            ? !validateBuildingAndAssociateName(value.trim())
            : false,
      });
    } else if (name === 'address') {
      this.setState({
        generalValidationError:
          value.length > 0 ? !generalValidation(value) : false,
      });
    }
    if (name === 'associateEmail') {
      this.setState(
        { [name]: value, associateEmailError: !validateEmail(value) },
        () => {
          const {
            associateName,
            associateEmail,
            associateEmailError,
            selectedRole,
            loadingRoles,
            selectedMailroom,
            associateNameError,
          } = this.state;
          this.setState({
            disableAssociateBtn:
              associateName.length === 0 ||
              associateEmail.length === 0 ||
              associateEmailError ||
              associateNameError ||
              loadingRoles ||
              (selectedRole.toLowerCase() === 'operator' &&
                selectedMailroom.length === 0),
          });
        }
      );
    } else if (name === 'selectedRole' || name === 'selectedMailroom') {
      this.setState(
        {
          [name]: value,
        },
        () => {
          const {
            associateName,
            associateEmail,
            associateEmailError,
            selectedRole,
            loadingRoles,
            selectedMailroom,
            associateNameError,
          } = this.state;
          this.setState({
            disableAssociateBtn:
              associateName.length === 0 ||
              associateEmail.length === 0 ||
              associateEmailError ||
              associateNameError ||
              loadingRoles ||
              (selectedRole.toLowerCase() === 'operator' &&
                selectedMailroom.length === 0),
          });
        }
      );
    } else if (name === 'associateName') {
      this.setState(
        {
          [name]: value,
          associateNameError:
            value.length > 0
              ? !validateBuildingAndAssociateName(value.trim())
              : false,
        },
        () => {
          const {
            associateName,
            associateEmail,
            associateEmailError,
            selectedRole,
            loadingRoles,
            selectedMailroom,
            associateNameError,
          } = this.state;
          this.setState({
            disableAssociateBtn:
              associateName.length === 0 ||
              associateEmail.length === 0 ||
              associateEmailError ||
              associateNameError ||
              loadingRoles ||
              (selectedRole.toLowerCase() === 'operator' &&
                selectedMailroom.length === 0),
          });
        }
      );
    } else if (name === 'csv_upload') {
      this.setState({
        csv_upload: {
          label:
            event.target.value === true ? t('email.yes') : t('associates.no'),
          value: event.target.value,
        },
      });
    } else {
      this.setState(
        {
          [name]: value,
          warning: null,
          createAssociateError: null,
        },
        () => {
          const { reply_to } = this.state;
          this.setState({
            // emailError: email && email.length > 0 ? !validateEmail(email) : false,
            replyToErrors:
              reply_to && reply_to.length > 0
                ? !validateEmail(reply_to)
                : false,
          });
        }
      );
    }
  };

  handleChangeMultiple = (name) => (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ [name]: value });
  };

  saveBuilding = () => {
    const {
      hubspot_building_id,
      name,
      code,
      address,
      city,
      state,
      country,
      nameError,
      addressError,
      mondayTime,
      mondaySwitch,
      tuesdayTime,
      tuesdaySwitch,
      wednesdayTime,
      wednesdaySwitch,
      thursdayTime,
      thursdaySwitch,
      fridayTime,
      fridaySwitch,
      reminderPerPkg,
      timeBetweenReminders,
      building,
      preferences,
      csv_upload,
      reply_to,
      replyToErrors,
    } = this.state;
    // const cityRegex = /^[A-Za-z]+$/;
    const { t, match, dispatch } = this.props;
    const nineAm = Date.parse('01/01/2011 09:00:00');
    const sixPm = Date.parse('01/01/2011 18:30:00');
    if (nameError) this.setState({ warning: t('common.error.nameValidation') });
    else if (addressError) {
      this.setState({ warning: t('common.error.addressValidation') });
    } else if (name.length === 0) {
      this.setState({ warning: t('buildings.nameNotEmpty') });
    } else if (name.length < 2) {
      this.setState({ warning: t('common.error.nameLength') });
    } else if (code.length === 0) {
      this.setState({ warning: t('buildings.codeNotEmpty') });
    } else if (country.length === 0) {
      this.setState({ warning: t('buildings.countryNotEmpty') });
    } else if (!(state instanceof Object)) {
      this.setState({ warning: t('buildings.stateNotEmpty') });
    } else if (!(city instanceof Object)) {
      this.setState({ warning: t('buildings.cityNotEmpty') });
    } else if (
      Date.parse(`01/01/2011 ${mondayTime}:00`) < nineAm ||
      Date.parse(`01/01/2011 ${mondayTime}:00`) > sixPm ||
      Date.parse(`01/01/2011 ${tuesdayTime}:00`) < nineAm ||
      Date.parse(`01/01/2011 ${tuesdayTime}:00`) > sixPm ||
      Date.parse(`01/01/2011 ${wednesdayTime}:00`) < nineAm ||
      Date.parse(`01/01/2011 ${wednesdayTime}:00`) > sixPm ||
      Date.parse(`01/01/2011 ${thursdayTime}:00`) < nineAm ||
      Date.parse(`01/01/2011 ${thursdayTime}:00`) > sixPm ||
      Date.parse(`01/01/2011 ${fridayTime}:00`) < nineAm ||
      Date.parse(`01/01/2011 ${fridayTime}:00`) > sixPm
    ) {
      this.setState({ warning: t('settings.chooseBetweenTime') });
    } else if (
      name.length > 0 &&
      city instanceof Object &&
      state instanceof Object &&
      country instanceof Object > 0
    ) {
      if (mondayTime.includes(':00') || mondayTime.includes(':30')) {
        if (tuesdayTime.includes(':00') || tuesdayTime.includes(':30')) {
          if (wednesdayTime.includes(':00') || wednesdayTime.includes(':30')) {
            if (thursdayTime.includes(':00') || thursdayTime.includes(':30')) {
              if (fridayTime.includes(':00') || fridayTime.includes(':30')) {
                if (
                  Date.parse(`01/01/2011 ${mondayTime}:00`) < nineAm ||
                  Date.parse(`01/01/2011 ${mondayTime}:00`) > sixPm ||
                  Date.parse(`01/01/2011 ${tuesdayTime}:00`) < nineAm ||
                  Date.parse(`01/01/2011 ${tuesdayTime}:00`) > sixPm ||
                  Date.parse(`01/01/2011 ${wednesdayTime}:00`) < nineAm ||
                  Date.parse(`01/01/2011 ${wednesdayTime}:00`) > sixPm ||
                  Date.parse(`01/01/2011 ${thursdayTime}:00`) < nineAm ||
                  Date.parse(`01/01/2011 ${thursdayTime}:00`) > sixPm ||
                  Date.parse(`01/01/2011 ${fridayTime}:00`) < nineAm ||
                  Date.parse(`01/01/2011 ${fridayTime}:00`) > sixPm
                ) {
                  this.setState({
                    warning: `${t('settings.chooseBetweenTime')} ${t(
                      'settings.chooseBetweenMinute'
                    )}`,
                  });
                } else {
                  const newBuilding = {
                    name,
                    code,
                    address,
                    hubspot_building_id,
                    city: city.city_id,
                    state: state.state_id,
                    country: country.country_id,
                    csv_upload: csv_upload.value,
                  };
                  if (!replyToErrors) {
                    if (building.reply_to !== reply_to) {
                      newBuilding.reply_to = reply_to.length ? reply_to : null;
                    }
                  }
                  const pref = {
                    mondaySwitch,
                    tuesdaySwitch,
                    wednesdaySwitch,
                    thursdaySwitch,
                    fridaySwitch,
                    mondayTime,
                    tuesdayTime,
                    wednesdayTime,
                    thursdayTime,
                    fridayTime,
                    reminderPerPkg: reminderPerPkg.value,
                    timeBetweenReminders: timeBetweenReminders.value,
                  };
                  const promises = [];
                  if (
                    building.name !== name ||
                    building.code !== code ||
                    building.address !== address ||
                    building.city.name !== city ||
                    building.state.name !== state ||
                    building.country.name !== country ||
                    building.details.csv_upload !== newBuilding.csv_upload ||
                    building.reply_to !== newBuilding.reply_to
                  ) {
                    promises.push(
                      this.updateBuilding(match.params.id, newBuilding)
                    );
                  }
                  if (
                    reminderPerPkg !== preferences.reminder_per_package ||
                    timeBetweenReminders !==
                    `${preferences.time_automated_reminders} hours` ||
                    mondaySwitch !== preferences.reminders.monday.active ||
                    tuesdaySwitch !== preferences.reminders.tuesday.active ||
                    wednesdaySwitch !==
                    preferences.reminders.wednesday.active ||
                    thursdaySwitch !== preferences.reminders.thursday.active ||
                    fridaySwitch !== preferences.reminders.friday.active ||
                    mondayTime !== preferences.reminders.monday.time ||
                    tuesdayTime !== preferences.reminders.tuesday.time ||
                    wednesdayTime !== preferences.reminders.wednesday.time ||
                    thursdayTime !== preferences.reminders.thursday.time ||
                    fridayTime !== preferences.reminders.friday.time
                  ) {
                    promises.push(
                      this.updatePreferences(pref, match.params.id)
                    );
                  }
                  if (promises.length > 0) {
                    this.setState({
                      updating: true,
                      errorUpdating: null,
                    });
                    Promise.all(promises)
                      .then(async () => {
                        const updatedBuilding = {
                          name,
                          code,
                          address,
                          city,
                          state,
                          country,
                        };
                        let auth = null;
                        switch (env && env.includes('cn')) {
                          case true:
                            auth = localStorage.px_uid;
                            break;
                          default: {
                            await Auth.currentSession().then((user) => {
                              const username = 'cognito:username';
                              auth = user.idToken.payload[username];
                            });
                          }
                        }
                        getProfile(auth).then((profile) => {
                          if (
                            profile.tenant.length === 1 &&
                            profile.tenant[0].buildings.length === 1
                          ) {
                            dispatch(
                              setBuilding([
                                {
                                  building_id:
                                    profile.tenant[0].buildings[0].building_id,
                                  building_name:
                                    profile.tenant[0].buildings[0]
                                      .building_name,
                                },
                              ])
                            );
                          } else if (
                            this.props.building.length > 0 &&
                            this.props.building[0].building_id ===
                            parseInt(match.params.id, 10)
                          ) {
                            dispatch(
                              setBuilding([
                                {
                                  building_id: parseInt(match.params.id, 10),
                                  building_name: name,
                                  building_address: name,
                                  building_city: city.name,
                                  building_state: state.name,
                                  building_country: country.name,
                                },
                              ])
                            );
                          }
                          dispatch(setBuildings(profile.tenant));
                          const updatedPrefs = {
                            reminder_per_package: reminderPerPkg,
                            time_automated_reminders: timeBetweenReminders,
                            reminders: {
                              monday: {
                                active: mondaySwitch,
                                time: mondayTime,
                              },
                              tuesday: {
                                active: tuesdaySwitch,
                                time: tuesdayTime,
                              },
                              wednesday: {
                                active: wednesdaySwitch,
                                time: wednesdayTime,
                              },
                              thursday: {
                                active: thursdaySwitch,
                                time: thursdayTime,
                              },
                              friday: {
                                active: fridaySwitch,
                                time: fridayTime,
                              },
                            },
                          };
                          this.setState({
                            building: updatedBuilding,
                            preferences: updatedPrefs,
                            buildingUpdated: true,
                            updating: false,
                          });
                        });
                      })
                      .catch((err) =>
                        this.setState({
                          errorUpdating:
                            err.response && err.response.data.message,
                          updating: false,
                        })
                      );
                  }
                }
              } else {
                this.setState({ warning: t('settings.chooseBetweenMinute') });
              }
            } else {
              this.setState({ warning: t('settings.chooseBetweenMinute') });
            }
          } else this.setState({ warning: t('settings.chooseBetweenMinute') });
        } else this.setState({ warning: t('settings.chooseBetweenMinute') });
      } else this.setState({ warning: t('settings.chooseBetweenMinute') });
    }
  };

  updateBuilding = (id, building) =>
    new Promise((resolve, reject) => {
      updateBuilding(id, building)
        .then(() => resolve())
        .catch((err) => reject(err));
    });

  updatePreferences = (pref, id) =>
    new Promise((resolve, reject) => {
      updatePreferences(pref, id)
        .then(() => resolve())
        .catch((error) => reject(error));
    });

  openCreateMailroomForm = () =>
    this.setState({ mailroomForm: !this.state.mailroomForm });

  openCreateAssociateForm = () => {
    this.setState({
      associateForm: !this.state.associateForm,
      createAssociate: false,
      associateName: '',
      associateEmail: '',
      selectedMailroom: [],
      disableAssociateBtn: true,
      createAssociateError: false,
    });
    if (this.state.roles.length === 0) {
      this.setState({ loadingRoles: true, rolesError: null });
      // const { userRole } = this.props;
      getRoles()
        .then((roles) => {
          const userIsAdmin =
            [...roles].filter((item) => item.name === 'admin').length === 0;
          const filteredRoles =
            roles.length === 1
              ? roles
              : userIsAdmin
                ? roles.slice(roles.length - 2, roles.length)
                : roles.slice(roles.length - 3, roles.length);
          this.setState({
            createAssociateError: null,
            roles: filteredRoles,
            loadingRoles: false,
            selectedRole: filteredRoles && filteredRoles[0].value,
          });
        })
        .catch((error) =>
          this.setState({
            rolesError: error.response
              ? error.response.data.message
              : error.message,
            loadingRoles: false,
          })
        );
    }
  };

  createMailroom = () => {
    this.setState({ creatingMailroom: true, disableMailroomBtn: true });
    const { mailroomName, building, mailrooms } = this.state;
    const { incrementLast, dispatch, t } = this.props;
    const mailroomcode = incrementLast(
      mailrooms[mailrooms.length - 1] && mailrooms[mailrooms.length - 1].code
        ? mailrooms[mailrooms.length - 1].code.includes(building.code)
          ? mailrooms[mailrooms.length - 1].code
          : `${building.code}-0`
        : `${building.code}-0`
    );

    createMailroom({
      code: mailroomcode,
      name: mailroomName,
      building_id: building.building_id,
    })
      .then(() => {
        this.openCreateMailroomForm();
        this.setState({
          mailroomsLoading: true,
          mailroomsError: null,
          mailroomCreated: true,
          creatingMailroom: false,
          mailroomName: '',
        });
        this.getMailrooms(building.building_id);
        if (env && env.includes('cn')) {
          const auth = localStorage.px_auth;
          if (auth) {
            getProfile(localStorage.px_uid).then((profile) => {
              this.setState({
                user: {
                  name: profile.user.UserAttributes[1].Value,
                },
              });
              if (
                profile.tenant.length === 1 &&
                profile.tenant[0].buildings.length === 1
              ) {
                dispatch(
                  setBuilding([
                    {
                      building_id: profile.tenant[0].buildings[0].building_id,
                      building_name:
                        profile.tenant[0].buildings[0].building_name,
                    },
                  ])
                );
              }
              dispatch(setBuildings(profile.tenant));
              dispatch(setRole(profile.role.name));
            });
          }
        } else {
          Auth.currentSession().then((user) => {
            this.setState({ loggedin: true });
            const username = 'cognito:username';
            this.setState({ user: user.idToken.payload });
            getProfile(user.idToken.payload[username]).then((profile) => {
              if (
                profile.tenant.length === 1 &&
                profile.tenant[0].buildings.length === 1
              ) {
                dispatch(
                  setBuilding([
                    {
                      building_id: profile.tenant[0].buildings[0].building_id,
                      building_name:
                        profile.tenant[0].buildings[0].building_name,
                    },
                  ])
                );
              }
              dispatch(setBuildings(profile.tenant));
              dispatch(setRole(profile.role.name));
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          mailroomName: '',
          createMailrromError:
            error.code ===
              error.message.includes('length must be at least 4 characters long')
              ? error.response && error.response.data.message
              : error.message.includes('Mailroom code already exists.')
                ? t('common.error.mailroomAlreadyExist')
                : t('common.error.createMailroom'),
          creatingMailroom: false,
        });
      });
  };

  createAssociate = () => {
    const {
      picture,
      associateName,
      associateEmail,
      roles,
      selectedRole,
      building,
      selectedMailroom,
      associateEmailError,
      associateNameError,
    } = this.state;
    const { t, userRole } = this.props;
    if (roles.length > 0) {
      const onlyRoles = roles.map((role) => role.value);
      const roleIndex = onlyRoles.indexOf(selectedRole);
      const associate = {
        user_name: associateName.trim(),
        user_email: associateEmail.trim(),
        role_id:
          selectedRole === '' ? roles[0].role_id : roles[roleIndex].role_id,
      };
      if (picture) associate.user_image = picture.split('base64,')[1];
      if (selectedRole.toLowerCase() === 'operator') {
        associate.access_policy = {
          mailroom_id: selectedMailroom.map((mlrm) => String(mlrm.mailroom_id)),
        };
      } else {
        associate.access_policy = {
          building_id: [building.building_id.toString()],
        };
      }
      if (associate.user_name.length === 0) {
        this.setState({ createAssociateError: t('common.error.noEmptyName') });
      } else if (associate.user_email.length === 0) {
        this.setState({ createAssociateError: t('common.error.noEmptyEmail') });
      } else if (associateEmailError) {
        this.setState({
          createAssociateError: t('common.error.enterValidEmail'),
        });
      } else if (associateNameError) {
        this.setState({
          createAssociateError: t('common.error.nameValidation'),
        });
      } else {
        if (selectedRole === 'Admin') {
          associate.access_policy = {};
        }
        if (userRole === 'super_admin') {
          associate.tenant = this.state.building.tenant_id;
        }
        this.setState({
          creatingAssociate: true,
          disableAssociateBtn: true,
        });
        createAssociate(associate)
          .then(() => {
            this.setState({
              creatingAssociate: false,
              disableAssociateBtn: false,
              associateCreated: true,
              createAssociateError: false,
            });
            this.getBuildingUsers();
            this.openCreateAssociateForm();
          })
          .catch((err) => {
            // let errorMessage;
            // errorMessage = err.response
            //   ? err.response.data.message
            //   : err.message;
            let error = err.message.includes(
              'Forbidden: User Already Exists.'
            ) || err.message.includes(
              'An account with the given email already exists'
            ) || err.message.includes(
              'User Already Exists.'
            )
              ? t('common.error.alreadyExist')
              : t('common.error.networkError');

            this.setState({
              createAssociateError: error,
              creatingAssociate: false,
              disableAssociateBtn: false,
            });
          });
      }
    } else {
      this.setState({ createAssociateError: t('associates.fea') });
    }
  };

  handleSwitching = (name) => (event) => {
    this.setState({ [name]: event.target.checked, warning: null });
  };

  createRows = (associates) =>
    associates.map((associate) => ({
      uuid: associate.Username,
      name: (
        <Link to={`/associate_detail/${associate.Attributes[0].Value}`}>
          {truncateLabel(associate.Attributes[1].Value) || ''}
        </Link>
      ),
      email: (
        <a href={`mailto:${associate.Attributes[2].Value}`}>
          {truncateLabel(associate.Attributes[2].Value)}
        </a>
      ) || <p style={{ paddingLeft: 5 }}> --- </p>,
      role: associate.role_data ? associate.role_data.role.value : <p> --- </p>,
      status: associate.UserStatus ? (
        <div
          style={{
            color: associate.UserStatus === 'CONFIRMED' ? '#2ECC71' : '#FF1E59',
          }}
        >
          {associate.UserStatus === 'CONFIRMED'
            ? this.props.t('associates.active')
            : this.props.t('associates.inactive')}
        </div>
      ) : (
        <div style={this.props.style.colors.red}>
          {this.props.t('associates.inactive')}
        </div>
      ),
    }));

  closeSuccessAlert = () => {
    // const { match } = this.props;
    this.setState({
      warning: null,
      buildingUpdated: false,
      reload: true,
    });
    this.getData();
    // window.location.reload();
    // history.pushState(null, '/');
    // history.pushState(null, '/messages');
    // this.props.history.push(`/building_detail/${match.params.id}`);
  };

  closeAssociateSuccessAlert = () => {
    this.setState({
      createAssociate: false,
      associateName: '',
      associateEmail: '',
      selectedMailroom: [],
      disableAssociateBtn: true,
      creatingAssociate: false,
      associateCreated: false,
      createAssociateError: false,
      mailroomCreated: false,
    });
  };

  resetWarning = () => this.setState({ warning: null });

  openRoleDropdown = (e) => {
    this.setState({
      roleDropdown: !this.state.roleDropdown,
      roleDropdownAnchor: this.state.roleDropdownAnchor
        ? null
        : e.currentTarget,
    });
  };

  selectRole = (selectedRole) => {
    this.resetWarning();
    this.setState({
      selectedRole,
      selectedBuildings: [],
      selectedMailrooms: [],
      roleDropdownAnchor: null,
    });
  };

  hourSelection = (state, h) => {
    let prevState = this.state[state];
    prevState = `${h}:${prevState.split(':')[1]}`;
    this.setState({ [state]: prevState });
  };

  minSelection = (state, m) => {
    let prevState = this.state[state];
    prevState = `${prevState.split(':')[0]}:${m}`;
    this.setState({ [state]: prevState });
  };

  handleSelect = (name, value) => this.setState({ [name]: value });

  loadimage = (picture) => this.setState({ picture });

  render() {
    const {
      country,
      state,
      city,
      loading,
      building,
      error,
      prefsLoading,
      updating,
      errorUpdating,
      loadingLocations,
      loadingStates,
      loadingCities,
      mailroomForm,
      associateForm,
      warning,
      buildingUpdated,
      emails,
      loadingEmails,
      emailError,
      nameError,
      addressError,
      associateCreated,
      createMailrromError,
      createAssociateError,
      mailroomCreated,
      openConfirmation,
      selectedAssociateNameToDelete,
      selectedAssociateIdToDelete,
      successDeletingAssociate,
      errorDeletingAssociate,
      deleteMemberLoader,
      replyToErrors,
    } = this.state;
    const { t, match, userRole } = this.props;
    const building_id = match.params.id;
    return !userRole ? (
      <VerticallyCenter>
        <AlignCenter>
          <CircularProgress className="blue" size={30} />
        </AlignCenter>
      </VerticallyCenter>
    ) : userRole === 'mailroom_supervisor' ? (
      <NotAuthorized />
    ) : (
      <Fragment>
        <Container className="min-width-100p">
          {errorUpdating && (
            <Alert
              variant="error"
              open
              message={errorUpdating}
              onClose={this.closeAlert}
            />
          )}
          {mailroomCreated && (
            <Alert
              variant="success"
              open
              message={t('buildings.mailroomCreated')}
              onClose={this.closeAssociateSuccessAlert}
            />
          )}
          {associateCreated && (
            <Alert
              variant="success"
              open
              message={t('associates.updatedSuccessfully')}
              onClose={this.closeAssociateSuccessAlert}
            />
          )}
          {buildingUpdated && (
            <Alert
              variant="success"
              open
              message={t('buildings.updatedSuccessfully')}
              onClose={this.closeSuccessAlert}
            />
          )}
          {warning && (
            <WarningAlert
              variant="error"
              onClose={this.closeAlert}
              message={warning}
              open
            />
          )}
          {createMailrromError && (
            <WarningAlert
              variant="error"
              onClose={this.closeAlert}
              message={createMailrromError}
              open
            />
          )}
          {createAssociateError && (
            <WarningAlert
              variant="error"
              onClose={() => {
                this.setState({
                  createAssociateError: undefined
                });
                this.closeAlert();
              }}
              message={createAssociateError}
              open
            />
          )}
          {successDeletingAssociate && (
            <Alert
              variant="success"
              open
              message={t('associates.associateDeletedSuccess')}
              onClose={() => {
                this.updateCloseAlert();
                this.setState({
                  successDeletingAssociate: false
                });
              }}
            />
          )}
          {errorDeletingAssociate && (
            <Alert
              variant="error"
              open
              message={t('associates.associateDeletedError')}
              onClose={() => {
                this.closeAlert();
                this.setState({
                  errorDeletingAssociate: false
                });
              }}
            />
          )}

          <ConfirmationModal
            setData={this.setDataForDeleteUser}
            openConfirmation={openConfirmation}
            selectedAssociateNameToDelete={selectedAssociateNameToDelete}
            selectedAssociateIdToDelete={selectedAssociateIdToDelete}
            successDeletingAssociate={successDeletingAssociate}
            errorDeletingAssociate={errorDeletingAssociate}
            deleteMemberLoader={deleteMemberLoader}
            updateCloseAlert={this.updateCloseAlert}
            deleteAssociate={this.deleteAssociate}
            closepopUp={this.closepopUp}
            closeAlert={this.closeDeleteUserAlert}
            t={t}
          />

          <CreateMailroom
            {...this.props}
            {...this.state}
            open={mailroomForm}
            toggle={this.openCreateMailroomForm}
            handleChange={this.handleChange}
            createMailroom={this.createMailroom}
          />
          <CreateAssociate
            {...this.props}
            {...this.state}
            userRole={userRole}
            open={associateForm}
            selectMailroom={(name, mailroom) =>
              this.selectMailroom(name, mailroom)
            }
            loadimage={this.loadimage}
            selectRole={(role) => this.selectRole(role)}
            openRoleDropdown={this.openRoleDropdown}
            openMailroomList={this.openMailroomList}
            toggle={this.openCreateAssociateForm}
            handleChange={this.handleChange}
            createAssociate={this.createAssociate}
            handleChangeMultiple={this.handleChangeMultiple}
            onClose={this.onCloseAddAssociate}
          />
          <Row style={{ marginBottom: 18 }}>
            <Col md={12}>
              <div
                style={
                  this.props.width === 'xs'
                    ? {
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }
                    : { display: 'flex', justifyContent: 'space-between' }
                }
              >
                <div>
                  <h3 className="page-title">
                    {loading && !error ? (
                      <Skeleton width={100} />
                    ) : (
                      building.name || '  ---'
                    )}
                  </h3>
                  <h3 className="page-subhead subhead">
                    {<Link to="/buildings">{t('tables.building')}</Link>}
                    <ArrowRightIcon />
                    {loading && !error ? (
                      <Skeleton width={100} />
                    ) : (
                      building.name || '  ---'
                    )}
                  </h3>
                </div>
                {!loading && !error && (
                  <div className="flex">
                    <BlueButton
                      right={10}
                      to="/buildings"
                      component={Link}
                      width={window.screen.width < 500 ? 136 : 160}
                    >
                      {t('common.cancel')}
                    </BlueButton>
                    <BlueButton
                      width={window.screen.width < 500 ? 136 : 160}
                      loading={updating}
                      disabled={
                        updating ||
                        loading ||
                        prefsLoading ||
                        loadingLocations ||
                        loadingStates ||
                        loadingCities ||
                        this.state.name.length < 2 ||
                        emailError ||
                        nameError ||
                        addressError ||
                        !country ||
                        country.length === 0 ||
                        !state ||
                        state.length === 0 ||
                        !city ||
                        city.length === 0 ||
                        replyToErrors
                      }
                      onClick={this.saveBuilding}
                    >
                      {t('buildings.saveBuilding')}
                    </BlueButton>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <BuildingDetailComp
            {...this.state}
            {...this.props}
            hourSelection={this.hourSelection}
            minSelection={this.minSelection}
            handleChange={this.handleChange}
            openCreateMailroomForm={this.openCreateMailroomForm}
            openCreateAssociateForm={this.openCreateAssociateForm}
            handleSwitching={this.handleSwitching}
            handleSelect={(name, value) => this.handleSelect(name, value)}
            emails={emails}
            loadingEmails={loadingEmails}
            emailError={emailError}
            nameError={nameError}
            addressError={addressError}
            building_id={building_id}
            selectItem={(name, item) => this.selectItem(name, item)}
            setDataForDeleteUser={this.setDataForDeleteUser}
            setpPreferenceCTA={this.setpPreferenceCTA}
            saveCTA={this.saveCTA}
            closeAlert={this.closeAlert}
            setEmailPref={this.setEmailPref}
          />
        </Container>
        <Footer />
      </Fragment>
    );
  }
}

BuildingDetail.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.string.isRequired,
};

export default withRouter(
  connect((state) => ({
    building: state.locationFilter,
    userRole: state.userRole,
    countries: state.countries,
    buildings: state.allBuildings,
  }))(withWidth()(BuildingDetail))
);
