export const CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY';
export const CHANGE_MOBILE_SIDEBAR_VISIBILITY = 'CHANGE_MOBILE_SIDEBAR_VISIBILITY';
export const CHANGE_TOP_BAR_MODE = 'CHANGE_TOP_BAR_MODE';
export const SHOW_SKIP_TUTORIAL = 'SHOW_SKIP_TUTORIAL';

export function changeSidebarVisibility() {
  return {
    type: CHANGE_SIDEBAR_VISIBILITY,
  };
}

export function changeMobileSidebarVisibility() {
  return {
    type: CHANGE_MOBILE_SIDEBAR_VISIBILITY,
  };
}

export function changeTopBarMode() {
  return {
    type: CHANGE_TOP_BAR_MODE
  };
}

export function showSkipTutorial(status) { // For onboarding
  return {
    type: SHOW_SKIP_TUTORIAL,
    showSkipTutorial: status
  };
}
