import { SET_STEP } from '../actions/userStepAction';

const initialState = null;

export default function (step = initialState, action) {
  switch (action.type) {
    case SET_STEP: {
      step = action.step;
      return step;
    }
    default: return step;
  }
}
