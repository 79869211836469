import React from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '../../PaymentModals/Styled';

function InvalidAccount(props) {
  return (
    <div>
      <Dialog keepMounted maxWidth="md" {...props}>
        <DialogContent className="pm-modal-container">
          <h3 className="pm-heading">
            Login with a {' '}
            <span className="pm-heading-red">
              valid
            </span> {' '}
            user
          </h3>
          <p className="pm-description">You have a <span className="pm-heading-red">Sales Admin</span> account, please login from <span className="pm-heading-green">Super Admin</span>, <span className="pm-heading-green">Admin</span>, <span className="pm-heading-green">Manager</span> or <span className="pm-heading-green">Operator </span>account.</p>
            <div className="pm-single-button-section">
              <Button
                className="pm-cp-downgrade-btn"
                onClick={() => props.onClose()}
              >
                Log Out
              </Button>
            </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withRouter(InvalidAccount);
