import React from 'react';
import PropTypes from 'prop-types';

const VerticallyCenter = ({ children, style, className }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      height: 'inherit',
      alignItems: 'center',
      ...style,
    }}
    className={className}
  >
    {children}
  </div>
);

VerticallyCenter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.node.isRequired,
  className: PropTypes.node.isRequired,
};

export default VerticallyCenter;
