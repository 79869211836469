import React from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { handleSidebarHover, handleSidebarHoverAway } from '../../../util/utility.functions';
import './style.css';

const BuildingsIconActive = require('./BuildingsIconActive.png');
const BuildingsIconInactive = require('./BuildingsIconInactive.png');

function BuildingsIcon(props) {
  const { active, label } = props;
  const imgSrc = active ? BuildingsIconActive : BuildingsIconInactive;
  return (
    <Tooltip title={label}>
      <img
        src={imgSrc}
        alt=""
        height="20"
        width="20"
        onMouseOver={(event) => { handleSidebarHover(event, BuildingsIconActive); }}
        onFocus={(event) => { handleSidebarHover(event, BuildingsIconActive); }}
        onMouseLeave={(event) => { handleSidebarHoverAway(event, active, BuildingsIconInactive); }}
      />
    </Tooltip>
  );
}

BuildingsIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.bool.isRequired,
};

export default BuildingsIcon;
