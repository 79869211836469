/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
/* eslint-disable function-paren-newline */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Link, withRouter } from "react-router-dom";
import { Person, Settings } from "@material-ui/icons";

import {
  withMobileDialog,
  IconButton,
  TableCell,
  Divider,
  Zoom,
  Tooltip,
  Grid,
  Badge,
} from "@material-ui/core";
import Alert from "../../../UI/Poper";
import { SpaceBetween, Flex } from "../../../UI/ResponsiveRelated";
import MaterialUITable from "../../../../shared/components/MaterialUITable/components/materialUITableComponent";
import { setTableColumn } from "../../../../util/application.utils";
import PaginationComponent from "../../../../shared/components/PaginationWithNumbers/components/paginationComponent";
import {
  truncateLabel,
  getBuildingFromCountries,
} from "../../../../util/utility.functions";
import { setPagination } from "../../../../redux/actions/paginationAction";
import { setDynamicPagination } from "../../../../redux/actions/dynamicPaginationActions";
import {
  getMembers,
  searchMember,
  getMembersForDateFilter,
} from "../../../../server";
import { setBuilding } from "../../../../redux/actions/locationsFilterActions";
import { setTableColumnFilter } from "../../../../redux/actions/tableColumnActions";
import { sortByMembers } from '../../../../util/sortBy';

class MembersTable extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      limit: props.limitPerPage.membersLimit,
      loading: false,
      members: [],
      pageOfItems: [],
      error: null,
      noMore: false,
      memberId: null,
      totalMembers: "---",
      view: "list",
      tableHeads: setTableColumn(t, props.voMember ? props.tableColumn.voMembers : props.tableColumn.members),
      activePage: 1,
      sortBy: "name",
      sortType: true,
      businessId: "",
      building: "",
      oneTableColumn: "",
      filterSetting: false,
      membersData: [],
    };

    this.tableRows = this.tableRows.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleSortByColumn = this.handleSortByColumn.bind(this);
  }

  componentDidMount() {
    if (this.props.from === undefined) {
      this.props.removeDateFilter();
    }
    this.getMembersData();
  }

  componentDidUpdate(prevProps) {
    const { from, to } = this.props;
    if (prevProps.lng !== this.props.lng) {
      const { t } = this.props;
      const { searchedMember } = this.props;
      // const { tableHeads } = this.state;
      // let tableHeads = this.props.tableColumn.members;
      let tableHeads = setTableColumn(t, prevProps.tableColumn.members);

      if (searchedMember.length !== "") {
        tableHeads[6].value = "phone_value";
      }
      this.setState({
        tableHeads,
        members: this.createRows(this.state.membersData),
      });
    } else if (prevProps.tab !== this.props.tab) {
      this.onChangePage(1);
    } else if (prevProps.building !== this.props.building) {
      this.onChangePage(1);
    } else if (
      prevProps.searchedMember.length > 0 &&
      this.props.startSearch &&
      !this.state.loading
    ) {
      this.onChangePage(1);
    } else if (
      prevProps.memberTempSearch !== this.props.memberTempSearch &&
      !this.state.loading
    ) {
      this.onChangePage(1);
    } else if (
      prevProps.from !== this.props.from &&
      prevProps.to !== this.props.to &&
      this.props.from !== undefined
    ) {
      this.setState({ activePage: 1 }, () => {
        this.onDateChange(from, to, 0);
      });
    } else if (
      ((prevProps.from !== this.props.from && prevProps.to === this.props.to) ||
        (prevProps.from === this.props.from &&
          prevProps.to !== this.props.to)) &&
      this.props.from !== undefined
    ) {
      this.setState({ activePage: 1 }, () => {
        this.onDateChange(from, to, 0);
      });
    } else if (
      prevProps.from !== this.props.from &&
      prevProps.to !== this.props.to &&
      this.props.from === undefined
    ) {
      this.setState({ activePage: 1, offset: 0 }, () => {
        this.getMembersData();
      });
    }
  }

  static getDerivedStateFromProps() {
    return true;
  }

  onChangePage = (currentActionPage, limit) => {
    let action;
    if (currentActionPage === "next") {
      action = this.state.activePage + 1;
    } else if (currentActionPage === "previous") {
      action = this.state.activePage - 1;
    } else {
      action = currentActionPage;
      if (limit) {
        let tempLimits = {};
        if (localStorage.px_dynamic_pagination) {
          tempLimits = JSON.parse(localStorage.px_dynamic_pagination);
          tempLimits.membersLimit = limit;
        } else {
          tempLimits = {
            packagesLimit: this.props.limitPerPage.packagesLimit,
            membersLimit: limit,
            businessesLimit: this.props.limitPerPage.businessesLimit,
            associatesLimit: this.props.limitPerPage.associatesLimit,
            buildingsLimit: this.props.limitPerPage.buildingsLimit,
          };
        }
        this.props.dispatch(setDynamicPagination(tempLimits));
        const currentPage = Math.ceil(
          this.state.totalMembers / this.state.limit
        );
        if (this.state.activePage === currentPage) {
          action = Math.ceil(this.state.totalMembers / limit);
        }
      }
    }
    const paginationObj = {
      route: "members",
      offset: (action - 1) * (limit || this.state.limit),
    };
    this.props.dispatch(setPagination(paginationObj));
    this.setState(
      {
        limit: limit || this.state.limit,
        loading: true,
        activePage: action,
        error: null,
        noMore: false,
      },
      () => this.getMembersData()
    );
  };

  onDateChange = (from, to, offset) => {
    const { t, building, id, tab } = this.props;
    const { tableHeads } = this.state;
    let buildingId = building.length > 0 ? building[0].building_id : null;
    if (id !== undefined && (tab === "building_members" || tab === "mp_members_building_members")) {
      buildingId = id;
    }
    tableHeads[6].value = "phone_value";
    this.setState(
      {
        tableHeads,
        sortBy: "name",
        loading: true,
      },
      () => {
        getMembersForDateFilter(
          this.state.limit,
          this.state.sortBy,
          this.state.sortType,
          offset,
          buildingId,
          from,
          to,
          (tab === 'business_designated_members' || tab === 'total_business_members') && id,
          tab === "business_designated_members"
        )
          .then((members) => {
            this.setState({
              activePage:
                members.length === 0 && this.state.activePage > 1
                  ? 1
                  : this.state.activePage,
              members: this.createRows(members),
              loading: false,
              membersData: members,
              totalMembers: members.length > 0 ? members[0].member_count : 0,
              noMore: members.length < this.state.limit,
            });
          })
          .catch((err) => {
            let errorMessage =
              err.message.indexOf("status code") > -1
                ? t("common.error.networkErrorMessage")
                : t("common.error.networkError");
            this.setState({
              error: errorMessage,
              loading: false,
            });
          });
      }
    );
  };

  getMembersData = () => {
    const { sortBy, sortType, activePage } = this.state;
    const {
      t,
      tab,
      id,
      building,
      // dispatch,
      searchedMember,
      stopSearch,
      pagination,
      from,
      to
    } = this.props;
    const routes = pagination.map((pg) => pg.route);
    const routeIndex = routes.indexOf("members");
    const offset = routeIndex > -1 ? pagination[routeIndex].offset : 0;
    this.setState({
      activePage: Math.floor(offset / this.state.limit) + 1,
      loading: true,
      error: null,
    });
    let buildingId = null;
    let businessId = null;
    if (from) {
      this.onDateChange(from, to, (activePage - 1) * this.state.limit);
    } else if (tab === "building_members" || tab === "mp_members_building_members") {
      buildingId = id;
    } else if (
      building &&
      tab !== "building_members" &&
      tab !== "mp_members_building_members" &&
      tab !== "total_business_members"
    ) {
      if (tab === "building_members" || tab === "mp_members_building_members") buildingId = id;
      else if (building.length > 0) buildingId = building[0].building_id;
    }
    if (tab === "total_business_members") {
      businessId = id;
    }
    if (tab === "building_members" || tab === "mp_members_building_members") {
      if (searchedMember) {
        const { tableHeads } = this.state;
        tableHeads[6].value = "phone_value";
        // membersTableColumnHeader(t)[6].value = 'business_name';
        this.setState(
          {
            tableHeads,
            sortBy: "name",
          },
          () => {
            searchMember(
              searchedMember,
              buildingId,
              this.state.limit,
              (activePage - 1) * this.state.limit,
              this.state.sortBy,
              this.state.sortType,
              businessId,
              this.props.voMember
            )
              .then((members) => {
                this.setState({
                  membersData: members,
                  members: this.createRows(members),
                  loading: false,
                  totalMembers:
                    members.length > 0 ? members[0].member_count : 0,
                  noMore: members.length < 15,
                });
              })
              .catch((err) => {
                let errorMessage =
                  err.message.indexOf("status code") > -1
                    ? t("common.error.networkErrorMessage")
                    : t("common.error.networkError");
                this.setState({
                  error: errorMessage,
                  loading: false,
                });
              });
            stopSearch();
          }
        );
      } else if (!from) {
        const { tableHeads } = this.state;
        tableHeads[6].value = "phone";
        this.setState(
          {
            tableHeads,

          },
          () => {
            getMembers(
              this.state.limit,
              this.state.sortBy,
              this.state.sortType,
              offset,
              buildingId,
              false,
              false,
              this.props.voMember
            )
              .then((members) => {
                this.setState({
                  activePage:
                    members.length === 0 && this.state.activePage > 1
                      ? 1
                      : this.state.activePage,
                  members: this.createRows(members),
                  loading: false,
                  membersData: members,
                  totalMembers:
                    members.length > 0 ? members[0].member_count : 0,
                  noMore: members.length < this.state.limit,
                });
                // if (members.length === 0 && this.state.activePage > 1) {
                //   this.setState({ activePage: 1 });
                //   this.onChangePage(1);
                // } else {
                //   this.setState({
                //     members: this.createRows(members),
                //     loading: false,
                //     membersData: members,
                //     totalMembers: members.length > 0 ? members[0].member_count : 0,
                //     noMore: members.length < 10,
                //   });
                // }
              })
              .catch((err) => {
                let errorMessage =
                  err.message.indexOf("status code") > -1
                    ? t("common.error.networkErrorMessage")
                    : t("common.error.networkError");
                this.setState({
                  error: errorMessage,
                  loading: false,
                });
              });
          }
        );
        stopSearch();
      }
    } else if (tab === "total_business_members") {
      if (searchedMember) {
        const { tableHeads } = this.state;
        tableHeads[6].value = "phone_value";
        // membersTableColumnHeader(t)[6].value = 'business_name';
        this.setState({ tableHeads, sortBy: "name", sortType: true }, () => {
          searchMember(
            searchedMember,
            buildingId,
            this.state.limit,
            (activePage - 1) * this.state.limit,
            this.state.sortBy,
            this.state.sortType,
            businessId,
            this.props.voMember
          )
            .then((members) => {
              this.setState({
                membersData: members,
                members: this.createRows(members),
                loading: false,
                totalMembers: members.length > 0 ? members[0].member_count : 0,
                noMore: members.length < this.state.limit,
              });
            })
            .catch((err) => {
              let errorMessage =
                err.message.indexOf("status code") > -1
                  ? t("common.error.networkErrorMessage")
                  : t("common.error.networkError");
              this.setState({
                error: errorMessage,
                loading: false,
              });
            });
          stopSearch();
        });
      } else if (!from) {
        const { tableHeads } = this.state;
        tableHeads[6].value = "phone";
        this.setState(
          {
            tableHeads,
          },
          () => {
            getMembers(
              this.state.limit,
              this.state.sortBy,
              this.state.sortType,
              offset,
              buildingId,
              id,
              false,
              this.props.voMember
            )
              .then((members) => {
                if (members.length === 0 && this.state.activePage > 1) {
                  this.setState({ activePage: 1 });
                  this.onChangePage(1);
                } else {
                  this.setState({
                    members: this.createRows(members),
                    loading: false,
                    membersData: members,
                    totalMembers:
                      members.length > 0 ? members[0].member_count : 0,
                    noMore: members.length < this.state.limit,
                  });
                }
              })
              .catch((err) => {
                let errorMessage =
                  err.message.indexOf("status code") > -1
                    ? t("common.error.networkErrorMessage")
                    : t("common.error.networkError");
                this.setState({
                  error: errorMessage,
                  loading: false,
                });
              });
            stopSearch();
          }
        );
      }
    } else if (tab === "business_designated_members" && !from) {
      const { tableHeads } = this.state;
      tableHeads[6].value = "phone";
      // membersTableColumnHeader(t)[6].value = 'business_name';
      this.setState(
        {
          tableHeads,
        },
        () => {
          getMembers(this.state.limit, sortBy, sortType, offset, null, id, true, this.props.voMember)
            .then((members) => {
              this.setState({
                membersData: members,
                members: this.createRows(members),
                loading: false,
                totalMembers: members.length > 0 ? members[0].member_count : 0,
                noMore: members.length < 15,
              });
            })
            .catch((err) => {
              let errorMessage =
                err.message.indexOf("status code") > -1
                  ? t("common.error.networkErrorMessage")
                  : t("common.error.networkError");
              this.setState({
                error: errorMessage,
                loading: false,
              });
            });
          stopSearch();
        }
      );
    } else if (searchedMember) {
      const { tableHeads } = this.state;
      tableHeads[6].value = "phone_value";
      // membersTableColumnHeader(t)[6].value = 'business_name';
      this.setState(
        {
          tableHeads,
          sortBy: "name",
        },
        () => {
          searchMember(
            searchedMember,
            buildingId,
            this.state.limit,
            (activePage - 1) * this.state.limit,
            this.state.sortBy,
            this.state.sortType,
            businessId,
            this.props.voMember
          )
            .then((members) => {
              this.setState({
                membersData: members,
                members: this.createRows(members),
                loading: false,
                totalMembers: members.length > 0 ? members[0].member_count : 0,
                noMore: members.length < 15,
              });
            })
            .catch((err) => {
              let errorMessage =
                err.message.indexOf("status code") > -1
                  ? t("common.error.networkErrorMessage")
                  : t("common.error.networkError");
              this.setState({
                error: errorMessage,
                loading: false,
              });
            });
          stopSearch();
        }
      );
    } else if (!from) {
      const { tableHeads } = this.state;
      tableHeads[6].value = "phone";
      // membersTableColumnHeader(t)[6].value = 'business_name';
      this.setState({ tableHeads });
      // dispatch(setPagination({ route: 'members', offset: (activePage - 1) * (this.state.limit) }));
      getMembers(this.state.limit, sortBy, sortType, offset, buildingId, false, false, this.props.voMember)
        .then((members) => {
          if (members.length === 0 && this.state.activePage > 1) {
            this.setState({ activePage: 1 });
            this.onChangePage(1);
          } else {
            this.setState({
              membersData: members,
              members: this.createRows(members),
              loading: false,
              totalMembers: members.length > 0 ? members[0].member_count : 0,
              noMore: members.length < this.state.limit,
            });
          }
          stopSearch();
        })
        .catch((err) => {
          let errorMessage =
            err.message.indexOf("status code") > -1
              ? t("common.error.networkErrorMessage")
              : t("common.error.networkError");
          this.setState({
            error: errorMessage,
            loading: false,
          });
          stopSearch();
        });
    }
  };

  createRows = (members) => {
    const { style, fullScreen, formatPhoneNumber, voMember } = this.props;
    return members.map((member) => {
      let allBusinesses =
        !member.member_businesses ||
          member.member_businesses === null ||
          member.member_businesses.length < 1
          ? []
          : member.member_businesses;
      let stringedBusinesses = [];
      if (allBusinesses.length > 0) {
        for (let i = 0; i < allBusinesses.length; i = 1 + i) {
          stringedBusinesses.push(allBusinesses[i].name);
        }
      }
      let tooltip = stringedBusinesses.toString();
      return {
        pkg_count: (
          <Link
            to={`/${voMember ? 'mp-items/member_items/' : 'packages/member_packages/'}${member.member_id}/outstanding`}
            onClick={() => this.handleRedirectToPackages(member.building_id)}
          >
            {member.count ? member.count : 0}
          </Link>
        ),
        pkg_received: (
          <Link
            to={`/${voMember ? 'mp-items/member_items/' : 'packages/member_packages/'}${member.member_id}`}
            onClick={() => this.handleRedirectToPackages(member.building_id)}
          >
            {member.total_received ? member.total_received : 0}
          </Link>
        ),
        profile_picture: (
          <Link to={`/member_detail/${member.member_id}`}>
            {member.profile_picture ? (
              <img
                src={`data:image/jpg;base64,${member.profile_picture}`}
                alt="profile"
                className="package-thumbnail"
                style={{ cursor: "default" }}
              />
            ) :
            member.membership && member.membership === "BOTH" ? <Badge className="badge fs-14px white bg-black" style={{ marginRight: "10px" }}>
            {'MP'}
          </Badge> :
            (
      
              <Person className="icon" style={{ cursor: "pointer" }} />
            )}
          </Link>
        ),
        id: member.member_id,
        name:
          member.name.length > 20 ? (
            <Tooltip TransitionComponent={Zoom} title={`${member.name}`}>
              <Link 
                to={`/member_detail/${member.member_id}`}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                {truncateLabel(member.name) || "---"}
              </Link>
            </Tooltip>
          ) : (
            <Link 
              to={`/member_detail/${member.member_id}`}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              {truncateLabel(member.name) || "---"}
             
            </Link>
          ),
        business_name:
          !member.member_businesses || member.member_businesses.length < 1 ? (
            <div>---</div>
          ) : tooltip.length > 20 ? (
            <Tooltip TransitionComponent={Zoom} title={tooltip}>
              <Flex style={style.common.hide_text_dots}>
                {member.member_businesses.map((i, index) => (
                  <Flex>
                    <Link
                      className="truncated-heading"
                      to={`/business_detail/${i.business_id}`}
                      onClick={() =>
                        this.props.rerender("business", i.business_id)
                      }
                      key={i.business_id}
                      style={{
                        width:
                          member.member_businesses.length - 1 === index
                            ? member.member_businesses.length === 1
                              ? fullScreen
                                ? 50
                                : 89
                              : fullScreen
                                ? 50
                                : 50
                            : "",
                      }}
                    >
                      {`${truncateLabel(i.name)}`}
                    </Link>
                    {member.member_businesses.length - 1 === index ? " " : ", "}
                  </Flex>
                ))}
              </Flex>
            </Tooltip>
          ) : (
            <Flex
              style={{
                display: "block !important",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {member.member_businesses.map((i, index) => (
                <Flex>
                  <Link
                    to={`/business_detail/${i.business_id}`}
                    onClick={() =>
                      this.props.rerender("business", i.business_id)
                    }
                    key={i.business_id}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`${truncateLabel(i.name)}`}
                  </Link>
                  {member.member_businesses.length - 1 === index ? " " : ", "}
                </Flex>
              ))}
            </Flex>
          ),
        building_name:
          !member.building_name || member.building_name === null ? (
            <div className="margin-10">---</div>
          ) : member.building_name.length > 20 ? (
            <Tooltip TransitionComponent={Zoom} title={member.building_name}>
              <Link
                className={
                  this.props.userRole === "mailroom_supervisor" &&
                  "disabled-link"
                }
                to={`/building_detail/${member.building_id}`}
                key={member.building_name}
                style={{ width: fullScreen ? 60 : 140 }}
              >
                {truncateLabel(member.building_name)}
              </Link>
            </Tooltip>
          ) : (
            <Link
              className={
                this.props.userRole === "mailroom_supervisor" && "disabled-link"
              }
              to={`/building_detail/${member.building_id}`}
              key={member.building_name}
              style={{ width: fullScreen ? 60 : 140 }}
            >
              {truncateLabel(member.building_name)}
            </Link>
          ),
        office: truncateLabel(member.office_number) || "---",
        email: (
          <a href={`mailto:${member.email}`}>
            {truncateLabel(member.email) || (
              <div className="margin-10">---</div>
            )}
          </a>
        ),
        phone: member.phone ? (
          <a href={`tel:${member.phone}`}>{formatPhoneNumber(member.phone)}</a>
        ) : (
          <div className="margin-10">---</div>
        ),
        total_members: member.active_member_counts,
        designated_members:
          member.designated_members === ""
            ? member.designated_members.length
            : 0,
        pending_packages: member.business_member_packages_count,
        businessPkgsCount: member.business_packages_count,
        totalPackages: (
          <div className="mailroom-data">
            <Link
              to={`/packages/vo-member_all_items/${member.member_id}`}
              onClick={() => this.handleRedirectToPackages(member.building_id)}
            >
              {this.props.t("associates.viewList")}
            </Link>
          </div>
        ),
      };
    });
  };

  handleRedirectToPackages = (buildingID) => {
    const { allCountries } = this.props;
    const currentBuilding = getBuildingFromCountries(allCountries, buildingID);
    this.props.dispatch(setBuilding([currentBuilding[0]]));
  };

  handleSwitchChange(index) {
    if (this.state.tableHeads[index].expanded) {
      const totalActive = [...this.state.tableHeads].filter(
        (item) => item.expanded === true
      ).length;
      if (totalActive > 1) {
        const tempArray = [...this.state.tableHeads];
        tempArray[index].expanded = !tempArray[index].expanded;
        this.setState({
          tableHeads: [...tempArray],
        });
      } else {
        this.setState({
          oneTableColumn: this.props.t("common.error.atleastOneActiveColumn"),
        });
      }
    } else {
      const tempArray = [...this.state.tableHeads];
      tempArray[index].expanded = !tempArray[index].expanded;
      this.setState({
        tableHeads: [...tempArray],
      });
    }
  }

  handleSortByColumn(columnName) {
    let { sortBy, sortType, activePage, limit } = this.state;
    // let tableHeads = this.props.tableColumn.members;
    if (sortBy === sortByMembers[columnName]) sortType = !sortType;
    else if (!sortByMembers[columnName]) sortType = false;
    else sortType = true;
    this.setState(
      {
        loading: true,
        sortBy: sortByMembers[columnName],
        sortType,
        members: [],
        // tableHeads,
      },
      () => this.onChangePage(activePage, limit)
    );
  }

  filterView = (name, saveTableColumn) => {
    this.setState({
      [name]: this.state.view === "list" ? "filter" : "list",
      filterSetting: true,
    });
    this.props.getFilterValue(this.state.view === "list" ? "filter" : "list");
    if (saveTableColumn) {
      const { tableHeads } = this.state;
      const originalColumn = this.props.tableColumn.members;
      const updatedMembersArray = tableHeads.map((item, index) => {
        let tempColumn = originalColumn[index];
        tempColumn.expanded = item.expanded;
        return tempColumn;
      });
      const tempTableColumn = {
        members: this.props.tableColumn.members,
        bussiness: this.props.tableColumn.bussiness,
        associates: this.props.tableColumn.associates,
        buildings: this.props.tableColumn.buildings,
        packages: this.props.tableColumn.packages,
        voMembers: updatedMembersArray,
        voBuilding: this.props.tableColumn.voBuilding && this.props.tableColumn.voBuilding,
      };
      this.props.dispatch(setTableColumnFilter(tempTableColumn));
    }
  };

  tableRows(item) {
    const { tableHeads } = this.state;
    // const { voMember } = this.props;
    let tableRowCells = [];
      if (tableHeads[0].expanded) {
        tableRowCells.push(
          <TableCell className="thumbnail-cell width-60px">
            {item.profile_picture}
          </TableCell>
        );
      }
      if (tableHeads[1].expanded) {
        tableRowCells.push(
          <TableCell
            className="padding-right-left truncated-heading width-200px"
            style={{ maxWidth: 89 }}
          >
            <span>{item.name}</span>
          </TableCell>
        );
      }
      if (tableHeads[2].expanded) {
        tableRowCells.push(
          <TableCell
            className="padding-right-left truncated-heading width-200px"
            style={{ maxWidth: 89 }}
          >
            {item.business_name}
          </TableCell>
        );
      }
      if (tableHeads[3].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-200px">
            {item.building_name}
          </TableCell>
        );
      }
      if (tableHeads[4].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-100px">
            {" "}
            {item.office}{" "}
          </TableCell>
        );
      }
      if (tableHeads[5].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-200px">
            {" "}
            {item.email}{" "}
          </TableCell>
        );
      }
      if (tableHeads[6].expanded) {
        tableRowCells.push(
          <TableCell className="padding-right-left truncated-heading width-100px">
            {" "}
            {item.phone}{" "}
          </TableCell>
        );
      }
      if (tableHeads[7].expanded) {
        tableRowCells.push(
          <TableCell style={{ paddingLeft: "60px", width: 100 }}>
            {item.pkg_received}
          </TableCell>
        );
      }
      if (tableHeads[8].expanded) {
        tableRowCells.push(
          <TableCell style={{ paddingLeft: "60px", width: 100 }}>
            {item.pkg_count}
          </TableCell>
        );
      }
    

    // if (tableHeads[9] && tableHeads[9].expanded) {
    //   tableRowCells.push(
    //     <TableCell className="padding-left-28px truncated-heading width-100px">
    //       {item.totalPackages}
    //     </TableCell>,
    //   );
    // }

    return tableRowCells;
  }

  render() {
    const {
      members,
      loading,
      error,
      memberId,
      totalMembers,
      tableHeads,
      activePage,
      sortBy,
      sortType,
      view,
      oneTableColumn,
      filterSetting,
    } = this.state;
    const showingColumns = [...tableHeads].filter(
      (item) => item.expanded === true
    );
    const activeColumns =
      showingColumns.length === tableHeads.length
        ? "Showing all columns"
        : `Showing ${showingColumns.length} of ${tableHeads.length} columns`;
    const { t } = this.props;
    const tableMessage =
      members.length > 0
        ? members.length < 10
          ? filterSetting
          : ""
        : this.props.voMember ? t("voItems.noVoFound") : t("members.noMembersFound");

    const { style } = this.props;
    if (memberId) return <Redirect to={`/member_detail/${memberId}`} />;
    return (
      <Fragment>
        <Grid item md={12} lg={12} className="container">
          {oneTableColumn && (
            <Alert
              variant="error"
              open
              message={oneTableColumn}
              onClose={() => this.setState({ oneTableColumn: "" })}
            />
          )}
          <div className="members-business-table">
            <SpaceBetween>
              <div
                className="justify-center"
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <p className="font-in-bold">{activeColumns}</p>
              </div>
              <IconButton
                disabled={loading}
                onClick={() => this.filterView("view")}
              >
                <Settings
                  style={
                    view === "filter"
                      ? {
                        ...style.common.fs_14,
                        ...style.colors.blue,
                        fontSize: 20,
                      }
                      : {
                        ...style.common.fs_14,
                        fontSize: 20,
                      }
                  }
                />
              </IconButton>
            </SpaceBetween>
            <Divider />
            {
              <React.Fragment>
                <MaterialUITable
                  t={t}
                  loading={loading}
                  view={view}
                  tableHeads={tableHeads}
                  tableData={members}
                  handleSwitchChange={this.handleSwitchChange}
                  handleSortByColumn={this.handleSortByColumn}
                  tableRows={this.tableRows}
                  sortBy={sortBy}
                  sortType={sortType}
                  message={tableMessage}
                  error={error}
                  changeView={this.filterView}
                  sortDataColumns={sortByMembers}
                />
              </React.Fragment>
            }
          </div>
        </Grid>
        {members.length > 0 && view === "list" && !error && (
          <div className="table-pagination">
            <PaginationComponent
              loading={loading}
              totalItems={totalMembers === "---" ? 0 : totalMembers}
              activePage={activePage}
              itemsPerPage={this.state.limit}
              onChange={this.onChangePage}
              showLimit
            />
          </div>
        )}
      </Fragment>
    );
  }
}

MembersTable.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  tab: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  building: PropTypes.arrayOf.isRequired,
  rerender: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  searchedMember: PropTypes.node.isRequired,
  startSearch: PropTypes.node.isRequired,
  stopSearch: PropTypes.func.isRequired,
  formatPhoneNumber: PropTypes.func.isRequired,
  getFilterValue: PropTypes.func.isRequired,
  lng: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  memberTempSearch: PropTypes.string.isRequired,
  limitPerPage: PropTypes.number.isRequired,
  pagination: PropTypes.node.isRequired,
  allCountries: PropTypes.node.isRequired,
  tableColumn: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  removeDateFilter: PropTypes.func.isRequired,
  voMember: PropTypes.bool

};

const mapStateToProps = (state) => ({
  tableColumn: state.tableColumn,
  limitPerPage: state.limitPerPage,
  allCountries: state.countries,
});

MembersTable.defaultProps = {
  voMember: false,
};


export default withMobileDialog()(
  withRouter(connect(mapStateToProps)(MembersTable))
);
