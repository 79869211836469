import { SET_LOADER } from '../actions/loaderAction';

const initialState = {
    buildings: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADER: {
      const { loader } = action;
      state[loader.loaderType] = loader.value;
      return state;
    }
    default:
      return state;
  }
}

