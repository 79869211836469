import React from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { handleSidebarHover, handleSidebarHoverAway } from '../../../util/utility.functions';
import './style.css';

const AssociatesIconActive = require('./AssociatesIconActive.png');
const AssociatesIconInactive = require('./AssociatesIconInactive.png');

function AssociatesIcon(props) {
  const { active, label } = props;
  const imgSrc = active ? AssociatesIconActive : AssociatesIconInactive;
  return (
    <Tooltip title={label}>
      <img
        src={imgSrc}
        alt=""
        height="20"
        width="20"
        onMouseOver={(event) => { handleSidebarHover(event, AssociatesIconActive); }}
        onFocus={(event) => { handleSidebarHover(event, AssociatesIconActive); }}
        onMouseLeave={(event) => { handleSidebarHoverAway(event, active, AssociatesIconInactive); }}
      />
    </Tooltip>
  );
}

AssociatesIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.bool.isRequired,
};

export default AssociatesIcon;
