/* eslint-disable quotes */
/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
// import { Redirect } from 'react-router-dom';
import {
  SettingsOutlined,
  PermIdentity,
  ExitToAppOutlined,
} from '@material-ui/icons';
import { Menu, MenuItem, Fade } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { setTriggerUserID } from '../../../util/Triggers/function';
// import usericon from "../../../assets/images/user-icon.png";
// import { setBuildings } from "../../../redux/actions/allBuildingsActions";
// import { setBuilding } from "../../../redux/actions/locationsFilterActions";
// import { setRole } from "../../../redux/actions/userRoleActions";

const NewLink = styled(Link)`
  color: #000;
  &:hover {
    color: black !important;
  }
`;

const itemStyle = {
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 16,
};

class TopbarProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      anchorEl: null,
    };
  }

  toggle = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  logout = async () => {
    const { signout, setRole } = this.props;
    await signout();
    setRole(null);
    setTriggerUserID(null);
    this.setState({
      redirect: true,
      anchorEl: null,
    });
  };

  render() {
    const { anchorEl, redirect } = this.state;
    if (redirect) {
      const { history } = this.props;
      history.replace('/login');
    }

    const { t, user, userRole, hideSearch } = this.props;

    const usersRole =
      userRole === 'mailroom_supervisor'
        ? 'operator'
        : userRole === 'building_manager'
        ? 'manager'
        : userRole;
    const indexOf_ = usersRole && usersRole.indexOf('_');
    const userRoleStyle =
      usersRole && indexOf_ > -1
        ? usersRole &&
          `${usersRole.charAt(0).toUpperCase() +
            usersRole.substr(1, indexOf_ - 1)} ${userRole
            .charAt(indexOf_ + 1)
            .toUpperCase() + usersRole.substr(indexOf_ + 2, usersRole.length)}`
        : usersRole &&
          usersRole.charAt(0).toUpperCase() +
            usersRole.substr(1, usersRole.length).toLowerCase();

    return (
      <div className="topbar__profile">
        <button
          className="topbar__avatar"
          onClick={this.toggle}
          style={{ alignItems: 'center' }}
        >
          <div className="avatar-div">
            {' '}
            <PermIdentity className="position-absolute profile-icon z-index-1" />{' '}
          </div>
          {/* {user.image && (
            <img
              src={user.image}
              className="height-width-35px border-radius-25px z-index-2 position-absolute"
              id="prof-img"
              onError={() => {
                let elem = document.getElementById("prof-img");
                elem.style.display = "none";
                elem.src = usericon;
              }}
              alt=""
            />
          )} */}
        </button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              width: 260,
              borderRadius: 4,
              boxShadow:
                'rgba(0, 0, 0, 0) 0px 6px 7px -4px, rgba(0, 0, 0, 0) 0px 11px 15px 1px, rgba(0, 0, 0, 0.12) 0px 4px 20px 3px',
            },
          }}
          TransitionComponent={Fade}
          style={{ marginTop: 45, marginLeft: 12 }}
        >
          <MenuItem
            style={{
              fontSize: 14,
              fontWeight: 500,
              lineHeight: 16,
              pointerEvents: 'none',
              backgroundColor: 'white',
            }}
            className="topbar__profile"
          >
            <PermIdentity className="position-absolute profile-icon z-index-1" />
            {/* {user.image && (
              <img
                src={user.image}
                className="height-width-25px border-radius-25px z-index-2 position-absolute"
                id="prof-icon"
                onError={() => {
                  let elem = document.getElementById("prof-icon");
                  elem.style.display = "none";
                  elem.style.height = "0px !important";
                  elem.style.width = "0px !important";
                  // elem.src = usericon;
                }}
                alt=""
              />
            )} */}
            <p
              style={{
                marginTop: 0,
                marginLeft: 6,
                textOverflow: 'ellipsis',
                width: 220,
                overflow: 'hidden',
                position: 'absolute',
                left: 40,
              }}
            >
              {' '}
              {`Hi, ${(user.name && user.name.split(' ')[0]) ||
                localStorage.getItem('userName') ||
                ''}`}
              {userRole && (
                <span className="font-size-14 dark-grey-color">
                  {' '}
                  ({userRoleStyle}){' '}
                </span>
              )}
            </p>
          </MenuItem>
          {!hideSearch && (
            <MenuItem
              style={itemStyle}
              onClick={this.handleClose}
              component={NewLink}
              to="/account_settings"
            >
              <SettingsOutlined className="topbar__avatar-img" />
              <p style={{ marginTop: 0, marginLeft: 6 }}>
                {t('main.accountSettings')}
              </p>
            </MenuItem>
          )}
          <MenuItem style={itemStyle} onClick={this.logout}>
            <ExitToAppOutlined className="topbar__avatar-img" />
            <p style={{ marginTop: 0, marginLeft: 6 }}>{t('main.logout')}</p>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

TopbarProfile.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.node.isRequired,
  userRole: PropTypes.node.isRequired,
  signout: PropTypes.func.isRequired,
  hideSearch: PropTypes.bool.isRequired,
  history: PropTypes.node.isRequired,
  setRole: PropTypes.func.isRequired,
  // history: PropTypes.node.isRequired
  // dispatch: PropTypes.func.isRequired,
  // i18n: PropTypes.node.isRequired,
};

export default TopbarProfile;
