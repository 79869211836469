import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Divider,
  List,
  IconButton,
  ListItem,
  withWidth,
  ListItemText,
} from '@material-ui/core';
import {
  Clear
} from '@material-ui/icons';
import {
  Truncate,
} from '../../../UI/ResponsiveRelated';

const ErrorDialog = ({
  t,
  style,
  width,
  open,
  handleClose,
  error,
}) => (
    <Dialog
      fullWidth
      fullScreen={width === 'xs'}
      open={open}
      onClose={handleClose}
      style={{ width: width === 'xs' && '100%', height: '100%' }}
      PaperProps={{
        style: { height: '100%', padding: 20, maxWidth: '-webkit-fill-available' },
      }}
    >
      <DialogContent style={{ height: 'inherit' }}>
        <IconButton
          color="inherit"
          onClick={handleClose}
          aria-label="Close"
          className="margin-right-10 float-right"
          style={{
            position: 'absolute',
            right:
              window.screen.width < 450 &&
                window.screen.width > 365
                ? 0
                : window.screen.width < 365
                  ? -10
                  : 0,
            top: 10
          }}
        >
          <Clear style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
        </IconButton>
        <List>
          <ListItem>
            <ListItemText>
              <div
                style={{
                  ...style.common.fs_12,
                  ...style.common.fs_14,
                  ...style.common.lh_14,
                  ...style.colors.grey,
                }}
              >
                {t('members.rowData')}
              </div>
            </ListItemText>
            <ListItemText>
              <div
                style={{
                  ...style.common.fs_12,
                  ...style.common.fs_14,
                  ...style.common.lh_14,
                  ...style.colors.grey,
                }}
              >
                {t('common.error.error')}
              </div>
            </ListItemText>
          </ListItem>
          <Divider />
          {error && error.error_type === 0 ? error.invalid_data.map(err => (
            <ListItem>
              <Truncate width="50%">
                <Truncate
                  width="100%"
                  style={{
                    ...style.common.fs_12,
                    ...style.common.fs_14,
                    ...style.common.lh_14,
                  }}
                >
                  {err.row}
                </Truncate>
              </Truncate>
              <Truncate width="50%" style={{ marginLeft: 50 }}>
                <Truncate width="100%" style={style.colors.red}>
                  {err.errors.toString()}
                </Truncate>
              </Truncate>
            </ListItem>
          )) : error && error.error_type === 1 && <ListItem>
              <Truncate width="50%">
                <Truncate
                  width="100%"
                  style={{
                    ...style.common.fs_12,
                    ...style.common.fs_14,
                    ...style.common.lh_14,
                  }}
                >
                  {error && 'All Data'}
                </Truncate>
              </Truncate>
              <Truncate width="50%" style={{ marginLeft: 50 }}>
                <Truncate width="100%" style={style.colors.red}>
                  {error && error.message}
                </Truncate>
              </Truncate>
            </ListItem>
          }
        </List>
      </DialogContent>
    </Dialog>
  );

ErrorDialog.propTypes = {
  t: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  error: PropTypes.node.isRequired,
  style: PropTypes.node.isRequired,
  // showErrorData: PropTypes.func.isRequired,
};

export default withWidth()(ErrorDialog);
