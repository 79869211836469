import axios from 'axios';
import { server } from './server';

export function searchMember(
  key,
  buildingId,
  paginationLimit,
  paginationOffset,
  sortBy,
  sortType,
  businessId,
  vo_member = false,
  all_member = false,
) {
  return new Promise((resolve, reject) => {
    let params = {
      order_by: sortBy,
      include_profile_pictures: true,
      order_asc: sortType,
      building_id: buildingId,
      pagination_limit: paginationLimit,
      pagination_offset: paginationOffset,
      include_business: true,
      include_num_pending_packages: true,
      member_count: true,
      membership: all_member ? undefined : vo_member ? 'VO_MEMBER' : 'MEMBER',
      platform: 'dashboard'
    };
    if (paginationOffset === 0 || paginationOffset === null) {
      delete params.pagination_offset;
    }
    if (businessId) {
      params.business_id = businessId;
    }
    axios
      .get(`${server}/entity/member/search?query=${key}`, { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function searchPackage(key, buildingId) {
  return new Promise((resolve, reject) => {
    let params = {
      order_asc: false,
      building_id: buildingId,
    };
    axios
      .get(`${server}/package/search?query=${key}`, { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function searchBuilding(
  key,
  paginationLimit,
  paginationOffset,
  sortBy,
  sortType,
) {
  return new Promise((resolve, reject) => {
    let params = {
      order_by: sortBy,
      include_profile_pictures: true,
      order_asc: sortType,
      pagination_limit: paginationLimit,
      pagination_offset: paginationOffset,
      include_business: true,
      include_num_pending_packages: true,
      member_count: true,
    };
    if (paginationOffset === 0 || paginationOffset === null) {
      delete params.pagination_offset;
    }
    axios
      .get(`${server}/entity/building/search?query=${key}`, { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function searchAssociate(
  key,
  buildingId,
  paginationLimit,
  paginationOffset,
  sortBy,
  sortType,
  mailroomId,
) {
  return new Promise((resolve, reject) => {
    let params = {
      order_by: sortBy,
      order_asc: sortType,
      building_id: buildingId,
      mailroom_id: mailroomId,
      pagination_limit: paginationLimit,
      pagination_offset: paginationOffset,
      include_users_thumbnail: true,
      include_mailrooms: true,
    };
    if (paginationOffset === 0 || paginationOffset === null) {
      delete params.pagination_offset;
    }

    axios
      .get(`${server}/entity/user/search?query=${key}`, { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}
