/* eslint-disable */
import { firebaseAnalytics } from './config';

const teamUrl = 'https://visionxtechnologies.webhook.office.com/webhookb2/9af555a1-0eb5-47cc-be8e-286883691640@4815bcf5-bf11-4348-8e31-c512254994b7/IncomingWebhook/b0d312d0ccca499584444458c8413036/3befc8a0-3b63-4148-b198-3292b7282188';

export const CallTrigger = (name, params) => {
    if (params.Previous_event === '') {
        params.Previous_event = localStorage.getItem('Previous_event');
    }
   firebaseAnalytics.logEvent(name, params);
   sendLogsToTeam(name, params)
    localStorage.setItem('Previous_event', name);
};

export const setTriggerUserID = (id) => {
   firebaseAnalytics.setUserId(id);
}

const sendLogsToTeam = (name, params) => {

    const facts = [];
    Object.entries(params).forEach(([key, value]) => {
        facts.push({ "name": key, "value": value })
    })

    const card = {
        "@type": "MessageCard",
        "@context": "http://schema.org/extensions",
        "themeColor": "0076D7",
        "summary": `${name}`,
        "sections": [{
            "activityTitle": `${name}`,
            "activitySubtitle": "Dashboard",
            "facts": facts,
            // "markdown": true
        }],
    }

    return new Promise((resolve, reject) => {
        fetch(teamUrl, {
            method: 'POST',
            mode: 'no-cors',
            headers: new Headers({
                'content-type': 'application/vnd.microsoft.teams.card.o365connector',
                'content-length': `${card.toString().length}`,
            }),
            body: JSON.stringify(card),
        })
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
};
