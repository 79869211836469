/* eslint-disable quotes */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Grid, Paper, Table, TableHead, TableRow, TableBody, TableCell, CircularProgress,
  withWidth, ListItem, ListItemSecondaryAction, ListItemText, List,
} from '@material-ui/core';
import Switch from '../../../UI/Switch';
import BlueButton from '../../../UI/BlueButton';
import { AlignCenter, Margin, VerticallyCenter } from '../../../UI/ResponsiveRelated';
import { getNotificationPrefs, updateNotificationPrefs, } from '../../../../server';
// import { styles } from '../../../../common/style';
import Alert from '../../../UI/Poper';

const env = process.env.REACT_APP_ENV;
const fs12 = { style: { fontSize: 12, color: '#898b8a' } };

class BuildingDetail extends Component {
  state = {
    pkgEmail: false,
    pkgSMS: false,
    remindersEmail: false,
    remindersSMS: false,
    pickupEmail: false,
    pickupSMS: false,
    loading: true,
    loadingError: null,
    saving: false,
    saved: false,
    savingError: null,
    actionItems: {}
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reload !== this.props.reload) {
      this.getData();
    }
  }

  getData = () => {
    getNotificationPrefs(this.props.building_id, 'BUILDING')
      .then(prefs => {
        this.setState({
          loading: false,
          pkgEmail: prefs.preference_json.EMAIL && prefs.preference_json.EMAIL.PACKAGE.NOTIFY.ACTIVE,
          pkgSMS: prefs.preference_json.SMS ? prefs.preference_json.SMS.PACKAGE.NOTIFY.ACTIVE : false,
          remindersEmail: prefs.preference_json.EMAIL && prefs.preference_json.EMAIL.PACKAGE.REMIND.ACTIVE,
          remindersSMS: prefs.preference_json.SMS ? prefs.preference_json.SMS.PACKAGE.REMIND.ACTIVE : false,
          pickupEmail: prefs.preference_json.EMAIL && prefs.preference_json.EMAIL.PACKAGE.PICKUP.ACTIVE,
          pickupSMS: prefs.preference_json.SMS ? prefs.preference_json.SMS.PACKAGE.PICKUP.ACTIVE : false,
        });
        if (prefs.preference_json.EMAIL) this.props.setEmailPref(prefs.preference_json.EMAIL);
        if (prefs.preference_json.ACTION_ITEMS) {
          // this.setState({ actionItems: prefs.preference_json.ACTION_ITEMS });
          this.props.setpPreferenceCTA(prefs.preference_json, Object.entries(prefs.preference_json.ACTION_ITEMS));
        }
      }).catch(err => this.setState({
        loading: false,
        loadingError: err.response ? err.response.data.message : this.props.t('common.error.networkError'),
      }));
  }
  handleChange = name => this.setState({ [name]: !this.state[name] });

  saveNotifications = () => {
    this.setState({ saving: true });
    // const { actionItems } = this.state;
    const prefJson = {
      EMAIL: {
        PACKAGE: {
          NOTIFY: {
            ACTIVE: this.state.pkgEmail,
          },
          PICKUP: {
            ACTIVE: this.state.pickupEmail,
          },
          REMIND: {
            ACTIVE: this.state.remindersEmail,
          },
        },
      },
      SMS: {
        PACKAGE: {
          NOTIFY: {
            ACTIVE: this.state.pkgSMS,
          },
          PICKUP: {
            ACTIVE: this.state.pickupSMS,
          },
          REMIND: {
            ACTIVE: this.state.remindersSMS,
          },
        },
      },
      SLACK: {
        PACKAGE: {
          NOTIFY: {
            ACTIVE: false,
          },
          PICKUP: {
            ACTIVE: false,
          },
          REMIND: {
            ACTIVE: false,
          },
        },
      },
      // ACTION_ITEMS: {
      //   SNAPSEND: false,
      //   FORWARD: false,
      //   HOLD: false,
      //   DESTROY: false,
      //   NOTMYPKG: false,
      // },
      ACTION_ITEMS: Object.fromEntries(this.props.preferenceCTA)
    };
    this.props.setEmailPref(prefJson.EMAIL);
    updateNotificationPrefs(this.props.building_id, 'BUILDING', prefJson)
      .then(() => this.setState({ saving: false, saved: true }))
      .catch(err => this.setState({
        saving: false,
        savingError: err.response ? err.response.data.message : this.props.t('common.error.networkError'),
      }));
  };

  closeAlert = () => this.setState({ saved: false, savingError: null });

  render() {
    const { t, building_id, width } = this.props;
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {(this.state.savingError || this.state.saved) && (
          <Alert
            open
            variant={this.state.savingError ? 'error' : 'success'}
            message={this.state.savingError ? t('common.error.networkError') : t('preferencesPortal.updated')}
            onClose={this.closeAlert}
          />
        )}
        <Paper className="paper">
          <div className="card-body">
            <div className="card__title">
              <h5 className="bold-text margin-0px">
                {t('preferencesPortal.preferences')}
              </h5>
            </div>
            {this.state.loading ?
              <VerticallyCenter>
                <AlignCenter>
                  <CircularProgress className="blue" size={30} />
                </AlignCenter>
              </VerticallyCenter>
              : (this.state.loadingError &&
                <VerticallyCenter>
                  <AlignCenter>
                    <p>
                      <b> {this.state.loadingError} </b>
                    </p>
                  </AlignCenter>
                </VerticallyCenter>
              ) ||
                width === 'xs' ?
                <List>
                  <ListItem desnse className="padding-10-0">
                    <ListItemText primaryTypographyProps={fs12} primary={t('tables.newPackages')} />
                    <ListItemSecondaryAction>
                      <Switch
                        checked={this.state.pkgEmail}
                        onChange={() => this.handleChange('pkgEmail')}
                      />{t('common.email')}
                      {env && env.includes('cn') &&
                        <>
                          <Switch
                            checked={this.state.pkgSMS}
                            onChange={() => this.handleChange('pkgSMS')}
                          />{t('common.sms')}
                        </>}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem desnse className="padding-10-0">
                    <ListItemText primaryTypographyProps={fs12} primary={t('tables.reminders')} />
                    <ListItemSecondaryAction>
                      <Switch
                        checked={this.state.remindersEmail}
                        onChange={() => this.handleChange('remindersEmail')}
                      />{t('common.email')}
                      {env && env.includes('cn') &&
                        <>
                          <Switch
                            checked={this.state.remindersSMS}
                            onChange={() => this.handleChange('remindersSMS')}
                          />{t('common.sms')}
                        </>}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem desnse className="padding-10-0">
                    <ListItemText primaryTypographyProps={fs12} primary={t('tables.pickedup')} />
                    <ListItemSecondaryAction>
                      <Switch
                        checked={this.state.pickupEmail}
                        onChange={() => this.handleChange('pickupEmail')}
                      />{t('common.email')}
                      {env && env.includes('cn') &&
                        <>
                          <Switch
                            checked={this.state.pickupSMS}
                            onChange={() => this.handleChange('pickupSMS')}
                          />{t('common.sms')}
                        </>}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
                :
                <Table dense table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="padding-right-5px">{t('tables.newPackages')}</TableCell>
                      <TableCell className="padding-right-5px">{t('tables.reminders')}</TableCell>
                      <TableCell className="padding-right-5px">{t('tables.pickedup')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className="padding-left-right-8px">
                        <Switch
                          checked={this.state.pkgEmail}
                          onChange={() => this.handleChange('pkgEmail')}
                        />
                        {t('common.email')}
                      </TableCell>
                      <TableCell className="padding-left-right-8px">
                        <Switch
                          checked={this.state.remindersEmail}
                          onChange={() => this.handleChange('remindersEmail')}
                        />
                        {t('common.email')}
                      </TableCell>
                      <TableCell className="padding-left-right-8px">
                        <Switch
                          checked={this.state.pickupEmail}
                          onChange={() => this.handleChange('pickupEmail')}
                        />
                        {t('common.email')}
                      </TableCell>
                    </TableRow>
                    {
                      env && env.includes('cn') &&
                      <TableRow>
                        <TableCell className="padding-left-right-8px">
                          <Switch
                            checked={this.state.pkgSMS}
                            onChange={() => this.handleChange('pkgSMS')}
                          />
                          {t('common.sms')}
                        </TableCell>
                        <TableCell className="padding-left-right-8px">
                          <Switch
                            checked={this.state.remindersSMS}
                            onChange={() => this.handleChange('remindersSMS')}
                          />
                          {t('common.sms')}
                        </TableCell>
                        <TableCell className="padding-left-right-8px">
                          <Switch
                            disabled
                          // checked={this.state.pickupSMS}
                          // onChange={() => this.handleChange('pickupSMS')}
                          />
                          {t('common.sms')}
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
            }
            {!this.state.loading && !this.state.loadingError &&
              <Margin top={50}>
                <div className="space-between-width-center align-center">
                  <div />
                  <BlueButton
                    width={120}
                    loading={this.state.saving}
                    disabled={this.state.saving || !building_id}
                    onClick={this.saveNotifications}
                  >{t('common.save')}
                  </BlueButton>
                </div>
              </Margin>}
          </div>
        </Paper>
      </Grid>
    );
  }
}

BuildingDetail.propTypes = {
  t: PropTypes.func.isRequired,
  building_id: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  setpPreferenceCTA: PropTypes.func.isRequired,
  preferenceCTA: PropTypes.node.isRequired,
  setEmailPref: PropTypes.func.isRequired,
  reload: PropTypes.bool.isRequired,
};

export default withWidth()(BuildingDetail);
