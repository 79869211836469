/* eslint-disable */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import { CircularProgress, Grid, Button } from '@material-ui/core';
import CircleCheck from '../../../shared/img/circlecheck.svg';
import ContactSalesResponse from '../../Dashboard/PaymentModals/ContactSalesResponse';
import { getPlanFeatures, getFullTierName } from '../../../util/application.utils';
import { contactSalesTeam } from '../../../server/payment';
import {
  getStripePaymentDetails,
  setPaymentPlan,
} from '../../../server/payment';

class PaymentConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadArray: true,
      type: 1,
      plans: [],
      selectedPlan: '',
      newSelectedPlan: '',
      loadingBtns: [],
      loadCustomContactSales: false,
      disableBtns: false,
      starterLoader: false,
      basicLoader: false,
      premiumLoader: false,
      internalLoader: true,
      openPlanChangeRequestModal: false,
      openContactSalesResponseModal: false,
      error: false,
      starterFeatures: [],
      basicFeatures: [],
      premiumFeatures: [],
      loadingCheckout: false
    };
  }


  closeContactSalesResponseModal = () => {
    this.setState(
      {
        openContactSalesResponseModal: false,
      },
      () => this.props.onClose());
  };

  setPaymentCheckout = (stripeId, loadingFalse) => {
    setPaymentPlan(stripeId)
      .then(async (res) => {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: res.data.id,
        });

        if (result.error) {
          return res;
        }
        return res;
      })
      .then((res) => {
        loadingFalse();
      })
      .catch((error) => {
        this.setState({ error: 'Unable to set payment plan' });
        loadingFalse();
        console.log(error);
      });
  };

  addPaymentInfo = () => {
    const loadingFalse = () =>
      this.setState({
        loadingCheckout: false,
      });
    this.setState(
      {
        loadingCheckout: true,
      },
      () => {
        getStripePaymentDetails()
          .then((res) => {
            this.setPaymentCheckout(res.data.stripe_price_id, loadingFalse);
          })
          .catch((err) => {
            console.log(err);
            this.setState({ error: 'Unable to fetch stripe payment details' });
            loadingFalse();
          });
      }
    );
  }

  render() {
    const {openContactSalesResponseModal, loadCustomContactSales, loadingCheckout} = this.state;
    const { buildings, type } = this.props;
    const hasBuilding = buildings && buildings.length > 0 && buildings[0];
    const hasDiscount = hasBuilding && buildings[0].discount;
    const features = hasBuilding ? getPlanFeatures(buildings[0].features, type) : [];
    const hasBillingInfo =
    hasBuilding && buildings[0].billing_price && buildings[0].billing;
    const getPrice = () => {
      if (hasBillingInfo && buildings[0].billing_price) {
        if (hasDiscount) {
          const discountPrice = parseInt(buildings[0].billing_price,10) * (hasDiscount / 100); 
          return (buildings[0].billing_price - discountPrice).toFixed(2);
        } else {
          return buildings[0].billing_price.toLocaleString('en-US');
        }
      } else {
        return '';
      }
    }

      const contactSales = () => {
          const { tenant_name, tier } = buildings[0];
          this.setState(
            {
              loadCustomContactSales: true,
            },
            () => {
              contactSalesTeam(tenant_name)
                .then(() => {
                  this.setState(
                    {
                      loadCustomContactSales: false,
                      openContactSalesResponseModal: true,
                    },
                    () => {}
                  );
                })
                .catch((err) => {
                  this.setState({ loadCustomContactSales: false });
                  console.log(err);
                });
            }
          );
      };

      const getPriceType = () => {
        if (hasBillingInfo) {
          return type === 1 ? 'PER MONTH' : 'PER YEAR';
        } else {
          return '';
        }
      };

    const closeModal = () => {
      this.props.onClose();
    } 
   const customPlanSBP =
      buildings &&
      buildings.length > 0 &&
      buildings[0].tier &&
      buildings[0].tier.toLowerCase();
    return (
      <Dialog maxWidth="md" {...this.props} onClose={closeModal}>
        <div className="pm-custom-plan-container">
      <div className="pm-main-heading">
          {getFullTierName(customPlanSBP)}{' '}Plan
      </div>
      <div className="pm-custom-main">
        <p className="font-weight-900">
        You are about to purchase the Mailroom by PackageX {getFullTierName(customPlanSBP)} Plan. With this plan, you will have access to the following features:
        </p>
        <div className="pm-custom-plan-card">
          <div className="pm-custom-plan-header">
            <span className="pm-custom-plan-heading">Features</span>
            <div className="pm-custom-plan-cost-section">
              <span className="pm-custom-currency">
                {hasBillingInfo ? '$' : ''}
              </span>
              <div className="pm-custom-plan-cost-inner">
                <span className="cost">{getPrice()}</span>
                <span className="occurance">{getPriceType()}</span>
              </div>
            </div>
          </div>
          <ul className="pm-custom-plan-features">
            {features &&
              features.length > 0 &&
              features.map((feature, index) => (
                <li key={index} className="pm-custom-plan-list-item">
                  <img src={CircleCheck} alt="" />
                  <span>{feature}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div className="pm-custom-footer-payment-confirmation">
                <p>
                You can contact our sales team to make any changes to your plan features before your purchase or proceed to checkout with the above feature set. 
                </p>
                <div className="pm-button-section m-t-0">
                <Button
                  disabled={loadCustomContactSales || loadingCheckout}
                  className="pm-cp-add-payment-btn"
                  onClick={() => contactSales()}
                >
                  <span className="pm-cp-btn-spacer">
                    CONTACT SALES{' '}
                    {loadCustomContactSales && (
                      <CircularProgress size={15} className="white" />
                    )}
                  </span>
                </Button>
                <Button
                  disabled={loadCustomContactSales || loadingCheckout}
                  className="pm-cp-upgrade-btn"
                  onClick={() => this.addPaymentInfo()}
                >
                  <span className="pm-cp-btn-spacer">
                    Proceed{' '}
                    {loadingCheckout && (
                      <CircularProgress size={15} className="white" />
                    )}
                  </span>
                </Button>
                </div>
                
                <ContactSalesResponse
                  open={openContactSalesResponseModal}
                  onClose={this.closeContactSalesResponseModal}
            />
      </div>
      </div>
      </Dialog>
    );
  }
}

export default connect((state) => ({
  paymentsDetails: state.paymentsDetails,
  buildings: state.allBuildings,
  buildingLoader: state.loader.buildings,
}))(withRouter(PaymentConfirmationModal));
