import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Paper } from '@material-ui/core';
import { version } from '../../../util/version';

const env = process.env.REACT_APP_ENV;

const Footer = ({ t }) => (
  <footer>
    <Paper className="space-between-justify-spacebetween-align-center just-paper height-60-footer margin-top-20px">
      <div className="space-between-100pc space-between-with-block-980 height-100p margin-left-right-20px">
        <div className="align-center font-size-14 displayFlex black">
          <p>
            {`${t('footer.formSupport')}: `} &nbsp;
            </p>
          <a href="mailto:support@packagex.io">
            support@packagex.io
          </a>
        </div>
        {(() => {
          switch (env && env.includes('cn')) {
            case true: {
              return (
                <div className="font-size-14 black align-center">
                  <p>
                    © {new Date().getFullYear()} PackageX Inc, (ICP No. 18032187-2). {`All rights reserved. ${version}`}
                  </p>
                </div>
              );
            }
            default: {
              return (
                <div className="font-size-14 black align-center">
                  <p className="fontSize">
                    © {new Date().getFullYear()} PackageX, Inc. {`All rights reserved. ${version}`}
                  </p>
                </div>
              );
            }
          }
        })()}
      </div>
    </Paper>
  </footer>
);

Footer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(Footer);
