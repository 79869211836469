/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Grid,
  Button,
  Tooltip,
} from '@material-ui/core';
import {
  AlignCenter,
  VerticallyCenter,
  SpaceBetween,
} from '../../../UI/ResponsiveRelated';
import ConfrimModal from '../../Buildings/ConfrimModal';
import PlanUpgradeNeeded from '../../PaymentModals/PlanUpgradeNeeded';
import FreeTrailExpiredInternal from '../../PaymentModals/FreeTrailExpiredInternal';
import { getDaysDifference } from '../../../../util/utility.functions';
import {
  function_checkFreeTrailExpired,
  function_checkPlanUpgradeNeededBuildingCheck,
} from '../../../../util/application.utils';
import { function_checkPlanUpgradeNeededMailroomCheck } from '../../../../util/application.utils';

class MailroomList extends React.Component {
  state = {
    openErrorModal: false,
    showPlanUpgradeNeededModal: false,
    showTrailExpiredModal: false,
  };

  closePlanUpgradeNeededModal = callback =>
    this.setState({ showPlanUpgradeNeededModal: false }, () => {
      callback && callback();
    });

    closeFreeTrialExpiredModal = (callback) => {
      this.setState({ showTrailExpiredModal: false }, () => {
        if (callback) {
        callback();
      }
    });
  }

  addMailroomForm = () => {
    const { buildings } = this.props;
    const validBuilding = buildings && buildings.length > 0 && buildings[0];
    if (
      validBuilding &&
      buildings[0].tier &&
      !buildings[0].buildings[0].legacy
    ) {
      const { showTrailExpiredModal } = this.state;
      const freeTrialExp = function_checkFreeTrailExpired(
        buildings,
        showTrailExpiredModal
      );
      const planUpgradNeed = function_checkPlanUpgradeNeededMailroomCheck(
        buildings
      );
      if (freeTrialExp) {
        this.setState({
          showTrailExpiredModal: true,
        });
        return;
      } else if (planUpgradNeed) {
        this.setState({
          showPlanUpgradeNeededModal: true,
        });
        return;
      }
    }
    this.props.openCreateMailroomForm();
  };

  render() {
    const {
      t,
      width,
      style,
      height,
      maxHeight,
      mailrooms,
      mailroomsLoading,
      mailroomsError,
      buildingDetail,
      buildings,
      userRole,
      userdetails,
    } = this.props;

    // const handleAddMailroom = () => {
    //   if (userRole !== 'super_admin') {
    //     if (buildings && buildings[0] && buildings[0].tier) {
    //       this.setState({ openErrorModal: true });
    //     }
    //   } else {
    //     openCreateMailroomForm();
    //   }
    // };

    const validBuilding = buildings && buildings.length > 0 && buildings[0];
    // const mailroomLimit =
    //   validBuilding &&
    //   buildings[0].features &&
    //   buildings[0].features.MAILROOM_LIMIT;

    const handleAddMailroom = () => {
      if (validBuilding) {
        if (buildings[0].status && buildings[0].status !== null) {
          // if (!mailroomLimit) this.addMailroomForm();
          // !mailroomsLoading && validBuilding && mailrooms.length < mailroomLimit
          //   ? this.addMailroomForm()
          //   : this.setState({ showPlanUpgradeNeededModal: true });
          this.addMailroomForm();
        } else {
          this.addMailroomForm();
        }
      }
    };

    const { showPlanUpgradeNeededModal, showTrailExpiredModal } = this.state;

    return (
      <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
        <Paper
          style={{
            height,
            maxHeight,
            marginBottom: 15,
          }}
          className="just-paper"
        >
          <div className="card-body">
            <SpaceBetween>
              <div className="bold-text mailroom-title">
                {t('tables.mailrooms')}
              </div>
              {!mailroomsLoading &&
                !mailroomsError &&
                (!buildingDetail ? (
                  <Tooltip title={t('buildings.saveBuildingFirst')}>
                    <Button className="mailroom-btn" role="presentation">
                      {t('buildings.addMailroom')}
                    </Button>
                  </Tooltip>
                ) : (
                  <>
                    <Button
                      className="mailroom-btn blue"
                      role="presentation"
                      // onKeyDown={() => handleAddMailroom()}
                      // onClick={() => handleAddMailroom()}
                      onKeyDown={handleAddMailroom}
                      onClick={handleAddMailroom}
                    >
                      {t('buildings.addMailroom')}
                    </Button>
                    <ConfrimModal
                      t={t}
                      open={this.state.openErrorModal}
                      buildingDetail={true}
                      handleClose={() =>
                        this.setState({ openErrorModal: false })
                      }
                    />
                  </>
                ))}
            </SpaceBetween>
            <Divider />
            {mailroomsError && (
              <VerticallyCenter>
                <AlignCenter>
                  <p>
                    <b>{t('common.error.networkError')}</b>{' '}
                  </p>
                </AlignCenter>
              </VerticallyCenter>
            )}
            {!mailroomsError && (
              <>
                <List className="mailrooms-heading">
                  <ListItem>
                    <ListItemText className="mailroom-cells uppsercase">
                      <div className="table-heading">{t('tables.name')}</div>
                    </ListItemText>
                    {/* <ListItemText className="mailroom-cells uppsercase">
                      <div className="table-heading">{t('tables.code')}</div>
                    </ListItemText> */}
                    <ListItemText className="mailroom-cells uppsercase">
                      <div
                        className="table-heading"
                        style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                      >
                        {t('tables.pendingPackages')}
                      </div>
                    </ListItemText>
                    <ListItemText className="mailroom-cells uppsercase">
                      <div className="table-heading">
                        {t('associates.associates')}
                      </div>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </List>
                {mailroomsLoading && (
                  <List style={{ ...style.common.o_h, width: '100%' }}>
                    {[1, 2, 3].map(() => (
                      <div>
                        <ListItem style={style.common.h_49}>
                          <ListItemText style={{ width: 85 }}>
                            <Skeleton width={100} />
                          </ListItemText>
                          {/* <ListItemText style={{ width: 85 }}>
                            <Skeleton width={100} />
                          </ListItemText> */}
                          <ListItemText style={{ width: 85 }}>
                            <Skeleton width={100} />
                          </ListItemText>
                          <ListItemText style={{ width: 85 }}>
                            <Skeleton width={100} />
                          </ListItemText>
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                  </List>
                )}
                {!mailroomsLoading && mailrooms.length > 0 && (
                  <List className="mailrooms-list">
                    {mailrooms.map((mailroom) => (
                      <div>
                        <ListItem style={style.common.h_49}>
                          <ListItemText className="mailroom-cells">
                            <div className="mailroom-data width-auto">
                              {mailroom.name}
                            </div>
                          </ListItemText>
                          {/* <ListItemText className="mailroom-cells">
                            <div className="mailroom-data width-auto">{mailroom.code}</div>
                          </ListItemText> */}
                          <ListItemText className="mailroom-cells">
                            <div className="mailroom-data width-auto">
                              <div
                                className={`mailroom-data ${
                                  width === 'xs' || 'sm'
                                    ? 'padding-left-20'
                                    : 'padding-left-50'
                                }`}
                              >
                                {mailroom.pending_packages_count || '  ---'}
                              </div>
                              {/* <Link
                              className={`mailroom-data ${
                                width === 'xs'
                                  ? 'padding-left-20'
                                  : 'padding-left-50'
                              }`}
                              to={`/packages/mailroom_packages/${mailroom.mailroom_id}/outstanding`}
                            >
                              {mailroom.pending_packages_count || '  ---'}
                            </Link> */}
                            </div>
                          </ListItemText>
                          <ListItemText className="mailroom-cells">
                            <div className="mailroom-data width-auto">
                              <Link
                                className="padding-left-10px"
                                to={`/associates/mailroom_associates/${mailroom.mailroom_id}`}
                              >
                                {mailroom.users_count ||
                                  t('associates.viewList')}
                              </Link>
                            </div>
                          </ListItemText>
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                  </List>
                )}
              </>
            )}
            {!mailroomsLoading && !mailroomsError && mailrooms.length === 0 && (
              <VerticallyCenter
                style={{
                  height:
                    !buildingDetail && width === 'lg' && width === 'xl' && 380,
                }}
              >
                <AlignCenter>
                  <p>{t('buildings.noMailroom')}</p>
                </AlignCenter>
              </VerticallyCenter>
            )}
          </div>
        </Paper>
        <PlanUpgradeNeeded
          type="Mailrooms"
          open={showPlanUpgradeNeededModal}
          onClose={this.closePlanUpgradeNeededModal}
          t={t}
        />
        <FreeTrailExpiredInternal
          type="users"
          userRole={this.props.userRole}
          open={showTrailExpiredModal}
          onClose={this.closeFreeTrialExpiredModal}
          t={t}
        />
      </Grid>
    );
  }
}

MailroomList.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  height: PropTypes.number.isRequired,
  maxHeight: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  mailrooms: PropTypes.node.isRequired,
  mailroomsLoading: PropTypes.bool.isRequired,
  mailroomsError: PropTypes.bool.isRequired,
  openCreateMailroomForm: PropTypes.func.isRequired,
  buildingDetail: PropTypes.bool.isRequired,
  buildings: PropTypes.bool.isRequired,
  userRole: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  buildings: state.allBuildings,
  userRole: state.userRole,
  userdetails: state.userDetails,
}))(withRouter(MailroomList));
