import { CSV_UPLOADING } from '../actions/csvActions';
import { informSlackDBCodeErrs } from '../../util/informSlack';

let initialState = false;

try {
  initialState = localStorage.uploading ? JSON.parse(localStorage.uploading) : false;
} catch (err) {
  informSlackDBCodeErrs(err);
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CSV_UPLOADING: {
      localStorage.uploading = action.uploading;
      state = action.uploading;
      return state;
    }
    default: return state;
  }
}
