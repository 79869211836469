/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React, { Fragment } from 'react';
import {
    Zoom,
    Divider,
    TableCell,
    Tooltip,
    IconButton,
    InputBase,
    InputAdornment
} from '@material-ui/core';
import { Cancel, Search } from "@material-ui/icons";
import PropTypes from 'prop-types';
import { Link, withRouter } from "react-router-dom";
import PaginationComponent from "../../../../shared/components/PaginationWithNumbers/components/paginationComponent";
import MaterialUITable from '../../../../shared/components/MaterialUITable/components/materialUITableComponent';
import { buildingsAPIExposeTableColumnHeader } from '../../../../util/application.utils';
import { getAllBuildingsExposeAPI } from '../../../../server/utils';
import { truncateLabel } from '../../../../util/utility.functions';
import Alert from '../../../UI/Poper';
import { sortByApiExposeBuildings } from '../../../../util/sortBy';

const BuildingsIconInactive = require('../../../Layout/sidebar/BuildingsIconInactive.png');

class BuildingListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copied: false,
            sortBy: 'name',
            sortType: true,
            activePage: 1,
            pagination_limit: 10,
            buildingsList: [],
            loading: true,
            error: null,
            searchedText: '',
            tableHeads: buildingsAPIExposeTableColumnHeader(props.t)
        };
    }

    componentDidMount() {
        this.getBuildings();
    }

    onPageChange = (currentActionPage) => {
        let action;
        if (currentActionPage === "next") {
            action = this.state.activePage + 1;
        } else if (currentActionPage === "previous") {
            action = this.state.activePage - 1;
        } else {
            action = currentActionPage;
        }
        this.setState({
            activePage: action,
            error: null,
            loading: true,
        }, () => this.getBuildings());
    };

    getBuildings = () => {
        const {
            sortBy,
            sortType,
            activePage,
            searchedText,
            pagination_limit
        } = this.state;
        const offset = activePage === 1 ? 0 : ((activePage - 1) * pagination_limit);
        getAllBuildingsExposeAPI(searchedText, pagination_limit, sortBy, sortType, offset)
            .then(res => {
                this.setState({
                    buildingsList: this.createRows(res),
                    loading: false,
                    error: null
                });
            })
            .catch(() => {
                this.setState({
                    error: 'Something went Wrong'
                });
            });
    }

    createRows = buildings =>
        buildings.map(building => ({
            logo: (
                <Link to={`/building_detail/${building.seq_id}`}>
                <img style={{ borderRadius: 5, height: 32, width: 32 }} src={BuildingsIconInactive} alt="" />
                </Link>
            ),
            id: building.building_id || "---",
            name:
                building.name.length > 20 ? (
                    <Tooltip TransitionComponent={Zoom} title={building.name}>
                        <Link to={`/building_detail/${building.seq_id}`}>
                            {truncateLabel(building.name) || "---"}
                        </Link>
                    </Tooltip>
                ) : (
                        <Link to={`/building_detail/${building.seq_id}`}>
                            {truncateLabel(building.name) || "---"}
                        </Link>
                    ),
            code: !building.code ? (
                <p> --- </p>
            ) : building.code.length > 20 ? (
                <Tooltip TransitionComponent={Zoom} title={building.code}>
                    <p> {truncateLabel(building.code)} </p>
                </Tooltip>
            ) : (
                        <p> {truncateLabel(building.code)} </p>
                    ),
            city: !building.city ? (
                <p> --- </p>
            ) : building.city.length > 20 ? (
                <Tooltip TransitionComponent={Zoom} title={building.city}>
                    <p> {truncateLabel(building.city)} </p>
                </Tooltip>
            ) : (
                        <p> {truncateLabel(building.city)} </p>
                    ),
            country: !building.country ? (
                <p> --- </p>
            ) : building.country.length > 20 ? (
                <Tooltip TransitionComponent={Zoom} title={building.country}>
                    <p> {truncateLabel(building.country)} </p>
                </Tooltip>
            ) : (
                        <p> {truncateLabel(building.country)} </p>
                    ),
            buildingsCount: building.building_count,
        }));

    closeAlert = () => {
        this.setState({
            error: null
        });
    }

    copyToClipboard = (buildingID) => {
        if (buildingID !== '---') {
            navigator.clipboard.writeText(buildingID);
            this.setState({
                copied: true
            });
        }
    }

    tableRows = (item) => {
        let tableRowCells = [];
        tableRowCells.push(<TableCell className="thumbnail-cell width-60px" style={{ cursor: "default" }}>
            {item.logo}
        </TableCell>);
        tableRowCells.push(<TableCell className="padding-right-left truncated-heading width-200px">
            <span> {item.name} </span>
        </TableCell>);
        tableRowCells.push(<TableCell className="padding-right-left truncated-heading width-200px">
            {item.code}
        </TableCell>);
        tableRowCells.push(
            item.id !== '---' ? (
                <Tooltip title={this.props.t('apiexpose.copyToClipboard')}>
                    <TableCell
                        className="padding-right-left truncated-heading width-200px"
                        onClick={() => this.copyToClipboard(item.id)}
                        style={{ cursor: 'pointer', color: '#027aff' }}
                    >
                        {item.id}
                    </TableCell>
                </Tooltip>
            ) : (
                    <TableCell
                        className="padding-right-left truncated-heading width-200px"
                        onClick={() => this.copyToClipboard(item.id)}
                        style={{ color: '#027aff' }}
                    >
                        {item.id}
                    </TableCell>
                )

        );
        return tableRowCells;
    }

    handleSortByColumn = (columnName) => {
        let { sortBy, sortType } = this.state;

        if (sortBy === columnName) {
            sortType = !sortType;
        } else {
            sortType = true;
        }

        this.setState({
            loading: true,
            sortBy: columnName,
            sortType,
        }, () => this.getBuildings());
    }

    handleSearchBarChange = e => {
        this.setState({
            searchedText: e.target.value.trimLeft()
        }, () => {
            if (this.state.searchedText === '') {
                this.handleClearSearchBar();
            }
        });
    }

    handleClearSearchBar = () => {
        this.setState({
            activePage: 1,
            loading: true,
            error: null,
            searchedText: ''
        }, () => {
            this.getBuildings();
        });
    }

    handleSearchBarSubmit = () => {
        this.setState({
            activePage: 1,
            loading: true,
            error: null,
        }, () => {
            this.getBuildings();
        });
    }

    closeAlert = () => {
        this.setState({
            copied: false
        });
    }

    render() {
        const { t, width } = this.props;
        const {
            error,
            sortBy,
            copied,
            loading,
            sortType,
            activePage,
            // tableHeads,
            searchedText,
            buildingsList,
            pagination_limit,
        } = this.state;
        let { tableHeads } = this.state;
        tableHeads = buildingsAPIExposeTableColumnHeader(t);

        return (
            <Fragment>
                {
                    copied && 
                    <Alert variant="success" open message={t('apiexpose.buildingIdCopied')} onClose={this.closeAlert} />
                }
                <div className="heading-search-container">
                    <h3 className="container-heading">{t('apiexpose.buildingsList')}</h3>
                    <div className="search-field">
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                if (!loading && searchedText.length !== 0) {
                                    this.handleSearchBarSubmit();
                                }
                            }}
                            className="width-100"
                        >
                            <InputBase
                                aria-haspopup="true"
                                className="topbar__search-field topbar__search-field--open search margin-4px fs-14px"
                                inputProps={{ style: { cursor: 'pointer', textOverflow: 'ellipsis' }, maxlength: 100 }}
                                value={searchedText}
                                onChange={this.handleSearchBarChange}
                                placeholder={t('members.searchBuilding')}
                                startAdornment={
                                    <Search
                                        style={{
                                            marginRight: window.screen.width < 500 ? 0 : 10,
                                            fontSize: 18,
                                        }}
                                    />
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        {this.state.searchedText.length > 0 ? (
                                            <IconButton
                                                style={{ padding: 14 }}
                                                onClick={this.handleClearSearchBar}
                                            >
                                                <Cancel className="cross-btn" />
                                            </IconButton>
                                        ) : (
                                                <div />
                                            )}
                                    </InputAdornment>
                                }
                            />
                        </form>
                    </div>
                </div>
                <Divider />
                <div className="table-contents-container">
                    {
                        !error &&
                        <MaterialUITable
                            t={t}
                            loading={loading}
                            view="list"
                            tableHeads={tableHeads}
                            tableData={buildingsList}
                            handleSwitchChange={this.handleSwitchChange}
                            handleSortByColumn={this.handleSortByColumn}
                            tableRows={this.tableRows}
                            sortBy={sortBy}
                            sortType={sortType}
                            message={!loading && !error && buildingsList.length === 0 && t('tables.noBuildingsToShow')}
                            error={error}
                            changeView={() => { }}
                            sortDataColumns={sortByApiExposeBuildings}
                        />
                    }
                    {
                        buildingsList && buildingsList.length > 0 && !error &&
                        <div className="table-pagination">
                            <PaginationComponent
                                width={width}
                                loading={loading}
                                totalItems={buildingsList[0].buildingsCount}
                                activePage={activePage}
                                itemsPerPage={pagination_limit}
                                availableItems={buildingsList.length}
                                onChange={this.onPageChange}
                                showLimit={false}
                            />
                        </div>
                    }
                </div>
            </Fragment>
        );
    }
}

BuildingListing.propTypes = {
    t: PropTypes.func.isRequired,
    width: PropTypes.string.isRequired,
};

export default (withRouter(BuildingListing));
