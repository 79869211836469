import React from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from './Styled';

function PaymentFailed(props) {
  const { t } = props;
  return (
    <div>
      <Dialog
        keepMounted
        maxWidth="sm"
        onClose={() => props.history.replace('/dashboard')}
        {...props}
      >
        <DialogContent className="pm-modal-container">
          <h3 className="pm-heading">
            {t('paymentModals.paymentFailed.headingNormal')}{' '}
            <span className="pm-heading-red">
              {t('paymentModals.paymentFailed.headingColored')}
            </span>
          </h3>
          <p className="pm-description">
            {t('paymentModals.paymentFailed.description')}{' '}
            <i>
              <b>‘support@packagex.io’</b>
            </i>
          </p>
          <div className="pm-single-button-section">
            <Button
              className="pm-cp-upgrade-btn"
              onClick={() => props.history.replace('/dashboard')}
            >
              {t('paymentModals.paymentFailed.button')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withRouter(PaymentFailed);
