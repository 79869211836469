/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  ListItemText,
  InputBase,
  List,
  ListItem,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Checkbox,
  ClickAwayListener,
  Popper,
  Menu,
  Divider,
  InputAdornment,
  IconButton,
  Button,
  Tooltip,
  Radio,
} from '@material-ui/core';
import { Search, CancelOutlined, ArrowDropDown } from '@material-ui/icons';
import { truncateLabel } from '../../../../util/utility.functions';

import { CustomInput } from '../../../UI/Input';
// import BlueButton from "../../../UI/BlueButton";
import {
  Truncate,
  Margin,
  AlignCenter,
  VerticallyCenter,
} from '../../../UI/ResponsiveRelated';

function BuildingSelector(props) {
  const {
    t,
    style,
    width,
    role,
    height,
    loading,
    anchorEl,
    mailroomAnchor,
    selectedBuildings,
    selectedMailrooms,
    loadingRoles,
    selectedRole,
    handleSearch,
    removeBuilding,
    searchedBuildings,
    openBuildingList,
    selectBuilding,
    openMailroomList,
    selectMailroom,
    handleClickAway,
    userRole,
    roleCode,
  } = props;

  let { buildings } = props;

  const newBuildings = [];
  buildings.map((item) => {
    item.buildings.map((eachBuilding) => {
      newBuildings.push(eachBuilding);
      return eachBuilding;
    });
    return item;
  });
  buildings = [...newBuildings];

  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
      <Paper
        className="paper"
        style={{
          height,
          overflow: 'auto',
          marginRight: width === 'xl' || width === 'lg' ? 0 : 10,
          marginBottom: 10,
          overflowX: '',
        }}
      >
        <div
          className="card-body"
          style={{
            overflowX: width === 'xl' || width === 'lg' ? 'hidden' : 'auto',
          }}
        >
          <div className="card__title business-selector-associate-top-display">
            <h5 className="bold-text">{t('buildings.info')}</h5>
            {selectedRole === 'Admin' || (userRole === roleCode && userRole !== "admin") ? (
              <div />
            ) : (
              <Button
                disabled={
                  (roleCode === userRole && userRole !== "admin") ||
                  loading ||
                  loadingRoles ||
                  selectedRole === 'Admin'
                }
                style={{ opacity: selectedRole === 'Admin' && 0.5 }}
                className="transparent-blue-button"
                onClick={(e) => openBuildingList(e)}
              >
                {t('buildings.chooseBuilding')}
              </Button>
            )}
            <Menu
              anchorEl={anchorEl}
              open={anchorEl}
              onClose={handleClickAway}
              PaperProps={{
                style: {
                  width: window.screen.width < 500 ? '80%' : '47%',
                  overflow: 'hidden',
                  borderRadius: 4,
                  height: 'auto',
                },
                elevation: 11,
              }}
              style={{ top: -7, left: -20 }}
            >
              <Margin bottom={5}>
                <form onSubmit={(e) => e.preventDefault()}>
                  <InputBase
                    required
                    autoFocus
                    style={{
                      ...style.common.w_100,
                      ...style.common.fs_12,
                    }}
                    placeholder={t('search.searchBuildings')}
                    onChange={handleSearch}
                    startAdornment={
                      <InputAdornment position="start" style={{ margin: 0 }}>
                        <IconButton
                          disableRipple
                          disableTouchRipple
                          focusRipple
                          type="submit"
                          style={{
                            ...style.colors.black,
                            paddingRight: 10,
                          }}
                        >
                          <Search className="font-size-12" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </form>
              </Margin>
              <Divider />
              <List className="height-235px overflowy-scroll overflowx-hidden">
                {searchedBuildings.length ? (
                  searchedBuildings.map((building) => (
                    <ListItem
                      button
                      className="padding-0 width-270px"
                      key={building.building_id}
                      disabled={loadingRoles}
                      onClick={() =>
                        selectBuilding('selectedBuildings', building)
                      }
                    >
                      {selectedRole.toLowerCase() === 'operator' ? (
                        <Radio
                          checked={
                            selectedBuildings &&
                            selectedBuildings
                              .map((element) => element.building_id)
                              .indexOf(building.building_id) > -1
                          }
                          className="blue"
                        />
                      ) : (
                        <Checkbox
                          checked={
                            selectedBuildings &&
                            selectedBuildings
                              .map((element) => element.building_id)
                              .indexOf(building.building_id) > -1
                          }
                          className="blue"
                        />
                      )}
                      <ListItemText
                        className="padding-0"
                        primary={
                          <Truncate
                            width={
                              window.screen.width < 321
                                ? 240
                                : window.screen.width < 769
                                  ? 260
                                  : '100%'
                            }
                          >
                            {building.building_name}
                          </Truncate>
                        }
                      />
                    </ListItem>
                  ))
                ) : (
                  <ListItem className="padding-10 width-270px" disabled>
                    <ListItemText
                      className="padding-10"
                      primary={
                        <Truncate
                          width={
                            window.screen.width < 321
                              ? 240
                              : window.screen.width < 769
                                ? 260
                                : '100%'
                          }
                        >
                          {/* No Building Found */}
                        </Truncate>
                      }
                    />
                  </ListItem>
                )}
              </List>
            </Menu>
          </div>
          <Table responsive className="table--bordered padding-10px">
            <TableHead>
              <TableRow style={{ height: 35 }}>
                <TableCell className="table-heading width-200px uppsercase padding-5px">
                  {t('tables.name')}
                </TableCell>
                <TableCell className="table-heading width-200px uppsercase padding-5px">
                  {t('tables.code')}
                </TableCell>
                <TableCell className="table-heading width-200px uppsercase padding-5px">
                  {t('tables.mailrooms')}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody style={{ overflowY: 'auto' }}>
              {selectedBuildings && selectedBuildings.map((building, index) => (
                <TableRow>
                  <TableCell className="fs-14px padding-5px width-200px truncated-heading">
                    {truncateLabel(building.building_name)}
                  </TableCell>
                  <TableCell className="fs-14px padding-5px">
                    {truncateLabel(building.building_code)}
                  </TableCell>
                  <TableCell className="padding-5px">
                    <FormControl style={style.common.h_18}>
                      <CustomInput
                        novalidate
                        required
                        readOnly="true"
                        className="fs-14px"
                        placeholder={t('tables.mailrooms')}
                        value={
                          selectedRole.toLowerCase() === 'operator'
                            ? selectedMailrooms && selectedMailrooms.map((mailroom, mailroomIndex) =>
                              mailroomIndex > 0
                                ? ' '.concat(mailroom.mailroom_name)
                                : mailroom.mailroom_name,
                            )
                            : building.mailrooms.map(
                              (mailroom) => mailroom.mailroom_name,
                            )
                        }
                        disabled={
                          selectedRole.toLowerCase() !== 'operator' ||
                          building.mailrooms.length === 1
                        }
                        onClick={
                          (userRole === roleCode && userRole !== "admin") ||
                            selectedRole.toLowerCase() === 'manager'
                            ? () => {}
                            : selectedRole.toLowerCase() === 'operator' &&
                              building.mailrooms.length > 1
                              ? (e) => openMailroomList(e)
                              : selectedMailrooms && selectedMailrooms.length > 1
                                ? (e) => openMailroomList(e)
                                : () => {}
                        }
                        style={{
                          fontSize: 12,
                          color: '#000',
                          width: 130,
                          padding: '0px 0px 1px 0px',
                        }}
                        endAdornment={
                          selectedRole.toLowerCase() === 'operator' &&
                            building.mailrooms.length !== 1 ? (
                            <InputAdornment position="end">
                              <IconButton className="padding-5px">
                                <ArrowDropDown className="dark-grey-color" />
                              </IconButton>
                            </InputAdornment>
                          ) : (
                            <div />
                          )
                        }
                      />
                      <Popper
                        anchorEl={mailroomAnchor}
                        open={mailroomAnchor}
                        placement="bottom-end"
                      >
                        <ClickAwayListener
                          onClickAway={() => openMailroomList()}
                        >
                          <Paper className="just-paper">
                            <List className="height-235px overflowy-scroll">
                              {/* {
                              } */}
                              {}
                              {/* {(buildings.length === 1 ? buildings[0] : (() => {
                                const currentBuilding = [...buildings].filter(item => item.building_id === building.building_id);
                                const bIndex = buildings.map(b => b.building_id).indexOf(building.building_id);
                                return buildings[bIndex];
                                return currentBuilding[0];
                              }) */}
                              {(buildings && buildings.length === 1
                                ? buildings[0]
                                : building
                              ).mailrooms.map((mlrm) => (
                                <ListItem
                                  button
                                  className="padding-0"
                                  onClick={
                                    building.mailrooms.length === 1
                                      ? () => {}
                                      : () =>
                                        selectMailroom(
                                          'selectedMailrooms',
                                          mlrm,
                                        )
                                  }
                                >
                                  {selectedMailrooms &&
                                    selectedMailrooms.length === 0 && (
                                      <Tooltip
                                        className="position-absolute height-25px"
                                        title={t(
                                          'buildings.mailroomMustBeSelected',
                                        )}
                                        PopperProps={{ disablePortal: true }}
                                      >
                                        <div className="tooltip-absolute height-25px" />
                                      </Tooltip>
                                    )}
                                  <Checkbox
                                    className="padding-5px blue"
                                    checked={
                                      selectedMailrooms &&
                                      selectedMailrooms
                                        .map((mailroom) => mailroom.mailroom_id)
                                        .indexOf(mlrm.mailroom_id) > -1
                                    }
                                  />
                                  <ListItemText primary={mlrm.mailroom_name} />
                                </ListItem>
                              ))}
                            </List>
                          </Paper>
                        </ClickAwayListener>
                      </Popper>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    {role !== 'Admin' && userRole !== roleCode ? (
                      <CancelOutlined
                        onClick={
                          role && role === 'Admin'
                            ? () => {}
                            : () => removeBuilding(index)
                        }
                        onKeyPress={
                          role && role === 'Admin'
                            ? () => {}
                            : () => removeBuilding(index)
                        }
                        style={{
                          ...style.colors.red,
                          ...style.common.fs_12,
                          cursor: role && role !== 'Admin' && 'pointer',
                          textOverflow: 'ellipsis',
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {!loading &&
                userRole !== roleCode &&
                selectedBuildings.length > 0 && (
                  <TableRow style={{ position: 'relative' }}>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        padding: '4px 4px 4px 4px',
                      }}
                    >
                      <FormControl>
                        <div
                          className="topbar__search"
                          style={{
                            width: '100%',
                            height: 'auto',
                            position: 'sticky',
                          }}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
          {selectedBuildings.length === 0 ? (
            <React.Fragment>
              <VerticallyCenter
                style={{
                  height: width === 'lg' && width === 'xl' && 380,
                }}
              >
                <AlignCenter>
                  <p>{t('buildings.noBuildingSelected')}</p>
                </AlignCenter>
              </VerticallyCenter>
            </React.Fragment>
          ) : null}
        </div>
      </Paper>
    </Grid>
  );
}

BuildingSelector.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  buildings: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
  selectedBuildings: PropTypes.node.isRequired,
  role: PropTypes.string.isRequired,
  selectedRole: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  handleSearch: PropTypes.func.isRequired,
  removeBuilding: PropTypes.func.isRequired,
  searchedBuildings: PropTypes.node.isRequired,
  selectedMailrooms: PropTypes.node.isRequired,
  loadingRoles: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  anchorEl: PropTypes.node.isRequired,
  mailroomAnchor: PropTypes.node.isRequired,
  openBuildingList: PropTypes.func.isRequired,
  selectBuilding: PropTypes.func.isRequired,
  openMailroomList: PropTypes.func.isRequired,
  selectMailroom: PropTypes.func.isRequired,
  handleClickAway: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  roleCode: PropTypes.string.isRequired,
};

export default BuildingSelector;
