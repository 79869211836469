/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PlusIcon from '@material-ui/icons/AddCircleOutline';
import { Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

class SimpleMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
    // eslint-disable-next-line no-unused-expressions
    () => this.props.handleRecent(event);
  };

  handleClose = e => {
    this.setState({ anchorEl: null });
    let tag = e.target.attributes.value.nodeValue.replace('+ ', '');
    this.props.getTag(tag);
  };

  render() {
    const { anchorEl } = this.state;
    const {
 topic, t, type, cat, recent 
} = this.props;
    let discardSingle;

    let discardMultiple;

    let pickupSingle;

    let pickupMultiple;

    let notifySingle;

    let notifyMultiple;
    discardMultiple = [
      {
        labelItem: 'Building',
        labels: t('email.building'),
      },
      {
        labelItem: 'Member Name',
        labels: t('email.memberName'),
      },
      {
        labelItem: 'Package count',
        labels: t('email.packageCount'),
      },
      {
        labelItem: 'Discard Reason',
        labels: t('email.discardReason'),
      },
    ];
    discardSingle = [
      {
        labelItem: 'Building',
        labels: t('email.building'),
      },
      {
        labelItem: 'Member Name',
        labels: t('email.memberName'),
      },
      {
        labelItem: 'Discard Reason',
        labels: t('email.discardReason'),
      },
    ];
    pickupMultiple = [
      {
        labelItem: 'Building',
        labels: t('email.building'),
      },
      {
        labelItem: 'Member Name',
        labels: t('email.memberName'),
      },
      {
        labelItem: 'Package count',
        labels: t('email.packageCount'),
      },
      {
        labelItem: 'Pickup Name',
        labels: t('email.packageName'),
      },
    ];
    pickupSingle = [
      {
        labelItem: 'Building',
        labels: t('email.building'),
      },
      {
        labelItem: 'Member Name',
        labels: t('email.memberName'),
      },
      {
        labelItem: 'Pickup Name',
        labels: t('email.packageName'),
      },
    ];
    notifySingle = [
      {
        labelItem: 'Building',
        labels: t('email.building'),
      },
      {
        labelItem: 'Member Name',
        labels: t('email.memberName'),
      },
    ];
    notifyMultiple = [
      {
        labelItem: 'Building',
        labels: t('email.building'),
      },
      {
        labelItem: 'Member Name',
        labels: t('email.memberName'),
      },
      {
        labelItem: 'Package count',
        labels: t('email.packageCount'),
      },
    ];

    let tags =
      topic === t('email.discard')
        ? type === 'multiple'
          ? discardMultiple
          : discardSingle
        : topic === t('email.pickup')
        ? type === 'multiple'
          ? pickupMultiple
          : pickupSingle
        : type === 'multiple'
        ? notifyMultiple
        : notifySingle;
    if (
      cat === 'normal' ||
      (cat === 'belongingTo' && (recent === 'title' || recent === 'subject'))
    ) {
      pickupMultiple.pop();
      pickupSingle.pop();
    }
    return (
      <div>
        <div>
          <div
            style={{
              fontSize: 12,
              alignItems: 'center',
              display: 'flex',
              color: 'grey',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={this.handleClick}
          >
            <PlusIcon style={{ fontSize: 17 }} />
          </div>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {tags.map(tag => (
            <MenuItem
              style={{ color: 'black' }}
              onClick={this.handleClose}
              value={tag.labelItem}
            >
              {`+ ${tag.labels}`}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

SimpleMenu.propTypes = {
  t: PropTypes.func.isRequired,
  getTag: PropTypes.func.isRequired,
  handleRecent: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  recent: PropTypes.string.isRequired,
  cat: PropTypes.string.isRequired,
};

export default SimpleMenu;
