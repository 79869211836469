import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from './Styled';

function PlanUpgradeNeeded(props) {
  const { type, t } = props;
  const closeModal = () => {
    props.onClose(null);
  };
  return (
    <div>
      <Dialog keepMounted maxWidth="sm" onClose={closeModal} open={props.open}>
        <DialogContent className="pm-modal-container">
          <h3 className="pm-heading">
            {t('paymentModals.planUpgradeNeeded.headingNormal1')}{' '}
            <span className="pm-heading-red">
              {t('paymentModals.planUpgradeNeeded.headingColored')}
            </span>{' '}
            {t('paymentModals.planUpgradeNeeded.headingNormal2')}
          </h3>
          <p className="pm-description">
            {t('paymentModals.planUpgradeNeeded.description1')}{' '}
            {type.toLowerCase()}{' '}
            {t('paymentModals.planUpgradeNeeded.description2')}{' '}
            {type.toLowerCase()},{' '}
            {t('paymentModals.planUpgradeNeeded.description3')}
          </p>
          <div className="pm-button-section">
            <Button
              className="pm-cp-upgrade-btn"
              onClick={() => {
                props.history.push('/manage-plan');
              }}
            >
              {t('paymentModals.freeTrialExpired.viewPlan')}
            </Button>
            <Button
              className="pm-cp-downgrade-btn"
              onClick={closeModal}
            >
              {t('common.continue')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

PlanUpgradeNeeded.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  history: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
};
export default withRouter(PlanUpgradeNeeded);
