/* eslint-disable */

import React, { Component } from 'react';
import styled from 'styled-components';
import { Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { encryptEmailWithAES } from '../../../../util/application.utils';
import { CallTrigger } from '../../../../util/Triggers/function';
import { CLA } from '../../../../util/Triggers/triggerNames';
import { onBoardingScreens } from '../../../../util/application.utils';

class PendingTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  // Creates encrypted url through email and redirects user
  getUrl = (email, userName, history) => {
    const recipientName = new Array(userName);
    const recipientEmail = new Array(email);
    localStorage.setItem('userName', recipientName[0]);
    localStorage.setItem(
      'onBoarding_email',
      email
    );
    localStorage.setItem('recipientsNames', JSON.stringify(recipientName));
    localStorage.setItem('recipientsEmails', JSON.stringify(recipientEmail));
    encryptEmailWithAES(email).then((res) => {
      history.push(`/onboarding?email=${res}`);
    });
  };

  render() {
    const { step, userEmail, userName, csv } = this.props;
    const TOTAL_STEPS = 10;
    const calcStep = parseInt(step) + 1;
    const remainingSteps = TOTAL_STEPS - calcStep;
    return !csv || remainingSteps - 1 > 0 ? (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '70px',
          marginBottom: '10px',
        }}
      >
        <Container>
          <Single heading>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {remainingSteps > 0 ? (
                <>
                  <Circle>{remainingSteps}</Circle>
                  <PointCursor
                    onClick={() => {
                      CallTrigger(CLA.DASHBOARD.Dashboard_Pending_Task_bclick, {
                        Previous_event: '',
                        clicked_pending_task: onBoardingScreens[step],
                      });
                      this.getUrl(userEmail, userName, this.props.history);
                    }}
                  >
                    {this.props.t('main.youHave')} {remainingSteps}{' '}
                    {this.props.t('main.pendingTasks')}
                  </PointCursor>
                </>
              ) : (
                <PointCursor
                  onClick={() => {
                    CallTrigger(CLA.DASHBOARD.Dashboard_Pending_Task_bclick, {
                      Previous_event: '',
                      clicked_pending_task: 'CSV_Upload',
                    });
                    this.props.history.push('/upload_csv');
                  }}
                >
                  {this.props.t('main.uploadCsvInfo')}
                </PointCursor>
              )}
            </div>

            {remainingSteps > 0 && !csv && (
              <Arrow
                onClick={() => {
                  CallTrigger(
                    CLA.DASHBOARD.Dashboard_Pending_Task_Drop_Down_bclick,
                    { Previous_event: '' }
                  );
                  this.setState((prev) => {
                    return {
                      open: !prev.open,
                    };
                  });
                }}
              >
                {this.state.open ? <ExpandLess /> : <ExpandMore />}
              </Arrow>
            )}
          </Single>

          <Collapse in={this.state.open} timeout="auto">
            <Single>
              <PointCursor
                onClick={() => {
                  CallTrigger(CLA.DASHBOARD.Dashboard_Pending_Task_bclick, {
                    Previous_event: '',
                    clicked_pending_task: 'CSV_Upload',
                  });
                  this.props.history.push('/upload_csv');
                }}
              >
                {this.props.t('main.uploadCsvInfo')}
              </PointCursor>
              <ArrowForwardIosIcon
                style={{ opacity: '0.3', fontSize: '15px', cursor: 'pointer' }}
                onClick={() => {
                  CallTrigger(CLA.DASHBOARD.Dashboard_Pending_Task_bclick, {
                    Previous_event: '',
                    clicked_pending_task: 'CSV_Upload',
                  });
                  this.props.history.push('/upload_csv');
                }}
              />
            </Single>
          </Collapse>
        </Container>
      </div>
    ) : (
      <></>
    );
  }
}

const Container = styled.div`
  width: 100%;
  padding: 0 1.5rem;
  background-color: #fff;
  box-shadow: 0 0 5px #eee;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  border-left: 4px solid #ff3030;
  position: absolute;
  z-index: 1;
  // border-bottom:1px solid #ccc;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 3px 1px -2px rgb(0 0 0 / 12%);
`;
const Circle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffd7d7;
  border: 1.5px solid #ff3030;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #ff3030;
  margin-right: 1rem;
`;
const Single = styled.div`
  border-top: ${(props) => (props.heading ? '' : '1px solid #ccc')};
  padding: 1.5rem 0;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
`;
const Arrow = styled.div`
  opacity: 0.3;
  cursor: pointer;
`;

const PointCursor = styled.div`
  cursor: pointer;
`;

PendingTasks.propTypes = {
  history: PropTypes.node.isRequired,
};

export default withRouter(PendingTasks);
