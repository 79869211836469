import axios from 'axios';
import { server } from './server';

const CancelToken = axios.CancelToken;
let cancel;

export function createAssociate(associate) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/entity/user`, associate)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}


export function deleteUserAssociate(id, tenant) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${server}/entity/user/${id}?tenant=${tenant}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export function getAssociates(
  limit,
  offset,
  sortBy,
  sortType,
  buildingId = null,
  mailroomId = null,
) {
  return new Promise((resolve, reject) => {
    if (cancel) cancel();
    const params = {
      pagination_limit: limit,
      include_mailrooms: true,
      include_users_thumbnail: true,
      order_by: sortBy,
      order_asc: sortType,
      building_id: buildingId,
      mailroom_id: mailroomId,
    };
    if (offset || offset !== 0) params.pagination_offset = offset;
    axios
      .get(`${server}/entity/user`, { params, cancelToken: new CancelToken(c => { cancel = c; }) })
      .then(response => resolve(response.data))
      .catch(error => {
        if (!axios.isCancel(error)) reject(error);
      });
  });
}

export function getAssociate(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/entity/user/${id}?include_mailrooms=true`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function updateAssociate(id, associate) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${server}/entity/user/${id}`, associate)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export function deleteAssociate(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${server}/entity/user/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}
