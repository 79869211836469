import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Alert from '../../containers/UI/Poper';

export default class AlertComponent extends PureComponent {
  static propTypes = {
    variant: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    onClose: () => {},
  };

  constructor() {
    super();

    this.state = {
      open: true,
    };
  }

  onShow = () => {
    this.setState({ open: true });
  };

  onDismiss = () => {
    this.setState({ open: false });
    this.props.onClose();
  };

  render() {
    const { variant, message } = this.props;

    return (
      <Alert
        onClose={this.onDismiss}
        variant={variant}
        message={message}
        open={this.state.open}
      />
    );
  }
}
