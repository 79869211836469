import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import { CustomizerProps } from '../../shared/prop-types/ReducerProps';
// todo: delete ym

class MainWrapper extends Component {
  static propTypes = {
    customizer: CustomizerProps.isRequired,
    children: PropTypes.element.isRequired,
  };

  componentDidMount() {
    this.initializeReactGA();
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidUpdate() {
    this.initializeReactGA();
  }
  initializeReactGA = () => {
    const env = process.env.REACT_APP_ENV;
    if (env && env === 'wework') {
      ReactGA.initialize('UA-145339592-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    } else if (env && env === 'wework_cn') {
      ReactGA.initialize('UA-145390255-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    } else if (env && env === 'qa') {
      ReactGA.initialize('UA-145368792-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    } else if (env && env === 'qa_cn') {
      ReactGA.initialize('UA-145408654-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    } else if (env && env === 'dev') {
      ReactGA.initialize('UA-145388357-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    } else if (env && env === 'dev_cn') {
      ReactGA.initialize('UA-145413484-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  };

  render() {
    const { customizer } = this.props;

    const wrapperClass = classNames({
      wrapper: true,
      'squared-corner-theme': customizer.squaredCorners,
      'blocks-with-shadow-theme': customizer.withBoxShadow,
      'top-navigation': customizer.topNavigation,
    });

    return (
      <div className="theme-light">
        <div className={wrapperClass}>{this.props.children}</div>
      </div>
    );
  }
}

export default connect(state => ({
  customizer: state.customizer,
}))(withRouter(MainWrapper));
