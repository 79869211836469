import React from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { CircularProgress } from '@material-ui/core';
import { Button } from './Styled';

function AccountDeletion(props) {
  const { t } = props;
  return (
    <div>
      <Dialog keepMounted maxWidth="sm" {...props}>
        <DialogContent className="pm-modal-container">
          <h3 className="pm-heading">
            {t('paymentModals.accountDeletion.headingNormal')}{' '}
            <span className="pm-heading-red">
              {t('paymentModals.accountDeletion.headingColored')}
            </span>
          </h3>
          <p className="pm-description">
          Your account deactivation process has begun, you are free to use PackageX Mailroom until your current plan subscription ends. Your account shall be deactivated after that and all users will be logged out. Logging back in after that shall reactivate your account. Please contact customer support in case of any further queries at 
            <i>
              <b>‘support@packagex.io’</b>
            </i>
          </p>
          <div className="pm-single-button-section">
            <Button
              className="pm-cp-downgrade-btn"
              onClick={() => props.onClose()}
            >
              {/* {t('paymentModals.accountDeletion.button')}{' '} */}
              Okay
              {props.deleteAccountLoader && (
                <span className="ml-2">
                  <CircularProgress size={15} className="white" />
                </span>
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withRouter(AccountDeletion);
