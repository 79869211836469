/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddBusinessComp from './components/AddBusiness';
import { Margin, SpaceBetween } from '../../UI/ResponsiveRelated';
import Alert from '../../UI/Poper';
import WarningAlert from '../../../shared/components/Alert';
import Footer from "../../UI/Footer/Footer";
import TenantSelector from '../TenantSelector';
import { validateBusinessName } from '../../../util';
import { checkAWSSession } from '../../../util/application.utils';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

class AddMember extends Component {
  constructor(props) {
    super(props);
    const { building } = props;
    this.state = {
      name: '',
      email: '',
      phone: '',
      office: '',
      note: '',
      address: '',
      selectedBuilding: building.length > 0 ? building[0] : null,
      alternativeName: [''],
      addingAltName: true,
      redirect: false,
      businessCreated: false,
      businessCreating: false,
      errorCreating: null,
      phoneError: false,
      emailError: false,
      nameError: false,
      addressError: false,
      officeError: false,
      disableSubmit: true,
      warning: null,
      selectedTenant: null,
      encryptedEmail: null,
    };
  }

  componentDidMount() {
    checkAWSSession(res => {
      if (res) {
        this.setState({
          encryptedEmail: `/onboarding?email=${res}`
        });
      }
      this.interval = setInterval(() => {
        const { buildings } = this.props;
        if (buildings.length !== 0) {
          const { tenant_name, tenant_id } = buildings[0];
          this.setState({
            selectedTenant: {
              tenant_name,
              tenant_id,
            }
          });
          clearInterval(this.interval);
        }
      }, 50);
    });
  }

  setBuildings = buildings => this.setState({ buildings });

  setAlternativeName = index => event => {
    const { alternativeName } = this.state;
    alternativeName[index] = event.target.value.trimLeft();
    this.setState({
      alternativeName,
      addingAltName: !this.state.addingAltName,
    });
  };

  handleNotes = notes => this.setState({ note: notes });

  selectBuilding = selectedBuilding => {
    this.setState({ selectedBuilding });
  };

  disableSubmit = () => {
    const { validateEmail, generalValidation } = this.props;
    const { email, name, address, office } = this.state;
    this.setState({
      emailError: email.length > 0 ? !validateEmail(email) : false,
      nameError: name.length > 0 ? !validateBusinessName(name.trim()) : false,
      addressError: address.length > 0 ? !generalValidation(address) : false,
      officeError: office.length > 0 ? !generalValidation(office) : false,
    },
      () => {
        const { emailError, phoneError, selectedBuilding, nameError, addressError, officeError } = this.state;
        if (name.length < 2 ||
          emailError ||
          nameError ||
          addressError ||
          officeError ||
          phoneError ||
          !selectedBuilding
        ) {
          this.setState({ disableSubmit: true });
        } else this.setState({ disableSubmit: false });
      });
  };

  handleChange = name => event => {
    const value = event.target.value;
    this.setState(
      {
        [name]: typeof value === 'string' ? value.trimLeft() : value,
        warning: null,
      },
      () => this.disableSubmit(),
    );
  };

  handlePhone = (name, phone) => {
    if (phone.length === 0) this.setState({ phoneError: false });
    if (phone.length < 8 && phone.length > 2) {
      this.setState({ phone, phoneError: true });
    } else if (phone.length < 3) {
      this.setState({ phone, phoneError: false });
    } else {
      phone = `+${phone}`;
      try {
        this.closeAlert();
        const phoneObj = phoneUtil.parse(phone);
        const phoneError = !phoneUtil.isValidNumber(phoneObj);
        this.setState({ phone, phoneError }, () => this.disableSubmit());
      } catch (err) {
        this.setState({ phone, phoneError: true }, () =>
          this.disableSubmit(),
        );
      }
    }
  };

  handleBuildingChange = (name, value) => this.setState({ [name]: value });

  saveBusiness = () => {
    const {
      name,
      email,
      phone,
      office,
      note,
      address,
      selectedBuilding,
      emailError,
      nameError,
      addressError,
      officeError,
      phoneError,
      alternativeName,
    } = this.state;
    const { t, addBusiness } = this.props;
    const filteredAltNamed = alternativeName.filter(
      val => val !== (null || undefined || ''),
    );

    const alternateNameExists = filteredAltNamed.filter(
      item => item.trim().toLowerCase() === name.trim().toLowerCase(),
    );

    const alternateFilteredAltNamedExists = filteredAltNamed.filter(item => {
      const altData = filteredAltNamed.map(tempItem => tempItem.toLowerCase());
      return altData.indexOf(item.toLowerCase()) !== altData.lastIndexOf(item.toLowerCase());
    });

    const warningText =
      name.length === 0
        ? 'common.error.noEmptyName'
        : name.length < 2
          ? 'common.error.nameLength'
          : emailError
            ? 'emailValidation'
            : nameError
              ? t('common.error.nameValidation')
              : addressError
                ? t('common.error.addressValidation')
                : officeError
                  ? t('common.error.officeValidation')
                  : phoneError
                    ? 'phoneValidation'
                    : !selectedBuilding
                      ? 'buildingValidation4'
                      : '';
    if (alternateNameExists.length !== 0) {
      this.setState({
        warning: t('common.error.nameAndBusinessAlias'),
      });
    } else if (alternateFilteredAltNamedExists.length !== 0) {
      this.setState({
        warning: t('common.error.businessAliasDuplicate'),
      });
    } else if (
      name.length >= 2 &&
      selectedBuilding &&
      !emailError &&
      !nameError &&
      !addressError &&
      !officeError &&
      !phoneError
    ) {
      const filtertedAltNames = alternativeName.filter(
        altName => altName.length > 0 && altName,
      );
      let tempPhone = '';
      if (phone && phone !== [] && phone.toString().length > 2) {
        tempPhone = '+'.concat(phone.replace(/[^\d]/g, ''));
      }
      const business = {
        name,
        email,
        phone: tempPhone,
        office,
        note,
        address,
        alternate_name: filtertedAltNames,
        building_id: selectedBuilding.building_id,
      };
      if (note.length === 0) delete business.note;
      if (email.length === 0) delete business.email;
      if (filtertedAltNames.length === 0) delete business.alternate_name;
      if (!business.phone && business.phone.length === 0) delete business.phone;
      if (office.length === 0) delete business.office;
      if (phone.length < 10) delete business.phone;
      if (address.length === 0) delete business.address;
      this.setState({
        businessCreating: true,
        errorCreating: null,
        disableSubmit: true,
        warning: null,
      });

      addBusiness(business)
        .then(() =>
          this.setState({
            businessCreated: true,
            businessCreating: false,
            disableSubmit: false,
          }),
        )
        .catch(err => {
          let errorMessage = err.response ? err.response.data.message : err.message;
          let error = errorMessage.includes(
            'child "alternate_name" fails because ["alternate_name" position 1 contains a duplicate value',
          )
            ? t('common.error.busalia')
            : errorMessage.includes("Invalid Phone Number")
              ? t("phoneValidation") :
              errorMessage.includes('Name/Alternate Name cannot be same')
                ? t('common.error.nameAndAltname')
                : errorMessage.includes('Invalid Phone Number')
                  ? errorMessage : errorMessage.includes('Business name already exists') ? t('members.businessAlreadyExists')
                    : t('common.error.networkError');
          this.setState({
            errorCreating: error,
            businessCreating: false,
            disableSubmit: false,
          });
        });
    } else this.setState({ warning: t(warningText) });
  };

  closeAlert = () => {
    if (this.state.businessCreated) this.setState({ redirect: true });
    this.setState({ warning: null, errorCreating: null });
  };

  addInputField = () => {
    const { alternativeName } = this.state;
    alternativeName.push('');
    this.setState({
      alternativeName,
      addingAltName: !this.state.addingAltName,
    });
  };

  removeInputField = index => {
    const { alternativeName } = this.state;
    alternativeName.splice(index, 1);
    this.setState({
      alternativeName,
      addingAltName: !this.state.addingAltName,
    });
  };

  handleTenantChange = e => {
    const value = JSON.parse(e.target.value);
    this.setState({
      selectedTenant: {
        tenant_name: value.tenant_name,
        tenant_id: value.tenant_id,
      }
    });
  }

  render() {
    const {
      redirect,
      errorCreating,
      businessCreated,
      warning,
      selectedTenant,
      encryptedEmail,
    } = this.state;
      if (encryptedEmail) {
        this.props.history.replace(encryptedEmail);
      }
    const { t, style, building, buildings, userRole } = this.props;

    const tenants = buildings.map(eachTenant => {
      const { tenant_name, contact_email, tenant_id } = eachTenant;
      return {
        contact_email,
        tenant_id,
        tenant_name
      };
    });

    if (redirect) return <Redirect to="/members/businesses" />;
    return (
      <Fragment>
        <div>
          <Grid container className="container min-width-100p">
            {errorCreating && (
              <Alert
                variant="error"
                open
                message={errorCreating}
                onClose={this.closeAlert}
              />
            )}
            {businessCreated && (
              <Alert
                variant="success"
                open
                message={t('members.businessCreated')}
                onClose={this.closeAlert}
              />
            )}
            <Grid item sm={12} md={12} lg={12}>
              <SpaceBetween style={style.common.mb_10}>
                <div>
                  <div style={{ display: 'flex' }}>
                    <h3 style={{ marginRight: 10, width: 140 }} className="page-title">{t('common.addBusiness')}</h3>
                    {
                      (userRole === 'super_admin' && building.length === 0) &&
                      <TenantSelector
                        t={t}
                        tenants={tenants}
                        selectedTenant={selectedTenant}
                        handleOnchange={this.handleTenantChange}
                      />
                    }
                  </div>
                  <h3 className="page-subhead subhead">
                    <Link to="/members/businesses">{t('tables.business')}</Link>
                    <ArrowRightIcon />
                    {t('common.addBusiness')}
                  </h3>
                </div>
              </SpaceBetween>
              <div>
                {warning && (
                  <Margin right={20} left={20}>
                    <WarningAlert
                      variant="error"
                      onClose={this.closeAlert}
                      message={warning}
                      open
                    />
                  </Margin>
                )}
              </div>
            </Grid>
            <AddBusinessComp
              {...this.state}
              {...this.props}
              selectedTenant={selectedTenant}
              handleChange={this.handleChange}
              handleBuildingChange={this.handleBuildingChange}
              setBuildings={this.setBuildings}
              handlePhone={this.handlePhone}
              addInputField={this.addInputField}
              setAlternativeName={this.setAlternativeName}
              removeInputField={this.removeInputField}
              saveBusiness={this.saveBusiness}
              selectItem={item => this.selectBuilding(item)}
              handleNotes={this.handleNotes}
            />
          </Grid>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default connect(state => ({
  building: state.locationFilter,
  buildings: state.allBuildings,
  userRole: state.userRole,
}))(withRouter(AddMember));
