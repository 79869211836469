/* eslint-disable object-curly-newline,eqeqeq */
/* eslint-disable function-paren-newline */

/* eslint-disable no-plusplus */

/* eslint-disable */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import { Redirect } from 'react-router-dom';
import { Grid, withWidth } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// import ScanDetails from './components/ScanDetails';
import HeldPackages from './components/HeldPackages';
import BuildingToggle from '../BuildingToggle';
import CardWithBars from './components/CardWithBars';
import { WorldMap } from './components/WorldMap';
import buildings from './buildings.json';
import BuildingBarChart from './components/BuildingBarChart';
import browser from '../../../util/getBrowserInfo';
import { resolveSafari } from '../../../util/resolveSafari';
import { setScanFilter } from '../../../redux/actions/scanFilterActions';
import Footer from '../../UI/Footer/Footer';
import {
  getCourierAnalytics,
  getPackageAnalytics,
  getCategoryAnalytics,
  getPackageScanDetail,
} from '../../../server';
import { setCountries } from '../../../redux/actions/countriesActions';
import { getBusySlotsData } from '../../../server/packages';
import {
  daysLabels,
  hoursLabels,
  GetMonthName,
  chartFilterOptions,
  checkAWSSession,
} from '../../../util/application.utils';
import ScanDetailsStats from './components/ScanDetailsStats';
import ScanDetailsGraph from './components/ScanDetailsGraph';
import Alert from '../../UI/Poper';
import NewUserModal from './components/NewUserModal';
import TrialModal from './components/TrailModal';
import PendingTasks from './components/PendingTasks';
import { CallTrigger } from '../../../util/Triggers/function';
import { CLA } from '../../../util/Triggers/triggerNames';
import { signout } from '../../../server/auth';
import InvalidAccount from './components/InvalidAccount';

const moment = extendMoment(originalMoment);

class DefaultDashboard extends Component {
  state = {
    scanDetailLoading: false,
    scanDetails: this.props.scanFilter.filter,
    scanDetailError: null,
    scanFilter: this.props.scanFilter.filter,
    buildingChartFilter: 0,
    loadingBuildingChartTop: true,
    loadingBuildingChartBottom: true,
    buildingChartFilterErrorTop: '',
    buildingChartFilterErrorBottom: '',
    buildingChartFilterDataTop: [],
    buildingChartFilterDataBottom: [],
    buildingScanCountTop: '',
    buildingScanCountBottom: '',
    topScanFrom: moment(this.props.scanFilter.topScanningFrom),
    topScanTo: moment(this.props.scanFilter.topScanningTo),
    leastScanFrom: moment(this.props.scanFilter.leastScanningFrom),
    leastScanTo: moment(this.props.scanFilter.leastScanningTo),
    mapFrom: moment(this.props.scanFilter.mapFrom),
    mapTo: moment(this.props.scanFilter.mapTo),
    courierFrom: this.props.scanFilter.courierFrom,
    courierTo: this.props.scanFilter.courierTo,
    categoryFrom: moment(this.props.scanFilter.categoryFrom),
    categoryTo: moment(this.props.scanFilter.categoryTo),
    chartFilter: this.props.scanFilter.graph,
    scanDetailGraphLoading: true,
    scanDetailsGraph: {},
    scanDetailsGraphError: null,
    packageLabelTotal: '',
    courierTotal: '',
    heldPkgLoading: true,
    heldPkgs: {},
    heldPkgError: null,
    courierStatsLoading: true,
    courierStats: {},
    courierPercentages: [],
    courierStatsError: null,
    categoryAnalyticsLoading: true,
    categoryAnalytics: {},
    categoryPercentages: [],
    categoryAnalyticsError: null,
    categoryTotal: 0,
    locationsLoading: true,
    locations: [],
    locationsError: null,
    selectedCountry: null,
    busySlotsAnchor: null,
    selectedOptionBusySlots: 'Select',
    busySlotsData: [],
    error: null,
    encryptedEmail: null,
    scanLimitReachedFlag: true,
    openNewUserModal: localStorage.getItem('newUserSignUp')
      ? localStorage.getItem('newUserSignUp')
      : false,
    openTrialUserModal: localStorage.getItem('trialUser')
      ? localStorage.getItem('trialUser')
      : false,
    invalidUser: false,
  };

  componentDidMount() {
    checkAWSSession((res) => {
      if (res) {
        this.setState({
          encryptedEmail: `/onboarding?email=${res}`,
        });
      }
      if (browser.name === 'Safari') {
        resolveSafari();
      }
      const { userRole, building } = this.props;
      if (
        (userRole === 'admin' || userRole === 'super_admin') &&
        building.length === 0
      ) {
        this.getLocationNBuildingData(this.props);
      }
      this.getBuildings();
      if (userRole) {
        this.changeFilter(this.props.scanFilter.filter);
        this.chartFilter(this.props.scanFilter.graph);
        this.getCourierAnalytics(
          {
            start: this.state.courierFrom,
            end: this.state.courierTo,
          },
          true
        );
        this.getCategoryAnalytics({
          start: this.state.categoryFrom,
          end: this.state.categoryTo,
        });
        this.getPackageAnalytics();
      }
    });
    CallTrigger(CLA.DASHBOARD.Dashboard_Screen, { Previous_event: '' });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps !== prevState.props) {
      return true;
    }
  }

  componentDidUpdate(prevProps) {
    const { userRole, building, scanFilter } = this.props;
    if (prevProps.userRole !== this.props.userRole && this.props.userRole === 'sales_admin') {
      this.setState({
        invalidUser: true
      });
    }
    if (prevProps.userRole !== userRole && !this.state.scanDetailLoading) {
      if (userRole === 'admin' || userRole === 'super_admin') {
        this.getLocationNBuildingData();
      } else {
        this.getData(this.props);
        this.getCourierAnalytics(
          {
            start: this.state.courierFrom,
            end: this.state.courierTo,
          },
          true
        );
        this.getCategoryAnalytics({
          start: this.state.categoryFrom,
          end: this.state.categoryTo,
        });
        this.getPackageAnalytics();
      }
    } else if (
      prevProps.building !== building &&
      userRole &&
      userRole !== 'mailroom_supervisor' &&
      !this.state.scanDetailLoading
    ) {
      this.changeFilter(scanFilter.filter);
      this.chartFilter(scanFilter.graph);
      this.getCourierAnalytics(
        {
          start: this.state.courierFrom,
          end: this.state.courierTo,
        },
        true
      );
      this.getCategoryAnalytics({
        start: this.state.categoryFrom,
        end: this.state.categoryTo,
      });
      this.getPackageAnalytics();
    }
    if (
      prevProps.userRole !== userRole &&
      (userRole === 'admin' || userRole === 'super_admin') &&
      building.length === 0
    ) {
      this.getLocationNBuildingData(this.props);
    } else if (
      prevProps.building.length > 0 &&
      this.props.building.length === 0 &&
      (userRole === 'admin' || userRole === 'super_admin')
    ) {
      this.getLocationNBuildingData(this.props);
    }
    if (userRole !== 'super_admin') {
      if (prevProps.userDetails !== this.props.userDetails) {
        const { userDetails } = this.props;
        if (
          userDetails &&
          userDetails.buildings &&
          userDetails.buildings[0] &&
          userDetails.buildings[0].tier &&
          userDetails.buildings[0].tier === 'bronze'
        ) {
          localStorage.removeItem('trialUser');
          this.setState({
            openTrialUserModal: false,
          });
        }
      }
    }
  }

  getLocationNBuildingData = () => {
    const {
      topScanFrom,
      topScanTo,
      leastScanFrom,
      leastScanTo,
      mapFrom,
      mapTo,
      courierFrom,
      courierTo,
      categoryFrom,
      categoryTo,
    } = this.state;
    this.getMap({ start: mapFrom, end: mapTo });
    this.getData(this.props);
    this.scanningBuildings(false, { start: topScanFrom, end: topScanTo });
    this.scanningBuildings(true, { start: leastScanFrom, end: leastScanTo });
    this.getPackageAnalytics();
    this.getCourierAnalytics(
      {
        start: courierFrom,
        end: courierTo,
      },
      true
    );
    this.getCategoryAnalytics({
      start: categoryFrom,
      end: categoryTo,
    });
  };


  getBuildings = () => {
    let {
      getBuildingHierarchy, setcountry,
    } = this.props;
    getBuildingHierarchy()
      .then(locations => {
        const { countries } = locations;
        setcountry(countries);
      });
  }

  getPackageAnalytics = () => {
    this.setState({
      heldPkgLoading: true,
      heldPkgs: {},
      heldPkgError: null,
    });
    const { building, t } = this.props;
    let buildingId;
    if (building.length > 0) buildingId = building[0].building_id;
    getPackageAnalytics(buildingId)
      .then((heldPkgs) => this.setState({ heldPkgs, heldPkgLoading: false }))
      .catch((error) =>
        this.setState({
          heldPkgLoading: false,
          heldPkgError: error.response
            ? error.response.data.message
            : t('common.error.networkError'),
        })
      );
  };

  getCourierAnalytics = (range) => {
    const { building, t } = this.props;
    let buildingId;
    this.setState({
      courierStats: {},
      courierPercentages: {},
      courierStatsLoading: true,
      courierTotal: 0,
      courierStatsError: null,
      courierFrom: range.start,
      courierTo: range.end,
    });
    this.props.setScanFilter({
      filter: this.state.scanFilter,
      graph: this.state.chartFilter,
      mapFrom: this.state.mapFrom,
      mapTo: this.state.mapTo,
      topScanningFrom: this.state.topScanFrom,
      topScanningTo: this.state.topScanTo,
      leastScanningFrom: this.state.leastScanFrom,
      leastScanningTo: this.state.leastScanTo,
      courierFrom: range.start,
      courierTo: range.end,
      categoryFrom: this.state.categoryFrom,
      categoryTo: this.state.categoryTo,
    });
    if (building.length > 0) buildingId = building[0].building_id;
    getCourierAnalytics(
      buildingId,
      null,
      moment(range.start)
        .utc()
        .startOf('day')
        .toISOString(),
      moment(range.end)
        .utc()
        .endOf('day')
        .toISOString()
    )
      .then((courierStats) => {
        let stats = [];
        let courierPercentages = [];
        let totalCount = 0;
        for (const [key, value] of Object.entries(courierStats)) {
          stats.push({
            name: key,
            val: value,
          });
          totalCount += parseInt(value, 10);
        }
        for (let i = 0; i < stats.length; i++) {
          courierPercentages.push({
            name: stats[i].name,
            count: stats[i].val,
            val: [(Math.floor(stats[i].val) * 100) / totalCount],
          });
        }
        this.setState({
          courierStats: stats,
          courierPercentages,
          courierStatsLoading: false,
          courierTotal: totalCount,
        });
      })
      .catch((error) => {
        this.setState({
          courierStatsLoading: false,
          courierStatsError: error.response
            ? error.response.data.message
            : t('common.error.networkError'),
        });
      });
  };

  getCategoryAnalytics = (range) => {
    const { building, t } = this.props;
    let buildingId;
    this.setState({
      categoryFrom: range.start,
      categoryTo: range.end,
      categoryPercentages: {},
      categoryAnalytics: {},
      categoryTotal: 0,
      categoryAnalyticsLoading: true,
    });
    this.props.setScanFilter({
      filter: this.state.scanFilter,
      graph: this.state.chartFilter,
      mapFrom: this.state.mapFrom,
      mapTo: this.state.mapTo,
      topScanningFrom: this.state.topScanFrom,
      topScanningTo: this.state.topScanTo,
      leastScanningFrom: this.state.leastScanFrom,
      leastScanningTo: this.state.leastScanTo,
      courierFrom: this.state.courierFrom,
      courierTo: this.state.courierTo,
      categoryFrom: range.start,
      categoryTo: range.end,
    });
    localStorage.setItem('categoryFromDate_analytics', range.start);
    localStorage.setItem('categoryToDate_analytics', range.end);
    if (building.length > 0) buildingId = building[0].building_id;
    getCategoryAnalytics(
      buildingId,
      null,
      moment(range.start)
        .utc()
        .startOf('day')
        .toISOString(),
      moment(range.end)
        .utc()
        .endOf('day')
        .toISOString()
    )
      .then((categoryAnalytics) => {
        const oversized = parseInt(categoryAnalytics.oversize_flag, 10);
        const fragile = parseInt(categoryAnalytics.fragile_flag, 10);
        const confidential = parseInt(categoryAnalytics.confidential_flag, 10);
        const timeSensitive = parseInt(
          categoryAnalytics.time_sensitive_flag,
          10
        );
        const returnToSender = parseInt(
          categoryAnalytics.return_to_sender_flag,
          10
        );
        const paystubs = parseInt(categoryAnalytics.pay_stubs_flag, 10);
        const legalDocument = parseInt(
          categoryAnalytics.legal_document_flag,
          10
        );
        const foodDelivery = parseInt(categoryAnalytics.food_delivery_flag, 10);
        const topShelf = categoryAnalytics.top_shelf_flag
          ? parseInt(categoryAnalytics.top_shelf_flag, 10)
          : 0;
        const shelfA = categoryAnalytics.shelf_a_flag
          ? parseInt(categoryAnalytics.shelf_a_flag, 10)
          : 0;
        const shelfB = categoryAnalytics.shelf_b_flag
          ? parseInt(categoryAnalytics.shelf_b_flag, 10)
          : 0;
        const shelfC = categoryAnalytics.shelf_c_flag
          ? parseInt(categoryAnalytics.shelf_c_flag, 10)
          : 0;
        const shelfD = categoryAnalytics.shelf_d_flag
          ? parseInt(categoryAnalytics.shelf_d_flag, 10)
          : 0;
        const shelfF = categoryAnalytics.shelf_f_flag
          ? parseInt(categoryAnalytics.shelf_f_flag, 10)
          : 0;
        const tub = categoryAnalytics.tub_flag
          ? parseInt(categoryAnalytics.tub_flag, 10)
          : 0;
        const floor = categoryAnalytics.floor_flag
          ? parseInt(categoryAnalytics.floor_flag, 10)
          : 0;
        const heavy = categoryAnalytics.heavy_flag
          ? parseInt(categoryAnalytics.heavy_flag, 10)
          : 0;
        const large = categoryAnalytics.large_flag
          ? parseInt(categoryAnalytics.large_flag, 10)
          : 0;
        const damaged = categoryAnalytics.damaged_flag
          ? parseInt(categoryAnalytics.damaged_flag, 10)
          : 0;
        const refrigerated = categoryAnalytics.refrigerated_flag
          ? parseInt(categoryAnalytics.refrigerated_flag, 10)
          : 0;

        let others = parseInt(categoryAnalytics.others_flag, 10);
        others =
          others +
          paystubs +
          legalDocument +
          foodDelivery +
          topShelf +
          shelfA +
          shelfB +
          shelfC +
          shelfD +
          shelfF +
          tub +
          floor +
          heavy +
          large +
          damaged +
          refrigerated;
        let total =
          oversized +
          fragile +
          confidential +
          timeSensitive +
          returnToSender +
          others;
        if (categoryAnalytics.total_packages) {
          total = categoryAnalytics.total_packages;
        }
        this.setState({ packageLabelTotal: total });
        let categoryPercentages = categoryAnalytics.labels.map((label) => ({
          id: label.label_id,
          name: label.value,
          color: label.color ? label.color : '#FF1E59',
          image: label.image,
          key: label.key,
          percentage: label.percentage,
          count: label.total_count,
        }));
        categoryPercentages.push({
          id: '-2',
          name: 'No Label',
          count: categoryAnalytics.packages_without_labels_count,
          percentage: categoryAnalytics.packages_without_labels_percentage,
          color: '#FF1E59',
          image: categoryAnalytics.packages_without_labels_image,
          key: 'no-label',
        });

        categoryPercentages.push({
          id: '-1',
          name: 'others',
          count: categoryAnalytics.other_labels_count,
          percentage: categoryAnalytics.other_labels_percentage,
          color: '#FF1E59',
          image: categoryAnalytics.other_labels_image,
          key: 'others',
        });
        this.setState({
          categoryPercentages,
          categoryAnalytics,
          categoryTotal: total,
          categoryAnalyticsLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          categoryAnalyticsLoading: false,
          categoryAnalyticsError: error.response
            ? error.response.data.message
            : t('common.error.networkError'),
        });
      });
  };

  getData = (props) => {
    const { building, scanFilter, t } = props;
    this.setState({
      scanFilter: scanFilter.filter,
      scanDetailLoading: true,
      scanDetails: {},
      scanDetailError: null,
      chartFilter: scanFilter.graph,
      buildingChartFilter: 0,
      buildingChartFilterErrorTop: '',
      buildingChartFilterErrorBottom: '',
      scanDetailGraphLoading: true,
      scanDetailsGraph: {},
      scanDetailsGraphError: null,
    });
    let buildingId;
    if (building.length > 0) buildingId = building[0].building_id;
    getPackageScanDetail(buildingId, null, chartFilterOptions[scanFilter.graph])
      // scanFilter.graph === 0
      //   ? 'today'
      //   : scanFilter.graph === 1
      //     ? 'last_7_days'
      //     : scanFilter.graph === 2
      //       ? 'last_4_weeks'
      //       : 'last_12_months')
      .then((scanDetailsGraph) => {
        this.setState({
          scanDetailsGraph: this.formatScanDetailsData(
            scanFilter.graph,
            scanDetailsGraph
          ),
          scanDetailGraphLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          scanDetailGraphLoading: false,
          scanDetailsGraphError: error.response
            ? error.response.data.message
            : t('common.error.networkError'),
          error:
            error &&
              error.message &&
              error.message === 'Not Found: Building(s) not found.'
              ? 'Building is Disabled'
              : this.state.error,
        });
      });
    getPackageScanDetail(
      buildingId,
      null,
      chartFilterOptions[scanFilter.filter]
    )
      // scanFilter.filter === 0,
      // ? 'today'
      // : scanFilter.filter === 1
      //   ? 'last_7_days'
      //   : scanFilter.filter === 2
      //     ? 'last_4_weeks'
      //     : 'last_12_months')
      .then((scanDetails) => {
        this.setState({
          scanDetails,
          scanDetailLoading: false,
        });
      })
      .catch((error) =>
        this.setState({
          scanDetailLoading: false,
          scanDetailError: error.response
            ? error.response.data.message
            : t('common.error.networkError'),
        })
      );
  };

  getMap = (range) => {
    this.setState({
      locations: {},
      locationsLoading: true,
      locationsError: '',
      mapFrom: range.start,
      mapTo: range.end,
    });
    this.props.setScanFilter({
      filter: this.state.scanFilter,
      graph: this.state.chartFilter,
      mapFrom: range.start,
      mapTo: range.end,
      topScanningFrom: this.state.topScanFrom,
      topScanningTo: this.state.topScanTo,
      leastScanningFrom: this.state.leastScanFrom,
      leastScanningTo: this.state.leastScanTo,
      courierFrom: this.state.courierFrom,
      courierTo: this.state.courierTo,
      categoryFrom: this.state.categoryFrom,
      categoryTo: this.state.categoryTo,
    });
    const { getLocations, t } = this.props;
    getLocations(
      moment(range.start)
        .utc()
        .startOf('day')
        .toISOString(),
      moment(range.end)
        .utc()
        .endOf('day')
        .toISOString()
    )
      .then((locations) => {
        let total = 0;
        locations.forEach((loc, index) => {
          const count = parseInt(loc.scans_count, 10);
          if (index === 0) total = loc.scans_count === '0' ? 1 : count;
          else total += count;
        });
        locations = locations.map(
          (loc) =>
            (loc.buildings_count !== '0' ||
              loc.scans_count !== '0' ||
              loc.members_count !== '0') && {
              id: loc.country_id,
              code: loc.country_code,
              count: Intl.NumberFormat('en').format(loc.buildings_count),
              name: loc.country_name,
              packages: Intl.NumberFormat('en').format(loc.scans_count),
              members: Intl.NumberFormat('en').format(loc.members_count),
              value:
                ((loc.scans_count === '0' ? 1 : parseInt(loc.scans_count, 10)) /
                  total) *
                (loc.scans_count === '0' ? 10 : 1000),
            }
        );
        locations = locations.filter((obj) => obj);
        this.setState({
          locations,
          locationsLoading: false,
        });
      })
      .catch((error) =>
        this.setState({
          locationsLoading: false,
          locationsError: error.response
            ? error.response.data.message
            : t('common.error.networkError'),
        })
      );
  };

  closeNewUserModal = () =>
    this.setState({ openNewUserModal: false }, () => {
      localStorage.removeItem('newUserSignUp');
    });

  closeTrialUserModal = () =>
    this.setState({ openTrialUserModal: false }, () => {
      localStorage.removeItem('trialUser');
    });

  changeFilter = (scanFilter) => {
    // this.setState({
    //   filter: scanFilter,
    //   scanDetailLoading: true,
    //   scanDetails: {},
    //   scanDetailError: null,
    // });
    this.props.setScanFilter({
      filter: scanFilter,
      graph: this.state.chartFilter,
      mapFrom: this.state.mapFrom,
      mapTo: this.state.mapTo,
      topScanningFrom: this.state.topScanFrom,
      topScanningTo: this.state.topScanTo,
      leastScanningFrom: this.state.leastScanFrom,
      leastScanningTo: this.state.leastScanTo,
      courierFrom: this.state.courierFrom,
      courierTo: this.state.courierTo,
      categoryFrom: this.state.categoryFrom,
      categoryTo: this.state.categoryTo,
    });
    this.setState({
      scanFilter,
      scanDetailLoading: true,
      scanDetails: {},
      scanDetailError: null,
    });
    const { building, t } = this.props;
    let buildingId;
    if (building.length > 0) buildingId = building[0].building_id;
    getPackageScanDetail(
      buildingId,
      null,
      chartFilterOptions[scanFilter]
      // scanFilter === 0
      //   ? 'today'
      //   : scanFilter === 1
      //     ? 'last_7_days'
      //     : scanFilter === 2
      //       ? 'last_4_weeks'
      //       : 'last_12_months',
    )
      .then((scanDetails) =>
        this.setState({ scanDetails, scanDetailLoading: false })
      )
      .catch((error) =>
        this.setState({
          scanDetailLoading: false,
          scanDetailError: error.response
            ? error.response.data.message
            : t('common.error.networkError'),
          error:
            error &&
              error.message &&
              error.message === 'Not Found: Building(s) not found.'
              ? 'Building is Disabled'
              : this.state.error,
        })
      );
  };

  formatScanDetailsData = (chartFilter, scanDetailsGraph) => {
    const scanDetailsData = [];
    const { t } = this.props;
    if (chartFilter === 0) {
      for (let a = 0; a < scanDetailsGraph.notified.today.length; a++) {
        const tempObject = {
          label: hoursLabels[a],
          Scanned: scanDetailsGraph.scanned.today[a].pkg_count,
          Picked: scanDetailsGraph.picked.today[a].pkg_count,
          Notified: scanDetailsGraph.notified.today[a].pkg_count,
        };
        scanDetailsData.push(tempObject);
      }
    } else if (chartFilter === 1) {
      for (let a = 0; a < scanDetailsGraph.notified.last_7_days.length; a++) {
        const tempObject = {
          label: `
        ${GetMonthName(scanDetailsGraph.scanned.last_7_days[a].month, t)}
        ${scanDetailsGraph.scanned.last_7_days[a].day},
        ${scanDetailsGraph.scanned.last_7_days[a].year}
        `,
          // label: hoursLabels[a],
          Scanned: scanDetailsGraph.scanned.last_7_days[a].pkg_count,
          Picked: scanDetailsGraph.picked.last_7_days[a].pkg_count,
          Notified: scanDetailsGraph.notified.last_7_days[a].pkg_count,
        };
        scanDetailsData.push(tempObject);
      }
    } else if (chartFilter === 2) {
      for (let a = 0; a < scanDetailsGraph.notified.last_4_weeks.length; a++) {
        const tempObject = {
          label: `Week ${scanDetailsGraph.scanned.last_4_weeks[a].week},
          ${scanDetailsGraph.scanned.last_4_weeks[a].year}`,
          // label: hoursLabels[a],
          Scanned: scanDetailsGraph.scanned.last_4_weeks[a].pkg_count,
          Picked: scanDetailsGraph.picked.last_4_weeks[a].pkg_count,
          Notified: scanDetailsGraph.notified.last_4_weeks[a].pkg_count,
        };
        scanDetailsData.push(tempObject);
      }
    } else if (chartFilter === 3) {
      for (
        let a = 0;
        a < scanDetailsGraph.notified.last_12_months.length;
        a++
      ) {
        const tempObject = {
          label: `${GetMonthName(
            scanDetailsGraph.scanned.last_12_months[a].month,
            t
          )},
          ${scanDetailsGraph.scanned.last_12_months[a].year}`,
          Scanned: scanDetailsGraph.scanned.last_12_months[a].pkg_count,
          Picked: scanDetailsGraph.picked.last_12_months[a].pkg_count,
          Notified: scanDetailsGraph.notified.last_12_months[a].pkg_count,
        };
        scanDetailsData.push(tempObject);
      }
    }
    return scanDetailsData;
  };

  chartFilter = (chartFilter) => {
    this.setState({
      selectedOptionBusySlots: 'Select',
      chartFilter,
      scanDetailGraphLoading: true,
      scanDetailsGraph: {},
      scanDetailsGraphError: null,
    });

    this.props.setScanFilter({
      filter: this.state.scanFilter,
      graph: chartFilter,
      mapFrom: this.state.mapFrom,
      mapTo: this.state.mapTo,
      topScanningFrom: this.state.topScanFrom,
      topScanningTo: this.state.topScanTo,
      leastScanningFrom: this.state.leastScanFrom,
      leastScanningTo: this.state.leastScanTo,
      courierFrom: this.state.courierFrom,
      courierTo: this.state.courierTo,
      categoryFrom: this.state.categoryFrom,
      categoryTo: this.state.categoryTo,
    });
    const { building, t } = this.props;
    let buildingId;
    if (building.length > 0) buildingId = building[0].building_id;
    getPackageScanDetail(
      buildingId,
      null,
      chartFilterOptions[chartFilter]
      // chartFilter === 0
      //   ? 'today'
      //   : chartFilter === 1
      //     ? 'last_7_days'
      //     : chartFilter === 2
      //       ? 'last_4_weeks'
      //       : 'last_12_months',
    )
      .then(async (scanDetailsGraph) => {
        const tempData = await this.formatScanDetailsData(
          chartFilter,
          scanDetailsGraph
        );
        this.setState({
          scanDetailsGraph: tempData,
          scanDetailGraphLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          scanDetailGraphLoading: false,
          scanDetailsGraphError: error.response
            ? error.response.data.message
            : t('common.error.networkError'),
          error:
            error &&
              error.message &&
              error.message === 'Not Found: Building(s) not found.'
              ? 'Building is Disabled'
              : this.state.error,
        });
      });
  };

  selectCountry = (selectedCountry) => this.setState({ selectedCountry });

  scanningBuildings = (least, range) => {
    const { getBuildingScanCount, t } = this.props;
    if (least) {
      this.setState({
        loadingBuildingChartBottom: true,
        buildingScanCountBottom: {},
        buildingChartFilterErrorBottom: '',
        leastScanFrom: range.start,
        leastScanTo: range.end,
      });
      this.props.setScanFilter({
        filter: this.state.scanFilter,
        graph: this.state.chartFilter,
        mapFrom: this.state.mapFrom,
        mapTo: this.state.mapTo,
        topScanningFrom: this.state.topScanFrom,
        topScanningTo: this.state.topScanTo,
        leastScanningFrom: range.start,
        leastScanningTo: range.end,
        courierFrom: this.state.courierFrom,
        courierTo: this.state.courierTo,
        categoryFrom: this.state.categoryFrom,
        categoryTo: this.state.categoryTo,
      });
    } else {
      this.setState({
        loadingBuildingChartTop: true,
        buildingScanCountTop: {},
        buildingChartFilterErrorTop: '',
        topScanFrom: range.start,
        topScanTo: range.end,
      });
      this.props.setScanFilter({
        filter: this.state.scanFilter,
        graph: this.state.chartFilter,
        mapFrom: this.state.mapFrom,
        mapTo: this.state.mapTo,
        topScanningFrom: range.start,
        topScanningTo: range.end,
        leastScanningFrom: this.state.leastScanFrom,
        leastScanningTo: this.state.leastScanTo,
        courierFrom: this.state.courierFrom,
        courierTo: this.state.courierTo,
        categoryFrom: this.state.categoryFrom,
        categoryTo: this.state.categoryTo,
      });
    }
    getBuildingScanCount(
      least,
      moment(range.start)
        .utc()
        .startOf('day')
        .toISOString(),
      moment(range.end)
        .utc()
        .endOf('day')
        .toISOString()
    )
      .then((scanCount) => {
        let totalCount = 0;

        for (let i = 0; i < scanCount.length; i++) {
          totalCount += parseInt(scanCount[i].scans_count, 10);
        }
        if (scanCount.length < 4) {
          for (let i = 0; i < 2; i++) {
            scanCount.push({
              building_id: '',
              code: '',
              country: '',
              name: i === 0 ? '' : i === 1 ? ' ' : i === 2 ? '  ' : '   ',
              scans_count: 0,
            });
          }
        }
        if (least) {
          this.setState({
            loadingBuildingChartBottom: false,
            buildingChartFilterDataBottom: scanCount,
            buildingScanCountBottom: totalCount,
          });
        } else {
          this.setState({
            loadingBuildingChartTop: false,
            buildingChartFilterDataTop: scanCount,
            buildingScanCountTop: totalCount,
          });
        }
      })
      .catch((error) => {
        if (least) {
          this.setState({
            loadingBuildingChartBottom: false,
            buildingChartFilterErrorBottom: error.response
              ? error.response.data.message
              : t('common.error.networkError'),
          });
        } else {
          this.setState({
            loadingBuildingChartTop: false,
            buildingChartFilterErrorTop: error.response
              ? error.response.data.message
              : t('common.error.networkError'),
          });
        }
      });
  };

  toggleBusySlotsAnchor = (event) => {
    this.setState({
      busySlotsAnchor: event.currentTarget,
    });
  };

  handleCloseBusySlots = () => {
    this.setState({
      busySlotsAnchor: null,
    });
  };

  handleSelectedOptionBusySlots = (value) => {
    this.setState(
      {
        scanDetailGraphLoading: value !== 'none',
        busySlotsAnchor: null,
        selectedOptionBusySlots:
          value === 'none'
            ? 'Select'
            : value === 'hourly'
              ? 'Hourly'
              : 'Weekly',
        busySlotsData: value === 'none' && [],
        scanDetailsGraphError: value !== 'none' && null,
        scanDetailsGraph: value === 'none' && {},
      },
      () => {
        if (this.state.selectedOptionBusySlots !== 'Select') {
          this.fetchBusySlotsData();
        } else {
          this.chartFilter(this.state.chartFilter);
        }
      }
    );
  };

  fetchBusySlotsData = () => {
    // const { t, building } = this.props;
    const { building } = this.props;
    const { selectedOptionBusySlots, chartFilter } = this.state;
    let currentDate = new Date();
    // const d1 = moment();

    const updatedDate = new Date(
      currentDate.setMinutes(
        currentDate.getMinutes() - currentDate.getTimezoneOffset()
      )
    );
    updatedDate.setHours(
      (-1 * currentDate.getTimezoneOffset()) / 60 + 23,
      59,
      59,
      999
    );
    let toDate = moment()
      .utc()
      .endOf('day')
      .toISOString();
    // updatedDate.toISOString();
    let fromDate = moment();
    let totalDays = 0;
    let statusFilter = 'hour';
    // let dayOfTheWeek = currentDate.getDay();

    if (chartFilter === 1) {
      // totalDays = 7
      totalDays = 7;
    } else if (chartFilter === 2) {
      // dayOfTheWeek = 8 - dayOfTheWeek;
      totalDays = 29;
      if (selectedOptionBusySlots === 'Hourly') statusFilter = 'hour';
      else statusFilter = 'day';
    } else if (chartFilter === 3) {
      totalDays = 365;
      if (updatedDate.getMonth() === 1 && updatedDate.getDate() === 29) {
        if (updatedDate.getFullYear() % 4 === 0) {
          totalDays += 1;
        }
      } else if (updatedDate.getMonth() > 1) {
        if (updatedDate.getFullYear() % 4 === 0) {
          totalDays += 1;
        }
      }
      if (selectedOptionBusySlots === 'Hourly') statusFilter = 'hour';
      else statusFilter = 'day';
    }
    if (currentDate.getDay() !== 1 && chartFilter === 2) {
      const dayOfTheWeek = 8 - currentDate.getDay();
      updatedDate.setDate(updatedDate.getDate() + dayOfTheWeek);
      // toDate = updatedDate.toISOString();
    }
    updatedDate.setDate(updatedDate.getDate() - totalDays);
    updatedDate.setHours((-1 * currentDate.getTimezoneOffset()) / 60, 0, 0, 0);

    if (chartFilter === 3) {
      updatedDate.setMonth(updatedDate.getMonth() + 1);
      updatedDate.setDate(updatedDate.getDate() - 1);
    }

    // fromDate = updatedDate.toISOString();
    fromDate = fromDate
      .subtract(totalDays - 1, 'days')
      .utc()
      .startOf('day')
      .toISOString();
    let buildingId = null;
    if (building && building.length === 1) {
      buildingId = building[0].building_id;
    }
    const { buildingss } = this.props;
    let builds = [];
    //  const newBuildings = buildingss.flat(Infinity);
    if (buildingId) {
      buildingss.forEach((element) => {
        const bui = element.buildings.filter(
          (x) => x.building_id === buildingId
        );
        if (bui.length) {
          builds = bui;
        }
      });
    }
    // builds[0].time_zone_offset = null;
    const statusFilters = ['scanned', 'picked', 'held'];
    // const statusFilters = ['scanned'];
    let busySlotsData = [];
    // Promise.all(statusFilters.map(async (eachStatus) => {
    //   const reponse = await getBusySlotsData(buildingId, toDate, fromDate, statusFilter, eachStatus);
    //   return reponse.data;
    // }))
    getBusySlotsData(
      buildingId,
      toDate,
      fromDate,
      statusFilter,
      statusFilters.join()
    )
      .then((response) => {
        if (selectedOptionBusySlots === 'Hourly') {
          let offSet = new Date().getTimezoneOffset() / 60;
          for (let a = 0; a < response.picked.hourly.length; a++) {
            if (buildingId) {
              offSet =
                moment()
                  .utcOffset(
                    builds[0].time_zone_offset ? builds[0].time_zone_offset : ''
                  )
                  .utcOffset() / 60;
              offSet = -offSet;
            }
            let off = (a - offSet) % 24;
            if (off < 0) off = 24 - Math.abs(off);
            const tempObject = {
              label: hoursLabels[off],
              Scanned: response.scanned.hourly[a].pkg_count,
              Picked: response.picked.hourly[a].pkg_count,
              Notified: response.held.hourly[a].pkg_count,
              // Scanned: response[0].hourly[a].pkg_count,
            };
            busySlotsData.push(tempObject);
          }
          busySlotsData.sort(
            (a, b) => +a.label.split(':')[0] - +b.label.split(':')[0]
          );
        } else {
          for (let a = 0; a < response.picked.daily.length; a++) {
            const tempObject = {
              label: daysLabels[a],
              Scanned: response.scanned.daily[a].pkg_count,
              Picked: response.picked.daily[a].pkg_count,
              Notified: response.held.daily[a].pkg_count,
              // Scanned: response[0].daily[a].pkg_count,
            };
            busySlotsData.push(tempObject);
          }
        }
        this.setState({
          busySlotsData: [...busySlotsData],
          scanDetailGraphLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          busySlotsData: [],
          selectedOptionBusySlots: 'Select',
          scanDetailGraphLoading: false,
        });
      });
  };

  closeInvalidUserModal = () => {
    signout();
    window.location.href = '/login';
  }

  render() {
    const { t, userRole, building, width, userDetails } = this.props;
    const {
      busySlotsAnchor,
      encryptedEmail,
      selectedOptionBusySlots,
      busySlotsData,
      error,
      openNewUserModal,
      openTrialUserModal,
      invalidUser
    } = this.state;

    if (encryptedEmail) {
      return <Redirect replace to={encryptedEmail} />;
    }
    const styleOfBuildingToggle =
      width === 'xs'
        ? { display: 'flex', flexDirection: 'column' }
        : { display: 'flex', flexDirection: 'row' };
    const marginLeftBuildingToggle = width === 'xs' ? 0 : 15;
    const sdStylesleft = `padding-right-15px ${building.length > 0 ? 'margin-top-10px' : 'margin-top-24px'
      }`;
    const sdStylesright = `padding-left-15px ${building.length > 0 && width !== 'xs' && width !== 'sm'
      ? 'margin-top-10px'
      : 'margin-top-24px'
      }`;
    if (this.state.selectedCountry) {
      return (
        <Redirect to={`/buildings/country/${this.state.selectedCountry}`} />
      );
    }

    const csvProp =
      userDetails &&
      userDetails.buildings &&
      userDetails.buildings[0] &&
      userDetails.buildings[0].buildings &&
      userDetails.buildings[0].buildings[0] &&
      userDetails.buildings[0].buildings[0].used_csv_feature &&
      userDetails.buildings[0].buildings[0].used_csv_feature;

    return (
      <Fragment>
        {error && (
          <Alert
            variant="error"
            open
            message={error}
            onClose={() => this.setState({ error: null })}
          />
        )}

        {userRole === null ? (
          <div className="align-center-column">
            <CircularProgress className="blue" />
          </div>
        ) : (
          <Fragment>
            <div className="dashboard container">
              {/* hide CLA Section */}
              {userDetails && userDetails.step && (
                <PendingTasks
                  csv={csvProp ? csvProp : null}
                  step={userDetails.step}
                  t={t}
                  userEmail={userDetails.UserAttributes[3].Value}
                  userName={userDetails.UserAttributes[1].Value}
                />
              )}

              <Grid container>
                <Grid item md={12} style={styleOfBuildingToggle}>
                  <h3 className="page-title">{t('main.title')}</h3>
                  <div
                    style={{
                      marginLeft: marginLeftBuildingToggle,
                      marginTop: 2,
                    }}
                  >
                    <BuildingToggle {...this.props} />
                  </div>
                </Grid>
              </Grid>
              {(userRole === 'admin' || userRole === 'super_admin') &&
                building.length === 0 && (
                  <Grid container>
                    <Grid item xs={12}>
                      <WorldMap
                        t={this.props.t}
                        from={this.state.mapFrom}
                        to={this.state.mapTo}
                        buildings={buildings}
                        style={this.props.style}
                        {...this.state}
                        selectCountry={this.selectCountry}
                        applyFilter={(range) => this.getMap(range)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className="padding-right-15px"
                      style={{ marginTop: building.length > 0 ? 0 : 24 }}
                    >
                      <BuildingBarChart
                        top
                        t={this.props.t}
                        sidebar={this.props.sidebar}
                        from={this.state.topScanFrom}
                        to={this.state.topScanTo}
                        loading={this.state.loadingBuildingChartTop}
                        error={this.state.buildingChartFilterErrorTop}
                        data={this.state.buildingChartFilterDataTop}
                        applyFilter={(range) =>
                          this.scanningBuildings(false, range)
                        }
                        scanCount={this.state.buildingScanCountTop}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className="padding-left-15px"
                      style={{ marginTop: building.length > 0 ? 0 : 24 }}
                    >
                      <BuildingBarChart
                        t={this.props.t}
                        sidebar={this.props.sidebar}
                        from={this.state.leastScanFrom}
                        to={this.state.leastScanTo}
                        loading={this.state.loadingBuildingChartBottom}
                        error={this.state.buildingChartFilterErrorBottom}
                        data={this.state.buildingChartFilterDataBottom}
                        applyFilter={(range) =>
                          this.scanningBuildings(true, range)
                        }
                        scanCount={this.state.buildingScanCountBottom}
                      />
                    </Grid>
                  </Grid>
                )}

              {/* Scan Details */}
              <Grid container>
                <Grid item xs={12} sm={12} md={6} className={sdStylesleft}>
                  <ScanDetailsStats
                    t={this.props.t}
                    style={this.props.style}
                    width={this.props.width}
                    loading={this.state.scanDetailLoading}
                    scanDetails={this.state.scanDetails}
                    error={this.state.scanDetailError}
                    scanFilter={this.state.scanFilter}
                    changeFilter={this.changeFilter}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={sdStylesright}>
                  {/* <ScanDetailsStats
                      charts
                      t={this.props.t}
                      style={this.props.style}
                      width={this.props.width}
                      sidebar={this.props.sidebar}
                      loading={this.state.scanDetailGraphLoading}
                      scanDetailsGraph={this.state.scanDetailsGraph}
                      error={this.state.scanDetailsGraphError}
                      chartFilter={this.state.chartFilter}
                      changeFilter={this.chartFilter}
                      busySlotsAnchor={busySlotsAnchor}
                      toggleBusySlotsAnchor={this.toggleBusySlotsAnchor}
                      handleCloseBusySlots={this.handleCloseBusySlots}
                      selectedOptionBusySlots={selectedOptionBusySlots}
                      fetchBusySlotsData={this.fetchBusySlotsData}
                      handleSelectedOptionBusySlots={this.handleSelectedOptionBusySlots}
                      busySlotsData={busySlotsData}
                    /> */}
                  <ScanDetailsGraph
                    charts
                    t={this.props.t}
                    style={this.props.style}
                    width={this.props.width}
                    sidebar={this.props.sidebar}
                    loading={this.state.scanDetailGraphLoading}
                    scanDetailsGraph={this.state.scanDetailsGraph}
                    error={this.state.scanDetailsGraphError}
                    chartFilter={this.state.chartFilter}
                    changeFilter={this.chartFilter}
                    busySlotsAnchor={busySlotsAnchor}
                    toggleBusySlotsAnchor={this.toggleBusySlotsAnchor}
                    handleCloseBusySlots={this.handleCloseBusySlots}
                    selectedOptionBusySlots={selectedOptionBusySlots}
                    fetchBusySlotsData={this.fetchBusySlotsData}
                    handleSelectedOptionBusySlots={
                      this.handleSelectedOptionBusySlots
                    }
                    busySlotsData={busySlotsData}
                    building={this.props.building}
                    lng={this.props.lng}
                  />
                </Grid>
              </Grid>

              {/* Held Packages */}
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  className="padding-right-15px margin-top-24px"
                >
                  <HeldPackages
                    t={this.props.t}
                    style={this.props.style}
                    heldPkgLoading={this.state.heldPkgLoading}
                    heldPkgs={this.state.heldPkgs}
                    heldPkgError={this.state.heldPkgError}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  className="padding-left-15px margin-top-24px"
                >
                  <HeldPackages
                    charts
                    t={this.props.t}
                    style={this.props.style}
                    heldPkgLoading={this.state.heldPkgLoading}
                    heldPkgs={this.state.heldPkgs}
                    heldPkgError={this.state.heldPkgError}
                  />
                </Grid>
              </Grid>

              {/* Breakdown and Labels */}
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  className="padding-right-15px margin-top-24px"
                >
                  <CardWithBars
                    title={t('main.courierBreakdown')}
                    {...this.state}
                    {...this.props}
                    courierBreakdown
                    total={this.state.courierTotal}
                    loading={this.state.courierStatsLoading}
                    data={this.state.courierStats}
                    error={this.state.courierStatsError}
                    from={this.state.courierFrom}
                    to={this.state.courierTo}
                    applyFilter={(range) => this.getCourierAnalytics(range)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  className="padding-left-15px margin-top-24px"
                >
                  <CardWithBars
                    title={t('main.packageLabels')}
                    {...this.state}
                    {...this.props}
                    total={this.state.packageLabelTotal}
                    loading={this.state.categoryAnalyticsLoading}
                    data={this.state.categoryPercentages}
                    error={this.state.categoryAnalyticsError}
                    from={this.state.categoryFrom}
                    to={this.state.categoryTo}
                    applyFilter={(range) => this.getCategoryAnalytics(range)}
                  />
                </Grid>
              </Grid>
            </div>
            <NewUserModal
              open={openNewUserModal}
              close={this.closeNewUserModal}
            />
            {/* CLA section Hide */}
            {/*
            {userDetails &&
            userDetails.buildings &&
            userDetails.buildings[0] &&
            userDetails.buildings[0].tier &&
            userDetails.buildings[0].tier !== 'bronze' ? (
              <TrialModal
                tier={userDetails.buildings[0].tier}
                open={openTrialUserModal}
                close={this.closeTrialUserModal}
                t={this.props.t}
              />
            ) : (
              ''
            )} */}
            <Footer />
          </Fragment>
        )}
        <InvalidAccount open={this.props.userRole === 'sales_admin'} onClose={this.closeInvalidUserModal} />
      </Fragment>
    );
  }
}

DefaultDashboard.propTypes = {
  building: PropTypes.node.isRequired,
  buildings: PropTypes.node.isRequired,
  userRole: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setScanFilter: (filter) => dispatch(setScanFilter(filter)),
  setcountry: (filter) => dispatch(setCountries(filter)),
});

export default connect(
  (state) => ({
    building: state.locationFilter,
    buildings: state.allBuildings,
    userRole: state.userRole,
    sidebar: state.sidebar,
    scanFilter: state.scanFilter,
    userDetails: state.userDetails,
    buildingss: state.allBuildings,
    paymentsDetails: state.paymentsDetails,
  }),
  mapDispatchToProps
)(withWidth()(DefaultDashboard));
