import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import BlueButton from '../../UI/BlueButton';

export default function ConfrimModal(props) {
  const {
    open,
    handleClose,
    t,
    // buildingDetail
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      {/* <DialogTitle id="draggable-dialog-title" style={{ borderBottom: '1px solid #E0E0E0' }}>
        {t('buildings.modifyCta')}
      </DialogTitle> */}
      <DialogContent>
        <DialogContentText style={{ paddingTop: '19px' }}>
          {t('buildings.noMoreAddBuilding')}
          {/* You are allowed to have only one building on your current plan. If you wish to have more buildings added, kindly request a plan upgrade. */}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="modelBTN" style={{ flexDirection: window.screen.width < 400 ? 'column' : 'row', margin: '13px 24px' }}>
        <BlueButton
          style={{ backgroundColor: 'gray', marginBottom: 10 }}
          width={window.screen.width < 500 ? 136 : 160}
          onClick={handleClose}>
          {t('common.cancel')}
        </BlueButton>
        {/* <BlueButton
          style={{ marginBottom: 10 }}
          width={window.screen.width < 500 ? 136 : 160}
          onClick={handleConfrim}>
          {t('common.confirm')}
        </BlueButton> */}
      </DialogActions>
    </Dialog>
  );
}

// ConfrimModal.defaultProps = {
//   buildingDetail: false
// };

ConfrimModal.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // handleConfrim: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  // buildingDetail: PropTypes.bool
};
