/* eslint-disable */

import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { CircularProgress } from '@material-ui/core';
import { updatedUserCard } from '../../../server/payment';
import { Button } from './Styled';
import Alert from '../../UI/Poper';

// class UserCardDetail extends React.Component {
const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);
const Wrapper = (props) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { t } = props;

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    if(loading) return;
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setErrorMsg(error.message);
      setLoading(false);
    } else {
      // console.log('[PaymentMethod]', paymentMethod);
      updatedUserCard(paymentMethod.id)
        .then((res) => {
          props.reloadData ? props.reloadData() : null;
          return res;
        })
        .then((res) => {
          cardElement.clear();
          return res;
        })
        .then((res) => {
          props.onClose();
        })
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setErrorMsg('Error Updating Payment Method!');
          console.log(err);
        });
    }
  };
  return (
    <form className="add-card-form" onSubmit={handleSubmit}>
      {errorMsg && (
        <Alert
          variant="error"
          open
          message={errorMsg}
          onClose={() => setErrorMsg(false)}
        />
      )}
      <CardElement
        hidePostalCode={true}
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />
      <div className="pm-single-button-section">
        <Button
          type="submit"
          className="pm-cp-upgrade-btn"
          disabled={!stripe || loading}
        >
          {t('paymentModals.userCardDetail.addCard')}{' '}
          {loading && (
            <span className="ml-2">
              <CircularProgress size={15} className="white" />
            </span>
          )}{' '}
        </Button>
      </div>
    </form>
  );
};

const UserCardDetail = (props) => {
  const { t } = props;
  return (
    <Dialog keepMounted maxWidth="sm" onClose={props.onClose} {...props}>
      <DialogContent className="pm-modal-container">
        <h3 className="pm-heading" style={{ marginBottom: 30 }}>
          {t('paymentModals.userCardDetail.headingNormal1')}{' '}
          <span className="pm-heading-green">
            {t('paymentModals.userCardDetail.headingColored')}
          </span>{' '}
          {t('paymentModals.userCardDetail.headingNormal2')}
        </h3>
        <Elements stripe={stripePromise}>
          <Wrapper {...props} />
        </Elements>
      </DialogContent>
    </Dialog>
  );
};

export default UserCardDetail;
