const resolveSafari = () => {
    let container = document.getElementsByClassName('container__wrap')[0];
    container.setAttribute('style', 'overflow-y:hidden');
    setTimeout(function () {
        container.setAttribute('style', 'overflow-y:auto');
    }, 50);
    return null;
};

export {
    resolveSafari,
};
