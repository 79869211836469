/* eslint-disable */
import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from '../../redux/actions/sidebarActions';
import { setBuildings } from '../../redux/actions/allBuildingsActions';
import { setLoader } from '../../redux/actions/loaderAction';
import { setRole } from '../../redux/actions/userRoleActions';
import {
  CustomizerProps,
  SidebarProps,
  ThemeProps,
} from '../../shared/prop-types/ReducerProps';
import { setBuilding } from '../../redux/actions/locationsFilterActions';
import { setUser } from '../../redux/actions/userActions';
import {
  setPaymentDetails,
  setPaymentDetailsLoading,
} from '../../redux/actions/paymentsAction';
import { setUser as setUserDetail } from '../../redux/actions/userDeatilsActions';
import {
  checkAWSSession,
  onBoardingScreens,
} from './../../util/application.utils';
import { csvUploadFlagForLayout } from './../../redux/actions/csvFlagAction';
import { getPaymentPlans } from './../../server/payment';
import { CallTrigger } from '../../util/Triggers/function';
import { CLA } from '../../util/Triggers/triggerNames';
import { getDaysDifference } from '../../util/utility.functions';
import Alert from '../UI/Poper';
// import { refreshToken } from '../../server/auth';

// let notification = null;
/* const showNotification = () => {
  notification.notice({
    content: <BasicNotification
      title="👋 Welcome to the EasyDev!"
      message="You have successfully registered in the EasyDev. Now you can start to explore the dashboard
                interface with a bunch of components and applications. Enjoy!"
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  });
}; */

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    customizer: CustomizerProps.isRequired,
    theme: ThemeProps.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      loggedin: true,
      user: {},
      loggingout: false,
      validUntil: null,
      error: false,
    };
  }

  componentDidMount() {
    const { getProfile, dispatch } = this.props;
    const env = process.env.REACT_APP_ENV;
    dispatch(setLoader('buildings', true));
    checkAWSSession((res) => {
      if (res) {
        return <Redirect replace to={`/onboarding?email=${res}`} />;
      }
      if (env && env.includes('cn')) {
        const auth = localStorage.px_auth;
        if (auth) {
          getProfile(localStorage.px_uid).then((profile) => {
            let user = profile.user;
            user = {
              ...user,
              step: profile.role.step,
            };
            this.setState({
              user: {
                name: user.UserAttributes[1].Value,
                image: user.user && user.user.user_image_link,
              },
            });
            if (
              profile.tenant.length === 1 &&
              profile.tenant[0].buildings.length === 1
            ) {
              dispatch(
                setBuilding([
                  {
                    building_id: profile.tenant[0].buildings[0].building_id,
                    building_name: profile.tenant[0].buildings[0].building_name,
                  },
                ])
              );
            }
            sessionStorage.setItem('px_tenant', user.role.tenant_id);
            let userdetails = user;
            userdetails.buildings = profile.tenant;
            const newBuildings = [];
            profile.tenant.map((item) => {
              item.buildings.map((eachBuilding) => {
                newBuildings.push(eachBuilding);
                return eachBuilding;
              });
              return item;
            });
            if (localStorage.px_search_suggestions) {
              const currentSuggestions = JSON.parse(
                localStorage.px_search_suggestions
              );
              const filteredArray = [...currentSuggestions].filter((item) => {
                const suggestedBuilding = newBuildings.filter(
                  (currentItem) => currentItem.building_id === item.building_id
                );
                if (suggestedBuilding.length === 0) {
                  return false;
                }
                return true;
              });
              localStorage.px_search_suggestions = JSON.stringify(
                filteredArray
              );
            }
            dispatch(setUserDetail(userdetails));
            dispatch(setUserDetail(user));
            dispatch(setBuildings(profile.tenant));
            dispatch(setRole(profile.role.name));
            dispatch(setUser(profile.user.UserAttributes[3].Value));
            this.getPayment(); // hide CLA section
            dispatch(setLoader('buildings', false));
          });
        }
      } else {
        let redirectState = localStorage.getItem('initialLoginState')
          ? localStorage.getItem('initialLoginState')
          : null;
        if (redirectState === 'FCP')
          this.props.history.replace(
            `/force_reset_password/${localStorage.getItem('px_user')}`
          );
        else if (redirectState === 'CND')
          this.props.history.replace(
            `/user_initial_details/${localStorage.getItem('px_user')}`
          );
        else {
          Auth.currentSession()
            .then((user) => {
              this.setState({ loggedin: true });
              const username = 'cognito:username';
              this.setState({ user: user.idToken.payload });
              CallTrigger(CLA.DASHBOARD.Dashboard_Pending_Tasks_fetch_attempt, {
                Previous_event: '',
              });
              getProfile(user.idToken.payload[username]).then((profile) => {
                CallTrigger(
                  CLA.DASHBOARD.Dashboard_Pending_Tasks_fetch_success,
                  {
                    Previous_event: '',
                    pending_tasks_list:
                      onBoardingScreens[profile.role.step] || 0,
                  }
                );
                //   CallTrigger(CLA.DASHBOARD.Dashboard_Pending_Task_bclick, { Previous_event: '', clicked_pending_task: onBoardingScreens[step] });
                this.setState({
                  user: {
                    name: profile.user && profile.user.UserAttributes[1].Value,
                    image: profile.user && profile.user.user_image_link,
                  },
                });

                if (localStorage.px_search_suggestions) {
                  const newBuildings = [];
                  profile.tenant.map((item) => {
                    item.buildings.map((eachBuilding) => {
                      newBuildings.push(eachBuilding);
                      return eachBuilding;
                    });
                    return item;
                  });
                  const currentSuggestions = JSON.parse(
                    localStorage.px_search_suggestions
                  );
                  const filteredArray = [...currentSuggestions].filter(
                    (item) => {
                      const suggestedBuilding = [...newBuildings].filter(
                        (currentItem) =>
                          currentItem.building_id === item.building_id
                      );
                      if (suggestedBuilding.length === 0) {
                        return false;
                      }
                      return true;
                    }
                  );
                  localStorage.px_search_suggestions = JSON.stringify(
                    filteredArray
                  );
                }
                if (
                  profile.tenant.length === 1 &&
                  profile.tenant[0].buildings.length === 1
                ) {
                  dispatch(
                    setBuilding([
                      {
                        building_id: profile.tenant[0].buildings[0].building_id,
                        building_name:
                          profile.tenant[0].buildings[0].building_name,
                        // building_country: profile.tenant[0].buildings[0].building_country,
                      },
                    ])
                  );
                }
                sessionStorage.setItem('px_tenant', profile.role);
                let userdetails = profile.user;
                userdetails.buildings = profile.tenant;
                userdetails.step = profile.role.step;
                dispatch(setUserDetail(userdetails));
                dispatch(setBuildings(profile.tenant));
                dispatch(setRole(profile.role.name));
                dispatch(setUser(profile.user.UserAttributes[3].Value));
                this.getPayment(); // hide CLA section
                dispatch(setLoader('buildings', false));
              });
            })
            .catch((error) => {
              CallTrigger(CLA.DASHBOARD.Dashboard_Pending_Tasks_fetch_fail, {
                Previous_event: '',
                Error_code: error.err_code,
                Error_desc: error.message,
              });
              if (
                window.location.pathname !== '/' &&
                window.location.pathname !== 'forgot_password' &&
                !window.location.pathname.includes('/reset_password/')
              ) {
                localStorage.setItem('redirectPath', window.location.pathname);
              }
              this.setState({ loggedin: false });
            });
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.csvFlag !== prevProps.csvFlag &&
      this.props.csvFlag === true
    ) {
      this.getUserCall(() =>
        this.props.dispatch(csvUploadFlagForLayout(false))
      );
    }
    if (this.props.buildings !== prevProps.buildings) {
      const { buildings } = this.props;
      if (
        buildings &&
        buildings[0] &&
        buildings[0].tier &&
        (buildings[0].tier.toLowerCase() === 'gold' ||
          buildings[0].tier.toLowerCase() === 'silver')
      ) {
        this.setState({
          validUntil: getDaysDifference(buildings[0].valid_until),
          // validUntil: getDaysDifference('2021-03-25T07:36:40.000Z')
        });
        // (buildings[0].valid_until)
      }
    }
  }

  getPayment = () => {
    const { dispatch } = this.props;
    dispatch(setPaymentDetailsLoading(true));
    getPaymentPlans()
      .then((resPay) => {
        dispatch(
          setPaymentDetails({
            data: resPay.data,
            loading: 0,
          })
        );
        dispatch(setPaymentDetailsLoading(false));
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          error: 'Unable to fetch stripe payment details',
        });
        dispatch(setPaymentDetailsLoading(false));
      });
  };

  getUserCall = (callback) => {
    const { dispatch, getProfile } = this.props;
    const username = 'cognito:username';
    getProfile(this.state.user[username])
      .then((profile) => {
        this.setState({
          user: {
            name: profile.user && profile.user.UserAttributes[1].Value,
            image: profile.user && profile.user.user_image_link,
          },
        });

        if (localStorage.px_search_suggestions) {
          const newBuildings = [];
          profile.tenant.map((item) => {
            item.buildings.map((eachBuilding) => {
              newBuildings.push(eachBuilding);
              return eachBuilding;
            });
            return item;
          });
          return item;
        }
        const currentSuggestions = JSON.parse(
          localStorage.px_search_suggestions
        );
        const filteredArray = [...currentSuggestions].filter((item) => {
          const suggestedBuilding = [...newBuildings].filter(
            (currentItem) => currentItem.building_id === item.building_id
          );
          if (suggestedBuilding.length === 0) {
            return false;
          }
          return true;
        });
        localStorage.px_search_suggestions = JSON.stringify(filteredArray);
        if (
          profile.tenant.length === 1 &&
          profile.tenant[0].buildings.length === 1
        ) {
          dispatch(
            setBuilding([
              {
                building_id: profile.tenant[0].buildings[0].building_id,
                building_name: profile.tenant[0].buildings[0].building_name,
                // building_country: profile.tenant[0].buildings[0].building_country,
              },
            ])
          );
        }
        sessionStorage.setItem('px_tenant', profile.role.tenant_id);
        let userdetails = profile.user;
        userdetails.buildings = profile.tenant;
        userdetails.step = profile.role.step;

        // userdetails.features = profile.tenant.features;
        dispatch(setUserDetail(userdetails));
        dispatch(setBuildings(profile.tenant));
        dispatch(setRole(profile.role.name));
        dispatch(setUser(profile.user.UserAttributes[3].Value));
        callback();
      })
      .catch(() => {
        if (
          window.location.pathname !== '/' &&
          window.location.pathname !== 'forgot_password' &&
          !window.location.pathname.includes('/reset_password/')
        ) {
          localStorage.setItem('redirectPath', window.location.pathname);
        }
        this.setState({ loggedin: false });
      });
  };

  changeSidebarVisibility = () => {
    this.props.dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    this.props.dispatch(changeMobileSidebarVisibility());
  };

  loggingout = () => this.setState({ loggingout: !this.state.loggingout });

  setUserROle = (role) => this.props.dispatch(setRole(role));

  render() {
    const { loggedin, user, loggingout, error, validUntil } = this.state;
    const { customizer, sidebar } = this.props;
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
      'layout--top-navigation': customizer.topNavigation,
    });

    window.scrollTo(0, 0);
    if (loggingout) return null;
    else if (loggedin) {
      return (
        <div className={layoutClass}>
          {this.state.error && (
            <Alert
              variant="error"
              open
              message={error}
              onClose={() => this.setState({ error: null })}
            />
          )}
          <Topbar
            user={user}
            {...this.props}
            loggingout={this.loggingout}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
            setRole={this.setUserROle}
          />
          <Sidebar
            {...this.props}
            sidebar={sidebar}
            changeToDark={this.changeToDark}
            changeToLight={this.changeToLight}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          />
        </div>
      );
    }
    return <Redirect to="/" />;
  }
}
export default withRouter(
  connect((state) => ({
    customizer: state.customizer,
    sidebar: state.sidebar,
    buildings: state.allBuildings,
    uploading: state.csvuploading,
    userRole: state.userRole,
    countries: state.countries,
    username: state.user,
    csvFlag: state.csvFlagForLayout,
  }))(Layout)
);
