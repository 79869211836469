/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import { withRouter, Link, Redirect } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AssociateDetailComp from './components/AssociateDetail';
import {
  VerticallyCenter,
  AlignCenter,
} from '../../UI/ResponsiveRelated';
import BlueButton from '../../UI/BlueButton';
import Alert from '../../UI/Poper';
import WarningAlert from '../../../shared/components/Alert';
import NotAuthorized from '../../UI/403/403';
import Footer from "../../UI/Footer/Footer";
import { getAssociate } from '../../../server';
import { userRolesHeirarchy } from '../../../util/application.utils';
// import TenantSelector from '../TenantSelector';

class AssociateDetail extends Component {
  constructor(props) {
    super(props);

    let selectedTenantTemp = null;
    let newBuildings = [];
    if (props.buildings) {
      const { buildings } = props;
      if (buildings.length !== 0) {
        selectedTenantTemp = {
          tenant_name: buildings[0].tenant_name,
          tenant_id: buildings[0].tenant_id,
        };

        buildings.map(item => {
          if (item.tenant_id === buildings[0].tenant_id) {
            newBuildings = [...item.buildings];
          }
          return item;
        });
      }
    }
    this.state = {
      loading: true,
      mailroomAnchor: null,
      roleDropdownAnchor: null,
      associate: {},
      buildings: [],
      error: null,
      editing: true,
      name: '',
      email: '',
      role: '',
      roleCode: null,
      picture: null,
      status: true,
      // selectedBuildings: newBuildings && newBuildings.length === 1 ? newBuildings : [],
      selectedBuildings: [],
      searchedBuildings: newBuildings ? [...newBuildings] : [],
      selectedMailrooms: newBuildings.length === 1 && newBuildings[0].mailrooms.length === 1
        ? newBuildings.buildings ? newBuildings.buildings[0].mailrooms : newBuildings[0].mailrooms : [], // not getting the Building properties in newBuilding so added the check 
      emailError: false,
      nameError: false,
      updating: false,
      updated: false,
      errorUpdating: false,
      warning: null,
      roles: [],
      loadingRoles: false,
      rolesError: null,
      selectedRole: '',
      deleting: false,
      deleted: false,
      errorDeleting: false,
      redirect: false,
      anchorEl: null,
      selectedTenant: selectedTenantTemp
    };
  }

  componentDidMount() {
    this.getData();
    this.interval = setInterval(() => {
      const { buildings } = this.props;

      if (buildings.length !== 0) {
        const { tenant_name, tenant_id } = buildings[0];
        let newBuildings = [];

        buildings.map(item => {
          if (item.tenant_id === tenant_id) {
            newBuildings = [...item.buildings];
          }
          return item;
        });
        this.setState({
          selectedTenant: {
            tenant_name,
            tenant_id,
          },
          searchedBuildings: newBuildings,
        });
        clearInterval(this.interval);
      }
    }, 50);
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidUpdate(prevProps) {
    const { match } = prevProps;
    if (match.params.id !== this.props.match.params.id) {
      this.getData(this.prevProps);
    }
    if (this.props !== prevProps) {
      const { selectedTenant } = this.state;
      if (selectedTenant) {
        let { buildings } = this.props;
        let newBuildings = [];

        buildings.map(item => {
          if (item.tenant_id === selectedTenant.tenant_id) {
            newBuildings = [...item.buildings];
          }
          return item;
        });
        this.setState({
          searchedBuildings: [...newBuildings],
          selectedBuildings: newBuildings.length === 1 ? newBuildings.buildings : [],
        });
      }
      this.componentDidUpdateState();
    }
    if (prevProps.userRole !== this.props.userRole) {
      this.getData();
    }
  }

  onClose = () => {
    this.setState({ errorUpdating: '', updated: '', redirect: this.state.updated });
  };

  getData = () => {
    const {
      getRoles,
      match,
      userRole,
      userDetail
    } = this.props;
    const currentUserRole = userRolesHeirarchy.indexOf(userRole);
    this.setState({ loading: true, rolesError: null });
    // if (userRole && userRole !== 'mailroom_supervisor') {
    getAssociate(match.params.id)
      .then(associate => {
        if (this.props.buildings) {
          const { buildings } = this.props;
          if (buildings.length !== 0) {
            const selectedTenant = buildings.filter(item => item.tenant_id === associate.role.tenant_id);
            const selectedTenantTemp = {
              tenant_name: selectedTenant[0].tenant_name,
              tenant_id: selectedTenant[0].tenant_id,
            };
            this.setState({
              selectedBuildings: selectedTenant && selectedTenant[0].buildings.length === 1 ? selectedTenant[0].buildings : [],
              searchedBuildings: selectedTenant ? selectedTenant[0].buildings : [],
              selectedMailrooms: (selectedTenant[0].buildings.length === 1 && selectedTenant[0].buildings[0].mailrooms.length === 1)
                ? selectedTenant[0].buildings[0].mailrooms : [],
              selectedTenant: { ...selectedTenantTemp }
            });
          }
        }
        const fetchedUserRole = userRolesHeirarchy.indexOf(associate.role.name);
        if (fetchedUserRole < currentUserRole) {
          this.setState({
            error: 'Not Authorized'
          });
        } else {
          const associatesListOfObjects = Object.values(
            Object.values(associate)[0].UserAttributes,
          );
          const nameOfUser = associatesListOfObjects.filter(
            item => item.Name === 'name',
          )[0].Value;
          const emailOfUser = associatesListOfObjects.filter(
            item => item.Name === 'email',
          )[0].Value;
          const status = associatesListOfObjects.filter(
            item => item.Name === 'email_verified',
          )[0].Value;
          const roleOfUser = associate.role.value;
          const roleCode = associate.role.name;
          if (associate.role.name === userRole && (userRole !== "admin" || (userDetail.Username === associate.user.Username))) {
            this.setState({ editing: false });
          }
          const buildingsOfUser = associate.buildings;
          if (this.state.roles.length === 0) {
            this.setState({ loadingRoles: true });
            getRoles()
              .then(roles => {
                // const userIsAdmin = [...roles].filter(item => item.name === 'admin').length === 0;
                const userIsAdmin = userRole === "admin";
                let assingRole = roles.length === 1
                  ? roles
                  : userIsAdmin
                    ? roles.slice(roles.length - 2, roles.length)
                    : roles.slice(roles.length - 3, roles.length);
                if (userIsAdmin) {
                  assingRole.unshift({
                    role_id: 1,
                    name: "admin",
                    value: "Admin",
                    level: 90
                  });
                }

                this.setState({
                  roles: assingRole,
                  selectedRole: this.state.role,
                  loadingRoles: false,
                });
              })
              .catch(error =>
                this.setState({
                  rolesError: error.response.data.message,
                  loadingRoles: false,
                }),
              );
          }
          window.URL.revokeObjectURL(associate.user.user_image_link);
          this.setState({
            loading: false,
            status,
            roleCode,
            name: nameOfUser,
            email: emailOfUser,
            role: roleOfUser,
            picture: associate.user.user_image_link,
            selectedRole: roleOfUser,
            building: buildingsOfUser,
            selectedBuildings: buildingsOfUser,
            selectedMailrooms:
              roleOfUser.toLowerCase() === 'operator'
                ? buildingsOfUser && buildingsOfUser[0].mailrooms
                : buildingsOfUser.length === 1
                  ? buildingsOfUser[0].mailrooms
                  : [],
            associate: {
              name: nameOfUser,
              email: emailOfUser,
              role: roleOfUser,
              selectedRole: roleOfUser,
              buildings: buildingsOfUser,
            },
          });
        }
      })
      .catch(err => {
        if (err.message.includes('Unauthorized')) {
          this.setState({
            error: 'Not Authorized'
          });
        } else if (err.message.includes('Not Found')) {
          this.setState({
            error: 'Not Authorized'
          });
        } else {
          this.setState({
            error: err.response && err.response.data.message,
            loading: false,
          });
        }
      });
    // }
  };

  componentDidUpdateState = () => {
    const { selectedTenant } = this.state;

    let { buildings } = this.props;
    if (selectedTenant) {
      let newBuildings = [];
      buildings.map(item => {
        if (item.tenant_id === selectedTenant.tenant_id) {
          newBuildings = [...item.buildings];
        }
        return item;
      });
      this.setState({ searchedBuildings: newBuildings });
    }
  };

  cancelEditing = () => {
    const { associate } = this.state;
    this.setState({
      emailError: false,
      nameError: false,
      editing: false,
      name: associate.name,
      email: associate.email,
      selectedBuildings: associate.buildings,
      selectedMailrooms:
        associate.role.toLowerCase() === 'operator'
          ? associate.buildings[0].mailrooms
          : associate.buildings.length === 1
            ? associate.buildings[0].mailrooms
            : [],
      role: associate.role,
      selectedRole: associate.role,
    });
    this.closeAlert();
  };

  resetWarning = () => this.setState({ warning: null });

  handleChange = name => event => {
    let valueOfField = event.target.value;
    this.resetWarning();
    const { userRole } = this.props;
    if (name === 'selectedRole' && valueOfField !== this.state.selectedRole && (valueOfField.toLowerCase() === 'operator' || valueOfField.toLowerCase() === 'manager') && (userRole === 'admin' || userRole === 'super_admin')) {
      this.setState({ selectedBuildings: [], selectedMailrooms: [] });
    }
    if (name === 'selectedRole' && valueOfField !== this.state.selectedRole && valueOfField === 'Admin' && (userRole === 'admin' || userRole === 'super_admin')) {
      this.setState({ selectedBuildings: [], selectedMailrooms: [] });
    }
    if (
      name === 'selectedBuildings' &&
      valueOfField.indexOf('search') > -1
    ) {
      const searchIndex = valueOfField.indexOf('search');
      valueOfField.splice(searchIndex, 1);
    }
    if (
      this.state.selectedRole.toLowerCase() === 'operator' &&
      valueOfField.length > 0 &&
      name === 'selectedBuildings'
    ) {
      this.setState({
        [name]: [valueOfField[valueOfField.length - 1]],
        selectedMailrooms: [],
      });
    } else {
      this.setState({ [name]: valueOfField }, () => {
        const { validateEmail, validateBuildingAndAssociateName } = this.props;
        const { email } = this.state;
        this.setState({
          emailError: email.length ? !validateEmail(email) : false,
        });
        if (name === 'name') {
          this.setState({
            nameError: valueOfField.length > 0 ? !validateBuildingAndAssociateName(valueOfField.trim()) : false,
          });
        }
      });
    }
  };

  saveAssociate = () => {
    const {
      name,
      email,
      emailError,
      nameError,
      roles,
      picture,
      selectedRole,
      selectedBuildings,
      selectedMailrooms,
    } = this.state;
    const {
      t,
      updateAssociate,
      match,
      userRole
    } = this.props;
    const tenantId = this.state.associate.buildings && this.state.associate.buildings[0].tenant_id;
    if (name.length === 0) {
      this.setState({ warning: t('buildings.nameNotEmpty') });
    } else if (email.length === 0) {
      this.setState({ warning: t('emailEmpty') });
    } else if (emailError) this.setState({ warning: t('emailValidation') });
    else if (nameError) this.setState({ warning: t('common.error.nameValidation') });
    else if (
      selectedRole.toLowerCase() === 'operator' &&
      selectedMailrooms.length === 0
    ) {
      this.setState({ warning: t('associates.mailroomMustBeSelected') });
    } else if (
      selectedRole.toLowerCase() === 'manager' &&
      selectedBuildings.length === 0
    ) {
      this.setState({ warning: t('associates.buildingMustBeSelected') });
    } else {
      this.setState({ updating: true, disaBtn: true, error: null });
      const onlyRoles = roles.map(role => role.value);
      const roleIndex = onlyRoles.indexOf(selectedRole);
      const associate = {
        name: name.trim(),
        user_name: name.trim(),
        user_email: email.trim(),
        role_id: roles[roleIndex] && roles[roleIndex].role_id ? roles[roleIndex].role_id : 1,
        tenant: tenantId,
      };
      if (picture) associate.user_image = picture.split('base64,')[1];
      if (selectedRole.toLowerCase() === 'operator') {
        associate.access_policy = {
          mailroom_id: selectedMailrooms.map(mlrm => String(mlrm.mailroom_id)),
        };
      } else {
        associate.access_policy = {
          building_id: selectedBuildings.map(building =>
            String(building.building_id),
          ),
        };
      }
      if (userRole === 'super_admin') {
        const { tenant_id } = this.state.selectedTenant;
        associate.tenant = tenant_id;
      }
      if (selectedRole === 'Admin') {
        associate.access_policy = {};
      }
      updateAssociate(match.params.id, associate)
        .then(() => {
          this.setState({
            updated: true,
            updating: false,
            disableAssociateBtn: false,
            role: selectedRole,
            associate: {
              name,
              email,
              selectedRole,
              selectedMailrooms,
              role: selectedRole,
              buildings: selectedBuildings,
            },
          });
        })
        .catch(error =>
          this.setState({
            errorUpdating: error.response && error.response.data.message,
            updating: false,
            disableAssociateBtn: false,
          }),
        );
    }
  };

  closeAlert = () => this.setState({ warning: null });

  removeBuilding = index => {
    if ((this.state.role !== 'Admin' || this.state.role !== 'super_dmin') && this.state.role !== 'super_admin') {
      const selectedBuildings = this.state.selectedBuildings.filter(
        (value, indx) => {
          if (indx !== index) return value;
          return null;
        },
      );
      this.setState({ selectedBuildings, selectedMailrooms: [] });
    }
  };

  handleSearch = event => {
    const { buildings } = this.props;
    const { selectedTenant } = this.state;
    let newBuildings = [];
    if (selectedTenant) {
      buildings.map(item => {
        if (item.tenant_id === selectedTenant.tenant_id) {
          newBuildings = [...item.buildings];
        }
        return item;
      });
    }
    if (event.target.value.length === 0) {
      this.setState({ searchedBuildings: newBuildings });
    } else {
      let searchedBuildings = newBuildings.map(building => {
        if (building.building_name.toLowerCase().includes(event.target.value.toLowerCase())) {
          return building;
        }
        return null;
      });
      searchedBuildings = searchedBuildings.filter(obj => obj);
      this.setState({ searchedBuildings });
    }
  };

  handleClickAway = () => {
    const { selectedTenant } = this.state;
    let { buildings } = this.props;
    let newBuildings = [];

    buildings.map(item => {
      if (item.tenant_id === selectedTenant.tenant_id) {
        newBuildings = [...item.buildings];
      }
      return item;
    });
    this.setState({
      anchorEl: null,
      searchedBuildings: [...newBuildings],
    });
  }

  closeBuildingList = () => this.setState({ anchorEl: null });

  closeMailroomList = () => this.setState({ mailroomAnchor: null });

  openBuildingList = e => {
    this.setState({ anchorEl: this.state.anchorEl ? null : e.currentTarget });
  };

  openMailroomList = e => {
    this.setState({
      mailroomAnchor: this.state.mailroomAnchor ? null : e.currentTarget,
    });
  };

  selectBuilding = (name, building) => {
    if (this.state.selectedRole.toLowerCase() === "manager") {
      let tempBuildings = this.state.selectedBuildings;
      if (tempBuildings.length > 0) {
        let tempSelected = tempBuildings.findIndex((element) => element.building_id === building.building_id && element);
        if (tempSelected > -1) {
          tempBuildings = tempBuildings.splice(tempSelected, 1);
          this.setState({ selectedMailrooms: [] });
        } else {
          tempBuildings.push(building);
          this.setState({ [name]: tempBuildings });
        }
      } else this.setState({ [name]: [building] });
    } else {
      const { buildings } = this.props;
      const { selectedTenant } = this.state;
      let newBuildings = [];
      if (selectedTenant) {
        buildings.map(item => {
          if (item.tenant_id === selectedTenant.tenant_id) {
            newBuildings = [...item.buildings];
          }
          return item;
        });
      }
      this.setState({ [name]: [building], anchorEl: null, searchedBuildings: newBuildings });
    }
    if (
      this.state.selectedRole.toLowerCase() === 'operator' &&
      building.mailrooms.length === 1
    ) {
      this.setState({
        selectedMailrooms: building.mailrooms,
      });
    } // else this.setState({ selectedMailrooms: [] });
  };

  selectMailroom = (name, mailroom) => {
    let selectedMailrooms = [...this.state.selectedMailrooms];
    const index = selectedMailrooms
      .map(mlrm => mlrm.mailroom_id)
      .indexOf(mailroom.mailroom_id);
    if (index > -1) selectedMailrooms.splice(index, 1);
    else selectedMailrooms.push(mailroom);
    this.setState({ selectedMailrooms });
  };

  openRoleDropdown = e =>
    this.setState({
      roleDropdown: !this.state.roleDropdown,
      roleDropdownAnchor: this.state.roleDropdownAnchor ? null : e.currentTarget,
    });

  selectRole = selectedRole => {
    this.resetWarning();
    this.setState({
      selectedRole,
      selectedBuildings: [],
      roleDropdownAnchor: null,
    });
  };

  deactivate = () => {
    const { t, match, deleteAssociate } = this.props;
    this.setState({ deleting: true });
    deleteAssociate(match.params.id)
      .then(() =>
        this.setState({
          deleted: t('associates.deletedSuccessfully'),
          deleting: false,
        }),
      )
      .catch(err =>
        this.setState({
          deleting: false,
          errorDeleting: err.response
            ? err.response.data.message
            : t('common.error.networkError'),
        }),
      );
  };

  redirect = () => {
    this.setState({
      redirect: this.state.deleted,
      errorDeleting: false,
    });
  };

  handleTenantChange = e => {
    const value = JSON.parse(e.target.value);
    this.setState({
      selectedTenant: {
        tenant_name: value.tenant_name,
        tenant_id: value.tenant_id,
      },
      selectedBuildings: [],
      selectedMailrooms: [],
    });
  }

  loadimage = picture => this.setState({ picture });

  render() {
    const {
      loading,
      error,
      nameError,
      editing,
      updating,
      errorUpdating,
      updated,
      warning,
      deleting,
      deleted,
      errorDeleting,
      redirect,
      loadingRoles,
      // selectedTenant,
      selectedBuildings,
    } = this.state;
    const { t, userRole } = this.props;
    // props.associate.buildings && props.associate.buildings[0].tenant_id
    // const tenants = buildings.map(eachTenant => {
    //   const { tenant_name, contact_email, tenant_id } = eachTenant;
    //   return {
    //     contact_email,
    //     tenant_id,
    //     tenant_name
    //   };
    // });

    return !userRole ? (
      <VerticallyCenter>
        <AlignCenter>
          <CircularProgress className="blue" size={30} />
        </AlignCenter>
      </VerticallyCenter>
    ) : error === 'Not Authorized' ? (
      <NotAuthorized />
    ) : (
      <Fragment>
        <Grid container className="container" style={{ minWidth: '100%' }}>
          {redirect && <Redirect to="/associates" />}
          {errorUpdating && (
            <Alert
              variant="error"
              open
              message={errorUpdating}
              onClose={this.onClose}
            />
          )}
          {updated && (
            <Alert
              variant="success"
              open
              message={t('associates.updated')}
              onClose={this.onClose}
            />
          )}
          <Alert
            variant={deleting ? 'success' : errorDeleting && 'error'}
            open={deleting || deleted || errorDeleting}
            message={
              deleting ? t('members.deactivating') : deleted || errorDeleting
            }
            onClose={this.redirect}
          />
          <Grid item sm={12} md={12}>
            <div className="space-between-with-767-block">
              <div>
                {
                  <div style={{ display: 'flex' }}>
                    <h3 className="page-title" style={{ marginRight: 10 }}>
                      {loading && !error ? (
                        <Skeleton width={100} />
                      ) : (
                        this.state.name || '  ---'
                      )}
                    </h3>
                    {/* {
                          (!loading && userRole === 'super_admin') &&
                          <TenantSelector
                            t={t}
                            tenants={tenants}
                            selectedTenant={selectedTenant}
                            handleOnchange={this.handleTenantChange}
                          />
                        } */}
                  </div>
                }
                <h3 className="page-subhead subhead">
                  <Link to="/associates/">{t('associates.associates')}</Link>
                  <ArrowRightIcon />
                  {loading && !error ? <Skeleton width={100} /> : this.state.name || '  ---'}
                </h3>
              </div>
              {editing && userRole !== 'mailroom_supervisor' && !loading && (
                <div className="flex">
                  <BlueButton
                    right={10}
                    component={Link}
                    to="/associates"
                    width={window.screen.width < 500 ? 132 : 160}
                    onClick={this.cancelEditing}
                  >
                    {t('common.cancel')}
                  </BlueButton>
                  <BlueButton
                    right={10}
                    disabled={updating || loadingRoles || nameError || this.state.name.length < 1 || (this.selectedRole === 'Admin' && selectedBuildings.length === 0)}
                    width={window.screen.width < 500 ? 132 : 160}
                    loading={updating}
                    onClick={this.saveAssociate}
                  >
                    {t('associates.saveAssociate')}
                  </BlueButton>
                </div>
              )}
            </div>
            {warning && (
              <WarningAlert
                variant="error"
                onClose={this.closeAlert}
                message={warning}
                open
              />
            )}
          </Grid>
          <AssociateDetailComp
            {...this.state}
            {...this.props}
            selectBuilding={(state, building) =>
              this.selectBuilding(state, building)
            }
            selectMailroom={(name, mailroom) =>
              this.selectMailroom(name, mailroom)
            }
            loadimage={image => this.loadimage(image)}
            selectRole={role => this.selectRole(role)}
            openRoleDropdown={this.openRoleDropdown}
            openMailroomList={this.openMailroomList}
            openBuildingList={this.openBuildingList}
            closeBuildingList={this.closeBuildingList}
            handleChange={this.handleChange}
            removeBuilding={this.removeBuilding}
            handleSearch={this.handleSearch}
            deactivate={this.deactivate}
            handleClickAway={this.handleClickAway}
            closeMailroomList={this.closeMailroomList}
          />
        </Grid>
        <Footer />
      </Fragment>
    );
  }
}

AssociateDetail.propTypes = {
  getAssociate: PropTypes.func.isRequired,
  match: PropTypes.string.isRequired,
  style: PropTypes.node.isRequired,
};

export default withRouter(
  connect(state => ({
    buildings: state.allBuildings,
    userRole: state.userRole,
    userDetail: state.userDetails
  }))(AssociateDetail),
);
