import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    FormHelperText,
    // Button
  } from '@material-ui/core';
  import { CustomInput, CustomInputLabel } from '../../../UI/Input';
  import SearchDropdown from '../../../UI/SearchDropdown/SearchDropdown';
  import countriesData from '../../../../util/country.json';

export default class UserDetail extends Component {
    static propTypes = {
        // t: PropTypes.func.isRequired,
        selectItem: PropTypes.func.isRequired,
        statesToShow: PropTypes.array.isRequired,
        citiesToShow: PropTypes.array.isRequired,
        country: PropTypes.number,
        state: PropTypes.number,
        city: PropTypes.number,
        companyName: PropTypes.string,
        buildingName: PropTypes.string,
        mailroomName: PropTypes.string,
        // zipCode: PropTypes.string, 
        handleChange: PropTypes.func.isRequired,
        errorCountry: PropTypes.bool.isRequired,
        errorState: PropTypes.bool.isRequired,
        errorCity: PropTypes.bool.isRequired,
        errorCompanyName: PropTypes.bool.isRequired,
        errorBuildingName: PropTypes.bool.isRequired,
        errorMailRoom: PropTypes.bool.isRequired,
        setCountry: PropTypes.func.isRequired,
        setCountryState: PropTypes.func.isRequired,
    };
    static defaultProps = {
        country: '',
        state: '',
        city: '',
        companyName: '',
        buildingName: '',
        mailroomName: '',
        // zipCode: '',
    };
    constructor() {
        super();
        this.state = {
        };
    }
    
    handleChange = state => event => {
        this.setState({
            [state]: event.target.value
        });
    }

    render() {
        const { 
            selectItem, 
            statesToShow, 
            citiesToShow, 
            country, 
            city, 
            state,
            companyName,
            buildingName,
            mailroomName,
            // zipCode,
            handleChange,
            errorCountry,
            errorState,
            errorCity,
            errorCompanyName,
            errorBuildingName,
            setCountry,
            setCountryState,
            errorMailRoom
        } = this.props;
        const labelStyle = { fontSize: 13 };
        return (
            <div>
                <div className="form__form-group">
                <FormControl required fullWidth className="height-75">
                          <CustomInputLabel htmlFor="adornment-password" shrink className="font-size-14">
                            Company Name
                          </CustomInputLabel>
                          <CustomInput
                            // type={this.state.showConfirmPass ? 'text' : 'password'}
                            value={companyName}
                            // danger={confirmPassError || wrongCreds}
                            onChange={handleChange('companyName', 'errorCompanyName')}
                            // placeholder="Company Name"
                          />
                          {errorCompanyName && (
                            <FormHelperText className="font-size-10 red">
                             {errorCompanyName}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl required fullWidth className="height-75">
                          <CustomInputLabel htmlFor="adornment-password" shrink className="font-size-14">
                            Building Address
                          </CustomInputLabel>
                          <CustomInput
                            // type={this.state.showConfirmPass ? 'text' : 'password'}
                            value={buildingName}
                            // danger={confirmPassError || wrongCreds}
                            onChange={handleChange('buildingName', 'errorBuildingName')}
                            // placeholder="Building Name"
                          />
                          {errorBuildingName && (
                            <FormHelperText className="font-size-10 red">
                              {errorBuildingName}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl fullWidth className="height-75">
                          <CustomInputLabel htmlFor="adornment-password" shrink className="font-size-14">
                            Mailroom Name (optional)
                          </CustomInputLabel>
                          <CustomInput
                            // type={this.state.showConfirmPass ? 'text' : 'password'}
                            value={mailroomName}
                            // danger={confirmPassError || wrongCreds}
                            onChange={handleChange('mailroomName','errorMailRoom')}
                            // placeholder="Mailroom Name (optional)"
                          />
                          {errorMailRoom && (
                            <FormHelperText className="font-size-10 red">
                              {errorMailRoom}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl 
                        style={labelStyle}
                        shrink={country instanceof Object}
                        className="height-75 border-solid" 
                        required 
                        fullWidth >
                          <CustomInputLabel htmlFor="adornment-password" shrink className="font-size-14">
                            Country
                          </CustomInputLabel>
                          <SearchDropdown
                            loading={countriesData.length === 0}
                            list={countriesData}
                            selection={country}
                            type="getUserDetails"
                            selectItem={setCountry}
                          />
                          {errorCountry && (
                            <FormHelperText className="font-size-10 red">
                              Please enter country name
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl 
                        style={labelStyle}
                        shrink={country instanceof Object}
                        className="height-75 border-solid" 
                        required 
                        fullWidth >
                          <CustomInputLabel htmlFor="adornment-password" shrink className="font-size-14">
                            State
                          </CustomInputLabel>
                          <SearchDropdown
                            // loading={countriesData.length === 0}
                            preSelection="Country"
                            type="getUserDetails"
                            list={statesToShow}
                            selection={state}
                            selectItem={setCountryState}
                          />
                          {errorState && (
                            <FormHelperText className="font-size-10 red">
                              Please enter state name
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl 
                        style={labelStyle}
                        shrink={country instanceof Object}
                        className="height-75 border-solid" 
                        required 
                        fullWidth >
                          <CustomInputLabel htmlFor="adornment-password" shrink className="font-size-14">
                            City
                          </CustomInputLabel>
                          <SearchDropdown
                            // loading={countriesData.length === 0}
                            type="getUserDetails"
                            preSelection="State"
                            list={citiesToShow}
                            selection={city}
                            selectItem={item => selectItem('city', 'errorCity', item)}
                          />
                          {errorCity && (
                            <FormHelperText className="font-size-10 red">
                              Please enter city name
                            </FormHelperText>
                          )}
                        </FormControl>

                        {/* <FormControl fullWidth className="height-75">
                          <CustomInputLabel htmlFor="adornment-password" shrink className="font-size-14">
                          Zipcode (optional)
                          </CustomInputLabel>
                          <CustomInput
                            // type={this.state.showConfirmPass ? 'text' : 'password'}
                            value={zipCode}
                            // danger={confirmPassError || wrongCreds}
                            onChange={handleChange('zipCode')}
                            // placeholder="Zip Code (optional)"
                          />
                        </FormControl> */}
                </div>
            </div>
        );
    }
}
