import {
  CHANGE_SIDEBAR_VISIBILITY,
  CHANGE_MOBILE_SIDEBAR_VISIBILITY,
  CHANGE_TOP_BAR_MODE,
  SHOW_SKIP_TUTORIAL, // For topbar onboarding
} from '../actions/sidebarActions';

const initialState = {
  show: false,
  collapse: false,
  changeTopBarModeflag: false,
  showSkipTutorial: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_SIDEBAR_VISIBILITY:
      return { ...state, collapse: !state.collapse };
    case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return { ...state, show: !state.show };
    case CHANGE_TOP_BAR_MODE:
      return { ...state, changeTopBarModeflag: !state.changeTopBarModeflag };
    case SHOW_SKIP_TUTORIAL: 
      return { ...state, showSkipTutorial: action.showSkipTutorial };
    default:
      return state;
  }
}
