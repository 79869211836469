import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, FormControl, List, ListItem, Paper, Popper } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { CustomAdornmentInput } from '../Input';

export default function Dropdown({
  value, interval, onChange, width,
  }) {
  const [anchor, setValue] = useState(null);

  const openPopper = event => setValue(event.currentTarget);

  const closePopper = () => setValue(null);

  const inputProps = { style: { cursor: 'pointer', fontSize: 14 } };

  return (
    <Fragment>
      <FormControl>
        <CustomAdornmentInput
          inputProps={inputProps}
          style={{ cursor: 'pointer', width }}
          value={value.label}
          onClick={openPopper}
        >
          <ArrowDropDown />
        </CustomAdornmentInput>
      </FormControl>
      <Popper open={anchor} anchorEl={anchor} placement="bottom-end">
        <ClickAwayListener onClickAway={closePopper}>
          <Paper elevation={8} className="just-paper">
            <List>
              {interval && interval.map(item => (
                <ListItem
                  selected={value.value === item.value}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  onClick={() => {
                    closePopper();
                    onChange(item);
                  }}
                  button
                >
                  {item.label}
                </ListItem>
                ))}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Fragment>
  );
}

Dropdown.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  interval: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
