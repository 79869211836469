/* eslint-disable no-confusing-arrow */
/* eslint-disable quotes */
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { informSlackDBCodeErrs } from "./informSlack";
import translation from '../translations/en/common.json';

const env = process.env.REACT_APP_ENV;
const moment = extendMoment(originalMoment);
function truncateLabel(item) {
  if (item) {
    let length = window.innerWidth > 1500 ? 50 : 25;
    let truncatedLabel = item.substr(0, length);
    if (item.length > length) {
      truncatedLabel += "...";
    }
    return truncatedLabel;
  }
  return item;
}

function truncateLabelFromLeft(item) {
  if (item) {
    let truncatedLabel = item.substr(0, 20);
    if (item.length > 22) {
      truncatedLabel = `${truncatedLabel}...`;
    }
    return truncatedLabel;
  }
  return item;
}

function truncateFirstName(item) {
  if (item) {
    let index = item.indexOf(" ");
    let truncatedLabel = item.substr(0, index);
    if (item.length > 34) {
      truncatedLabel = `${truncatedLabel}...`;
    }
    return truncatedLabel;
  }
  return item;
}

function truncateArray(array) {
  try {
    if (array.length > 2) {
      return array.splice(0, 1);
    }
  } catch (err) {
    informSlackDBCodeErrs(err, 'src/util/utility.functionality.js');
  }

  return array;
}

function truncatePaginationNumbers(number) {
  let updatedNumber = number;
  if (number > 999) {
    updatedNumber = number
      .toString()
      .slice(0, 3)
      .concat("...");
  }
  return updatedNumber;
}

function timeZonesFormat(timeZone) {
  const zero = timeZone.offset >= 10 || timeZone.offset <= -10 ? "" : "0";
  const timeInFormat =
    timeZone.offset > 0
      ? Math.floor(timeZone.offset) < timeZone.offset
        ? `+${zero}${Math.floor(timeZone.offset)}:30`
        : `+${zero}${Math.floor(timeZone.offset)}:00`
      : timeZone.offset === 0
        ? "+00:00"
        : Math.ceil(timeZone.offset) > timeZone.offset
          ? `-${zero}${Math.abs(Math.ceil(timeZone.offset))}:30`
          : `-${zero}${Math.abs(Math.ceil(timeZone.offset))}:00`;
  const label = `(GMT ${timeInFormat}) ${timeZone.label}`;
  return label;
}

function formatCNNumber(phoneNumberString) {
  if (phoneNumberString) {
    let arrayedNumber = Array.from(phoneNumberString);
    let number = `${arrayedNumber[0]}${arrayedNumber[1]}${arrayedNumber[2]}-${arrayedNumber[3]
      }${arrayedNumber[4]}${arrayedNumber[5]}${arrayedNumber[6]}-${arrayedNumber[7]
      }${arrayedNumber[8]}${arrayedNumber[9]}${arrayedNumber[10]}-${arrayedNumber[11]
      }`;
    if (arrayedNumber[12]) {
      number = `${number}${arrayedNumber[12]}`;
    }
    return number;
  }
  return phoneNumberString;
}

function formatUSNumber(phoneNumberString) {
  let cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
}

const formatPhoneNumber = phone =>
  env && env.includes("_cn") ? formatCNNumber(phone) : formatUSNumber(phone);

function imageExists(path) {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = path;
  });
}

const convertTenantsIntoBuildings = buildings => {
  const newBuildings = [];
  buildings.map(item => {
    item.buildings.map(eachBuilding => {
      newBuildings.push(eachBuilding);
      return eachBuilding;
    });
    return item;
  });
  return newBuildings;
};

const getTodayEnd = () => {
  const date = new Date();
  // const currentDate = date.toISOString();
  // return currentDate;
  return date;
};

// const getTodayEnd = () => {
//   const currentDate = new Date();
//   const maxTime = new Date(currentDate.setMinutes(currentDate.getMinutes() - currentDate.getTimezoneOffset()));
//   // maxTime.setHours((-1 * currentDate.getTimezoneOffset()) / 60 + 23, 59, 59, 999);
//   maxTime.setHours(23, 59, 59, 999);
//   // maxTime.setDate(maxTime.getDate() - 1);
//   return maxTime;
// };

const getDateInDaysDifference = days => {
  const minTime = getTodayEnd();
  minTime.setDate(minTime.getDate() - days);
  return minTime;
};

const getDateInAYearDifference = () => {
  const minTime = new Date(getTodayEnd());
  minTime.setUTCFullYear(minTime.getUTCFullYear() - 1);
  minTime.setDate(minTime.getDate() + 1);
  return minTime;
};

const appendTimeAtEnd = (currentDate) => {
  const newDate = `${currentDate}T23:59:59.999Z`;
  return newDate;
};

const appendTimeAtStart = (currentDate) => {
  const newDate = `${currentDate}T00:00:00.000Z`;
  return newDate;
};

const getBuildingFromCountries = (countries, buildingID) => {
  const buildingsArray = [];
  countries.map(eachCountry => {
    const temp = eachCountry.states.map(eachState => {
      const temp2 = eachState.cities.map(eachCity => {
        eachCity.buildings.map(eachBuilding => {
          const buildingObj = {
            building_city: eachCity.city,
            building_state: eachState.state,
            building_country: eachCountry.name,
            building_id: eachBuilding.building_id,
            building_name: eachBuilding.name,
          };
          if (eachBuilding.building_id === buildingID) {
            buildingsArray.push(buildingObj);
          }
          return buildingObj;
        });
        return eachCity.buildings;
      });
      return temp2;
    });
    return temp;
  });
  return buildingsArray;
};

const handleSidebarHover = (event, activeIcon) => {
  event.target.src = activeIcon;
};

const handleSidebarHoverAway = (event, isActive, inactiveIcon) => {
  if (!isActive) {
    event.target.src = inactiveIcon;
  }
};
const getPackageStatus = (status, action_status, isHolding, activity_type, t) => {
  let pakageStatus = status === 'PICKED'
    ? t('tables.collected')
    : status === 'DELIVERED'
      ? t('tables.delivered')
      : status === 'NOTIFIED' && (action_status === 'NOTIFIED' || action_status === 'REMINDER') && !isHolding
        ? t('tables.overdue')
        : (status === 'NOTIFIED' && (action_status === 'NOTIFIED' || action_status === 'REMINDER') && isHolding) || (status === 'NOTIFIED' && activity_type === 'SCAN-OUT' && isHolding)
          ? t('tables.holding')
          : status === 'NOTIFIED' && action_status !== null
            ? action_status === 'SNAPSEND'
              ? t('tables.scannedSent')
              : action_status === 'REQUEST_SNAPSEND'
                ? t('tables.scanSend')
                : action_status === 'HOLD'
                  ? t('tables.held')
                  : action_status === 'REQUEST_HOLD'
                    ? t('tables.hold')
                    : action_status === 'FORWARD' && activity_type === 'SCAN-IN'
                      ? t('tables.forwarded')
                      : action_status === 'REQUEST_FORWARD'
                        ? t('tables.forward')
                        : action_status === 'REQUEST_DESTROY'
                          ? t('tables.destroy')
                          : status
            : status === 'FORWARD' && activity_type === 'SCAN-IN'
              ? t('tables.forwarded')
              : status === 'NOTIFIED' && action_status === null && !isHolding
                ? t('tables.overdue')
                : status === 'NOTIFIED' && action_status === null && isHolding
                  ? t('tables.holding')
                  : status === 'MAILBOX' && action_status === 'HOLD'
                    ? t('tables.heldMailbox')
                    : status === 'DESTROYED' && action_status === 'DESTROY'
                      ? t('tables.destroyed')
                      : status === 'FORWARD' && action_status === 'FORWARD'
                        ? t('tables.forwarded')
                        : status === 'FORWARD' && action_status === 'REQUEST_FORWARD'
                          ? t('tables.forward')
                          : status === 'DISCARDED' && action_status === 'DISCARDED'
                            ? t('tables.discarded')
                            : status === 'DISCARDED' && action_status === null
                              ? t('tables.discarded')
                              : status;

  let colr = pakageStatus === t('tables.scanSend') ?
    '#3380da'
    : pakageStatus === t('tables.scannedSent') ?
      '#3380da'
      : pakageStatus === t('tables.forward') ?
        '#804DDF'
        : pakageStatus === t('tables.forwarded') ?
          '#804DDF'
          : pakageStatus === t('tables.destroy') ?
            ' #FD1D59'
            : pakageStatus === t('tables.destroyed') ?
              ' #FD1D59'
              : pakageStatus === t('tables.hold') ?
                '#804DDF'
                : pakageStatus === t('tables.held') ?
                  '#804DDF'
                  : pakageStatus === t('tables.collected') ? '#02B67C'
                    : pakageStatus === t('tables.overdue') ? '#FF1E59'
                      : pakageStatus === t('tables.discarded') ? '#FF1E59'
                        : pakageStatus === t('tables.holding') ? '#FFB02F'
                          : '#757575';

  if (action_status === "DISPATCHED" && status === "DISPATCHED") {
    pakageStatus = "Dispatched";
    colr = "#1BF2A3";
  }

  return { packageStatus: pakageStatus, colour: colr };
};
const getStatusEn = (status, action_status, isHolding, activity_type) => {
  const pakageStatus = status === 'PICKED'
    ? translation.tables.collected
    : status === 'DELIVERED'
      ? translation.tables.delivered
      : status === 'NOTIFIED' && (action_status === 'NOTIFIED' || action_status === 'REMINDER') && !isHolding
        ? translation.tables.overdue
        : (status === 'NOTIFIED' && (action_status === 'NOTIFIED' || action_status === 'REMINDER') && isHolding) || (status === 'NOTIFIED' && activity_type === 'SCAN-OUT' && isHolding)
          ? translation.tables.holding
          : status === 'NOTIFIED' && action_status !== null
            ? action_status === 'SNAPSEND'
              ? translation.tables.scannedSent
              : action_status === 'REQUEST_SNAPSEND'
                ? translation.tables.scanSend
                : action_status === 'HOLD'
                  ? translation.tables.held
                  : action_status === 'REQUEST_HOLD'
                    ? translation.tables.hold
                    : action_status === 'FORWARD' && activity_type === 'SCAN-IN'
                      ? translation.tables.forwarded
                      : action_status === 'REQUEST_FORWARD'
                        ? translation.tables.forward
                        : action_status === 'REQUEST_DESTROY'
                          ? translation.tables.destroy
                          : status
            : status === 'FORWARD' && activity_type === 'SCAN-IN'
              ? translation.tables.forwarded
              : status === 'NOTIFIED' && action_status === null && !isHolding
                ? translation.tables.overdue
                : status === 'NOTIFIED' && action_status === null && isHolding
                  ? translation.tables.holding
                  : status === 'MAILBOX' && action_status === 'HOLD'
                    ? translation.tables.heldMailbox
                    : status === 'DESTROYED' && action_status === 'DESTROY'
                      ? translation.tables.destroyed
                      : status === 'FORWARD' && action_status === 'FORWARD'
                        ? translation.tables.forwarded
                        : status === 'FORWARD' && action_status === 'REQUEST_FORWARD'
                          ? translation.tables.forward
                          : status === 'DISCARDED' && action_status === 'DISCARDED'
                            ? translation.tables.discarded
                            : status === 'DISCARDED' && action_status === null
                              ? translation.tables.discarded
                              : status;

  return { packageStatus: pakageStatus };
};

const getDaysDifference = (expiryDate) => {
  if (expiryDate !== null) {
    const today = moment().startOf('day');
    const expiryDateDate = moment(expiryDate);
    const diff = expiryDateDate.diff(today, 'days');
    return diff;
  }
  return null;
};

const getCommaSeperatedNumber = (numString) => {
  if (!numString) return 0;
  const newNum = parseFloat(numString).toLocaleString('en-US', {
    maximumFractionDigits: 2,
  });
  return newNum;
};

export {
  truncateLabel,
  truncateLabelFromLeft,
  truncateArray,
  truncatePaginationNumbers,
  timeZonesFormat,
  formatPhoneNumber,
  truncateFirstName,
  imageExists,
  convertTenantsIntoBuildings,
  getTodayEnd,
  getDateInDaysDifference,
  getDateInAYearDifference,
  appendTimeAtEnd,
  appendTimeAtStart,
  getBuildingFromCountries,
  handleSidebarHover,
  handleSidebarHoverAway,
  getPackageStatus,
  getStatusEn,
  getDaysDifference,
  getCommaSeperatedNumber,
};
