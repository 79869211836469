export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';
export const SET_PAYMENT_DETAILS_LOADING = 'SET_PAYMENT_DETAILS_LOADING';

export function setPaymentDetails(paymentsDetails) {
  return {
    type: SET_PAYMENT_DETAILS,
    paymentsDetails,
  };
}

export function setPaymentDetailsLoading(loading) {
  return {
    type: SET_PAYMENT_DETAILS_LOADING,
    loading,
  };
}
