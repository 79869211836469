import React from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from './Styled';

function PaymentSuccessful(props) {
  const { t } = props;
  return (
    <div>
      <Dialog
        keepMounted
        maxWidth="md"
        onClose={() => props.history.replace('/dashboard')}
        {...props}
      >
        <DialogContent className="pm-modal-container">
          <h3 className="pm-heading">
            {t('paymentModals.paymentSuccessful.headingNormal')}{' '}
            <span className="pm-heading-green">
              {t('paymentModals.paymentSuccessful.headingColored')}!
            </span>
          </h3>
          {/* <p className="pm-description">
            {t('paymentModals.paymentSuccessful.description')}
          </p> */}
          <div className="pm-single-button-section">
            <Button
              className="pm-cp-upgrade-btn"
              onClick={() => props.history.replace('/dashboard')}
            >
              {t('paymentModals.paymentSuccessful.button')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withRouter(PaymentSuccessful);
