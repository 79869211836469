import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withWidth, Grid, Paper } from '@material-ui/core';
import Button from '../../../UI/BlueButton';
import Alert from '../../../UI/Poper';
import DeleteConfirmationPopup from './deleteConfirmationPopup';

class DeleteAccount extends Component {
  state = {
    error: null,
    openConfirmDelete: false,
  };

  render() {
    const { t } = this.props;
    const { error } = this.state;

    return (
      <Grid item md={12} lg={6} xl={6}>
        {error && (
          <Alert
            variant={error ? 'error' : 'success'}
            open
            message={error || t('signin.changeSuccessMessage1')}
            onClose={() => this.setState({ error: null })}
          />
        )}
        <Paper className="just-paper margin-bottom-15 margin-right-10px account-settings-card">
          <div className="account-settings-card-header">
            {t('deleteAccount.deleteAccount')}
          </div>
          <div className="flex-container-account-settings">
            <div>
              <div className="delete-account-text-para1">
                {t('deleteAccount.para1')}
              </div>
              <div className="delete-account-text-para2">
                {t('deleteAccount.para2')}
              </div>
              <div>{t('deleteAccount.para3')}</div>
              <div className="delete-account-text-bullet-point">
                {t('deleteAccount.point1')}
              </div>
              <div className="delete-account-text-bullet-point">
                {t('deleteAccount.point2')}
              </div>
              <div className="delete-account-text-bullet-point">
                {t('deleteAccount.point3')}
              </div>
            </div>

            <div className="space-between-width-center align-center margin-top-24px">
              <div />
              <Button
                width={180}
                height={45}
                color="#ff1f5a"
                // disabled="false"
                // loading="false"
                onClick={() => this.setState({ openConfirmDelete: true })}
              >
                {t('deleteAccount.deleteAccount')}
              </Button>
            </div>
          </div>
        </Paper>
        <DeleteConfirmationPopup
          t={t}
          open={this.state.openConfirmDelete}
          onClose={() => this.setState({ openConfirmDelete: false })}
          history={this.props.history}
        />
      </Grid>
    );
  }
}

DeleteAccount.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withWidth()(DeleteAccount);
