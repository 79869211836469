import React from 'react';
import PropTypes from 'prop-types';
import { TrendingFlat } from '@material-ui/icons';

function CustomSteps(props) {
    const {
        totalSteps, currentStep, next, back, disableNext, disablePrev, t
    } = props;
    
    const width = (100 / totalSteps);
    document.getElementsByClassName('stepper-progress').innerHTML = '';
    const firstStep = currentStep <= 4 ? { color: '#0000006e' } : null;
    const lastStep = totalSteps === currentStep ? { color: '#0000006e' } : null;
    const getSteps = () => (
        <>
            {
                Array.from(Array(totalSteps), (d, index) => (
                    <li
                        style={{ width: `${width}%` }}
                        className={`stepper-progress ${index <= currentStep - 1 ? 'stepper-checked' : 'stepper-unchecked'}`}
                    >.</li>
                ))
            }
        </>
    );

    return (
        <div className="custom-stepper-container">
            <div
                role="button"
                tabIndex={0}
                style={firstStep}
                className={!disablePrev ? 'next-previous-btn' : 'next-previous-btn-disable'}
                onKeyDown={!disablePrev ? () => back() : undefined}
                onClick={!disablePrev ? () => back() : undefined}
            >
                <TrendingFlat className="arrow-back" /> {t('onboarding.prev')}
            </div>
            <ul className="stepper-progress-section">{getSteps()}</ul>
            <div
                role="button"
                tabIndex={0}
                style={lastStep}
                className={!disableNext ? 'next-previous-btn' : 'next-previous-btn-disable'}
                onClick={!disableNext ? () => next() : undefined}
                onKeyDown={!disableNext ? () => next() : undefined}
            >
                {t('onboarding.next')} <TrendingFlat className="arrow-next" />
            </div>
        </div >
    );
}

CustomSteps.propTypes = {
    totalSteps: PropTypes.number.isRequired,
    currentStep: PropTypes.number.isRequired,
    next: PropTypes.func.isRequired,
    back: PropTypes.func.isRequired,
    disablePrev: PropTypes.bool.isRequired,
    disableNext: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired
};

export default CustomSteps;
