/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import {
  AlignCenter,
  Margin,
  VerticallyCenter,
} from '../../../UI/ResponsiveRelated';
import MailroomList from './MailroomList';
import AssociatesList from './AssociateList';
import Preferences from './Preferences';
import Notifications from './Notifications';
import EmailTemplate from './EmailTemplate';
import CTAToggle from './CTAToggle';
import { CustomInputLabel, CustomTextField, CustomInput } from '../../../UI/Input';
import SearchDropdown from '../../../UI/SearchDropdown/SearchDropdown';

const BuildingsIconInactive = require('../../../Layout/sidebar/BuildingsIconInactive.png');

function BuildingDetail(props) {
  const {
    t,
    name,
    address,
    city,
    state,
    country,
    emailError,
    nameError,
    addressError,
    style,
    loading,
    handleChange,
    hourSelection,
    minSelection,
    error,
    emails,
    loadingEmails,
    building_id,
    loadingLocations,
    loadingStates,
    loadingCities,
    errorLocations,
    errorStates,
    errorCities,
    locations,
    states,
    cities,
    width,
    selectItem,
    setDataForDeleteUser,
    csv_upload,
    reply_to,
    replyToErrors,
    buildings,
    tenant_id,
  } = props;
  let env = process.env.REACT_APP_ENV
      ? process.env.REACT_APP_ENV
      : "development";
  const hasBuildingAndTier = buildings && buildings.length > 0 && buildings[0].tier;
  let enableActionItems = true;
  if (hasBuildingAndTier) {
    if (buildings[0].features.ACTION_ITEMS) enableActionItems = true;
    else enableActionItems = false;
  }
  let enableEmails = true;
  if (hasBuildingAndTier) {
    if (buildings[0].features.EMAILS) enableEmails = true;
    else enableEmails = false;
  }

  const inputStyle = { fontSize: 13, color: '#000' };
  const labelStyle = { fontSize: 13 };
  const FormHelperStyle = { color: '#FF1E59' };

  let csvOptions =
    [
      {
        label: t('email.yes'),
        value: true,
      },
      {
        label: t('associates.no'),
        value: false,
      },
    ];

  return (
    <Grid container>
      <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
        <Paper
          style={{
            height: width === 'sm' || width === 'xs' ? 550 : 400,
            marginBottom: 15,
            marginRight: width === 'lg' || width === 'xl' ? 15 : 0,
            ...style.common.oy_a,
          }}
          className="just-paper"
        >
          <div className="card-body">
            {error ? (
              <div style={{ height: '80%' }}>
                <VerticallyCenter>
                  <AlignCenter>
                    <p>
                      <b> {t('common.error.networkError')} </b>
                    </p>
                  </AlignCenter>
                </VerticallyCenter>
              </div>
            ) : (
              <div className="flex card__title">
                <img style={{ borderRadius: 5, height: 20, width: 20 }} src={BuildingsIconInactive} alt="" />
                &nbsp;
                <h5 className="bold-text">{t('buildings.info')}</h5>
              </div>
            )}
            {!error && (
              <Grid container direction="row" justify="space-between">
                <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-60">
                  {loading ? (
                    <Skeleton width={100} />
                  ) : (
                    <CustomTextField
                      fullWidth
                      required
                      className="height-50px"
                      label={t('tables.name')}
                      value={name}
                      InputProps={{
                        inputProps: {
                          style: {
                            inputStyle,
                            textOverflow: 'ellipsis',
                            color: 'black',
                          },
                        },
                      }}
                      InputLabelProps={{ style: labelStyle }}
                      onChange={handleChange('name')}
                    />
                  )}
                  {name.length > 1 &&
                    <FormHelperText style={FormHelperStyle}>
                      {nameError ? t('common.error.nameValidation') : ''}
                    </FormHelperText>}
                  {name.length < 2 && name.length > 0 &&
                    <FormHelperText style={FormHelperStyle}>{t('common.error.nameLength')}</FormHelperText>}
                </Grid>
                {/* <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-60">
                  {loading ? (
                    <Skeleton width={100} />
                  ) : (
                      <CustomTextField
                        fullWidth
                        className="height-50px"
                        label={t('tables.email')}
                        value={email}
                        InputProps={{ style: inputStyle }}
                        InputLabelProps={{ style: labelStyle }}
                        onChange={handleChange('email')}
                        helperText={emailError ? t('emailValidation') : ''}
                        FormHelperTextProps={{ style: FormHelperStyle }}
                      />
                    )}
                </Grid> */}
                <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-60">
                  {loading ? (
                    <Skeleton width={100} />
                  ) : (
                    <CustomTextField
                      fullWidth
                      className="height-50px"
                      label={t('tables.address')}
                      value={address}
                      InputProps={{ style: inputStyle }}
                      InputLabelProps={{ style: labelStyle }}
                      onChange={handleChange('address')}
                      helperText={addressError ? t('common.error.addressValidation') : ''}
                      FormHelperTextProps={{ style: FormHelperStyle }}
                    />
                  )}
                </Grid>
                <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-60">
                  {loading ? (
                    <Skeleton width={100} />
                  ) : loadingLocations ? (
                    <Margin top={20}>
                      <CircularProgress className="blue" size={15} />{' '}
                      {t('tables.loadingCountries')}
                    </Margin>
                  ) : (
                    <FormControl className="height-50px" required fullWidth>
                      <CustomInputLabel
                        style={labelStyle}
                        shrink={country instanceof Object}
                      >
                        {t('buildings.country')}
                      </CustomInputLabel>
                      <SearchDropdown
                        loading={locations.length === 0}
                        list={locations}
                        selection={country}
                        selectItem={item => selectItem('country', item)}
                      />
                      {errorLocations && (
                        <FormHelperText>{errorLocations}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Grid>
                <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-60">
                  {loading ? (
                    <Skeleton width={100} />
                  ) : loadingStates ? (
                    <Margin top={20}>
                      <CircularProgress className="blue" size={15} />{' '}
                      {t('buildings.loadingStates')}
                    </Margin>
                  ) : (
                    <FormControl className="height-50px" required fullWidth>
                      <CustomInputLabel
                        style={labelStyle}
                        shrink={state instanceof Object}
                      >
                        {t('buildings.state')}
                      </CustomInputLabel>
                      <SearchDropdown
                        loading={states.length === 0}
                        list={states}
                        selection={state}
                        selectItem={item => selectItem('state', item)}
                      />
                      <FormHelperText>
                        {country instanceof Object
                          ? errorStates
                          : t('tables.selectCountryFirst')}
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>
                <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-60">
                  {loading ? (
                    <Skeleton width={100} />
                  ) : loadingCities ? (
                    <Margin top={20}>
                      <CircularProgress className="blue" size={15} />{' '}
                      {t('buildings.loadingCities')}
                    </Margin>
                  ) : (
                    <FormControl className="height-50px" required fullWidth>
                      <CustomInputLabel
                        style={labelStyle}
                        shrink={city instanceof Object}
                      >
                        {t('buildings.city')}
                      </CustomInputLabel>
                      <SearchDropdown
                        loading={cities.length === 0}
                        list={cities}
                        selection={city}
                        selectItem={item => selectItem('city', item)}
                      />
                      <FormHelperText>
                        {state instanceof Object
                          ? errorCities
                          : t('tables.selectStateFirst')}
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>
                <Grid item md={5} xs={12} style={{ marginTop: 19 }} className="height-60">
                  {loading ? (
                    <Skeleton width={100} />
                  ) : (
                    <FormControl className="height-50px" required fullWidth>
                      <CustomInputLabel style={{ display: "flex" }}>
                        {' '}
                        {t('common.csvUpload')}{' '}
                      </CustomInputLabel>
                      <Select
                        value={csv_upload.value}
                        onChange={handleChange('csv_upload')}
                        style={{ font: 'inherit' }}
                        input={<CustomInput />}
                      >
                        {csvOptions.map(item => (
                          <MenuItem value={item.value} key={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item md={5} xs={12} className="height-60" style={{ marginTop: 24 }}>
                  {loading ? (
                    <Skeleton width={100} />
                  ) : (
                    <Tooltip title={t('buildings.replyToTooltip')}>
                      <CustomTextField
                        fullWidth
                        label={t('buildings.replyToFiled')}
                        value={reply_to}
                        onChange={handleChange('reply_to')}
                        InputProps={{ style: inputStyle }}
                        InputLabelProps={{ style: labelStyle }}
                        FormHelperTextProps={{ style: FormHelperStyle }}
                        helperText={replyToErrors ? t('emailValidation') : ''}
                        className="height-50px"
                      />
                    </Tooltip>)}
                </Grid>
              </Grid>
            )}
          </div>
        </Paper>
      </Grid>
      <MailroomList
        {...props}
        height={400}
        maxHeight={width === 'xs' && 405}
        buildingDetail
      />
      <AssociatesList height={450} {...props} buildingDetail setDataForDeleteUser={setDataForDeleteUser} />
      {enableEmails && <>
        <EmailTemplate
          emails={emails}
          error={emailError}
          loading={loadingEmails}
          building_id={building_id}
          style={style}
          width={width}
          t={t}
          tenant_id={tenant_id}
          buildings={buildings}
        />
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="margin-bottom-15">
          <Preferences
            type="building_detail"
            {...props}
            height={480}
            hourSelection={hourSelection}
            minSelection={minSelection}
            comp="buildingDetails"
          />
        </Grid>
        <Notifications {...props} />
      </>
      }
      
      { !(env === "wework" || env === "wework_cn") && enableActionItems && <CTAToggle
        type="building_detail"
        {...props}
        height={480}
        hourSelection={hourSelection}
        minSelection={minSelection}
        comp="buildingDetails"
      />}
    </Grid>
  );
}

BuildingDetail.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // email: PropTypes.string.isRequired,
  // address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  style: PropTypes.node.isRequired,
  emailError: PropTypes.bool.isRequired,
  nameError: PropTypes.bool.isRequired,
  addressError: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  errorLocations: PropTypes.string.isRequired,
  locations: PropTypes.node.isRequired,
  states: PropTypes.node.isRequired,
  cities: PropTypes.node.isRequired,
  emails: PropTypes.string.isRequired,
  selectItem: PropTypes.func.isRequired,
  setDataForDeleteUser: PropTypes.func.isRequired,
  buildings: PropTypes.object.isRequired,
};

export default BuildingDetail;
