import React from 'react';
import moment from 'moment';
import { Divider, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getCommaSeperatedNumber } from '../../../../util/utility.functions';

class PaymentHistorySingle extends React.Component {
  state = {};
  render() {
    const { record, buildings } = this.props;
    const offset = buildings[0] && buildings[0].buildings[0] ? buildings[0].buildings[0].time_zone_offset : null;
    const dateTime = offset ? moment.utc(record.created_at).utcOffset(offset).format('MMMM Do YYYY, h:mm a') : moment.utc(record.created_at).format('MMMM Do YYYY, h:mm a');

    if (!record || !record.description) return <></>;
    const getPlan = () => {
      let st = record.description;
      if (record.description && record.description.toLowerCase() === 'custom') {
        st = 'Enterprise';
      } else if (record.description && record.description.toLowerCase() === 'starter_custom') {
        st = 'Customized Starter';
      } else if (record.description && record.description.toLowerCase() === 'basic_custom') {
        st = 'Customized Basic';
      } else if (record.description && record.description.toLowerCase() === 'premium_custom') {
        st = 'Customized Premium';
      }
      return st;
    };
    return (
      <>
        <Grid container className="payment-history-single">
          <Grid xs={6} sm={4}>
            {dateTime}
          </Grid>
          <Grid xs={6} sm={4}>
            {`$${record.amount ? getCommaSeperatedNumber(record.amount) : '0'}`}
          </Grid>
          <Grid xs={6} sm={4}>
            {`${getPlan().charAt(0).toUpperCase() + getPlan().slice(1).toLowerCase()} plan fee`}
          </Grid>
        </Grid>
        <Divider />
      </>
    );
  }
}

PaymentHistorySingle.propTypes = {
  record: PropTypes.object.isRequired,
  buildings: PropTypes.array.isRequired,
};

export default PaymentHistorySingle;
