import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import Dropzone from 'react-dropzone';
import {
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Select,
  MenuItem, Avatar,
} from '@material-ui/core';
import BuildingSelector from '../../AddAssociate/components/BuildingSelector';
import {
  CustomTextField,
  CustomInput,
  CustomInputLabel,
} from '../../../UI/Input';
import newuser from '../../../../assets/images/new-user.svg';
import WarningAlert from '../../../../shared/components/Alert';
// import Switch from '../../../UI/Switch';
// import BlueButton from '../../../UI/BlueButton';

const AssociatesIconInactive = require('../../../Layout/sidebar/AssociatesIconInactive.png');

function AssociateDetail(props) {
  const [fileSizeWarning, setValue] = React.useState(null);
  const {
    t,
    loading,
    error,
    handleChange,
    name,
    email,
    picture,
    emailError,
    nameError,
    loadingRoles,
    roles,
    loadimage,
    roleCode,
    userRole,
    rolesError,
    selectedRole,
    role,
    /* deactivate,
    width,
    deleting, */
  } = props;

  const inputStyle = { fontSize: 14, color: '#000', width: '100%' };
  const labelStyle = { fontSize: 13 };
  const HelperTextStyle = { fontSize: 10 };
  // const loadingimage = async accepted => {
  //   if (accepted[0].size > 1000000) {
  //     setValue(t('common.fileLargerThan1Mb'));
  //   } else {
  //     const reader = new window.FileReader();
  //     reader.readAsDataURL(accepted[0]);
  //     reader.onloadend = () => {
  //       loadimage(reader.result);
  //     };
  //   }
  // };

  function imageExists() {
    let img = new Image();
    img.onerror = () => { loadimage(newuser); };
    img.src = newuser;
  }

  // const getDisableEditRight = () => {
  //   if (userRole.toLowerCase() === 'operator') return false;
  //   else if (userRole.toLowerCase() === 'manager') {
  //     if (selectedRole.toLowerCase() === 'operator') return false;
  //     else return true;
  //   } else if (userRole.toLowerCase() === 'admin') {
  //     if (selectedRole.toLowerCase() === 'manager') return false;
  //     else if (selectedRole.toLowerCase() === 'operator') return false;
  //     else return true;
  //   } else if (userRole.toLowerCase() === 'super_admin') {
  //     if (selectedRole.toLowerCase() === 'admin') return false;
  //     else if (selectedRole.toLowerCase() === 'manager') return false;
  //     else if (selectedRole.toLowerCase() === 'operator') return false;
  //     else return true;
  //   } else return true;
  // };

  imageExists();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Paper
          style={{
            height: 370,
            overflow: 'auto',
            marginRight: 10,
          }}
          className="paper"
        >
          {fileSizeWarning && <WarningAlert variant="error" message={fileSizeWarning} open onClose={() => setValue(null)} />}
          <div className="card-body overflowx-hidden">
            <div className="flex card__title">
              <img style={{ height: 20, width: 20 }} src={AssociatesIconInactive} alt="" />
              &nbsp;
              <h5 className="bold-text">{t('associates.information')}</h5>
            </div>
            {error && <p className="align-center"><b> {t('common.error.networkError')} </b></p>}
            {!error && (
              <Grid container direction="row" justify="space-between">
                <Grid item justify="center" className="flex" xs={12} sm={12} md={12}>
                  <FormControl>
                    <Dropzone
                      // disabled={roleCode === 'admin' || userRole === roleCode || loading}
                      disabled
                      className="height-width-80px"
                      accept="image/png, image/jpg, image/jpeg"
                      multiple={false}
                    // onDrop={accepted => loadingimage(accepted)}
                    >
                      <Avatar
                        src={newuser}
                        className={
                          `height-width-80px margin-bottom-20 border-radius-0 ${!picture && 'padding-10px'}
                          ${picture && picture.includes('/media') ? 'border-radius-0' : ''}`
                        }
                      />
                    </Dropzone>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                  {loading ? (
                    <Skeleton width={100} />
                  ) : (
                    <CustomTextField
                      fullWidth
                      label={t('tables.name')}
                      value={name}
                      InputProps={{ style: inputStyle }}
                      InputLabelProps={{ style: labelStyle }}
                      onChange={handleChange('name')}
                      disabled={userRole === roleCode && userRole !== 'admin'}
                      helperText={nameError ? t('common.error.nameValidation') : ''}
                      FormHelperTextProps={{ style: { color: '#FF1E59' } }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={5} style={{ marginTop: 19 }}>
                  {loading ? (
                    <Skeleton width={100} />
                  ) : (
                    <CustomTextField
                      fullWidth
                      disabled
                      // disabled={getDisableEditRight()}
                      // disabled={roleCode === 'admin' || userRole === roleCode}
                      label={t('tables.email')}
                      value={email}
                      InputProps={{ style: inputStyle }}
                      InputLabelProps={{ style: labelStyle }}
                      FormHelperTextProps={{ style: HelperTextStyle }}
                      onChange={handleChange('email')}
                      helperText={emailError ? t('emailValidation') : ''}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={5} lg={5} xl={5} style={{ marginTop: 19 }}>
                  {loading
                    ? (
                      <Skeleton width={100} />
                    ) : userRole === roleCode && userRole !== 'admin'
                      ? (
                        <CustomTextField
                          disabled
                          label={t('tables.role')}
                          value={role}
                          style={{ width: '100%' }}
                          InputProps={{ style: inputStyle }}
                          InputLabelProps={{ style: labelStyle }}
                        />
                      )
                      : (
                        loadingRoles
                          ? (
                            <>
                              <CircularProgress size={15} className="blue margin-top-20px" />{' '}
                              &nbsp; {t('tables.loadingRoles')}
                            </>
                          )
                          : (
                            rolesError || (
                              <FormControl required fullWidth>
                                <CustomInputLabel>
                                  {t('tables.role')}
                                </CustomInputLabel>
                                <Select
                                  value={selectedRole}
                                  onChange={handleChange('selectedRole')}
                                  input={
                                    <CustomInput
                                      style={inputStyle}
                                      name="selectedRole"
                                      id="uncontrolled-native"
                                    />
                                  }
                                >
                                  {roles.map(roleOption => (
                                    <MenuItem value={roleOption.value}>
                                      {roleOption.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )
                          )
                      )}
                </Grid>
              </Grid>
            )}
          </div>
        </Paper>
      </Grid>
      <BuildingSelector {...props} height="370px" edit />
    </Grid>
  );
}

AssociateDetail.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  style: PropTypes.array.isRequired,
  emailError: PropTypes.bool.isRequired,
  nameError: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  roleCode: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  deactivate: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
};

export default AssociateDetail;
