import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  getStripePaymentDetails,
  setPaymentPlan,
  contactSalesTeam,
} from '../../../server/payment';
import { Button } from './Styled';
import ContactSalesResponse from './ContactSalesResponse';

function TrailAboutToExpire(props) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [openContactSalesResponseModal, setOpenContactSalesResponseModal] = useState(false);

  const { userRole, t, buildings } = props;
  const { billing_method } = buildings;
  const isStripeCustomer = billing_method && billing_method.toLowerCase() === 'stripe';

  const setPaymentCheckout = (stripeId) => {
    setPaymentPlan(stripeId)
      .then(async (res) => {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: res.data.id,
        });

        if (result.error) {
          return res;
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
        return res;
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setError('Unable to set payment plan');
        setLoading(false);
        console.log(error);
      });
  };

  const addPaymentInfo = () => {
    setLoading(true);
    getStripePaymentDetails()
          .then((res) => {
            setPaymentCheckout(res.data.stripe_price_id);
          })
          .catch((err) => {
            console.log(err);
            setError('Unable to fetch stripe payment details');
          }).finally(() => {
            setLoading(false);
        });
  };

  const contactSales = () => {
    if (
      buildings &&
      buildings.length > 0 &&
      buildings[0].tier &&
      buildings[0].tenant_name
    ) {
      if (loading) return;
      setLoading(true);
      const { tenant_name } = buildings[0];
      contactSalesTeam(tenant_name)
    .then(() => {
                setOpenContactSalesResponseModal(true);
            }).catch((err) => {
              console.log(err);
            }).finally(() => {
                setLoading(false);
            });
    }
    };

  const forStripeCustomer = () => {
    let message = '';
    if (userRole === 'admin') {
      message = `You have only ${props.days} ${
        props.days === 1 ? 'day' : 'days'
      } left on your 14-day Trial and it looks like you have not yet added a credit/debit card. Add a card to continue using Mailroom by PackageX after your trial period ends.`;
    } else {
      message = `You have only ${props.days} ${
        props.days === 1 ? 'day' : 'days'
      } left on your 14-day Trial. Please contact your Admin`;
    }
    return (
      <>
        <h3 className="pm-heading">
          {props.days} {props.days > 1 ? 'Days' : 'Day'}{' '}
          <span className="pm-heading-red">
            {t('paymentModals.trialAboutToExpire.left')}
          </span>
        </h3>
        <p className="pm-description">{message}</p>
        <div className="pm-single-button-section">
          {userRole === 'admin' ? <Button
            onClick={() => {
                addPaymentInfo();
              }}
            className="pm-cp-upgrade-btn"
          >
            {t('paymentModals.upgradePlan.addPaymentInfoBtn')} {loading && <CircularProgress size={15} className="white" />}
          </Button> : <Button
                                    onClick={() => {
                props.onClose(null);
              }}
                                    className="pm-cp-upgrade-btn"
                                  >
              okay {loading && <CircularProgress size={15} className="white" />}
                                              </Button>
            }
        </div>
      </>
    );
  };


  const forNonStripeCustomer = () => {
    let message = '';
    if (userRole === 'admin') {
      message = `You have only ${props.days} ${
        props.days === 1 ? 'day' : 'days'
      } left on your free trial. If you like the Mailroom by PackageX experience, contact sales today to purchase a plan that suits you!`;
    } else {
      message = `You have only ${props.days} ${
        props.days === 1 ? 'day' : 'days'
      } left on your free trial. Please contact your Admin`;
    }
    return (
      <>
        <h3 className="pm-heading">
          {props.days} {props.days > 1 ? 'Days' : 'Day'}{' '}
          <span className="pm-heading-red">
            {t('paymentModals.trialAboutToExpire.left')}
          </span>
        </h3>
        <p className="pm-description">{message}</p>
        <div className="pm-single-button-section">
          {userRole === 'admin' ? <Button
            onClick={() => {
                contactSales();
              }}
            className="pm-cp-upgrade-btn"
          >
              Contact Sales {loading && <CircularProgress size={15} className="white" />}
          </Button> : <Button
                                    onClick={() => {
                props.onClose(null);
              }}
                                    className="pm-cp-upgrade-btn"
                                  >
              okay {loading && <CircularProgress size={15} className="white" />}
                                              </Button>
            }
        </div>
      </>
    );
  };

  const closeModal = () => {
    props.onClose(null);
  };

  const closeContactSalesResponseModal = () => {
    setOpenContactSalesResponseModal(false);
    closeModal();
    props.history.push('/dashboard');
  };


  return (
    <div>
      <Dialog keepMounted maxWidth="sm" onClose={closeModal} open={props.open}>
        <DialogContent className="pm-modal-container">
          {isStripeCustomer ? forStripeCustomer() : forNonStripeCustomer()}
          <ContactSalesResponse
            open={openContactSalesResponseModal}
            onClose={closeContactSalesResponseModal}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

TrailAboutToExpire.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  days: PropTypes.number.isRequired,
  buildings: PropTypes.array.isRequired,
  history: PropTypes.node.isRequired,
};

export default withRouter(TrailAboutToExpire);
