/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import { Done } from "@material-ui/icons";

const GbLng = props => (
  <>

    <p className="language-icon">EN</p>
    <span
      style={props.full ? { marginLeft: 8, fontSize: 13, color: "#000" } : {}}
    >
      {props.full ? "English" : ""}
    </span>
  </>
);

const FrLng = props => (
  <>
    <p className="language-icon">FR</p>
    <span
      style={props.full ? { marginLeft: 8, fontSize: 13, color: "#000" } : {}}
    >
      {props.full ? "French" : ""}
    </span>
  </>
);

const DeLng = props => (
  <>
    <p className="language-icon">DE</p>
    <span
      style={props.full ? { marginLeft: 8, fontSize: 13, color: "#000" } : {}}
    >
      {props.full ? "German" : ""}
    </span>
  </>
);

const IdLng = props => (
  <>
    {/* <img src={id} alt="I  " style={flagStyle} /> */}
    <p className="language-icon">ID</p>
    <span
      style={props.full ? { marginLeft: 8, fontSize: 13, color: "#000" } : {}}
    >
      {props.full ? "Indonesian" : ""}
    </span>
  </>
);

const JaLng = props => (
  <>
    <p className="language-icon">JA</p>
    <span
      style={props.full ? { marginLeft: 8, fontSize: 13, color: "#000" } : {}}
    >
      {props.full ? "Japanese" : ""}
    </span>
  </>
);

const KoLng = props => (
  <>
    <p className="language-icon">KO</p>
    <span
      style={props.full ? { marginLeft: 8, fontSize: 13, color: "#000" } : {}}
    >
      {props.full ? "Korean" : ""}
    </span>
  </>
);

const NeLng = props => (
  <>
    <p className="language-icon">NE</p>
    <span
      style={props.full ? { marginLeft: 8, fontSize: 13, color: "#000" } : {}}
    >
      {props.full ? "Dutch" : ""}
    </span>
  </>
);

const PoLng = props => (
  <>
    <p className="language-icon">PO</p>
    <span
      style={props.full ? { marginLeft: 8, fontSize: 13, color: "#000" } : {}}
    >
      {props.full ? "Polish" : ""}
    </span>
  </>
);

const PtLng = props => (
  <>
    <p className="language-icon">PT</p>

    <span
      style={props.full ? { marginLeft: 8, fontSize: 13, color: "#000" } : {}}
    >
      {props.full ? "Portuguese" : ""}
    </span>
  </>
);

const RuLng = props => (
  <>
    <p className="language-icon">RU</p>

    <span
      style={props.full ? { marginLeft: 8, fontSize: 13, color: "#000" } : {}}
    >
      {props.full ? "Russian" : ""}
    </span>
  </>
);

const SpLng = props => (
  <>
    <p className="language-icon">SP</p>

    <span
      style={props.full ? { marginLeft: 8, fontSize: 13, color: "#000" } : {}}
    >
      {props.full ? "Spanish" : ""}
    </span>
  </>
);

const ZhLng = props => (
  <>
    <p className="language-icon">ZH</p>
    <span
      style={props.full ? { marginLeft: 8, fontSize: 13, color: "#000" } : {}}
    >
      {props.full ? "Chinese" : ""}
    </span>
  </>
);

const languagesList = {
  en: <GbLng />,
  fr: <FrLng />,
  de: <DeLng />,
  id: <IdLng />,
  ja: <JaLng />,
  ko: <KoLng />,
  ne: <NeLng />,
  po: <PoLng />,
  pt: <PtLng />,
  ru: <RuLng />,
  sp: <SpLng />,
  zh: <ZhLng />,
  gb: <GbLng />,
};

class TopbarLanguage extends Component {
  static propTypes = {
    i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
    style: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      mainButtonContent:
        localStorage.getItem("language") !== null ? (
          languagesList[localStorage.getItem("language")]
        ) : (
          <GbLng />
        ),
    };
    this.props.i18n.changeLanguage(
      localStorage.getItem("language") !== null
        ? localStorage.getItem("language")
        : "en",
    );
  }

  changeLanguage = lng => {
    localStorage.setItem("language", lng);
    this.props.i18n.changeLanguage(lng);
    const setLanguage = languagesList[lng]
      ? languagesList[lng]
      : languagesList.gb;
    this.setState({
      anchorEl: null,
      mainButtonContent: setLanguage,
    });
  };

  render() {
    const { style } = this.props;
    let lng =
      localStorage.getItem("language") !== null
        ? localStorage.getItem("language")
        : "en";
    const languagesWithLinks = [
      {
        text: "zh",
        tag: <ZhLng full />,
      },
      {
        text: "ne",
        tag: <NeLng full />,
      },
      {
        text: "en",
        tag: <GbLng full />,
      },
      {
        text: "de",
        tag: <DeLng full />,
      },
      {
        text: "id",
        tag: <IdLng full />,
      },
      {
        text: "ja",
        tag: <JaLng full />,
      },
      {
        text: "ko",
        tag: <KoLng full />,
      },

      {
        text: "po",
        tag: <PoLng full />,
      },
      {
        text: "pt",
        tag: <PtLng full />,
      },
      {
        text: "ru",
        tag: <RuLng full />,
      },
      {
        text: "sp",
        tag: <SpLng full />,
      },
    ];
    const { anchorEl, mainButtonContent } = this.state;

    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <IconButton
          onClick={e => this.setState({ anchorEl: e.currentTarget })}
          style={{ cursor: "pointer", color: "#027AFF" }}
        >
          {mainButtonContent}
        </IconButton>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          MenuListProps={{
            style: { width: 180, borderRadius: 4 },
          }}
          disableAutoFocusItem
          onClose={() => this.setState({ anchorEl: null })}
          style={{ marginTop: 45, marginLeft: 12 }}
          PaperProps={{
            style: {
              marginRight: 20,
              paddingTop: 0,
              boxShadow:
                "rgba(0, 0, 0, 0) 0px 6px 7px -4px, rgba(0, 0, 0, 0) 0px 11px 15px 1px, rgba(0, 0, 0, 0.12) 0px 4px 20px 3px",
            },
          }}
        >
          {languagesWithLinks.map(item => (
            <MenuItem button onClick={() => this.changeLanguage(item.text)}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex" }}>{item.tag}</div>
                <div>
                  {lng === item.text && (
                    <Done
                      style={{ ...style.common.fs_18, ...style.colors.blue }}
                    />
                  )}
                </div>
              </div>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default TopbarLanguage;
