import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import usps from '../../../../assets/images/couriers/usps.svg';
import others from '../../../../assets/images/couriers/others.svg';
// import confidential from '../../../../assets/images/categories/confidential.svg';
// import fragile from '../../../../assets/images/categories/fragile.svg';
// import oversize from '../../../../assets/images/categories/oversize.svg';
// import returntosender from '../../../../assets/images/categories/return-to-sender.svg';
// import timesensitive from '../../../../assets/images/categories/time-sensitive.svg';
// import other from '../../../../assets/images/categories/other.svg';
import fedex from '../../../../assets/images/couriers/fedex.svg';
import ups from '../../../../assets/images/couriers/ups.svg';
import amazon from '../../../../assets/images/couriers/amazon.svg';
import canpar from '../../../../assets/images/couriers/canpar.svg';
import anPost from '../../../../assets/images/couriers/an-post.svg';
import aramex from '../../../../assets/images/couriers/aramex.svg';
import dhl from '../../../../assets/images/couriers/dhl.svg';
import couriersPlease from '../../../../assets/images/couriers/couriers- please.svg';
import directFreightExpress from '../../../../assets/images/couriers/direct-freight-express.svg';
import dpdCourier from '../../../../assets/images/couriers/dpd-courier.svg';
import dxMail from '../../../../assets/images/couriers/dx-mail.svg';
import dynamex from '../../../../assets/images/couriers/dynamex.svg';
import fastway from '../../../../assets/images/couriers/fastway.svg';
import hongKongPost from '../../../../assets/images/couriers/hong-kong-post.svg';
import indiaPost from '../../../../assets/images/couriers/india-post.svg';
import japanPost from '../../../../assets/images/couriers/japan-post.svg';
import laserShip from '../../../../assets/images/couriers/laser-ship.svg';
import mark3 from '../../../../assets/images/couriers/mark-3.svg';
import maxx from '../../../../assets/images/couriers/maxx.svg';
import onTrac from '../../../../assets/images/couriers/on-trac.svg';
import purolator from '../../../../assets/images/couriers/purolator.svg';
import qantasFreight from '../../../../assets/images/couriers/qantas-freight.svg';
import royalMail from '../../../../assets/images/couriers/royal-mail.svg';
import singaporePost from '../../../../assets/images/couriers/singapore-post.svg';
import smartfreight from '../../../../assets/images/couriers/smart-freight.svg';
import starTrack from '../../../../assets/images/couriers/star-track.svg';
import tnt from '../../../../assets/images/couriers/tnt.svg';
import tollHoldings from '../../../../assets/images/couriers/toll-holdings.svg';
import australiaPost from '../../../../assets/images/couriers/australia-post.svg';
import canadaPost from '../../../../assets/images/couriers/canada-post.svg';
import cdl from '../../../../assets/images/couriers/cdl.svg';
import chinaEms from '../../../../assets/images/couriers/china-ems.svg';
import chinaPost from '../../../../assets/images/couriers/china-post.svg';
import sagawaExpress from '../../../../assets/images/couriers/sagawa-express.svg';
import yamato from '../../../../assets/images/couriers/yamato.svg';
import pakistanPost from '../../../../assets/images/couriers/pakistan-post.svg';
import yunExpress from '../../../../assets/images/couriers/yun-express.svg';
import ytoExpress from '../../../../assets/images/couriers/yto-express.svg';
import sfExpress from '../../../../assets/images/couriers/sf-express.svg';
import ztoExpress from '../../../../assets/images/couriers/zto-express.svg';
import nanjingWoyuan from '../../../../assets/images/couriers/nanjing-woyuan.svg';
import yanwen from '../../../../assets/images/couriers/yanwen.svg';
import dpexChina from '../../../../assets/images/couriers/dpex-china.svg';
import zjsExpress from '../../../../assets/images/couriers/zjs-express.svg';
import sto from '../../../../assets/images/couriers/sto.svg';
import stoExpress from '../../../../assets/images/couriers/sto-express.svg';
import equickChina from '../../../../assets/images/couriers/equick-china.svg';
import yundaExpress from '../../../../assets/images/couriers/yunda-express.svg';
import bestExpress from '../../../../assets/images/couriers/best-express.svg';
import yto from '../../../../assets/images/couriers/yto.svg';
import jd from '../../../../assets/images/couriers/jd.svg';
import parcel from '../../../../assets/images/couriers/parcel.svg';
import lso from '../../../../assets/images/couriers/lso.svg';
import stat from '../../../../assets/images/couriers/stat.svg';
import bring from '../../../../assets/images/couriers/Bring_logo.svg';
import srGroup from '../../../../assets/images/couriers/sr-group.svg';
import JetpakLogo from '../../../../assets/NewCarriers/Jetpak.svg';
import kuehneNagelLogo from '../../../../assets/NewCarriers/Kuehne.svg';
import LogoTransLogo from '../../../../assets/NewCarriers/LogoTrans.svg';
import MerkesdalLogo from '../../../../assets/NewCarriers/Merkesdal.svg';
import NorlinesLogo from '../../../../assets/NewCarriers/Norlines.svg';
import PentagonLogo from '../../../../assets/NewCarriers/Pentagon.svg';
import PostNordLogo from '../../../../assets/NewCarriers/PostNord.svg';
import TransferdLogo from '../../../../assets/NewCarriers/Transferd.svg';
import DBLogo from '../../../../assets/NewCarriers/DB.svg';
import LoomisExpress from '../../../../assets/NewCarriers/LoomisExpress.svg';
import DicomLogo from '../../../../assets/NewCarriers/DicomLogo.svg';

import Seur from '../../../../assets/images/couriers/seur.svg';
import Mrw from '../../../../assets/images/couriers/mrw.svg';
import Envalia from '../../../../assets/images/couriers/envalia.svg';
import Correos from '../../../../assets/images/couriers/correos.svg';
import CorreosExpress from '../../../../assets/images/couriers/correos-express.svg';

import Sending from '../../../../assets/images/couriers/sending.svg';
import Redur from '../../../../assets/images/couriers/redur.svg';
import Nacex from '../../../../assets/images/couriers/nacex.svg';
import GLS from '../../../../assets/images/couriers/gLS.svg';
import CTTExpress from '../../../../assets/images/couriers/ctt-express.svg';
import SwissPost from '../../../../assets/images/couriers/swiss-post.svg';

import blue_dart from '../../../../assets/images/couriers/blue_dart.svg';
import chronopost from '../../../../assets/images/couriers/chronopost.svg';
import colissimo from '../../../../assets/images/couriers/colissimo.svg';
import dtdc from '../../../../assets/images/couriers/DTDC.svg';
import shree_maruti from '../../../../assets/images/couriers/shree-maruti.svg';
import parcel_force from '../../../../assets/images/couriers/parcel_force.svg';
import rl_carriers from '../../../../assets/images/couriers/rl_carriers.svg';
import pittohio from '../../../../assets/images/couriers/pittohio.svg';
import zoom2u from '../../../../assets/images/couriers/zoom2u.svg';

const Flag = props => {
  const {
    name,
    courierBreakdown,
    t,
    image,
  } = props;
  let icon = null;
  let label = name;
  const style = { height: 30, width: 30 };
  const labels = { height: 20, width: 20 };
  // } else if (name && (name.includes('smart freight') || name.includes('smart-freight'))) {
  if (courierBreakdown) { // if component is be used as a courier breakdown on dashboard page
    if (name && name.includes('usps')) { // if the carrier name is USPS, show the respective icon
      icon = <img src={usps} style={style} alt="courier-logo" />;
      label = 'USPS';
    } else if (name && name.includes('fedex')) { // if the carrier name is FedEx, show the respective icon
      icon = <img src={fedex} style={style} alt="courier-logo" />; // set height width of rectangular icon
      label = 'FedEx';
    } else if (name && name.includes('amazon')) { // if the carrier name is Amazon, show the respective icon
      icon = <img src={amazon} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'Amazon';
    } else if (name && name.includes('amazon-fba-us') || name.includes('amazon fba us')) { // if the carrier name is Amazon-fba-us, show the respective icon
      icon = <img src={amazon} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'Amazon-fba-us';
    } else if (name && name.includes('ups')) { // if the carrier name is UPS, show the respective icon
      icon = <img src={ups} style={style} alt="courier-logo" />;
      label = 'UPS';
    } else if (name && name.includes('canpar')) { // if the carrier name is Canpar, show the respective icon
      icon = <img src={canpar} style={style} alt="courier-logo" />;
      label = 'Canpar';
    } else if (name && name.includes('an-post') || name.includes('an post')) { // if the carrier name is An Post, show the respective icon
      icon = <img src={anPost} style={style} alt="courier-logo" />;
      label = 'An Post';
    } else if (name && name.includes('aramex')) { // if the carrier name is Aramex, show the respective icon
      icon = <img src={aramex} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'Aramex';
    } else if (name && name.includes('dhl')) { // if the carrier name is dhl, show the respective icon
      icon = <img src={dhl} style={style} alt="courier-logo" />;
      label = 'dhl';
    } else if (name && name.includes('couriersplease') || name.includes('couriers please') || name.includes('couriers-please')) { // if the carrier name is Couriers Please, show the respective icon
      icon = <img src={couriersPlease} style={style} alt="courier-logo" />;
      label = 'Couriers Please';
    } else if (name && name.includes('dfe') || name.includes('direct express') || name.includes('direct-express')) { // if the carrier name is Direct Express, show the respective icon
      icon = <img src={directFreightExpress} style={style} alt="courier-logo" />;
      label = 'Direct Express';
    } else if (name && name.includes('dpd') || name.includes('dpd courier') || name.includes('dpd-courier')) { // if the carrier name is dpd Courier, show the respective icon
      icon = <img src={dpdCourier} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'dpd Courier';
    } else if (name && name.includes('dx-mail') || name.includes('dx mail')) { // if the carrier name is dx Mail, show the respective icon
      icon = <img src={dxMail} style={style} alt="courier-logo" />;
      label = 'dx Mail';
    } else if (name && name.includes('dynamex')) { // if the carrier name is dynamex, show the respective icon
      icon = <img src={dynamex} style={style} alt="courier-logo" />;
      label = 'dynamex';
    } else if (name && name.includes('china-post') || name.includes('china post')) { // if the carrier name is china Post, show the respective icon
      icon = <img src={chinaPost} style={style} alt="courier-logo" />;
      label = 'china Post';
    } else if (name && name.includes('china-ems') || name.includes('china ems')) { // if the carrier name is china Ems, show the respective icon
      icon = <img src={chinaEms} style={style} alt="courier-logo" />;
      label = 'china Ems';
    } else if (name && name.includes('sagawa-express') || name.includes('sagawa express')) { // if the carrier name is sagawa express, show the respective icon
      icon = <img src={sagawaExpress} style={style} alt="courier-logo" />;
      label = 'sagawa express';
    } else if (name && name.includes('yamato')) { // if the carrier name is yamato, show the respective icon
      icon = <img src={yamato} style={style} alt="courier-logo" />;
      label = 'yamato';
    } else if (name && name.includes('fastway')) { // if the carrier name is fastway, show the respective icon
      icon = <img src={fastway} style={style} alt="courier-logo" />;
      label = 'fastway';
    } else if (name && name.includes('hong-kong-post') || name.includes('hong kong post')) { // if the carrier name is HongKong Post, show the respective icon
      icon = <img src={hongKongPost} style={style} alt="courier-logo" />;
      label = 'HongKong Post';
    } else if (name && name.includes('india-post') || name.includes('india post')) { // if the carrier name is India Post, show the respective icon
      icon = <img src={indiaPost} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'India Post';
    } else if (name && name.includes('japan-post') || name.includes('japan post')) { // if the carrier name is Japan Post, show the respective icon
      icon = <img src={japanPost} style={style} alt="courier-logo" />;
      label = 'Japan Post';
    } else if (name && name.includes('lasership') || name.includes('laser ship') || name.includes('laser-ship')) { // if the carrier name is Laser Ship, show the respective icon
      icon = <img src={laserShip} style={style} alt="courier-logo" />;
      label = 'Laser Ship';
    } else if (name && name.includes('mark3')) { // if the carrier name is mark3, show the respective icon
      icon = <img src={mark3} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'mark3';
    } else if (name && name.includes('maxx')) { // if the carrier name is maxx, show the respective icon
      icon = <img src={maxx} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'maxx';
    } else if (name && name.includes('ontrac') || name.includes('on-trac') || name.includes('on trac')) { // if the carrier name is On Trac, show the respective icon
      icon = <img src={onTrac} style={style} alt="courier-logo" />;
      label = 'On Trac';
    } else if (name && name.includes('purolator')) { // if the carrier name is purolator, show the respective icon
      icon = <img src={purolator} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'purolator';
    } else if (name && name.includes('qantas')) { // if the carrier name is Qantas Freight, show the respective icon
      icon = <img src={qantasFreight} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'Qantas Freight';
    } else if (name && name.includes('royal mail') || name.includes('royal-mail')) { // if the carrier name is Royal Mall, show the respective icon
      icon = <img src={royalMail} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'Royal Mail';
    } else if (name && name.includes('singapore-post') || name.includes('singapore post')) { // if the carrier name is Singapore Post, show the respective icon
      icon = <img src={singaporePost} style={style} alt="courier-logo" />;
      label = 'Singapore Post';
    } else if (name && name.includes('smart-freight') || name.includes('smart freight')) { // if the carrier name is Smart Freight, show the respective icon
      icon = <img src={smartfreight} style={style} alt="courier-logo" />;
      label = 'Smart Freight';
    } else if (name && name.includes('star-track') || name.includes('star track')) { // if the carrier name is Star Track, show the respective icon
      icon = <img src={starTrack} style={style} alt="courier-logo" />;
      label = 'Star Track';
    } else if (name && name.includes('tnt')) { // if the carrier name is tnt, show the respective icon
      icon = <img src={tnt} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'tnt';
    } else if (name && name.includes('toll')) { // if the carrier name is Toll Holdings, show the respective icon
      icon = <img src={tollHoldings} style={style} alt="courier-logo" />;
      label = 'Toll Holdings';
    } else if (name && name.includes('australia-post') || name.includes('australia post')) { // if the carrier name is Australia Post, show the respective icon
      icon = <img src={australiaPost} style={style} alt="courier-logo" />;
      label = 'Australia Post';
    } else if (name && name.includes('canada-post') || name.includes('canada post')) { // if the carrier name is Canada Post, show the respective icon
      icon = <img src={canadaPost} style={style} alt="courier-logo" />;
      label = 'Canada Post';
    } else if (name && name.includes('cdl')) { // if the carrier name is cdl, show the respective icon
      icon = <img src={cdl} style={style} alt="courier-logo" />;
      label = 'cdl';
    } else if (name && name.includes('lone-star-overnight') || name.includes('Lone Star Overnight')) {
      icon = <img src={lso} style={style} alt="courier-logo" />;
      label = 'Lone Star Overnight';
    } else if (name && name.includes('stat') || name.includes('STAT')) {
      icon = <img src={stat} style={style} alt="courier-logo" />;
      label = 'Stat Overnight';
    } else if (name && name.includes('Pakistan Post') || name.includes('pakistan post')) {
      icon = <img src={pakistanPost} style={style} alt="courier-logo" />;
      label = 'pakistan post';
    } else if (name && name.includes('YunExpress') || name.includes('yunexpress')) {
      icon = <img src={yunExpress} style={style} alt="courier-logo" />;
      label = 'yun express';
    } else if (name && name.includes('YTO Express') || name.includes('YTO-Express')) {
      icon = <img src={ytoExpress} style={style} alt="courier-logo" />;
      label = 'yto express';
    } else if (name && name.includes('SF Express') || name.includes('SF-Express')) {
      icon = <img src={sfExpress} style={style} alt="courier-logo" />;
      label = 'sf express';
    } else if (name && name.includes('ZTO Express') || name.includes('ZTO-Express')) {
      icon = <img src={ztoExpress} style={style} alt="courier-logo" />;
      label = 'zto express';
    } else if (name && name.includes('Nanjing Woyuan') || name.includes('Nanjing-Woyuan') || name.includes('nanjingwoyuan')) {
      icon = <img src={nanjingWoyuan} style={style} alt="courier-logo" />;
      label = 'Nanjing Woyuan';
    } else if (name && name.includes('Yanwen')) {
      icon = <img src={yanwen} style={style} alt="courier-logo" />;
      label = 'Yanwen';
    } else if (name && name.includes('DPEX China') || name.includes('DPEX-China') || name.includes('szdpex')) {
      icon = <img src={dpexChina} style={style} alt="courier-logo" />;
      label = 'Dpex China';
    } else if (name && name.includes('ZJS Express') || name.includes('ZJS-Express')) {
      icon = <img src={zjsExpress} style={style} alt="courier-logo" />;
      label = 'ZJS Express';
    } else if (name && name.includes('STO')) {
      icon = <img src={sto} style={style} alt="courier-logo" />;
      label = 'STO';
    } else if (name && name.includes('STO Express') || name.includes('STO-Express')) {
      icon = <img src={stoExpress} style={style} alt="courier-logo" />;
      label = 'STO express';
    } else if (name && name.includes('Equick China') || name.includes('Eqick-China') || name.includes('equick-cn')) {
      icon = <img src={equickChina} style={style} alt="courier-logo" />;
      label = 'Equick China';
    } else if (name && name.includes('Yunda Express') || name.includes('Yunda-Express') || name.includes('yundaex')) {
      icon = <img src={yundaExpress} style={style} alt="courier-logo" />;
      label = 'Yunda Express';
    } else if (name && name.includes('Best Express') || name.includes('Best-Express') || name.includes('800bestex')) {
      icon = <img src={bestExpress} style={style} alt="courier-logo" />;
      label = 'Best Express';
    } else if (name && name.includes('YTO') || name.includes('yto-')) {
      icon = <img src={yto} style={style} alt="courier-logo" />;
      label = 'yto';
    } else if (name && name.includes('jd') || name.includes('京东')) {
      icon = <img src={jd} style={style} alt="courier-logo" />;
      label = 'jd';
    } else if (name && name === 'parcel') {
      icon = <img src={parcel} style={style} alt="courier-logo" />;
      label = 'parcel';
    } else if (name && name.includes('Bring') || name.includes('bring')) {
      icon = <img src={bring} style={style} alt="courier-logo" />;
      label = 'Bring';
    } else if (name && name.includes('sr-group') || name.includes('SR Group') || name.includes('sr group')) {
      icon = <img src={srGroup} style={style} alt="courier-logo" />;
      label = 'SR Group';// ======= New Carriers =======
    } else if (name && name.includes('pentagon-freight') || name.includes('Pentagon Freight') || name.includes('pentagon freight')) {
      icon = <img src={PentagonLogo} style={style} alt="courier-logo" />;
      label = 'Pentagon Freight';
    } else if (name && name.includes('merkesdal') || name.includes('Merkesdal') || name.includes('Merkesdal')) {
      icon = <img src={MerkesdalLogo} style={style} alt="courier-logo" />;
      label = 'Merkesdal';
    } else if (name && name.includes('logi-trans') || name.includes('Logi-Trans') || name.includes('Logi Trans')) {
      icon = <img src={LogoTransLogo} style={style} alt="courier-logo" />;
      label = 'Logi Trans';
    } else if (name && name.includes('postnord') || name.includes('Postnord') || name.includes('Postnord')) {
      icon = <img src={PostNordLogo} style={style} alt="courier-logo" />;
      label = 'Postnord';
    } else if (name && name.includes('db-schenker') || name.includes('Db-Schenker') || name.includes('DB-Schenker') || name.includes('DB Schenker')) {
      icon = <img src={DBLogo} style={style} alt="courier-logo" />;
      label = 'DB Schenker';
    } else if (name && name.includes('transferd') || name.includes('Transferd') || name.includes('Transferd')) {
      icon = <img src={TransferdLogo} style={style} alt="courier-logo" />;
      label = 'Transferd';
    } else if (name && name.includes('kuehne-nagel') || name.includes('Kuehne-Nagel') || name.includes('Kuehne Nagel')) {
      icon = <img src={kuehneNagelLogo} style={style} alt="courier-logo" />;
      label = 'Kuehne Nagel';
    } else if (name && name.includes('nor-lines') || name.includes('Nor-Lines') || name.includes('Nor Lines')) {
      icon = <img src={NorlinesLogo} style={style} alt="courier-logo" />;
      label = 'Nor Lines';
    } else if (name && name.includes('loomis-express') || name.includes('loomis') || name.includes('loomis express') || name.includes('Loomis Express') || name.includes('Loomis-Express')) {
      icon = <img src={LoomisExpress} style={style} alt="courier-logo" />;
      label = 'Loomis Express';
    } else if (name && name.includes('dicom') || name.includes('Dicom')) {
      icon = <img src={DicomLogo} style={style} alt="courier-logo" />;
      label = 'Dicom';
    } else if (name && name.includes('jetpak') || name.includes('Jetpak') || name.includes('Jetpak')) {
      icon = <img src={JetpakLogo} style={style} alt="courier-logo" />;
      label = 'Jetpak'; // ======== New Carriers ========
    } else if (name && name.includes('mrw') || name.includes('MRW') || name.includes('mrw')) {
      icon = <img src={Mrw} style={style} alt="courier-logo" />;
      label = 'MRW'; // ======== New Carriers ========
    } else if (name && name.includes('Envialia') || name.includes('envialia') || name.includes('Envialia')) {
      icon = <img src={Envalia} style={style} alt="courier-logo" />;
      label = 'Envialia'; // ======== New Carriers ========
    } else if (name && name === 'correos' || name === 'Correos' || name === 'correos') {
      icon = <img src={Correos} style={style} alt="courier-logo" />;
      label = 'Correos'; // ======== New Carriers ========
    } else if (name && name === 'correos-express' || name === 'Correos-express' || name === 'Correos Express') {
      icon = <img src={CorreosExpress} style={style} alt="courier-logo" />;
      label = 'Correos Express'; // ======== New Carriers ========
    } else if (name && name.includes('Seur') || name.includes('Seur') || name.includes('seur')) {
      icon = <img src={Seur} style={style} alt="courier-logo" />;
      label = 'Seur'; // ======== New Carriers ========
    } else if (name && name.includes('ctt') || name.includes('CTT') || name.includes('CTT Express')) {
      icon = <img src={CTTExpress} style={style} alt="courier-logo" />;
      label = 'CTT Express'; // ======== New Carriers ========
    } else if (name && name.includes('GLS') || name.includes('gLS') || name.includes('gls')) {
      icon = <img src={GLS} style={style} alt="courier-logo" />;
      label = 'GLS'; // ======== New Carriers ========
    } else if (name && name.includes('Nacex') || name.includes('nacex') || name.includes('Nacex')) {
      icon = <img src={Nacex} style={style} alt="courier-logo" />;
      label = 'Nacex'; // ======== New Carriers ========
    } else if (name && name.includes('Redur') || name.includes('redur') || name.includes('Redur')) {
      icon = <img src={Redur} style={style} alt="courier-logo" />;
      label = 'Redur'; // ======== New Carriers ========
    } else if (name && name.includes('Sending') || name.includes('sending') || name.includes('Sending')) {
      icon = <img src={Sending} style={style} alt="courier-logo" />;
      label = 'Sending'; // ======== New Carriers ========
    } else if (name.includes('swiss-post') || name.includes('Swiss-Post') || name.includes('SWISS-POST') || name.toLowerCase() === 'swiss_post' || name.toLowerCase() === 'swiss-post') { // if the carrier name is others, show the respective icon
      icon = <img src={SwissPost} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'Swiss Post';
    } else if (name.includes('bluedart') || name.includes('blue-dart') || name.includes('Bluedart') || name.toLowerCase() === 'bluedart') { // if the carrier name is others, show the respective icon
      icon = <img src={blue_dart} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'Bluedart';
    } else if (name.includes('chronopost') || name.includes('Chronopost') || name.includes('chronopost') || name.toLowerCase() === 'chronopost') { // if the carrier name is others, show the respective icon
      icon = <img src={chronopost} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'Chronopost';
    } else if (name.includes('colissimo') || name.includes('colissimo') || name.includes('colissimo') || name.toLowerCase() === 'colissimo') { // if the carrier name is others, show the respective icon
      icon = <img src={colissimo} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'Colissimo';
    } else if (name.includes('dtdc') || name.includes('DTDC') || name.includes('dtdc') || name.toLowerCase() === 'dtdc') { // if the carrier name is others, show the respective icon
      icon = <img src={dtdc} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'DTDC';
    } else if (name.includes('shree-maruti') || name.includes('shree-maruti') || name.includes('Shree Maruti') || name.toLowerCase() === 'shree-maruti') { // if the carrier name is others, show the respective icon
      icon = <img src={shree_maruti} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'Shree Maruti';
    } else if (name.includes('parcel-force') || name.includes('parcel-force') || name.includes('Parcel Force') || name.toLowerCase() === 'parcel-force') { // if the carrier name is others, show the respective icon
      icon = <img src={parcel_force} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'Parcel Force';
    } else if (name.includes('rl-carrier') || name.includes('r + l') || name.includes('rl-carrier')) { // if the carrier name is others, show the respective icon
      icon = <img src={rl_carriers} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'RL Carrier';
    } else if (name.includes('pitt-ohio-express') || name.includes('pitt ohio express')) { // if the carrier name is others, show the respective icon
      icon = <img src={pittohio} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'Pitto Ohio Express';
    } else if (name && name.toLowerCase().includes('zoom2u') || name.includes('Zoom2U')) { // if the carrier name is others, show the respective icon
      icon = <img src={zoom2u} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = 'Zoom2U';
    } else if (name.includes('other')) { // if the carrier name is others, show the respective icon
      icon = <img src={others} style={style} alt="courier-logo" />;// set height width of rectangular icon
      label = t('main.others');
    } else {
      label = name && name.split('-', 1)[0];
      icon = null;
    }
  } else {
    icon = <img src={image} style={labels} alt="category-logo" />;
  }

  // else if (label && label.includes('confidential')) { // if component is be used as a package labels on dashboard page and the package label name is confidential, show the respective icon
  //   icon = <img src={confidential} style={labels} alt="category-logo" />;
  // } else if (label && label.includes('time')) { // if component is be used as a package labels on dashboard page and the package label name is timesensitive, show the respective icon
  //   icon = <img src={timesensitive} style={labels} alt="category-logo" />;
  // } else if (label && label.includes('fragile')) { // if component is be used as a package labels on dashboard page and the package label name is fragile, show the respective icon
  //   icon = <img src={fragile} style={labels} alt="category-logo" />;
  // } else if (label && label.includes('oversize')) { // if component is be used as a package labels on dashboard page and the package label name is oversize, show the respective icon
  //   icon = <img src={oversize} style={labels} alt="category-logo" />;
  // } else if (label && label.includes('return')) { // if component is be used as a package labels on dashboard page and the package label name is returntosender, show the respective icon
  //   icon = <img src={returntosender} style={labels} alt="category-logo" />;
  // } else { // if component is be used as a package labels on dashboard page and the package label name is other, show the respective icon
  //   icon = <img src={other} style={labels} alt="category-logo" />;
  // }
  return (
    <div
      style={{
        alignItems: 'center',
        height: '100%',
        fontSize: 14,
        fontWeight: 500,
        display: 'flex',
        width: courierBreakdown ? 50 : 140,
        marginTop: courierBreakdown ? 0
          : 0,
        // marginBottom: courierBreakdown ? 0 : 7,
      }}
    >
      <Tooltip
        className="capitalize"
        title={label.toUpperCase()}
        style={{
          height: courierBreakdown ? 20 : 25,
          // height: 20
        }}
      >
        <div>{icon || label}</div>
      </Tooltip>
      {!courierBreakdown && (
        <div
          className="truncated-heading width-115px"
          style={{
            display: 'flex', alignItems: 'center', marginLeft: 5,
          }}
        >
          {label}
        </div>
      )}
    </div>
  );
};
Flag.propTypes = {
  name: PropTypes.string.isRequired,
  courierBreakdown: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
};
export { Flag };
