/* eslint-disable */

import React from 'react';
import { Divider, Grid } from '@material-ui/core';

// This component renders the top line of payment history section i.e. the names of the sections mentioned in each record
// e.g. date, amount, description etc

class PaymentHistorySections extends React.Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <>
        <Grid
          container
          className="payment-history-single"
          style={{ color: 'grey', opacity: '0.7' }}
        >
          <Grid xs={6} sm={4}>
            {t('paymentHistory.date')}
          </Grid>
          <Grid xs={6} sm={4}>
            {t('paymentHistory.amount')}
          </Grid>
          <Grid xs={6} sm={4}>
            {t('paymentHistory.description')}
          </Grid>
        </Grid>
        <Divider />
      </>
    );
  }
}

export default PaymentHistorySections;
