import axios from 'axios';
import moment from 'moment';
import { server } from './server';

export function getExposeAPIKey(tenantid) {
    const params = {
        tenantid,
    };
    return new Promise((resolve, reject) => {
        axios
            .get(`${server}/entity/exposed/generateapikey`, { params })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
}

export function getAllBuildingsExposeAPI(
    searchedText,
    pagination_limit,
    sortBy,
    sortType,
    offset
) {
    const params = {
        pagination_limit,
        order_by: sortBy,
        order_asc: sortType,
        pagination_offset: offset
    };
    if (searchedText && searchedText !== '') {
        params.query = searchedText;
    }
    return new Promise((resolve, reject) => {
        axios
            .get(`${server}/entity/exposed/building`, { params })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
}

export function getQuotaInformation(tenantid) {
    const params = {
        date: getTodayDate(),
        tenantid
    };
    return new Promise((resolve, reject) => {
        axios
            .get(`${server}/entity/exposed/getapikeyusage`, { params })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
}

function getTodayDate() {
    const currentDate = new Date(moment(new Date()).utc().format('YYYY-MM-DD'));
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    if (parseInt(day, 10) < 10) {
        day = `0${day}`;
    }
    if (parseInt(month, 10) < 10) {
        month = `0${month}`;
    }
    return `${year}-${month}-${day}`;
}
