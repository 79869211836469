/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { withWidth } from '@material-ui/core';
import { Col, Container, Row } from 'reactstrap';
import { withRouter, Link, Redirect } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MemberDetailComp from './components/MemberDetail';
import Alert from '../../UI/Poper';
import WarningAlert from '../../../shared/components/Alert';
import { informSlackDBCodeErrs } from '../../../util/informSlack';
import Footer from "../../UI/Footer/Footer";
import BlueButton from "../../UI/BlueButton";

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

class MemberDetail extends Component {
  state = {
    loading: true,
    member: {},
    error: null,
    editing: true,
    updating: false,
    name: '',
    email: '',
    address: '',
    phone: '',
    office: '',
    business: [],
    position: '',
    status: false,
    perAddress: '',
    floor: '',
    note: '',
    selectedBuilding: '',
    alternateName: [],
    alternatePhone: [],
    alternateEmail: [],
    isDesignated: false,
    isSuperDesignated: false,
    altPhoneError: [],
    businesses: [],
    loadingBus: false,
    errorBus: null,
    emailError: false,
    nameError: false,
    addressError: false,
    officeError: false,
    altEmailError: false,
    altNameError: false,
    phoneError: false,
    memberUpdated: false,
    errorUpdating: false,
    warning: null,
    redirect: false,
    deleting: false,
    profilePicture: null,
    search: '',
    selectedBusiness: {},
    successToast: false,
    isVoMember: false
  };

  componentDidMount() {
    this.getMember(this.props);
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidUpdate(prevProps) {
    const { match } = prevProps;
    if (match.params.id !== this.props.match.params.id) {
      this.getMember(this.props);
    }
    const { searchObj } = this.props;
    if (
      searchObj.member_id &&
      searchObj.member_id.toString() !== this.props.match.params.id
    ) {
      this.getMemberById(this.props);
    }
  }

  getMember = props => {
    this.setState({
      loading: true,
      member: {},
      error: null,
      editing: true,
    });
    const { t, getMember, match, validateEmail, validateName, generalValidation } = props;
    getMember(match.params.id)
      .then(member => {
        if (member) {
          localStorage.alternate_name = JSON.stringify(
            member.alternate_name && member.alternate_name.length > 0
              ? member.alternate_name
              : [''],
          );
          localStorage.alternate_email = JSON.stringify(
            member.alternate_email && member.alternate_email.length > 0
              ? member.alternate_email
              : [''],
          );
          localStorage.alternate_phone = JSON.stringify(
            member.alternate_phone && member.alternate_phone.length > 0
              ? member.alternate_phone
              : [''],
          );
          let phoneError = false;
          if (member.phone && member.phone.length > 0) {
            try {
              const phoneObj = phoneUtil.parse(member.phone);
              phoneError = !phoneUtil.isValidNumber(phoneObj);
            } catch (e) {
              phoneError = true;
            }
          }
          member.status = member.is_active;
          this.setState({
            member,
            isVoMember: member && member.membership && (member.membership === 'VO_MEMBER' || member.membership === 'BOTH'),
            loading: false,
            name: member.name,
            email: member.email,
            phone: member.phone || '',
            address: member.building_name,
            office: member.office_number,
            status: member.is_active,
            floor: member.floor,
            note: member.note,
            phoneError,
            emailError: member.email ? !validateEmail(member.email) : false,
            nameError: member.name ? !validateName(member.name) : false,
            addressError: member.address ? !generalValidation(member.address) : false,
            officeError: member.office ? !generalValidation(member.office) : false,
            perAddress: member.permanent_address,
            selectedBuilding: member.building_id,
            alternateName:
              member.alternate_name && member.alternate_name.length > 0
                ? member.alternate_name
                : [''],
            alternateEmail:
              member.alternate_email && member.alternate_email.length > 0
                ? member.alternate_email
                : [''],
            alternatePhone:
              member.alternate_phone && member.alternate_phone.length > 0
                ? member.alternate_phone
                : [''],
            business: member.member_businesses ? member.member_businesses : [],
            profilePicture: member.profile_picture && (
              <img
                style={{
                  height: 80,
                  width: 80,
                }}
                src={`data:image/jpg;base64,${member.profile_picture}`}
                alt="profile"
              />
            ),
          });
        } else {
          this.setState({ error: 'No member found!' });
        }
      })
      .catch(error =>
        this.setState({
          error: error.response
            ? error.response.data.message.message.includes(
              'Not Found: No member data found for this id',
            )
              ? this.props.t('members.noMemberFound')
              : this.props.t('common.error.something')
            : error.message.includes('must be a number')
              ? t('common.error.idMust')
              : error.message.includes('must be a positive number')
                ? t('common.error.idMustPositive')
                : error.message.includes('is out of range')
                  ? t('common.error.idRange')
                  : t('common.error.networkError'),
          loading: false,
        }),
      );
  };

  getMemberById = props => {
    this.setState({
      loading: true,
      member: {},
      error: null,
      editing: false,
    });
    const { getMember, searchObj } = props;
    getMember(searchObj.member_id)
      .then(member => {
        localStorage.alternate_name = JSON.stringify(
          member.alternate_name && member.alternate_name.length > 0
            ? member.alternate_name
            : [''],
        );
        localStorage.alternate_email = JSON.stringify(
          member.alternate_email && member.alternate_email.length > 0
            ? member.alternate_email
            : [''],
        );
        localStorage.alternate_phone = JSON.stringify(
          member.alternate_phone && member.alternate_phone.length > 0
            ? member.alternate_phone
            : [''],
        );
        let phoneError = false;
        if (member.phone && member.phone.length > 0) {
          try {
            const phoneObj = phoneUtil.parse(member.phone);
            phoneError = !phoneUtil.isValidNumber(phoneObj);
          } catch (e) {
            phoneError = true;
          }
        }
        member.status = member.is_active;
        this.setState({
          member,
          isVoMember: member && member.membership && (member.membership === 'VO_MEMBER' || member.membership === 'BOTH'),
          phoneError,
          loading: false,
          name: member.name,
          email: member.email,
          phone: member.phone || '',
          address: member.building_name,
          office: member.office_number,
          status: member.is_active,
          floor: member.floor,
          note: member.note,
          perAddress: member.permanent_address,
          selectedBuilding: member.building_id,
          alternateName:
            member.alternate_name && member.alternate_name.length > 0
              ? member.alternate_name
              : [''],
          alternateEmail:
            member.alternate_email && member.alternate_email.length > 0
              ? member.alternate_email
              : [''],
          alternatePhone:
            member.alternate_phone && member.alternate_phone.length > 0
              ? member.alternate_phone
              : [''],
          business: member.member_businesses ? member.member_businesses : [],
          profilePicture: member.profile_picture && (
            <img
              style={{
                height: 80,
                width: 80,
              }}
              src={`data:image/jpg;base64,${member.profile_picture}`}
              alt="profile"
            />
          ),
        });
      })
      .catch(error =>
        this.setState({
          error: error.response
            ? error.response.data.message.indexOf('child "id"') !== -1
              ? this.props.t('members.noMemberFound')
              : this.props.t('common.error.something')
            : this.props.t('common.error.networkError'),
          loading: false,
        }),
      );
  };

  setAlternativeName = (name, index) => event => {
    const selectedState = this.state[name];
    selectedState[index] = event.target.value.trimLeft();
    this.setState({
      [name]: selectedState,
      addingAltName: !this.state.addingAltName,
    });
  };

  setAltPhone = (name, index, phone) => {
    let { altPhoneError, alternatePhone } = this.state;
    alternatePhone[index] = `${phone}`;
    if (phone.length < 3) {
      altPhoneError[index] = false;
      this.setState({ alternatePhone, altPhoneError });
    } else {
      try {
        const checkphoneformat = phone.charAt(0) === "+" ? phone : `+${phone}`;
        const phoneObj = phoneUtil.parse(checkphoneformat);
        altPhoneError[index] = !phoneUtil.isValidNumber(phoneObj);
      } catch (err) {
        altPhoneError[index] = true;
      }
      this.setState({
        alternatePhone,
        altPhoneError,
        addingAltName: !this.state.addingAltName,
      });
    }
    this.setState({
      alternatePhone,
      altPhoneError,
      addingAltName: !this.state.addingAltName,
    });
  };

  handlePhone = (name, phone) => {
    if (name === 'phone' && phone) {
      if (phone.length < 8 && phone.length > 2) {
        this.setState({ phone, phoneError: true });
      } else if (phone.length < 3) {
        this.setState({ phone, phoneError: false });
      } else {
        try {
          this.closeAlert();
          const checkphoneformat = phone.charAt(0) === "+" ? phone : `+${phone}`;
          const phoneObj = phoneUtil.parse(checkphoneformat);
          const phoneError = !phoneUtil.isValidNumber(phoneObj);
          this.setState({ phone: `${phone}`, phoneError });
        } catch (err) {
          this.setState({ phone: `${phone}`, phoneError: true });
        }
      }
    } else if (name === 'alternatePhone' && phone) {
      try {
        this.closeAlert();
        const checkphoneformat = phone.charAt(0) === "+" ? phone : `+${phone}`;
        const phoneObj = phoneUtil.parse(checkphoneformat);
        const phoneError = !phoneUtil.isValidNumber(phoneObj);
        this.setState({ alternatePhone: `${phone}`, altPhoneError: phoneError });
      } catch (err) {
        this.setState({ alternatePhone: `${phone}`, altPhoneError: true });
        return null;
      }
      return phone;
    }
  };

  startEdit = () => this.setState({ editing: true });

  cancelEditing = () => {
    const { member } = this.state;
    let alternate_name = [];
    let alternate_email = [];
    let alternate_phone = [];
    try {
      alternate_name = JSON.parse(localStorage.alternate_name);
      alternate_email = JSON.parse(localStorage.alternate_email);
      alternate_phone = JSON.parse(localStorage.alternate_phone);
    } catch (e) {
      informSlackDBCodeErrs(e, '', '');
    }
    this.setState({
      emailError: false,
      nameError: false,
      addressError: false,
      officeError: false,
      editing: false,
      name: member.name,
      email: member.email,
      phone: member.phone,
      phoneError: null,
      address: member.building_name,
      office: member.office_number,
      status: member.status,
      floor: member.floor,
      perAddress: member.permanent_address,
      note: member.note,
      alternateName: alternate_name,
      alternateEmail: alternate_email,
      alternatePhone: alternate_phone,
      business: member.member_businesses ? member.member_businesses : [],
    });
    this.closeAlert();
  };

  selectBusiness = bsns => {
    const { business, businesses } = this.state;
    const onlyIndices = business.map(bus => bus.business_id);
    const selectedIndex = onlyIndices.indexOf(bsns.business_id);
    const busIndex = businesses.map(bus => bus.business_id);
    if (selectedIndex > -1) {
      business.splice(selectedIndex, 1);
      businesses.push(bsns);
      this.setState({
        business,
        businesses,
        addingAltName: !this.state.addingAltName,
      });
    } else {
      business.push(bsns);
      businesses.splice(busIndex.indexOf(bsns.business_id), 1);
      this.setState({
        business,
        businesses,
        addingAltName: !this.state.addingAltName,
      });
    }
  };

  handleSearch = () => {
    const { search, selectedBuilding, isVoMember } = this.state;

    if (search.trimRight().length >= 1) {
      this.setState({ businessError: null, loadingBus: true, businesses: [] });
      const { t, searchBusiness } = this.props;
      searchBusiness(search, selectedBuilding, 10, 0, 'name', true, isVoMember)
        .then(businesses =>
          this.setState({
            businesses,
            loadingBus: false,
            businessError:
              businesses.length === 0 ? t('members.noBusinessFound') : null,
          }),
        )
        .catch(error =>
          this.setState({ businessError: error.message, loadingBus: false }),
        );
    }
  };

  handleChange = name => event => {
    let value = event.target.value;
    this.setState({ [name]: value.trimLeft() }, () => {
      this.closeAlert();
      const { validateEmail, validateName, generalValidation } = this.props;
      const { email, alternateEmail, alternateName } = this.state;
      this.setState({
        emailError: email.length > 0 ? !validateEmail(email) : false,
        // nameError: name.length > 0 ? !validateName(name) : false,
        altEmailError:
          alternateEmail.length > 0 ? !validateEmail(alternateEmail) : false,
        altNameError:
          alternateName.length > 0 ? !validateName(alternateName) : false,
      });
      if (name === 'name') {
        this.setState({
          nameError: value.length > 0 ? !validateName(value.trim()) : false,
        });
      } else if (name === 'perAddress') {
        this.setState({
          addressError: value.length > 0 ? !generalValidation(value) : false,
        });
      } else if (name === 'office') {
        this.setState({
          officeError: value.length > 0 ? !generalValidation(value) : false,
        });
      }
    });
  };

  saveMember = () => {
    const {
      name,
      email,
      phone,
      office,
      business,
      member,
      note,
      status,
      perAddress,
      alternateName,
      alternateEmail,
      alternatePhone,
      isHotdesk,
      phoneError,
      emailError,
      nameError,
      addressError,
      officeError,
      altPhoneError,
      warning,
    } = this.state;
    const { t, updateMember, match, validateEmail, validateName } = this.props;
    let call = true;
    const filteredAltEmail = alternateEmail.filter(
      val => val !== (null || undefined || ''),
    );
    const alternateEmailExists = filteredAltEmail.filter(
      item => item.toLowerCase() === email.toLowerCase(),
    );

    const alternateFilteredAltEmailExists = filteredAltEmail.filter(item => {
      const altData = filteredAltEmail.map(tempItem => tempItem.toLowerCase());
      return altData.indexOf(item.toLowerCase()) !== altData.lastIndexOf(item.toLowerCase());
    });

    const filteredAltNamed = alternateName.filter(
      val => val !== (null || undefined || ''),
    );

    const alternateNameExists = filteredAltNamed.filter(
      item => item.trim().toLowerCase() === name.trim().toLowerCase(),
    );

    const alternateFilteredAltNamedExists = filteredAltNamed.filter(item => {
      const altData = filteredAltNamed.map(tempItem => tempItem.toLowerCase());
      return altData.indexOf(item.toLowerCase()) !== altData.lastIndexOf(item.toLowerCase());
    });

    let filteredAltPhone = alternatePhone.filter(
      val => val !== (null || undefined || '') && val.length > 2,
    );
    filteredAltPhone = filteredAltPhone.map(val =>
      '+'.concat(val.replace(/[^\d]/g, '')),
    );

    let alternatePhoneExists = filteredAltPhone.filter(
      item => item === phone,
    );

    const alternateFilteredAltPhoneExists = filteredAltPhone.filter(
      item => filteredAltPhone.indexOf(item) !== filteredAltPhone.lastIndexOf(item),
    );

    altPhoneError.map(
      altPhone =>
        altPhone && this.setState({ warning: t('altPhoneValidation') }),
    );
    filteredAltEmail.map(altEmail => {
      if (!validateEmail(altEmail)) {
        call = false;
        this.setState({ warning: t('common.error.enterValidAlterEmail') });
      }
      return null;
    });
    filteredAltNamed.map(altName => {
      if (!validateName(altName)) {
        call = false;
        this.setState({ warning: t('common.error.altNameValidation') });
      }
      return null;
    });
    if (name.trim().length === 0) {
      this.setState({ warning: t('common.error.noEmptyName') });
    }
    if (email.length === 0) {
      this.setState({ warning: t('common.error.noEmptyEmail') });
    }
    if (emailError) this.setState({ warning: t('emailValidation') });
    if (nameError) this.setState({ warning: t('common.error.nameValidation') });
    if (addressError) this.setState({ warning: t('common.error.addressValidation') });
    if (officeError) this.setState({ warning: t('common.error.officeValidation') });
    if (phoneError) {
      this.setState({
        warning: t('phoneValidation'),
      });
    } else if (alternateEmailExists.length !== 0) {
      this.setState({
        warning: t('common.error.emailAndAltEmail'),
      });
    } else if (alternateFilteredAltEmailExists.length !== 0) {
      this.setState({
        warning: t('common.error.altEmailDuplicate'),
      });
    } else if (alternateNameExists.length !== 0) {
      this.setState({
        warning: t('common.error.nameAndAltname'),
      });
    } else if (alternateFilteredAltNamedExists.length !== 0) {
      this.setState({
        warning: t('common.error.altNameDuplicate'),
      });
    } else if (alternatePhoneExists.length !== 0) {
      this.setState({
        warning: t('common.error.phoneAndAltphone'),
      });
    } else if (alternateFilteredAltPhoneExists.length !== 0) {
      this.setState({
        warning: t('common.error.altPhoneDuplicate'),
      });
    } else if (
      name.trim().length > 0 &&
      email.length > 0 &&
      !phoneError &&
      !emailError &&
      !nameError &&
      !addressError &&
      !officeError &&
      !warning &&
      call
    ) {
      const filteredAltName = alternateName.filter(
        val => val !== (null || undefined || ''),
      );
      const newMember = {
        name,
        email,
        office,
        perAddress,
        phone: phone.length > 5 ? phone : '',
        alternateName: filteredAltName,
        alternateEmail: filteredAltEmail,
        alternatePhone: filteredAltPhone,
        isHotdesk,
        business,
        note,
        building_id: member.building_id,
        building_name: member.building_name,
      };
      if (newMember.phone) {
        newMember.phone = newMember.phone.charAt(0) === "+" ? newMember.phone : `+${newMember.phone}`;
      }
      this.setState({ updating: true, errorUpdating: null });
      updateMember(match.params.id, newMember)
        .then(() => {
          localStorage.alternate_name = JSON.stringify(
            member.alternate_name && member.alternate_name.length > 0
              ? member.alternate_name
              : [''],
          );
          localStorage.alternate_email = JSON.stringify(
            member.alternate_email && member.alternate_email.length > 0
              ? member.alternate_email
              : [''],
          );
          localStorage.alternate_phone = JSON.stringify(
            member.alternate_phone && member.alternate_phone.length > 0
              ? member.alternate_phone
              : [''],
          );
          if (
            !newMember.alternateName ||
            newMember.alternateName.length === 0
          ) {
            newMember.alternateName = [''];
          }
          if (
            !newMember.alternateEmail ||
            newMember.alternateEmail.length === 0
          ) {
            newMember.alternateEmail = [''];
          }
          if (
            !newMember.alternatePhone ||
            newMember.alternatePhone.length === 0
          ) {
            newMember.alternatePhone = [''];
          }
          newMember.status = status;
          this.setState({
            status,
            member: newMember,
            memberUpdated: true,
            updating: false,
            editing: false,
            name: newMember.name,
            email: newMember.email,
            phone: newMember.phone,
            office: newMember.office,
            isHotdesk: newMember.isHotdesk,
            perAddress: newMember.perAddress,
            note: newMember.note,
            alternateName: newMember.alternateName
              ? newMember.alternateName
              : [''],
            alternateEmail: newMember.alternateEmail
              ? newMember.alternateEmail
              : [''],
            alternatePhone: newMember.alternatePhone
              ? newMember.alternatePhone
              : [''],
            business: newMember.business || [],
          });
        })
        .catch(err => {
          let errorMessage = err.response
            ? err.response.data.message
            : err.message;
          let error = errorMessage.includes(
            '"office_number" is not allowed to be empty',
          )
            ? t('common.error.officeNotEmpty')
            : errorMessage.includes('permanent_address')
              ? t('common.error.addressNotEmpty')
              : errorMessage.includes(
                'child "alternate_name" fails because ["alternate_name" position 1 contains a duplicate value',
              )
                ? t('common.error.duplicate')
                : errorMessage.includes(
                  'child "alternate_email" fails because ["alternate_email" position 1 contains a duplicate value',
                )
                  ? t('common.error.alternatePosition')
                  : errorMessage.includes(
                    'Alternate phone contains duplicate entries',
                  )
                    ? errorMessage
                    : errorMessage.includes(
                      'Conflict: Email/Alternate Email cannot be same.',
                    )
                      ? t('common.error.emailAndAltEmail')
                      : errorMessage.includes(
                        'Conflict: Phone/Alternate Phone cannot be same.',
                      )
                        ? t('common.error.phoneAndAltphone')
                        : errorMessage.includes('already has this email address')
                          ? t('common.error.ExistsEmailForMembers')
                          : errorMessage.includes('Name/Alternate Name cannot be same')
                            ? t('common.error.nameAndAltname')
                            : errorMessage.includes('at position 0 fails because')
                              ? t('common.error.zero')
                              : errorMessage.includes('Invalid Phone Number')
                                ? t('phoneValidation')
                                : errorMessage.includes(
                                  'child "alternate_phone" fails because ["alternate_phone" position 1 contains a duplicate value]',
                                )
                                  ? t('common.error.phoneAndAltphone')
                                  : errorMessage.includes('position 1 contains a duplicate val')
                                    ? t('common.error.alternatePosition')
                                    : t('common.error.networkError');
          this.setState({ errorUpdating: error, updating: false });
        });
    }
  };

  closeAlert = () => {
    this.setState({
      warning: null,
      errorUpdating: null,
    });
  };
  closeSuccessAlert = () => {
    this.setState({
      memberUpdated: false,
      deleting: false,
    });
    const { isVoMember } = this.state;
    this.props.history.push(isVoMember ? '/members/mp_members' : '/members/all_members');
  };

  addInputField = name => {
    const addedState = this.state[name];
    const { altPhoneError } = this.state;
    addedState.push('');
    if (name === 'alternatePhone') altPhoneError.push('');
    this.setState({
      [name]: addedState,
      altPhoneError,
      addingAltName: !this.state.addingAltName,
    });
  };

  removeInputField = (name, index) => {
    const removedState = this.state[name];
    const { altPhoneError } = this.state;
    removedState.splice(index, 1);
    if (name === 'alternatePhone') altPhoneError.splice(index, 1);
    this.setState({
      [name]: removedState,
      altPhoneError,
      addingAltName: !this.state.addingAltName,
    });
  };

  deactivateMember = () => {
    this.setState({ deleting: true });
    const { match, deleteMember, t } = this.props;
    deleteMember(match.params.id)
      .then(() => this.setState({ deleting: false, successToast: true }))
      .catch(err => {
        let errorMessage = (err.response ? err.response.data.message : err.message);
        let error = errorMessage.includes(`Not Acceptable: ${this.state.isVoMember ? "MP Member" : "Member"} has pending packages to be picked up.`)
          ? t('common.error.pendingPackageExits')
          : errorMessage;
        this.setState({
          deleting: false,
          errorUpdating: error,
        });
      });
  };

  assignDesignated = bsns => {
    const { business } = this.state;
    const selectedIds = business.map(bus => bus.business_id);
    const index = selectedIds.indexOf(bsns.business_id);
    if (index > -1) {
      business[index].is_designated = !business[index].is_designated;
      this.setState({ business, addingAltName: !this.state.addingAltName });
    } else {
      bsns.is_designated = true;
      business.push(bsns);
      this.setState({ business, addingAltName: !this.state.addingAltName });
    }
  };

  assignSuperDesignated = bsns => {
    const { business } = this.state;
    const selectedIds = business.map(bus => bus.business_id);
    const index = selectedIds.indexOf(bsns.business_id);
    if (index > -1) {
      business[index].is_super_designated = !business[index].is_super_designated;
      this.setState({ business, addingAltName: !this.state.addingAltName });
    } else {
      bsns.is_super_designated = true;
      business.push(bsns);
      this.setState({ business, addingAltName: !this.state.addingAltName });
    }
  };

  emptyBusinesses = () => {
    let { business, businesses } = this.state;
    businesses = [...businesses, ...business];
    this.setState({ business: [], businesses });
  };

  render() {
    const {
      loading,
      member,
      error,
      errorUpdating,
      memberUpdated,
      warning,
      redirect,
      deleting,
      successToast,
      isVoMember
    } = this.state;

    const { t, style, width, validateEmail, validateName } = this.props;
    if (redirect) return <Redirect to="/members/all_members" />;
    return (
      <Fragment>
        <Container style={{ minWidth: '100%' }}>
          {deleting && (
            <Alert
              open={deleting}
              variant="success"
              message={t('members.deactivating')}
              onClose={this.closeAlert}
            />
          )}
          {errorUpdating && (
            <Alert
              open={errorUpdating}
              variant="error"
              title="Stop!"
              btn="Danger"
              message={errorUpdating}
              onClose={this.closeAlert}
            />
          )}
          {memberUpdated && (
            <Alert
              open={memberUpdated}
              variant="success"
              title="Success!"
              btn="success"
              message={t('members.updated')}
              onClose={this.closeSuccessAlert}
            />
          )}

          {successToast && (
            <Alert
              open={successToast}
              variant="success"
              title="Success!"
              btn="success"
              // message="Member successfully deactivated"
              message={t('members.memberSuccessfullyDeactived')}
              onClose={this.closeSuccessAlert}
            />
          )}
          <Row>
            <Col md={12}>
              <div
                style={
                  width !== 'xs'
                    ? {
                      display: 'flex',
                      justifyContent: 'space-between',
                      ...style.common.mb_20,
                    }
                    : {
                      display: 'flex',
                      flexDirection: 'column',
                      ...style.common.mb_20,
                    }
                }
              >
                <div>
                  <h3 className="page-title">
                    {loading && !error ? (
                      <Skeleton width={100} />
                    ) : (
                      member.name || (error && '  ---')
                    )}
                  </h3>
                  <h3 className="page-subhead subhead">
                    {
                      <Link to={isVoMember ? "/members/mp_members" : "/members/all_members"}>
                        {isVoMember ? t('voItems.voMember') : t('members.members')}
                      </Link>
                    }
                    <ArrowRightIcon />
                    {loading && !error ? (
                      <Skeleton width={100} />
                    ) : (
                      member.name || (error && '  ---')
                    )}
                  </h3>
                </div>
                {!loading && !error && (
                  <div className="flex">
                    <BlueButton
                      right={width !== 'xs' && 10}
                      top={10}
                      bottom={10}
                      component={Link}
                      width={width === 'xs' || width === 'sm' ? 135 : 160}
                      marginStyle={
                        width === 'xs' && {
                          marginRight: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                        }
                      }
                      to="/members/all_members"
                    >
                      {t('common.cancel')}
                    </BlueButton>
                    <BlueButton
                      top={10}
                      bottom={10}
                      width={width === 'xs' || width === 'sm' ? 135 : 160}
                      loading={this.state.updating}
                      disabled={this.state.updating ||
                        this.state.name.length === 0 ||
                        this.state.emailError ||
                        this.state.nameError ||
                        this.state.addressError ||
                        this.state.officeError ||
                        this.state.email.length === 0 ||
                        this.state.phoneError ||
                        this.state.alternateEmail.map(value => (value.length === 0 ? false : !validateEmail(value))).indexOf(true) > -1 ||
                        this.state.alternateName.map(value => (value.length === 0 ? false : !validateName(value))).indexOf(true) > -1 ||
                        this.state.altPhoneError.indexOf(true) > -1
                      }
                      onClick={this.saveMember}
                      marginStyle={
                        width === 'xs' && {
                          display: 'flex',
                          justifyContent: 'center',
                        }
                      }
                    >
                      {t('common.saveMember')}
                    </BlueButton>
                  </div>
                )}
              </div>
              {warning && (
                <WarningAlert
                  variant="error"
                  onClose={this.closeAlert}
                  message={warning}
                />
              )}
            </Col>
          </Row>
          <MemberDetailComp
            {...this.state}
            {...this.props}
            startEdit={this.startEdit}
            handleChange={this.handleChange}
            handlePhone={this.handlePhone}
            handleSearch={this.handleSearch}
            selectBusiness={this.selectBusiness}
            emptyBusinesses={this.emptyBusinesses}
            addInputField={this.addInputField}
            setAlternativeName={this.setAlternativeName}
            removeInputField={this.removeInputField}
            setAltPhone={this.setAltPhone}
            deactivateMember={this.deactivateMember}
            assignDesignated={this.assignDesignated}
            assignSuperDesignated={this.assignSuperDesignated}
            saveMember={this.saveMember}
            cancelEditing={this.cancelEditing}
          />
        </Container>
        <Footer />
      </Fragment>
    );
  }
}

MemberDetail.propTypes = {
  t: PropTypes.func.isRequired,
  getMember: PropTypes.func.isRequired,
  deleteMember: PropTypes.func.isRequired,
  match: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default withWidth()(
  connect(state => ({
    buildings: state.allBuildings,
    searchObj: state.search,
  }))(withRouter(MemberDetail)),
);
