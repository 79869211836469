import React from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Button
} from './Styled';

function DeleteCardConfirmation(props) {
  const { loader, deleteCard, cardLastDigits } = props;
  return (
    <div>
      <Dialog
        keepMounted
        maxWidth="lg"
        onClose={() => props.onClose()}
        {...props}
      >
        <DialogContent className="pm-modal-container">
          <h3 className="pm-heading"><span className="pm-heading-red">Confirmation</span> Required</h3>
          <p className="pm-description">Are you sure you want to delete the requested card <b className="pm-heading-red">**** {cardLastDigits}</b>?</p>
          <div className="pm-single-button-section">
            <Button className="pm-cp-downgrade-btn" disabled={loader} onClick={() => !loader && deleteCard()}>Delete Card {loader && <CircularProgress size={15} className="white" />} </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withRouter(DeleteCardConfirmation);
