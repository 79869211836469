/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import { Divider, CircularProgress, Tooltip, withWidth } from '@material-ui/core';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import './styles/styles.css';
import APIQuotaComponent from './components/APIQuotaComponent';
import BuildingListing from './components/buildingListing';
import Alert from '../../UI/Poper';
import { getExposeAPIKey, getQuotaInformation } from '../../../server/utils';
import NotAuthorized from '../../UI/403/403';
import Footer from '../../UI/Footer/Footer';
import TenantSelector from '../TenantSelector/TenantSelector';
import { checkAWSSession } from '../../../util/application.utils';
// import { id } from 'date-fns/esm/locale';

class APIExpose extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copied: false,
            clientIdCopied: false,
            sampleQuota: [],
            quotaError: false,
            exposeAPIError: false,
            apiKeyValue: null,
            clientIdValue: null,
            selectedTenant: null,
            encryptedEmail: null,
            redirectToDashboard: false
        };
    }

    componentDidMount() {
        checkAWSSession(res => {
            if (res) {
              this.setState({
                encryptedEmail: `/onboarding?email=${res}`
              });
            }
            const { userRole } = this.props;
            if (userRole) {
                this.getData();
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userRole !== this.props.userRole) {
            this.getData();
        }
        if (prevProps.buildings.length !== this.props.buildings.length && this.props.buildings.length > 0) {
            if (this.props.buildings[0] && 
            this.props.buildings[0].features && 
            this.props.buildings[0].features.EXPOSE_API !== true) {
                this.setState({ redirectToDashboard: true });
            }
        }
    }

    getData() {
        const { userRole, t } = this.props;
        if (userRole === 'super_admin') {
            this.getDataFromAPI();
        } else if (userRole === 'admin' || userRole === 'Admin') {
            getExposeAPIKey()
                .then(apiRes => {
                    this.setState({
                        apiKeyValue: apiRes.api_key,
                        clientIdValue: apiRes.uuid,
                        exposeAPIError: false,
                    });
                    getQuotaInformation()
                        .then(res => {
                            const newQuota = [
                                {
                                    label: t('apiexpose.callsLeft'),
                                    data: res.total,
                                },
                                {
                                    label: t('apiexpose.reachedQuota'),
                                    data: Math.round((parseInt(res.used, 10) / parseInt(res.total, 10)) * 100)
                                },
                                {
                                    label: t('apiexpose.remainingQuota'),
                                    data: ((parseInt(res.remaining, 10) / parseInt(res.total, 10)) * 100)
                                },
                            ];
                            this.setState({
                                sampleQuota: [...newQuota],
                                quotaError: false
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            this.setState({
                                quotaError: true,
                            });
                        });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        exposeAPIError: true,
                        quotaError: true
                    });
                });
        }
    }

    getDataFromAPI() {
        const { t, buildings } = this.props;
        const { selectedTenant } = this.state;
        if (buildings.length !== 0) {
            const { tenant_name, tenant_id } = buildings[0];
            getExposeAPIKey(selectedTenant ? selectedTenant.tenant_id : tenant_id)
                .then(apiRes => {
                    this.setState({
                        apiKeyValue: apiRes.api_key,
                        clientIdValue: apiRes.uuid,
                        exposeAPIError: false,
                        selectedTenant: {
                            tenant_name: selectedTenant ? selectedTenant.tenant_name : tenant_name,
                            tenant_id: selectedTenant ? selectedTenant.tenant_id : tenant_id
                        }
                    });
                    getQuotaInformation(selectedTenant ? selectedTenant.tenant_id : tenant_id)
                        .then(res => {
                            const newQuota = [
                                {
                                    label: t('apiexpose.callsLeft'),
                                    data: res.total,
                                },
                                {
                                    label: t('apiexpose.reachedQuota'),
                                    data: Math.round((parseInt(res.used, 10) / parseInt(res.total, 10)) * 100)
                                },
                                {
                                    label: t('apiexpose.remainingQuota'),
                                    data: ((parseInt(res.remaining, 10) / parseInt(res.total, 10)) * 100)
                                },
                            ];
                            this.setState({
                                sampleQuota: [...newQuota],
                                quotaError: false
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            this.setState({
                                quotaError: true,
                            });
                        });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        exposeAPIError: true,
                        quotaError: true
                    });
                });
        }
    }

    copyAPIKey = () => {
        const { apiKeyValue } = this.state;
        navigator.clipboard.writeText(apiKeyValue);
        this.setState({
            copied: true
        });
    }

    copyClientId = () => {
        const { clientIdValue } = this.state;
        document.execCommand('copy');
        navigator.clipboard.writeText(clientIdValue);
        this.setState({
            clientIdCopied: true
        });
    }

    closeAlert = () => {
        this.setState({
            copied: false,
            clientIdCopied: false
        });
    };

    openMailbox = () => {
        window.location.href = "mailto:support@packagex.app?subject=Requesting%20API%20Change";
    };

    handleTenantChange = (tanent) => {
        const { selectedTenant } = this.state;
        if (selectedTenant.tenant_id !== tanent.tenant_id) {
            this.setState({
                selectedTenant: {
                    tenant_id: tanent.tenant_id,
                    tenant_name: tanent.tenant_name
                },
                sampleQuota: [],
                apiKeyValue: null,
                clientIdValue: null
            }, () => this.getDataFromAPI());
        }
    }

    render() {
        const {
            t,
            userRole,
            buildings,
            width
        } = this.props;
        const {
            copied,
            quotaError,
            apiKeyValue,
            sampleQuota,
            clientIdValue,
            exposeAPIError,
            clientIdCopied,
            selectedTenant,
            encryptedEmail,
            redirectToDashboard
        } = this.state;

        if (encryptedEmail) {
            return <Redirect replace to={encryptedEmail} />;
        }

        if (redirectToDashboard) {
            return <Redirect replace to="/dashboard" />;
        }

        const tenants = buildings.map(eachTenant => {
            const { tenant_name, tenant_id } = eachTenant;
            return {
                tenant_id,
                tenant_name
            };
        });
        if (!userRole) {
            return (
                <div className="loading-container">
                    <CircularProgress className="blue" size={30} />
                </div>
            );
        } else if (userRole === 'mailroom_supervisor' || userRole === 'building_manager') {
            return <NotAuthorized />;
        } else {
            return (
                <React.Fragment>
                    <div className="main-api-container">
                        {
                            copied &&
                            <Alert variant="success" open message={t('apiexpose.apiKeyCopied')} onClose={this.closeAlert} />
                        }
                        {
                            clientIdCopied &&
                            <Alert variant="success" open message={t('apiexpose.clientIdCopied')} onClose={this.closeAlert} />
                        }
                        {/* <h3 className="page-title" style={{ marginBottom: 8 }}>
                            {t('apiexpose.apiInformation')}
                        </h3> */}
                        <div style={
                            width === 'xs'
                                ? { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }
                                : { display: 'flex', flexDirection: 'row' }
                        }>
                            <h3 className="page-title" style={{ marginBottom: 8, marginRight: 8 }}>
                                {t('apiexpose.apiInformation')}
                            </h3>
                            {
                                userRole && userRole === 'super_admin' && selectedTenant &&
                                <div style={{ marginTop: 2, display: 'initial' }}>
                                    <TenantSelector
                                        t={t}
                                        tenants={tenants}
                                        selectedTenant={selectedTenant}
                                        handleOnchange={this.handleTenantChange}
                                        handleReset={() => {
                                            if (selectedTenant.tenant_id !== this.props.buildings[0].tenant_id) {
                                                this.setState({
                                                    selectedTenant: {
                                                        tenant_id: this.props.buildings[0].tenant_id,
                                                        tenant_name: this.props.buildings[0].tenant_name
                                                    },
                                                    sampleQuota: [],
                                                    apiKeyValue: null,
                                                    clientIdValue: null
                                                }, () => this.getDataFromAPI());
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </div>
                        {/* <h3 className="page-title margin-bottom-10">{t('settings.settings')}</h3> */}
                        <div className="api-building-container">
                            <div className="api-keys-container">
                                <h3 className="container-heading">{t('apiexpose.apiKeys')}</h3>
                                <Divider />
                                <div className="api-key-information">
                                    <p> {t('apiexpose.apiKey')}: </p>
                                    {
                                        exposeAPIError
                                            ? <p> {t('common.error.networkError')} </p>
                                            : typeof clientIdValue === 'string'
                                                ? (
                                                    <Tooltip title={t('apiexpose.copyToClipboard')}>
                                                        <p
                                                            className="api-key"
                                                            onClick={this.copyAPIKey}
                                                            role="presentation"
                                                        >
                                                            {apiKeyValue !== null ? apiKeyValue : <Skeleton width={200} />}
                                                        </p>
                                                    </Tooltip>
                                                )
                                                : (
                                                    <p
                                                        className="api-key"
                                                        onClick={this.copyAPIKey}
                                                        role="presentation"
                                                    >
                                                        {apiKeyValue !== null ? apiKeyValue : <Skeleton width={200} />}
                                                    </p>
                                                )
                                    }
                                </div>
                                <Divider />
                                <div className="api-client-information">
                                    <p> {t('apiexpose.clientId')}: </p>
                                    {
                                        exposeAPIError
                                            ? <p> {t('common.error.networkError')} </p>
                                            : typeof clientIdValue === 'string'
                                                ? (
                                                    <Tooltip title={t('apiexpose.copyToClipboard')}>
                                                        <p
                                                            className="client-id"
                                                            onClick={this.copyClientId}
                                                            role="presentation"
                                                        >
                                                            {clientIdValue !== null ? clientIdValue : <Skeleton width={200} />}
                                                        </p>
                                                    </Tooltip>
                                                )
                                                : (
                                                    <p
                                                        className="client-id"
                                                        onClick={this.copyClientId}
                                                        role="presentation"
                                                    >
                                                        {clientIdValue !== null ? clientIdValue : <Skeleton width={200} />}
                                                    </p>
                                                )
                                    }
                                </div>
                                <h3 className="container-heading">{t('apiexpose.requestToChangeAPI')}</h3>
                                <Divider />
                                <div className="api-key-information">
                                    <p> {t('apiexpose.requestToChangeMessage')}? </p>
                                    <button className="api-key-button" onClick={this.openMailbox}>
                                        {t('apiexpose.changeAPIKey')}</button>
                                </div>
                                <Divider />
                                <APIQuotaComponent
                                    t={t}
                                    data={sampleQuota}
                                    error={quotaError}
                                />
                            </div>
                            <div className="bulding-listing-container">
                                <BuildingListing t={t} />
                            </div>
                        </div>
                    </div>
                    <Footer />
                </React.Fragment >
            );
        }
    }
}

APIExpose.propTypes = {
    t: PropTypes.func.isRequired,
    userRole: PropTypes.string.isRequired,
    buildings: PropTypes.node.isRequired,
    width: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    userRole: state.userRole,
    buildings: state.allBuildings
});

export default connect(mapStateToProps)(withWidth()(APIExpose));
