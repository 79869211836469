import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Progress } from 'react-sweet-progress';
import {
    Dialog,
    RadioGroup,
    Radio,
    FormControlLabel
    // FormControl,
    // CircularProgress, Button,
} from '@material-ui/core';
// import Switch from '@material-ui/core/Switch';
// import Label from '@material-ui/core/InputLabel';
// import {
//     Margin
// } from '../../../UI/ResponsiveRelated';
// import Alert from '../../../UI/Poper';
import Cloud from '../../../../assets/images/cloud.svg';
import Button from '../../../UI/BlueButton';

class CSVUploadModal extends React.Component {
    state = { warningMsg: '' };

    onDrop = accepted => {
        if (
            accepted[0].type === 'application/vnd.ms-excel' ||
            accepted[0].type === 'text/csv' ||
            accepted[0].name.includes('csv')
        ) {
            if (accepted[0].size > 5000000) {
                this.setState({ warningMsg: 'Selected file is larger than 5 MB.' });
            } else {
                this.setState({ warningMsg: '' });
                return new Promise(resolve => {
                    const reader = new window.FileReader();
                    reader.readAsDataURL(accepted[0]);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        this.props.addFile(accepted[0]);
                        resolve(base64data);
                    };
                });
            }
        } else {
            const message = 'Please submit valid file type';
            this.setState({ warningMsg: message });
        }
    };

    render() {
        const {
            t,
            open,
            close,
            percentage,
            loading,
            overWrite,
            setOverWrite
        } = this.props;
        const { warningMsg } = this.state;

        return (
            <Dialog
                open={open}
                onClose={close}
                disableBackdropClick={false}
                onBackdropClick={close}
            //   maxWidth="lg"
            //  className="paperFullWidth"
            >
                <div className="csv-upload-modal">
                    <h3 className="mt-4" style={{ fontWeight: '700' }}>Add CSV</h3>
                    <div className="csv-upload-modal-svg">
                        <img src={Cloud} alt="Upload CSV file" />
                        <h3>{t('members.uploadCSVhere')}</h3>
                        {/* <p>Upload CSV after adding recipient details</p> */}
                        {loading && <div className="csv-upload-modal-progress-bar"><Progress percent={percentage} /> </div>}
                        {warningMsg && <p style={{ color: 'red' }}>{warningMsg}</p>}
                    </div>

                    <div className="csv-upload-modal-button">
                        <div className="rdo-btn-merge">
                            {/* eslint-disable-next-line */}
                            {/* <label htmlFor="checkedB">Merge</label> */}
                            <RadioGroup aria-label="option" name="overwite-merge" value={overWrite} onChange={() => setOverWrite(!overWrite)}>
                                {/* eslint-disable-next-line */}
                                <FormControlLabel value={false} control={<Radio color="primary" />} label={t("members.mergeCase")} />
                                {/* eslint-disable-next-line */}
                                <FormControlLabel value={true} control={<Radio color="primary" />} label={t("members.overwriteCase")} />

                            </RadioGroup>
                            {/* <Switch
                                className="overWriteSwitch"
                                checked={overWrite}
                                onChange={() => setOverWrite(!overWrite)}
                                name="checkedB"
                                color="primary"
                                id="checkedB"
                            /> */}
                            {/* eslint-disable-next-line */}
                            {/* <label htmlFor="checkedB">Overwrite</label> */}
                        </div>

                        <Button
                            component={Dropzone}
                            disabled={loading}
                            multiple={false}
                            // accept="text/csv, application/xls, application/excel, application/vnd.ms-excel, application/vnd.ms-excel, charset=binary, application/msexcel, application/x-excel, application/x-msexcel, application/x-ms-excel, application/x-dos_ms_excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/csv, application/x-csv, text/csv, text/comma-separated-values, text/x-comma-separated-values, text/tab-separated-values"
                            onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                            capitalize
                            width={180}
                        >
                            {t('members.addAttachment')}
                        </Button>
                        <h4>{t('members.csvMaxSize')}</h4>
                    </div>
                </div>

            </Dialog>
        );
    }
}

CSVUploadModal.propTypes = {
    t: PropTypes.func.isRequired,
    open: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,

    addFile: PropTypes.func.isRequired,
    // width: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    percentage: PropTypes.number.isRequired,
    overWrite: PropTypes.bool.isRequired,
    setOverWrite: PropTypes.func.isRequired,
    // uploading: PropTypes.node.isRequired,
    // buildings: PropTypes.node.isRequired
};

export default CSVUploadModal;
