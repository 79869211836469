/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
/* eslint-disable */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
  Tabs,
  Tab,
  Modal,
  MenuList,
  MenuItem,
  ListItemIcon,
  Divider,
  InputAdornment,
  Paper,
  withWidth,
  Grid,
  IconButton,
  InputBase,
  Dialog,
} from "@material-ui/core";
import {
  Person,
  Description,
  BusinessCenter,
  Search,
  Cancel,
  Clear,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
// import originalMoment from "moment";
// import { extendMoment } from "moment-range";
import { Margin } from "../../UI/ResponsiveRelated";
import MembersTab from "./components/Members";
import Alert from "../../UI/Poper";
import BusinessesTab from "./components/Businesses";
import BuildingToggle from "../BuildingToggle";
import BlueButton from "../../UI/BlueButton";
import browser from "../../../util/getBrowserInfo";
import { resolveSafari } from "../../../util/resolveSafari";
import {
  setDateFilter,
  removeDateFilter,
} from "../../../redux/actions/dateFilterActions";
import { MembersIndexStyles as styles } from "../../../util/StyleConsts";
import Footer from "../../UI/Footer/Footer";
import nobuilding from "../../../assets/images/nobuilding.svg";
import {
  getBusiness,
  getBuilding,
  getMember,
  getMembers,
  getBusinesses,
  searchMember,
  searchBusiness,
} from "../../../server";
import DateRange from "../Main/components/DateRange";
import { validateSearchCharacter } from "../../../util";
import { checkAWSSession } from "../../../util/application.utils";
// const moment = extendMoment(originalMoment);
const StyledLink = styled(Link)`
  &:hover {
    color: black;
  }
`;

class Members extends Component {
  constructor(props) {
    super(props);
    const { tab, id } = props.match.params;
    const { path } = props.match;
    this.state = {
      memberFilter: true,
      from: undefined,
      to: undefined,
      value:
        path === "/members/businesses" || tab === "building_businesses"
          ? "businesses"
          : path.includes("/members/mp_members") || tab === "mp_members_building_members" || (path.includes("/members/mp_members") && tab === "total_business_members")  ? "mp_members" :
            !tab ||
              tab === "members" ||
              tab === "business" ||
              tab === "building_members" ||
              tab === "total_business_members" ||
              tab === "business_designated_members"
              ? "members"
              :
              "businesses",
      tab,
      openCsvAlert: false,
      id,
      path,
      modal: false,
      addMember: false,
      addBusiness: false,
      disabled: false,
      businessId:
        (tab === "business" || tab === "total_business_members") && id,
      title: "Name ↑",
      buildingName: props.t("common.subtitle"),
      searchedMember: "",
      searchedBusiness: "",
      searchedVOMember: "",
      startSearch: false,
      businessNameBusinesses: "",
      buildingNameBuildings: "",
      businessTempSearch: "",
      memberTempSearch: "",
      openAlertError: false,
      errorMessage: "",
      encryptedEmail: null,
    };
    this.componentDidUpdateSetState = this.componentDidUpdateSetState.bind(
      this
    );
  }

  componentDidMount() {
    checkAWSSession((res) => {
      if (res) {
        this.setState({
          encryptedEmail: `/onboarding?email=${res}`,
        });
      }
      if (browser.name === "Safari") {
        resolveSafari();
      }
      const { tab } = this.state;
      const { history, match } = this.props;
      history.listen(() => {
        const url = window.location.href;
        if (url && url.includes("/members/businesses")) {
          this.setState({
            tab: "businesses",
            value: "businesses",
            path: "/members/businesses",
            id: null,
          });
        } else if (
          url &&
          url.includes("/member/business_designated_members/")
        ) {
          const id = url.split("/").pop();
          this.setState(
            {
              id,
              tab: "business_designated_members",
              value: "members",
              path: `/member/business_designated_members/${id}`,
              businessNameBusinesses: "",
              buildingNameBuildings: "",
            },
            () =>
              getBusiness(id)
                .then((business) =>
                  this.setState({ businessNameBusinesses: business.name })
                )
                .catch(() => this.setState({ businessNameBusinesses: "" }))
          );
        } else if (url && url.includes("/member/total_business_members/")) {
          const id = url.split("/").pop();
          this.setState(
            {
              id,
              tab: "total_business_members",
              value: "members",
              path: `/member/total_business_members/${id}`,
              businessNameBusinesses: "",
              buildingNameBuildings: "",
            },
            () =>
              getBusiness(id)
                .then((business) =>
                  this.setState({ businessNameBusinesses: business.name })
                )
                .catch(() => this.setState({ businessNameBusinesses: "" }))
          );
        } else if (url && url.includes("/member/building_members/")) {
          const id = url.split("/").pop();
          this.setState(
            {
              id,
              tab: "building_members",
              value: "members",
              path: `/member/building_members/${id}`,
              businessNameBusinesses: "",
              buildingNameBuildings: "",
            },
            () => {
              getBuilding(id)
                .then((building) => {
                  this.setState({
                    buildingNameBuildings: building.name,
                  });
                })
                .catch(() => this.setState({ buildingNameBuildings: "" }));
            }
          );
        }
        else if (url && url.includes("/member/mp_members_building_members/")) {
          const id = url.split("/").pop();
          this.setState(
            {
              id,
              tab: "building_members",
              value: "mp_members",
              path: `/member/mp_members_building_members/${id}`,
              businessNameBusinesses: "",
              buildingNameBuildings: "",
            },
            () => {
              getBuilding(id)
                .then((building) => {
                  this.setState({
                    buildingNameBuildings: building.name,
                  });
                })
                .catch(() => this.setState({ buildingNameBuildings: "" }));
            }
          );
        }
        else if (url && url.includes("/member/mp_members/total_business_members/")) {
          const id = url.split("/").pop();
          this.setState(
            {
              id,
              tab: "total_business_members",
              value: "mp_members",
              path: `/member/mp_members/total_business_members/${id}`,
              businessNameBusinesses: "",
              buildingNameBuildings: "",
            },
            () =>
              getBusiness(id)
                .then((business) =>
                  this.setState({ businessNameBusinesses: business.name })
                )
                .catch(() => this.setState({ businessNameBusinesses: "" }))
          );
        }

        else if (url && url.includes("/member/building_businesses/")) {
          const id = url.split("/").pop();
          this.setState(
            {
              id,
              tab: "building_businesses",
              value: "businesses",
              path: `/member/building_businesses/${id}`,
              businessNameBusinesses: "",
              buildingNameBuildings: "",
            },
            () =>
              getBuilding(id)
                .then((building) => {
                  this.setState({
                    buildingNameBuildings: building.name,
                  });
                })
                .catch(() => this.setState({ buildingNameBuildings: "" }))
          );
        } else if (url && url.includes("/members/all_members")) {
          this.setState({
            id: null,
            tab: "all_members",
            value: "members",
            path: "/members/all_members",
            businessNameBusinesses: "",
            buildingNameBuildings: "",
          });
        }
        else if (url && url.includes("/members/mp_members")) {
          this.setState({
            tab: "mp_members",
            value: "mp_members",
            path: "/members/mp_members",
            id: null,
          });
        }
      });
      if (
        tab === "total_business_members" ||
        tab === "business" ||
        tab === "business_designated_members"
      ) {
        const businessId = match.params.id;
        getBusiness(businessId)
          .then((business) =>
            this.setState({ businessNameBusinesses: business.name })
          )
          .catch(() => this.setState({ businessNameBusinesses: "" }));
      } else if (tab === "building_members" || tab === "building_businesses" || tab === "mp_members_building_members") {
        const buildingId = match.params.id;
        getBuilding(buildingId)
          .then((building) => {
            this.setState({
              buildingNameBuildings: building.name,
            });
          })
          .catch(() => this.setState({ buildingNameBuildings: "" }));
      }
    });
  }

  static getDerivedStateFromProps() {
    return true;
  }

  componentDidUpdate(prevState, prevProps) {
    if (prevProps.match !== this.props.match) {
      // console.log('');
    } else if (prevProps !== this.props) {
      this.componentDidUpdateSetState();
    } else if (prevState.startSearch !== this.state.startSearch) {
      this.componentDidUpdateSetState();
    }
  }

  getFilterValue = (filter) => this.setState({ disabled: filter === "filter" });

  componentDidUpdateSetState() {
    const { tab, id } = this.props.match.params;
    const { path } = this.props.match;
    this.setState({
      value:
        path === "/members/businesses"
          ? "businesses"
          : !tab ||
            tab === "members" ||
            tab === "business" ||
            tab === "building_members" ||
            tab === "total_business_members" ||
            tab === "business_designated_members"
            ? "members"
            :
            path === "members/mp_members" || tab === "mp_members_building_members" ? "mp_members" :
              "businesses",
      tab,
      id,
      path,
      businessId:
        (tab === "business" ||
          tab === "total_business_members" ||
          tab === "business_designated_members") &&
        id,
    });
  }

  rerender = (tab, id) => {
    this.setState({
      tab,
      id,
      value: tab,
      path: `/${tab}/${id}`,
    });
  };

  handleChange = (event, value) => {
    this.resetFilter();
    this.setState({
      value,
      title: "Name ↑",
      tab: value,
      id: null,
      path: value === "members" ? "/members/all_members" : value === "businesses" ? "/members/businesses" : "/members/mp_members",
    });
  };

  applyFilter = (range) => {
    // const { setDateFilter } = this.props;
    // const fromDate = moment(range.start).utc().startOf('day')
    //   .toISOString();
    // const toDate = moment(range.end).utc().endOf('day')
    //   .toISOString();
    this.setState(
      {
        from: range.start,
        to: range.end,
        searchedMember: "",
      },
      () => {
        this.props.setDateFilter({
          fromDate: range.start,
          toDate: range.end,
        });
      }
    );
  };

  resetFilter = () => {
    this.setState({
      from: undefined,
      to: undefined,
    });
    this.props.removeDateFilter();
  };

  toggle = () => this.setState({ modal: !this.state.modal });

  changeTitle = (title) => this.setState({ title });

  handleSearchBarChange = (event) => {
    if (this.state.value === "members") {
      this.setState({
        searchedMember: event.target.value.trimLeft(),
      });
    }
    else if (this.state.value === "mp_members") {
      this.setState({
        searchedVOMember: event.target.value.trimLeft(),
      });
    }
    else {
      this.setState({
        searchedBusiness: event.target.value.trimLeft(),
      });
    }
    if (event.target.value.trimLeft().length === 0) {
      if (this.state.value === "members") {
        this.setState({
          memberTempSearch: "search",
        });
      } else if (this.state.value === "mp_members") {
        this.setState({
          voMemberTempSearch: "search",
        });
      }
      else {
        this.setState({
          businessTempSearch: "search",
        });
      }
    }
  };

  handleSearchAction = () => {
    if (this.state.searchedMember !== "" && this.state.value === "members") {
      if (!validateSearchCharacter(this.state.searchedMember)) {
        this.setState({
          openAlertError: true,
          errorMessage: this.props.t("common.error.notAllowSpecialCharacters"),
        });
      } else {
        this.setState(
          {
            startSearch: true,
            memberTempSearch: "",
            businessTempSearch: "",
            voMemberTempSearch: "",
            from: undefined,
            to: undefined,
          },
          () => this.resetFilter
        );
      }
    }
    else if (this.state.searchedVOMember !== "" && this.state.value === "mp_members") {
      if (!validateSearchCharacter(this.state.searchedVOMember)) {
        this.setState({
          openAlertError: true,
          errorMessage: this.props.t("common.error.notAllowSpecialCharacters"),
        });
      } else {
        this.setState({
          startSearch: true,
          memberTempSearch: "",
          businessTempSearch: "",
          voMemberTempSearch: "",
          from: undefined,
          to: undefined,
        });
      }
    }

    else if (this.state.searchedBusiness !== "" && this.state.value === "businesses") {
      if (!validateSearchCharacter(this.state.searchedBusiness)) {
        this.setState({
          openAlertError: true,
          errorMessage: this.props.t("common.error.notAllowSpecialCharacters"),
        });
      } else {
        this.setState({
          startSearch: true,
          memberTempSearch: "",
          businessTempSearch: "",
          voMemberTempSearch: "",
        });
      }
    }
  };

  handleStopSearching = () => this.setState({ startSearch: false });
  closeCsvAlert = () => this.setState({ openCsvAlert: false });
  handleUploadCsv = () => {
    const { userRole, t } = this.props;
    if (userRole === "mailroom_supervisor") {
      this.setState({
        openAlertError: true,
        errorMessage: t("package.PickUpDeleteRoleError"),
      });
    } else {
      this.setState({ openCsvAlert: true });
    }
  };

  render() {
    let env = process.env.REACT_APP_ENV
      ? process.env.REACT_APP_ENV
      : "development";
    const {
      value,
      modal,
      searchedMember,
      searchedBusiness,
      startSearch,
      disabled,
      tab,
      path,
      businessTempSearch,
      memberTempSearch,
      from,
      to,
      memberFilter,
      openAlertError,
      errorMessage,
      encryptedEmail,
      searchedVOMember
    } = this.state;
    const {
      t,
      classes,
      style,
      // match,
      building,
      userRole,
      pagination,
      dispatch,
      width,
      lng,
      formatPhoneNumber,
      buildings,
      stateBuild
    } = this.props;

    const allowCSVUploadForSpecificWeWorkBuilding = building && building[0] && building[0].building_id && building[0].building_id === 3915 && env === "wework";

    if (encryptedEmail) {
      return <Redirect replace to={encryptedEmail} />;
    }
    const tabStyle = {
      fontSize: 18,
      flexGrow: 0,
      textTransform: "capitalize",
    };

    // const canUseCsvFeature = buildings && buildings.length> 0 && buildings[0].buildings[0].used_csv_feature;

    let disableCSV = userRole === "mailroom_supervisor";
    // if (userRole === 'building_manager') {
    //   if (building.length === 1) {
    //     disableCSV = false;
    //   } else {
    //     disableCSV = true;
    //   }
    // }
    const addTypes = [
      {
        name: t("common.individual"),
        icon: <Person style={{ color: "grey" }} />,
        link: "/add_member",
      },
      {
        name: t("common.business"),
        icon: <BusinessCenter />,
        link: "/add_business",
      },
      {
        name: t("common.uploadcsv"),
        icon: <Description />,
        link: "/upload_csv",
      },
    ];

    const headingTabStyle = {
      fontWeight: "bold",
      fontSize: 14,
      color: "black",
    };
    const { pathname } = this.props.location;
    return (
      <Fragment>
        <div className="dashboard container">
          <Grid container>
            <ModalAddMember
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={modal}
              onClose={this.toggle}
              className="memberdropDown"
            >
              <div className="modal-all-members">
                <MenuList>
                  {addTypes
                    // .filter(
                    //   (item) => {
                    //     console.log('');
                    //     return allowCSVUploadForSpecificWeWorkBuilding || !(
                    //       item.name === t("common.uploadcsv") &&
                    //       (env === "wework" || env === "wework_cn")
                    //     ); 
                    //   }
                    // )
                    .map((item) => (
                      <MenuItem
                        onClick={
                          item.name === t("common.uploadcsv") && disableCSV
                            ? (e) => {
                              this.handleUploadCsv();
                              e.preventDefault();
                            }
                            : undefined
                        }
                        className={classes.menuitem}
                        component={Link}
                        // disabled={item.name === t('common.uploadcsv') && userRole === 'mailroom_supervisor'}
                        to={
                          item.name === t("common.uploadcsv")
                            ? !disableCSV && item.link
                            : item.link
                        }
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <div className={classes.itemtext}>{t(item.name)}</div>
                      </MenuItem>
                    ))}
                </MenuList>
              </div>
            </ModalAddMember>
            <Dialog
              open={this.state.openCsvAlert}
              onClose={this.closeCsvAlert}
              disableBackdropClick={false}
              onBackdropClick={this.closeCsvAlert}
            >
              {" "}
              <Dialogwrapper className="dialogwrapper">
                <IconButton
                  color="inherit"
                  onClick={this.closeCsvAlert}
                  aria-label="Close"
                  className="margin-right-10 float-right"
                  style={{
                    position: "absolute",
                    right:
                      window.screen.width < 450 && window.screen.width > 365
                        ? 0
                        : window.screen.width < 365
                          ? -10
                          : 0,
                    top: 2,
                  }}
                >
                  <Clear style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                </IconButton>
                <Margin
                  style={{
                    padding: 20,
                    paddingBottom: window.screen.width < 400 ? 10 : 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={nobuilding}
                      alt="no Building"
                      height="200"
                      width="200"
                    />
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      {t("members.noBuildingSelectAddCSV")}
                    </p>
                  </div>
                </Margin>
              </Dialogwrapper>
            </Dialog>
            {openAlertError && (
              <Alert
                variant="error"
                open={openAlertError}
                message={errorMessage}
                onClose={() => this.setState({ openAlertError: false })}
              />
            )}

            <Grid item xs={12} md={12}>
              <div className="space-between-members margin-0px">
                <div style={{ display: "flex", marginTop: -5 }}>
                  {path === "/members/all_members" ? (
                    <h3 className="page-title" style={{ marginBottom: 8 }}>
                      {" "}
                      {t("members.members")}{" "}
                    </h3>
                  ) : path === "/members/businesses" ? (
                    <h3 className="page-title" style={{ marginBottom: 8 }}>
                      {" "}
                      {t("tables.businesses")}{" "}
                    </h3>
                  ) : tab === "total_business_members" ||
                    tab === "business_designated_members" ? (
                    this.state.businessNameBusinesses ? (
                      <p style={headingTabStyle}>
                        {" "}
                        {tab === "business_designated_members"
                          ? this.state.businessNameBusinesses.concat(
                            ` ${t("members.designated")} ${t(
                              "members.member"
                            )}`
                          )
                          : this.state.businessNameBusinesses.concat(
                            ` ${t("members.member")}`
                          )}{" "}
                      </p>
                    ) : (
                      <Skeleton width={100} />
                    )
                  ) : tab === "building_members" || tab === "mp_members_building_members" ? (
                    this.state.buildingNameBuildings ? (
                      <p style={headingTabStyle}>
                        {" "}
                        {this.state.buildingNameBuildings.concat(
                          ` ${t("members.member")}`
                        )}{" "}
                      </p>
                    ) : (
                      <Skeleton width={100} />
                    )
                  ) : tab === "building_businesses" ? (
                    this.state.buildingNameBuildings ? (
                      <p style={headingTabStyle}>
                        {" "}
                        {this.state.buildingNameBuildings.concat(
                          ` ${t("tables.businesses")}`
                        )}{" "}
                      </p>
                    ) : (
                      <Skeleton width={100} />
                    )
                  ) : tab === "business" ? (
                    this.state.businessNameBusinesses ? (
                      <p style={headingTabStyle}>
                        {" "}
                        {this.state.businessNameBusinesses.concat(
                          ` ${t("tables.businesses")}`
                        )}{" "}
                      </p>
                    ) : (
                      <Skeleton width={100} />
                    )
                  ) : (
                    ""
                  )}
                  {/* {width !== 'xs' ||
                    width !== 'sm' && */}
                  {(() => {
                    switch (tab) {
                      case "building_members":
                      case "mp_members_building_members":
                        return null;

                      case "building_businesses":
                        return null;
                      case "total_business_members":
                        return null;
                      case "business":
                        return null;
                      case "business_designated_members":
                        return null;
                      default:
                        return (
                          <div style={{ marginLeft: 10, marginTop: 2 }}>
                            <BuildingToggle {...this.props} />{" "}
                          </div>
                        );
                    }
                  })()}
                </div>
                {/* {tab === 'members' && */}
                <div
                  className={`${from === undefined && to === undefined
                    ? "hideDateInput"
                    : "memberDateRangeWrapper"
                    }`}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {(path === "/members/all_members" ||
                    pathname.includes("/member/building_members") ||
                    pathname.includes("/member/total_business_members") ||
                    pathname.includes(
                      "/member/business_designated_members"
                    )) && (
                      <DateRange
                        {...this.state}
                        {...this.props}
                        from={from}
                        to={to}
                        memberFilter={memberFilter}
                        applyFilter={(range) => this.applyFilter(range)}
                        resetFilter={this.resetFilter}
                        BackBtn // for adding btnBackground class for stying the background
                      />
                    )}
                  {/* } */}
                  <BlueButton
                    width={120}
                    onClick={this.toggle}
                    style={{ marginBottom: 10 }}
                  >
                    {t("members.addMember")}
                  </BlueButton>
                </div>
              </div>
              {/* {(width === 'xs' || width === 'sm') &&
                (() => {
                  switch (match.params.tab) {
                    case 'building_members':
                      return null;
                    case 'building_businesses':
                      return null;
                    case 'total_business_members':
                      return null;
                    case 'business':
                      return null;
                    case 'business_designated_members':
                      return null;
                    default:
                      return (
                        <div style={{ marginBottom: 10 }}>
                          <BuildingToggle {...this.props} />{' '}
                        </div>
                      );
                  }
                })()} */}
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper className="just-paper">
                <div
                  style={
                    width !== "xs"
                      ? {
                        display: "flex",
                        justifyContent: "space-between",
                        paddingRight: 10,
                      }
                      : {
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: 0,
                      }
                  }
                >
                  <Grid container>
                    <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Tabs
                        value={value}
                        onChange={this.handleChange}
                        variant="fullWidth"
                        fullWidth
                        classes={{ indicator: classes.tabsIndicator }}
                      >
                        <Tab
                          value={"members"}
                          label={t("members.members")}
                          component={StyledLink}
                          to="/members/all_members"
                          style={tabStyle}
                          disabled={disabled}
                        />
                        <Tab
                          value={"businesses"}
                          label={t("tables.businesses")}
                          component={StyledLink}
                          to="/members/businesses"
                          style={tabStyle}
                          disabled={disabled}
                        />
                        {(env.includes('wework') || env.includes('uat')) &&
                          <Tab
                            value={"mp_members"}
                            label={t("voItems.voMember")}
                            component={StyledLink}
                            to="/members/mp_members"
                            style={tabStyle}
                            disabled={disabled}
                          />
                        }
                      </Tabs>
                    </Grid>
                    {tab !== "business_designated_members" && (
                      <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div
                          className={`topbar__search ${disabled &&
                            "disabled-link"}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            marginLeft: 0,
                          }}
                        >
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              this.handleSearchAction();
                            }}
                            disabled={startSearch}
                            style={{ width: "100%" }}
                          >
                            <InputBase
                              disabled={disabled}
                              aria-haspopup="true"
                              className="topbar__search-field topbar__search-field--open search margin-4px fs-14px"
                              inputProps={{
                                style: { cursor: "pointer" },
                                maxlength: 100,
                              }}
                              value={
                                value === "members" ? searchedMember : value === "mp_members" ? searchedVOMember : searchedBusiness
                              }
                              onChange={(e) => this.handleSearchBarChange(e)}
                              placeholder={
                                path === "/members/all_members" ||
                                  tab === "building_members" ||
                                  tab === "business_members" ||
                                  tab === "total_business_members"
                                  ? t("members.search") :
                                  path.includes("mp_member") || tab === "mp_members_building_members" ? t('voItems.searchVoMember')
                                    : t("members.searchBusiness")
                              }
                              startAdornment={
                                <Search
                                  style={{
                                    marginRight:
                                      window.screen.width < 500 ? 0 : 10,
                                    fontSize: 18,
                                  }}
                                />
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  {searchedMember &&
                                    searchedMember.length > 0 &&
                                    value === "members" ? (
                                    <IconButton
                                      style={{ padding: 14 }}
                                      onClick={() => {
                                        this.setState({
                                          searchedMember: "",
                                          startSearch: true,
                                        });
                                      }}
                                    >
                                      <Cancel className="cross-btn" />
                                    </IconButton>
                                  ) : (
                                    <div />
                                  )}
                                  {searchedBusiness &&
                                    searchedBusiness.length > 0 &&
                                    value === "businesses" ? (
                                    <IconButton
                                      style={{ padding: 14 }}
                                      onClick={() => {
                                        this.setState({
                                          searchedBusiness: "",
                                          startSearch: true,
                                        });
                                      }}
                                    >
                                      <Cancel className="cross-btn" />
                                    </IconButton>
                                  ) : (
                                    <div />
                                  )}
                                  {searchedVOMember &&
                                    searchedVOMember.length > 0 &&
                                    value === "mp_members" ? (
                                    <IconButton
                                      style={{ padding: 14 }}
                                      onClick={() => {
                                        this.setState({
                                          searchedVOMember: "",
                                          startSearch: true,
                                        });
                                      }}
                                    >
                                      <Cancel className="cross-btn" />
                                    </IconButton>
                                  ) : (
                                    <div />
                                  )}
                                </InputAdornment>
                              }
                            />
                          </form>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
                <Divider style={{ marginTop: -2 }} />
                {value === "members" && (
                  <MembersTab
                    t={t}
                    lng={lng}
                    style={style}
                    building={building}
                    pagination={pagination}
                    dispatch={dispatch}
                    getMember={getMember}
                    getMembers={getMembers}
                    getTitle={this.changeTitle}
                    rerender={this.rerender}
                    {...this.state}
                    stopSearch={this.handleStopSearching}
                    searchMember={searchMember}
                    formatPhoneNumber={formatPhoneNumber}
                    getFilterValue={this.getFilterValue}
                    userRole={userRole}
                    memberTempSearch={memberTempSearch}
                    from={from}
                    to={to}
                    removeDateFilter={this.props.removeDateFilter}
                  />
                )}
                {value === "businesses" && (
                  <BusinessesTab
                    t={t}
                    lng={lng}
                    style={style}
                    building={building}
                    pagination={pagination}
                    dispatch={dispatch}
                    getBusiness={getBusiness}
                    getBusinesses={getBusinesses}
                    handleChange={this.handleChange}
                    getTitle={this.changeTitle}
                    rerender={this.rerender}
                    {...this.state}
                    stopSearch={this.handleStopSearching}
                    searchBusiness={searchBusiness}
                    formatPhoneNumber={formatPhoneNumber}
                    getFilterValue={this.getFilterValue}
                    userRole={userRole}
                    businessTempSearch={businessTempSearch}
                  />
                )}
                {value === "mp_members" && (
                  <MembersTab
                    voMember={true}
                    t={t}
                    lng={lng}
                    style={style}
                    building={building}
                    pagination={pagination}
                    dispatch={dispatch}
                    getMember={getMember}
                    getMembers={getMembers}
                    getTitle={this.changeTitle}
                    rerender={this.rerender}
                    {...this.state}
                    searchedMember={searchedVOMember}
                    stopSearch={this.handleStopSearching}
                    searchMember={searchMember}
                    formatPhoneNumber={formatPhoneNumber}
                    getFilterValue={this.getFilterValue}
                    userRole={userRole}
                    memberTempSearch={memberTempSearch}
                    from={from}
                    to={to}
                    removeDateFilter={this.props.removeDateFilter}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

Members.propTypes = {
  t: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  classes: PropTypes.node.isRequired,
  style: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  generalValidation: PropTypes.node.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setDateFilter: (dates) => dispatch(setDateFilter(dates)),
  removeDateFilter: () => dispatch(removeDateFilter()),
});
const mapStateToProps = (state) => ({
  building: state.locationFilter,
  buildings: state.allBuildings,
  userRole: state.userRole,
  pagination: state.pagination,
  customizer: state.customizer,
  stateBuild: state,
  ...state,
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withWidth()(Members)))
);

const ModalAddMember = styled(Modal)`
  > div:first-child {
    background-color: unset;
  }
`;
const Dialogwrapper = styled.div`
  @media (min-width: 720px) {
    width: 400px;
  }
  @media (min-width: 365px) and (max-width: 450) {
    width: 270px;
  }
  @media (max-width: 364) {
    width: 220px;
  }
`;
