import { SET_BUILDINGS } from '../actions/allBuildingsActions';

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_BUILDINGS: {
      const { buildings } = action;
      state = buildings;
      return state;
    }
    default:
      return state;
  }
}

