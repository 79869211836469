function chartConfig(chartData1, currentWidth) {
    let chartData = chartData1.slice(1);
    chartData[0].data = parseFloat(chartData[0].data);
    chartData[1].data = parseFloat(chartData[1].data);
    // const chartData = [
    //     {
    //         label: 'Reached Quota',
    //         data: 70
    //     },
    //     {
    //         label: 'Remaining Quota',
    //         data: 30
    //     },
    // ];
    const config = {
        chart: {
            type: 'pie',
            width: currentWidth < 770 ? 150 : 200,
            height: currentWidth < 770 ? 150 : 170,
        },
        title: {
            text: null,
        },
        yAxis: {
            title: {
                text: '',
            },
        },
        plotOptions: {
            pie: {
                shadow: false,
                colors: ['#FF1E59', '#02B67C'],
                borderWidth: null,
                backgroundColor: null,
            },
        },
        tooltip: {
            textTransform: 'capitalize',
            valueSuffix: '',
            backgroundColor: 'rgba(97,97,97,0.9)',
            borderWidth: 0,
            borderRadius: 0,
            headerFormat: '',
            formatter() {
                return `<b> ${this.point.name}</b>: <b> ${this.y}%</b>`;
            },
            style: { color: 'white', textTransform: 'capitalize' },
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                type: 'pie',
                name: 'Held Packages',
                data: [
                    [chartData[0].label, chartData[0].data],
                    [chartData[1].label, chartData[1].data],
                ],
                backgroundColor: ['#FF1E59', '#02B67C'],
                hoverBackgroundColor: ['#FF1E59', '#02B67C'],
                size: '100%',
                innerSize: '40%',
                states: {
                    hover: {
                        enabled: false,
                    },
                },
                showInLegend: false,
                dataLabels: {
                    enabled: false,
                },
                borderColor: '#ffffff',
                borderWidth: 1,
            },
        ],
    };
    return config;
}

export default chartConfig;
