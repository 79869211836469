/* eslint-disable curly */
/* eslint-disable function-paren-newline */
/* eslint-disable quotes */
// import axios from "axios";

export default function theme() {
  return new Promise(() => {
    // axios.get('https://core.packagex.app/dev')
  });
}
