/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import pickupIcon from '../../../../assets/images/pickup.svg';
import notifyIcon from '../../../../assets/images/notify.svg';
import discardIcon from '../../../../assets/images/discard.svg';
import reminderIcon from '../../../../assets/images/reminder.svg';
import rerouteIcon from '../../../../assets/images/re-route_icon.svg';
import snapsendIcon from '../../../../assets/images/snap_send_email.svg';
import forwardIcon from '../../../../assets/images/forward_email.svg';
import holdIcon from '../../../../assets/images/hold_email.svg';
import destroyIcon from '../../../../assets/images/destroy_email.svg';

const Icon = props => {
  const emailIcons = {
    pickup: pickupIcon,
    notify: notifyIcon,
    discard: discardIcon,
    reminder: reminderIcon,
    reroute: rerouteIcon,
    forward: forwardIcon,
    snapsend: snapsendIcon,
    hold: holdIcon,
    destroy: destroyIcon
  };

  const { title } = props;
  const src = title ? emailIcons[title.toLowerCase()] : emailIcons.discard;
  // const src = title.includes('PICKUP')
  //   ? pickup
  //   : title.includes('REMINDER')
  //     ? reminder
  //     : title.includes('NOTIFY')
  //       ? notify
  //       : title.includes('REROUTE')
  //         ? reroute
  //         : discard;
  return (
    <img
      src={src}
      style={{
        width: 26,
        height: 26,
        marginLeft: 8,
        paddingRight: window.screen.width < 600 ? 8 : 0,
      }}
      alt="icon"
    />
  );
};

Icon.propTypes = {
  title: PropTypes.string.isRequired,
};

const EmailTemplate = ({
  t,
  style,
  emails,
  error,
  loading,
  building_id,
  width,
  tenant_id,
  buildings
}) => {
  let filteredEmail = emails.filter(obj => {
    if (obj.topic !== "FORWARD" && obj.topic !== "HOLD" && obj.topic !== "SNAPSEND" && obj.topic !== "DESTROY" && obj.topic !== "EMAIL") {
      return obj;
    }
    return null;
  });

  const tenant = buildings.filter(x => x.tenant_id === +tenant_id);
  // const newEmails = [{ notification_id: 1450, building_id: null, tenant_id: null, topic: "EMAIL", updated_at: "2021-07-17T00:44:06.000Z", emailTemplates: ["notify", "PICKUP", "destroy", "reminder"] }];

  if (tenant.length && emails.length) {
    if (!tenant[0].legacy_emails) {
      const newEmails = emails.filter(obj => obj.topic.toUpperCase() === "EMAIL");
      filteredEmail = newEmails[0].email_templates || ["NOTIFY", "REMINDER", "PICKUP", "DISCARD", "REROUTE"];

      return (
        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
          <Paper
            elevation={11}
            style={{
              height: 480,
              overflow: 'auto',
              marginTop: 0,
              marginBottom: 15,
              marginRight: width === 'lg' || width === 'xl' ? 15 : 0,
            }}
            className="just-paper"
          >
            <div className="card-body">
              <div className="email_title">
                {loading && loading && error && error}
                <div
                  className="bold_text"
                  style={{
                    ...style.common.fw_500,
                    ...style.colors.black,
                    ...style.common.fs_18,
                    ...style.common.lh_21,
                    ...style.common.capitalize,
                    marginBottom: 10,
                  }}
                >
                  {t('email.title')}
                </div>
              </div>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ fontSize: 12, fontFamily: 'Muli', padding: 0 }}
                    >
                      {' '}
                      &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
                      &nbsp;&nbsp;
                      {t('email.name')}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: 'Muli', padding: 0 }}
                    >
                      {t('email.lastEdited')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading &&
                    [1, 2, 3].map(() => (
                      <TableRow>
                        <TableCell style={{ padding: 0 }} />
                        <TableCell style={{ padding: 0 }}>
                          {' '}
                          <Skeleton width={50} />{' '}
                        </TableCell>
                        <TableCell style={{ padding: 0 }}>
                          {' '}
                          <Skeleton width={50} />{' '}
                        </TableCell>
                      </TableRow>
                    ))}

                  {!loading &&
                    filteredEmail.map(email => (
                      <TableRow key={newEmails[0].notification_id}>
                        {/* <TableCell style={{ padding: 0 }}>
                      {' '}
                      <Link
                        to={`/building_detail/${building_id}/email/${email.notification_id}/${email.topic}`}
                      >
                        <Icon title={email.topic} className="color-grey" />
                      </Link>
                    </TableCell> */}
                        <TableCell component="th" scope="row" style={{ padding: 0 }}>
                          <Link
                            to={`/building_detail/${building_id}/email/${newEmails[0].notification_id}/${email}/${tenant_id}`}
                            style={{
                              display: 'flex',
                              color: '#027AFF',
                              fontFamily: 'Muli',
                              textDecoration: 'none',
                              textTransform: 'capitalize',
                            }}
                          >
                            {' '}
                            {/* &nbsp; &nbsp; */}
                            <Icon title={email} className="color-grey" />
                            {`${email.toLowerCase()} template`}
                          </Link>
                        </TableCell>

                        <TableCell
                          style={{
                            marginTop: 10,
                            padding: 0,
                            fontFamily: 'Muli',
                          }}
                        >
                          {newEmails[0].updated_at
                            ? `${moment(email.updated_at).format('MM/DD/YYYY')} @
                      ${moment(email.updated_at).format('hh:mm A')}`
                            : '  ---'}
                        </TableCell>
                        {/* <HeadCell align='right'>
                ---
              </HeadCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </Paper>
        </Grid>

      );
    }
  }

  return (
    <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
      <Paper
        elevation={11}
        style={{
          height: 480,
          overflow: 'auto',
          marginTop: 0,
          marginBottom: 15,
          marginRight: width === 'lg' || width === 'xl' ? 15 : 0,
        }}
        className="just-paper"
      >
        <div className="card-body">
          <div className="email_title">
            {loading && loading && error && error}
            <div
              className="bold_text"
              style={{
                ...style.common.fw_500,
                ...style.colors.black,
                ...style.common.fs_18,
                ...style.common.lh_21,
                ...style.common.capitalize,
                marginBottom: 10,
              }}
            >
              {t('email.title')}
            </div>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ fontSize: 12, fontFamily: 'Muli', padding: 0 }}
                >
                  {' '}
                  &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
                  &nbsp;&nbsp;
                  {t('email.name')}
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontSize: 12, fontFamily: 'Muli', padding: 0 }}
                >
                  {t('email.lastEdited')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading &&
                [1, 2, 3].map(() => (
                  <TableRow>
                    <TableCell style={{ padding: 0 }} />
                    <TableCell style={{ padding: 0 }}>
                      {' '}
                      <Skeleton width={50} />{' '}
                    </TableCell>
                    <TableCell style={{ padding: 0 }}>
                      {' '}
                      <Skeleton width={50} />{' '}
                    </TableCell>
                  </TableRow>
                ))}

              {!loading &&
                filteredEmail.map(email => (
                  <TableRow key={email.notification_id}>
                    {/* <TableCell style={{ padding: 0 }}>
                    {' '}
                    <Link
                      to={`/building_detail/${building_id}/email/${email.notification_id}/${email.topic}`}
                    >
                      <Icon title={email.topic} className="color-grey" />
                    </Link>
                  </TableCell> */}
                    <TableCell component="th" scope="row" style={{ padding: 0 }}>
                      <Link
                        to={`/building_detail/${building_id}/email/${email.notification_id}/${email.topic}/${tenant_id}`}
                        style={{
                          display: 'flex',
                          color: '#027AFF',
                          fontFamily: 'Muli',
                          textDecoration: 'none',
                          textTransform: 'capitalize',
                        }}
                      >
                        {' '}
                        {/* &nbsp; &nbsp; */}
                        <Icon title={email.topic} className="color-grey" />
                        {`${email.topic.toLowerCase()} template`}
                      </Link>
                    </TableCell>

                    <TableCell
                      style={{
                        marginTop: 10,
                        padding: 0,
                        fontFamily: 'Muli',
                      }}
                    >
                      {email.updated_at
                        ? `${moment(email.updated_at).format('MM/DD/YYYY')} @
                    ${moment(email.updated_at).format('hh:mm A')}`
                        : '  ---'}
                    </TableCell>
                    {/* <HeadCell align='right'>
              ---
            </HeadCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </Grid>
  );
};

EmailTemplate.propTypes = {
  t: PropTypes.func.isRequired,
  style: PropTypes.node.isRequired,
  error: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  building_id: PropTypes.string.isRequired,
  emails: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
  tenant_id: PropTypes.number.isRequired,
  buildings: PropTypes.object.isRequired,
};
Table.propTypes = {
  // Pass in a Component to override default element
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  size: PropTypes.string,
  bordered: PropTypes.bool,
  borderless: PropTypes.bool,
  striped: PropTypes.bool,
  dark: PropTypes.bool,
  hover: PropTypes.bool,
  responsive: PropTypes.bool,
  // Custom ref handler that will be assigned to the 'ref' of the inner <table> element
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.object,
  ]),
};
export default EmailTemplate;
