/* eslint-disable */

import React from "react";
import Scrollbar from "react-smooth-scrollbar";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import SidebarContent from "./SidebarContent";
import { styles } from "../../../common/style";
import "./style.css";
import { SidebarProps } from "../../../shared/prop-types/ReducerProps";

const Sidebar = (props) => {
  const {
    changeToDark,
    changeToLight,
    changeMobileSidebarVisibility,
    sidebar,
  } = props;

  const sidebarClass = classNames({
    sidebar: true,
    "sidebar--show": sidebar.show,
    "sidebar--collapse": sidebar.collapse,
  });

  const StyledScrollbar = styled(Scrollbar)`
    .scroll-content div {
      height: 100%;
    }
  `;
  let empty = () => {};
  let handleClose =
    window.innerWidth > 1025 ? empty : changeMobileSidebarVisibility;
  return (
    <Paper elevation={11} style={{ borderRadius: 0 }} className={sidebarClass}>
      <button
        className="sidebar__back"
        onClick={changeMobileSidebarVisibility}
      />
      <StyledScrollbar
        className="sidebar__scroll scroll"
        style={{ height: "inherit" }}
      >
        <div
          className="sidebar__wrapper sidebar__wrapper--desktop"
          style={{
            height: "inherit",
            ...styles.flex.justify_between,
            ...styles.flex.display,
            ...styles.flex.direction_column,
          }}
        >
          <SidebarContent
            {...props}
            onClick={handleClose}
            changeToDark={changeToDark}
            changeToLight={changeToLight}
          />
        </div>
      </StyledScrollbar>
    </Paper>
  );
};

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;
