import React from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, List, ListItem, Paper, Popper } from '@material-ui/core';

const hours = ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18'];
const interval = ['00', '30'];

export default function Dropdown(props) {
  const {
    state,
    value,
    anchor,
    hourSelection,
    minSelection,
    anchorName,
    setClose,
  } = props;

  return (
    <Popper open={anchor} anchorEl={anchor} placement="bottom-end">
      <ClickAwayListener onClickAway={() => setClose(anchorName)}>
        <Paper elevation={8} className="just-paper">
          <div className="flex">
            <List>
              {hours.map(h => (
                <ListItem
                  selected={value.split(':')[0] === h}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  onClick={() => hourSelection(state, h)}
                  button
                >
                  {h}
                </ListItem>
              ))}
            </List>
            <List style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}>
              {interval.map(m => (
                <ListItem
                  selected={value.split(':')[1] === m}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  onClick={() => minSelection(state, m)}
                  button
                >
                  {m}
                </ListItem>
              ))}
            </List>
          </div>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
}

Dropdown.propTypes = {
  state: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  anchor: PropTypes.string.isRequired,
  hourSelection: PropTypes.func.isRequired,
  minSelection: PropTypes.func.isRequired,
  setClose: PropTypes.func.isRequired,
  anchorName: PropTypes.string.isRequired,
};
